<div class="relative flex flex-col h-full w-full px-4">
  <button *ngIf="showCloseButton" class="absolute text-red-500 right-4 top-4" (click)="closeModal()">
    <fa-icon class="fa-xl fa-regular" [icon]="FaTimes"></fa-icon>
  </button>
  <h2 class="text-4xl mb-6 w-full text-center">Profile</h2>

  <form *ngIf="!!user && !!userForm" [formGroup]="userForm" (submit)="save()" class="flex flex-col flex-grow h-full w-full overflow-y-auto">
    <div class="flex flex-col space-y-2">
      <div class="flex flex-col w-full justify-center items-center">
      <div class="flex flex-col justify-center items-start">
        <label class="text-sm text-grey-grey-5" for="name">Avatar</label>
        <div class="relative w-[75px] h-[75px] flex flex-row justify-center items-center overflow-hidden border rounded text-grey-grey-8 bg-gray-100 cursor-pointer" (click)="openFileInput('AVATAR')">
          <!-- Image preview -->
          <img *ngIf="avatarImagePreviewUrl" [src]="avatarImagePreviewUrl" alt="Image Preview" class="w-full h-full object-cover" />
          <div class="flex flex-col justify-center items-center">
            <fa-icon *ngIf="!avatarImagePreviewUrl" [icon]="FaImage" class="text-business-blue text-2xl"></fa-icon>
            <div *ngIf="userForm.controls['avatarImageFile']?.invalid && userForm.controls['avatarImageFile']?.touched" class="text-red-500 text-sm">Avatar is invalid</div>
          </div>

          <!-- Custom button -->
          <div *ngIf="avatarImagePreviewUrl"
          class="absolute top-1 right-1">
            <fa-icon [icon]="FaImage" class="text-white text-xl"></fa-icon>
          </div>

          <!-- Hidden actual input field -->
          <input type="file" class="hidden" #avatarFileInput
          (change)="onFileSelected($event, 'avatarImageFile')"
          (close)="onCloseFile('avatarImageFile')"
          (cancel)="onCloseFile('avatarImageFile')"
          (error)="onFileSelectError($event, 'avatarImageFile')" />
        </div>
      </div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5" for="firstName">First Name</label>
        <input formControlName="firstName" type="text" class="border rounded p-2 text-xl text-grey-grey-8" />
        <div *ngIf="userForm.controls['firstName']?.invalid && userForm.controls['firstName']?.touched" class="text-red-500 text-sm">First Name is required</div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5" for="lastName">Last Name</label>
        <input formControlName="lastName" type="text" class="border rounded p-2 text-xl text-grey-grey-8" />
        <div *ngIf="userForm.controls['lastName']?.invalid && userForm.controls['lastName']?.touched" class="text-red-500 text-sm">Last Name is invalid</div>
      </div>
      <div class="flex flex-col" *ngIf="navControlService.hasRole(NavRole.ADMIN)">
        <label class="text-sm text-grey-grey-5" for="id">ID</label>
        <input formControlName="id" type="text" class="border rounded p-2 text-xl text-grey-grey-8" [disabled]="true" />
      </div>
    </div>

    <div class="flex items-center space-x-4 justify-between pt-6 flex-wrap">
      <div class="flex items-center space-x-4 flex-wrap">
        <button type="button" class="text-business-blue" (click)="resetForm()">Undo/Reset</button>
      </div>
      <button type="submit" class="button-business-blue px-6 py-2" [disabled]="userForm.invalid">Save</button>
    </div>

    <!-- Show Error Here -->
    <div *ngIf="saveState === FetchState.ERROR" class="text-red-500 mt-6">
      Error saving, please try again.
    </div>
  </form>

  <!-- Loading Cover -->
  <div *ngIf="saveState === FetchState.LOADING" class="flex justify-center items-center h-full absolute inset-0 bg-gray-100 bg-opacity-25">
    <div class="loader-wrapper flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  </div>
</div>
