<div class="flex w-full h-full flex-col items-center bg-stone-200 pt-12 px-4">
  <!-- Text field to capture next input -->
  <form [formGroup]="chatForm" class="w-full flex flex-row justify-center items-center" (submit)="sendMessage()">
    <div class="flex flex-col w-full mb-2">
      <label class="text-sm text-grey-grey-5" for="firstName">Message</label>
      <div class="flex flex-row justify-between items-center">
        <input formControlName="message" type="text" class="border rounded p-2 text-base w-full cursor-text"
          [disabled]="upsertChatState === FetchState.LOADING" [ngClass]="{
          'border-red-500': chatForm.controls['message']?.invalid && chatForm.controls['message']?.touched,
          'border-grey-800': chatForm.controls['message']?.valid,
          'text-grey-700 bg-stone-100 select-none pointer-events-none cursor-auto': upsertChatState === FetchState.LOADING,
          'text-grey-900 bg-white': upsertChatState !== FetchState.LOADING
        }" />
        <div
        [ngClass]="{
          'select-none pointer-events-none cursor-auto': upsertChatState === FetchState.LOADING,
          'cursor-pointer': upsertChatState !== FetchState.LOADING
        }">
          <button class=" text-white font-bold py-2 px-4 rounded-lg ml-2" [ngClass]="{
            'bg-grey-400': upsertChatState === FetchState.LOADING,
            'bg-business-blue': upsertChatState !== FetchState.LOADING
          }" [disabled]="upsertChatState === FetchState.LOADING" type="submit">Send</button>

        </div>

      </div>
      <div *ngIf="chatForm.controls['message']?.invalid && chatForm.controls['message']?.touched"
        class="text-red-500 text-sm">Required</div>
    </div>
    <!-- <input
      class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id="message" type="text" placeholder="Type a message" [(ngModel)]="message" name="message"> -->
  </form>

  <!-- List  previous messages -->
  <div class="w-full flex flex-col items-center mx-8 h-full overflow-y-auto">
    <!-- not selectable -->
    <div *ngFor="let message of chat.messages"
      class="w-full flex flex-col justify-start items-start m-2 gap-1">
      <div *ngIf="message.role === 'assistant'"
        class=" border border-patron-pink text-patron-pink text-sm py-1 px-2 rounded-lg mr-1 select-none pointer-events-none">GZBot</div>
      <div *ngIf="message.role === 'user'"
        class="border  border-grey-800 text-grey-800 text-sm py-1 px-2 rounded-lg mr-1 select-none pointer-events-none">You</div>
      <div class="bg-white text-black text-opacity-75 font-outfit-thin py-2 px-4 rounded-md mr-2"
        [innerHTML]="message.message | newline"></div>
    </div>

    <!-- Previous chat, if any -->
    <div *ngIf="!!previousChat" class="w-full flex flex-col items-center gap-2 mt-8">
      <div class="text-grey-800 text-sm">Previous Chat</div>
      <div class="w-full flex flex-col items-center gap-2 opacity-50">
        <div *ngFor="let message of previousChat.messages"
          class="w-full flex flex-col justify-start items-start m-2 gap-1 select-none pointer-events-none">
          <div *ngIf="message.role === 'assistant'"
            class=" border border-patron-pink text-patron-pink text-sm py-1 px-2 rounded-lg mr-1">GZBot</div>
          <div *ngIf="message.role === 'user'"
            class="border  border-grey-800 text-grey-800 text-sm py-1 px-2 rounded-lg mr-1">You</div>
          <div class="bg-white text-black text-opacity-75 font-outfit-thin py-2 px-4 rounded-md mr-2"
            [innerHTML]="message.message | newline"></div>
        </div>
      </div>
    </div>
  </div>

</div>
