import { Injectable } from '@angular/core';
import { Merchant } from './model/venue.model';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { FetchState } from './app.module';
import { MerchantQuery } from './graphql/queries/merchant.query.graphql';
import { UpsertMerchantMutation } from './graphql/mutations/merchant.mutation.graphql';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  constructor(
    private readonly apollo: Apollo
  ) { }

  // Fetch Merchant by venueId
  fetchMerchantState$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  async fetchMerchant(venueId: string): Promise<Merchant> {
    // Variables: $id: String!
    console.log(`fetchMerchant`);
    console.dir(venueId);

    // Validate the input
    if (!venueId) {
      return Promise.reject('Invalid merchant');
    }

    this.fetchMerchantState$.next(FetchState.LOADING);

    //GraphQL query
    return new Promise<Merchant>((resolve, reject) => {
      this.apollo.query(
        {
          query: MerchantQuery,
          variables: {
            venueId: venueId
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          let result: Merchant = null;
          if (data && data['merchant']) {
            result = Merchant.parseResponse(data['merchant']);
          }
          this.fetchMerchantState$.next(FetchState.LOADED_ALL);
          resolve(result);
        },
        error: (error) => {
          console.log(error);
          this.fetchMerchantState$.next(FetchState.ERROR);
          reject(error);
        }
      });
    });
  }

  // Upsert Merchant
  upsertMerchantState$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  async upsertMerchant(id: string, merchantName: string, networkMerchantId: string,
    visaMerchantId: string, mastercardMerchantId: string, amexMerchantId: string, sync: boolean,
    forceBrandNameUpdate: boolean): Promise<Merchant> {
    console.log(`upsertMerchant`);
    console.dir(id);

    // Validate the input
    if (!id) {
      return Promise.reject('Invalid merchant');
    }

    //GraphQL mutation
    return new Promise<Merchant>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: UpsertMerchantMutation,
          variables: {
            id: id,
            merchantName: merchantName,
            networkMerchantId: networkMerchantId,
            visaMerchantId: visaMerchantId,
            mastercardMerchantId: mastercardMerchantId,
            amexMerchantId: amexMerchantId,
            sync: sync,
            forceBrandNameUpdate: forceBrandNameUpdate
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          let result: Merchant = null;
          if (data && data['upsertMerchant']) {
            result = Merchant.parseResponse(data['upsertMerchant']);
          }
          resolve(result);
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

}
