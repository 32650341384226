<!-- <div class="tile-container-2" (click)="goToOfferDetails()"> -->
<div class="tile-container-2" >
  <div class="main-image-container relative">
    <picture *ngIf="!!offer" class="w-full h-full">
      <source [srcset]="offerImageUrl" type="image/webp">
      <img [src]="offerImageUrl" loading="eager" class="main-image"
        alt="offer image"
        >
    </picture>

    <!-- <div *ngIf="offer?.charityIncentive && !offer?.charityIncentive.disabledDate || true" class="charity-header">
      <div class="title-text text-center text-white ">{{offer?.charityIncentive?.charity?.name || "TEST CHARITY"}}</div>
    </div> -->
    <!-- <div *ngIf="!!offer?.claim || !!rewardedOffer || true" class="tag-container"> -->
    <!-- <div class="tag-container" *ngIf="userState === LoginState.LOGGED_IN"
    [ngClass]="{'!text-charity-green-900': offer?.charityIncentive && !offer?.charityIncentive.disabledDate}"
    >
      <div *ngIf="!offer?.claim" class="tag-icon" (click)="claimOffer($event)">
        <fa-icon [icon]="faClaim"></fa-icon>
      </div>
      <div *ngIf="!rewardedOffer && !!offer?.claim" class="tag-icon">
        <fa-icon [icon]="faClaimed"></fa-icon>
      </div>
      <div *ngIf="!!offer?.claim && !!rewardedOffer" class="tag-icon">
        <fa-icon [icon]="faReward"></fa-icon>
      </div>
    </div> -->
    <div class="reward-container" *ngIf="!!offer"
    [ngClass]="{'bg-charity-green': isCharityOffer, 'bg-patron-pink': !isCharityOffer}"
    >
      <div *ngIf="!isCharityOffer" class="reward-text">
        <span *ngIf="!!offer?.patronIncentive?.intReturnPercentage" class="">
          {{offer?.patronIncentive?.intReturnPercentage / 100.0}}% {{ 'CASH_BACK' | translate }}
        </span>
        <span *ngIf="!!offer?.patronIncentive?.intReturnAmount" class="">
          {{(offer?.patronIncentive?.intReturnAmount / 100.0) | currency}} {{ 'CASH_BACK' | translate }}
        </span>
      </div>
      <div *ngIf="isCharityOffer" class="reward-text">
        <span *ngIf="!!offer?.charityIncentive?.intReturnPercentage" class="">
          {{offer?.charityIncentive?.intReturnPercentage / 100.0}}% {{ 'DONATION' | translate }}
        </span>
        <span *ngIf="!!offer?.patronIncentive?.intReturnAmount" class="">
          {{(offer?.patronIncentive?.intReturnAmount / 100.0) | currency}} {{ 'DONATION' | translate }}
        </span>
      </div>
    </div>

    <div *ngIf="!isCharityOffer && !!logoImageUrl"
        class="absolute bottom-2 left-1">
      <div class="back-shadow">
        <div *ngIf="logoImageLoaded" class="back-shadow-bg-dark"></div>
        <div class="back-shadow-contents">
          <img class="w-20 h-20 rounded-2xl object-cover aspect-square "
          [ngClass]="{'bg-zinc-300': logoImageLoaded}"
            loading="lazy"
            [src]="logoImageUrl" (load)="onLogoImageLoad()">
        </div>
      </div>
    </div>
    <div *ngIf="!!isCharityOffer"
      class="absolute bottom-6 left-1 flex justify-center items-center z-60 ">
      <div class="relative">
        <div *ngIf="!!logoImageUrl"
          class="absolute bottom-0 left-0 w-24 h-24 rounded-full flex justify-center items-center z-60 bg-zinc-300">
          <img class="w-24 h-24 rounded-full object-cover aspect-square"
          loading="lazy"
          [ngClass]="{'bg-zinc-300': logoImageLoaded}"
          [src]="logoImageUrl" (load)="onLogoImageLoad()">
        </div>
        <div *ngIf="!!subLogoImageUrl"
          class="back-shadow absolute -bottom-[20px] left-[60px] ">
          <div *ngIf="subLogoImageLoaded"
            class="back-shadow-bg"
          ></div>
          <div
            class="back-shadow-contents"
          >
            <img class="w-14 h-14 rounded-lg object-cover aspect-square bg-stone-100"
            loading="lazy"
            [ngClass]="{'bg-zinc-300': subLogoImageLoaded}"
            [src]="subLogoImageUrl" (load)="onSubLogoImageLoad()">
          </div>
        </div>
      </div>
    </div>

    <app-fade-toggle [isVisible]="!offer" class="absolute inset-0">
      <div class="bg-gray-200 w-full rounded-t-xl h-full relative overflow-hidden">
        <div class="shimmer"></div>
      </div>
    </app-fade-toggle>
  </div>

  <div class="main-content-container">
    <div class="w-full flex flex-col flex-grow">
      <div class="w-full flex flex-row justify-between items-start">
        <!-- <div class="flex flex-col flex-grow justify-start items-start"> -->
        <div class="title-text w-full text-md relative">
          <app-fade-toggle [isVisible]="!offer" [ngClass]="{'relative w-full': !offer, 'absolute inset-0': !!offer}">
            <div class="shimmer-container-light-gray-h-6 my-1">
              <div class="shimmer"></div>
            </div>
          </app-fade-toggle>
          {{offer?.name}}
        </div>
          <!-- <div class="subtitle-text text-md">{{offer?.description}}</div> -->
        <!-- </div> -->
        <div *ngIf="!rewardedOffer && !!offer?.claim" class="text-center text-lg font-normal leading-normal"
        [ngClass]="{'text-green-700': isCharityOffer, 'text-patron-pink': !isCharityOffer}"
        >CLAIMED</div>
      </div>

      <div class="title-text w-full text-md flex-grow relative">
        <app-fade-toggle [isVisible]="!offer" [ngClass]="{'relative w-full': !offer, 'absolute inset-0': !!offer}">
          <div class="shimmer-container-light-gray-h-6 my-1">
            <div class="shimmer"></div>
          </div>
        </app-fade-toggle>
        <span *ngIf="offer?.charityIncentive && !offer?.charityIncentive.disabledDate" class="!text-charity-green-900" >
          {{offer?.charityIncentive?.charity?.name || "TEST CHARITY"}}
        </span>
        <span *ngIf="offer?.charityIncentive && !offer?.charityIncentive.disabledDate">
          &#64;
        </span>
        {{offer?.venue?.name}}
      </div>
      <div class="flex flex-row justify-start items-center w-full relative">
        <div *ngIf="!!distance" class="justify-center items-center flex">
          <img src="/assets/icons/zeals_logo_pink.svg" class="w-5 h-7 inline-block mr-1">
          <span class="text-black subtitle-text">{{distanceDisplay}}</span>&nbsp;&nbsp;•&nbsp;&nbsp;
        </div>
        <div *ngIf="!!offerAddressLine1" class="subtitle-text">{{offerAddressLine1}}</div>
        <app-fade-toggle [isVisible]="!offer" [ngClass]="{'relative w-full': !offer, 'absolute inset-0': !!offer}">
          <div class="shimmer-container-light-gray-h-6 my-1">
            <div class="shimmer"></div>
          </div>
        </app-fade-toggle>

      </div>
    </div>

  </div>
</div>
