import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from './apple/authorize.guard';
import { OffersComponent } from './offers/offers.component';
import { OfferDetailsComponent } from './offer-details/offer-details.component';
import { HomeComponent } from './components/home/home.component';
import { TermOfServiceComponent } from './term-of-service/term-of-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { VenueOfferDetailWrapperComponent } from './venue-offer-detail-wrapper/venue-offer-detail-wrapper.component';
import { VenueDetailsComponent } from './components/venue/venue-details/venue-details.component';
import { VenuesComponent } from './components/venue/venues/venues.component';
import { FundraisingComponent } from './components/fundraising/fundraising.component';
import { AffiliatesComponent } from './components/affiliates/affiliates.component';
import { AdminGuard } from './guards/admin.guard';
import { AffiliateDetailsComponent } from './components/affiliates/affiliate-details/affiliate-details.component';
import { PlaidComponent } from './components/plaid/plaid.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { FaqComponent } from './components/faq/faq.component';
import { AboutComponent } from './components/about/about.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { PatronBankAccountComponent } from './components/patron-bank-account/patron-bank-account.component';
import { PatronCreditCardsComponent } from './components/patron-credit-cards/patron-credit-cards.component';
import { VenueOperatorGuard } from './guards/venue-operator.guard';
import { ContactComponent } from './components/contact/contact.component';
import { BusinessComponent } from './components/business/business.component';
import { CharityComponent } from './components/charity/charity.component';
import { PatronEarningsComponent } from './components/patron-earnings/patron-earnings.component';
import { VenueBankAccountComponent } from './components/venue/venue-bank-account/venue-bank-account.component';
import { VenueMerchantDetailsComponent } from './components/venue/venue-merchant-details/venue-merchant-details.component';
import { AcceptInviteComponent } from './components/user/accept-invite/accept-invite.component';
import { UserDetailsComponent } from './components/user/user-details/user-details.component';
import { ChatComponent } from './components/chat/chat.component';

const routes: Routes = [
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  // { path: 'login', redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'business', component: BusinessComponent },
  { path: 'charity', component: CharityComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'faq', component: FaqComponent },
  // { path: 'login', component: LoginPageComponent },
  { path: 'fundraising', component: FundraisingComponent },
  { path: 'invite/:token', component: AcceptInviteComponent },
  { path: 'chat', component: ChatComponent, canActivate: [AuthorizeGuard, AdminGuard]},
  { path: 'patron/bank', component: PatronBankAccountComponent, canActivate: [AuthorizeGuard]},
  { path: 'patron/cards', component: PatronCreditCardsComponent, canActivate: [AuthorizeGuard]},
  { path: 'patron/earnings', component: PatronEarningsComponent, canActivate: [AuthorizeGuard]},
  { path: 'patron/profile', component: UserDetailsComponent, canActivate: [AuthorizeGuard]},
  { path: 'offers', component: OffersComponent },
  { path: 'offers/:offerId', component: OffersComponent },
  { path: 'offers/e/:pivotId', component: OffersComponent },
  { path: 'offers/:offerId/claim', component: OfferDetailsComponent, canActivate: [AuthorizeGuard] },
  { path: 'term', component:TermOfServiceComponent},
  { path: 'privacy', component:PrivacyPolicyComponent},
  { path: 'venue', component: VenuesComponent, canActivate: [AuthorizeGuard, VenueOperatorGuard] },
  { path: 'venue/:venueId', component: VenueDetailsComponent, canActivate: [AuthorizeGuard, VenueOperatorGuard] },
  { path: 'venue/:venueId/bank', component: VenueBankAccountComponent, canActivate: [AuthorizeGuard, VenueOperatorGuard]},
  { path: 'venue/:venueId/merchant', component: VenueMerchantDetailsComponent, canActivate: [AuthorizeGuard, VenueOperatorGuard]},
  // { path: 'venue/:venueId/offers', component: VenueOffersComponent, canActivate: [AuthorizeGuard, VenueOperatorGuard] },
  { path: 'venue/:venueId/offers/:offerId', component: VenueOfferDetailWrapperComponent, canActivate: [AuthorizeGuard, VenueOperatorGuard] },
  // { path: 'venue/:venueId/offers/:offerId', component: VenueOfferDetailWrapperComponent, canActivate: [AuthorizeGuard, VenueOperatorGuard] },
  { path: 'affiliate', component: AffiliatesComponent, canActivate: [AuthorizeGuard, AdminGuard] },
  { path: 'affiliate/:affiliateId', component: AffiliateDetailsComponent, canActivate: [AuthorizeGuard, AdminGuard] },
  { path: 'plaid', component: PlaidComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // scrollPositionRestoration: 'top'
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
