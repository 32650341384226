import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { User } from '../model/user.model';
import { UserDetails } from '../graphql/fragments/user.fragment.graphql';
import { OperatorType, PageAndSortInput, SearchInput } from '../graphql/types.graphql';
import { UpsertUserMutation } from '../graphql/mutations/user.mutation.graphql';
import { FetchUserImageDownloadUrl, FetchUserImageUploadUrl } from '../graphql/queries/user.query.graphql';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apollo: Apollo
  ) { }

  page: number = 0;
  pageSize: number = 10;

  static readonly SEARCH_USERS = gql`
    ${UserDetails}
    query Users($search: [SearchInput!]!, $sort: PageAndSortInput) {
      users(search: $search, sort: $sort) {
        ...UserDetails
      }
    }
  `;


  searchUsers(searchTerm: string): Observable<User[]> {

    const sort: PageAndSortInput = {
      page: this.page,
      pageSize: this.pageSize,
      sortFields: []
    };

    const searchInputs: Array<SearchInput> = []
    searchInputs.push({value: searchTerm, searchFields: ["ALL"], operator: OperatorType.LIKE});

    const variables = {
      search: searchInputs.map((searchInput) => {
        return {
          value: searchInput.value,
          searchFields: searchInput.searchFields,
          operator: searchInput.operator.toUpperCase()
          };
        }),
      sort: sort
    };


    return this.apollo.watchQuery<any>({
      query: UserService.SEARCH_USERS,
      variables: variables,
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      map(result => {
        return result.data.users.map((userData: any) => {
          let user = User.parseResponse(userData);
          console.log(user);
          return user;
        });
      })
    );
  }

  async save(entity: User): Promise<User> {
    const variables = {
      id: entity.id,
      firstName: entity.firstName,
      lastName: entity.lastName
    };

    //GraphQL mutation
    return new Promise<User>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: UpsertUserMutation,
          variables
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          let result: User = null;
          if (data && data['upsertUser']) {
            // If the venue is not already in the list, add it, otherwise update the existing one
            result = User.parseResponse(data['upsertUser']);
          }
          resolve(result);
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

  fetchImageUploadUrl(id: string, contentType: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.apollo.query({
        query: FetchUserImageUploadUrl,
        variables: {
          id: id,
          contentType: contentType
        }
      }).subscribe({
        next: ({ data, loading }) => {
          let newData: any = data;
          if (newData) {
            newData = newData.fetchUserImageUploadUrl;
            resolve(newData);
          }
          else {
            reject();
          }
        },
        error: (error) => {
          console.log(error)
          reject(error);
        }
      });
    });
  }

  fetchImageDownloadUrl(id: string, forceRefresh: boolean = false): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.apollo.query({
        query: FetchUserImageDownloadUrl,
        variables: {
          id: id,
          forceRefresh: forceRefresh
        }
      }).subscribe({
        next: ({ data, loading }) => {
          let newData: any = data;
          if (newData) {
            newData = newData.fetchUserImageDownloadUrl;
            resolve(newData);
          }
          else {
            reject();
          }
        },
        error: (error) => {
          console.log(error)
          reject(error);
        }
      });
    });
  }

}
