import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OfferDetailsComponent } from './offer-details/offer-details.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgOptimizedImage } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OffersComponent } from './offers/offers.component';
import { AppleComponent } from './apple/apple.component';
import { AppleService } from './apple/apple.service';
import { AuthInterceptor } from './apple/auth.interceptor';
import 'hammerjs';
import { AddressPipe } from './pipes/address.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { OfferDetailInfoComponent } from './components/offer-detail-info/offer-detail-info.component';
import { FooterComponent } from './components/footer/footer.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { HomeComponent } from './components/home/home.component';
import { ClickOutsideDirective } from './directives/click_outside.directive';
import { VenueOffersComponent } from './venue-offers/venue-offers.component';
import { VenueOfferDetailComponent } from './venue-offer-detail/venue-offer-detail.component';
import { AvailableComponent } from './venue-offer-detail/available/available.component';
import { IncentivesComponent } from './venue-offer-detail/incentives/incentives.component';
import { PromosComponent } from './promos/promos.component';
import { OfferTileComponent } from './offers/offer-tile/offer-tile.component';
import { VenueOfferDetailWrapperComponent } from './venue-offer-detail-wrapper/venue-offer-detail-wrapper.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CreditCardsComponent } from './credit-cards/credit-cards.component';
import { VenueDetailsComponent } from './components/venue/venue-details/venue-details.component';
import { VenuesComponent } from './components/venue/venues/venues.component';
import { EditPromoComponent } from './promos/edit-promo/edit-promo.component';
import { FundraisingComponent } from './components/fundraising/fundraising.component';
import { MenuComponent } from './components/nav/menu/menu.component';
import { VenueOperatorGuard } from './guards/venue-operator.guard';
import { WaitlistComponent } from './components/waitlist/waitlist.component';
import { CardRowComponent } from './credit-cards/card-row/card-row.component';
import { GoogleLoginComponent } from './auth/google-login/google-login.component';
import { GoogleService } from './auth/google.service';
import { LoginComponent } from './auth/login/login.component';
import { AppleLoginComponent } from './auth/apple-login/apple-login.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MinutesToLocalizedTimePipe } from './minutes-to-localized-time.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { AffiliatesComponent } from './components/affiliates/affiliates.component';
import { AdminGuard } from './guards/admin.guard';
import { EditAffiliateComponent } from './components/affiliates/edit-affiliate/edit-affiliate.component';
import { AffiliateDetailsComponent } from './components/affiliates/affiliate-details/affiliate-details.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AchAccountComponent } from './components/ach_account/ach_account.component';
import { PlaidComponent } from './components/plaid/plaid.component';
import { UserSearchComponent } from './components/utils/user-search/user-search.component';
import { EditVenueComponent } from './components/venue/edit-venue/edit-venue.component';
import { OfferHowToEarnComponent } from './offer-details/offer-how-to-earn/offer-how-to-earn.component';
import { OfferActionComponent } from './offer-details/offer-action/offer-action.component';
import { OfferClaimStepsComponent } from './offer-details/offer-claim-steps/offer-claim-steps.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { OfferShareComponent } from './components/offer-detail-info/offer-share/offer-share.component';
import { FaqComponent } from './components/faq/faq.component';
import { AboutComponent } from './components/about/about.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MapComponent } from './components/map/map.component';
import { OfferPopoverComponent } from './components/map/offer-popover/offer-popover.component';
import { environment } from 'src/environments/environment';
import { PatronBankAccountComponent } from './components/patron-bank-account/patron-bank-account.component';
import { PatronCreditCardsComponent } from './components/patron-credit-cards/patron-credit-cards.component';
import { EditOfferComponent } from './components/venue/edit-offer/edit-offer.component';
import { CharitySearchComponent } from './components/venue/charity-search/charity-search.component';
import { ContactComponent } from './components/contact/contact.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { BusinessComponent } from './components/business/business.component';
import { CharityComponent } from './components/charity/charity.component';
import { PatronEarningsComponent } from './components/patron-earnings/patron-earnings.component';
import { EarningsComponent } from './components/earnings/earnings.component';
import { EarningRowComponent } from './components/earnings/earning-row/earning-row.component';
import { ModalComponent } from './components/modal/modal.component';
import { MerchantDetailsComponent } from './components/venue/merchant-details/merchant-details.component';
import { VenueBankAccountComponent } from './components/venue/venue-bank-account/venue-bank-account.component';
import { VenueMerchantDetailsComponent } from './components/venue/venue-merchant-details/venue-merchant-details.component';
import { EditUserComponent } from './components/user/edit-user/edit-user.component';
import { VenueOperatorsComponent } from './components/venue/venue-operators/venue-operators.component';
import { OfferTile2Component } from './offers/offer-tile2/offer-tile2.component';
import { SelectRewardOfferComponent } from './offers/select-reward-offer/select-reward-offer.component';
import { AcceptInviteComponent } from './components/user/accept-invite/accept-invite.component';
import { ShareInviteComponent } from './components/user/share-invite/share-invite.component';
import { NavControlService } from './components/nav/nav-control.service';
import { FidelService } from './fidel.service';
import { VenueService } from './venue.service';
import { VenueOffersService } from './venue-offers.service';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
// import { ApolloClientOptions, ApolloLink, InMemoryCache, Observable } from '@apollo/client';
import { HttpLink } from 'apollo-angular/http';
// import { setContext } from '@apollo/client/link/context';
// import {onError} from '@apollo/client/link/error';
import { createApollo } from './graphql.module';
import { UserOffersService } from './services/user-offers.service';
import { PullDownCloseDirective } from './directives/pull-down-close.directive';
import { OfferFilterComponent } from './components/offer-filter/offer-filter.component';
import { TabBarComponent } from './components/nav/tab-bar/tab-bar.component';
import { AccountDetailsComponent } from './components/user/account-details/account-details.component';
import { SearchComponent } from './components/search/search.component';
import { OfferListComponent } from './components/offer-list/offer-list.component';
import { HeaderSliderComponent } from './components/nav/header-slider/header-slider.component';
import { AccountWidgetComponent } from './components/nav/account-widget/account-widget.component';
import { FadeToggleComponent } from './directives/fade-toggle/fade-toggle.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ClipboardModule } from 'ngx-clipboard';
import { CashOutWidgetComponent } from './components/nav/account-widget/cash-out-widget/cash-out-widget.component';
import { FacebookService } from './auth/facebook.service';
import { UserDetailsComponent } from './components/user/user-details/user-details.component';
import { ChatComponent } from './components/chat/chat.component';
import { NewlinePipe } from './pipes/newline.pipe';
import { SearchResultRowComponent } from './components/search/search-result-row/search-result-row.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export enum FetchState {
  NONE,
  PENDING,
  LOADING,
  ERROR,
  LOADED_ALL,
  NO_RESULTS,
  GOOD
}

@NgModule({
  declarations: [
    AppComponent,
    OfferDetailsComponent,
    OffersComponent,
    AppleComponent,
    AddressPipe,
    PhonePipe,
    NewlinePipe,
    NavHeaderComponent,
    OfferDetailInfoComponent,
    FooterComponent,
    SignUpComponent,
    HomeComponent,
    ClickOutsideDirective,
    VenueOffersComponent,
    VenueOfferDetailComponent,
    AvailableComponent,
    IncentivesComponent,
    PromosComponent,
    OfferTileComponent,
    VenueOfferDetailWrapperComponent,
    CreditCardsComponent,
    VenueDetailsComponent,
    VenuesComponent,
    EditPromoComponent,
    FundraisingComponent,
    MenuComponent,
    WaitlistComponent,
    CardRowComponent,
    GoogleLoginComponent,
    LoginComponent,
    AppleLoginComponent,
    MinutesToLocalizedTimePipe,
    LocalizedDatePipe,
    AffiliatesComponent,
    EditAffiliateComponent,
    AffiliateDetailsComponent,
    AchAccountComponent,
    PlaidComponent,
    UserSearchComponent,
    EditVenueComponent,
    OfferHowToEarnComponent,
    OfferActionComponent,
    OfferClaimStepsComponent,
    LoginPageComponent,
    OfferShareComponent,
    FaqComponent,
    AboutComponent,
    HowItWorksComponent,
    MapComponent,
    OfferPopoverComponent,
    PatronBankAccountComponent,
    PatronCreditCardsComponent,
    EditOfferComponent,
    CharitySearchComponent,
    ContactComponent,
    MainContainerComponent,
    BusinessComponent,
    CharityComponent,
    PatronEarningsComponent,
    EarningsComponent,
    EarningRowComponent,
    ModalComponent,
    MerchantDetailsComponent,
    VenueBankAccountComponent,
    VenueMerchantDetailsComponent,
    EditUserComponent,
    UserDetailsComponent,
    VenueOperatorsComponent,
    OfferTile2Component,
    SelectRewardOfferComponent,
    AcceptInviteComponent,
    ShareInviteComponent,
    PullDownCloseDirective,
    OfferFilterComponent,
    TabBarComponent,
    AccountDetailsComponent,
    SearchComponent,
    OfferListComponent,
    HeaderSliderComponent,
    AccountWidgetComponent,
    CashOutWidgetComponent,
    FadeToggleComponent,
    ChatComponent,
    SearchResultRowComponent,
  ],
  imports: [
    ApolloModule,
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgOptimizedImage,
    BrowserAnimationsModule,
    FormsModule,
    QRCodeModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.accessToken
    }),
    SlickCarouselModule,
    ClipboardModule

  ],
  bootstrap: [AppComponent],
  providers: [
    // AuthTokenService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      // deps: [HttpLink, AuthTokenService],
      deps: [HttpLink],
    },
    UserOffersService,
    AppleService,
    GoogleService,
    FacebookService,
    VenueOperatorGuard,
    AdminGuard,
    NavControlService,
    TranslateService,
    FidelService,
    VenueService,
    VenueOffersService,

    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
})
export class AppModule { }
