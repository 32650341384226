import { Injectable } from '@angular/core';
import { RemoveOfferMutation, UpsertOfferMutation } from './graphql/mutations/venue_offers.mutation.graphql';
import { FetchState } from './app.module';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { PageAndSortInput, SearchInput } from './graphql/types.graphql';
import { VenueOfferQuery, VenueOffersQuery } from './graphql/queries/offer.query.graphql';
import { VenueOffer } from './model/venue_offer.model';
import { FetchOfferImageDownloadUrl, FetchOfferImageUploadUrl } from './graphql/queries/venue-offer.query.graphql';

@Injectable({
  providedIn: 'root'
})
export class VenueOffersService {

  error: any;
  state: FetchState = FetchState.NONE;
  page: number = 0;
  pageSize: number = 10;
  venueOffers$: BehaviorSubject<VenueOffer[]> = new BehaviorSubject<VenueOffer[]>([]);
  selectedVenueOffer$: BehaviorSubject<VenueOffer> = new BehaviorSubject<VenueOffer>(null);

  venueOfferId: string = '';

  constructor(
    private readonly apollo: Apollo,
  ) {
  }

  private clear() {
    this._killCurrentFetchOffers();
    this.state = FetchState.NONE;
    this.venueOffers$.next([]);
    this.page = 0;
  }

  loadMore() {
    this.fetchOffers(this._venueId);
  }

  private _venueId: string = null;

  private _currentFetchOffersKillSwitch$: Subject<void> = new Subject<void>();
  private _killCurrentFetchOffers() {
    this._currentFetchOffersKillSwitch$.next();
    this._currentFetchOffersKillSwitch$.complete();
    this._currentFetchOffersKillSwitch$ = new Subject<void>();
  }
  fetchOffers(venueId: string, forceRefresh: boolean = false) {
    if (!forceRefresh && this.state === FetchState.LOADING) {
      return;
    }
    return this._fetchOffers(venueId, forceRefresh);
  }

  private _fetchOffersRetryCount: number = 0;
  private _fetchOffers(venueId: string, forceRefresh: boolean = false) {
    if (!venueId) {
      this.venueOffers$.next([]);
      return;
    }
    this._venueId = venueId;

    if (forceRefresh) {
      this.clear();
    }

    // Kill any current fetch
    this._killCurrentFetchOffers();

    if (this.state !== FetchState.GOOD && !forceRefresh) {
      return
    }

    this.state = FetchState.LOADING;

    const searchInputs: Array<SearchInput> = []

    const sort: PageAndSortInput = {
      page: this.page,
      pageSize: this.pageSize,
      sortFields: [{ sortAscending: true, sortField: "name" }]
    };

    // this._currentFetchOffers =
    this.apollo.query({
      query: VenueOffersQuery,
      variables: {
        id: venueId,
        search: searchInputs.map((searchInput) => {
          return {
            value: searchInput.value,
            searchFields: searchInput.searchFields,
            operator: searchInput.operator.toUpperCase()
          };
        }),
        sort: sort,
      },
      // fetchPolicy: 'network-only'
    })
    .pipe(
      takeUntil(this._currentFetchOffersKillSwitch$)
    )
    .subscribe({
      next: ({ data, loading }) => {
        console.log(data);
        if (data) {
          const dataOffers = data['offers'] ?? [];
          const venueOffers: Array<VenueOffer> = [].concat(this.venueOffers$.value);
          for (const offer of dataOffers) {
            if (offer) {
              venueOffers.push(VenueOffer.parseResponse(offer));
            }
          }
          this.venueOffers$.next(venueOffers);
          this.page += 1;
          this.state = (dataOffers.length === this.pageSize) ? FetchState.GOOD : FetchState.LOADED_ALL;
        }
        else {
          this.page += 1;
          this.state = FetchState.LOADED_ALL;
        }

        // If selectedVenueOffer is not set or is not in the set of venue offers, then set it to the first venue offer
        if (!this.selectedVenueOffer$.getValue() || !this.venueOffers$.getValue().includes(this.selectedVenueOffer$.getValue())) {
          if (this.venueOffers$.getValue().length > 0) {
            this.selectedVenueOffer$.next(this.venueOffers$.getValue()[0]);
          }
          else {
            this.selectedVenueOffer$.next(null);
          }
        }
      },
      error: (error) => {
        this._fetchOffersRetryCount += 1;

        // Implement retry logic with some sort of exponential backoff
        if (this._fetchOffersRetryCount < 5) {
          const timeoutTime = 150 * Math.pow(2, this._fetchOffersRetryCount);
          console.log(`Retrying fetch venue offers in ${timeoutTime}ms`);
          setTimeout(() => {
            this._fetchOffersRetryCount++;
            this._fetchOffers(venueId, forceRefresh);
          }, timeoutTime);
        }
        else {
          console.log(`error fetching offers: ${error}`);
          this.state = FetchState.ERROR;
        }
      }
    });
  }

  stateFetchOffer: FetchState = FetchState.GOOD;
  async fetchVenueOffer(venueOfferId: string): Promise<VenueOffer> {
    // if (this.stateFetchOffer !== FetchState.GOOD) {
    //   return null;
    // }

    this.stateFetchOffer = FetchState.LOADING;

    return new Promise<VenueOffer>((resolve, reject) => {
      this.apollo.query({
        query: VenueOfferQuery,
        variables: {
          id: venueOfferId
        },
      }).subscribe({
        next: ({ data, loading }) => {
          let newData: any = data;
          let venueOffer: VenueOffer = null;

          if (newData) {
            newData = newData.offer;
            console.log(newData);
            venueOffer = VenueOffer.parseResponse(newData);
            this.selectedVenueOffer$.next(venueOffer);
          }

          this.stateFetchOffer = FetchState.LOADED_ALL;
          resolve(venueOffer);
        },
        error: (error) => {
          console.log(error)
          this.error = error;
          this.stateFetchOffer = FetchState.ERROR;
          reject(error);
        }
      });
    });
  }

  fetchOfferImageUploadUrl(offerId: string, contentType: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.apollo.query({
        query: FetchOfferImageUploadUrl,
        variables: {
          id: offerId,
          contentType: contentType
        }
      }).subscribe({
        next: ({ data, loading }) => {
          let newData: any = data;
          if (newData) {
            newData = newData.fetchOfferImageUploadUrl;
            resolve(newData);
          }
          else {
            reject();
          }
        },
        error: (error) => {
          console.log(error)
          reject(error);
        }
      });
    });
  }

  fetchOfferImageDownloadUrl(offerId: string, forceRefresh: boolean = false): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.apollo.query({
        query: FetchOfferImageDownloadUrl,
        variables: {
          id: offerId,
          forceRefresh: forceRefresh
        }
      }).subscribe({
        next: ({ data, loading }) => {
          let newData: any = data;
          if (newData) {
            newData = newData.fetchOfferImageDownloadUrl;
            resolve(newData);
          }
          else {
            reject();
          }
        },
        error: (error) => {
          console.log(error)
          reject(error);
        }
      });
    });
  }

  //Business create new offers function
  upsertOfferState$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  async upsertOffer(venueOffer: VenueOffer): Promise<VenueOffer> {
    if (!venueOffer) {
      return Promise.reject();
    }
    if (this.upsertOfferState$.getValue() === FetchState.LOADING) {
      return Promise.reject();
    }

    this.upsertOfferState$.next(FetchState.LOADING);
    const request = venueOffer.toRequest();
    console.log(`upsertOffer`);
    console.dir(request);

    //GraphQL mutation
    return new Promise<VenueOffer>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: UpsertOfferMutation,
          variables: request
        }
      ).subscribe({
        next: ({ data, loading }) => {
          this.upsertOfferState$.next(FetchState.LOADED_ALL);
          const upsertedOffer = VenueOffer.parseResponse(data['upsertOffer']);
          // Add to list of offers, if not already in it
          const venueOffers: VenueOffer[] = this.venueOffers$.getValue();
          const index = venueOffers.findIndex((offer) => offer.id === upsertedOffer.id);
          if (index < 0) {
            venueOffers.push(upsertedOffer);
            this.venueOffers$.next(venueOffers);
          }

          resolve(upsertedOffer);
        },
        error: (error) => {
          this.upsertOfferState$.next(FetchState.ERROR);
          console.log(error);
          reject(error);
        }
      });
    });
  }

  //Business delete offers function
  deleteOfferState$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  async deleteOffer(venueOfferId: string): Promise<boolean> {
    if (!venueOfferId) {
      return Promise.reject();
    }
    if (this.deleteOfferState$.getValue() === FetchState.LOADING) {
      return Promise.reject();
    }

    this.deleteOfferState$.next(FetchState.LOADING);
    console.log(`deleteOffer`);

    //GraphQL mutation
    return new Promise<boolean>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: RemoveOfferMutation,
          variables: {
            id: venueOfferId
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          this.deleteOfferState$.next(FetchState.LOADED_ALL);
          console.log(data);

          // Remove this from the list of offers
          const venueOffers: VenueOffer[] = this.venueOffers$.getValue();
          const index = venueOffers.findIndex((offer) => offer.id === venueOfferId);
          if (index >= 0) {
            venueOffers.splice(index, 1);
            this.venueOffers$.next(venueOffers);
          }
          resolve(true);
        },
        error: (error) => {
          this.deleteOfferState$.next(FetchState.ERROR);
          console.log(error);
          reject(error);
        }
      });
    });
  }
}
