import { Component } from '@angular/core';

@Component({
  selector: 'app-offer-how-to-earn',
  templateUrl: './offer-how-to-earn.component.html',
  styleUrls: ['./offer-how-to-earn.component.css']
})
export class OfferHowToEarnComponent {
  claimSteps: any[] = [
    {
      step: 1,
      header: "Sign Up and Claim the Offer",
      text: "Sign in with Apple or Google and link the Credit Card you will use when you visit",
      path: "M40 0.9375C18.4264 0.9375 0.9375 18.4264 0.9375 40C0.9375 61.5736 18.4264 79.0625 40 79.0625C61.5736 79.0625 79.0625 61.5736 79.0625 40C79.0625 18.4264 61.5736 0.9375 40 0.9375ZM48.1367 59.4827H37.6395V29.3475H31.8633V20.5173H48.1367V59.4827Z"
    },
    {
      step: 2,
      header: "Visit and Pay with your Credit Card",
      text: "Pay with your debit or credit card",
      path: "M40 0.9375C18.4264 0.9375 0.9375 18.4264 0.9375 40C0.9375 61.5736 18.4264 79.0625 40 79.0625C61.5736 79.0625 79.0625 61.5736 79.0625 40C79.0625 18.4264 61.5736 0.9375 40 0.9375ZM54.4903 59.9798H25.5097V57.608C41.1875 39.1167 43.3469 36.1284 43.3469 32.7831C43.3469 30.6703 41.9548 29.2534 39.9591 29.2534C37.7716 29.2534 36.438 30.7288 36.438 33.0744V33.8281H26.1377V33.1073C26.1377 25.2287 31.3017 20.0209 39.8331 20.0209C48.0988 20.0209 53.8055 25.1028 53.8055 32.4183C53.8055 37.3216 51.9914 41.0856 43.6575 50.7392H54.4903V59.9798Z"
    },
    {
      step: 3,
      header: "Earn Your Donation",
      text: "We will notify you when you earn a cash back donation and send the donation automatically",
      path: "M40 0.9375C18.4264 0.9375 0.9375 18.4264 0.9375 40C0.9375 61.5736 18.4264 79.0625 40 79.0625C61.5736 79.0625 79.0625 61.5736 79.0625 40C79.0625 18.4264 61.5736 0.9375 40 0.9375ZM39.8367 59.9339C31.4631 59.9339 25.7067 54.4681 25.4725 46.4027H35.4739C35.5909 49.1495 37.2992 50.9748 39.8616 50.9748C42.525 50.9748 44.3928 49.0142 44.3928 46.1666C44.3928 43.3189 42.4572 41.5516 39.4248 41.5516H33.1834V39.3797L39.5284 28.948H26.4503V20.0661H53.1875V21.8563L45.3669 34.3761C50.9534 35.7777 54.5275 40.3 54.5275 46.3347C54.5275 54.2591 48.3863 59.9339 39.8367 59.9339Z"
    }
  ];

}
