<div class="w-full h-36 flex flex-row gap-0 rounded-xl overflow-hidden bg-white">
  <div class="main-image-container" [ngStyle]="{'background-image': 'url(' + offerImageUrl + ')'}">
    <div *ngIf="!offer" class="bg-gray-300 w-full rounded-l-xl h-full relative overflow-hidden">
      <div class="shimmer"></div>
    </div>
    <div *ngIf="!!offer" class="reward-container" [ngClass]="{'bg-charity-green': isCharityOffer, 'bg-patron-pink': !isCharityOffer}">
      <div *ngIf="!isCharityOffer" class="reward-text">
        <span *ngIf="!!offer?.patronIncentive?.intReturnPercentage" class="">
          {{offer?.patronIncentive?.intReturnPercentage / 100.0}}% {{ 'CASH_BACK' | translate }}
        </span>
        <span *ngIf="!!offer?.patronIncentive?.intReturnAmount" class="">
          {{(offer?.patronIncentive?.intReturnAmount / 100.0) | currency}} {{ 'CASH_BACK' | translate }}
        </span>
      </div>
      <div *ngIf="isCharityOffer" class="reward-text">
        <span *ngIf="!!offer?.charityIncentive?.intReturnPercentage" class="">
          {{offer?.charityIncentive?.intReturnPercentage / 100.0}}% {{ 'DONATION' | translate }}
        </span>
        <span *ngIf="!!offer?.patronIncentive?.intReturnAmount" class="">
          {{(offer?.patronIncentive?.intReturnAmount / 100.0) | currency}} {{ 'DONATION' | translate }}
        </span>
      </div>
    </div>

    <div *ngIf="(!isCharityOffer || subLogoImageUrl === logoImageUrl) && !!logoImageUrl" class="absolute bottom-2 left-1">
      <div class="back-shadow">
        <div *ngIf="logoImageLoaded" class="back-shadow-bg-dark"></div>
        <div class="back-shadow-contents">
          <img class="w-10 h-10 rounded-xl object-cover aspect-square " [ngClass]="{'bg-zinc-300': logoImageLoaded}"
            loading="lazy" [src]="logoImageUrl" (load)="onLogoImageLoad()">
        </div>
      </div>
    </div>
    <div *ngIf="!!isCharityOffer && subLogoImageUrl !== logoImageUrl" class="absolute bottom-4 left-1 flex justify-center items-center z-60 ">
      <div class="relative">
        <div *ngIf="!!logoImageUrl"
          class="absolute bottom-0 left-0 w-12 h-12 rounded-full flex justify-center items-center z-60 bg-zinc-300">
          <img class="w-12 h-12 rounded-full object-cover aspect-square" loading="lazy"
            [ngClass]="{'bg-zinc-300': logoImageLoaded}" [src]="logoImageUrl" (load)="onLogoImageLoad()">
        </div>
        <div *ngIf="!!subLogoImageUrl" class="back-shadow absolute -bottom-[10px] left-[30px] z-20">
          <div *ngIf="subLogoImageLoaded" class="back-shadow-bg"></div>
          <div class="back-shadow-contents">
            <img class="w-7 h-7 rounded-md object-cover aspect-square bg-stone-100" loading="lazy"
              [ngClass]="{'bg-zinc-300': subLogoImageLoaded}" [src]="subLogoImageUrl" (load)="onSubLogoImageLoad()">
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="main-content-container">
    <div class="flex flex-col justify-center items-start gap-1 w-full">
      <div class="flex flex-col justify-center items-start gap-0 w-full">
        <div class="title-text w-full">
          <div *ngIf="!offer" class="shimmer-container-light-gray-h-6">
            <div class="shimmer"></div>
          </div>
          {{offer?.name}}
        </div>
        <div class="title-text-md w-full">
          <div *ngIf="!offer" class="shimmer-container-light-gray-h-6">
            <div class="shimmer"></div>
          </div>
          <span *ngIf="offer?.charityIncentive && !offer?.charityIncentive.disabledDate" class="!text-charity-green-900">
            {{offer?.charityIncentive?.charity?.name || "TEST CHARITY"}}
          </span>
          <span *ngIf="offer?.charityIncentive && !offer?.charityIncentive.disabledDate">
            &#64;
          </span>
          {{offer?.venue?.name}}
        </div>
      </div>
      <div *ngIf="!rewardedOffer && !!offer?.claim" class="text-left text-md font-normal leading-normal w-full"
      [ngClass]="{'text-green-700': isCharityOffer, 'text-patron-pink': !isCharityOffer}">CLAIMED</div>
    </div>

    <div class="flex flex-row justify-start items-center w-full">
      <img *ngIf="!!offer" src="/assets/icons/zeals_logo_pink.svg" class="w-5 h-7 inline-block mr-1">
      <div *ngIf="!!offer && !!distance" class="justify-center items-start flex flex-col">
        <div class="text-black subtitle-text">{{distanceDisplay}} away</div>
        <div *ngIf="!!offerAddressCityState" class="subtitle-text">{{offerAddressCityState}}</div>
      </div>

    </div>
  </div>
</div>
