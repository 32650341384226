import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Subject, debounceTime, distinctUntilChanged, filter, fromEvent, merge, of, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { Affiliate, AffiliateOperator } from 'src/app/model/affiliate.model';
import { Operator, User } from 'src/app/model/user.model';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { UserService } from '../../../services/user.service';
import { NavControlService } from '../../nav/nav-control.service';

@Component({
  selector: 'app-affiliate-details',
  templateUrl: './affiliate-details.component.html',
  styleUrls: ['./affiliate-details.component.css']
})
export class AffiliateDetailsComponent implements OnInit, OnDestroy {
  affiliateId: string;
  affiliate: Affiliate;


  faCopy = faCopy;
  faTrash = faTrash;


  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    private readonly affiliateService: AffiliateService,
    private readonly userService: UserService,
    private readonly navControlService: NavControlService,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (params) => {
        this.affiliateId = params['affiliateId'];
        this.getAffiliateDetails();
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.affiliate.code).then(
      () => {
        console.log('Text successfully copied');
        // You can also show a tooltip or message saying "Copied!"
      },
      (err) => {
        console.error('Unable to copy text', err);
      }
    );
  }

  async getAffiliateDetails() {
    this.affiliate = await this.affiliateService.fetchById(this.affiliateId);
  }

  showEditAffiliate: boolean = false;
  editAffiliate() {
    this.showEditAffiliate = true;
  }

  closeModal() {
    this.showEditAffiliate = false;
  }

  handleClickOutsideModal() {
    this.closeModal();
  }


  ////////////////////////////
  // Operators
  ////////////////////////////

  get affiliateOperatorUsers(): User[] {
    return this.affiliate?.affiliateOperators?.map(ao => ao.user) ?? [];
  }

  async inviteAffiliateOperator(user: User): Promise<void> {
    await this.affiliateService.inviteAffiliateOperator(this.affiliate, user.id)
  }

  canRemoveAffiliateOperator(affiliateOperator: AffiliateOperator): boolean {
    // Can't remove if this is the only operator or if the current user is the operator
    return !this.removingAffiliateOperator && this.affiliate?.affiliateOperators?.length > 1 && affiliateOperator?.user?.id !== this.navControlService?.user?.id;
  }

  removingAffiliateOperator: AffiliateOperator;

  async removeAffiliateOperator(affiliateOperator: AffiliateOperator): Promise<void> {
    if (!this.canRemoveAffiliateOperator(affiliateOperator)) {
      return;
    }
    this.removingAffiliateOperator = affiliateOperator;
    await this.affiliateService.removeAffiliateOperator(this.affiliate, affiliateOperator.id)
  }
}
