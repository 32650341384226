import { gql } from "apollo-angular";

export const CharityDetails = gql`
fragment CharityDetails on Charity {
  id
  name
  description
  createdDate
  updatedDate
  logoUrl
  bannerUrl
}
`;
export const CharityPromotionDetails = gql`
fragment CharityPromotionDetails on CharityPromotion {
  id
  name
  disabledDate
  url
  medium {
    id
    name
    type
  }
}
`
export const CharityImageDetails = gql`
fragment CharityImageDetails on CharityImage {
  id
  name
  type
  dateAdded
  url
  urlExpiry
  charity {
    id
  }
}
`
