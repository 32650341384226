import { gql } from "apollo-angular";
import { MerchantDetails } from "../fragments/merchant.fragment.graphql";



export const UpsertMerchantMutation = gql`
${MerchantDetails}
mutation UpsertMerchant($id: String!, $merchantName: String!, $networkMerchantId: String!, $visaMerchantId: String, $mastercardMerchantId: String, $amexMerchantId: String, $sync: Boolean, $forceBrandNameUpdate: Boolean) {
  upsertMerchant(id: $id, merchantName: $merchantName, networkMerchantId: $networkMerchantId, visaMerchantId: $visaMerchantId, mastercardMerchantId: $mastercardMerchantId, amexMerchantId: $amexMerchantId, sync: $sync, forceBrandNameUpdate: $forceBrandNameUpdate) {
    ...MerchantDetails
  }
}
`;
