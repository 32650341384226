import { gql } from "apollo-angular";
import { PatronClaimDetails } from "../fragments/patron_claim.fragment.graphql";
import { RewardDetails } from "../fragments/reward.fragment.graphql";

export const UpsertClaimMutation = gql`
mutation UpsertClaim($offerViewId: String!, $claimedDate: Float) {
  upsertClaim(offerViewId: $offerViewId, claimedDate: $claimedDate) {
    ...PatronClaimDetails
  }
}
${PatronClaimDetails}
`;

export const RemoveClaimMutation = gql`
mutation RemoveClaim($id: String!) {
  removeClaim(id: $id)
}
`;

export const UpdateRewardOfferMutation = gql`
${RewardDetails}
mutation UpdateRewardOffer($id: String!, $offerId: String!) {
  updateRewardOffer(id: $id, offerId: $offerId) {
    ...RewardDetails
  }
}`;
