import { gql } from "apollo-angular";


export const UserInviteDetails = gql`
fragment UserInviteDetails on UserInvite {
  id
  fromUser {
    id
    firstName
    lastName
  }
  responseBy {
    id
    firstName
    lastName
    emails {
      value
    }
  }
  dateSent
  dateAccepted
  dateRejected
  toEmail
  toName
  createdDate
  updatedDate
  entityId
  token
  messageId
}
`;

export const UserInviteResponseDetails = gql`
fragment UserInviteResponseDetails on UserInviteResponse {
  success
  error
  entityId
  entityType
}
`;
