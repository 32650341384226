import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Observable, ReplaySubject, Subject, first, map, take, takeUntil, throwError } from 'rxjs';
import { SearchResults } from '../model/search_results.model';
// import { LocationService } from '../location.service';
import { FetchState } from '../app.module';
import { PatronOfferDetails } from '../graphql/fragments/patron_offer.fragment.graphql';
import { VenueDetails } from '../graphql/fragments/venue-details.fragment.graphql';
import { CharityDetails } from '../graphql/fragments/charity.fragment.graphql';
import { EventBusService, NavControlEventType } from './event-bus.service';
import { PatronClaim, PatronOffer } from '../model/patron-offer.model';
import { LoginState, UserAuthService } from '../user-auth.service';
import { FetchOffersOptions, OfferFilter } from '../offers.service';
import { OperatorType, PageAndSortInput, SearchInput } from '../graphql/types.graphql';
import { PatronOffersQuery } from '../graphql/queries/offer.query.graphql';
import { Entity } from '../model/entity.model';

export enum SearchOrderBy {
  PROXIMITY = 'proximity',
  RELEVANCE = 'relevance',
  CREATED_DATE = 'createdDate',
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

const SEARCH_QUERY = gql`
${PatronOfferDetails}
${VenueDetails}
${CharityDetails}
  query Search($text: String!, $page: Int, $pageSize: Int, $latitude: Float, $longitude: Float, $distance: Float, $units: String, $searchType: String, $tags: String, $orderBy: String, $sortDirection: String, $entityId: String) {
    search(text: $text, page: $page, pageSize: $pageSize, latitude: $latitude, longitude: $longitude, distance: $distance, units: $units, searchType: $searchType, tags: $tags, orderBy: $orderBy, sortDirection: $sortDirection, entityId: $entityId) {
      total
      page
      maxScore
      moreResults
      error
      results {
        id
        type
        label
        tags
        score
        locations {
          latitude
          longitude
        }
        entity {
          ... on Offer {
            ...PatronOfferDetails
          }
          ... on Venue {
            ...VenueDetails
          }
          ... on Charity {
            ...CharityDetails
          }
        }
      }
    }
  }
`;

export enum SearchType {
  OFFERS = 'offers',
  ALL = 'all',
}


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private readonly apollo: Apollo,
    // private readonly locationService: LocationService,
    private readonly eventBusService: EventBusService,
    private readonly userAuthService: UserAuthService,
  ) {
    this.userAuthService.state$.subscribe({
      next: (state) => {
        if ((state === LoginState.LOGGED_IN || state === LoginState.LOGGED_OUT) && (this._hasSearched || this._pendingSearch)) {
          this.search(this.searchText, this.page, this.pageSize, this.options, true);
        }
      }
    });

    this.eventBusService.eventSubject$.subscribe({
      next: (event) => {
        const searchResults = this._searchResults;
        switch (event.type) {
          case NavControlEventType.OFFER_CLAIMED:
            // Update the claim in the search results
            if (!searchResults) {
              return;
            }
            const claim = event.data as PatronClaim;
            if (!claim?.offer?.id) {
              return;
            }
            const claimIndex = searchResults.results.findIndex((result) => {
              return (result.entity instanceof PatronOffer) && result.id === claim.offer.id;
            });
            if (claimIndex === -1) {
              return;
            }
            const claimResult = searchResults.results[claimIndex];
            if (!claimResult || !(claimResult?.entity instanceof PatronOffer)) {
              return;
            }

            // Set the claim in the result
            claimResult.entity.claim = claim;
            break;

          case NavControlEventType.OFFER_UNCLAIMED:
            // Update the claim in the search results
            if (!searchResults) {
              return;
            }
            const unclaimedOfferId = event.data as string;
            if (!unclaimedOfferId) {
              return;
            }
            let result = searchResults.results.find((result) => {
              return (result.entity instanceof PatronOffer) && result.id === unclaimedOfferId;
            });
            if (!!result && !!result.entity) {
              result.entity['claim'] = null;
            }
            break;

          default:
            // Do nothing
            break;
        }
      }
    });

    this._entityIdFilter$
    .subscribe({
      next: (entityId) => {
        if (!!entityId) {
          this.apollo.query({
            query: SEARCH_QUERY,
            variables: {
              text: "",
              page: 0,
              entityId: entityId,
            },
            fetchPolicy: 'no-cache'
          }).pipe(
            // takeUntil(this._searchKillSwitch$),
            take(1),
            map((response: any) => {
              return SearchResults.parseResponse(response.data.search);
            }),
          )
          .subscribe({
            next: (searchResults) => {
              let entityFilter = this.entityFilter$.getValue();
              // first search result
              const result = searchResults.results[0];
              if (!!result && !!result.entity) {
                entityFilter = result.entity;
              }
              this.entityFilter$.next(entityFilter);
            },
            error: (error) => {
              console.error('search error', error);
              this.entityFilter$.error(error);
              return throwError(error);
            }
          });
        }
      }
    });
  }

  private _entityIdFilter$: BehaviorSubject<string> = new BehaviorSubject(null);
  entityFilter$: BehaviorSubject<Entity> = new BehaviorSubject(null);
  filters$: BehaviorSubject<Array<OfferFilter | string>> = new BehaviorSubject([OfferFilter.NEAR_ME]);

  private _searchResults: SearchResults;
  private _initialized: boolean = false;
  private _searchResults$: ReplaySubject<SearchResults> = new ReplaySubject<SearchResults>(1);
  get searchResults$(): Observable<SearchResults> {
    if (!this._initialized) {
      this._initialized = true;
      this.search(this.searchText, this.page, this.pageSize, this.options)
    }
    return this._searchResults$;
  }

  private updateSearchResults(searchResults: SearchResults, forceRefresh: boolean = false) {
    this._searchResults = searchResults;
    this._searchResults$.next(searchResults);
  }

  private _searchKillSwitch$: Subject<void> = new Subject<void>();

  private _killSearch() {
    this._searchKillSwitch$.next();
    this._searchKillSwitch$.complete();
    this._searchKillSwitch$ = new Subject<void>();
  }

  loadMore() {
    // If we have loaded all, then nothing to do
    if (this.state$.getValue() === FetchState.LOADED_ALL) {
      return;
    }
    this.search();
  }


  private _pendingSearch: boolean = false;
  private _hasSearched: boolean = false;
  state$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  searchText: string = '';
  page: number = 0;
  pageSize: number = 25;
  options: {useCurrentLocation?: boolean, distance?: number, units?: string, orderBy?: SearchOrderBy, sortDirection?: SortDirection} = {useCurrentLocation: true, distance: 50, units: 'mi', orderBy: SearchOrderBy.PROXIMITY, sortDirection: SortDirection.DESC};
  search(query?: string, page?: number, pageSize?: number, options?: {useCurrentLocation?: boolean, distance?: number, units?: string, orderBy?: SearchOrderBy, sortDirection?: SortDirection, filters?: Array<OfferFilter | string>, venueId?: string, searchType?: SearchType}, forceRefresh: boolean = false) {
    if (!!forceRefresh) {
      page = 0;
      pageSize = 25;
    }

    // let startTime: number = Date.now();
    if (query === undefined || query === null) {
      query = this.searchText || '';
    }
    if (page === undefined || page === null) {
      page = this.page || 0;
    }
    pageSize = pageSize || this.pageSize || 25;
    if (options === undefined || options === null) {
      options = this.options || {};
    }

    this.searchText = query;
    this.page = page;
    this.pageSize = pageSize;
    this.options = options;

    this._hasSearched = true;

    this.state$.next(FetchState.LOADING);

    // If we are logging in, then pend the search until the user is logged in
    if (this.userAuthService.state$.value === LoginState.LOGGING_IN || this.userAuthService.state$.value === LoginState.LOGGING_OUT) {
      console.log('PENDING SEARCH');
      this._pendingSearch = true;
      return;
    }
    if (this._pendingSearch) {
      console.log('RESUMING SEARCH');
      this._pendingSearch = false;
    }
    else {
      console.log('SEARCHING');
    }

    this._killSearch();

    if (!!options && options.filters) {
      this.filters$.next(options.filters);
    }
    const filters = this.filters$.getValue() ?? [];

    if (!!options && (!!options.venueId || filters.includes(OfferFilter.CLAIMED) || filters.includes(OfferFilter.RECENT))) {
      this._fetchOffers({
        filters: filters,
        venueId: options.venueId,
        forceRefresh
      });
    }
    else {
      if (!options) {
        options = {};
      }
      options.filters = options?.filters ?? this.filters$.getValue() ?? [];
      this._search(query, page, pageSize, options, forceRefresh)
      .subscribe({
        next: (searchResults) => {
          this.state$.next(FetchState.GOOD);
          this.updateSearchResults(searchResults, forceRefresh);
          return searchResults;
        },
        error: (error) => {
          console.error('search error', error);
          this.state$.next(FetchState.ERROR);
          this._searchResults$.error(error);
          return throwError(error);
        }
      });
    }
  }

  querySearch(query?: string, page?: number, pageSize?: number, options?: {useCurrentLocation?: boolean, distance?: number, units?: string, orderBy?: SearchOrderBy, sortDirection?: SortDirection, filters?: Array<OfferFilter | string>, venueId?: string, searchType?: SearchType}, forceRefresh: boolean = false): Observable<SearchResults> {
    if (!!forceRefresh) {
      page = 0;
      pageSize = 25;
    }

    // let startTime: number = Date.now();
    if (query === undefined || query === null) {
      query = '';
    }
    if (page === undefined || page === null) {
      page = 0;
    }
    pageSize = pageSize || this.pageSize || 25;
    if (options === undefined || options === null) {
      options = {};
    }

    // If we are logging in, then pend the search until the user is logged in
    if (this.userAuthService.state$.value === LoginState.LOGGING_IN || this.userAuthService.state$.value === LoginState.LOGGING_OUT) {
      console.log('PENDING SEARCH');
      this._pendingSearch = true;
      return new Observable<SearchResults>();
    }
    if (this._pendingSearch) {
      console.log('RESUMING SEARCH');
      this._pendingSearch = false;
    }
    else {
      console.log('SEARCHING');
    }

    return this._search(query, page, pageSize, options, forceRefresh)
  }

  private _search(query?: string, page?: number, pageSize?: number, options?: {useCurrentLocation?: boolean, distance?: number, units?: string, orderBy?: SearchOrderBy, sortDirection?: SortDirection, filters?: Array<OfferFilter | string>, venueId?: string, searchType?: SearchType}, forceRefresh: boolean = false): Observable<SearchResults> {
    const location = {
      distance: 500,
      units: 'mi',
    }
    // // Validate the distance
    // if (!location.distance) {
    //   location.distance = 50;
    // }
    // if (location.distance < 0) {
    //   location.distance = 1;
    // }
    // // Validate the units: 'mi' or 'km'
    // if (!location.units) {
    //   location.units = 'mi';
    // }
    // if (location.units !== 'mi' && location.units !== 'km') {
    //   location.units = 'mi';
    // }

    const filters = options?.filters ?? [];
    const searchTags = [];

    if (filters.includes(OfferFilter.CHARITY)) {
      searchTags.push("charity");
    }
    // put all uuid v4 filters into searchTags
    filters.forEach((filter) => {
      if (filter.match(/^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/)) {
        searchTags.push(filter);
      }
    });

    const variables = {
      text: query,
      page: page,
      pageSize: pageSize,
      distance: location.distance,
      units: location.units,
      orderBy: options.orderBy ?? SearchOrderBy.PROXIMITY,
      sortDirection: options.sortDirection ?? SortDirection.DESC,
      tags: searchTags.join(','),
    };

    if (!!options?.searchType) {
      variables['searchType'] = options.searchType;
    }

    return this.apollo.query({
      query: SEARCH_QUERY,
      variables,
      fetchPolicy: 'no-cache'
    })
    .pipe(
      take(1),
      map((response: any) => {
        // this.state$.next(FetchState.GOOD);
        return SearchResults.parseResponse(response.data.search);
      }),
    );
  }

  private _fetchOffersRetryCount: number = 0;
  private _fetchOffers(options: FetchOffersOptions = {}) {
    const sort: PageAndSortInput = {
      page: this.page,
      pageSize: this.pageSize,
      sortFields: []
    };

    let filters = options?.filters ?? [OfferFilter.ALL];

    const searchInputs: Array<SearchInput> = []
    // if (filters.indexOf(OfferFilter.CLAIMABLE) >= 0) {
    //   searchInputs.push({value: new Date().toISOString(), searchFields: ["claimableFromDate"], operator: OperatorType.EQUALS});
    //   searchInputs.push({value: new Date().toISOString(), searchFields: ["claimableToDate"], operator: OperatorType.EQUALS});
    // }
    // else
    if (filters.indexOf(OfferFilter.CLAIMED) >= 0) {
      searchInputs.push({value: "true", searchFields: ["claimedOnly"], operator: OperatorType.EQUALS});
    }
    if (filters.indexOf(OfferFilter.NEW) >= 0) {
      searchInputs.push({value: "7", searchFields: ["claimStartedWithinDays"], operator: OperatorType.EQUALS});
    }
    if (filters.indexOf(OfferFilter.CHARITY) >= 0) {
      searchInputs.push({value: "true", searchFields: ["charityOnly"], operator: OperatorType.EQUALS});
    }
    if (filters.includes(OfferFilter.RECENT)) {
      searchInputs.push({value: "true", searchFields: ["recentlyViewedOnly"], operator: OperatorType.EQUALS});
  }

    // Check for venue id
    if (options?.venueId) {
      searchInputs.push({value: options.venueId, searchFields: ["venue"], operator: OperatorType.EQUALS});
    }

    // Check available date
    if (options?.availableDate) {
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["claimableFromDate"], operator: OperatorType.EQUALS});
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["claimableToDate"], operator: OperatorType.EQUALS});
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["redeemableFromDate"], operator: OperatorType.EQUALS});
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["redeemableToDate"], operator: OperatorType.EQUALS});
    }

    const variables = {
      id: this.userAuthService.user?.id ?? '',
      search: searchInputs.map((searchInput) => {
        return {
          value: searchInput.value,
          searchFields: searchInput.searchFields,
          operator: searchInput.operator.toUpperCase()
          };
        }),
      sort: sort,
      latitude: null,
      longitude: null,
      radiusKm: null
    };

    this.state$.next(FetchState.LOADING);

    const rando = Math.random();
    console.log(`fetchOffers: ${rando}`);
    console.log('');

    this.apollo.query({
      query: PatronOffersQuery,
      variables,
      fetchPolicy: 'no-cache'
    })
    .pipe(
      take(1),
      map((response: any) => {
        this.state$.next(FetchState.GOOD);
        // const offers: PatronOffer[] = response.data?.offers?.map((offerData: any) => PatronOffer.parseResponse(offerData)) ?? [];
        return SearchResults.parseResponse({
          total: response.data?.offers?.length ?? 0,
          page: 0,
          maxScore: 0,
          moreResults: false,
          results: response.data?.offers?.map((offerData) => {
            return {
              id: offerData.id,
              type: 'Offer',
              label: offerData.name,
              score: 0,
              locations: [],
              tags: ['offer'],
              entity: offerData
            };
          })
        });
      }),
    )
    .subscribe({
      next: (searchResults) => {
        // const resultTime: number = Date.now() - startTime;
        // console.log(`Got search in ${resultTime}ms`);
        this.state$.next(FetchState.GOOD);
        this.updateSearchResults(searchResults, options?.forceRefresh);
        return searchResults;
      },
      error: (error) => {
        console.error('search error', error);
        this.state$.next(FetchState.ERROR);
        this._searchResults$.error(error);
        return throwError(error);
      }
    });
  }


  private _searchWithLocation(query: string, location: {latitude: number, longitude: number, distance: number, units: string}, page: number = 0, pageSize: number = 25, orderBy: SearchOrderBy = SearchOrderBy.PROXIMITY, sortDirection: SortDirection = SortDirection.DESC) {
    // const startTime: number = Date.now();
    // If no location, or location is invalid then get current location
    let locationIsValid: boolean = true;

    // Validate the location
    if (!location || !location.latitude || !location.longitude) {
      locationIsValid = false;
    }
    if (location.latitude < -90 || location.latitude > 90) {
      locationIsValid = false;
    }
    if (location.longitude < -180 || location.longitude > 180) {
      locationIsValid = false;
    }

    if (!locationIsValid) {
      throw new Error('Invalid location');
    }

    // Validate the distance
    if (!location.distance) {
      location.distance = 50;
    }
    if (location.distance < 0) {
      location.distance = 1;
    }
    // Validate the units: 'mi' or 'km'
    if (!location.units) {
      location.units = 'mi';
    }
    if (location.units !== 'mi' && location.units !== 'km') {
      location.units = 'mi';
    }

    this.state$.next(FetchState.LOADING);

    this._killSearch();

    this.apollo.query({
      query: SEARCH_QUERY,
      variables: {
        text: query,
        page: page,
        pageSize: pageSize,
        latitude: location.latitude,
        longitude: location.longitude,
        distance: location.distance,
        units: location.units,
        orderBy: orderBy,
        sortDirection: sortDirection,
      },
      fetchPolicy: 'no-cache'
    }).pipe(
      takeUntil(this._searchKillSwitch$),
      take(1),
      map((response: any) => {
        this.state$.next(FetchState.GOOD);
        return SearchResults.parseResponse(response.data.search);
      }),
    )
    .subscribe({
      next: (searchResults) => {
        // const resultTime: number = Date.now() - startTime;
        // console.log(`Got search in ${resultTime}ms`);
        this.state$.next(FetchState.GOOD);
        this.updateSearchResults(searchResults);
        return searchResults;
      },
      error: (error) => {
        console.error('search error', error);
        this.state$.next(FetchState.ERROR);
        this._searchResults$.error(error);
        return throwError(error);
      }
    });
  }


  selectFilters(filters: OfferFilter[]) {
    // Only update if the filters have changed.
    if (!filters || filters.length === 0 || filters.length !== this.filters$.getValue().length) {
      this.filters$.next(filters);
      this.search();
    }
    // Else if the contents of the filters have changed, then update
    else {
      for (const filter of filters) {
        if (this.filters$.getValue().indexOf(filter) < 0) {
          this.filters$.next(filters);
          this.search();
          break;
        }
      }
    }
    return;
  }

  selectFilter(filter: OfferFilter | string) {
    let filters = this.filters$.getValue();
    let currentEntityIdFilter = this._entityIdFilter$.getValue();
    // iterate backwards on array and splice the array when item is found
    switch (filter) {
      case OfferFilter.ALL:
        if (filters.indexOf(OfferFilter.ALL) > -1) {
          if (filters.indexOf(OfferFilter.CLAIMED) > -1) {
            filters = [OfferFilter.NEAR_ME, OfferFilter.CLAIMED];
          }
        }
        else {
          if (filters.indexOf(OfferFilter.CLAIMED) > -1) {
            filters = [OfferFilter.ALL, OfferFilter.CLAIMED];
          }
          if (filters.indexOf(OfferFilter.RECENT) > -1) {
            filters = [OfferFilter.ALL, OfferFilter.RECENT];
          }
        }
        break;
      case OfferFilter.NEAR_ME:
        if (filters.indexOf(OfferFilter.NEAR_ME) > -1) {
          filters.reverse().forEach((filter, index) => {
            // if filter contains NEAR_ME, remove it
            if (filter == OfferFilter.NEAR_ME) {
              filters.splice(index, 1);
            }
          });
        }
        else {
          filters.reverse().forEach((filter, index) => {
            // if filter contains ALL, remove it
            if (filter == OfferFilter.ALL) {
              filters.splice(index, 1);
            }
          })
          filters.push(OfferFilter.NEAR_ME);
          // remove RECENT and CLAIMED filters
          filters.reverse().forEach((filter, index) => {
            if (filter == OfferFilter.RECENT || filter == OfferFilter.CLAIMED) {
              filters.splice(index, 1);
            }
          });
        }
        break;
      case OfferFilter.RECENT:
        if (filters.indexOf(OfferFilter.RECENT) > -1) {
          filters.reverse().forEach((filter, index) => {
            // if filter contains RECENT, remove it
            if (filter == OfferFilter.RECENT) {
              filters.splice(index, 1);
            }
          })
        } else {
          filters = [OfferFilter.RECENT];
          currentEntityIdFilter = null;
        }
        break;
      case OfferFilter.CLAIMED:
        if (filters.indexOf(OfferFilter.CLAIMED) > -1) {
          filters.reverse().forEach((filter, index) => {
            // if filter contains CLAIMED, remove it
            if (filter == OfferFilter.CLAIMED) {
              filters.splice(index, 1);
            }
          })
        } else {
          filters = [OfferFilter.CLAIMED];
          currentEntityIdFilter = null;
        }
        break;
      case OfferFilter.NEW:
        if (filters.indexOf(OfferFilter.NEW) > -1) {
          filters.reverse().forEach((filter, index) => {
            // if filter contains NEW, remove it
            if (filter === OfferFilter.NEW) {
              filters.splice(index, 1);
            }
          })
        } else {
          filters.push(OfferFilter.NEW);
          filters.reverse().forEach((filter, index) => {
            // if filter contains ALL, remove it
            if (filter === OfferFilter.ALL) {
              filters.splice(index, 1);
            }
            else if (filter === OfferFilter.CLAIMED) {
              filters.splice(index, 1);
            }
            else if (filter === OfferFilter.RECENT) {
              filters.splice(index, 1);
            }
          });
        }
        break;
      case OfferFilter.CHARITY:
        if (filters.indexOf(OfferFilter.CHARITY) > -1) {
          filters.reverse().forEach((filter, index) => {
            // if filter contains CHARITY, remove it
            if (filter == OfferFilter.CHARITY) {
              filters.splice(index, 1);
            }
          })
        } else {
          filters.push(OfferFilter.CHARITY);
          filters.reverse().forEach((filter, index) => {
            // if filter contains ALL, remove it
            if (filter === OfferFilter.ALL) {
              filters.splice(index, 1);
            }
            else if (filter === OfferFilter.CLAIMED) {
              filters.splice(index, 1);
            }
            else if (filter === OfferFilter.RECENT) {
              filters.splice(index, 1);
            }
          });
        }
        break;
      default:
        // if this is a valid uuid v4, then add it to filters$
        if (filter.match(/^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/)) {
          let filterIndex = filters.indexOf(filter);
          if (filterIndex >= 0) {
            filters.splice(filterIndex, 1);
            currentEntityIdFilter = null;
          }
          else {
            filters.push(filter);
            // remove claimed and recent filters
            filters.reverse().forEach((filter, index) => {
              if (filter === OfferFilter.RECENT || filter === OfferFilter.CLAIMED) {
                filters.splice(index, 1);
              }
            });
          }

          currentEntityIdFilter = filter;
        }
        break;
    }

    let oldEntityIdFilter = this._entityIdFilter$.getValue();
    if (currentEntityIdFilter !== oldEntityIdFilter) {
      this._entityIdFilter$.next(currentEntityIdFilter);

      // remove all filters that are entity id filters
      for(let i = filters.length - 1; i >= 0; i--) {
        if (filters[i] === currentEntityIdFilter) {
          continue;
        }
        if (filters[i].match(/^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/)) {
          filters.splice(i, 1);
        }
      }
    }

    // make sure all entity id filters are in filters$
    if (!!currentEntityIdFilter) {
      if (!filters.includes(currentEntityIdFilter)) {
        filters.push(currentEntityIdFilter);
      }
    }

    this.filters$.next(filters);
    if (this.state$.getValue() !== FetchState.NONE) {
      this.search();
    }
  }

}


