import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(date: Date | string): string {
    if (!date) {
      return '';
    }

    const currentLang = this.translateService.currentLang;
    moment.locale(currentLang);

    const now = moment();
    const inputDate = moment(date);

    if (inputDate.isSame(now, 'day')) {
      return this.translateService.instant('DATES.TODAY');
    } else if (inputDate.isSame(moment(now).add(1, 'day'), 'day')) {
      return this.translateService.instant('DATES.TOMORROW');
    } else if (inputDate.isSame(moment(now).subtract(1, 'day'), 'day')) {
      return this.translateService.instant('DATES.YESTERDAY');
    } else {
      return inputDate.format('LL');
    }
  }
}
