import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, take, takeUntil } from 'rxjs';
import { FetchState } from 'src/app/app.module';
import { OfferFilter } from 'src/app/offers.service';
import { BusinessType } from 'src/app/services/waitlist.service';
import { TranslateService } from '@ngx-translate/core';
import { NavControlService } from '../nav/nav-control.service';
import { LoginState } from 'src/app/user-auth.service';
import { User } from 'src/app/model/user.model';
import { PatronOffer } from 'src/app/model/patron-offer.model';
import { SearchService } from 'src/app/services/search.service';
import { OfferData } from 'src/app/offers/offers.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  offers: OfferData[] = [{},{},{},{},{}];
  offersServiceState: FetchState;
  loggedIn: boolean = false;

  // Carousel settings
  slideConfig = {
    variableWidth: false,
    draggable: true,
    focusOnSelect: true,
    arrows: true,
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "dots": true,
    "autoplay": false,
    "autoplaySpeed": 3000,
    "infinite": true,
    "responsive": [
      {
        "breakpoint": 1536,
        "settings": {
          "slidesToShow": 5,
          "slidesToScroll": 1,
          "infinite": true,
          "dots": true
        }
      },
      {
        "breakpoint": 1280,
        "settings": {
          "slidesToShow": 4,
          "slidesToScroll": 1,
          "infinite": true,
          "dots": true
        }
      },
      {
        "breakpoint": 1024,
        "settings": {
          "slidesToShow": 3,
          "slidesToScroll": 1,
          "infinite": true,
          "dots": true
        }
      },
      {
        "breakpoint": 768,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 1
        }
      },
      {
        "breakpoint": 480,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1
        }
      }
    ]
  };

  categories: any[] = [
    { label: "Restaurants, Dining, Bistros", imagePath: "../../../assets/images/Category1.jpg" },
    { label: "Cafes, Coffee, Deli", imagePath: "../../../assets/images/Category2.jpg" },
    { label: "Breweries, Wineries", imagePath: "../../../assets/images/Category3.jpeg" },
    { label: "Bars, Lounges, Pubs", imagePath: "../../../assets/images/Category4.jpeg" }
  ];
  user: User;

  FetchState = FetchState;
  LoginState = LoginState;


  public getScreenWidth: any;

  businessType: BusinessType = BusinessType.Business;

  hasCachedToken$: BehaviorSubject<boolean> = this.navControlService.hasCachedToken$;

  constructor(
    private elementRef: ElementRef,
    private readonly translate: TranslateService,
    private readonly navControlService: NavControlService,
    private readonly searchService: SearchService,
  ) {
    this.getScreenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.searchService.searchResults$.pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      next: (searchResults) => {
        if (searchResults) {
          const newOffers = searchResults.results.map((result) => {
            if (result.entity instanceof PatronOffer) {
              return result.entity;
            }
            return null;
          })
          .filter((offer) => !!offer);

          OfferData.updateOfferDataArray(this.offers, newOffers);
        }
      }
    });

    this.searchService.state$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (state) => {
        this.offersServiceState = state;
      }
    });

    this.hasCachedToken$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (hasCachedToken) => {
        this.dispatchWelcomeTexts();
      }
    });

    this.navControlService.userState$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (userState) => {
        // this.dispatchWelcomeTexts();
        if (userState === LoginState.LOGGED_IN) {
          this.loggedIn = true;
        }
        else if (userState === LoginState.LOGGED_OUT) {
          this.loggedIn = false;
        }
      }
    });

    this.navControlService.user$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (user) => {
        this.user = user;
      }
    });
  }

  dispatchWelcomeTexts() {
    if (!!this.hasCachedToken$.getValue()) {
      this.translate.get('HOME_PAGE_TITLE_MESSAGE_LOGGED_IN').pipe(take(1)).subscribe({
        next: (text) => {
          this.welcomeHeader.next(text);
        }
      });
      this.translate.get('HOME_PAGE_SUBTITLE_MESSAGE').pipe(take(1)).subscribe({
        next: (text) => {
          this.welcomeSubHeader.next(text);
        }
      });
      // this.welcomeSubHeader.next(this.translate.instant('HOME_PAGE_SUBTITLE_MESSAGE_LOGGED_IN'));
    }
    else {
      const currentState = this.navControlService.userState;
      this.translate.get('HOME_PAGE_TITLE_MESSAGE').pipe(take(1)).subscribe({
        next: (text) => {
          this.welcomeHeader.next(text);
        }
      });
      this.translate.get('HOME_PAGE_SUBTITLE_MESSAGE').pipe(take(1)).subscribe({
        next: (text) => {
          this.welcomeSubHeader.next(text);
        }
      });
    }
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
      this.navControlService.collapseNavHeader();
  }

  welcomeHeader: BehaviorSubject<string> = new BehaviorSubject<string>('');
  welcomeSubHeader: BehaviorSubject<string> = new BehaviorSubject<string>('');

  handleMainButtonClick() {
    if (!!this.user) {
      this.navControlService.goToOffers([OfferFilter.NEAR_ME]);
    }
    else {
      this.navControlService.goToLogin();
    }
  }


  scrollToElement(): void {
    const element = this.elementRef.nativeElement.querySelector('#howItWorks');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }



  scrollToWaitlist() {
    document.getElementById("waitlist").scrollIntoView({ behavior: "smooth" });
  }

  goToCharityLanding() {
    this.navControlService.goToCharityLanding();
  }

  goToBusinessLanding() {
    this.navControlService.goToBusinessLanding();
  }

  goToOffers() {
    this.navControlService.goToOffers([OfferFilter.NEAR_ME]);
  }

  goToOffer(offer: PatronOffer) {
    if (!this.isDragging) {
      this.navControlService.goToOffer(offer);
    }
  }

  /**
   * Mouse drag detection for offer tiles
   */
  private isMouseDown = false;
  private isDragging = false;
  private dragStartX = 0;
  private maxMouseDrag = 5;
  @HostListener('mousedown', ['$event'])
  onMousedown(event: MouseEvent): void {
    this.isMouseDown = true;
    this.dragStartX = event.clientX;
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent): void {
    if (this.isMouseDown && Math.abs(event.clientX - this.dragStartX) > this.maxMouseDrag) {
      this.isDragging = true;
    }
  }

  @HostListener('mouseup', ['$event'])
  onMouseup(event: MouseEvent): void {
    setTimeout(() => {
      this.isMouseDown = false;
      this.isDragging = false;
    }, 1);
  }

}
