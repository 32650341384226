
export enum OperatorType {
  EQUALS = 'equals',
  NOT_EQUALS = 'not_equals',
  GREATER_THAN = "greater_than",
  GREATER_THAN_OR_EQUAL = "greater_than_or_equal",
  LESS_THAN = "less_than",
  LESS_THAN_OR_EQUAL = "less_than_or_equal",
  LIKE = "like",
  NOT_LIKE = "not_like",
  IS_NULL = "IS_NULL",
  IS_NOT_NULL = "IS_NOT_NULL",
}

export type SearchInput = {
  value: string,
  searchFields: Array<string>,
  operator: OperatorType
};

export type SortFieldInput = {
  sortAscending?: boolean
  sortField: string
};

export type PageAndSortInput = {
  page?: number,
  pageSize?: number,
  sortFields: Array<SortFieldInput>
};
