<!-- This is meant to be a modal that allows the user to type in email address(es) to send an invite to. The invite also includes an optional message. -->
<form class="bg-white p-8 relative flex flex-col h-full w-full min-h-max" [formGroup]="shareForm" (submit)="save()">
  <div class="flex flex-row justify-between">
    <h2 class="text-2xl mb-6 text-zinc-900">Send Invite </h2>
    <div class=" text-zinc-900 cursor-pointer">
      <fa-icon [icon]="FaClose" (click)="handleClose()"></fa-icon>
    </div>
  </div>

    <div class="flex flex-col flex-grow space-y-2 overflow-y-auto">
      <div class="flex flex-col flex-grow">
        <label class="text-sm text-grey-grey-5" for="name">Email</label>
        <input id="emails" formControlName="email" type="email" class="border rounded p-2 text-grey-grey-8" />
        <div *ngIf="shareForm.controls['email']?.invalid && shareForm.controls['email']?.touched" class="text-red-500 text-sm">Valid email is required</div>
      </div>
      <div class="flex flex-col flex-grow" *ngIf="allowMessage">
        <label class="text-sm text-grey-grey-5" for="description">Message (optional)</label>
        <textarea id="message" formControlName="message" class="border rounded p-2 text-grey-grey-8 row-span-3"></textarea>
        <div *ngIf="shareForm.controls['message']?.invalid && shareForm.controls['share']?.touched" class="text-red-500 text-sm">Message is invalid</div>
      </div>
    </div>

    <div class="flex items-center space-x-4 justify-between pt-6 flex-wrap">
      <button type="submit" class="button-zinc-900 px-6 py-2">Send</button>
    </div>

    <!-- Show Error Here -->
    <div *ngIf="saveState === FetchState.ERROR" class="text-red-500 mt-6">
      {{ errorMessage || "Error saving, please try again." }}
    </div>

  <!-- Loading Cover -->
  <div *ngIf="saveState === FetchState.LOADING" class="flex justify-center items-center h-full absolute inset-0 bg-gray-100 bg-opacity-25">
    <div class="loader-wrapper flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  </div>
</form>
