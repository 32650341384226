import { gql } from "apollo-angular";
import { PatronClaimDetails } from "./patron_claim.fragment.graphql";
import { CharityDetails } from "./charity.fragment.graphql";
import { OfferImageDetails } from "./offer_image.fragment.graphql";

export const PatronOfferDetails = gql`
fragment PatronOfferDetails on Offer {
  id
  name
  description
  finePrint
  redeemStartDate
  redeemEndDate
  archivedDate
  claimStartDate
  claimEndDate
  startTimeOfDay
  endTimeOfDay
  daysOfTheWeek
  weeksOfTheMonth
  patronsLimit
  singlePatronLimit
  isUniversal
  showInApp
  claim {
    ...PatronClaimDetails
  }
  platformIncentive {
    disabledDate
    promotions {
      id
    }
  }
  venueIncentive {
    disabledDate
    promotions {
      id
    }
  }
  patronIncentive {
    id
    intReturnPercentage
    intReturnAmount
    intMinSpend
    intMaxReward
    disabledDate
  }
  charityIncentive {
    id
    intReturnPercentage
    intReturnAmount
    intMinSpend
    intMaxReward
    disabledDate
    charity {
      ...CharityDetails
    }
  }
  venue {
    id
    name
    description
    venueAddresses {
      id
      street
      street2
      city
      state
      zip
      country
      phone
      geoCoordinate {
        latitude
        longitude
      }
    }
    venueImages {
      id
      name
      type
      dateAdded
      url
      urlExpiry
    }
  }
  venueUrl
  offerImage {
    ...OfferImageDetails
  }
}
${PatronClaimDetails}
${CharityDetails}
${OfferImageDetails}
`;
