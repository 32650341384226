<div class="bg-white p-8 relative flex flex-col h-full w-full flex-grow">
  <button class="absolute text-red-500 right-4 top-4" (click)="closeModal()">Close</button>
  <h2 class="text-2xl mb-6 text-grey-grey-5">Edit Merchant</h2>

  <form *ngIf="!!merchant && !!merchantForm" [formGroup]="merchantForm" (submit)="saveMerchant()" class="flex flex-col flex-grow h-full w-full overflow-y-auto">
    <div class="flex flex-col flex-grow space-y-2">
      <div *ngIf="isLive || isActive" class="text-red-500 mt-6">
        Merchant is already live/active. Please contact us to make changes.
      </div>
      <div class="flex flex-row space-x-4 items-end flex-wrap">
        <div class="flex flex-col flex-grow">
          <label class="text-sm text-grey-grey-5" for="merchantName">Merchant Name</label>
          <input formControlName="merchantName" type="text" class="border rounded p-2 text-xl text-grey-grey-8" />
          <div *ngIf="merchantForm.controls['merchantName']?.invalid && merchantForm.controls['merchantName']?.touched" class="text-red-500 text-sm">Merchant Name is required</div>
        </div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5">Network Merchant ID</label>
        <input formControlName="networkMerchantId" type="text" class="border rounded p-2 text-grey-grey-8" />
        <div *ngIf="merchantForm.controls['networkMerchantId']?.invalid && merchantForm.controls['networkMerchantId']?.touched" class="text-red-500 text-sm">Network Merchant ID is invalid</div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5">VISA Merchant ID</label>
        <input formControlName="visaMerchantId" type="text" class="border rounded p-2 text-grey-grey-8" />
        <div *ngIf="merchantForm.controls['visaMerchantId']?.invalid && merchantForm.controls['visaMerchantId']?.touched" class="text-red-500 text-sm">VISA Merchant ID is invalid</div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5">MasterCard Merchant ID</label>
        <input formControlName="mastercardMerchantId" type="text" class="border rounded p-2 text-grey-grey-8" />
        <div *ngIf="merchantForm.controls['mastercardMerchantId']?.invalid && merchantForm.controls['mastercardMerchantId']?.touched" class="text-red-500 text-sm">MasterCard Merchant ID is invalid</div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5">AMEX Merchant ID</label>
        <input formControlName="amexMerchantId" type="text" class="border rounded p-2 text-grey-grey-8" />
        <div *ngIf="merchantForm.controls['amexMerchantId']?.invalid && merchantForm.controls['amexMerchantId']?.touched" class="text-red-500 text-sm">AMEX Merchant ID is invalid</div>
      </div>
      <div class="flex flex-col">
        <label class="relative inline-flex items-center mr-5 ml-1 cursor-pointer">
          <input formControlName="sync" type="checkbox" class="text-grey-grey-8 sr-only peer" />
          <div
            class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-business-blue">
          </div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-900">Sync</span>
        </label>
      </div>
    </div>

    <div class="flex items-center space-x-4 justify-between pt-6 flex-wrap">
      <div class="flex items-center space-x-4 flex-wrap">
        <button type="button" class="text-business-blue" (click)="resetForm()">Undo/Reset</button>
      </div>
      <button [disabled]="isActive || isLive" type="submit" class="button-business-blue px-6 py-2" [disabled]="merchantForm.invalid">Save</button>
    </div>

    <!-- Show Error Here -->
    <div *ngIf="saveState === FetchState.ERROR" class="text-red-500 mt-6">
      Error saving, please try again.
    </div>
  </form>

  <!-- Loading Cover -->
  <div *ngIf="saveState === FetchState.LOADING" class="flex justify-center items-center h-full absolute inset-0 bg-gray-100 bg-opacity-25">
    <div class="loader-wrapper flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  </div>
</div>
