import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Map } from 'mapbox-gl';
import { Subject, takeUntil } from 'rxjs';
import { LocationService } from 'src/app/location.service';
import { PatronOffer } from 'src/app/model/patron-offer.model';
import { NavControlService } from '../nav/nav-control.service';
import { UserOffersService } from 'src/app/services/user-offers.service';

export interface IPlace {
  id: string;
  name: string;
  address: string;
  phone: string;
  latitude: number;
  longitude: number;
  // distance: number;
}


export interface IPlaceOffer extends IPlace {
  offer: PatronOffer;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnDestroy {
  private _places: IPlace[] = [];
  @Input()
  set places(places: IPlace[]) {
    this._places = (places ?? []).filter((place) => place.latitude && place.longitude);
    if (places?.length > 0) {
      this._place = places[0];
    }

    this.showCurrentOffer();
  }
  get places(): IPlace[] {
    return this._places;
  }

  @ViewChild('map') map: ElementRef;
  private _mapInstance: Map;
  get mapInstance(): Map {
    return this._mapInstance;
  }
  set mapInstance(value: Map) {
    this._mapInstance = value;
    this.updateCurrentLocation(null, this._currentLocation);
  }


  constructor(
    private readonly locationService: LocationService,
    private readonly navControlService: NavControlService,
    private readonly userOffersService: UserOffersService,
  ) {
  }

  ngOnInit(): void {
    this.locationService.location$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (location) => {
        this.location = location;
        if (!this.currentLocation) {
          this.currentLocation = location;
        }
      }
    });

    this.userOffersService.offer$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (offerData) => {
        if (offerData) {
          this.showCurrentOffer();
        }
      }
    });

    this.navControlService.highlightedOfferId$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (offerId) => {
        if (offerId) {
          this.showCurrentOffer(offerId);
        }
      }
    });
  }

  private _place: IPlace;
  isHighlighted(place: IPlace): boolean {
    if (this._place === place) {
      return window.innerWidth > 768;
    }
    return false;
  }

  private destroy$: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get firstLocation(): [number, number] {
    let place = this.places?.[0];
    if (!place || !place.latitude || !place.longitude) {
      if (this.location?.latitude && this.location?.longitude) {
        return [this.location?.longitude, this.location?.latitude];
      }
      return null;
    }
    return [place.longitude, place.latitude];
  }

  onMapLoad(mapInstance: Map) {
    this.mapInstance = mapInstance;

    if (!!this.currentLocation?.latitude && !!this.currentLocation?.longitude) {
      // Move the map to this location without any animation
      this.mapInstance.jumpTo({
        center: [this.currentLocation.longitude, this.currentLocation.latitude],
        zoom: 12,
      });
    }
    // this.addCustomMarkers();

    this.showCurrentOffer();
  }

  showCurrentOffer(offerId: string = null) {
    // If the current offer is in our list of offers, then go to it
    const currentOfferData = offerId || this.userOffersService.selectedOfferData;
    let currentOfferId: string = null;
    if (currentOfferData instanceof PatronOffer) {
      currentOfferId = currentOfferData.id;
    }
    else {
      currentOfferId = currentOfferData;
    }
    let place: IPlace;
    if (currentOfferId) {
      place = this.places?.find((place) => place['offer']?.id === currentOfferId);
    }
    else {
      // If there is no current offer, then go to the first offer in the list
      place = this.places?.[0];
    }
    if (place) {
      this._place = place;
      this.zoomToPlace(place);
      // this.openPopup(place);
    }
  }

  private _pendingLoad: boolean = false;

  handlePopupClick($event) {
    console.log('handlePopupClick', $event);
  }

  private _currentLocation: { latitude: number; longitude: number };
  set currentLocation(value: { latitude: number; longitude: number }) {
    const oldValue = this._currentLocation;
    this._currentLocation = value;
    this.updateCurrentLocation(oldValue, value);
  }
  location: { latitude: number; longitude: number };

  private updateCurrentLocation(oldValue: { latitude: number; longitude: number }, value: { latitude: number; longitude: number }) {
    if (!value?.latitude || !value?.longitude) {
      return;
    }
    if (!!this.mapInstance && !!value?.latitude && !!value?.longitude) {
      if (oldValue?.latitude === value?.latitude || oldValue?.longitude === value?.longitude) {
        // Nothing to do
        return;
      }

      if (!!oldValue?.latitude && !!oldValue?.longitude) {
        this.mapInstance.flyTo({
          center: [value.longitude, value.latitude],
          minZoom: 12,
        });
      }
      else {
        this.mapInstance.jumpTo({
          center: [value.longitude, value.latitude],
          zoom: 12,
        });
      }
    }
  }

  get mapZoom(): number {
    return this.mapInstance?.getZoom() ?? -1;
  }

    zoomToPlace(place: IPlace) {
      if (!!this.mapInstance) {
        if (this.mapInstance.getBounds().getNorth() < place.latitude + 0.001 ||
            this.mapInstance.getBounds().getSouth() > place.latitude - 0.001 ||
            this.mapInstance.getBounds().getEast() < place.longitude + 0.001 ||
            this.mapInstance.getBounds().getWest() > place.longitude - 0.001) {
          this.currentLocation = { latitude: place.latitude, longitude: place.longitude };
        }
      }
    }

    placeIsOpen: IPlace;
    openPopup(place) {
      if (!place || !place.latitude || !place.longitude) return;
      if (!!this.mapInstance) {
        // Fly to the place if it's not already in view
        // Use a small buffer on the edges to avoid the popup being cut off
        if (this.mapInstance.getBounds().getNorth() < place.latitude + 0.001 ||
            this.mapInstance.getBounds().getSouth() > place.latitude - 0.001 ||
            this.mapInstance.getBounds().getEast() < place.longitude + 0.001 ||
            this.mapInstance.getBounds().getWest() > place.longitude - 0.001) {
          this.currentLocation = { latitude: place.latitude, longitude: place.longitude };
        }
      }
      setTimeout(() => {
        this.placeIsOpen = place;
      }, 1);
    }

    closePopup(place) {
      if (this.placeIsOpen === place) {
        this.placeIsOpen = null;
      }
    }
    isOpen(place) {
      return this.placeIsOpen === place;
    }

    isCharity(offer: any): boolean {
      return !!offer?.offer?.charityIncentive && !offer?.offer?.charityIncentive?.disabledDate;
    }

    get place(): IPlace {
      return this._place;
    }

    placeAmount(place: IPlace): number {
      let result: number = 0;
      if (place?.hasOwnProperty('offer')) {
        if (this.isCharity(place)) {
          result = place['offer']?.charityIncentive?.intReturnAmount / 100.0;
        }
        else {
          result = place['offer']?.patronIncentive?.intReturnAmount / 100.0;
        }
      }
      return result;
    }
    placePercentage(place: IPlace): number {
      let result: number = 0;
      if (place?.hasOwnProperty('offer')) {
        if (this.isCharity(place)) {
          result = place['offer']?.charityIncentive?.intReturnPercentage / 100.0;
        }
        else {
          result = place['offer']?.patronIncentive?.intReturnPercentage / 100.0;
        }
      }
      return result;
    }

    placeIsDuplicate(place: IPlace): boolean {
      // If _place is set and it is not the same as the place but does have the same latitude and longitude, then this is a duplicate
      return !!this._place && this._place !== place && this._place.latitude === place.latitude && this._place.longitude === place.longitude;
    }

    goToOffer(offer) {
      this.navControlService.goToOffer(offer);
    }

    goToPlace(place) {
      if (place.hasOwnProperty('offer')) {
        this.goToOffer(place['offer']);
      }
      else {
        this.zoomToPlace(place);
        // this.openPopup(place);
      }
    }
}
