import { gql } from "apollo-angular";
import { VenueImageDetails } from "./venue-image.fragment.graphql";
import { CharityImageDetails } from "./charity.fragment.graphql";
import { AchAccountDetails } from "./ach_account.fragment.graphql";
export const UserPhoneDetails = gql`
fragment UserPhoneDetails on UserPhone {
    id
    value
}
`
export const UserEmailDetails = gql`
fragment UserEmailDetails on UserEmail {
    id
    value
}
`
export const UserImageDetails = gql`
fragment UserImageDetails on UserImage {
    id
    name
    type
    dateAdded
    url
    urlExpiry
}
`
export const UserDetails = gql`
fragment UserDetails on User {
  id
  firstName
  lastName
  userImage {
    ...UserImageDetails
  }
  emails {
    ...UserEmailDetails
  }
  phones {
    ...UserPhoneDetails
  }
  patron {
    id
    activated
    deactivated
  }
  operator {
    id
    activated
    deactivated
  }
  userAdmin {
    id
    activated
    deactivated
  }
}
${UserPhoneDetails}
${UserEmailDetails}
${UserImageDetails}
`
export const FullUserDetails = gql`
${AchAccountDetails}
fragment FullUserDetails on User {
    id
    createdDate
    firstName
    lastName
    userImage {
      ...UserImageDetails
    }
    emails {
      ...UserEmailDetails
    }
    # paymentMethods {
    #   ...UserPaymentMethodDetails
    # }
    phones {
      ...UserPhoneDetails
    }
    patron {
      id
      activated
      deactivated
      achAccount {
        ...AchAccountDetails
      }
    }
    operator {
      id
      activated
      deactivated
      operatesVenues {
        id
        venue {
          id
          name
          venueImages {
            ...VenueImageDetails
          }
        }
      }
      operatesCharities {
        id
        charity {
          id
          name
          description
          charityImages {
            ...CharityImageDetails
          }
        }
      }
    }
    userAdmin {
      id
      activated
      deactivated
    }
}
${UserPhoneDetails}
${UserEmailDetails}
${UserImageDetails}
${VenueImageDetails}
${CharityImageDetails}
`



