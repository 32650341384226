import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(number, ...args: unknown[]): string {
    if (!number) {
      return '';
    }

    const value = number.toString().trim().replace(/^\+/, '');
    if (value.match(/[^0-9]/)) {
      return number;
    }

    let country, city, numberLength;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1;
        city = value.slice(0, 3);
        numberLength = 7;
        break;

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0];
        city = value.slice(1, 4);
        numberLength = 7;
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3);
        city = value.slice(3, 5);
        numberLength = 7;
        break;

      default:
        return number;
    }

    if (country == 1) {
      country = "";
    }

    const numberPart = value.slice(-numberLength);
    if (numberLength == 7) {
      return (country + " (" + city + ") " + numberPart.slice(0, 3) + "-" + numberPart.slice(3)).trim();
    }
    return number;

    // number = number.charAt(0) != 0 ? "0" + number : "" + number;

    // let newStr = "";
    // let i = 0;

    // for (; i < Math.floor(number.length / 2) - 1; i++) {
    //   newStr = newStr + number.substr(i * 2, 2) + "-";
    // }

    // return newStr + number.substr(i * 2);
  }

}
