import { Component } from '@angular/core';

@Component({
  selector: 'app-patron-credit-cards',
  templateUrl: './patron-credit-cards.component.html',
  styleUrls: ['./patron-credit-cards.component.css']
})
export class PatronCreditCardsComponent {

}
