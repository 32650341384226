import { Injectable } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import * as geolib from 'geolib';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private locationSubject = new ReplaySubject<{ latitude: number; longitude: number }>(1);
  private _location$: Subject<{ latitude: number; longitude: number }> = this.locationSubject;
  get location$(): Subject<{ latitude: number; longitude: number }> {
    // If this has not been initialized, then initialize it
    this.init();
    return this._location$;
  }

  currentLocation: { latitude: number; longitude: number } = null;
  locationRetrieved: boolean = false;

  constructor() {
    // this.init();
  }

  private _initialized: boolean = false;
  init(): void {
    if (this._initialized) {
      return;
    }
    this._initialized = true;
    // See if we have cached the location
    // const locationStr = null;
    const locationStr = localStorage.getItem('location');
    if (!!locationStr && locationStr.length > 0) {
      try {
        const location = JSON.parse(locationStr);
        if (!!location && !!location.latitude && !!location.longitude) {
          this.locationRetrieved = true;
          this.currentLocation = {
            latitude: parseFloat(location.latitude),
            longitude: parseFloat(location.longitude)
          }
          this.locationSubject.next(this.currentLocation);
          console.log(`retrieved current location from local storage`);
        }
      }
      catch(err) {
        // Do nothing.
      }
    }

    this.getLocation();
  }

  private handlePosition(position) {
    const location = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    this.locationRetrieved = true;
    this.currentLocation = location;
    this.locationSubject.next(location);
    localStorage.setItem('location', JSON.stringify(location));
  }

  private handlePositionError(error) {
    console.warn('Error getting location:', error);
    // If we can get error, then pick a random default location.
    const locations = [
      // {"name": "Santa Cruz, CA", "latitude": 36.9741, "longitude": -122.0308},
      {"name": "San Francisco, CA", "latitude": 37.7749, "longitude": -122.4194},
      {"name": "San Jose, CA", "latitude": 37.3382, "longitude": -121.8863},
      // {"name": "Goodland, KS", "latitude": 39.3508, "longitude": -101.7108},
      // {"name": "Austin, TX", "latitude": 30.2672, "longitude": -97.7431},
      // {"name": "Houston, TX", "latitude": 29.7604, "longitude": -95.3698},
      // {"name": "Dallas, TX", "latitude": 32.7767, "longitude": -96.7970},
      // {"name": "New York, NY", "latitude": 40.7128, "longitude": -74.0060},
      // {"name": "Chicago, IL", "latitude": 41.8781, "longitude": -87.6298},
      // {"name": "Los Angeles, CA", "latitude": 34.0522, "longitude": -118.2437},
      // {"name": "San Diego, CA", "latitude": 32.7157, "longitude": -117.1611},
      // {"name": "Seattle, WA", "latitude": 47.6062, "longitude": -122.3321},
      // {"name": "Portland, OR", "latitude": 45.5051, "longitude": -122.6750},
      // {"name": "Denver, CO", "latitude": 39.7392, "longitude": -104.9903},
      // {"name": "Phoenix, AZ", "latitude": 33.4484, "longitude": -112.0740},
      // {"name": "Las Vegas, NV", "latitude": 36.1699, "longitude": -115.1398},
      // {"name": "Salt Lake City, UT", "latitude": 40.7608, "longitude": -111.8910},
      // {"name": "Boise, ID", "latitude": 43.6150, "longitude": -116.2023},
      // {"name": "Billings, MT", "latitude": 45.7833, "longitude": -108.5007},
      // {"name": "Minneapolis, MN", "latitude": 44.9778, "longitude": -93.2650},
      // {"name": "Milwaukee, WI", "latitude": 43.0389, "longitude": -87.9065},
      // {"name": "St. Louis, MO", "latitude": 38.6270, "longitude": -90.1994},
    ];

    const namedLocation = locations[Math.floor(Math.random() * locations.length)];
    const location = {
      latitude: namedLocation.latitude,
      longitude: namedLocation.longitude,
    };
    // const location = {
    //   latitude: 36.9741,
    //   longitude: -122.0308,
    // };
    this.locationRetrieved = true;
    this.currentLocation = location;
    this.locationSubject.next(location);
    // this.locationSubject.error(error);
    // this.locationSubject.complete(); // Complete the observable after an error
  }

  private getLocation(): void {
    const that = this;
    const options = {
      enableHighAccuracy: false,
      timeout: 1000 * 60, // 1 minute
      maximumAge: 0
    };
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
        if (result.state === 'granted') {
          // You have permission - get the location
          navigator.geolocation.getCurrentPosition(that.handlePosition.bind(that), that.handlePositionError.bind(that), options);
        }
        else if (result.state === 'prompt') {
          // Permission is not granted yet, ask for it
          navigator.geolocation.getCurrentPosition(that.handlePosition.bind(that), that.handlePositionError.bind(that), options);
        } // handle 'denied' state
        else {
          that.handlePositionError('Geolocation is not supported by this browser.');
        }
      });
    } else {
      this.locationSubject.error('Geolocation is not supported by this browser.');
      this.locationSubject.complete(); // Complete the observable after an error
    }
  }

  retryGetLocation(): void {
    this.locationSubject.next(null);
    this.getLocation();
  }

  fetchDistance(fromLocation: {latitude: number, longitude: number}, toLocation: { latitude: number; longitude: number }): number | null {
    // Use geolib to calculate distance between two locations, but first do some validation of the input
    if (!fromLocation || !toLocation) {
      return null;
    }
    if (!fromLocation.latitude || !fromLocation.longitude || !toLocation.latitude || !toLocation.longitude) {
      return null;
    }
    try {
      const distance = geolib.getDistance(fromLocation, toLocation);
      // Convert to miles
      return geolib.convertDistance(distance, 'mi');
    }
    catch (error) {
      console.error('Error calculating distance:', error);
      return null;
    }
  }
}
