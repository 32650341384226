import { gql } from "apollo-angular";
import { OfferViewDetails } from "../fragments/offer_view.fragment.graphql";


export const UpsertOfferViewMutation = gql`
mutation UpsertOfferView($id: String, $offerId: String, $promotionId: String, $dateViewed: String) {
  upsertOfferView(details: {id: $id, offerId: $offerId, promotionId: $promotionId, dateViewed: $dateViewed}) {
    ...OfferViewDetails
  }
}
${OfferViewDetails}
`;
