import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GetZealsAppConfigQuery } from './graphql/queries/getzeals-app-config.query.graphql';
import { FetchState } from './app.module';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  baseUrl: string = 'https://getzeals.com';
  isProd: boolean = true;
  public configState: FetchState = FetchState.NONE;

  constructor(
    private readonly apollo: Apollo
  ) {

    this.configState = FetchState.LOADING;
    this.apollo.query({
      query: GetZealsAppConfigQuery
    }).subscribe({
      next: (result) => {
        if (result?.data && result.data['getGetZealsAppConfig']) {
          this.configState = FetchState.LOADED_ALL;
          this.baseUrl = result.data['getGetZealsAppConfig'].baseUrl;
          this.isProd = result.data['getGetZealsAppConfig'].prodEnv;
        }
      },
      error: (error) => {
        console.error(error);
        this.configState = FetchState.ERROR;
      }
    });
  }

}
