import { Component } from '@angular/core';
import { NavControlService } from '../../nav/nav-control.service';
import { User } from 'src/app/model/user.model';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.css'
})
export class UserDetailsComponent {

  constructor(
    private readonly navControlService: NavControlService,
  ) { }

  get user(): User {
    return this.navControlService.user;
  }
}
