<div #googleButtonDiv id="googleButtonDiv" class="w-full flex justify-center items-center"></div>
<!-- data-login_uri="https://local.getzeals.com:3000/google/login" -->
<!-- [data-client_id]="GOOGLE_CLIENT_ID" -->
<!-- <div class="google-btn-container" #googleContainer>

  <div id="g_id_onload"
      data-client_id="613362574245-ngt8q8m30sot29rqrusmdvcj22c4ccst.apps.googleusercontent.com"
      data-context="signup"
      data-ux_mode="popup"
      data-callback="handleCredentialResponse"
      data-auto_select="true"
      data-itp_support="true">
  </div>

  <div class="g_id_signin"
    #googleSignIn
      data-type="standard"
      data-shape="rectangular"
      data-theme="outline"
      data-text="continue_with"
      data-size="large"
      data-logo_alignment="center"
      >
  </div>
</div> -->
