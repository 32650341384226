<div class="flex flex-col justify-start items-center p-4 h-full w-full overflow-y-auto gz-bg-gradient" id="howItWorks">
  <div class="flex flex-col justify-center items-center mt-12">
    <p class="text-[36px] text-patron-pink font-extrabold antialiased text-center sm:text-left">{{ 'HOW_ZEALS_WORKS_TITLE' |
      translate }}</p>
    <p class="text-2xl mt-3 text-gray-800 font-bold antialiased">{{ 'EASY_AS_123' | translate }}</p>
  </div>
  <div class="flex flex-col lg:flex-row sm:justify-center items-center sm:items-start mt-8 pb-[100px] flex-grow">
    <div class="flex flex-col justify-center w-[300px] items-center mt-8 mx-4">
      <svg width="80" height="80" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M84 0.992188C38.1561 0.992188 0.992188 38.1561 0.992188 84C0.992188 129.844 38.1561 167.008 84 167.008C129.844 167.008 167.008 129.844 167.008 84C167.008 38.1561 129.844 0.992188 84 0.992188ZM101.291 125.401H78.984V61.3634H66.7095V42.5994H101.291V125.401Z"
          fill="#ED2D92" />
      </svg>
      <p class="text-xl sm:text-[22px] font-bold antialiased mt-4 text-gray-800">{{ 'STEP_1_TITLE' | translate }}</p>
      <p class="sm:text-sm mt-3 text-gray-500 break-words text-center font-sans font-normal">{{ 'STEP_1_MESSAGE' |
        translate }}</p>
    </div>
    <div class="flex flex-col justify-center w-[300px] items-center mt-8 mx-4">
      <svg width="80" height="80" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M84 0.992188C38.1561 0.992188 0.992188 38.1561 0.992188 84C0.992188 129.844 38.1561 167.008 84 167.008C129.844 167.008 167.008 129.844 167.008 84C167.008 38.1561 129.844 0.992188 84 0.992188ZM114.792 126.457H53.2081V121.417C86.5234 82.123 91.1121 75.7729 91.1121 68.6641C91.1121 64.1744 88.154 61.1636 83.913 61.1636C79.2646 61.1636 76.4307 64.2986 76.4307 69.283V70.8848H54.5425V69.3531C54.5425 52.6111 65.5162 41.5445 83.6454 41.5445C101.21 41.5445 113.337 52.3435 113.337 67.8888C113.337 78.3083 109.482 86.3069 91.7722 106.821H114.792V126.457Z"
          fill="#ED2D92" />
      </svg>
      <p class="text-xl sm:text-[22px] font-bold antialiased mt-4 text-gray-800">{{ 'STEP_2_TITLE' | translate }}</p>
      <p class="sm:text-sm mt-3 text-gray-500 text-center font-sans font-normal">{{ 'STEP_2_MESSAGE' | translate }}
      </p>
    </div>
    <div class="flex flex-col justify-center w-[300px] items-center mt-8 mx-4">
      <svg width="80" height="80" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M84 0.992188C38.1561 0.992188 0.992188 38.1561 0.992188 84C0.992188 129.844 38.1561 167.008 84 167.008C129.844 167.008 167.008 129.844 167.008 84C167.008 38.1561 129.844 0.992188 84 0.992188ZM83.653 126.36C65.8591 126.36 53.6268 114.745 53.1291 97.6056H74.382C74.6307 103.443 78.2608 107.322 83.7058 107.322C89.3656 107.322 93.3347 103.155 93.3347 97.1039C93.3347 91.0527 89.2215 87.2971 82.7778 87.2971H69.5148V82.6818L82.9979 60.5144H55.2069V41.6404H112.023V45.4445L95.4046 72.0492C107.276 75.0275 114.871 84.6375 114.871 97.4612C114.871 114.301 101.821 126.36 83.653 126.36Z"
          fill="#ED2D92" />
      </svg>
      <p class="text-xl sm:text-[22px] font-bold antialiased mt-4 text-gray-800">{{ 'STEP_3_TITLE' | translate }}</p>
      <p class="sm:text-sm mt-3 text-gray-500 break-words text-center font-sans font-normal">{{ 'STEP_3_MESSAGE' |
        translate }}</p>
    </div>
  </div>
  <div style="height: 50px;" class="flex justify-center mb-[75px] -mt-10">
    <button class="hidden md:block w-full button-patron-pink hover:border-patron-pink"
      style="width: 220px; height: 50px; margin-left: 10px; border-color: #ED2D92; border-radius: 25px;"
      (click)="handleMainButtonClick()">
      <span *ngIf="!user">{{ 'JOIN_NOW' | translate }}</span>
      <span *ngIf="!!user">{{ 'VIEW_OFFERS' | translate }}</span>
    </button>
    <button class="md:hidden w-full button-patron-pink hover:border-patron-pink"
      style="width: 220px; height: 50px; margin-left: 10px; border-color: #ED2D92; border-radius: 25px;"
      (click)="handleMainButtonClick(true)">
      <span *ngIf="!user">{{ 'JOIN_NOW' | translate }}</span>
      <span *ngIf="!!user">{{ 'VIEW_OFFERS' | translate }}</span>
    </button>
  </div>
</div>
