import { AchAccount, IAchAccountOwner } from "./ach_account.model";
import { AffiliateVenue } from "./affiliate_venue.model";
import { Entity } from "./entity.model";
import { BaseImage } from "./image.model";
import { UserInvite } from "./invite.model";
import { User } from "./user.model";

export class GeoLocation {

  constructor() {}

  latitude: number;
  longitude: number;

}

export class Venue extends Entity implements IAchAccountOwner {// ILedgerOwner

  constructor() {
    super();
  }

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      name: this.name,
      description: this.description,
      archivedDate: this.archivedDate,
      venueAddresses: this.venueAddresses?.map(a => a.toJson()),
      venueImages: this.venueImages?.map(i => i.toJson()),
      venueOperators: this.venueOperators?.map(o => o.toJson()),
      achAccount: this.achAccount?.toJson(),
    };
  }

  name: string;
  description: string;
  archivedDate: Date;
  venueAddresses: Array<VenueAddress> = [];
  venueImages: Array<VenueImage> = [];
  venueOperators: Array<VenueOperator> = [];
  affiliateVenue: AffiliateVenue;
  achAccount: AchAccount;
  invites: Array<UserInvite> = [];


  get venueOperatorUsers(): Array<User> {
    return this.venueOperators?.map(ao => ao.user) ?? [];
  }

  static override parseResponse(details: any, result?: Venue): Venue {
    if (!details) return null;
    if (!result) result = new Venue();

    Entity.parseResponse(details, result);

    result.name = details.name;
    result.description = details.description;
    if (details.archivedDate) {
      result.archivedDate = new Date(details.archivedDate);
    }

    result.venueAddresses = details.venueAddresses?.map(a => VenueAddress.parseResponse(a));
    result.venueImages = details.venueImages?.map(i => VenueImage.parseResponse(i));
    result.venueOperators = details.venueOperators?.map(o => VenueOperator.parseResponse(o));
    result.achAccount = AchAccount.parseResponse(details.achAccount);
    result.invites = details.invites?.map(i => UserInvite.parseResponse(i));

    return result;
  }


  override toRequest(): any {
    const result: any = super.toRequest();

    result.name = this.name;
    result.description = this.description;
    if (this.archivedDate) {
      result.archivedDate = this.archivedDate.toISOString();
    }
    result.venueAddresses = this.venueAddresses?.map(a => a.toRequest());
    result.venueImages = this.venueImages?.map(i => i.toRequest());
    result.venueOperators = this.venueOperators?.map(o => o.toRequest());
    result.achAccount = this.achAccount?.toRequest();

    return result;
  }
}

export class VenueAddress extends Entity {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  geoCoordinate: GeoLocation;

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      street: this.street,
      street2: this.street2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      country: this.country,
      phone: this.phone,
      geoCoordinate: this.geoCoordinate,
    };
  }

  static override parseResponse(details: any, result?: VenueAddress): VenueAddress {
    if (!details) return null;
    if (!result) result = new VenueAddress();

    Entity.parseResponse(details, result);

    result.street = details.street;
    result.street2 = details.street2;
    result.city = details.city;
    result.state = details.state;
    result.zip = details.zip;
    result.country = details.country;
    result.phone = details.phone;
    result.geoCoordinate = details.geoCoordinate;

    return result;
  }
}

export class VenueImage extends BaseImage {

  static override parseResponse(details: any, result?: VenueImage): VenueImage {
    if (!details) return null;
    if (!result) result = new VenueImage();

    BaseImage.parseResponse(details, result);
    return result;
  }
}

export class VenueOperator extends Entity {

  constructor() {
    super();
  }

  primary: boolean;
  user: User;

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      primary: this.primary,
      user: this.user?.toJson(),
    };
  }

  static override parseResponse(details: any, result?: VenueOperator): VenueOperator {
    if (!details) return null;
    if (!result) result = new VenueOperator();

    Entity.parseResponse(details, result);

    result.primary = details.primary;
    if (details.operator?.user) {
      result.user = User.parseResponse(details.operator?.user);
    }

    return result;
  }
}


export class Merchant extends Entity {

  constructor() {
    super();
  }

  merchantName: string;
  networkMerchantId: string;
  visaMerchantId: string;
  mastercardMerchantId: string;
  amexMerchantId: string;
  deactivated: number;
  intOfferPercentage: number;
  intOfferAmount: number;
  intOfferMinSpend: number;
  intOfferMaxReward: number;
  venue: Venue;
  fidel: FidelMerchant;
  merchantLocations: Array<MerchantLocation> = [];

  get isActive(): boolean {
    return this.merchantLocations?.some((location) => location.location?.active === true);
  }

  get isLive(): boolean {
    return this.merchantLocations?.some((location) => location.location?.live === true);
  }

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      merchantName: this.merchantName,
      networkMerchantId: this.networkMerchantId,
      visaMerchantId: this.visaMerchantId,
      mastercardMerchantId: this.mastercardMerchantId,
      amexMerchantId: this.amexMerchantId,
      deactivated: this.deactivated,
      intOfferPercentage: this.intOfferPercentage,
      intOfferAmount: this.intOfferAmount,
      intOfferMinSpend: this.intOfferMinSpend,
      intOfferMaxReward: this.intOfferMaxReward,
      venue: this.venue?.toJson(),
      fidel: this.fidel?.toJson(),
      merchantLocations: this.merchantLocations?.map(l => l.toJson()),
    };
  }

  static override parseResponse(details: any, result?: Merchant): Merchant {
    if (!details) return null;
    if (!result) result = new Merchant();

    Entity.parseResponse(details, result);

    result.merchantName = details.merchantName;
    result.networkMerchantId = details.networkMerchantId;
    result.visaMerchantId = details.visaMerchantId;
    result.mastercardMerchantId = details.mastercardMerchantId;
    result.amexMerchantId = details.amexMerchantId;
    result.deactivated = details.deactivated;
    result.intOfferPercentage = details.intOfferPercentage;
    result.intOfferAmount = details.intOfferAmount;
    result.intOfferMinSpend = details.intOfferMinSpend;
    result.intOfferMaxReward = details.intOfferMaxReward;
    result.venue = details.venue;
    result.fidel = details.fidel;
    result.merchantLocations = details.merchantLocations?.map(l => MerchantLocation.parseResponse(l));

    return result;
  }
}

export class MerchantLocation extends Entity {

  constructor() {
    super();
  }

  deactivatedDate: Date;
  errorCode: string;
  errorMessage: string;
  errorDate: Date;
  serviceId: string;
  location: FidelLocation;

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      deactivatedDate: this.deactivatedDate?.toISOString() ?? "",
      errorCode: this.errorCode,
      errorMessage: this.errorMessage,
      errorDate: this.errorDate?.toISOString() ?? "",
      serviceId: this.serviceId,
      location: this.location,
    };
  }

  static override parseResponse(details: any, result?: MerchantLocation): MerchantLocation {
    if (!details) return null;
    if (!result) result = new MerchantLocation();

    Entity.parseResponse(details, result);

    result.deactivatedDate = details.deactivatedDate;
    result.errorCode = details.errorCode;
    result.errorMessage = details.errorMessage;
    result.errorDate = details.errorDate;
    result.serviceId = details.serviceId;
    result.location = details.location;

    return result;
  }
}



export class FidelMerchant extends Entity {

  constructor() {
    super();
  }

  deactivated: Date;
  errorCode: string;
  errorMessage: string;
  errorDate: Date;
  serviceId: string;
  location: FidelLocation;

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      deactivated: this.deactivated?.toISOString() ?? "",
      errorCode: this.errorCode,
      errorMessage: this.errorMessage,
      errorDate: this.errorDate?.toISOString() ?? "",
      serviceId: this.serviceId,
      location: this.location,
    };
  }

  static override parseResponse(details: any, result?: FidelMerchant): FidelMerchant {
    if (!details) return null;
    if (!result) result = new FidelMerchant();

    Entity.parseResponse(details, result);

    result.deactivated = details.deactivated;
    result.errorCode = details.errorCode;
    result.errorMessage = details.errorMessage;
    result.errorDate = details.errorDate;
    result.serviceId = details.serviceId;
    result.location = details.location;

    return result;
  }
}

export type FidelLocation = {
  id?: string;
  currency?: string;
  visa?: FidelLocationNetwork;
  mastercard?: FidelLocationNetwork;
  amex?: FidelLocationNetwork;
  city?: string;
  geolocation?: GeoLocation;
  programId?: string;
  searchBy?: FidelSearchBy;
  preonboard?: boolean;
  postcode?: string;
  accountId?: string;
  activeDate?: string;
  stateCode?: string;
  countryCode?: FidelCountryCode;
  created?: string;
  address?: string;
  live?: boolean;
  updated?: string;
  brandId?: string;
  timezone?: string;
  active?: boolean;
  metadata?: any;
}

export type FidelLocationNetwork = {

  estimatedActivationDate?: Date;
  clearingTransactionId?: string;
  auth?: boolean;
  authTransactionId?: string;
  clearing?: boolean;
  status: string
  error?: FidelLocationNetworkError;
}

export type FidelLocationNetworkError = {
  message?: string;
}

export enum FidelCountryCode {
  ARE = 'ARE', AUS = 'AUS', CAN = 'CAN', DNK = 'DNK', FIN = 'FIN', GBR = 'GBR', IRL = 'IRL', JPN = 'JPN', NOR = 'NOR', NZL = 'NZL', SGP = 'SGP', SWE = 'SWE', USA = 'USA'
}

export type FidelSearchBy = {

  merchantIds?: FidelMerchantIds;
}


export type FidelMerchantIds = {

  mastercard?: string[];
  amex?: string[];
  visa?: string[];
}
