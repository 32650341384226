import { gql } from "apollo-angular";

export const OfferImageDetails = gql`
fragment OfferImageDetails on OfferImage {
  id
  name
  type
  dateAdded
  url
  urlExpiry
}
`;
