import { Injectable } from '@angular/core';
import { FetchState } from '../app.module';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Affiliate, AffiliateOperator } from '../model/affiliate.model';
import { Apollo } from 'apollo-angular';
import { LoginState } from '../user-auth.service';
import { NavControlService } from '../components/nav/nav-control.service';
import { OperatorType, PageAndSortInput, SearchInput } from '../graphql/types.graphql';
import { AffiliateQuery, AffiliatesQuery } from '../graphql/queries/affiliates.query.graphql';
import { InviteAffiliateOperatorMutation, RemoveAffiliateMutation, RemoveAffiliateOperatorMutation, UpsertAffiliateMutation } from '../graphql/mutations/upsert_affiliate.mutation.graphql';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  state: FetchState = FetchState.NONE;
  affiliates$: BehaviorSubject<Affiliate[]> = new BehaviorSubject<Affiliate[]>([]);
  searchTerm$ = new Subject<string>();
  private _searchTerm: string = null;
  private currentPage: number = 0;
  private pageSize: number = 7;
  private pendingSearchTerm: string;
  selectedAffiliate: Affiliate = null;

  constructor(
    private readonly navControlService: NavControlService,
    private readonly apollo: Apollo
    ) {
    this.searchTerm$.pipe(
      debounceTime(300),        // wait for 300ms pause in events
      distinctUntilChanged()   // only emit if value is different from previous value
    ).subscribe(searchTerm => {
      // If searchTerm has changed, then reset page to 0 and reset list
      this._searchTerm = searchTerm;
      this.clear();
      this.fetch(searchTerm, true);
    });

    this.navControlService.userState$.subscribe({
      next: (userState) => {
        if (userState === LoginState.LOGGED_IN) {
          if (this.pendingSearchTerm) {
            this.fetch(this.pendingSearchTerm);
          }

          if (this.pendingId) {
            this.fetchById(this.pendingId);
          }
        }
      }
    });
  }

  private clear() {
    this.state = FetchState.NONE;
    this.currentPage = 0;
    this.affiliates$.next([]);
  }

  loadMore() {
    this.fetch(this._searchTerm);
  }

  fetch(searchTerm: string = null, forceRefresh: boolean = false) {
    if (this.navControlService.userState !== LoginState.LOGGED_IN) {
      this.pendingSearchTerm = searchTerm;
      this.affiliates$.next([]);
      return;
    }

    if (this.state === FetchState.LOADING) {
      return;
    }
    if (forceRefresh) {
      this.clear();
    }

    const searchInputs: Array<SearchInput> = [];
    if (!!searchTerm && searchTerm.length > 0) {
      searchInputs.push({
        value: searchTerm,
        searchFields: ['name'],
        operator: OperatorType.LIKE
      });
    }

    const sort: PageAndSortInput = {
      page: this.currentPage,
      pageSize: this.pageSize,
      sortFields: []
    }
    console.log("Fetching Affiliates");

    this.state = FetchState.LOADING;
    this.apollo.query({
      query: AffiliatesQuery,
      variables: {
        search: searchInputs.map((searchInput) => {
          return {
            value: searchInput.value,
            searchFields: searchInput.searchFields,
            operator: searchInput.operator.toUpperCase()
          };
        }),
        sort: sort,
      },
      fetchPolicy: forceRefresh ? 'no-cache' : 'cache-first'
    }).subscribe({
      next: ({ data }) => {
        const affiliates: Affiliate[] = [].concat(this.affiliates$.value);
        if (!!data && !!data['affiliates']) {
          for (const entityData of data['affiliates']) {
            if (entityData) {
              affiliates.push(Affiliate.parseResponse(entityData));
            }
          }

          if (data['affiliates'].length < this.pageSize) {
            this.state = FetchState.LOADED_ALL;
          }
          else {
            this.state = FetchState.GOOD;
          }
        }
        else {
          this.state = FetchState.LOADED_ALL;
        }
        this.currentPage++;
        this.affiliates$.next(affiliates);
      }
    })
  }

  private pendingId: string = null;
  stateById: FetchState = FetchState.GOOD;
  async fetchById(id: string, forceRefresh: boolean = false): Promise<Affiliate> {
    if (this.navControlService.userState !== LoginState.LOGGED_IN) {
      this.pendingId = id;
      return Promise.resolve(null);
    }

    if (this.stateById === FetchState.LOADING) {
      return Promise.resolve(null);
    }

    if (!forceRefresh) {
      const existing = this.affiliates$.value.find(v => v.id === id);
      if (existing) {
        this.stateById = FetchState.LOADED_ALL;
        return Promise.resolve(existing);
      }
    }

    this.pendingId = null;

    this.stateById = FetchState.LOADING;

    return new Promise((resolve, reject) => {
      this.apollo.query({
        query: AffiliateQuery,
        variables: {
          id: id,
        }
      }).subscribe({
        next: ({ data, loading }) => {
          let entity: Affiliate = null;
          if (!!data && !!data['affiliate']) {
            const entityData = data['affiliate'];
            if (!!entityData && !!entityData.id) {
              entity = Affiliate.parseResponse(entityData);
              const newEntities = [];
              for(let i=0; i<this.affiliates$.value.length; i++) {
                const nextEntity = this.affiliates$.value[i];
                if (nextEntity.id === entityData.id) {
                  newEntities.push(entity);
                }
                else {
                  newEntities.push(nextEntity);
                }
              }
              this.affiliates$.next(newEntities);
            }
          }

          this.stateById = FetchState.LOADED_ALL;
          resolve(entity);
        },
        error: (error) => {
          console.error(error);
          this.stateById = FetchState.ERROR;
          reject(error);
        }
      })
    }
    );
  }

  async save(entity: Affiliate): Promise<Affiliate> {
    const request = entity.toRequest();
    console.log(`upsertAffiliate`);
    console.dir(request);

    //GraphQL mutation
    return new Promise<Affiliate>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: UpsertAffiliateMutation,
          variables: request
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          let result: Affiliate = null;
          if (data && data['upsertAffiliate']) {
            // If the affiliate is not already in the list, add it, otherwise update the existing one
            const existing = this.affiliates$.value.find(v => v.id === data['upsertAffiliate'].id);
            result = Affiliate.parseResponse(data['upsertAffiliate']);
            if (!existing) {
              const affiliates = [].concat(this.affiliates$.value);
              affiliates.push(result);
              this.affiliates$.next(affiliates);
            }
            else {
              const affiliates = this.affiliates$.value.map(v => {
                if (v.id === data['upsertAffiliate'].id) {
                  return result;
                }
                return v;
              });
              this.affiliates$.next(affiliates);
            }
          }
          resolve(result);
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

  // Unarchive an affiliate. This is a mutation, so it returns a promise
  // Updates the existing affiliate in the list
  async unarchive(id: string): Promise<Affiliate> {
    console.log(`unarchiveAffiliate`);
    console.dir(id);

    //GraphQL mutation
    return new Promise<Affiliate>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: UpsertAffiliateMutation,
          variables: {
            id: id
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          let result: Affiliate = null;
          if (data && data['unarchiveAffiliate']) {
            // If the affiliate is not already in the list, add it, otherwise update the existing one
            const existing = this.affiliates$.value.find(v => v.id === data['unarchiveAffiliate'].id);
            result = Affiliate.parseResponse(data['unarchiveAffiliate']);
            if (!existing) {
              const affiliates = [].concat(this.affiliates$.value);
              affiliates.push(result);
              this.affiliates$.next(affiliates);
            }
            else {
              const affiliates = this.affiliates$.value.map(v => {
                if (v.id === data['unarchiveAffiliate'].id) {
                  return result;
                }
                return v;
              });
              this.affiliates$.next(affiliates);
            }
          }
          resolve(result);
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

  async remove(id: string): Promise<void> {
    console.log(`removeAffiliate`);
    console.dir(id);

    //GraphQL mutation
    return new Promise<void>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: RemoveAffiliateMutation,
          variables: {
            id: id
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          resolve();
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }


  async inviteAffiliateOperator(affiliate: Affiliate, userId: string): Promise<AffiliateOperator> {
    console.log(`inviteAffiliateOperator`);
    console.dir(affiliate);
    console.dir(userId);

    // Validate the input
    if (!affiliate || !affiliate.id) {
      return Promise.reject('Invalid affiliate');
    }

    //GraphQL mutation
    return new Promise<AffiliateOperator>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: InviteAffiliateOperatorMutation,
          variables: {
            affiliateId: affiliate.id,
            userId: userId
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          if (data && data['inviteAffiliateOperator']) {
            const affiliateOperator = AffiliateOperator.parseResponse(data['inviteAffiliateOperator']);
            // If the affiliate operator is not already in the list, add it, otherwise update the existing one
            const existing = affiliate.affiliateOperators.find(o => o.id === affiliateOperator.id);
            if (!existing) {
              affiliate.affiliateOperators.push(affiliateOperator);
            }
            else {
              const affiliateOperators = affiliate.affiliateOperators.map(o => {
                if (o.id === affiliateOperator.id) {
                  return affiliateOperator;
                }
                return o;
              });
              affiliate.affiliateOperators = affiliateOperators;
            }
            resolve(affiliateOperator);
          }
          else {
            resolve(null);
          }
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

  async removeAffiliateOperator(affiliate: Affiliate, id: string): Promise<void> {
    console.log(`removeAffiliateOperator`);
    console.dir(id);

    // Validate the input
    if (!affiliate || !affiliate.id) {
      return Promise.reject('Invalid affiliate');
    }

    //GraphQL mutation
    return new Promise<void>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: RemoveAffiliateOperatorMutation,
          variables: {
            id: id
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          if (!!data) {
            // Remove from the list of affiliate operators, if it exists
            const index = affiliate.affiliateOperators.findIndex(o => o.id === id);
            if (index !== -1) {
              affiliate.affiliateOperators.splice(index, 1);
            }
          }
          resolve();
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }
}
