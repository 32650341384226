import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppleService } from './apple.service';

@Component({
  selector: 'app-apple',
  templateUrl: './apple.component.html',
  styleUrls: ['./apple.component.css']
})
export class AppleComponent implements OnInit {
  authUrl: string;

  constructor(
    private route: ActivatedRoute,
    private readonly appleService: AppleService,
    private readonly http: HttpClient
  ) {}

  ngOnInit(): void {
    // this.route.queryParams.subscribe(params => {
    //   const code = params['code'];
    //   const state = params['state'];
    //   const id_token = params['id_token'];
    //   const user = params['user'];
    //   console.log('code', code);
    //   console.log('state', state);
    //   console.log('id_token', id_token);
    // });

    // this.login();
    // this.authUrl = this.appleService.getAuthorizationUrl();
  }

  // async login() {
  //   // let rez = await this.http.post("/apple/redirect/api", {}).subscribe((data) => {
  //   let rez = await this.http.post("https://localhost:3000/apple/redirect/api", {}).subscribe((data) => {
  //     console.log(data);
  //   });
  //   return rez;
  // }
}
