<div class="w-full h-full flex justify-center items-start gz-bg-default">
  <div class="center-content-container relative">

    <!-- If not logged in, then show message the user needs to login with a button to open the login -->
    <div *ngIf="!isLoggedIn" class="flex flex-col justify-center items-center h-full absolute inset-0 p-8">
      <div class="flex flex-col justify-center items-center">
        <div class="w-full border-gray-200 text-gray-600 border p-4 flex justify-center items-center rounded-xl text-xs my-4 gap-4 bg-white drop-shadow-sm">
          <div class="flex flex-col justify-center items-center">
            <div class="text-xl">Please login to accept this invite.</div>
            <button (click)="openLogin()" class="button-business-blue">Login</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading while accepting invite -->
    <div *ngIf="acceptState === FetchState.LOADING" class="flex justify-center items-center h-full absolute inset-0">
      <div class="loader-wrapper flex justify-center items-center">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
      </div>
    </div>

    <!-- Error State -->
    <div *ngIf="acceptState === FetchState.ERROR" class="flex justify-center items-center h-full absolute inset-0 p-8">
      <div class="flex flex-col justify-center items-center">
        <div class="w-full border-gray-200 text-gray-600 border p-4 flex justify-center items-center rounded-xl text-xs my-4 gap-4 bg-white drop-shadow-sm">
          <!-- <fa-icon [icon]="faError" [styles]="{'color': 'red'}" class="text-xl mr-1"></fa-icon> -->
          <div class="flex flex-col justify-center items-center">
            <div class="text-xl">{{ errorMessage || "There was an error accepting your invite." }}</div>
            <!-- <div *ngIf="!!errorMessage" class="text-lg">{{ errorMessage }}</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
