import { gql } from "apollo-angular";
import { UserDetails } from "./user.fragment.graphql";
export const PromoterDetails = gql`
fragment PromoterDetails on Promoter {
  id
  user {
    ...UserDetails
  }
}

`

export const PromoterPromotionDetails = gql`
fragment PromoterPromotionDetails on PromoterPromotion {
  id
  name
  disabledDate
  url
  medium {
    id
    name
    type
  }
  promoter {
    ...PromoterDetails
  }
  ${PromoterDetails}
}
`
export const PlatformPromotionDetails = gql`
fragment PlatformPromotionDetails on PlatformPromotion {
  id
  name
  disabledDate
  url
  medium {
    id
    name
    type
  }
}
`
