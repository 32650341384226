import { Entity } from "./entity.model";
import { BaseImage } from "./image.model";


export class Charity extends Entity {

  constructor() {
    super();
  }

  name: string;
  description: string;
  availableDate?: Date;
  archivedDate?: Date;
  logoImage: BaseImage;
  bannerImage: BaseImage;

  static override parseResponse(details: any, result?: Charity): Charity {
    if (!details) return null;
    if (!result) result = new Charity();

    Entity.parseResponse(details, result);

    result.name = details.name;
    result.description = details.description;
    if (details.availableDate) {
      result.availableDate = new Date(details.availableDate);
    }
    if (details.archivedDate) {
      result.archivedDate = new Date(details.archivedDate);
    }
    result.logoImage = new BaseImage();
    result.logoImage.type = 'logo';
    result.logoImage.url = details.logoUrl;
    result.bannerImage = new BaseImage();
    result.bannerImage.type = 'banner';
    result.bannerImage.url = details.bannerUrl;

    return result;
  }
}
