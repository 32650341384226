<form class="pb-10" [formGroup]="waitlistForm" (ngSubmit)="onSubmit()">
  <div class="py-5 px-5 md:px-[200px] mx-auto flex flex-row w-full justify-center flex-wrap">
    <div class="w-full max-w-[400px] xl:w-[50%]" *ngIf="waitlistJoined">
      <p class="bold-title text-center xl:text-left">{{ 'WAITLIST.WELCOME' | translate }}</p>
      <p class="paragraph mt-5 text-center xl:text-left pr-10">{{ 'WAITLIST.WELCOME_MESSAGE' | translate }}</p>
    </div>
    <div *ngIf="!waitlistJoined" class="w-full max-w-[400px] xl:w-[50%]">
      <p class="bold-title text-center xl:text-left">{{ 'WAITLIST.JOIN' | translate }}</p>
      <p class="paragraph mt-5 text-center xl:text-left pr-10">{{ 'WAITLIST.JOIN_MESSAGE' | translate }}</p>
      <p *ngIf="waitlistError"
        class="text-[18px] mt-5 bg-white text-red-500 rounded border border-red-500 p-4 text-center xl:text-left pr-10">
        {{ 'WAITLIST.JOIN_ERROR' | translate}}</p>
    </div>
    <div class="flex flex-col" *ngIf="!waitlistJoined">
      <div class="flex flex-col w-[100%] mt-5 items-start justify-between">
        <input type="text" [placeholder]="'WAITLIST.NAME.LABEL' | translate" formControlName="name"
          class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-full">
        <p *ngIf="name?.touched && name?.value === ''" class="text-left text-red-500 text-sm">{{'WAITLIST.NAME.REQUIRED'
          | translate}}</p>
        <p *ngIf="name?.value.length < 2 && name?.value.length > 0" class="text-left text-red-500 text-sm">
          {{'WAITLIST.NAME.TOO_SHORT' | translate}}</p>
        <p *ngIf="name?.value.length > 50 && name?.value.length > 0" class="text-left text-red-500 text-sm">
          {{'WAITLIST.NAME.TOO_LONG' | translate}}</p>
        <p *ngIf="name?.hasError('pattern') && name?.touched" class="text-left text-red-500 text-sm">
          {{'WAITLIST.NAME.INVALID' | translate}}</p>
      </div>
      <div *ngIf="businessType === 1" class="flex flex-col w-[100%] mt-5 items-start justify-between">
        <input type="text" [placeholder]="'WAITLIST.VENUE_NAME.LABEL' | translate" formControlName="companyName"
          class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-full">
        <p *ngIf="companyName?.touched && companyName?.value === ''" class="text-left text-red-500 text-sm">
          {{'WAITLIST.VENUE_NAME.REQUIRED' | translate}}</p>
        <p *ngIf="companyName?.value.length < 2 && companyName?.value.length > 0"
          class="text-left text-red-500 text-sm">{{'WAITLIST.VENUE_NAME.TOO_SHORT' | translate}}</p>
        <p *ngIf="companyName?.value.length > 50 && companyName?.value.length > 0"
          class="text-left text-red-500 text-sm">{{'WAITLIST.VENUE_NAME.TOO_LONG' | translate}}</p>
        <p *ngIf="companyName?.hasError('pattern') && companyName?.touched" class="text-left text-red-500 text-sm">
          {{'WAITLIST.VENUE_NAME.INVALID' | translate}}</p>
      </div>
      <div *ngIf="businessType === 0" class="flex flex-col w-[100%] mt-5 items-start justify-between">
        <input type="text" [placeholder]="'WAITLIST.CHARITY_NAME.LABEL' | translate" formControlName="companyName"
          class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-full">
        <p *ngIf="companyName?.touched && companyName?.value === ''" class="text-left text-red-500 text-sm">
          {{'WAITLIST.CHARITY_NAME.REQUIRED' | translate}}</p>
        <p *ngIf="companyName?.value.length < 2 && companyName?.value.length > 0"
          class="text-left text-red-500 text-sm">{{'WAITLIST.CHARITY_NAME.TOO_SHORT' | translate}}</p>
        <p *ngIf="companyName?.value.length > 50 && companyName?.value.length > 0"
          class="text-left text-red-500 text-sm">{{'WAITLIST.CHARITY_NAME.TOO_LONG' | translate}}</p>
        <p *ngIf="companyName?.hasError('pattern') && companyName?.touched" class="text-left text-red-500 text-sm">
          {{'WAITLIST.CHARITY_NAME.INVALID' | translate}}</p>
      </div>
      <div class="flex flex-col mt-5 items-start justify-between">
        <input type="text" [placeholder]="'WAITLIST.STREET_ADDRESS.LABEL' | translate" formControlName="street"
          class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-full">
        <p *ngIf="street?.touched && street?.value === ''" class="text-left text-red-500 text-sm">
          {{'WAITLIST.STREET_ADDRESS.REQUIRED' | translate}}</p>
        <p *ngIf="street?.value.length < 2 && street?.value.length > 0" class="text-left text-red-500 text-sm">
          {{'WAITLIST.STREET_ADDRESS.TOO_SHORT' | translate}}</p>
        <p *ngIf="street?.value.length > 50 && street?.value.length > 0" class="text-left text-red-500 text-sm">
          {{'WAITLIST.STREET_ADDRESS.TOO_LONG' | translate}}</p>
        <p *ngIf="street?.hasError('pattern') && street?.touched" class="text-left text-red-500 text-sm">
          {{'WAITLIST.STREET_ADDRESS.INVALID' | translate}}</p>
      </div>
      <div class="flex flex-col w-[100%] mt-5 items-start justify-between">
        <input type="text" [placeholder]="'WAITLIST.ADDRESS_2.LABEL' | translate" formControlName="street2"
          class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-full">
        <p *ngIf="street2?.value.length < 2 && street2?.value.length > 0" class="text-left text-red-500 text-sm">
          {{'WAITLIST.ADDRESS_2.TOO_SHORT' | translate}}</p>
        <p *ngIf="street2?.value.length > 50 && street2?.value.length > 0" class="text-left text-red-500 text-sm">
          {{'WAITLIST.ADDRESS_2.TOO_LONG' | translate}}</p>
        <p *ngIf="street2?.hasError('pattern') && street2?.touched" class="text-left text-red-500 text-sm">
          {{'WAITLIST.ADDRESS_2.INVALID' | translate}}</p>
      </div>
      <div class="flex flex-col w-[100%] mt-5 items-start justify-between">
        <input type="text" [placeholder]="'WAITLIST.CITY.LABEL' | translate" formControlName="city"
          class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-full">
        <p *ngIf="city?.touched && city?.value === ''" class="text-left text-red-500 text-sm">{{'WAITLIST.CITY.REQUIRED'
          | translate}}</p>
        <p *ngIf="city?.value.length < 2 && city?.value.length > 0" class="text-left text-red-500 text-sm">
          {{'WAITLIST.CITY.TOO_SHORT' | translate}}</p>
        <p *ngIf="city?.value.length > 50 && city?.value.length > 0" class="text-left text-red-500 text-sm">
          {{'WAITLIST.CITY.TOO_LONG' | translate}}</p>
        <p *ngIf="city?.hasError('pattern') && city?.touched" class="text-left text-red-500 text-sm">
          {{'WAITLIST.CITY.INVALID' | translate}}</p>
      </div>
      <div class="flex flex-row w-[100%] mt-5 items-center justify-between">
        <div class="flex flex-row w-[45%] mt-5 items-center justify-between">
          <input type="text" [placeholder]="'WAITLIST.STATE.LABEL' | translate" formControlName="state"
            class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-[100%]">
          <p *ngIf="state?.touched && state?.value === ''" class="text-left text-red-500 text-sm">
            {{'WAITLIST.STATE.REQUIRED' | translate}}</p>
          <p *ngIf="state?.value.length < 2 && state?.value.length > 0" class="text-left text-red-500 text-sm">
            {{'WAITLIST.STATE.TOO_SHORT' | translate}}</p>
          <p *ngIf="state?.value.length > 25 && state?.value.length > 0" class="text-left text-red-500 text-sm">
            {{'WAITLIST.STATE.TOO_LONG' | translate}}</p>
          <p *ngIf="state?.hasError('pattern') && state?.touched" class="text-left text-red-500 text-sm">
            {{'WAITLIST.STATE.INVALID' | translate}}</p>
        </div>
        <div class="flex flex-row w-[45%] mt-5 items-center justify-between">
          <input type="text" [placeholder]="'WAITLIST.ZIP_CODE.LABEL' | translate" formControlName="zip"
            class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-[100%]">
          <p *ngIf="zip?.touched && zip?.value === ''" class="text-left text-red-500 text-sm">
            {{'WAITLIST.ZIP_CODE.REQUIRED' | translate}}</p>
          <p *ngIf="zip?.value.length < 5 && zip?.value.length > 0" class="text-left text-red-500 text-sm">
            {{'WAITLIST.ZIP_CODE.TOO_SHORT' | translate}}</p>
          <p *ngIf="zip?.value.length > 5 && zip?.value.length > 0" class="text-left text-red-500 text-sm">
            {{'WAITLIST.ZIP_CODE.TOO_LONG' | translate}}</p>
          <p *ngIf="zip?.hasError('pattern') && zip?.touched" class="text-left text-red-500 text-sm">
            {{'WAITLIST.ZIP_CODE.INVALID' | translate}}</p>
        </div>
      </div>

      <div class="flex flex-col w-[100%] mt-5 items-start justify-between">
        <input type="text" [placeholder]="'WAITLIST.PHONE.LABEL' | translate" formControlName="phone"
          class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-full">
        <p *ngIf="phone?.touched && phone?.value === ''" class="text-left text-red-500 text-sm">
          {{'WAITLIST.PHONE.REQUIRED' | translate}}</p>
        <p *ngIf="phone?.hasError('pattern') && phone?.touched" class="text-left text-red-500 text-sm">
          {{'WAITLIST.PHONE.INVALID' | translate}}</p>
      </div>
      <div class="flex flex-col w-[100%] mt-5 items-start justify-between">
        <input type="text" [placeholder]="'WAITLIST.EMAIL.LABEL' | translate" formControlName="email"
          class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-full">
        <p *ngIf="email?.touched && email?.value === ''" class="text-left text-red-500 text-sm">
          {{'WAITLIST.EMAIL.REQUIRED' | translate}}</p>
        <p *ngIf="email?.invalid && email?.value !== ''" class="text-left text-red-500 text-sm">
          {{'WAITLIST.EMAIL.INVALID' | translate}}</p>
      </div>
      <div class="flex flex-col w-[100%] mt-5 items-start justify-between">
        <input type="text" [placeholder]="'WAITLIST.WEBSITE.LABEL' | translate" formControlName="website"
          class="border-2 rounded-[8px] border-[#E1E3E6] border-solid px-[16px] py-[8px] w-full">
        <p *ngIf="website?.touched && website?.value === ''" class="text-left text-red-500 text-sm">
          {{'WAITLIST.WEBSITE.REQUIRED' | translate}}</p>
        <p *ngIf="website?.invalid && website?.value !== ''" class="text-left text-red-500 text-sm">
          {{'WAITLIST.WEBSITE.INVALID' | translate}}</p>
      </div>
      <div class="flex flex-col items-center mt-5">
        <button type="submit" *ngIf="!waitlistForm.valid" disabled class="w-full bg-gray-400 text-gray-100"
          style="width: 225px; height: 50px; margin-left: 10px; border-color: gray-400; border-radius: 25px;">
          {{ 'WAITLIST.JOIN' | translate }}
        </button>
        <button type="submit" *ngIf="waitlistForm.valid" class="w-full button-patron-pink hover:border-patron-pink"
          style="width: 225px; height: 50px; margin-left: 10px; border-color: #ED2D92; border-radius: 25px;">
          {{ 'WAITLIST.JOIN' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
