import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Affiliate } from 'src/app/model/affiliate.model';
import { AffiliateService } from 'src/app/services/affiliate.service';

@Component({
  selector: 'app-edit-affiliate',
  templateUrl: './edit-affiliate.component.html',
  styleUrls: ['./edit-affiliate.component.css'],
})
export class EditAffiliateComponent implements OnInit, OnChanges {
  @Input() affiliate: Affiliate;
  @Output() close = new EventEmitter<void>();
  affiliateForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private readonly affiliateService: AffiliateService,
  ) {
  }

  ngOnChanges() {
    this.affiliateForm?.reset(this.affiliate);
  }

  ngOnInit() {
    this.affiliateForm = this.fb.group({
      id: [{ value: this.affiliate?.id, disabled: true }],
      name: [this.affiliate?.name, Validators.required],
      code: [this.affiliate?.code],
      description: [this.affiliate?.description],
      intPlatformPercentage: [this.affiliate?.intPlatformPercentage, [Validators.min(0), Validators.max(100)]],
      intPlatformAmount: [this.affiliate?.intPlatformAmount, Validators.min(0)],
      intDurationDays: [this.affiliate?.intDurationDays || 180, Validators.min(0)],
      archivedDate: [this.affiliate?.archivedDate],
    });
  }

  async saveAffiliate() {
    if (this.affiliateForm.valid) {
      // Save logic here
      const affiliate = this.affiliateForm.getRawValue();
      this.affiliate.name = affiliate.name ?? this.affiliate.name;
      this.affiliate.code = affiliate.code ?? this.affiliate.code;
      this.affiliate.description = affiliate.description ?? this.affiliate.description;
      this.affiliate.intPlatformPercentage = affiliate.intPlatformPercentage ?? this.affiliate.intPlatformPercentage;
      this.affiliate.intPlatformAmount = affiliate.intPlatformAmount ?? this.affiliate.intPlatformAmount;
      this.affiliate.intDurationDays = affiliate.intDurationDays ?? this.affiliate.intDurationDays;
      this.affiliate.archivedDate = affiliate.archivedDate ?? this.affiliate.archivedDate;

      let result = await this.affiliateService.save(this.affiliate);
      if (!!result) {
        // Success! Close
        this.close.emit();
      }
    }
  }

  unarchiveAffiliate() {
    if (!!this.affiliate.id) {
      // Call the unarchive logic here
      this.affiliateService.unarchive(this.affiliate.id)
    }
  }

  archiveAffiliate() {
    if (!!this.affiliate.id) {
      this.affiliateForm.get('archivedDate').setValue(new Date());
      // Call the archive logic here
      this.affiliateService.remove(this.affiliate.id)
    }
  }

  resetForm() {
    this.affiliateForm.reset(this.affiliate);
  }

  closeModal() {
    this.close.emit();
  }

}
