import { gql } from "apollo-angular";

export const VenueAddressDetails = gql`
    fragment VenueAddressDetails on VenueAddress {
      id
      street
      street2
      city
      state
      zip
      country
      phone
      geoCoordinate {
        latitude
        longitude
      }
    }
`
