import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, Subject, filter, map, takeUntil } from 'rxjs';
import { FetchState } from 'src/app/app.module';
import { Reward } from 'src/app/model/ledger';
import { PatronOffer } from 'src/app/model/patron-offer.model';
import { UserOffersService } from 'src/app/services/user-offers.service';

@Component({
  selector: 'app-select-reward-offer',
  templateUrl: './select-reward-offer.component.html',
  styleUrls: ['./select-reward-offer.component.css']
})
export class SelectRewardOfferComponent implements OnInit, OnDestroy {
  private _reward: Reward
  @Input()
  set reward(value: Reward) {
    this._reward = value;
    this.selectedOffer = value?.claim?.offer;
    this.fetchOffers();
  }
  get reward(): Reward {
    return this._reward;
  }

  selectedOffer: PatronOffer | null = null;

  FaClose = faClose;
  FetchState = FetchState;

  // Close event emitter
  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  saveState: FetchState = FetchState.NONE;

  constructor(
    // private readonly offersService: OffersService,
    private readonly userOfferService: UserOffersService,
  ) { }

  ngOnInit(): void {
    this.fetchOffers();

    this.userOfferService.offers$.pipe(
      takeUntil(this.destroy$),
      map((offers) => {
        // Sort by selected offer first
        offers.sort((a, b) => {
          if (a.id === this.selectedOffer?.id) return -1;
          if (b.id === this.selectedOffer?.id) return 1;
          return 0;
        });
        return offers;
      }
    ))
    .subscribe({
      next: (offers) => {
        this.offers = offers;
      }
    });

    this.userOfferService.state$.pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      next: (state) => {
        this.fetchState = state;
      }
    });

    this.userOfferService.stateUpdateRewardOffer$.pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      next: (state) => {
        this.stateUpdateRewardOffer = state;
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  offers: PatronOffer[] = []
  fetchState: FetchState;

  async fetchOffers() {
    if (!!this.reward?.transaction?.venue?.id && !!this.reward?.transaction?.datetime) {
      this.userOfferService.fetchOffers({forceRefresh: true, venueId: this.reward.transaction.venue.id, availableDate: this.reward.transaction.datetime})
    }
  }

  stateUpdateRewardOffer: FetchState;
  async selectOffer(offer: PatronOffer) {
    this.selectedOffer = offer;
    try {
      let result = await this.userOfferService.updateRewardOffer(this.reward.id, offer.id);
      if (!!result?.claim?.offer) {
        // Make sure the reward's offer is updated
        let index = this.reward.claim.offer.claim?.rewards.findIndex((reward) => reward.id === this.reward.id);
        if (index >= 0) {
          // Remove the old reward
          this.reward.claim.offer.claim.rewards.splice(index, 1);
        }
        this.reward.claim.offer = result.claim.offer;
        index = this.reward.claim.offer.claim?.rewards.findIndex((reward) => reward.id === this.reward.id);
        if (index < 0) {
          // Add the new reward
          this.reward.claim.offer.claim.rewards.push(this.reward);
        }
        this.closeModal();
      }
    }
    catch(error) {
      // Something went wrong, so go back to reward's original offer
      this.selectedOffer = this.reward?.claim?.offer;
    }
  }

  closeModal() {
    this.selectedOffer = this.reward?.claim?.offer;
    this.close.emit();
  }
}
