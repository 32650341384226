import { Injectable } from '@angular/core';
import { JWTTokenService } from './apple/jwt-token.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import * as uuid from 'uuid';

export enum AuthTokenState {
  NONE,
  SUCCESS,
  REFRESHING_ACCESS_TOKEN,
  REFRESHED_ACCESS_TOKEN,
  CANCELLED,
  NO_ACCESS_TOKEN,
  EXPIRED_ACCESS_TOKEN,
  ERROR
}


@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  private static _instance: AuthTokenService;
  static get instance(): AuthTokenService {
    if (!AuthTokenService._instance) {
      AuthTokenService._instance = new AuthTokenService(
        JWTTokenService.instance,
      );
    }
    return AuthTokenService._instance;
  }

  private constructor(
    private readonly jwtTokenService: JWTTokenService,
  ) {

    this.init();
  }

  private async init() {
    const { userId, isExpired } = this.fetchCachedUserId();
    if (userId != null && !isExpired) {
      // We are authenticated, so get the current user
      this.state$.next(AuthTokenState.SUCCESS);
    }
    else if (userId != null && isExpired) {
      // We are authenticated, but the token is expired, so refresh it
      this.state$.next(AuthTokenState.EXPIRED_ACCESS_TOKEN);
      this.refreshAccessToken();
    }
    else {
      this.state$.next(AuthTokenState.NO_ACCESS_TOKEN);
    }
  }

  private fetchCachedUserId(): { userId: string, isExpired: boolean } {
    const cachedToken = localStorage.getItem('access_token');
    if (cachedToken) {
      this.hasCachedToken$.next(true);
      this.jwtTokenService.setToken(cachedToken);
      const cachedUserId = this.jwtTokenService.getUserId();
      const isTokenExpired = !!cachedUserId && this.jwtTokenService.isTokenExpired();
      return {
        userId: cachedUserId,
        isExpired: isTokenExpired,
      }
    }
    return {
      userId: null,
      isExpired: true,
    }
  }

  public hasCachedToken$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public state$: BehaviorSubject<AuthTokenState> =  new BehaviorSubject(AuthTokenState.NONE);

  public getCachedUserModelId() {
    return this.jwtTokenService.getUserId();
  }

  public getAccessToken() {
    return this.jwtTokenService.getToken();
  }

  public getSystemId(generateIfNotExists: boolean = true) {
    let systemId = localStorage.getItem('system_id');
    if (!systemId && generateIfNotExists) {
      systemId = uuid.v4();
      localStorage.setItem('system_id', systemId);
    }
    return systemId;
  }

  private _refreshTokenPromise: Promise<string | boolean>;
  async refreshAccessToken(retryCount: number = 0): Promise<string | boolean> {
    if (this._refreshTokenPromise) {
      return this._refreshTokenPromise;
    }
    const refreshToken = localStorage.getItem('refresh_token');
    const systemId = localStorage.getItem('system_id');
    // If no sysytem id, then this won't be a valid refresh token
    if (!!refreshToken && !!systemId) {
      this._refreshTokenPromise = this._refreshAccessToken(refreshToken, systemId);
      return this._refreshTokenPromise;
    }

    this.state$.next(AuthTokenState.NO_ACCESS_TOKEN);
    return false;
  }

  private async _refreshAccessToken(refreshToken: string, systemId: string, retryCount: number = 0): Promise<string | boolean> {
    this.state$.next(AuthTokenState.REFRESHING_ACCESS_TOKEN);
    const uri = environment.authUri + '/refresh';

    try {
      const response = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-system-id': systemId,
        },
        body: JSON.stringify({
          refresh_token: refreshToken
        })
      });
      const json = await response.json();
      if (json && json['access_token']) {
        this.jwtTokenService.setToken(json['access_token']);
        localStorage.setItem('access_token', json['access_token']);
        localStorage.setItem('refresh_token', json['refresh_token']);
        this._refreshTokenPromise = null;
        this.state$.next(AuthTokenState.REFRESHED_ACCESS_TOKEN);
        return json['access_token'];
      }
      else {
        // Try again
        // if (retryCount < 1) {
        //   return this.refreshAccessToken(retryCount+1);
        // }

        this._refreshTokenPromise = null;
        await this.logout(AuthTokenState.ERROR);
        return false;
      }
    }
    catch (error) {
      // Try again
      // if (retryCount < 1) {
      //   return this.refreshAccessToken(retryCount+1);
      // }

      this._refreshTokenPromise = null;
      await this.logout(AuthTokenState.ERROR);
      return false;
    }
  }

  public async handleAuthResponse(response) {
    if (response && response['access_token']) {
      this.jwtTokenService.setToken(response['access_token']);
      localStorage.setItem('access_token', response['access_token']);
      localStorage.setItem('refresh_token', response['refresh_token']);

      this.state$.next(AuthTokenState.SUCCESS);
    }
    else {
      // Invalid response. Clear all tokens.
      this.logout(AuthTokenState.NO_ACCESS_TOKEN);
    }
  }

  public async handleAuthFailure(error?) {
    if (error === "popup_closed_by_user") {
      this.logout(AuthTokenState.CANCELLED);
    }
    else {
      this.logout(AuthTokenState.ERROR);
    }
  }


  public async logout(state: AuthTokenState = AuthTokenState.NO_ACCESS_TOKEN) {
    console.log('=== AUTH LOGOUT ===');
    this.jwtTokenService.setToken(null);
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    if (this.state$.value !== state) {
      this.state$.next(state);
    }
  }

}
