import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthTokenService } from '../auth-token.service';
import { LoginState, UserAuthService } from '../user-auth.service';


@Injectable({
  providedIn: 'root'
})
export class GoogleService {
	// static GOOGLE_CLIENT_ID: string = "82300956489-jdehvfkjtuf1pu0i1q6lo005n2derud9.apps.googleusercontent.com";
	static GOOGLE_CLIENT_ID: string = "613362574245-ngt8q8m30sot29rqrusmdvcj22c4ccst.apps.googleusercontent.com";

  constructor(
    private readonly userAuthService: UserAuthService,
  ) {
  }

  private signInResultHandlers: Array<Function> = [];
  public registerSignInResultHandler(handler: Function) {
    // Push this handler if it's not already in the list
    if (this.signInResultHandlers.indexOf(handler) === -1) {
      this.signInResultHandlers.push(handler);
    }
  }
  public removeSignInResultHandler(handler: Function) {
    // Remove this handler if it's in the list
    const index = this.signInResultHandlers.indexOf(handler);
    if (index !== -1) {
      this.signInResultHandlers.splice(index, 1);
    }
  }

  async handleCredentialResponse(googleAuthResponse: any) {
    this.userAuthService.setState(LoginState.LOGGING_IN);

    if (!googleAuthResponse) {
      this.userAuthService.setState(LoginState.ERROR);
      console.log('=== GOOGLE SIGN IN ERROR ===');
      console.log(googleAuthResponse);
      return;
    }
    if (!googleAuthResponse.credential) {
      this.userAuthService.setState(LoginState.ERROR);
      console.log('=== GOOGLE SIGN IN ERROR (NO CREDENTIAL) ===');
      console.log(googleAuthResponse);
      return;

      // TODO: ???
      // console.log('=== GOOGLE SIGN IN SUCCESS (NO AUTHORIZATION) ===');
      // AuthTokenService.instance.handleAuthFailure(googleAuthResponse.detail);
      // for (const handler of this.signInResultHandlers) {
      //   handler(null, "no authorization", googleAuthResponse.detail);
      // }

    }

    // Handle successful response.
    console.log('=== GOOGLE SIGN IN SUCCESS ===');
    console.log(googleAuthResponse);
    const uri = environment.googleRedirectUri;

    try {
      // Get/set the system id from local storage
      const systemId: string = AuthTokenService.instance.getSystemId(true);
      const response = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-system-id': systemId
        },
        body: JSON.stringify({id_token: googleAuthResponse.credential})
      });
      console.log('=== GOOGLE SIGN IN SUCCESS (BACK-END) ===');

      const body = await response.json();
      // If this is a 200 response, then we have a valid token
      if (response.status >= 200 && response.status < 300) {
        AuthTokenService.instance.handleAuthResponse(body);
      }
      else {
      // If this is a 401 response, then we have an invalid token
        AuthTokenService.instance.handleAuthFailure(body);
        return;
      }

      for (const handler of this.signInResultHandlers) {
        handler(body, null, null);
      }
    }
    catch (error) {
      console.log('=== GOOGLE SIGN IN ERROR (BACK-END) ===');
      console.log(error);
      AuthTokenService.instance.handleAuthFailure(error);
    }
  }
}
