import {ApolloClientOptions, InMemoryCache, ApolloLink, Observable } from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { environment } from '../environments/environment';
import {onError} from '@apollo/client/link/error'
import { AuthTokenService } from './auth-token.service';

const handleTokenExpirationLink = (): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    return new Observable(observer => {
      const subscription = forward(operation).subscribe({
        next: response => {          // Check if the response contains an error indicating token expiration
          const { errors } = response;
          const isTokenExpired = errors && errors.some(error => error.message === 'TokenExpiredError');

          if (isTokenExpired) {
            // Handle token expiration, attempt to refresh token and retry the request
            AuthTokenService.instance.refreshAccessToken().then(newToken => {
              if (!newToken) {
                // No new token, pass the response along
                observer.next(response);
                observer.complete();
                return;
              }
              // Update the authorization header with the new token
              operation.setContext(({ headers }) => ({
                headers: {
                  ...headers,
                  Authorization: `Bearer ${newToken}`,
                },
              }));

              // console.log('=== GRAPHQL REFRESHED TOKEN ===');
              // // Print the headers
              // console.dir(operation.getContext());

              // Retry the original GraphQL request
              forward(operation).subscribe(observer);
            }).catch(error => {
              // Handle token refresh failure
              observer.error(error);
            });
          } else {
            // No token expiration, pass the response along
            observer.next(response);
            observer.complete();
          }
        },
        error: err => {
          observer.error(err);
        },
      });

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    });
  });
};


  export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

  const auth = setContext((operation, context) => {
    console.log("Operation: " + operation.operationName + " With variables: " )
    console.log(operation.variables)

    const token = localStorage.getItem('access_token');
    const systemId = localStorage.getItem('system_id');

    // console.log("Token Stored: " + token)
    if (token) {
      if (!environment.production || false) {
        console.log('=== GRAPHQL AUTHORIZATION HEADER ===');
        console.log("Token: " + token)
      }
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
          'x-system-id': systemId ? systemId : '',
        }
      };
    } else {
      return {};
    }
  });

  const onErrror = onError(({ graphQLErrors, networkError, response, operation }) => {
    console.log("==== ERROR ====");
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError.message.replace} ${networkError.cause} ${networkError.name} ${networkError.stack}`);
    }

    // If the response has data associated with it, then attach that data to the error
    if (response && response.data && response.errors) {
      (response.errors as any) = {
        data: response.data,
        errors: response.errors
      };
    }
  })

  const uri = environment.api;

  return {
    link: ApolloLink.from([auth, onErrror, handleTokenExpirationLink(), httpLink.create({ uri })]),
    cache: new InMemoryCache(),
    // defaultOptions: {
    //   watchQuery: {
    //     fetchPolicy: 'network-only',
    //   },
    //   query: {
    //     fetchPolicy: 'network-only',
    //   },
    // },
  };
}

