import { gql } from "apollo-angular";

export const UserPaymentMethodDetails = gql`
fragment UserPaymentMethodDetails on UserPaymentMethod {
  id
  scheme
  accountId
  countryCode
  created
  expDate
  expMonth
  expYear
  firstNumbers
  lastNumbers
  live
  type
  updated
  archivedDate
}
`;

