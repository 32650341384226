<div class="max-padding w-full h-full flex flex-row justify-center min-h-screen">
  <div class="center-content-container flex flex-col relative overflow-y-auto">
    <div class="flex flex-row justify-between items-center">
        <div class="page-header-title">Welcome back, {{navControlService?.user?.firstName}}</div>
        <div class="flex flex-row">
          <div class="icon-white-zinc-700" (click)="showUpsertVenue()">
            <fa-icon [icon]="FaCreate"></fa-icon>
          </div>
        </div>
    </div>
    <div class="flex flex-row justify-between items-center mt-12 mb-6 sticky top-10">
        <div class="section-header-title">Your Businesses</div>
        <div class="flex flex-row">
          <!-- <button *ngIf="navControlService.hasRole(NavRole.ADMIN)" class="ml-4 text-business-blue" (click)="showAllVenues()">ALL</button> -->
        </div>
    </div>
    <div *ngIf="venuesState !== FetchState.LOADING && venues?.length === 0"
    class="no-content-container">
      <div class="button-white-zinc-700 flex flex-col justify-center items-center corner-25" (click)="showUpsertVenue()">
        Add Your First Business!
      </div>
    </div>
    <div *ngIf="venues?.length > 0" class="flex flex-col justify-start items-center flex-grow bg-white text-gray-900">
        <div *ngFor="let venue of this.venues" class="w-full mt-6 flex flex-col justify-between items-center rounded-3xl border border-gray-200 p-6">
          <div class="w-full flex flex-row justify-between items-center">
            <div class="flex flex-row justify-start items-center flow-grow">
              <img *ngIf="logoImageUrl(venue)" [src]="logoImageUrl(venue)" alt="Logo" class="w-12 h-12 mr-4 object-cover" />
              <button (click)="selectVenue(venue)" class="flex flex-col justify-start w-full">
                  <p class="text-xl antialiased text-left">{{venue.name}}</p>
                  <p class="text-gray-500 text-left">{{getVenueStreetAddresses(venue.venueAddresses)}}</p>
              </button>
            </div>
            <div class="button-white-zinc-700 flex flex-col justify-center items-center w-10 h-10 corner-25" (click)="showUpsertVenue(venue)">
              <fa-icon [icon]="FaEdit" class="text-lg"></fa-icon>
            </div>
          </div>
        </div>
    </div>

    <!-- Loading Cover -->
    <div *ngIf="venuesState === FetchState.LOADING" class="flex justify-center items-center h-full absolute inset-0 bg-gray-100 bg-opacity-25">
      <div class="loader-wrapper flex justify-center items-center">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
      </div>
    </div>
</div>

<app-modal [isActive]="!!venue" (close)="handleClickOutsideModal()">
  <app-edit-venue [venue]="venue" (close)="handleClickOutsideModal()"></app-edit-venue>
</app-modal>
