import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FetchState } from '../app.module';
import { Apollo } from 'apollo-angular';
import { FetchLinkTokenQuery, GetDwollaServiceCustomerQuery } from '../graphql/queries/ach_account.query.graphql';
import { AchAccount, IAchAccountOwner } from '../model/ach_account.model';
import { RemoveAchAccountMutation, UpsertAchAccountMutation } from '../graphql/mutations/ach_account.mutation.graphql';
import { DwollaServiceCustomer } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class AchAccountService {

  plaidConfig$: BehaviorSubject<any> = new BehaviorSubject(null);
  accountLinkedResult$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private readonly apollo: Apollo,
  ) { }

  async fetchLinkToken(ownerId: string, redirectUri: string = null): Promise<string> {
    if (!ownerId) {
      return null;
    }

    return new Promise((resolve, reject) => {
        this.apollo.query({
        query: FetchLinkTokenQuery,
        variables: {
          ownerId: ownerId,
          redirectUri: redirectUri
        }
      }).subscribe({
        next: ({ data, loading }) => {
          console.log("Link Token", data);
          if (data) {
            if (data['fetchLinkToken']) {
              resolve(data['fetchLinkToken']);
            }
            else {
              resolve(null);
            }
          }
          else {
            resolve(null);
          }
        },
        error: (error) => {
          console.log("Error fetching link token", error);
          reject(error);
        }
      });
    });
  }

  async fetchDwollaServiceCustomer(ownerId: string): Promise<DwollaServiceCustomer> {
    if (!ownerId) {
      return null;
    }

    return new Promise((resolve, reject) => {
        this.apollo.query({
        query: GetDwollaServiceCustomerQuery,
        variables: {
          id: ownerId,
        },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next: ({ data, loading }) => {
          let result: DwollaServiceCustomer;
          if (data && data['dwollaServiceCustomer']) {
            result = DwollaServiceCustomer.parseResponse(data['dwollaServiceCustomer']);
            resolve(result);
          }
          else {
            resolve(null);
          }
        },
        error: (error) => {
          console.log("Error fetching link token", error);
          reject(error);
        }
      });
    });
  }

  upsertAchAccountState$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  async upsertAchAccount(publicToken: string, accountId: string, accountName: string, ownerId: string, controllerUserId?: string): Promise<AchAccount> {
    console.log(`upsertAchAccount`);

    this.upsertAchAccountState$.next(FetchState.LOADING);

    //GraphQL mutation
    return new Promise<AchAccount>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: UpsertAchAccountMutation,
          variables: {
            publicToken,
            accountId,
            accountName,
            ownerId,
            controllerUserId
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          let result: AchAccount = null;
          if (data && data['upsertAchAccount']) {
            // // If the affiliate is not already in the list, add it, otherwise update the existing one
            result = AchAccount.parseResponse(data['upsertAchAccount']);
            this.upsertAchAccountState$.next(FetchState.LOADED_ALL);
          }
          else {
            this.upsertAchAccountState$.next(FetchState.ERROR);
          }
          resolve(result);
        },
        error: (error) => {
          console.log(error);
          this.upsertAchAccountState$.next(FetchState.ERROR);
          reject(error);
        }
      });
    });
  }

  stateRemove: FetchState = FetchState.NONE;
  async remove(owner: IAchAccountOwner): Promise<boolean> {
    if (!owner || !owner.achAccount || !owner.achAccount.id) {
      return true;
    }
    return this._remove(owner);
  }

  private _remove(owner: IAchAccountOwner): Promise<boolean> {
    if (this.stateRemove === FetchState.LOADING) {
      return Promise.resolve(false);
    }

    this.stateRemove = FetchState.LOADING;

    return new Promise<boolean>((resolve, reject) => {
      this.apollo.mutate({
        mutation: RemoveAchAccountMutation,
        variables: {
          id: owner.achAccount.id,
          ownerId: owner.id
        }
      }).subscribe({
        next: ({ data, loading }) => {
          const result = (data ? data['removeAchAccount'] : false) ?? false;
          if (result) {
            owner.achAccount = null;
            this.stateRemove = FetchState.LOADED_ALL;
            resolve(result);
          }
          else {
            this.stateRemove = FetchState.ERROR;
            reject(false);
          }
        },
        error: (error) => {
          console.log("Error removing ach account", error);
          this.stateRemove = FetchState.ERROR;
          reject(error);
        }
      });
    });
  }

}
