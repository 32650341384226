<div class="w-full p-4 bg-white rounded-[20px] flex-col justify-start items-start gap-[15px] flex shadow-sm">
  <div class="self-stretch text-zinc-600 text-xl font-normal leading-7">How to Earn this Donation Offer</div>
  <div class="self-stretch justify-start items-center gap-0.5 inline-flex">
    <div class="w-10 h-10 relative">
      <div class="pr-3">
        <svg width="40" height="40" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path [attr.d]="claimSteps[0].path" fill="#ED2D92" />
        </svg>
      </div>

    </div>
    <div class="flex-col justify-center items-start inline-flex">
      <div class="px-2.5 justify-start items-center gap-2.5 inline-flex">
        <div class="text-black text-base font-bold antialiased leading-normal">Sign Up and Claim the Offer</div>
      </div>
      <div class="px-2.5 justify-start items-center gap-2.5 inline-flex">
        <div class="text-black text-sm font-light leading-tight">Sign in with Apple or Google and link the Credit Card
          you will use when you visit</div>
      </div>
    </div>
  </div>
  <div class="self-stretch justify-start items-center gap-0.5 inline-flex">
    <div class="w-10 h-10 relative">
      <div class="pr-3"><svg width="40" height="40" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path [attr.d]="claimSteps[1].path" fill="#ED2D92" />
        </svg></div>
    </div>
    <div class="flex-col justify-start items-start inline-flex">
      <div class="px-2.5 justify-start items-center gap-2.5 inline-flex">
        <div class="text-black text-base font-bold antialiased leading-normal">Visit and Pay with your Credit Card</div>
      </div>
      <div class="px-2.5 justify-start items-center gap-2.5 inline-flex">
        <div class="text-black text-sm font-light leading-tight">Visit the business and pay with your linked card</div>
      </div>
    </div>
  </div>
  <div class="self-stretch justify-start items-center gap-0.5 inline-flex">
    <div class="w-10 h-10 relative">
      <div class="pr-3"><svg width="40" height="40" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path [attr.d]="claimSteps[2].path" fill="#ED2D92" />
        </svg></div>
    </div>
    <div class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
      <div class="px-2.5 justify-start items-center gap-2.5 inline-flex">
        <div class="text-black text-base font-bold antialiased leading-normal">Earn Your Donation</div>
      </div>
      <div class="self-stretch px-2.5 justify-start items-center gap-2.5 inline-flex">
        <div class="grow shrink basis-0 text-black text-sm font-light leading-tight">We will notify you when you earn a
          cash back donation and send the donation automatically</div>
      </div>
    </div>
  </div>
</div>
