import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, take } from 'rxjs';
import { NavControlService } from '../nav/nav-control.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit, OnDestroy {

  constructor(
    private readonly translate: TranslateService,
    private readonly navControlService: NavControlService,
  ) { }

  ngOnInit(): void {
    for(let i = 0; i < this.questions.length; i++) {
      this.setupQuestion(this.questions[i]);
    }

  }

  private setupQuestion(question) {
    const label = question.label;
    this.translate.get(`FAQ_${label}_Q`)
    .pipe(take(1))
    .subscribe({
      next: (text) => {
        question.question = text;
      }
    });

    this.translate.get(`FAQ_${label}_A`)
    .pipe(take(1))
    .subscribe({
      next: (text) => {
        question.answer = text;
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }

  questions: any[] = [
    { label: '1', question: "", answer: "Coming soon...", isOpen: false },
    { label: '2', question: "How much does it cost?", answer: "", isOpen: false },
    { label: '3', question: "How much can my team make?", answer: "", isOpen: false },
    // { question: "How do I get started?", answer: "", isOpen: false },
  ]

  toggleFAQ(question: any, i: number) {
    question.isOpen = !question.isOpen
  }

  goToContact() {
    this.navControlService.goToContact();
  }

}
