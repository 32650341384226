import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-promo',
  templateUrl: './edit-promo.component.html',
  styleUrls: ['./edit-promo.component.css']
})
export class EditPromoComponent implements OnInit {
  @Input() parentForm: FormGroup;
  promoForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.promoForm = this.fb.group({
      id: [''],
      medium: [''],
      disabledDate: ['']
    });
    this.parentForm.addControl('promo', this.promoForm);
  }
}
