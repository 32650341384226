import { gql } from "apollo-angular";
import { LedgerDetails } from "./ledger.fragment.graphql";
import { AchAccountDetails } from "./ach_account.fragment.graphql";

export const AffiliateOperatorDetails = gql`
fragment AffiliateOperatorDetails on AffiliateOperator {
  id
  primary
  operator {
    id
    user {
      id
      firstName
      lastName
      emails {
        value
      }
    }
  }
}`;

export const AffiliateDetails = gql`
fragment AffiliateDetails on Affiliate {
  id
  name
  code
  description
  createdDate
  updatedDate
  description
  intPlatformPercentage
  intPlatformAmount
  intDurationDays
  archivedDate
  affiliateVenues {
    id
    disabledDate
    endDate
    intPlatformPercentage
    intPlatformAmount
    intDurationDays
    returnCurrency
    intMinSpend
    intMaxReward
    venue {
      id
      name
    }
  }
  affiliateOperators {
    ...AffiliateOperatorDetails
  }
  ledger {
    ...LedgerDetails
  }
  achAccount {
    ...AchAccountDetails
  }
}
${AffiliateOperatorDetails}
${LedgerDetails}
${AchAccountDetails}
`;

