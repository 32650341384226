<app-main-container class=""
[backgroundImagePath]="'/assets/images/business-landing.jpeg'"
[useBgImageFilter]="true"
[overylayImagePath]="''"
[header]="'List Your Business'"
[subHeader]="'You have a unique experience to offer and you need to reach people who want to experience it. With GetZeals you can. We help you find new customers and you give them a great experience to remember.'"
[buttonLabel]="'Book a Demo'"
[buttonClass]="'button-business-blue-borderless'"
(buttonClicked)="handleMainButtonClick()"
></app-main-container>
<div class="flex w-full h-full flex-col items-center bg-gray-200 pt-12">
  <div #waitlist class="max-w-8xl max-padding flex flex-col justify-center items-center pt-12">
      <app-waitlist [businessType]="BusinessType.Business"></app-waitlist>
  </div>
</div>

