import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { FetchState } from '../../../app.module';
import { User } from 'src/app/model/user.model';
import { S3Service } from 'src/app/s3.service';
import { UserService } from 'src/app/services/user.service';
import { NavControlService, NavRole } from '../../nav/nav-control.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent {
  private _user: User;
  @Input()
  set user(value: User) {
    this._user = value;
    this.setup();
  }
  get user(): User {
    return this._user;
  }
  @Input() showCloseButton: boolean = true;

  @Output() close = new EventEmitter<void>();
  userForm: FormGroup;

  @ViewChild('avatarFileInput') avatarFileInput: ElementRef;

  FaTimes = faTimes;
  FaImage = faImage;
  NavRole = NavRole;

  constructor(
    private fb: FormBuilder,
    private readonly userService: UserService,
    protected readonly navControlService: NavControlService,
    private readonly s3Service: S3Service,
  ) {
  }

  FetchState = FetchState;
  saveState: FetchState = FetchState.NONE;

  setup() {
    this.userForm = this.fb.group({
      id: [{ value: this.user?.id, disabled: true }],
      avatarImageFile: [null],
      firstName: [this.user?.firstName, Validators.required],
      lastName: [this.user?.lastName],
    });
  }

  async save() {
    if (this.saveState === FetchState.LOADING) {
      return;
    }

    if (this.userForm.valid) {
      this.saveState = FetchState.LOADING;

      const userFormValues = this.userForm.getRawValue();
      this.user.firstName = userFormValues.firstName ?? this.user.firstName;
      this.user.lastName = userFormValues.lastName ?? this.user.lastName;

      try {
        let result = await this.userService.save(this.user);
        if (!!result) {
          // Success! Close
          this.user.firstName = result.firstName ?? this.user.firstName;
          this.user.lastName = result.lastName ?? this.user.lastName;

          // Step 2: Fetch Upload URL for Logo Image
          const avatarSelectedFile = this.userForm.get('avatarImageFile')?.value;
          const avatarImage = result.userImage;
          this.user.userImage = avatarImage;
          if (avatarSelectedFile && avatarImage) {
            try {
              const uploadUrl = await this.userService.fetchImageUploadUrl(avatarImage.id, avatarSelectedFile.type);

              // Step 2: Upload File to S3
              const uploadResult = !!uploadUrl ? (await this.s3Service.uploadFileToS3(uploadUrl, avatarSelectedFile)) : false;
              console.log(`uploadResult`, uploadResult);

              if (uploadResult) {
                // Get the download url
                avatarImage.url = await this.userService.fetchImageDownloadUrl(avatarImage.id, true) + '?cachebust=' + new Date().getTime();
                avatarImage.preview = this.selectedAvatarFilePreviewUrl;
              }
            }
            catch(error) {
              console.log(`error uploading file to S3`, error);
            }
          }


          this.saveState = FetchState.LOADED_ALL;
          this.close.emit();
        }
        else {
          this.saveState = FetchState.ERROR;
        }
      }
      catch (err) {
        console.error(err);

        // Update invalid controls
        if (err?.graphQLErrors?.length > 0) {
          const errors = err.graphQLErrors;
          for(let graphqlError of errors) {
            if (graphqlError.message === 'invalid firstName') {
              this.userForm.get('firstName').setErrors({ server: 'Invalid first name' });
            }
            if (graphqlError.message === 'invalid lastName') {
              this.userForm.get('lastName').setErrors({ server: 'Invalid last name' });
            }
          }
        }

        this.saveState = FetchState.ERROR;
      }
    }
    else {
      // Which fields are invalid?
      for(const controlName in this.userForm.controls) {
        if (this.userForm.controls[controlName].invalid) {
          console.log(`Control ${controlName} is invalid`);
        }
        if (this.userForm.controls[controlName].pending) {
          console.log(`Control ${controlName} is pending`);
        }
        if (!this.userForm.controls[controlName].valid) {
          console.log(`Control ${controlName} is not valid`);
        }
      }
    }
  }

  // Handle file input
  selectedAvatarFilePreviewUrl: string | ArrayBuffer = null;
  get avatarImagePreviewUrl(): string | ArrayBuffer {
    if (this.selectedAvatarFilePreviewUrl) return this.selectedAvatarFilePreviewUrl;
    const avatarImage = this.user?.userImage;
    if (avatarImage && avatarImage?.url) return avatarImage?.url;
    return null;
  }
  onFileSelected(event, fileId: string) {
    if (event.target.files.length > 0) {
      const selectedFile = <File>event.target.files[0];
      this.userForm.get(fileId).setValue(selectedFile);

      // Create Image Preview
      const reader = new FileReader();

      reader.onload = () => {
        if (fileId === 'avatarImageFile') this.selectedAvatarFilePreviewUrl = reader.result;
        this.userForm.get(fileId).setValue(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
    }
    else {
      this.userForm.controls[fileId]?.markAsTouched();
    }
  }

  openFileInput(fileId: string): void {
    if (fileId === 'AVATAR') {
      this.avatarFileInput.nativeElement.click();
    }
  }

  onCloseFile(fileId: string) {
    this.userForm.controls[fileId]?.markAsTouched();
    // If bannerImageFile is valud, then clear the errors on it
    console.log(`${fileId} is valid?`, this.userForm.controls[fileId].getRawValue());
    if (this.userForm.controls[fileId]?.valid) {
      this.userForm.controls[fileId]?.setErrors(null);
    }
  }

  onFileSelectError($event, fileId: string) {
    console.log(`onFileSelectError`, $event);
  }


  resetForm() {
    this.userForm.reset(this.user);
  }

  closeModal() {
    this.close.emit();
  }

}
