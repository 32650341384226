<div class="w-full h-full flex justify-center items-start gz-bg-gradient">
  <div class="flex flex-col justify-center items-center py-[50px] px-[50px] lg:px-[200px] center-content-container">
      <div class="resp-title mb-6 w-full">About GetZeals</div>
      <div class="resp-para mb-12">Welcome to GetZeals, where your dining experience becomes not just about savoring flavors, but also about unlocking rewards and contributing to your community. We're on a mission to redefine how you engage with local businesses and make every meal an opportunity for something more.</div>

      <div class="resp-title mb-6 w-full">Your Journey with GetZeals</div>
      <div class="resp-para mb-12">At GetZeals, we're not just a platform; we're your companion in exploring the best of what your city has to offer. Whether you're a food enthusiast, a savvy shopper, or someone who simply loves to connect with your community, GetZeals is designed with you in mind.</div>

      <div class="resp-title mb-6 w-full">What Makes Us Unique</div>
      <div class="resp-para mb-6"><span class="font-bold">Rewarding Your Choices:</span> Every time you dine out, shop, or engage with local businesses through GetZeals, you earn cashback rewards. It's our way of saying thank you for choosing local and being a part of something bigger.</div>
      <div class="resp-para mb-6"><span class="font-bold">Discovering Local Gems:</span> Explore a curated selection of local restaurants, shops, and events. GetZeals opens the door to hidden gems, ensuring that every experience is a delightful discovery.</div>
      <div class="resp-para mb-12"><span class="font-bold">Contributing to Community Growth:</span> Your choices matter beyond your table. GetZeals connects you with local initiatives and fundraisers, transforming your patronage into positive contributions for your community.</div>

      <div class="resp-title mb-6 w-full">Our Promise to You</div>
      <div class="resp-para mb-6">GetZeals is more than an app; it's your key to a richer, more rewarding lifestyle. From personalized deals to supporting local causes, we're committed to enhancing your everyday experiences.</div>
      <div class="resp-para mb-12">Join us in creating a community where every meal and every choice brings joy, connection, and a sense of shared prosperity. Welcome to GetZeals, where your choices make a difference.</div>
  </div>
</div>
