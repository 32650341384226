import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LoginState } from 'src/app/user-auth.service';
import { NavControlService } from 'src/app/components/nav/nav-control.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-apple-login',
  templateUrl: './apple-login.component.html',
  styleUrls: ['./apple-login.component.css']
})
export class AppleLoginComponent implements OnInit, OnDestroy {
  private static CLIENT_ID: string = 'com.getzeals.app.login';

  constructor(
    private renderer: Renderer2,
    private readonly navControlService: NavControlService,
  ) {
  }

  get redirectUrl(): string {
    return `${window.location.origin}/apple/redirect`;
  }

  private appleId: any;
  async ngOnInit() {
    const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    // Initial check and button setup
    this.updateAppleSignInButton(colorSchemeQuery.matches);

    // Listen for changes
    colorSchemeQuery.onchange = (event) => {
      this.updateAppleSignInButton(event.matches);
    };

    this.loadScript('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js').then((d: any) => {
      this.appleId = (window as any).AppleID;

      if (this.appleId?.auth) {
        this.appleId.auth.init({
          clientId: AppleLoginComponent.CLIENT_ID,
          scope : 'name email',
          responseMode: 'form_post',
          // redirectURI: AppleService.REDIRECT_URI, // `${window.location.origin}/apple-signin`,
          redirectURI: `${window.location.origin}/apple/redirect`,
          state: '',
          usePopup: true,
        });
        }
      else {
        // TODO: Do something to show that Google login is not available
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Update the Apple Sign In button based on the current color scheme
  private updateAppleSignInButton(isDarkMode) {
    // const appleButton = document.getElementById('appleid-signin');
    // if (isDarkMode) {
    //   // Set button for dark mode
    //   appleButton.setAttribute('data-color', 'white');
    // } else {
    //   // Set button for light mode
    //   appleButton.setAttribute('data-color', 'black');
    // }
  }

  async handleLoginClick() {
    this.navControlService.setUserState(LoginState.LOGGING_IN);
  }

  private scriptLoaded: boolean = false;
  private loadScript(url: string): Promise<void> {
    const that = this;
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = url;

      script.onload = () => {
        // Script loaded successfully
        that.scriptLoaded = true;
        resolve();
      };

      script.onerror = (error) => {
        // Script loading failed
        that.scriptLoaded = false;
        reject(error);
      };

      this.renderer.appendChild(document.body, script);
    });
  }
}
