import { gql } from "apollo-angular";
import { VenueImageDetails } from "./venue-image.fragment.graphql";
import { VenueAddressDetails } from "./venue-address.fragment.graphql";
import { AchAccountDetails } from "./ach_account.fragment.graphql";
import { UserDetails } from "./user.fragment.graphql";
import { UserInviteDetails } from "./invite.fragment.graphql";

export const VenueOperatorDetails = gql`
${UserDetails}
fragment VenueOperatorDetails on VenueOperator {
  id
  primary
  operator {
    id
    user {
      ...UserDetails
    }
  }
}`;
    // user {
    //   id
    //   firstName
    //   lastName
    //   emails {
    //     value
    //   }
    // }

export const VenueDetails = gql`
    ${VenueImageDetails}
    ${VenueAddressDetails}
    ${AchAccountDetails}
    ${VenueOperatorDetails}
    ${UserInviteDetails}
    fragment VenueDetails on Venue {
      id
      name
      description
      achAccount {
        ...AchAccountDetails
      }
      venueImages {
        ...VenueImageDetails
      }
      venueAddresses {
        ...VenueAddressDetails
      }
      venueOperators {
        ...VenueOperatorDetails
      }
      invites {
        ...UserInviteDetails
      }
    }
`;

