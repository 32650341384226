import { gql } from "apollo-angular";


export const OfferViewDetails = gql`
fragment OfferViewDetails on OfferView {
  id
  promotion {
    ... on PlatformPromotion {
      id
      incentive {
        id
      }
    }
  }
  viewedDate
  claim {
    id
    claimedDate
  }
  patron {
    id
    user {
      id
      firstName
      lastName
    }
  }
}
`;
