import { gql } from "apollo-angular";

export const VenueImageDetails = gql`
    fragment VenueImageDetails on VenueImage {
      id
      name
      type
      dateAdded
      url
      urlExpiry
      venue {
        id
      }
    }
`;
