<div class="content">
  <div class="w-full h-full py-[19px] flex flex-row-reverse justify-center items-start gap-[46px]">
    <div #mainListContainer (scroll)="onScroll($event)"
      class="main-list-container">

      <div class="relative w-full flex flex-row items-center justify-between mb-4 section-pad">
        <div [ngClass]="{'invisible': !userOfferService.offer}" class="flex-col justify-center items-start w-full inline-flex gap-3">
          <div class="flex-row justify-between w-full items-center flex">
            <div class="title flex-grow">
              {{userOfferService.offer?.name || userOfferService.offer?.venue?.name}}
            </div>
            <div class="flex flex-row gap-2">
              <div (click)="shareInfo()" class="cursor-pointer flex-col justify-end text-gray-500 text-xl font-medium">
                <fa-icon [icon]="faShare" ></fa-icon>
                <input #shareUrl type="text" [value]="offerQrCode" readonly class="hidden">
                <div class="relative">
                  <div *ngIf="isShareConfirmationVisible" class="tooltip">
                    Link copied to clipboard!
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="w-full flex flex-row items-center justify-start gap-3">
            <div *ngIf="!!intReturnPercentage || !!intReturnAmount"
              [ngClass]="{'reward-pop-charity': userOfferService?.isCharityOffer, 'reward-pop': !userOfferService?.isCharityOffer}">
              <div *ngIf="!!intReturnPercentage" class="text-center">
                <div>{{(intReturnPercentage / 100.0)}}%</div>
                <!-- <div class="text-white text-xs font-light antialiased">{{ 'OF_PURCHASE' | translate }}</div> -->
              </div>
              <div *ngIf="!!intReturnAmount" class="text-center">
                <div>{{(intReturnAmount / 100.0) | currency}}</div>
                <!-- <div class="text-white text-xs font-light">{{ 'PER_PURCHASE' | translate }}</div> -->
              </div>
            </div>
            <div class="title-details-container flex-grow">
              <div class="title">
                {{ userOfferService?.isCharityOffer ? 'Donation' : 'Cash back' }} when you use your linked card at venue.
              </div>
              <div class="flex flex-row justify-between items-end">
                <div class="details">
                  {{ userOfferService?.validDisplayDate('Valid') }}
                </div>
                <div *ngIf="!!userOfferService?.offerClaim" class="flex-grow text-right text-sm font-medium leading-none"
                [ngClass]="{'text-charity-green': userOfferService?.isCharityOffer, 'text-pink': !userOfferService?.isCharityOffer}">
                  <span *ngIf="userOfferService.rewardedOffer">REWARDED</span>
                  <span *ngIf="!userOfferService.rewardedOffer">CLAIMED</span>
                </div>
              </div>
              <!-- *ngIf="userState === LoginState.LOGGED_IN && !!userOfferService.offerClaim" -->
              <button
              *ngIf="userState === LoginState.LOGGED_IN && !!userOfferService.offerClaim && hasRole(NavRole.ADMIN)"
              (click)="userOfferService.unclaimOffer()">Unclaim Offer</button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!!userOfferService.offerClaim && cardLinkEvent === CardLinkEventType.CARD_LINKED"
        class="w-full flex flex-row items-center mb-6 section-pad">
        <img src="/assets/icons/checkmark-circle.svg" class="w-20 h-20 mr-2">
        <div class="text-gray-500 text-lg font-normal leading-relaxed flex-grow">All set! Time to visit <b>{{userOfferService?.offer?.venue?.name}}</b> {{ offerClaimedCallToActionPostscript}}</div>
      </div>

      <div class="section-pad">
        <div *ngIf="cardLinkEvent !== CardLinkEventType.CARD_LINKED && userNeedsToLinkCard"
          class="action-details-container mb-6"
          (click)="showCreditCards()">
          <div class="title">Link Card</div>
          <div class="call-to-action">to finish claim</div>
        </div>
      </div>

      <div class="section-pad">
        <div *ngIf="!userOfferService.offerClaim && stateClaim !== FetchState.PENDING && stateClaim !== FetchState.LOADING"
            (click)="showGetStartedLogin()"
            [ngClass]="{'action-details-container-charity': userOfferService?.isCharityOffer, 'action-details-container': !userOfferService?.isCharityOffer}"
            class="mb-6">
          <div *ngIf="!userOfferService.isCharityOffer"
            class="title">
            <span *ngIf="!!this.intReturnPercentage">{{(this.intReturnPercentage / 100.0)}}% {{ 'BACK' | translate
              }}</span>
            <span *ngIf="!!this.intReturnAmount">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'BACK' | translate
              }}</span>
          </div>
          <div *ngIf="userOfferService.isCharityOffer"
            class="title">
            <span *ngIf="!!this.intReturnPercentage">{{(this.intReturnPercentage / 100.0)}}% {{ 'DONATION' | translate
              }}</span>
            <span *ngIf="!!this.intReturnAmount">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'DONATION' |
              translate }}</span>
          </div>
          <div class="call-to-action">Claim Offer</div>
          <!-- <div class="details"
            [innerHTML]="handleOfferClaimedInfo() | async"></div> -->
        </div>
      </div>

      <div class="w-full h-52 relative mb-6 ">
        <div class="w-full block h-52 bg-stone-100 border-stone-200 border"
        ></div>
        <!-- *ngIf="!userOfferService.offerMainImageUrl" -->
        <img class="w-full block h-52 object-cover absolute inset-0"
          *ngIf="!!userOfferService.offerMainImageUrl"
          [src]="userOfferService.offerMainImageUrl">


        <div *ngIf="!userOfferService?.isCharityOffer && !!userOfferService?.logoImageUrl"
            class="absolute bottom-2 left-2">
          <div class="back-shadow">
            <div class="back-shadow-bg"></div>
            <div class="back-shadow-contents">
              <img class="w-20 h-20 rounded-xl object-cover aspect-square bg-zinc-300"
                [src]="userOfferService?.logoImageUrl">
            </div>
          </div>
        </div>
        <div *ngIf="!!userOfferService?.isCharityOffer"
          class="absolute -bottom-[10px] left-3 flex justify-center items-center z-60 ">
          <div class="relative">
            <div *ngIf="!!userOfferService?.logoImageUrl"
              class="absolute bottom-0 left-0 w-24 h-24 rounded-full flex justify-center items-center z-60 bg-zinc-300">
              <img class="w-24 h-24 rounded-full object-cover aspect-square"
                [src]="userOfferService?.logoImageUrl">
            </div>
            <div *ngIf="!!userOfferService?.subLogoImageUrl"
              class="back-shadow absolute -bottom-[20px] left-[60px] z-60">
              <div
                class="back-shadow-bg"
              ></div>
              <div
                class="back-shadow-contents"
              >
                <img class="w-14 h-14 rounded-lg object-cover aspect-square bg-stone-100"
                  [src]="userOfferService?.subLogoImageUrl">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!!userOfferService.offer?.description"
        class="section-pad">
        <div
          class="section-details-container">
          <div *ngIf="!!userOfferService?.isCharityOffer" class="title-center">{{ userOfferService?.offer?.charityIncentive?.charity?.name }} &amp; {{ userOfferService?.offer?.venue?.name }} Fundraiser</div>
          <div *ngIf="!userOfferService?.isCharityOffer" class="title-center">{{ userOfferService?.offer?.venue?.name }}</div>
          <div class="details">{{ userOfferService.offer.description }}</div>
        </div>
      </div>

      <div *ngIf="!!userOfferService?.isCharityOffer && userOfferService.offerMainImageUrl !== userOfferService.venueBannerImageUrl" class="w-full">
        <div class="w-full h-52 relative mb-6">
          <div class="w-full block h-full  bg-stone-100 border-stone-200 border"
          *ngIf="!userOfferService.venueBannerImageUrl"
          ></div>
          <img class="w-full block h-full object-cover "
            *ngIf="!!userOfferService?.venueBannerImageUrl"
            [src]="userOfferService.venueBannerImageUrl">

          <!-- <div
            class="absolute -bottom-[10px] left-2 w-28 h-28 rounded-full flex justify-center items-center z-60 "> -->
            <div *ngIf="!!userOfferService?.venueLogoImageUrl"
              class="absolute -bottom-[28px] left-3 h-20 bg-none flex flex-row justify-start items-start z-40 ">
              <div class="back-shadow">
                <div class="back-shadow-bg"></div>
                <div class="h-20 w-20 back-shadow-contents">
                  <img class="w-20 h-20 rounded-xl object-cover aspect-square bg-zinc-300"
      [src]="userOfferService?.venueLogoImageUrl">
                </div>
              </div>
              <div class="image-label ml-4 mt-4">
                {{ userOfferService?.offer?.venue?.name }}
              </div>
            </div>
          <!-- </div> -->
        </div>
        </div>

      <div *ngIf="!!userOfferService?.offer?.charityIncentive?.charity?.description"
        class="section-pad">
        <div
          class="section-details-container">
          <div class="title">About {{ userOfferService?.offer?.charityIncentive?.charity?.name }}</div>
          <div class="details">{{
            userOfferService?.offer?.charityIncentive?.charity?.description }}</div>
        </div>
      </div>

      <div class="section-pad"
      *ngIf="!!userOfferService.offer?.venue?.description"
      >
        <div
          class="section-details-container">
          <div class="title">About {{
            userOfferService.offer?.venue?.name }}</div>
          <div class="details">{{ userOfferService.offer.venue.description }}
          </div>
        </div>
      </div>

      <div class="section-pad">
        <div class="section-details-container">
          <div class="title">Location</div>
          <div *ngIf="userOfferService.offer?.venue?.venueAddresses?.length > 0"
            class="details">
            <img src="/assets/icons/zeals_logo_pink.svg" class="w-5 h-7 inline-block mr-1">
            {{userOfferService.offer?.venue?.venueAddresses[0] | address:"singleLine":false}}
          </div>
          <div *ngIf="userOfferService.venuePhone?.length > 0"
            class="details">
            {{userOfferService.venuePhone | phone}}
          </div>
        </div>
      </div>

      <!-- <div class="section-details-container">
        <div class="flex-col justify-start items-start flex flex-grow">
          <div class="w-full text-pink text-xl font-bold antialiased leading-7">{{ userOfferService.offer?.venue?.name
            ||
            userOfferService.offer?.name }}</div>
          <div *ngIf="userOfferService.offer?.venue?.venueAddresses?.length > 0"
            class="self-stretch text-black text-[15.14px] font-light leading-[17.89px]">
            {{userOfferService.offer?.venue?.venueAddresses[0] | address:"singleLine":false}}
          </div>
          <div *ngIf="userOfferService.venuePhone?.length > 0"
            class="self-stretch text-black text-[15.14px] font-light leading-[17.89px]">
            {{userOfferService.venuePhone | phone}}
          </div>
        </div>
      </div>
 -->
      <div class="section-pad">
        <div class="section-details-container">
          <app-offer-detail-info class="w-full" [showValidDaysAndHours]="true" [title]="'Valid Days and Hours'" [offer]="userOfferService.offer"></app-offer-detail-info>
        </div>
      </div>

      <div class="section-pad">
        <div class="section-details-container">
          <app-offer-detail-info class="w-full" [showMinSpend]="true" [showValidDates]="true" [title]="'Zeal Details'" [offer]="userOfferService.offer"></app-offer-detail-info>
        </div>
      </div>

      <div *ngIf="userOfferService.otherOffers?.length > 0" class="section-pad">
        <div class="section-details-container">
          <div class="title">More Offers from {{userOfferService.offer?.venue?.name}}</div>
          <div class="flex flex-col w-full">
            <div class="offer-list-item my-1" *ngFor="let offerData of userOfferService.otherOffers">
              <app-offer-list class="hidden xs:block cursor-pointer" [offer]="offerData.offer" (click)="selectOffer(offerData?.offer)"></app-offer-list>
              <app-offer-tile2 class="block xs:hidden w-full relative md:aspect-square" [offerData]="offerData" (click)="selectOffer(offerData?.offer)"></app-offer-tile2>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-12"
      [ngClass]="{'section-pad': howToEarnOpen, 'section-container-closed': !howToEarnOpen}"
      (click)="howToEarnOpen = !howToEarnOpen">
        <div *ngIf="!howToEarnOpen" class="section-details-container-closed">
          <div class="title">How it works</div>
          <img src="/assets/icons/right_arrow.svg" class="w-2.5 h-4 opacity-90">
        </div>
        <div class="section-details-container"
        *ngIf="howToEarnOpen"
        >
          <app-offer-how-to-earn
          class="w-full"></app-offer-how-to-earn>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Link Credit Card -->
<app-modal [isActive]="showLinkCard" (close)="closeModal()">
  <app-credit-cards
  class="mt-4 transition-all duration-300 h-auto" [hideFidelCloseButton]="true"
  [visible]="showLinkCard"
  [showFidelLinkCardOnly]="true" [initWithFidelAddCard]="true"
  (close)="handleCardLinkEvent(CardLinkEventType.CARD_LINK_CANCELLED)"
  (cardLinkEvent)="handleCardLinkEvent($event)" (cardLinked)="handleCardLinked($event)"></app-credit-cards>

</app-modal>

<div class="footer-container"
  *ngIf="!userOfferService.offerClaim && stateClaim !== FetchState.PENDING && stateClaim !== FetchState.LOADING"
  >
  <button class="button-patron-pink" *ngIf="userState === LoginState.LOGGED_IN"
    (click)="claimOffer()">{{ 'CLAIM_OFFER' | translate }}</button>
  <button class="button-patron-pink" *ngIf="userState === LoginState.LOGGED_OUT"
    (click)="showGetStartedLogin()">{{ 'CLAIM_OFFER' | translate }}</button>
</div>

<div class="footer-container"
  *ngIf="cardLinkEvent !== CardLinkEventType.CARD_LINKED && userNeedsToLinkCard && userState === LoginState.LOGGED_IN && !!userOfferService.offerClaim">
  <p class="details">Link a card for this offer before you visit.
    <button
    class="button-patron-pink w-full" (click)="showCreditCards()">Link card to get cash back</button>
</div>

<div *ngIf="stateClaim === FetchState.LOADING"
  class="absolute top-0 left-0 w-full h-full z-75 flex justify-center items-center bg-black bg-opacity-25">
  <div class="flex flex-col justify-center items-center bg-white rounded-md p-6">
    <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
    <div class="text-lg text-center">Claiming Offer</div>
  </div>
</div>
