import { Component } from '@angular/core';
import { BusinessType } from 'src/app/services/waitlist.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {

  BusinessType = BusinessType;

}
