import { AchAccount, IAchAccountOwner } from "./ach_account.model";
import { Entity } from "./entity.model";
import { BaseImage } from "./image.model";

export class UserEmail extends Entity {
  value: string;

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      value: this.value,
    };
  }

  static override parseResponse(details: any, result?: UserEmail): Entity {
    if (!details) return null;
    if (!result) result = new UserEmail();

    Entity.parseResponse(details, result);
    result.value = details.value;
    return result;
  }
}

export class UserPhone extends Entity {
  value: string;

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      value: this.value,
    };
  }

  static override parseResponse(details: any, result?: UserPhone): Entity {
    if (!details) return null;
    if (!result) result = new UserPhone();

    Entity.parseResponse(details, result);
    result.value = details.value;
    return result;
  }
}

export class UserImage extends BaseImage {

  static override parseResponse(details: any, result?: UserImage): UserImage {
    if (!details) return null;
    if (!result) result = new UserImage();

    BaseImage.parseResponse(details, result);
    return result;
  }
}

export class Patron extends Entity implements IAchAccountOwner {
  activated?: Date;
  deactivated?: Date;
  achAccount: AchAccount;

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      activated: this.activated?.toISOString() ?? "",
      deactivated: this.deactivated?.toISOString() ?? "",
      achAccount: this.achAccount?.toRequest(),
    }
  }

  static override parseResponse(details: any, result?: Patron): Patron {
    if (!details) return null;
    if (!result) result = new Patron();

    Entity.parseResponse(details, result);
    if (details.activated) {
      result.activated = new Date(details.activated);
    }
    if (details.deactivated) {
      result.deactivated = new Date(details.deactivated);
    }
    result.achAccount = AchAccount.parseResponse(details.achAccount);
    return result;
  }
}

export class Operator extends Entity {
  activated?: Date;
  deactivated?: Date;

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      activated: this.activated?.toISOString() ?? "",
      deactivated: this.deactivated?.toISOString() ?? "",
    };
  }


  static override parseResponse(details: any, result?: Operator): Entity {
    if (!details) return null;
    if (!result) result = new Operator();

    Entity.parseResponse(details, result);
    if (details.activated) {
      result.activated = new Date(details.activated);
    }
    if (details.deactivated) {
      result.deactivated = new Date(details.deactivated);
    }
    return result;
  }
}

export class UserAdmin extends Entity {
  activated?: Date;
  deactivated?: Date;

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      activated: this.activated?.toISOString() ?? "",
      deactivated: this.deactivated?.toISOString() ?? "",
    };
  }


  static override parseResponse(details: any, result?: UserAdmin): Entity {
    if (!details) return null;
    if (!result) result = new UserAdmin();

    Entity.parseResponse(details, result);
    if (details.activated) {
      result.activated = new Date(details.activated);
    }
    if (details.deactivated) {
      result.deactivated = new Date(details.deactivated);
    }
    return result;
  }
}

export class User extends Entity {
  firstName: string;
  lastName: string;
  emails: UserEmail[] = [];
  phones: UserPhone[] = [];
  userImage?: UserImage;
  patron?: Patron;
  operator?: Operator;
  userAdmin?: UserAdmin;

  get fullName(): string {
    // Handle nulls and undefineds and empty strings
    if (!this.firstName && !this.lastName) return "";
    if (!this.firstName) return this.lastName;
    if (!this.lastName) return this.firstName;
    return `${this.firstName} ${this.lastName}`;
  }

   get email(): string {
    if (!this.emails || this.emails.length == 0) return "";
    return this.emails[0].value;
   }

  constructor() {
    super();
  }

  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      firstName: this.firstName,
      lastName: this.lastName,
      emails: this.emails?.map(e => e.toJson()),
      phones: this.phones?.map(p => p.toJson()),
      userImage: this.userImage?.toJson(),
      patron: this.patron?.toJson(),
      operator: this.operator?.toJson(),
      userAdmin: this.userAdmin?.toJson(),
    };
  }


  static override parseResponse(details: any, result?: User): User {
    if (!details) return null;
    if (!result) result = new User();

    Entity.parseResponse(details, result);
    result.firstName = details.firstName;
    result.lastName = details.lastName;
    result.emails = details.emails?.map((e: any) => UserEmail.parseResponse(e));
    result.phones = details.phones?.map((p: any) => UserPhone.parseResponse(p));
    result.userImage = UserImage.parseResponse(details.userImage);
    result.patron = Patron.parseResponse(details.patron);
    result.operator = Operator.parseResponse(details.operator);
    result.userAdmin = UserAdmin.parseResponse(details.userAdmin);
    return result;
  }
}

export enum DwollaAccountType {
  // Receive-only
  RECEIVE_ONLY = "receive-only",
  // Unverified (Send and Receive)
  UNVERIFIED = "unverified",
  // Unknown
  UNKNOWN = "unknown"
}

export class DwollaServiceCustomer extends Entity {
  created?: Date;
  email?: string;
  businessName?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
  type?: DwollaAccountType;

  get fullName(): string {
    // Handle nulls and undefineds and empty strings
    if (!this.firstName && !this.lastName) return "";
    if (!this.firstName) return this.lastName;
    if (!this.lastName) return this.firstName;
    return `${this.firstName} ${this.lastName}`;
  }

  static override parseResponse(details: any, result?: DwollaServiceCustomer): DwollaServiceCustomer {
    if (!details) return null;
    if (!result) result = new DwollaServiceCustomer();

    Entity.parseResponse(details, result);
    if (details.created) {
      result.created = new Date(details.created);
    }
    result.email = details.email;
    result.businessName = details.businessName;
    result.firstName = details.firstName;
    result.lastName = details.lastName;
    result.status = details.status;
    if (details.type?.toLowerCase() === "receive-only") {
      result.type = DwollaAccountType.RECEIVE_ONLY;
    }
    else if (details.type?.toLowerCase() === "unverified") {
      result.type = DwollaAccountType.UNVERIFIED;
    }
    else {
      result.type = DwollaAccountType.UNKNOWN;
    }
    return result;
  }
}
