<div *ngIf="stateOffer !== FetchState.LOADED_ALL"
  class="w-full min-h-[97px] rounded-lg flex flex-col bg-zinc-200 xl:bg-white p-4 shadow-sm">
</div>

<div *ngIf="stateOffer === FetchState.LOADED_ALL">
  <div *ngIf="!claimedOffer"
    class="hidden sm:flex rounded-lg  flex-col w-full bg-white p-4 shadow-sm">
    <img src="assets/CombZealsLogo.png" class="w-[150px] h-[41.29px]" />
    <div class="w-full text-gray-500 text-sm font-light leading-[21px] pb-4">Get started earning rewards for free! Sign
      up with GetZeals Today.</div>
    <div class="w-full flex justify-start">
      <button class="button-patron-pink w-[200px]"
        *ngIf="!!controller && userState === LoginState.LOGGED_IN"
        (click)="userOfferService?.claimOffer()">{{ 'CLAIM_OFFER' | translate }}</button>
      <button class="button-patron-pink w-[200px]"
        *ngIf="!!controller && userState === LoginState.LOGGED_OUT"
        (click)="showGetStartedLogin()">{{ 'CLAIM_OFFER' | translate }}</button>
    </div>
  </div>

  <div *ngIf="claimedOffer"
    class="w-full min-h-[97px] rounded-lg flex flex-col bg-zinc-200 xl:bg-white p-4 shadow-sm">
    <div class="self-stretch text-pink text-xl font-bold antialiased leading-7">{{ 'OFFER_CLAIMED' | translate }}</div>
    <div class="self-stretch text-black text-lg font-light leading-normal" [innerHTML]="offerClaimedMessage() | async">
    </div>
    <div *ngIf="activeUserPaymentMethods?.length > 0"
      class="self-stretch text-black text-lg font-light leading-normal pt-4">
      {{ 'PAY_WITH_LINKED_CARDS' | translate }}: <span
        class="font-bold">{{userOfferService?.userPaymentsLastDigitsDisplay}}</span>
    </div>
    <button *ngIf="!!controller && !!userOfferService?.offerClaim" class="button-patron-pink w-full max-w-[230px]"
      (click)="userOfferService?.unclaimOffer()">Unclaim Offer</button>
  </div>
</div>
