import { gql } from "apollo-angular";
import { CharityDetails } from "../fragments/charity.fragment.graphql";

export const CharitiesQuery = gql`
query Charities($operatorId: String, $search: [SearchInput!]! = [], $sort: PageAndSortInput) {
    charities(operatorId: $operatorId, search: $search, sort: $sort) {
      ...CharityDetails
    }
  }
  ${CharityDetails}
  `;

export const CharityQuery = gql`
query Charity($id: String!) {
    charity(id: $id) {
      ...CharityDetails
    }
  }
  ${CharityDetails}
  `;
