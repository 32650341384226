import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faHeart as faClaim } from '@fortawesome/free-regular-svg-icons';
import { faGift, faHeart as faClaimed } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Subject, catchError, takeUntil, throwError } from 'rxjs';
import { LocationService } from 'src/app/location.service';
import { PatronOffer } from 'src/app/model/patron-offer.model';
import { UserOffersService } from 'src/app/services/user-offers.service';
import { LoginState } from 'src/app/user-auth.service';
import { OfferData } from '../offers.component';

@Component({
  selector: 'app-offer-tile2',
  templateUrl: './offer-tile2.component.html',
  styleUrls: ['./offer-tile2.component.css'],
})
export class OfferTile2Component implements OnInit, OnDestroy {
  @Input() actionable: boolean = true;
  @Input() offerData: OfferData;
  get offer(): PatronOffer {
    return this.offerData?.offer;
  }
  distance: number | null = null;
  distanceDisplay: string = "";

  get offerAddressLine1(): string {
    if (!this.offer?.venue?.venueAddresses?.length) return '';
    return `${this.offer.venue.venueAddresses[0].street}, ${this.offer.venue.venueAddresses[0].city}`;
  }
  get offerAddressLine2(): string {
    if (!this.offer?.venue?.venueAddresses?.length) return '';
    return `${this.offer.venue.venueAddresses[0].city}, ${this.offer.venue.venueAddresses[0].state}`;
  }
  get offerPhone(): string {
    if (!this.offer?.venue?.venueAddresses?.length) return '';
    return this.offer.venue.venueAddresses[0].phone;
  }

  location$ = this.locationService.location$;
  error$ = this.locationService.location$.pipe(
    catchError((error) => {
      // Handle errors here
      return throwError(error);
    })
  );

  faReward = faGift;
  faClaimed = faClaimed;
  faClaim = faClaim;
  LoginState = LoginState;

  constructor(
    private router: Router,
    private readonly locationService: LocationService,
    public readonly translate: TranslateService,
    private readonly userOfferService: UserOffersService,
  ) {

  }

  userState: LoginState;

  ngOnInit(): void {
    this.location$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: async (location) => {
        if (location) {
          if (this.offer?.venue?.venueAddresses?.length > 0) {
            const venueAddress = this.offer.venue.venueAddresses[0];
            if (venueAddress.geoCoordinate?.latitude && venueAddress.geoCoordinate?.longitude) {
              this.distance = await this.locationService.fetchDistance(venueAddress.geoCoordinate, location);

              if (this.distance <= 0) this.distanceDisplay = "0.0 mi";
              else if (this.distance < 10) this.distanceDisplay = `${this.distance.toFixed(2)} mi`;
              else if (this.distance < 25) this.distanceDisplay = `${this.distance.toFixed(1)} mi`;
              else this.distanceDisplay = `${this.distance.toFixed(0)} mi`;
            }
          }
        }
      }
    });

    this.userOfferService.userState$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (userState) => {
        this.userState = userState;
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToOfferDetails(): void {
    if (!this.actionable) return;
    if (!this.offer || !this.offer.id || this.offer.id.length === 0) return;
    this.router.navigate(['/offers/', this.offer.id]);
  }

  get offerImageUrl(): string | ArrayBuffer {
    return this.offerMainImageUrl;
    // let result = this.offer?.offerImage?.imageUrl;
    // if (!!result) return result;
    // return '/assets/images/offer_placeholder.png';
  }

  get offerMainImageUrl(): string | ArrayBuffer {
    if (!!this.offer?.offerImage?.imageUrl) {
      return this.offer?.offerImage?.imageUrl;
    }

    if (!!this.offer?.charityIncentive?.charity) {
      let charityImage = this.offer?.charityIncentive?.charity?.bannerImage?.imageUrl;
      if (!!charityImage) {
        return charityImage;
      }
      charityImage = this.offer?.charityIncentive?.charity?.logoImage?.imageUrl;
      if (!!charityImage) {
        return charityImage;
      }
    }

    if (!!this.offer?.venue?.venueImages) {
      let venueImage = this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'BANNER')?.imageUrl;
      if (!!venueImage) {
        return venueImage;
      }
      venueImage = this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl;
      if (!!venueImage) {
        return venueImage;
      }
    }
    return null;
  }

  logoImageLoaded: boolean = false;
  onLogoImageLoad() {
    this.logoImageLoaded = true;
  }
  get logoImageUrl(): string | ArrayBuffer | boolean {
    if (!!this.offer?.charityIncentive?.charity?.logoImage?.imageUrl) {
      return this.offer?.charityIncentive?.charity?.logoImage?.imageUrl;
    }
    return this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl ?? false;
  }

  subLogoImageLoaded: boolean = false;
  onSubLogoImageLoad() {
    this.subLogoImageLoaded = true;
  }
  get subLogoImageUrl(): string | ArrayBuffer | boolean {
    if (!!this.offer?.charityIncentive?.charity?.logoImage?.imageUrl) {
      return this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl ?? false;
    }
    return this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl ?? false;
    // return null;
  }

  get venueLogoImageUrl(): string | ArrayBuffer | boolean {
    return this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl ?? false;
  }

  get venueBannerImageUrl(): string | ArrayBuffer | boolean {
    let result = this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'BANNER')?.imageUrl ?? false;
    console.log("venueBannerImageUrl", result);
    return result;
  }


  get isCharityOffer(): boolean {
    return !!this.offer?.charityIncentive && (!this.offer.charityIncentive.disabledDate || this.offer.charityIncentive.disabledDate.getTime() <= 0);
  }

  get rewardedOffer(): boolean {
    return !!this.offer?.claim && this.offer?.claim?.rewards?.length > 0;
  }


  claimOffer($event) : void {
    // TODO: Implement this, which includes logging in the user if they are not logged in
    return;
    // $event.stopPropagation();
    // this.userOfferService.marketingChannelId = this.offer.id;
    // this.userOfferService.init();
  }
}
