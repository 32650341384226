import { gql } from "apollo-angular";
import { RewardDetails } from "./reward.fragment.graphql";

export const PatronClaimDetails = gql`
fragment PatronClaimDetails on Claim {
  id
  claimedDate
  offer {
    id
    name
    venue {
      id
      name
    }
  }
  rewards {
    ...RewardDetails
  }
}
${RewardDetails}
`;
