import { gql } from "apollo-angular";
import { AffiliateDetails } from "../fragments/affiliate.fragment.graphql";

export const AffiliatesQuery = gql`
query Affiliates($search: [SearchInput!]! = [], $sort: PageAndSortInput) {
    affiliates(search: $search, sort: $sort) {
      ...AffiliateDetails
    }
  }
  ${AffiliateDetails}
  `;

export const AffiliateQuery = gql`
query Affiliate($id: String!) {
    affiliate(id: $id) {
      ...AffiliateDetails
    }
  }
  ${AffiliateDetails}
  `;
