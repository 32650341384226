<div class="w-full h-full flex justify-center items-start gz-bg-gradient">
  <div class="flex flex-col justify-center items-center py-[50px] px-[50px] lg:px-[200px] w-full max-w-8xl">

    <h1 class="resp-title mb-12 w-full">Terms of Service</h1>

    <h2 class="resp-subtitle mb-6">THESE TERMS OF SERVICE INCLUDE, AMONG OTHER THINGS, A BINDING ARBITRATION
      PROVISION THAT CONTAINS A CLASS ACTION WAIVER. PLEASE REFER TO SECTION 19 BELOW FOR MORE INFORMATION.</h2>
    <p class="resp-para mb-12">GetZeals, Inc. (the “Company,” “we,” “us,” and “our”) offers a rewards
      program in the United States to help consumers save money. These Terms of Service (“Terms”) govern your access to
      and use of (1) our website located at www.GetZeals.com and all of our other websites to which these Terms are
      posted (collectively, the “Website”); (2) our mobile applications to which these Terms are posted (collectively,
      the “Application”); and (3) any services, content, and features made available by us through the Website or the
      Application (together with the Website and the Application, the “Services”). In these Terms, “you” and “your”
      refer to any user of the Services.</p>

    <h2 class="resp-subtitle mb-6"><b>YOUR ACCEPTANCE OF THESE TERMS</b></h2>
    <p class="resp-para mb-12">By accessing or using the Services in any way or by clicking to agree to
      these Terms when that option is made available to you, you agree to be bound by these Terms and our Privacy
      Policy, which is incorporated by this reference into these Terms. If you do not agree to all the terms and
      conditions of these Terms, your sole recourse is to terminate your access to and use of the Services and forfeit
      any GetZeals (as defined below) balance in your Account. If your access to or use of the Services is prohibited by
      applicable law, you are not authorized to access or use the Services. We are not responsible if you access or use
      the Services in any manner that violates these Terms or applicable law.</p>

    <h2 class="resp-subtitle mb-6"><b>CHANGES TO THESE TERMS</b></h2>
    <p class="resp-para mb-12">We may, without prior notice or liability to you, discontinue the Services or
      modify the Services by adding or removing features or functionalities, even though such changes may affect the
      rewards (“GetZeals”) provided to you. We may also revise these Terms from time to time in our sole discretion,
      subject to applicable law. When we revise these Terms, we will post a revised version on the Website and the
      Application and such revised version will take immediate effect upon posting on the Website and the Application
      and, subject to applicable law, become binding on you. For the avoidance of doubt, except as otherwise expressly
      stated by us, your access to and use of the Services are subject to the version of these Terms currently in
      effect.</p>

    <h2 class="resp-subtitle mb-6"><b>ADDITIONAL TERMS AND OTHER THIRD-PARTY PARTNERS</b></h2>
    <p class="resp-para mb-12">In connection with your access to or use of the Services, you may be subject
      to additional terms, rules, policies, and conditions imposed by us (“Additional Terms”), which are hereby
      incorporated by reference into these Terms. To the extent of any inconsistency between these Terms and any
      Additional Terms, these Terms will control, unless otherwise expressly provided by us. From time to time, we will
      use the services of third party partners (“Partners”) like Fidel API, Plaid Technologies, Inc. (“Plaid”) and
      Dwolla. By agreeing to these Terms, you agree to be bound by the current terms of use and privacy policies of our
      Partners. You can review the terms of use and privacy policy of Plaid here and the terms of use and privacy policy
      of Dwolla here. If we add another Partner, we will notify you and send you a link to its terms of use and privacy
      policy.</p>

    <h2 class="resp-subtitle mb-6"><b>FEES</b></h2>
    <p class="resp-para mb-12">Our application is free to download and there are no costs to you associated
      with the creation of your user account with us (“Account”). However, we cannot maintain your Account indefinitely
      if your Account remains Inactive. If at any time your Account becomes Inactive for longer than twelve (12) months
      (an “Inactive Account”), we reserve the right to reduce, on a monthly basis, the amount of GetZeals held in your
      Account in the amount of the lesser of (i) GetZeals representing the equivalent of $4.99 USD, or (ii) the GetZeals
      balance then reflected in your Account (the “Inactive Account Rewards Deduction”). The Inactive Account Reward
      Deduction shall be assessed each consecutive month that the Account remains Inactive. “Inactive” is defined as
      failing to either complete an eligible transaction or redeem GetZeals from your Account in the trailing twelve
      (12)-month period. Each time you complete an eligible transaction or redeem GetZeals from your Account, you will
      reset the twelve (12)-month period by which we measure activity. Though not required, we may, in our sole
      discretion, elect to notify you prior to your Account becoming Inactive so that you can avoid the assessment of
      the Inactive Account Rewards Deduction. Inactive Account Maintenance Fees will only be debited from GetZeals in
      your Account and will never be charged to a card on file. While we may assess an Inactive Account Rewards
      Deduction and reduce the GetZeals in your Account as stated above, we will never bill you in relation to an
      Inactive Account. Inactive Account Reward Deductions cannot be recredited to your Account, but your GetZeals
      balance will never become negative. If your Account remains Inactive for longer than twelve (12) months and your
      GetZeals balance represents the equivalent of $0.01 USD or less, we reserve the right to terminate your Account.
      Unless you are advised otherwise, you will be eligible to create a new account at a later time when you are able
      to actively use the Services.</p>

    <h2 class="resp-subtitle mb-6"><b>THE SERVICES</b></h2>
    <p class="resp-para mb-12">The purpose of the Services is to help you save money with minimal effort by
      rewarding you with GetZeals for certain qualifying purchases and other activities.</p>

    <h2 class="resp-subtitle mb-6"><b>(A) Certain Requirements</b></h2>
    <p class="resp-para mb-12">In order to access or use the Services (other than merely browsing the
      Website), you must: (i) be 18 years of age or older, reside in the United States, and have a U.S.-based, non-VoIP
      mobile phone number; (ii) accept and agree to these Terms, including our Privacy Policy and any Additional Terms;
      (iii) create an Account and maintain your Account in good standing; and (iv) provide all information requested by
      us, such as your name, phone number, email address, and payment account information (“User Information”). All
      determinations as to whether a phone number is authorized by the Company is at our sole discretion. Each
      individual user of the Services may only have one Account. You represent and warrant that all User Information you
      provide us from time to time is truthful, accurate, current, and complete, and you agree not to misrepresent your
      identity or your User Information. You must promptly notify us of changes to your User Information by updating
      your Account. Our Privacy Policy governs our collection, use, storage, and disclosure of User Information.</p>
    <p class="resp-para mb-12">You are solely responsible for ensuring the confidentiality of your Account
      login information and maintaining the security of such information. You agree not to authorize any other person to
      use your Account for any purpose. Except as otherwise provided by applicable law, you are solely responsible for
      all transactions and other activities authorized or performed using your Account, whether authorized by you or
      not. If you believe any of your Account credentials, such as your password, have been obtained or used by any
      unauthorized person or you become aware of any other breach or attempted breach of the security of the Services or
      your Account, you must notify us immediately.</p>

    <h2 class="resp-subtitle mb-6"><b>(B) Linking Your Payment Card</b></h2>
    <p class="resp-para mb-12">In order to receive GetZeals from us for qualifying purchases of goods or
      services, or for referring other users to download our application, you must link at least one eligible debit or
      credit card (a “Payment Card”) to your Account. Please note that not all debit and credit cards are eligible to be
      linked to your Account. All determinations as to whether a card is eligible to be a Payment Card are at our sole
      discretion. For example, certain Visa, Mastercard, and American Express cards are not eligible to be linked to
      your Account, including, but not limited to, the following: prepaid cards, corporate cards, purchasing cards,
      store cards which can only be used at their respective retailers’ stores, government-administered prepaid cards
      (including Electronic Benefit (EBT) cards), healthcare cards (including Health Savings Account (HSA) cards,
      Flexible Spending Account (FSA) cards, and insurance prepaid cards), Visa Buxx, and cards that are not processed
      through the Visa U.S.A payment system, the Mastercard payment system, or the American Express payment system. In
      order to be eligible as a Payment Card, it must be issued by a U.S. bank. In addition, you may not be able to link
      a debit or credit card to your Account if the card is already linked to certain other card-linked-offer programs
      offered by us or by third parties. If your Payment Card is terminated, suspended or expires, your Account may be
      suspended in our sole discretion.</p>

    <h2 class="resp-subtitle mb-6"><b>(C) Use Of Enrolled Cards And Transaction Information</b></h2>
    <p class="resp-para mb-12">By registering a Payment Card in connection with transaction monitoring, and
      in order to enable us to provide the Services, you authorize us to share your payment information with any Payment
      Card Networks ("Card-Related Parties"). In particular, you authorize the Card-Related Parties to monitor
      transactions on your linked Payment Card(s) to identify qualifying purchases in order to determine whether you
      have qualified for or earned an offer linked to your Payment Card, and for the Card-Related Parties to share such
      transaction details with us to enable your card-linked offer(s) and target offers that may be of interest to you.
      You agree that the Company and the Card-Related Parties may view transactions made by you with participating
      merchants. You authorize participating merchants to provide the Company with historical transaction data.</p>
    <p class="resp-para mb-12">For more information on how we use your Payment Card transaction information,
      see the "Information Collected from Payment Card Networks and Transaction Information" section of the Privacy
      Policy. You may opt-out of transaction monitoring on the Payment Card(s) you have registered by de-linking then
      through the Services or by terminating your Account. To terminate your Account and this Agreement and your right
      to use the Services at any time and for any reason and for no reason, contact Customer Support and immediately
      discontinue all use of the Services. Please note that if you opt-out of transaction monitoring, certain features
      of the Services may not be available to you or the performance of certain features of the Services may be limited
      or not work at all.</p>

    <h2 class="resp-subtitle mb-6"><b>(D) Transaction Data Usage</b></h2>
    <p class="resp-para mb-12">Notwithstanding anything to the contrary in the Terms or Privacy Policy,
      Company and its Third Party Service Provider (Fidel API) will use transaction information solely as follows:</p>

    <p class="resp-para mb-12">- Use transaction data such as transaction amount, transaction time and
      merchant location to confirm a Qualifying Purchase or return to match transactions to confirm whether you qualify
      for rewards</p>

    <p class="resp-para mb-12">- Share transaction data with the participating merchant where a transaction
      occurred as needed for the merchant to confirm a specific transaction occurred. For example, the date and amount
      of your purchase and the last 4 digits of your card number so the merchant can verify your purchase with its
      records if there is a missing or disputed transaction;</p>

    <p class="resp-para mb-12">- Provide participating merchants or Third Party Service Provider (Fidel API)
      aggregated and anonymized information relating specifically to registered card activity solely to allow
      participating merchants and Third Party Service Provider to assess the results of their campaign;(Fidel API)</p>

    <p class="resp-para mb-12">- Provide information in order to respond to a request from government
      authority or a payment organization involved in a transaction with you or a merchant.</p>

    <p class="resp-para mb-12">- You authorize the sharing, exchange and use of transaction data described
      above and herein by and among Company and Company’s Third Party Service Provider (Fidel API), applicable Payment
      Card Networks and applicable Merchants.</p>


    <h2 class="resp-subtitle mb-6"><b>(E) Receiving GetZeals</b></h2>
    <p class="resp-para mb-12">Subject to these Terms, we will automatically provide GetZeals to you when
      you purchase qualifying goods or services with your Payment Card or perform other qualifying actions identified by
      us. The Company, in its sole discretion, establishes the terms and conditions for each qualifying purchase or
      action and determines whether you have satisfied such terms and conditions to receive GetZeals. Such terms and
      conditions are set forth on the Website and the Application and may be updated, modified, suspended, or cancelled
      by the Company at any time in its sole discretion. All offers made available to you through the Application are
      temporary and may become unavailable without notice. You may also have a limited, prescribed window of time to
      take advantage of an offer. Always check the Application for offers currently available to you.</p>
    <p class="resp-para mb-12">Not all qualifying purchases made with your Payment Card may be eligible for
      GetZeals if we are unable to obtain certain transaction information from the Payment Card Network associated with
      your Payment Card. For example, we may be unable to obtain certain transaction information from the Payment Card
      Network (and you may therefore be unable to receive GetZeals) for the following transactions made with your
      Payment Card: (i) purchases that require you to enter your Personal Identification Number (“PIN”) for your Payment
      Card, (ii) purchases you initiate through identification technology that substitutes for a PIN, (iii) payments
      made through other payment methods (such as a digital wallet or a third-party payment application, where you may
      choose your Payment Card as a funding source but you do not present your Payment Card directly to the merchant),
      (iv) payments of existing balances, balance transfers, or (v) transactions that are not processed or submitted
      through the Payment Card Network. Do not use a PIN when paying for a qualifying purchase with your Payment Card if
      you want the purchase to be eligible for GetZeals.</p>
    <p class="resp-para mb-12">Without limiting any of the other terms of these Terms, if you return, charge
      back, cancel, dispute, or otherwise request a refund for a qualifying purchase for which you have already received
      GetZeals, we will reduce the balance of GetZeals in your Account by the amount of GETZEALS you received for such
      transaction. If the balance of GetZeals in your Account is less than the amount of GetZeals you received for such
      transaction, we will offset the applicable amount of GetZeals from GetZeals you would otherwise receive for future
      qualifying actions.</p>
    <p class="resp-para mb-12">GetZeals that you receive as a result of a qualifying physical purchase at a
      participating merchant will be reflected in your Account in pending status on the date of purchase. As soon as the
      funds are verified and received from the merchant, the funds will be available to withdraw. In some cases, it may
      take longer for GetZeals to be reflected in your Account. For purposes of these Terms, “Business Day” means Monday
      through Friday, excluding federal banking holidays. If you do not automatically receive GetZeals for purchasing
      qualifying goods or services with your Payment Card or for performing other qualifying actions as identified by
      us, you have thirty (10) days from the date of transaction if made in store to contact us, provide evidence of the
      purchase and request GetZeals earned. Any requests for GetZeals earned after these deadlines will not be honored
      and the GetZeals associated with the transaction will be forfeited.</p>
    <p class="resp-para mb-12">Without limiting any of the other conditions of these Terms, if we award
      GetZeals to you in error (e.g., we later determine that you did not comply with the terms and conditions of a
      qualifying purchase or if you charge back a qualifying purchase), we reserve the right to remove the applicable
      GetZeals from your Account. You are responsible for making sure the GetZeals balance reflected in your Account is
      correct. If you believe that your Account does not accurately reflect GetZeals that you are eligible to receive,
      please contact us.</p>
    <p class="resp-para mb-12">GetZeals will remain in your Account until we approve redemption of GetZeals
      as described below.</p>

    <h2 class="resp-subtitle mb-6"><b>(F) Redeeming GetZeals</b></h2>
    <p class="resp-para mb-12">Subject to these Terms and our approval, you may request that we redeem
      GetZeals from your Account. Upon redemption 1 GetZeals will equal $1 USD (“Reward Redemption”). Prior to redeeming
      GetZeals, (i) you must verify an email address associated with your Account, (ii) GetZeals may only be redeemed
      from your Account through the Website or the Application, (iii) the value of the balance of GetZeals in your
      Account must be equivalent to a Reward Redemption amount of at least $5 USD, and (iv) you must have completed an
      offer-qualifying transaction with a participating merchant. We will generally redeem GetZeals from your Account
      and credit the corresponding Reward Redemption amount to your designated U.S. bank account, PayPal account, or
      other payment method permitted by us and designated by you (“Redemption Method”) within 1-5 Business Day(s) of
      when we receive your redemption request. Credits to your bank account will be completed within 2-3 Business Days
      from when we receive the redemption request. Credits to your PayPal account may be completed on the same day we
      receive the redemption request or up to 5 Business Days after your Reward Redemption request is received and
      approved by us.</p>
    <p class="resp-para mb-12">We use Fidel API. (“Fidel API”) to gather users’ data from financial
      institutions. By using the Services, you grant us and Fidel API the right, power and authority to act on your
      behalf to access and transmit your personal and financial information from the relevant financial institution. You
      agree to your personal and financial information being transferred, stored and processed in accordance with the
      Fidel API Privacy Policy. By redeeming GetZeals from your Account, you authorize us to electronically credit your
      Redemption Method for the applicable Reward Redemption amount. This authorization will remain in full force and
      effect until the termination of your Account or until you otherwise notify us by contacting us. You must notify us
      of any change in your Redemption Method at least 3 Business Days before any such change by contacting us or by
      updating your Account. If we do not receive notice at least 3 Business Days before any such change, we may
      attempt, in our sole discretion, to implement such change prior to any credit transaction performed hereunder.
      However, we assume no responsibility for our failure to do so.</p>
    <p class="resp-para mb-12">You are solely responsible for verifying the accuracy and completeness of any
      credits of the Reward Redemption amount to your Redemption Method performed by us hereunder. You must notify us of
      any errors within 15 days of such information being made available to you. If you do not notify us of any such
      errors within such 15 days, you will forfeit the right to contest a transaction, except to the extent such
      forfeiture is prohibited by applicable law.</p>
    <p class="resp-para mb-12">For example, unless otherwise permitted by us, you may only redeem GetZeals
      for the equivalent of up to a Reward Redemption amount of $500 USD in any day. Without limiting any of the other
      conditions of these Terms, we may establish additional limits on your redemption of GetZeals and otherwise modify
      the redemption terms for GetZeals at any time in our sole discretion.</p>

    <h2 class="resp-subtitle mb-6"><b>(G) Taxes</b></h2>
    <p class="resp-para mb-12">All amounts paid to you hereunder are exclusive of any applicable
      withholding, sales, use, excise, value added, or other taxes. You acknowledge and agree that you are responsible
      for determining, paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments
      associated with your activity in connection with the Services. We are not responsible for determining whether you
      owe taxes in connection with your access to or use of the Services or for collecting, reporting, or remitting
      taxes arising from your access to or use of the Services, except for our own income taxes. You agree to promptly
      and fully reimburse and indemnify us for any taxes, penalties, and interest assessed by any taxing authority
      regarding amounts owed by you in connection with these Terms.</p>
    <p class="resp-para mb-12">Depending on applicable tax laws, your receipt of rewards may be subject to
      reporting to certain tax authorities. In accordance with such laws, we may be required to send to you and file
      certain forms with tax authorities, such as the IRS Form 1099-MISC (Miscellaneous Income), for any year in which
      rewards are issued to you. If we request information from you in order to complete a required tax form, and you
      fail to provide the requisite information, you will be prohibited from redeeming GetZeals from your Account until
      we receive the required information.</p>

    <h2 class="resp-subtitle mb-6"><b>(H) Additional Terms, Representations, And Requirements</b></h2>
    <p class="resp-para mb-12">You may only access and use the Services for your own personal,
      non-commercial use and not on behalf of or for the benefit of any third party. You may not use GetZeals to make
      purchases, to transfer funds to third parties, or for any other purpose, except as expressly permitted by us. You
      have no property rights or other legal interest in GetZeals. The GetZeals in your Account as well as any
      references to rewards in communications regarding any offers available through our platform are represented in the
      Redemption Reward equivalent for illustrative purposes only and are intended to reflect the current potential
      value of your GetZeals in the event that you redeem GetZeals to the extent eligible to do so under these Terms. As
      such, you acknowledge and agree that any rewards you receive are received as GetZeals, and that GetZeals: (i) is
      issued solely by the Company and is not underwritten, funded, sponsored, or otherwise provided by any third party,
      including, but not limited to, the Payment Card Networks, our merchant, brand, or other clients or business
      partners; (ii) has no cash value or any other value (monetary or otherwise) and does not act as a substitute for
      real currency; and (iii) is not redeemable or exchangeable for real currency or other monetary value from the
      Company or redeemable or exchangeable with any other third party, except as expressly provided in these Terms or
      otherwise required by applicable law. By accessing or using the Services, you agree not to seek to redeem or
      otherwise claim GetZeals from any third party, including, but not limited to, the Payment Card Networks, our
      merchant, brand, or other clients or business partners.</p>
    <p class="resp-para mb-12">You further acknowledge and agree that: (i) the Company, in its sole
      discretion, may impose limits, terms, and conditions on GetZeals, including, but not limited to, limits on the
      amount of GetZeals that may be received and redeemed, and may adjust the GetZeals balance in your Account; (ii)
      the Company and the Payment Card Networks are not financial institutions and do not provide banking or payment
      processing services; (iii) no consideration or other value is or has been given in exchange for GetZeals; (iv) the
      Company is not a party to your Payment Card transactions; and (v) the Company is not responsible, and has no
      liability for, any products or services that are paid for with your Payment Card. You must resolve all disputes
      related to any products or services that are paid for with your Payment Card directly with the applicable merchant
      and/or the financial institution that issued your Payment Card.</p>
    <p class="resp-para mb-12">You may not transfer, assign, sell, gift, exchange, trade, convert, lease,
      sublicense, rent, or distribute GetZeals, except as expressly permitted by us and subject to applicable law. Any
      disposition or attempted disposition of GetZeals in violation of these Terms will be void and will result in
      immediate termination of your Account. We do not recognize or condone any third-party service that may be used to
      sell, exchange, transfer, or otherwise dispose of GetZeals, and we do not assume any responsibility for, and will
      not support, such transactions.</p>
    <p class="resp-para mb-12">In addition to your other representations and warranties in these Terms, you
      represent and warrant that you will not access or use the Services to engage in any illegal, fraudulent, or other
      illicit activity. In order to redeem GetZeals through the Services, you must provide such other information and
      documents as requested by us to verify your identity and compliance with these Terms, including, but not limited
      to, your representations and warranties herein. For our compliance purposes and in order to provide the Services
      to you, you hereby authorize us to, directly or through a third party, obtain, verify, and record information and
      documentation for purposes of verifying your identity and your Payment Card and Redemption Method information.</p>
    <p class="resp-para mb-12">Can you please provide more details to Visa about what will be included in
      these records and what they will be specifically be used for?</p>
    <p class="resp-para mb-12">&#64;GetZeals - understand this is part of the guidance language provided -
      however, suggestion here is to specific the usage of the data and creating a record 'i.e. to allow users to have
      visibility of their qualifying transaction history and balance etc'</p>

    <h2 class="resp-subtitle mb-6"><b>OWNERSHIP AND PROPRIETARY RIGHTS IN THE SERVICES</b></h2>
    <p class="resp-para mb-12">All right, title, and interest in and to the Services, including any updates,
      upgrades, and modifications thereto, and any associated patents, trademarks, copyrights, mask work rights, trade
      secrets, and other intellectual property rights, belong solely and exclusively to the Company and its licensors,
      and, except as expressly set forth in these Terms, we do not grant you any licenses or other rights, express or
      implied, to the Services.</p>
    <p class="resp-para mb-12">You acknowledge and agree that the Services are protected by applicable
      copyright, trademark, and other intellectual property laws. All words and logos displayed in connection with the
      Services that are marked by the ™ or ® symbols are trademarks and service marks of the Company and/or their
      respective owners. The display of a third-party trademark in connection with the Services does not mean that we
      have any relationship with that third party or that such third party endorses the Services or the Company.</p>
    <p class="resp-para mb-12">Subject to these Terms, we hereby grant you a limited, revocable, personal,
      non-exclusive, and non-transferable right and license to access and use the Services solely for your personal,
      non-commercial, entertainment purposes. Except as expressly provided by these Terms or as otherwise expressly
      permitted by us, you may not use, modify, disassemble, decompile, reverse engineer, reproduce, distribute, rent,
      sell, license, publish, display, download, transmit, or otherwise exploit the Services in any form by any means.
      Without limiting the foregoing, you agree not to (and not to allow any third party to): (a) use any robot, spider,
      scraper, or other automatic or manual device, process, or means to access or copy the Services; (b) take any
      action that imposes or may impose (in our sole determination) an unreasonable or a disproportionately large load
      on the Services or our infrastructure; (c) utilize any device, software, or routine that will interfere or attempt
      to interfere with the functionality of the Services; (d) rent, lease, copy, provide access to or sublicense any
      portion of the Services to a third party; (e) use any portion of the Services to provide, or incorporate any
      portion of the Services into, any product or service provided to a third party; (f) remove or obscure any
      proprietary or other notices contained in the Services; or (g) use the Services for any illegal or unauthorized
      purpose. We may, but are not obligated to, monitor your use of the Services.</p>

    <h2 class="resp-subtitle mb-6"><b>USER CONTENT</b></h2>
    <p class="resp-para mb-12">You may be able to post, submit, publish, display, or transmit (collectively,
      “share”) materials, suggestions, ideas, data, and other content through the Services (“User Content”). By sharing
      User Content through the Services, you represent and warrant that: (a) you are solely responsible for the
      transmission, accuracy, completeness, and publication of that User Content; (b) you have the right, power, and
      authority to share that User Content and grant the rights and licenses to that User Content provided herein; and
      (c) the User Content does not and will not infringe or violate the rights of any third party. Any User Content
      will be treated as non-confidential and non-proprietary, except as otherwise provided in our Privacy Policy. You
      may not share User Content that is offensive; objectionable; promotes racism, discrimination, bigotry, hatred, or
      physical harm of any kind; harasses or advocates harassment of another person; exploits people in any manner; or
      contains nudity, violence, or pornographic subject matter.</p>
    <p class="resp-para mb-12">You hereby grant us a royalty-free, worldwide, perpetual, non-exclusive,
      unrestricted, irrevocable, transferable, and sub-licensable right and license to use, modify, copy, reproduce,
      distribute, sell, publicly display, transmit, delete, monetize, share, trade, make derivative works from, store,
      and otherwise exploit User Content and to allow others to do the same for any purpose, including, but not limited
      to, commercial purposes. You acknowledge and agree that you will not receive any monetary compensation whatsoever
      for granting us this license to your User Content, and you hereby completely and irrevocably waive any moral or
      similar rights you may have in your User Content, even if such User Content is altered or changed in a manner that
      is not agreeable to you. This includes, but is not limited to, any claims based on invasion of privacy, idea
      misappropriation, other civil rights violations, or defamation. The license granted under this Section, including
      the related waiver of any applicable moral rights, will survive any termination of these Terms.</p>
    <p class="resp-para mb-12">Without undertaking any obligation to screen or monitor User Content, we have
      the right (but not the obligation) to edit, modify, refuse to post, or remove any User Content that we determine,
      in our sole discretion, violates these Terms or is otherwise objectionable. You acknowledge and agree that we may,
      but are not obligated to, preserve User Content and may also disclose User Content to the extent permitted by
      applicable law and as provided in our Privacy Policy. You acknowledge and agree that your communications with
      other users via any channel of communication via the Services may be public and that you have no expectation of
      privacy concerning your access to and use of the Services. You are solely responsible for your communications
      through the Services and your interactions with other users of the Services.</p>

    <h2 class="resp-subtitle mb-6"><b>COPYRIGHT POLICY</b></h2>
    <h2 class="resp-subtitle mb-6"><b>(A) Reporting Claims Of Copyright Infringement</b></h2>
    <p class="resp-para mb-12">We take claims of copyright infringement seriously. We will respond to
      notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible
      on or from the Website or the Application infringe your copyright, you may request removal of those materials (or
      access to them) from the Website and the Application by submitting written notification to our Copyright Agent
      (designated below). In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital
      Millennium Copyright Act (17 U.S.C. § 512) (“DMCA”), the written notice (the “DMCA Notice”) must include
      substantially the following: (i) your physical or electronic signature; (ii) identification of the copyrighted
      work you believe to have been infringed or, if the claim involves multiple works on the Website and the
      Application, a representative list of such works; (iii) identification of the material you believe to be
      infringing in a sufficiently precise manner to allow us to locate that material; (iv) adequate information by
      which we can contact you (including your name, postal address, telephone number, and, if available, email
      address); (v) a statement that you have a good faith belief that use of the copyrighted material is not authorized
      by the copyright owner, its agent, or the law; (vi) a statement that the information in the written notice is
      accurate; and (vii) a statement, under penalty of perjury, that you are authorized to act on behalf of the
      copyright owner.</p>
    <p class="resp-para mb-12">Our designated Copyright Agent to receive DMCA Notice is:</p>
    <p class="resp-para mb-12">GetZeals Inc.: 1702L Meridian Ave, San Jose, CA 95125 #173; www.GetZeals.com
      If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be
      effective. Please be aware that if you knowingly materially misrepresent that material or activity on the Website
      or the Application is infringing your copyright, you may be held liable for damages (including costs and
      attorneys’ fees) under Section 512(f) of the DMCA.</p>

    <h2 class="resp-subtitle mb-6"><b>(B) Counter-Notification Procedures</b></h2>
    <p class="resp-para mb-12">If you believe that material you posted on the Website or the Application was
      removed or access to it was disabled by mistake or misidentification, you may file a counter-notification with us
      (a “Counter-Notice”) by submitting written notification to our copyright agent (identified below). Pursuant to the
      DMCA, the Counter-Notice must include substantially the following: (i) your physical or electronic signature; (ii)
      an identification of the material that has been removed or to which access has been disabled and the location at
      which the material appeared before it was removed or access disabled; (iii) adequate information by which we can
      contact you (including your name, postal address, telephone number, and, if available, email address); (iv) a
      statement under penalty of perjury by you that you have a good faith belief that the material identified above was
      removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; and
      (v) a statement that you will consent to the jurisdiction of the Federal District Court for the judicial district
      in which your address is located (or if you reside outside the United States for any judicial district in which
      the Website and the Application may be used) and that you will accept service from the person (or an agent of that
      person) who provided the complaint at issue. Completed</p>
    <p class="resp-para mb-12">Counter-Notices should be sent to:</p>
    <p class="resp-para mb-12">GetZeals Inc.: 1702L Meridian Ave, San Jose, CA 95125 #173; www.GetZeals.com
    </p>
    <p class="resp-para mb-12">The DMCA allows us to restore the removed content if the party filing the
      original DMCA Notice does not file a court action against you within 10 Business Days of receiving the copy of
      your Counter-Notice.</p>
    <p class="resp-para mb-12">Please be aware that if you knowingly materially misrepresent that material
      or activity on the Website or the Application was removed or disabled by mistake or misidentification, you may be
      held liable for damages (including costs and attorneys’ fees) under Section 512(f) of the DMCA.</p>
    <p class="resp-para mb-12">It is our policy in appropriate circumstances to disable and/or terminate the
      Accounts of users who are repeat copyright infringers.</p>

    <h2 class="resp-subtitle mb-6"><b>PAYMENT TERMS</b></h2>
    <p class="resp-para mb-12">By providing your payment account information, including Payment Card and
      Redemption Method information, to us, you represent, warrant, and covenant that: (a) you are legally authorized to
      provide such information to us; (b) you are legally authorized to perform payments from, and accept payments to,
      the payment account; and (c) such action does not violate the terms and conditions applicable to your use of such
      payment account or applicable law. When you authorize a payment in connection with the Services, you represent,
      warrant, and covenant that there are sufficient funds or credit available to complete a payment using the
      designated payment method.</p>

    <h2 class="resp-subtitle mb-6"><b>THIRD-PARTY CONTENT</b></h2>
    <p class="resp-para mb-12">The information presented through the Services is made available solely for
      general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any
      reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility
      arising from any reliance placed on such materials by you or any other user of the Services. The Services may
      include content provided by third parties, including content provided by other users of the Services. All
      statements and/or opinions expressed in such content (other than the content provided by the Company) are solely
      the opinions and the responsibility of the provider of the content and do not necessarily reflect the opinion of
      the Company. We are not responsible, or liable to you or any third party, for the content provided by any third
      party.</p>

    <h2 class="resp-subtitle mb-6"><b>THIRD-PARTY SERVICES AND LINKS</b></h2>
    <p class="resp-para mb-12">The Services may contain links to third-party websites, applications, and
      services that are not owned or operated by us. The Services may also facilitate your purchase of third-party
      products and services, such as the Travel Services. We do not control, and are not responsible for, the Travel
      Services or any other third-party products, services, websites, or applications (collectively, “Third-Party
      Services”) or the Third-Party Suppliers or any other providers or sellers of Third-Party Services. Separate terms
      and conditions apply to Third-Party Services. You should read those terms and conditions carefully before
      accessing or purchasing any Third-Party Services. You are, among other things, responsible for all charges, fees,
      duties, taxes, and assessments in connection with your purchase of any Third-Party Services through the Services,
      except as otherwise provided by applicable law. You must resolve all disputes related to Third-Party Services with
      the applicable third party that provides and/or sells the Third-Party Services.</p>

    <h2 class="resp-subtitle mb-6"><b>SUSPENSION AND TERMINATION</b></h2>
    <p class="resp-para mb-12">We may, in our sole discretion, suspend, limit, or terminate your Account and
      your access to and use of the Services, including the GetZeals balance in your Account, at any time for any
      reason, without notice or liability to you, including, but not limited to, if we suspect that your access to or
      use of the Services violates these Terms or applicable law. You may stop using the Services and terminate your
      Account at any time by contacting us. Upon the termination of your Account, you must cease all use of the
      Services, and you shall forfeit any GetZeals balance remaining in your Account. Termination of your Account and
      your access to and use of the Services will not affect any of our rights or your obligations arising under these
      Terms prior to such termination. In the event you or the Company terminates your Account, you agree that we may
      retain your data, including personal and transaction information, for one year from the date of termination for
      audit and merchant invoicing purposes. Provisions of these Terms that, by their nature, should survive termination
      of your Account and your access to and use of the Services will survive such termination.</p>

    <h2 class="resp-subtitle mb-6"><b>INDEMNIFICATION</b></h2>
    <p class="resp-para mb-12">You agree to indemnify, hold harmless, and (at our request) defend us, our
      affiliates, and our and their respective employees, officers, directors, and agents, as well as the Payment Card
      Networks, from and against all claims, demands, suits, damages, costs, lawsuits, fines, penalties, liabilities,
      and expenses, including reasonable attorneys’ fees, that arise from any third-party claim due to or arising out
      of: (a) User Content you share through the Services; (b) your use of the Services; (c) your breach or alleged
      breach of these Terms; (d) your violation of applicable law, including, but not limited to, infringement of
      third-party intellectual property rights; or (e) your other actions or omissions that result in liability to us.
      We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are
      required to indemnify us under these Terms, and you agree to cooperate with our defense of these claims.</p>

    <h2 class="my-10 text-l text-gray-500"><b>DISCLAIMERS</b></h2>
    <p class="resp-para mb-12">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY UNDERSTAND
      AND AGREE THAT YOUR ACCESS TO AND USE OF THE SERVICES AND ALL INFORMATION, PRODUCTS, AND OTHER CONTENT (INCLUDING
      THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE THROUGH THE SERVICES ARE AT YOUR OWN RISK. TO THE FULLEST EXTENT
      PERMITTED BY APPLICABLE LAW, THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY
      WARRANTIES OF ANY KIND, AND WE AND THE PAYMENT CARD NETWORKS EXPRESSLY DISCLAIM ANY AND ALL CONDITIONS,
      REPRESENTATIONS, WARRANTIES, EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY
      IMPLIED WARRANTY OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>
    <p class="resp-para mb-12">WITHOUT LIMITING THE FOREGOING, WE AND THE PAYMENT CARD NETWORKS MAKE NO
      WARRANTY THAT (A) THE SERVICES WILL MEET YOUR REQUIREMENTS; (B) THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
      SECURE, OR ERROR-FREE; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR
      RELIABLE; (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
      THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS; OR (E) ANY ERRORS IN THE SERVICES WILL BE CORRECTED. NO ORAL OR
      WRITTEN INFORMATION OR ADVICE GIVEN BY US OR ANY OF OUR AUTHORIZED REPRESENTATIVES WILL CREATE ANY WARRANTY. SOME
      JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS AND
      LIMITATIONS MAY NOT APPLY TO YOU. HOWEVER, ANY LIMITATION WILL BE CONSTRUED TO THE MAXIMUM EXTENT PERMITTED UNDER
      APPLICABLE LAW.</p>

    <h2 class="resp-subtitle mb-6"><b>LIMITATIONS OF LIABILITY</b></h2>
    <p class="resp-para mb-12">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE, OUR AFFILIATES, THE
      PAYMENT CARD NETWORKS, AND THEIR AND OUR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, AND AGENTS WILL NOT BE LIABLE
      FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO OR USE OF THE
      SERVICES OR ANY INFORMATION, PRODUCTS, OR OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR
      ACCESSIBLE THROUGH THE SERVICES, INCLUDING, BUT NOT LIMITED TO, ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
      CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
      AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS
      OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR
      OTHERWISE, EVEN IF FORESEEABLE. WITHOUT LIMITING THE FOREGOING AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
      LAW, IN THE EVENT YOU OR ANY OTHER PERSON OR ENTITY IS ENTITLED TO DIRECT DAMAGES ARISING OUT OF OR IN CONNECTION
      WITH YOUR ACCESS, USE, OR INABILITY TO ACCESS OR USE, THE SERVICES OR ANY INFORMATION, PRODUCTS, OR OTHER CONTENT
      (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE THROUGH THE SERVICES, THE COLLECTIVE LIABILITY OF THE
      COMPANY, OUR AFFILIATES, THE PAYMENT CARD NETWORKS, AND OUR AND THEIR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS,
      AND AGENTS, (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) WILL NOT EXCEED $500.</p>
    <p class="resp-para mb-12">IN ADDITION TO AND WITHOUT LIMITING ANY OF THE FOREGOING, WE AND THE PAYMENT
      CARD NETWORKS WILL HAVE NO LIABILITY FOR ANY FAILURE OR DELAY RESULTING FROM ANY CONDITION BEYOND OUR REASONABLE
      CONTROL, INCLUDING, WITHOUT LIMITATION, ACTS OF GOD, ACTS OF TERRORISM, LABOR CONDITIONS, POWER FAILURES, INTERNET
      DISTURBANCES, OR ANY SERVICES OR SYSTEMS CONTROLLED BY THIRD PARTIES.THE LIMITATIONS IN THIS SECTION DO NOT AFFECT
      ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>

    <h2 class="resp-subtitle mb-6"><b>NOTICES</b></h2>
    <p class="resp-para mb-12">We will send all notices and other communications regarding the Services to
      you at the email address or physical address you provided for your Account, as may be updated by you from time to
      time. You will be considered to have received a notice from us regarding the Services when we send it to the email
      address or physical address we have in our records for you or when we post such notice on the Website or the
      Application.</p>
    <p class="resp-para mb-12">Except as otherwise provided in these Terms, all notices to us that are
      intended to have a legal effect must be delivered via email to Legal&#64;GetZeals.com. All such notices are deemed
      effective upon documented receipt by us.</p>

    <h2 class="resp-subtitle mb-6"><b>GOVERNING LAW</b></h2>
    <p class="resp-para mb-12">These Terms are governed by the laws of the State of California, without
      giving effect to any principle that provide for the application of the law of another jurisdiction.</p>

    <h2 class="resp-subtitle mb-6"><b>DISPUTE RESOLUTION BY BINDING ARBITRATION; JURY TRIAL WAIVER; CLASS
        ACTION WAIVER</b></h2>
    <p class="resp-para mb-12">For any and all controversies, disputes, demands, claims, or causes of action
      between you and us (including the interpretation and scope of this Section and the arbitrability of the
      controversy, dispute, demand, claim, or cause of action) relating to the Services or these Terms (as well as any
      related or prior agreement that you may have had with us), you and we agree to resolve any such controversy,
      dispute, demand, claim, or cause of action exclusively through binding and confidential arbitration. In the event
      of any such controversy, dispute, demand, claim, or cause of action, the complaining party must notify the other
      party in writing thereof.</p>
    <p class="resp-para mb-12">Within 30 days of such notice, you and we agree to use reasonable efforts to
      attempt to resolve the dispute in good faith. If you and we do not resolve the dispute within 30 days after such
      notice, the complaining party may seek remedies exclusively through arbitration. Except as otherwise expressly
      provided by applicable law, the demand for arbitration must be made within a reasonable time after the
      controversy, dispute, demand, claim, or cause of action in question arose, and in any event within two years after
      the complaining party knew or should have known of the controversy, dispute, demand, claim, or cause of action.
    </p>
    <p class="resp-para mb-12">The arbitration will take place in the federal judicial district of your
      residence. As used in this Section, “we” and “us” mean the Company and its subsidiaries, affiliates, predecessors,
      successors, and assigns and all of our and their respective employees, officers, directors, agents, and
      representatives. In addition, “we” and “us” include any third party providing any product, service, or benefit in
      connection with the Services or these Terms (as well as any related or prior agreement that you may have had with
      us) if such third party is named as a co-party with us in any controversy, dispute, demand, claim, or cause of
      action subject to this Section. Arbitration will be subject to the Federal Arbitration Act and not any state
      arbitration law. The arbitration will be conducted before one commercial arbitrator from the American Arbitration
      Association (“AAA”) with substantial experience in resolving commercial contract disputes. As modified by these
      Terms, and unless otherwise agreed upon by the parties in writing, the arbitration will be governed by the AAA’s
      Commercial Arbitration Rules and, if the arbitrator deems them applicable, the Supplementary Procedures for
      Consumer Related Disputes (collectively, the “Rules and Procedures”). You should review this Section carefully. To
      the maximum extent permitted by applicable law, YOU ARE GIVING UP YOUR RIGHT TO GO TO COURT to assert or defend
      your rights EXCEPT for matters that you file in small claims court in the state or municipality of your residence
      or as otherwise provided in the Rules and Procedures within the jurisdictional limits of the small claims court
      and as long as such matter is only pending in that court. Additionally, notwithstanding the agreement to arbitrate
      included in this Section, you and we may seek emergency equitable relief in federal court if it has jurisdiction
      or, if it does not, in a state court located in the federal judicial district of your residence in order to
      maintain the status quo pending arbitration, and you and we hereby agree to submit to the exclusive personal
      jurisdiction of the courts located within the federal judicial district of your residence for such purpose. A
      request for interim measures will not be deemed a waiver of the obligation to arbitrate.</p>
    <p class="resp-para mb-12">Your rights will be determined by a NEUTRAL ARBITRATOR and NOT A JUDGE OR
      JURY. You are entitled to a FAIR HEARING, BUT the arbitration procedures may be SIMPLER AND MORE LIMITED THAN
      RULES APPLICABLE IN COURT. Arbitrators’ decisions are as enforceable as any court order and are subject to VERY
      LIMITED REVIEW BY A COURT.</p>
    <p class="resp-para mb-12">You and we must abide by the following rules: (A) ANY CLAIMS BROUGHT BY YOU
      OR US MUST BE BROUGHT IN THE PARTY’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
      CLASS OR REPRESENTATIVE PROCEEDING; (B) THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS; MAY NOT
      OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING; AND MAY NOT AWARD CLASS-WIDE RELIEF; (c)
      we will pay as much of your filing and hearing fees in connection with the arbitration as required by the Rules
      and Procedures and/or as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive as
      compared to the cost of litigation; (d) we also reserve the right, in our sole and exclusive discretion, to assume
      responsibility for any or all of the costs of the arbitration; (e) the arbitrator will honor claims of privilege
      and privacy recognized at law; (f) the arbitration will be confidential, and neither you nor we may disclose the
      existence, content, or results of any arbitration, except as may be required by applicable law or for purposes of
      enforcement of the arbitration award; (g) subject to the limitation of liability provisions of these Terms, the
      arbitrator may award any individual relief or individual remedies that are expressly permitted by applicable law;
      and (h) each party will pay its own attorneys’ fees and expenses, unless there is a statutory provision that
      requires the prevailing party to be paid its fees and litigation expenses and the arbitrator awards such
      attorneys’ fees and expenses to the prevailing party, and, in such instance, the fees and costs awarded will be
      determined by the applicable law.</p>
    <p class="resp-para mb-12">This Section will survive termination of these Terms as well as any voluntary
      payment of any debt in full by you or any bankruptcy by you or us. With the exception of subparts (A) and (B) of
      this Section (prohibiting arbitration on a class or collective basis), if any part of this Section is deemed to be
      invalid, unenforceable, or illegal, or otherwise conflicts with the Rules and Procedures, then the balance of this
      Section will remain in effect and will be construed in accordance with its terms as if the invalid, unenforceable,
      illegal, or conflicting part was not contained herein. If, however, either subpart (A) or (B) of this Section is
      found to be invalid, unenforceable, or illegal, then the entirety of this Section will be null and void, and
      neither you nor we will be entitled to arbitration. If for any reason a controversy, dispute, demand, claim, or
      cause of action proceeds in court rather than in arbitration, the controversy, dispute, demand, claim, or cause of
      action will be exclusively brought in federal court if it has jurisdiction or, if it does not, in a state court
      located in the federal judicial district of your residence.</p>
    <p class="resp-para mb-12">If you wish to opt out of the agreement to arbitrate included in this
      Section, you must notify us of your election in writing within 30 days of the date that you first became subject
      to these Terms, or within 30 days of the effective date of any material change to these Terms, by sending a
      written notice to us by certified mail at the following address: 1702L Meridian Ave, San Jose, CA 95125 #173 Attn:
      Arbitration Opt-Out. Your opt-out notice must include your name, address, phone number, and email address. For
      more information on the AAA, the Rules and Procedures, and the process for filing an arbitration claim, you may
      call the AAA at 800-778-7879 or visit the AAA website at http://www.adr.org.</p>
    <p class="resp-para mb-12">If Visa transaction data is going to be used to provide segmented offers, the
      consent collected when a customer enrolls a card will need to be updated to be more specific about the use cases
      of transaction data.</p>
    <p class="resp-para mb-12">The Transaction Data Usage section of the privacy policy will also need to be
      updated to include this use case</p>

    <h2 class="resp-subtitle mb-6"><b>MISCELLANEOUS</b></h2>
    <p class="resp-para mb-12">These Terms, including our Privacy Policy and any Additional Terms,
      constitute the entire agreement between you and us regarding the Services and supersede and replace any prior
      agreements and communications between you and us, except as expressly set forth herein. These Terms may not be
      modified, supplemented, qualified, or interpreted by any trade usage or prior course of dealing not expressly made
      a part of these Terms. These Terms and any rights hereunder may not be transferred or assigned by you without our
      prior written consent but may be assigned by us without restriction and without your prior consent. Any attempted
      transfer or assignment by you without our prior written consent will be null and void. No agency, joint venture,
      partnership, trust, or employment relationship is created between you and us by way of these Terms. Except as
      provided in Section 19 (DISPUTE RESOLUTION BY BINDING ARBITRATION; JURY TRIAL WAIVER; CLASS ACTION WAIVER), the
      invalidity or unenforceability of any provision of these Terms will not affect the validity of these Terms as a
      whole and any such provision should be enforced by authorities, and reconstructed if need be, to apply to the
      maximum extent allowable under applicable law. The failure by us to enforce at any time any of the provisions of
      these Terms, to exercise any election or option provided herein, to require at any time your performance of any of
      the provisions herein, or to enforce our rights under these Terms or applicable law will not in any way be
      construed as a waiver of such provisions or rights. The section headings used herein are for convenience only and
      will not be given any legal import.</p>

    <h2 class="resp-subtitle mb-6"><b>Contact Us</b></h2>
    <p class="resp-para mb-12">For any questions, or to request further information regarding this Privacy
      Policy, please contact us at legal&#64;getzeals.com.</p>
    <p class="resp-para mb-12">GetZeals, Inc 1702L #173 Meridian Ave, San Jose, CA. 95125</p>
    <p class="resp-para mb-12">You expressly authorize Getzeal's service provider, Dwolla, Inc. to originate
      credit transfers to your financial institution account. You authorize Getzeals to collect and share with Dwolla
      your personal information including full name, email address and financial information, and you are responsible
      for the accuracy and completeness of that data. Dwolla’s Privacy Policy is available here. <a
        href="https://www.dwolla.com/legal/privacy/" class="">Dwolla Privacy Policy</a></p>
  </div>
</div>
