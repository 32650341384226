import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FidelConfig, FidelService } from '../fidel.service';
import { NavControlService } from '../components/nav/nav-control.service';
import { FetchState } from '../app.module';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { UserPaymentMethod } from '../model/user_payment_method.model';
import { TranslateService } from '@ngx-translate/core';

export enum CardLinkEventType {
  SHOW_LINK_CARD = 'SHOW_LINK_CARD',
  CARD_LINKED = 'CARD_LINKED',
  CARD_LINK_ERROR = 'CARD_LINK_ERROR',
  CARD_LINK_CANCELLED = 'CARD_LINK_CANCELLED',
}

export type FidelCard = {
  id: string,
  lastNumbers: string,
  firstNumbers: string,
  scheme: string,
  expDate: Date,
  live: boolean,
  countryCode: string,
  accountId: string,
  type: string,
}


@Component({
  selector: 'app-credit-cards',
  templateUrl: './credit-cards.component.html',
  styleUrls: ['./credit-cards.component.css']
})
export class CreditCardsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  initWithFidelAddCard: boolean = false;
  @Input()
  hideFidelCloseButton: boolean = false;
  @Input()
  showFidelLinkCardOnly: boolean = false;

  private _visible: boolean = false;
  @Input()
  set visible(value: boolean) {
    this._visible = value;
    if (this._visible) {
      this.showLinkCard();
    }
  }


  @Output() cardLinkEvent: EventEmitter<CardLinkEventType> = new EventEmitter<CardLinkEventType>();
  @Output() cardLinked: EventEmitter<FidelCard> = new EventEmitter<FidelCard>();

  @ViewChild('myFidelContainer') fidelContainer: ElementRef;

  activeUserPaymentMethods: Array<UserPaymentMethod> = [];
  archivedUserPaymentMethods: Array<UserPaymentMethod> = [];

  constructor(
    private readonly fidelService: FidelService,
    protected readonly navControlService: NavControlService,
    private renderer: Renderer2,
    private readonly translateService: TranslateService,
    ) {
  }

  FetchState = FetchState;

  addCardResults: UserPaymentMethod;
  error: any;

  configResult: FidelConfig;
  configError: any;

  showingArchived: boolean = false;
  toggleShowArchived() {
    this.showingArchived = !this.showingArchived;
  }

  private _fidelInitialized: boolean = false;
  ngAfterViewInit(): void {
    this.pendingShowLinkCard = this.initWithFidelAddCard;
    this.loadScript('https://resources.fidel.uk/sdk/js/v3/fidel.js');
  }


  ngOnInit(): void {

    this.fidelService.init();

    this.fidelService.activeUserPaymentMethods$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (activeUserPaymentMethods) => {
        this.activeUserPaymentMethods = activeUserPaymentMethods;
      }
    });

    this.fidelService.archivedUserPaymentMethods$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (archivedUserPaymentMethods) => {
        this.archivedUserPaymentMethods = archivedUserPaymentMethods;
      }
    });

    this.fidelService.activeUserPaymentMethods$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (activeUserPaymentMethods) => {
        if (activeUserPaymentMethods.length > 0) {
          this.cardLinkEvent.emit(CardLinkEventType.CARD_LINKED);
        }
      },
      error: (error) => {

      }
    });

    this.navControlService.user$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (user) => {
        if (user?.id) {
          this.fidelService.fetchUserPaymentMethods(user.id);
        }
      }
    });

    this.fidelService.cardAddedResult$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (cardResult) => {
        this.error = null;
        this.addCardResults = cardResult;
      },
      error: (error) => {
        this.addCardResults = null;
        this.error = error;
      }
    });

    this.fidelService.fidelConfig$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (configResult) => {
        this.error = null;
        this.configResult = configResult;
        this.setCanLinkCard();
        if (this.pendingShowLinkCard) {
          this.showLinkCard();
        }
      },
      error: (error) => {
        this.configResult = null;
        this.configError = error;
      }
    });

    // this.loadScript('https://resources.fidel.uk/sdk/js/v3/fidel.js').then(() => {
    //   // this.showLinkCard();
    // }).catch((error) => {
    //   // console.log('error loading fidel script', error);
    // });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canLinkCard: boolean = false;
  private setCanLinkCard() {
    this.canLinkCard = this.fidelScriptState === FetchState.LOADED_ALL && !!this.configResult;
  }

  private _fidelScriptState: FetchState = FetchState.NONE;
  private set fidelScriptState(state: FetchState) {
    this._fidelScriptState = state;
    this.setCanLinkCard();
  }
  get fidelScriptState() {
    return this._fidelScriptState;
  }
  // displayingLinkedCard$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get displayingLinkedCard() {
    return this.linkCardState === FetchState.LOADING || this.linkCardState === FetchState.ERROR;
  }
  private fidelScriptLoaded: boolean = false;
  private fidelTimeout: any;
  private fidelScriptElement;
  private async loadScript(url: string, counter: number = 0) {
    const that = this;
    this.fidelScriptElement = this.renderer.createElement('script');
    this.fidelScriptElement.type = 'text/javascript';
    this.fidelScriptElement.src = url;
    this.fidelScriptElement.dataset.id = 'fidel-sdk';
    this.fidelScriptState = FetchState.LOADING;

    if (this.fidelTimeout) {
      clearTimeout(this.fidelTimeout);
      this.fidelTimeout = null;
    }
    this.fidelTimeout = setTimeout(() => {
      if (!that.fidelScriptLoaded) {
        that.fidelScriptLoaded = false;
        that.fidelScriptState = FetchState.ERROR;
        console.log('error loading fidel script');
      }
    }, 10000);

    this.fidelScriptElement.onload = () => {
      if (that.fidelTimeout) {
        clearTimeout(that.fidelTimeout);
        that.fidelTimeout = null;
      }
      // Script loaded successfully
      that.fidelScriptLoaded = true;
      that.linkCardState = FetchState.NONE;
      that.fidelScriptState = FetchState.LOADED_ALL;
      console.log('fidel script loaded');

      this._fidelInitialized = true;

      if (that.pendingShowLinkCard) {
        that.showLinkCard();
      }
    };

    this.fidelScriptElement.onerror = (error) => {
      if (that.fidelTimeout) {
        clearTimeout(that.fidelTimeout);
        that.fidelTimeout = null;
      }
      that.linkCardState = FetchState.NONE;
      that.fidelScriptLoaded = false;
      that.fidelScriptState = FetchState.ERROR;
      console.log('error loading fidel script', error);
    };

    this.renderer.appendChild(document.body, this.fidelScriptElement);
  }

  linkCardState: FetchState = FetchState.NONE;
  private pendingShowLinkCard: boolean = false;
  showLinkCard() {
    if (!this._visible || !this._fidelInitialized) return;
    const userId = this.navControlService.user?.id;
    const that = this;

    if (!!userId && !!this.configResult && !!this.fidelScriptLoaded) {

      this.linkCardState = FetchState.LOADING;
      this.pendingShowLinkCard = false;

      const existingFormContents = this.fidelContainer.nativeElement.innerHTML;
      if (!!existingFormContents && existingFormContents.length > 0) {
        // We have already loaded the fidel card link form
        return;
      }

      const sdkKey = this.configResult.sdkKey; // "pk_test_e6bc304c-bbad-4796-9c2a-605cda324a04";
      const Fidel = window['Fidel'];
      const fidelService = this.fidelService;

      let fidelConfig = {
        companyName: "GetZeals",
        sdkKey: sdkKey,
        programId: this.configResult.programId, // "0de8a6d5-6fb6-4dfb-9f5a-d5da005b733a",
        countries: ['USA'],
        custom: {
          // logo: {
          //   url: "https://app.getzeals.com/assets/ZealsLogoHeader.png",
          // }
          close: {
          },
          submit: {
            defaultText: this.translateService.instant('LINK_CARD'),
            style: {
              backgroundColor: "#ed2d92",
            }
          },
          title: {
            text: this.translateService.instant('LINK_YOUR_CARD'),
          },
          overlay: {
            style: {
              // Position at top of screen
              // top: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }
          },
        },
        // callback(err, data) {
        //   console.log(err, err);
        //   console.log("data", data);
        //   console.log(arguments);
        // },
        container: this.fidelContainer.nativeElement,
        onCardVerificationStartedCallback() {
          console.log("onCardVerificationStartedCallback called");
          console.log(arguments);
        },
        onCardVerifiedCallback() {
          console.log("onCardVerifiedCallback called");
          console.log(arguments);
        },
        onCardVerifyFailedCallback() {
          console.log("onCardVerifyFailedCallback called");
          console.log(arguments);
        },
        async onCardEnrolledCallback() {
          try {
            const userPaymentMethod = await fidelService.saveLinkResult(arguments, userId);
            that.linkCardState = FetchState.LOADED_ALL;
            that.cardLinkEvent.emit(CardLinkEventType.CARD_LINKED);
            console.log("onCardEnrolledCallback called");
            console.log(arguments);
            if (!!arguments && !!arguments[0]) {
              that.cardLinked.emit(arguments[0]);
            }
          }
          catch (error) {
            console.log("error", error);
            that.linkCardState = FetchState.ERROR;
            that.cardLinkEvent.emit(CardLinkEventType.CARD_LINK_ERROR);
          }
        },
        onCardEnrollFailedCallback() {
          that.linkCardState = FetchState.ERROR;
          that.cardLinkEvent.emit(CardLinkEventType.CARD_LINK_ERROR);
          console.log("onCardEnrollFailedCallback called");
          console.log(arguments);
        },
        onUserCancelledCallback() {
          that.linkCardState = FetchState.NONE;
          console.log("onUserCancelledCallback called");
          that.cardLinkEvent.emit(CardLinkEventType.CARD_LINK_CANCELLED);
        },
      };

      if (this.hideFidelCloseButton) {
        fidelConfig.custom.close['location'] = 'overlay';
      }

      Fidel.openForm(fidelConfig);
    }
    else {
      this.pendingShowLinkCard = true;
      this.linkCardState = FetchState.NONE;
    }
  }

  removeUserPaymentMethod(id: string) {
    this.fidelService.removeUserPaymentMethod(id);
  }
}
