import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { NavControlService } from '../nav/nav-control.service';

export enum ModalWidth {
  NARROW = 'narrow',
  NORMAL = 'normal',
  WIDE = 'wide',
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  @Input() wideMode: ModalWidth = ModalWidth.NORMAL;
  private _isActive: boolean = false;
  @Input()
  set isActive(value: boolean) {
    this._isActive = value;
    this.navControlService?.isShowingModal$.next(value);

    if (value) {
      this.setupModalListeners();
    }
    else {
      this.removeModalListeners();
    }
  }
  get isActive(): boolean {
    return this._isActive;
  }
  @Input() showClose: boolean = true;
  @Output() close = new EventEmitter<void>();

  @ViewChild('myModalContent') modalContent: ElementRef;
  pullDownHandleRef$: BehaviorSubject<ElementRef> = new BehaviorSubject<ElementRef>(null);
  @ViewChild('myPullDownHandle') set pullDownHandle(value: ElementRef) {
    setTimeout(() => {
      this.pullDownHandleRef$.next(value);
    }, 1);
  }

  ModalWidth = ModalWidth;

  constructor(
    private readonly navControlService: NavControlService,
  ) {}

  closeStyle = {
    transitionDuration: '325ms',
    animationDuration: '325ms',
  }

  FaTimes = faTimes;

  closeModal(duration: number = 0) {
    console.log(`closeModal(${duration})`);
    if (!duration || duration <= 0) {
      duration = 325;
    }
    duration = Math.max(duration, 325);
    duration = Math.min(duration, 625);
    if (duration > 325) {
      this.closeStyle.transitionDuration = `${duration.toFixed(0)}ms`;
      this.closeStyle.animationDuration = `${duration.toFixed(0)}ms`;
      setTimeout(() => {
        this.closeStyle.transitionDuration = '325ms';
        this.closeStyle.animationDuration = '325ms';
      }, duration);
    }
    this.close.emit();
  }

  // Setup listeners to disable scrolling when modal is open
  setupModalListeners() {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';
    document.body.style.width = '100vw';
    document.body.style.position = 'fixed';
    // Disable scrolling on touch devices
    const _d = document as any;
    _d.body.style['overscroll-behavior-y'] = 'none';
    if (!!_d.documentElement) {
      _d.documentElement.style['overscroll-behavior-y'] = 'none';
    }
  }

  // Remove listeners to disable scrolling when modal is open
  removeModalListeners() {
    document.body.style.overflow = 'auto';
    document.body.style.height = 'auto';
    document.body.style.width = 'auto';
    document.body.style.position = 'static';
    // Enable scrolling on touch devices
    const _d = document as any;
    _d.body.style['overscroll-behavior-y'] = 'auto';
    if (!!_d.documentElement) {
      _d.documentElement.style['overscroll-behavior-y'] = 'auto';
    }
  }
}
