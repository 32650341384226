import { gql } from "apollo-angular";
import { WaitlistDetails } from "../fragments/waitlist.fragment.graphql";

// Upsert waitlist request
export const UpsertWaitlistMutation = gql`
    mutation UpsertWaitlist(
          $waitlistType: String!,
          $name: String,
          $companyName: String,
          $street: String,
          $street2: String,
          $city: String,
          $state: String,
          $zip: String,
          $country: String,
          $phone: String,
          $email: String,
          $website: String
      ) {
        upsertWaitlist(
            waitlistType: $waitlistType,
            name: $name
            companyName: $companyName
            street: $street
            street2: $street2
            city: $city
            state: $state
            zip: $zip
            country: $country
            phone: $phone
            email: $email
            website: $website
        ) {
        ...WaitlistDetails
        }
    }
    ${WaitlistDetails}
`;
