import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavControlService } from '../nav-control.service';
import { LoginState } from 'src/app/user-auth.service';
import { Subject, takeUntil } from 'rxjs';
import { Ledger } from 'src/app/model/ledger';
import { LedgerService } from 'src/app/services/ledger.service';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.css']
})
export class TabBarComponent implements OnInit, OnDestroy {

  ledger: Ledger;
  hideNavbar: boolean = false;

  LoginState = LoginState;


  constructor(
    private readonly navControlService: NavControlService,
    private readonly ledgerService: LedgerService,
  ) {}

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.navControlService.isHideNavTabBar$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (isHide) => {
        this.hideNavbar = isHide;
      }
    });

    this.navControlService.isShowingHowItWorks$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (isShowing) => {
        this.showHowItWorks = isShowing;
      }
    });

    this.navControlService.userState$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (userState) => {
        if (userState === LoginState.LOGGED_IN) {
          this.closeLogin();
        }
      }
    });

    this.ledgerService.ledger$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (ledger) => {
        this.ledger = ledger;
      }
    });

    this.navControlService.user$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (user) => {
        if (user?.patron?.id) {
          this.ledgerService.ownerId = user.patron.id;
        }
      }
    });
  }

  get userState(): LoginState {
    return this.navControlService.userState;
  }

  goToPatronEarnings() {
    this.navControlService.goToPatronEarnings();
  }

  goToOffers() {
    this.navControlService.goToOffers();
  }

  signIn() {
    this.navControlService.showLogin();
  }

  showHowItWorks: boolean = false;
  closeHowItWorks() {
    this.navControlService.hideHowItWorks();
  }

  showLogin: boolean = false;
  closeLogin() {
    this.showLogin = false;
  }

}
