<div class="flex flex-row justify-end items-center relative">
  <button *ngIf="userState !== LoginState.LOGGED_IN" data-collapse-toggle="navbar-default" type="button"
    (click)="toggleMenu()" class="md:block menu-button mr-4" aria-controls="navbar-default"
    aria-expanded="false">
    <span class="sr-only">Open main menu</span>
    <svg [ngClass]="{'fill-zinc-900': !isExpanded, 'fill-zinc-900 md:fill-white': isExpanded}" class="w-6 h-6 " viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clip-rule="evenodd"></path>
    </svg>
  </button>

  <div class="flex flex-row items-center justify-end"
    *ngIf="userState === LoginState.LOGGED_OUT || userState === LoginState.ERROR">
    <button
      class="hidden sm:flex text-center text-gray-500 text-sm font-medium leading-tight flex-row justify-center items-center mr-4"
      (click)="signIn()" [ngClass]="{'md:text-gray-500': !isExpanded, 'md:text-white': isExpanded}">
      <img src="/assets/icons/user_white.png" class="w-5 h-6 object-contain hidden"
        [ngClass]="{'md:block': isExpanded}" />
      <img src="/assets/icons/user.png" class="w-5 h-6 object-contain block" [ngClass]="{'md:hidden': isExpanded}" />
      <span class="ml-2">
        Sign In
      </span>
    </button>
    <button class="button-white-patron-pink !text-sm" (click)="signUp()">
      Join Now
    </button>
  </div>
  <div *ngIf="userState === LoginState.LOGGING_IN" class="flex h-full justify-center items-center opacity-25">
    <div class="loader-wrapper flex h-full justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-7 w-7"></div>
    </div>
  </div>
  <div class="flex flex-row items-center justify-end" *ngIf="userState === LoginState.LOGGED_IN">
    <div class="hidden cursor-pointer h-9 w-9 justify-center items-center corner-25 overflow-hidden relative"
      (click)="toggleMenu()">
      <div class="absolute inset-0 flex justify-center items-center corner-25 ">
        <img *ngIf="isExpanded" src="/assets/icons/user_white.png" class="w-full h-full object-contain" />
        <img *ngIf="!isExpanded" src="/assets/icons/user.png" class="w-full h-full object-contain" />
      </div>
      <img class="absolute inset-0 w-full h-full object-cover rounded-full" *ngIf="!!user?.userImage?.imageUrl"
        [src]="user?.userImage?.imageUrl">
    </div>
    <div class="flex flex-row gap-2 cursor-pointer" (click)="toggleAccount()">
      <div class="relative">
        <div class="flex justify-center items-center corner-25 w-7 h-7">
          <img [src]="isExpanded ? '/assets/icons/user_white.png' : '/assets/icons/user.png'" class="hidden md:block w-full h-full object-contain"
          [ngClass]="{'drop-shadow-sm': isExpanded}" />
          <img src="/assets/icons/user.png" class="md:hidden w-full h-full object-contain drop-shadow-sm" />
        </div>
        <img class="absolute inset-0 w-7 h-7 object-cover rounded-full" *ngIf="!!user?.userImage?.imageUrl"
          [src]="user?.userImage?.imageUrl">
      </div>
      <div class="cursor-pointer flex flex-col justify-center items-start gap-0.5"
      [ngStyle]="detailsContainerStyle"
      >
        <div class="text-gray-500 text-sm font-medium leading-none" [ngClass]="{'md:text-gray-100 md:drop-shadow': isExpanded}">{{ greeting }}</div>
        <div class="flex flex-row" [ngClass]="{'md:drop-shadow': isExpanded}">
          <span class="text-gray-500 text-xs font-medium leading-none" [ngClass]="{'md:text-gray-100': isExpanded}">Cash: </span>
          <span class="text-black text-xs font-medium leading-none" [ngClass]="{'md:text-white': isExpanded}">{{ ((ledger?.intBalance || 0.00) / 100.0) | currency }}</span>
        </div>
        <div class="flex flex-row" [ngClass]="{'md:drop-shadow': isExpanded}">
          <span class="text-gray-500 text-xs font-medium leading-none" [ngClass]="{'md:text-gray-100': isExpanded}">Donations: </span>
          <span class="text-black text-xs font-medium leading-none" [ngClass]="{'md:text-white': isExpanded}">{{ ((ledger?.intRewardsCharityBalance || 0.00) / 100.0) | currency }}</span>
        </div>
      </div>
    </div>
  </div>

  <div #cashOutWidget class="hidden md:flex absolute right-0 top-[50px]">
    <app-cash-out-widget></app-cash-out-widget>
  </div>

  <div *ngIf="isMenuOpen" class="menu-container">
    <app-menu (clickOutside)="closeMenu()"></app-menu>
  </div>
</div>
