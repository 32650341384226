<div
  class="mt-0 mb-12 pb-12 mx-auto bg-white z-1 w-full h-full sm:min-w-[500px] overflow-auto z-20 flex flex-col justify-start items-center transition-all duration-300 min-h-[585px]">
  <div class="bg-pink w-full h-12 mb-8 sm:h-14 flex justify-center items-center p-2 relative">
    <img src="assets/getZealsLogoWhite.png" class="w-auto max-w-[150px] max-h-9 sm:max-h-10" />
    <button class="absolute right-0 p-4" (click)="handleClose()">
      <fa-icon [icon]="FaXmark" [styles]="{'color': 'white'}"></fa-icon>
    </button>
  </div>
  <div
    class="p-2 sm:p-12 bg-white z-1 w-full h-full flex flex-col justify-start items-center transition-all duration-300">
    <div class="flex flex-row w-full justify-center items-center pb-12">
      <div class="w-12 h-12 rounded-full justify-center items-center flex flex-none">
        <svg width="100%" height="100%" viewBox="0 0 80 80" fill="none"
          [ngClass]="{'step-complete': isStepActive(StepState.CLAIM), 'step-incomplete': !isStepActive(StepState.CLAIM)}"
          xmlns="http://www.w3.org/2000/svg">
          <path id="Vector"
            d="M40 0.9375C18.4264 0.9375 0.9375 18.4264 0.9375 40C0.9375 61.5736 18.4264 79.0625 40 79.0625C61.5736 79.0625 79.0625 61.5736 79.0625 40C79.0625 18.4264 61.5736 0.9375 40 0.9375ZM48.1367 59.4827H37.6395V29.3475H31.8633V20.5173H48.1367V59.4827Z" />
        </svg>
      </div>
      <div [ngClass]="{'bg-pink': isStepActive(StepState.LINK), 'bg-zinc-500': !isStepActive(StepState.LINK)}"
        class="step-link"></div>
      <div class="w-12 h-12 rounded-full justify-center items-center flex flex-none">
        <svg width="100%" height="100%" viewBox="0 0 80 80" fill="none"
          [ngClass]="{'step-complete': isStepActive(StepState.LINK), 'step-incomplete': !isStepActive(StepState.LINK)}"
          xmlns="http://www.w3.org/2000/svg">
          <path id="Vector"
            d="M40 0.9375C18.4264 0.9375 0.9375 18.4264 0.9375 40C0.9375 61.5736 18.4264 79.0625 40 79.0625C61.5736 79.0625 79.0625 61.5736 79.0625 40C79.0625 18.4264 61.5736 0.9375 40 0.9375ZM54.4903 59.9798H25.5097V57.608C41.1875 39.1167 43.3469 36.1284 43.3469 32.7831C43.3469 30.6703 41.9548 29.2534 39.9591 29.2534C37.7716 29.2534 36.438 30.7288 36.438 33.0744V33.8281H26.1377V33.1073C26.1377 25.2287 31.3017 20.0209 39.8331 20.0209C48.0988 20.0209 53.8055 25.1028 53.8055 32.4183C53.8055 37.3216 51.9914 41.0856 43.6575 50.7392H54.4903V59.9798Z" />
        </svg>
      </div>
      <div [ngClass]="{'bg-pink': isStepActive(StepState.EARN), 'bg-zinc-500': !isStepActive(StepState.EARN)}"
        class="step-link"></div>
      <div class="w-12 h-12 rounded-full justify-center items-center flex flex-none">
        <svg width="100%" height="100%" viewBox="0 0 80 80" fill="none"
          [ngClass]="{'step-complete': isStepActive(StepState.EARN), 'step-incomplete': !isStepActive(StepState.EARN)}"
          xmlns="http://www.w3.org/2000/svg">
          <path id="Vector"
            d="M40 0.9375C18.4264 0.9375 0.9375 18.4264 0.9375 40C0.9375 61.5736 18.4264 79.0625 40 79.0625C61.5736 79.0625 79.0625 61.5736 79.0625 40C79.0625 18.4264 61.5736 0.9375 40 0.9375ZM39.8367 59.9339C31.4631 59.9339 25.7067 54.4681 25.4725 46.4027H35.4739C35.5909 49.1495 37.2992 50.9748 39.8616 50.9748C42.525 50.9748 44.3928 49.0142 44.3928 46.1666C44.3928 43.3189 42.4572 41.5516 39.4248 41.5516H33.1834V39.3797L39.5284 28.948H26.4503V20.0661H53.1875V21.8563L45.3669 34.3761C50.9534 35.7777 54.5275 40.3 54.5275 46.3347C54.5275 54.2591 48.3863 59.9339 39.8367 59.9339Z" />
        </svg>
      </div>
    </div>

    <div *ngIf="!isDataLoaded" class="w-full flex justify-center items-center py-12">
      <div class="loader-wrapper flex flex-col justify-center items-center">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        <div class="text-lg text-center">Loading...</div>
      </div>
    </div>

    <div *ngIf="isDataLoaded" class="w-full flex justify-center items-center py-3">
      <div *ngIf="isStepCurrent(StepState.CLAIM)">
        <app-login *ngIf="userState !== LoginState.LOGGED_IN" [title]="loginTitle | async"
          [details]="loginDetails | async"></app-login>
        <div *ngIf="userState === LoginState.LOGGED_IN">
          <div class="w-full h-full flex flex-col justify-center items-center">
            <div class="text-2xl font-bold antialiased text-center">{{ 'CLAIM_OFFER' | translate }}</div>
            <div class="text-center text-sm m-5">{{ 'CLAIM_STEP_1_MESSAGE_SIGNED_IN' | translate }}</div>
            <button class="button-patron-pink" (click)="claimOffer()">{{ 'CLAIM_OFFER' | translate }}</button>
          </div>
        </div>
      </div>

      <app-credit-cards *ngIf="isStepCurrent(StepState.LINK)" [initWithFidelAddCard]="true" [hideFidelCloseButton]="true"
        (cardLinkEvent)="handleCardLinkEvent($event)"></app-credit-cards>

      <div *ngIf="isStepCurrent(StepState.EARN)">
        <div class="w-full h-full flex flex-col justify-center items-center">
          <div class="text-2xl font-bold antialiased text-center">{{ 'CLAIM_STEP_COMPLETE' | translate }}</div>
          <div class="text-center text-sm m-5">{{ 'CLAIM_STEP_COMPLETE_MESSAGE' | translate }}</div>
          <app-offer-action class="w-full mb-8"></app-offer-action>
          <button class="button-patron-pink min-w-[225px]" (click)="userOfferService.done()">{{ 'DONE' | translate
            }}</button>
        </div>

      </div>
    </div>

  </div>
</div>
