import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FetchState } from 'src/app/app.module';
import { InviteService } from 'src/app/services/invite.service';
import { VenueService } from 'src/app/venue.service';

@Component({
  selector: 'app-share-invite',
  templateUrl: './share-invite.component.html',
  styleUrls: ['./share-invite.component.css']
})
export class ShareInviteComponent implements OnInit {

  @Input() saveHandler: (email: string, message?: string) => any;
  @Input() allowMessage: boolean = false;

  @Output() close = new EventEmitter<void>();

  FetchState = FetchState;

  constructor(
    private fb: FormBuilder,
  ) { }


  shareForm: FormGroup;

  FaClose = faClose;

  saveState: FetchState = FetchState.NONE;
  errorMessage: string;

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.shareForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      message: ["", [Validators.maxLength(500)]],
    });
  }

  async save() {
    this.errorMessage = null;
    if (this.saveState === FetchState.LOADING) {
      return;
    }

    if (!this.shareForm.valid) {
      return;
    }

    if (!this.saveHandler) {
      return;
    }

    try {
      this.saveState = FetchState.LOADING;
      let result = await this.saveHandler(this.shareForm.get('email').value, this.shareForm.get('message').value);
      if (result) {
        this.saveState = FetchState.LOADED_ALL;
        this.setup();
        this.handleClose();
      } else {
        this.saveState = FetchState.ERROR;
      }
    }
    catch (e) {
      console.log(e);
      this.saveState = FetchState.ERROR;
      this.errorMessage = "An error occurred. Please try again.";

      // If e contains a message, display it
      if (e.graphQLErrors?.length > 0) {
        this.errorMessage = e.graphQLErrors[0].message;
      }
    }
  }

  handleClose() {
    this.close.emit();
  }

}
