
export enum WeekDays{
  Sunday = 0b00000001,
  Monday = 0b00000010,
  Tuesday = 0b00000100,
  Wednesday = 0b00001000,
  Thursday = 0b00010000,
  Friday = 0b00100000,
  Saturday = 0b01000000
}

export function dayAbbreviation(day: WeekDays): string {
  switch(day) {
    case WeekDays.Sunday:
      return "Su";
    case WeekDays.Monday:
      return "M";
    case WeekDays.Tuesday:
      return "Tu";
    case WeekDays.Wednesday:
      return "W";
    case WeekDays.Thursday:
      return "Tu";
    case WeekDays.Friday:
      return "F";
    case WeekDays.Saturday:
      return "Sa";
    default:
      return "";
  }
}

export const AllDaysOfTheWeek: Array<WeekDays> = [WeekDays.Sunday, WeekDays.Monday, WeekDays.Tuesday, WeekDays.Wednesday, WeekDays.Thursday, WeekDays.Friday, WeekDays.Saturday];

// export enum WeekDaySet{
//   All,  //[Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday]
//   WeekdaysOnly, //[Monday, Tuesday, Wednesday, Thursday, Friday, Saturday]
//   WeekendsOnly, //[Saturday, Sunday]
//   MidWeek,  //[Tuesday, Wednesday, Thursday]
//   HumpDay, //[Wednesday]
//   Miscellanious, // Any combination of atleast 2 days that does not fall into one of the above
// }

export function parseDaysOfTheWeekInt(daysOfTheWeek: number): Array<WeekDays> {
  const result: Array<WeekDays> = [];
  for(const weekDay of AllDaysOfTheWeek) {
    if (daysOfTheWeek & weekDay) {
      result.push(weekDay);
    }
  }
  return result;
}

export function daysOfWeekToInt(daysOfTheWeek: Array<WeekDays>): number {
  let result: number = 0;
  for(const weekDay of daysOfTheWeek) {
    result = result | weekDay;
  }
  return result;
}
