import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CharityService } from '../../charity.service';
import { OfferReturnType, VenueOffer } from '../../model/venue_offer.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Charity } from '../../model/charity.model';
import { FetchState } from '../../app.module';
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-incentives',
  templateUrl: './incentives.component.html',
  styleUrls: ['./incentives.component.css']
})
export class IncentivesComponent implements OnInit {
  @Input() incentiveGroup: FormGroup;

  OfferReturnType = OfferReturnType;
  FetchState = FetchState;

  FaCharity = faHandHoldingHeart;

  private _selectedCharity: Charity;
  get selectedCharity(): Charity {
    return this._selectedCharity;
  }
  set selectedCharity(value: Charity) {
    this._selectedCharity = value;
  }
  charityPopup: boolean = false;

  constructor(protected readonly charityService: CharityService) { }

  ngOnInit(): void {
    this.selectedCharity = this.incentiveGroup.get('charity').value;
    this.incentiveGroup.addControl('charitySearchTerm', new FormControl(''));
    this.charityService.searchTerm$.next('');
    this.incentiveGroup.get('charitySearchTerm').valueChanges.subscribe((value) => {
      this.charityService.searchTerm$.next(value);
    });

    // // If charity is already selected, fetch the Charity
    // if (this.incentiveGroup.get('charity').value) {
    //   this.fetchCharity(this.incentiveGroup.get('charity').value.id);
    // }

    // // If the charityId changes, fetch the Charity
    // this.incentiveGroup.get('charity').valueChanges.subscribe((value) => {
    //   console.log("charity changed", value);
    //   if (value) {
    //     this.fetchCharity(value.id);
    //   }
    // });

  }

  onCharitySearch(event: any) {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.charityService.searchTerm$.next(searchTerm);
  }

  // async fetchCharity(charityId: string) {
  //   // Fetch the selected Charity
  //   try {
  //     const charity = await this.charityService.fetchCharity(charityId);
  //     console.log("charity", charity);
  //     // this.incentiveGroup.get('charity').setValue(charity);
  //     this.selectedCharity = charity;
  //   }
  //   catch(error) {
  //     console.error(error);
  //   }
  // }

  get isCharity(): boolean {
    return !!this.incentiveGroup.get('isCharity')?.value;
  }

  get returnType(): OfferReturnType {
    return this.incentiveGroup.get('returnType')?.value ?? OfferReturnType.Percentage;
  }

  get amount(): number {
    return this.incentiveGroup.get('amount')?.value ?? 0;
  }

  get percentage(): number {
    return this.incentiveGroup.get('percentage')?.value ?? 0;
  }

  get maxReward(): number {
    return this.incentiveGroup.get('maxReward')?.value ?? 0;
  }

  get minSpend(): number {
    return this.incentiveGroup.get('minSpend')?.value ?? 0;
  }

  limitDecimalPlaces(event: Event, decimalPlaces: number = 0, maxValue: number = null) {
    const value = (event.target as HTMLInputElement).value;
    let parsedValue = parseFloat(value);
    if (maxValue && parsedValue > maxValue) {
      parsedValue = maxValue;
    }
    const formattedValue = parsedValue.toFixed(decimalPlaces);
    this.incentiveGroup.get((event.target as HTMLInputElement).id).setValue(formattedValue);
  }


  toggleReturnType() {
    const currentReturnType = this.returnType;
    if (currentReturnType === OfferReturnType.Percentage) {
      this.incentiveGroup.get('returnType').setValue(OfferReturnType.Amount);
    }
    else {
      this.incentiveGroup.get('returnType').setValue(OfferReturnType.Percentage);
    }
  }

  loadMoreCharities() {
    this.charityService.loadMoreCharities();
  }

  selectCharity(charity) {
    if (charity) {
      this.selectedCharity = charity;
      this.incentiveGroup.get('charity').setValue(charity);
    }
    this.charityPopup = false;
  }
}
