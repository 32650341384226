import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavControlService, NavRole } from '../../nav/nav-control.service';
import { User } from 'src/app/model/user.model';
import { faArrowLeft, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { OfferFilter } from 'src/app/offers.service';
import { Venue } from 'src/app/model/venue.model';
import { VenueService } from 'src/app/venue.service';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent implements OnInit, OnDestroy {

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  role: NavRole;
  venues: Venue[] = [];
  venue: Venue;

  FaArrowLeft = faArrowLeft;
  FaChevronUp = faArrowUp;
  NavRole = NavRole;
  OfferFilter = OfferFilter;

  constructor(
    private readonly venueService: VenueService,
    private readonly navControlService: NavControlService,
  ) { }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.navControlService.role$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (role) => {
        this.role = role;
      }
    });

    this.navControlService.selectedVenue$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (venue) => {
        this.venue = venue;
      }
    });

    this.venueService.venues$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (venues) => {
        this.venues = venues;
      }
    });

  }

  get user(): User {
    return this.navControlService.user;
  }

  hasRole(role: NavRole): boolean {
    return this.navControlService.hasRole(role);
  }

  setRole(role: NavRole) {
    this.navControlService.setRole(role);
  }

  handleClickBusinesses() {
    if (this.hasRole(NavRole.VENUE) || this.hasRole(NavRole.ADMIN)) {
      if (this.role !== NavRole.VENUE) {
        this.setRole(NavRole.VENUE);
      }
      this.goToVenues()
    }
    else {
      this.navControlService.goToBusinessLanding();
    }
  }

  handleClickCharities() {
    if (this.hasRole(NavRole.CHARITY)) {
      if (this.role !== NavRole.CHARITY) {
        this.setRole(NavRole.CHARITY);
      }
      // this.goToCharities()
    }
    else {
      this.navControlService.goToCharityLanding();
    }
  }

  goToEditUser() {
    this.closeAll();
    this.showEditUser = true;
  }

  goToVenues() {
    this.closeAll();
    this.navControlService.goToVenues();
  }

  goToVenue(venueId?: string) {
    venueId = venueId || this.navControlService.selectedVenue?.id;
    if (!!venueId) {
      this.navControlService.goToVenue(venueId);
    }
  }

  goToVenueBank(venueId?: string) {
    venueId = venueId || this.navControlService.selectedVenue?.id;
    if (!!venueId) {
      this.navControlService.goToVenueBank(venueId);
    }
  }

  goToVenueMerchant(venueId?: string) {
    venueId = venueId || this.navControlService.selectedVenue?.id;
    if (!!venueId) {
      this.navControlService.goToVenueMerchant(venueId);
    }
  }

  goToHowItWorks() {
    this.closeAll();
    this.navControlService.showHowItWorks();
    this.handleClose();
  }
  toggleHowItWorks() {
    this.closeAll();
    this.navControlService.showHowItWorks();
  }

  goToPatronEarnings() {
    this.closeAll();
    this.navControlService.goToPatronEarnings();
  }

  goToPatronCreditCards() {
    this.closeAll();
    this.showCreditCards = true;
  }

  goToPatronBank() {
    this.closeAll();
    this.showBankAccount = true;
  }

  goToOffers(filters: Array<OfferFilter> = []) {
    this.closeAll();
    this.navControlService.goToOffers(filters);
  }

  async logout() {
    await this.navControlService.logout();
    this.handleClose();
  }

  handleClose() {
    this.close.emit();
  }

  showDetails: boolean = false;

  private _showCreditCards: boolean = false;
  get showCreditCards(): boolean {
    return this._showCreditCards;
  }
  set showCreditCards(value: boolean) {
    this._showCreditCards = value;
    this.showDetails = value;
  }

  private _showBankAccount: boolean = false;
  get showBankAccount(): boolean {
    return this._showBankAccount;
  }
  set showBankAccount(value: boolean) {
    this._showBankAccount = value;
    this.showDetails = value;
  }

  private _showEditUser: boolean = false;
  get showEditUser(): boolean {
    return this._showEditUser;
  }
  set showEditUser(value: boolean) {
    this._showEditUser = value;
    this.showDetails = value;
  }
  closeEditUser() {
    this.showEditUser = false;
  }

  closeAll() {
    this.showDetails = false;
    this._showCreditCards = false;
    this._showBankAccount = false;
    this._showEditUser = false;
  }

  goToAbout() {
    this.navControlService.goToAbout();
  }

  goToFaq() {
    this.navControlService.goToFaq();
  }

  goToTerms() {
    this.navControlService.goToTerms();
  }

  goToPrivacy() {
    this.navControlService.goToPrivacy();
  }

}
