<div class="flex flex-col w-full h-screen pb-safe overflow-y-auto overflow-x-hidden" #mainContent id="main-content"
(scroll)="onMainScroll($event)"
>
  <app-nav-header class="z-10" *ngIf="!(isHideNavHeader$ |async)"
  ></app-nav-header>
  <div class="flex-grow w-full">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
  <!-- <app-tab-bar class="pb-safe" *ngIf="!(isHideNavFooter$ |async)"></app-tab-bar> -->
  <app-footer class="hidden md:block" *ngIf="!(isHideNavFooter$ |async)"></app-footer>
</div>
<app-modal [isActive]="!closingOfferDetails && !!selectedOfferData" (close)="closeOfferDetails()" [showClose]="true">
  <app-offer-details ></app-offer-details>
</app-modal>
<app-modal [isActive]="!!showAccountDetails" (close)="closeAccountDetails()" [showClose]="true">
  <app-account-details (close)="closeAccountDetails()"></app-account-details>
</app-modal>
<app-modal [isActive]="!!showLogin" (close)="closeLogin()" [showClose]="true" [wideMode]="ModalWidth.NARROW">
  <app-login class="w-full"></app-login>
</app-modal>
<app-modal [isActive]="!!showHowItWorks" (close)="closeHowItWorks()" [showClose]="true">
  <app-how-it-works ></app-how-it-works>
</app-modal>
