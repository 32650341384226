import { gql } from "apollo-angular";
import { AchAccountDetails } from "../fragments/ach_account.fragment.graphql";


export const UpsertAchAccountMutation = gql`
mutation UpsertAchAccount($publicToken: String!, $accountId: String!, $accountName: String!, $ownerId: String!, $controllerUserId: String) {
  upsertAchAccount(publicToken: $publicToken, accountId: $accountId, accountName: $accountName, ownerId: $ownerId, controllerUserId: $controllerUserId) {
    ...AchAccountDetails
  }
}
${AchAccountDetails}
`;

export const RemoveAchAccountMutation = gql`
mutation RemoveAchAccount($ownerId: String!, $id: String) {
  removeAchAccount(ownerId: $ownerId, id: $id)
}`;
