<div class="content"
[ngClass]="{'slide-down': hideNavbar}">
<div class="bar-button" (click)="goToOffers()">
  <div class="grow shrink basis-0 self-stretch flex-col justify-start items-center inline-flex">
    <img class="w-7 h-7 object-contain" src="/assets/icons/search.svg" />
      <div class="text-center text-gray-500 text-xs font-normal leading-3">Discover</div>
  </div>
  </div>
  <div *ngIf="userState !== LoginState.LOGGED_IN" class="bar-button"
    (click)="showHowItWorks = true">
    <img class="w-6 h-6 object-contain opacity-80" src="/assets/GetZealsLogoBlack.svg" />
    <div class="text-center text-gray-500 text-xs font-normal leading-3">How it Works</div>
  </div>
  <div *ngIf="userState === LoginState.LOGGED_IN" class="bar-button" (click)="goToOffers()">
      <img class="w-6 h-6 object-contain opacity-80" src="/assets/GetZealsLogoBlack.svg" />
      <div class="text-center text-gray-500 text-xs font-normal leading-3">My Zeals</div>
  </div>
  <div *ngIf="userState === LoginState.LOGGED_IN" class="bar-button" (click)="goToPatronEarnings()">
    <div class="w-6 h-6 p-0.5 justify-center items-center inline-flex">
      {{ ((ledger?.intAvailableToTransfer || 0.00) / 100.0) | currency }}
    </div>
    <div class="text-center text-gray-500 text-xs font-normal leading-3">Cash Back</div>
  </div>
  <!-- <div *ngIf="userState === LoginState.LOGGED_IN" class="bar-button w-16"> -->
    <!-- <div class="w-6 h-6 relative rounded-full"></div>
    <div class="text-center text-gray-500 text-xs font-normal leading-3">Experiences</div> -->
  <!-- </div> -->
  <div *ngIf="userState !== LoginState.LOGGED_IN" class="bar-button"
  (click)="signIn()">
      <img class="w-5 h-5 object-contain" src="/assets/icons/user.png" />
      <div class="text-center text-gray-500 text-xs font-normal leading-3">Sign In</div>
  </div>
</div>

<app-modal [isActive]="!!showHowItWorks" (close)="closeHowItWorks()" [showClose]="true">
  <app-how-it-works ></app-how-it-works>
</app-modal>
