import { ElementRef, Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, debounce, debounceTime, fromEvent, throttleTime } from 'rxjs';
import { OfferFilter, OffersService } from '../../offers.service';
import { AppleService } from '../../../app/apple/apple.service';
import { Charity } from 'src/app/model/charity.model';
import { LoginState, LoginSubState, UserAuthService } from 'src/app/user-auth.service';
import { GoogleService } from 'src/app/auth/google.service';
import { User } from 'src/app/model/user.model';
import { PatronOffer } from 'src/app/model/patron-offer.model';
import { VenueOffer } from 'src/app/model/venue_offer.model';
import { LoginViewState } from 'src/app/auth/login/login.component';

export enum NavRole {
  GUEST = 'guest',
  VENUE = 'venue',
  PATRON = 'patron',
  CHARITY = 'charity',
  ADMIN = 'admin',
}


@Injectable({
  providedIn: 'root'
})
export class NavControlService {
  selectedCharity$: BehaviorSubject<Charity> = new BehaviorSubject<Charity>(null);
  get selectedCharity() {
    return this.selectedCharity$.value;
  }

  selectedVenue$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  get selectedVenue() {
    return this.selectedVenue$.value;
  }

  highlightedOfferId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  get highlightedOfferId() {
    return this.highlightedOfferId$.value;
  }
  set highlightedOfferId(offerId: string) {
    this.highlightedOfferId$.next(offerId);
  }

  hasCachedToken$: BehaviorSubject<boolean> = this.userAuthService.hasCachedToken$;

  constructor(
    public readonly userAuthService: UserAuthService,
    private readonly appleService: AppleService,
    private readonly googleService: GoogleService,
    private readonly router: Router,
    private readonly offersService: OffersService,
    private zone: NgZone,
  ) {
    // Set the viewingMap flag
    this.viewingMap = localStorage.getItem('viewingMap') === 'true';

    this.userAuthService.state$.subscribe((state)=> {
      if (state === LoginState.LOGGED_IN) {
        this.hideLogin();

        this.roleSubject.next(NavRole.PATRON);

        // If the current route is '/login', then navigate to the home page
        const currentUrl = this.router.url;
        const urlParts = currentUrl.split('?');
        const path = urlParts[0];
        const queryString = urlParts[1];

        // See if we have stored the return url
        const storedReturnUrl = this.userAuthService.getReturnUrl();
        if (storedReturnUrl) {
          this.userAuthService.clearReturnUrl();
          this.zone.run(() => {
            this.router.navigateByUrl(storedReturnUrl);
          });
          return;
        }
        else if (path === '/login') {
          this.zone.run(() => {
            this.router.navigateByUrl('/');
          });
        }
      }
      else if (state === LoginState.LOGGED_OUT) {
        this.roleSubject.next(NavRole.GUEST);
        this.hideAccountDetails();
      }
    });

    this.menuOpenSubject.pipe(
      throttleTime(10)
    ).subscribe({
      next: (isOpen) => {
        if (isOpen) {
          this._menuIsOpen = true;
        }
        else {
          this._menuIsOpen = false;
          this.hideAccountDetails();
          // this.hideLogin();
        }
      }
    });

    this.isShowingModal$.pipe(
      throttleTime(1)
    ).subscribe({
      next: (isShowingModal) => {
        if (isShowingModal) {
          setTimeout(() => {
            this.hideNavTabBar();
          }, 1);
        }
        else {
          setTimeout(() => {
            this.showNavTabBar();
          }, 1);
        }
      }
    });
  }

  private _viewingMap: boolean = false;
  get viewingMap(): boolean {
    return this._viewingMap;
  }
  set viewingMap(viewingMap: boolean) {
    this._viewingMap = viewingMap;
    localStorage.setItem('viewingMap', viewingMap ? 'true' : 'false');
  }

  userState$ = this.userAuthService.state$.asObservable();
  get userState(): LoginState {
    return this.userAuthService.state$.value;
  }
  setUserState(newState: LoginState) {
    this.userAuthService.state$.next(newState);
  }

  get userSubState$(): Observable<LoginSubState> {
    return this.userAuthService.subState$.asObservable();
  }
  get userSubState(): LoginSubState {
    return this.userAuthService.subState$.value;
  }

  get user$(): BehaviorSubject<User> {
    return this.userAuthService.currentUser$;
  }
  get user(): User {
    return this.userAuthService.user;
  }

  public registerSignInResultHandler(handler: Function) {
    this.googleService.registerSignInResultHandler(handler);
    this.appleService.registerSignInResultHandler(handler);
  }
  public removeSignInResultHandler(handler: Function) {
    this.googleService.removeSignInResultHandler(handler);
    this.appleService.removeSignInResultHandler(handler);
  }

  public setReturnUrl(url: string) {
    this.userAuthService.setReturnUrl(url);
  }

  private roleSubject = new BehaviorSubject<NavRole>(NavRole.GUEST);
  role$ = this.roleSubject;

  get role(): NavRole {
    return this.roleSubject.value;
  }

  setRole(newRole: NavRole, navigate: boolean = true) {
    this.closeMenu();
    if (newRole === NavRole.CHARITY) {
      if (navigate) {
        this.router.navigateByUrl('/charities');
      }
    }
    else if (newRole === NavRole.VENUE) {
      if (navigate) {
        this.router.navigateByUrl('/venues');
      }
    }
    else if (newRole === NavRole.PATRON) {
      if (navigate) {
        this.router.navigateByUrl('/offers');
      }
    }
    this.roleSubject.next(newRole);
  }

  hasRole(role: NavRole): boolean {
    const user: User = this.userAuthService.user;
    if(!user) {
      return role === NavRole.GUEST;
    }
    else {
      if (role === NavRole.CHARITY || role === NavRole.VENUE) {
        return !!user.operator?.id;
      }
      else if (role === NavRole.PATRON) {
        return !!user.patron?.id;
      }
      else if (role === NavRole.ADMIN) {
        return !!user.userAdmin?.id;
      }
      else {
        return false;
      }
    }
  }

  primaryColor: string = 'patron-pink';

  // Setup control to open/close menu
  private _menuIsOpen: boolean = false;
  private menuOpenSubject = new BehaviorSubject<boolean>(false);
  menuOpen$ = this.menuOpenSubject.asObservable();

  get isMenuOpen(): boolean {
    return this._menuIsOpen;
  }

  isShowingModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isShowingModal() {
    return this.isShowingModal$.value;
  }


  isHideNavHeader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isHideNavHeader() {
    return this.isHideNavHeader$.value;
  }
  toggleNavHeader() {
    this.isHideNavHeader$.next(!this.isHideNavHeader$.value);
  }
  showNavHeader() {
    this.isHideNavHeader$.next(false);
  }
  hideNavHeader() {
    this.isHideNavHeader$.next(true);
  }

  isNavHeaderExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isNavHeaderExpanded() {
    return this.isNavHeaderExpanded$.value;
  }
  toggleNavHeaderExpanded() {
    this.isNavHeaderExpanded$.next(!this.isNavHeaderExpanded$.value);
  }
  expandNavHeader() {
    this.isNavHeaderExpanded$.next(true);
  }
  collapseNavHeader() {
    this.isNavHeaderExpanded$.next(false);
  }

  setSearchElement(elementRef: ElementRef) {
    this.searchFocused$ = fromEvent(elementRef.nativeElement, 'focus');
    this.searchFocused$.subscribe({
      next: (event) => {
        console.log('searchFocused$');
        // If we are on the home page, then go to the offers page
        if (this.router.url === '/' || this.router.url === '/home') {
          this.goToOffers();
        }
      }
    });
  }
  searchFocused$: Observable<any> = new Observable<any>();

  isHideNavFooter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isHideNavFooter() {
    return this.isHideNavFooter$.value;
  }
  toggleNavFooter() {
    this.isHideNavFooter$.next(!this.isHideNavFooter$.value);
  }
  showNavFooter() {
    this.isHideNavFooter$.next(false);
  }
  hideNavFooter() {
    this.isHideNavFooter$.next(true);
  }

  isHideNavTabBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isHideNavTabBar() {
    return this.isHideNavTabBar$.value;
  }
  toggleNavTabBar() {
    this.isHideNavTabBar$.next(!this.isHideNavTabBar$.value);
  }
  showNavTabBar() {
    this.isHideNavTabBar$.next(false);
  }
  hideNavTabBar() {
    this.isHideNavTabBar$.next(true);
  }

  isShowingLogin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isShowingLogin() {
    return this.isShowingLogin$.value;
  }
  loginViewState$: BehaviorSubject<LoginViewState> = new BehaviorSubject<LoginViewState>(LoginViewState.LOGIN);
  get loginViewState() {
    return this.loginViewState$.value;
  }
  set loginViewState(viewState: LoginViewState) {
    this.loginViewState$.next(viewState);
  }
  toggleLogin() {
    this.isShowingLogin$.next(!this.isShowingLogin$.value);
  }
  showLogin(loginViewState: LoginViewState = LoginViewState.LOGIN) {
    this.loginViewState = loginViewState;
    this.isShowingLogin$.next(true);
  }
  hideLogin() {
    this.isShowingLogin$.next(false);
  }

  isShowingHowItWorks$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isShowingHowItWorks() {
    return this.isShowingHowItWorks$.value;
  }
  toggleHowItWorks() {
    this.isShowingHowItWorks$.next(!this.isShowingHowItWorks$.value);
  }
  showHowItWorks() {
    this.isShowingHowItWorks$.next(true);
  }
  hideHowItWorks() {
    this.isShowingHowItWorks$.next(false);
  }

  isShowingAccountDetails$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isShowingAccountDetails() {
    return this.isShowingAccountDetails$.value;
  }
  toggleAccountDetails() {
    this.isShowingAccountDetails$.next(!this.isShowingAccountDetails$.value);
  }
  showAccountDetails() {
    this.isShowingAccountDetails$.next(true);
  }
  hideAccountDetails() {
    this.isShowingAccountDetails$.next(false);
  }

  openMenu() {
    this.menuOpenSubject.next(true);
    // this.renderer.addClass(document.body, 'overflow-hidden');
  }

  closeMenu() {
    this.menuOpenSubject.next(false);
  }
  toggleMenu() {
    // Debounce the toggleMenu() function
    if (this.isMenuOpen) {
      this.closeMenu();
    }
    else {
      this.openMenu();
    }
  }

  followReturnUrl() {
    const returnUrl = this.userAuthService.getReturnUrl();
    if (returnUrl) {
      this.router.navigateByUrl(returnUrl);
      return;
    }
    this.router.navigateByUrl('/');
  }

  goToHome() {
    this.router.navigateByUrl('/');
    this.closeMenu();
  }

  goToChat() {
    this.router.navigateByUrl('/chat');
    this.closeMenu();
  }

  goToBusinessLanding() {
    this.router.navigateByUrl('/business');
    this.closeMenu();
  }

  goToCharityLanding() {
    this.router.navigateByUrl('/charity');
    this.closeMenu();
  }

  goToLogin(defaultReturnUrl?: string) {
    // Get the current route so we can return to it after login
    const currentUrl = defaultReturnUrl ?? this.router.url;
    if (currentUrl && currentUrl !== '/login') {
      this.userAuthService.setReturnUrl(currentUrl);
    }
    this.router.navigate(['/login']);
    this.closeMenu();
  }

  goToOffers(filters: Array<OfferFilter> = []) {
    if (filters?.length > 0) {
      this.offersService.selectFilters(filters);
    }
    // this.router.navigate(['/offers'], { queryParams: { filters: JSON.stringify(filters) } });
    this.router.navigate(['/offers']);
    this.closeMenu();
  }

  goToPivotEntityOffers(pivotId: string) {
    if (!pivotId) return;
    this.router.navigate(['/offers/e', pivotId]);
    this.closeMenu();
  }

  goToOffer(offerData: PatronOffer | VenueOffer | string) {
    if (!offerData) {
      return;
    }
    let offerId: string;
    if (typeof offerData === 'string') {
      offerId = offerData;
    }
    else {
      offerId = offerData.id;
      if (offerData instanceof VenueOffer) {
        offerData = offerId;
      }
    }

    this.offerData$.next(offerData);
    this.router.navigate(['/offers']);
    this.closeMenu();
  }
  offerData$: BehaviorSubject<string | PatronOffer> = new BehaviorSubject<string | PatronOffer>(null);

  goToPatronCreditCards() {
    this.setRole(NavRole.PATRON, false);
    this.router.navigateByUrl('/patron/cards');
    this.closeMenu();
  }

  goToPatronBank() {
    this.setRole(NavRole.PATRON, false);
    this.router.navigateByUrl('/patron/bank');
    this.closeMenu();
  }

  goToPatronEarnings() {
    this.setRole(NavRole.PATRON, false);
    this.router.navigateByUrl('/patron/earnings');
    this.closeMenu();
  }

  goToVenueOffers() {
    const venueId = this.selectedVenue?.id;
    if (venueId) {
      this.router.navigateByUrl(`/venue/${venueId}/offers`);
      this.closeMenu();
    }
    else {
      this.router.navigateByUrl(`/offers`);
      this.closeMenu();

    }
  }

  goToVenue(venueId) {
    this.router.navigateByUrl(`/venue/${venueId}`);
    this.closeMenu();
  }

  goToVenueBank(venueId) {
    this.setRole(NavRole.VENUE, false);
    this.router.navigateByUrl(`/venue/${venueId}/bank`);
    this.closeMenu();
  }

  goToVenueMerchant(venueId) {
    this.setRole(NavRole.VENUE, false);
    this.router.navigateByUrl(`/venue/${venueId}/merchant`);
    this.closeMenu();
  }

  goToCharityOffers() {
    const charityId = this.selectedCharity?.id;
    if (charityId) {
      this.router.navigateByUrl(`/charity/${charityId}/offers`);
    }
  }

  goToVenues() {
    this.router.navigateByUrl('/venue');
    this.closeMenu();
  }

  goToAffiliates() {
    this.router.navigateByUrl('/affiliate');
    this.closeMenu();
  }

  goToHowItWorks() {
    this.router.navigateByUrl('/how-it-works');
    this.closeMenu();
  }

  goToAbout() {
    this.router.navigate(['/about']);
    this.closeMenu();
  }

  goToContact() {
    this.router.navigate(['/contact']);
    this.closeMenu();
  }

  goToFaq() {
    this.router.navigate(['/faq']);
    this.closeMenu();
  }

  goToTerms() {
    this.router.navigateByUrl('/term');
    this.closeMenu();
  }

  goToPrivacy() {
    this.router.navigateByUrl('/privacy');
    this.closeMenu();
  }

  goToEditUser() {
    this.closeMenu();
    // Make sure we are logged in
    if (this.userState !== LoginState.LOGGED_IN) {
      return;
    }
    this.router.navigateByUrl('/patron/profile');
  }

  async logout() {
    await this.userAuthService.logout();
    this.router.navigate(['']);
    this.closeMenu();
  }

  selectVenue(venue, navigate = true) {
    this.selectedVenue$.next(venue);
    if (navigate) {
      this.router.navigateByUrl(`/venue/${venue.id}`);
      this.closeMenu();
    }
  }

}
