import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BusinessType, WaitlistService } from 'src/app/services/waitlist.service';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Waitlist } from 'src/app/model/waitlist.model';

@Component({
  selector: 'app-waitlist',
  templateUrl: './waitlist.component.html',
  styleUrls: ['./waitlist.component.css']
})
export class WaitlistComponent implements OnInit {
  @Input() showingModal: boolean = false;
  @Input() isShowModal: boolean = false;
  @Input() businessType: BusinessType;

  @Output() closeModalEvent = new EventEmitter<boolean>();

  waitlistForm!: FormGroup;
  waitlistJoined: boolean = false;
  waitlistError: boolean = false;

  urlRegex: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  constructor(private waitlistService: WaitlistService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.waitlistForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?!d+$)(?:[a-zA-Z- ][a-zA-Z- ]*)?$'), // only A-Za-z and spaces allowed
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      companyName: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?!d+$)(?:[a-zA-Z- ][a-zA-Z- ]*)?$'), // only A-Za-z and spaces allowed
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      street: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?!d+$)(?:[0-9a-zA-Z- ][0-9a-zA-Z- ]*)?$'),
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      street2: [
        '',
        [
          Validators.pattern('^(?!d+$)(?:[0-9a-zA-Z- ][0-9a-zA-Z- ]*)?$'),
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      city: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?!d+$)(?:[0-9a-zA-Z- ][0-9a-zA-Z- ]*)?$'),
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      state: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?!d+$)(?:[a-zA-Z- ][a-zA-Z- ]*)?$'),
          Validators.minLength(2),
          Validators.maxLength(25),
        ],
      ],
      zip: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?!d+$)(?:[0-9][0-9]*)?$'),
          Validators.minLength(5),
          Validators.maxLength(5),
        ],
      ],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      website: ['', [Validators.required, Validators.pattern(this.urlRegex)]],
    })
  }

  get name() {
    return this.waitlistForm.get("name");
  }

  get companyName() {
    return this.waitlistForm.get("companyName");
  }

  get street() {
    return this.waitlistForm.get("street");
  }

  get street2() {
    return this.waitlistForm.get("street2");
  }

  get city() {
    return this.waitlistForm.get("city");
  }

  get state() {
    return this.waitlistForm.get("state");
  }

  get zip() {
    return this.waitlistForm.get("zip");
  }

  get phone() {
    return this.waitlistForm.get("phone");
  }

  get email() {
    return this.waitlistForm.get("email");
  }

  get website() {
    return this.waitlistForm.get("website");
  }

  handleClickOutsideModal() {
    if (this.isShowModal && !this.showingModal) {
      this.showingModal = false;
    }
  }

  closeWaitlist() {
    this.showingModal = !this.showingModal;
    console.log(this.showingModal);
    this.closeModalEvent.emit(this.showingModal);
  }

  async onSubmit() {
    const newForm = this.waitlistForm.value;
    if (this.waitlistForm.valid) {
      try {
        const waitlist = new Waitlist();

        if (this.businessType == 0) waitlist.waitlistType = "Charity";
        if (this.businessType == 1) waitlist.waitlistType = "Venue";

        waitlist.name = newForm.name;
        waitlist.companyName = newForm.companyName;
        waitlist.street = newForm.street;
        waitlist.street2 = newForm.street2;
        waitlist.city = newForm.city;
        waitlist.state = newForm.state;
        waitlist.zip = newForm.zip;
        waitlist.phone = newForm.phone;
        waitlist.email = newForm.email;
        waitlist.website = newForm.website;

        await this.waitlistService.upsertWaitlist(waitlist);
        this.waitlistJoined = true;
      } catch (error) {
        console.log(`error adding form to waitlist`, error);
        this.waitlistError = true;
      }
    } else {
      console.log('Waitlist Form is invalid');
    }
  }
}
