<div class="content md:screen-minus-nav-height">

  <div class="p-0 w-full flex flex-col gap-0 h-full">

    <!-- <div class="filters-container sticky top-0"> -->
    <div class="filters-container max-padding">
      <div class="filters-list my-1">
        <div class="flex flex-row w-full overflow-x-auto md:justify-center items-center">
          <app-offer-filter *ngIf="!!(entityFilter$ | async)"
            [selectedFilters]="selectedFilters" [filter]="(entityFilter$ | async)" [fetchState]="offersServiceState" (filterSelected)="selectFilter($event)"></app-offer-filter>
          <app-offer-filter *ngFor="let filter of possibleFilters"
            [selectedFilters]="selectedFilters" [filter]="filter" [fetchState]="offersServiceState" (filterSelected)="selectFilter($event)"></app-offer-filter>
        </div>
        <div class="hidden sm:flex md:hidden flex-grow justify-end">
          <button (click)="toggleMap()"
            class="flex justify-center items-center w-12 h-12 !rounded-3xl button-zinc-700 font-bold antialiased cursor-pointer text-sm md:text-base">
            <fa-icon [icon]="showMap ? FaList : FaMap" [classes]="['fa-regular']"
              [styles]="{'color': 'zinc'}"></fa-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- <app-map *ngIf="showMap" [places]="mapOffers" class="absolute inset-0 w-full h-full flex-grow"></app-map> -->
    <app-map *ngIf="showMap" [places]="mapOffers" class="block md:hidden w-full h-full"></app-map>

    <div class="offers-list-container flex-grow scroll-smooth"
    [ngClass]="{'justify-center': offersCount === 0}"
    >
      <div class="overflow-y-auto h-full w-96 relative"
        [ngClass]="{'flex justify-center items-center': offersCount === 0 && offersServiceState === FetchState.LOADED_ALL}"
      (scroll)="onScrollList($event)" #offersListContainer>
        <div *ngIf="(offersServiceState === FetchState.LOADED_ALL || offersServiceState === FetchState.GOOD) && offersCount === 0" class="text-center text-2xl flex justify-center items-center">
          {{ 'NO_OFFERS_FOUND' | translate }}
        </div>
        <div *ngIf="offersServiceState === FetchState.ERROR" class="text-center text-2xl flex justify-center items-center">
          <p>{{ 'ERROR_LOADING_OFFERS' | translate }}</p>
        </div>
        <div id="{{offerData.offer?.id}}" class="offer-list-item" *ngFor="let offerData of offers" >
          <app-offer-list class="cursor-pointer" [offer]="offerData.offer" (click)="scrollToPlace(offerData.offer?.id, true)"></app-offer-list>
        </div>

        <app-fade-toggle class="fake-offers-container absolute inset-0" [isVisible]="offersServiceState === FetchState.LOADING" *ngIf="offersServiceState === FetchState.LOADING">
          <div #fakeOffersContainer>
            <div *ngFor="let fakeOffer of fakeOffers" class="mb-2">
              <app-offer-list></app-offer-list>
            </div>
          </div>
        </app-fade-toggle>

        <div class="min-h-[25px]"
        [ngStyle]="{'height': listContainerBufferHeight+'px'}"></div>
      </div>
      <div class="flex-grow flex ml-2">
        <app-map [places]="mapOffers" class="w-full h-full"></app-map>
      </div>

    </div>

    <div class="py-0 px-4 w-full h-full items-center flex md:hidden flex-col max-w-6xl max-padding flex-grow overflow-y-auto relative" *ngIf="!showMap"
    [ngClass]="{'justify-start': offersCount !== 0, 'justify-center ': offersCount === 0 && (offersServiceState === FetchState.LOADED_ALL || offersServiceState === FetchState.GOOD)}"
    >

      <div *ngIf="offersServiceState === FetchState.ERROR" class="flex-grow h-full flex justify-center items-center">
        <p>{{ 'ERROR_LOADING_OFFERS' | translate }}</p>
      </div>

      <div *ngIf="offersCount === 0 && (offersServiceState === FetchState.LOADED_ALL || offersServiceState === FetchState.GOOD)"
      class="flex-grow h-full text-center text-2xl flex justify-center items-center">
        {{ 'NO_OFFERS_FOUND' | translate }}
      </div>
      <div *ngIf="offersCount !== 0" class="flex-grow h-full offers-grid-container" (scroll)="onScroll($event)">
        <app-offer-tile2 class="offer-tile" *ngFor="let offerData of offers" [offerData]="offerData" (click)="showOfferDetails(offerData.offer)"></app-offer-tile2>
      </div>


      <!-- <div style="margin-top: 30px; height: 50px;" class="flex justify-center">
        <button *ngIf="offersServiceState === FetchState.GOOD" (click)="loadMore()"
          class="w-full button-patron-pink hover:border-patron-pink"
          style="width: 120px; height: 50px; margin-left: 10px;">
          {{ 'VIEW_MORE' | translate }}
        </button>
      </div> -->
    </div>


    <!-- <div id="offersLoading" *ngIf="offersServiceState === FetchState.LOADING && offersCount === 0"
      class="flex justify-center items-center absolute inset-0">
      <div class="loader-wrapper flex justify-center items-center mb-4">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      </div>
    </div> -->


    <!-- <div class="flex sm:hidden justify-center sticky left-0 right-0 transition-all duration-200 ease-cubic-bezier" -->
    <div class="flex sm:hidden justify-center fixed left-0 right-0 transition-all duration-200 ease-cubic-bezier"
    [ngClass]="{'bottom-[65px]': !isHidingNavTabBar, 'bottom-[15px]': isHidingNavTabBar}"
    >
      <button (click)="toggleMap()"
        class="text-center m-2 w-12 h-12 !rounded-3xl button-zinc-700 !px-3 font-bold antialiased cursor-pointer text-sm md:text-base z-50 !border-none drop-shadow-md">
        <!-- [ngClass]="{ 'button-patron-pink': showMap, 'bg-white': !showMap, 'text-white': showMap }" -->
        <fa-icon [icon]="showMap ? FaList : FaMap" [classes]="['fa-regular']" [styles]="{'color': 'zinc'}"></fa-icon>
      </button>
      <!-- <button (click)="toggleMap()"
          class="text-center m-2 py-2 rounded-3xl hover:bg-patron-pink hover:text-white px-3 text-patron-pink font-bold antialiased cursor-pointer text-sm md:text-base"
          [ngClass]="{ 'bg-patron-pink': showMap, 'bg-white': !showMap, 'text-white': showMap }">
          <fa-icon [icon]="showMap ? FaList : FaMap" [classes]="['fa-regular']"
            [styles]="{'color': 'zinc'}"></fa-icon>
        </button> -->
    </div>
  </div>
</div>
