import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavControlService } from '../../nav/nav-control.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { InviteService } from 'src/app/services/invite.service';
import { FetchState } from 'src/app/app.module';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import ErrorHelper from 'src/app/utils/enums/error.utils';
import { LoginState } from 'src/app/user-auth.service';

@Component({
  selector: 'app-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.css']
})
export class AcceptInviteComponent implements OnInit, OnDestroy {

  isLoggedIn: boolean = false;

  FetchState = FetchState;
  faError = faTriangleExclamation;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly navControlService: NavControlService,
    private readonly inviteService: InviteService,
  ) { }

  private token: string;
  private user: User;
  errorMessage: string;

  ngOnInit(): void {
    this.route.params.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (params) => {
        this.token = params['token'];
        console.log(this.token);
        if (!!this.token && this.token.length > 0) {
          this.acceptInvite();
        }
      }
    });

    // If the user is not logged in, then we need to wait for them to log in before we can accept the invite
    this.navControlService.userState$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (userState) => {
        if (userState !== LoginState.LOGGED_IN) {
          setTimeout(() => {
            this.isLoggedIn = false;
            this.navControlService.showLogin();
          }, 1);
        }
        else {
          setTimeout(() => {
            this.isLoggedIn = true;
          }, 1);
        }
      }
    });

    // Subscribe to current user, then accept invite
    this.navControlService.user$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (user) => {
        this.user = user;
        if (!!user) {
          this.acceptInvite();
        }
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openLogin() {
    this.navControlService.showLogin();
  }

  acceptState: FetchState = FetchState.NONE;
  async acceptInvite() {
    this.errorMessage = null;
    if (!!this.token && !!this.user) {
      this.acceptState = FetchState.LOADING;
      console.log('Accepting invite');
      try {
        let result = await this.inviteService.acceptInvite(this.token);
        if (!!result && !!result.success) {
          this.acceptState = FetchState.LOADED_ALL;

          // Go the correct page based on entity type
          if (!!result.entityId) {
            if (result.entityType === 'Venue') {
              this.navControlService.goToVenue(result.entityId);
              return;
            }
          }

          this.navControlService.goToHome();
        }
        else {
          this.acceptState = FetchState.ERROR;
          if (result?.error) {
            if (result.error === 'Invite not found') {
              this.errorMessage = ErrorHelper.getReassurancePhrase();
            }
            else if (result.error === 'Invalid token') {
              this.errorMessage = ErrorHelper.getReassurancePhrase();
            }
            else if (result.error === 'Invalid user') {
              this.errorMessage = ErrorHelper.getReassurancePhrase();
            }
            else {
              this.errorMessage = ErrorHelper.getErrorPhrase();
              console.warn(`Unhandled error: ${result.error}`);
            }
          }
        }
      }
      catch (error) {
        console.error(error);
        this.acceptState = FetchState.ERROR;
      }
    }
  }
}
