<div formArrayName="promos" class="bg-[#E1E3E6] flex flex-row justify-center p-8 w-full">
    <div>
        <div class="w-[75vw] flex flex-row justify-between items-center">
            <p class="text-2xl font-bold antialiased">Promotional Links</p>
            <button class="bg-gray-700 text-white px-6 h-[40px] rounded-lg" (click)="addPromoOpen = true">Add</button>
        </div>

        <!-- Add Promo popup -->
        <div class="flex flex-row justify-center z-10 mt-5">
            <div class="bg-white w-[80vw] p-8 rounded-lg shadow-xl" *ngIf="addPromoOpen">

                <div class="px-3 w-[75vw] mt-4">
                    <select id="cars" class="mb-5 text-business-blue">
                        <option value="volvo" *ngFor="let option of options">{{option}}</option>
                      </select>
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased mb-2"
                        for="grid-last-name">
                        Name
                    </label>
                    <input
                        class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-last-name" type="text">
                </div>
                <div class="px-3 w-[75vw] mt-4">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased mb-2"
                        for="grid-last-name">
                        URL
                    </label>
                    <input
                        class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-last-name" type="text">
                </div>
                <div class="flex flex-row justify-around mt-5">
                    <button class="text-business-blue rounded-lg border-business-blue px-8 py-2"
                        (click)="addPromoOpen = !addPromoOpen">Cancel</button>
                    <button
                        class="bg-business-blue text-white rounded-lg border-business-blue px-[75px] py-2">Save</button>
                </div>
            </div>
        </div>

        <div class="mt-4">
          <!-- <div *ngFor="let promoControl of promosArray.controls; let i = index">
            <app-edit-promo [parentForm]="promoControl"></app-edit-promo>
            <button type="button" (click)="removePromo(i)">Remove</button>
          </div> -->
          <div class="bg-white w-[250px] h-[200px] rounded-lg flex flex-col justify-around items-center shadow-xl">
              <p class="text-lg font-bold antialiased">In App</p>
              <div>
                  <div class="flex flex-row">
                      <p class="p-2">$0.00</p>
                      <p class="p-2">$0.00</p>
                  </div>
                  <div class="flex flex-row">
                      <p class="p-2">$0.00</p>
                      <p class="p-2">$0.00</p>
                  </div>
              </div>
              <button class="text-lg font-bold antialiased">View / Share</button>
          </div>
        </div>
    </div>
    <div>
