import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '../environments/environment';
import { NavControlService } from './components/nav/nav-control.service';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalWidth } from './components/modal/modal.component';
import { AppInjector } from './app.injector';
import { PatronOffer } from './model/patron-offer.model';
import { UserOffersService } from './services/user-offers.service';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild("mainContent")
  private mainContentDiv!: ElementRef<HTMLElement>;

  ModalWidth = ModalWidth;

  constructor(
    private readonly navControlService: NavControlService,
    private readonly injector: Injector,
    public translate: TranslateService,
    private readonly router: Router,
    private readonly userOffersService: UserOffersService,
  ) {
    // In your main module or component
    AppInjector.setInjector(this.injector);

    if (!environment.production) {
      console.log('=== APP COMPONENT ===');
      console.log(environment.production);
      console.log(environment.api);

      loadDevMessages();
      loadErrorMessages();
    }

    this.isHideNavHeader$ = this.navControlService.isHideNavHeader$;
    this.isHideNavFooter$ = this.navControlService.isHideNavFooter$;

    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en');

    // Check to see if there is a language in the URL params, if so, use that language
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    if (lang) {
      // Check for supported languages
      if (lang.match(/en|es/)) {
        this.translate.use(lang);
      }
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });

  }

  ngOnInit(): void {
    this.navControlService.isShowingHowItWorks$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (isShowing) => {
        this.showHowItWorks = isShowing;
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isHeaderExpanded: boolean = true;
  isHideNavHeader$: BehaviorSubject<boolean>;
  isHideNavFooter$: BehaviorSubject<boolean>;

  /**
    Whenever a new route is activated
    @param _event
  */
  private _scrollToTop: any | undefined;
  onActivate(_event: any): void {
    if (this.mainContentDiv) {
      if (this._scrollToTop) clearInterval(this._scrollToTop);
      this._scrollToTop = setInterval(() => {
        // Get scroll position of mainContentDiv
        let pos = (this.mainContentDiv.nativeElement as HTMLElement).scrollTop;
        if (pos > 0) {
          (this.mainContentDiv.nativeElement as HTMLElement).scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
          if (this._scrollToTop) {
            clearInterval(this._scrollToTop);
            this._scrollToTop = undefined;
          }
        }
      }, 16);
    }
  }

  // hideNavbar = false;
  private lastScrollTop = 0;
  private hideNavbarThreshold = 25; // Adjust this threshold as needed
  onMainScroll(event: any) {
    const target = event.target;
    const scrollTop = target.scrollTop;
    const maxScrollTop = target.scrollHeight - target.clientHeight;

    // Hide navbar if scrolled past threshold or at max scroll position
    // Show nav bar if scrolling back up
    if (this.lastScrollTop > scrollTop) {
      this.navControlService.showNavTabBar();
    }
    else {
      if (scrollTop > this.hideNavbarThreshold || scrollTop >= maxScrollTop) {
        this.navControlService.hideNavTabBar();
      }
      else {
        this.navControlService.showNavTabBar();
      }
    }
    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  }

  showHowItWorks: boolean = false;
  closeHowItWorks() {
    this.navControlService.hideHowItWorks();
  }

  closeLogin() {
    this.navControlService.hideLogin();
  }
  get showLogin(): boolean {
    return this.navControlService.isShowingLogin$.value;
  }

  closeAccountDetails() {
    this.navControlService.hideAccountDetails();
  }
  get showAccountDetails(): boolean {
    return this.navControlService.isShowingAccountDetails$.value;
  }


  ////////////////////////////
  // OFFERS
  ////////////////////////////
  closingOfferDetails: boolean = false;
  get selectedOfferData(): string | PatronOffer {
    return this.userOffersService.offer;
  }
  showOfferDetails(offer: PatronOffer) {
    if (!offer) {
      return;
    }
    this.userOffersService.selectedOfferData = offer;
  }

  closeOfferDetails() {
    this.closingOfferDetails = true;
    // We don't want the offer to disappear all of a sudden, so we start closing the modal and then wait a bit before actually setting the selectedOfferData to null
    setTimeout(() => {
      this.userOffersService.selectedOfferData = null;
      this.closingOfferDetails = false;
    }, 300);
  }

}
