import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AcceptInviteMutation, RemoveInviteMutation } from '../graphql/mutations/invite.mutation.graphql';

export type UserInviteResponse = {success: boolean, error?: string, entityId?: string, entityType?: string};

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  constructor(
    private readonly apollo: Apollo
  ) { }


  public async acceptInvite(token: string): Promise<UserInviteResponse> {
    const variables = {
      token
    };

    return new Promise<UserInviteResponse>((resolve, reject) => {
      this.apollo.mutate({
        mutation: AcceptInviteMutation,
        variables
      })
      .subscribe({
        next: (response) => {
          console.log('=== ACCEPT INVITE RESPONSE ===');
          const data = response['data'];
          resolve(data['acceptInvite']);
        },
        error: (err) => {
          console.log('=== ACCEPT INVITE ERROR ===');
          console.log(err);
          reject(err);
        }
      });
    });
  }

  async removeInvite(inviteId: string): Promise<boolean> {
    console.log(`removeInvite`);

    // Validate the input
    if (!inviteId || inviteId.length === 0) {
      return Promise.reject('Invalid invite');
    }

    //GraphQL mutation
    return new Promise<boolean>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: RemoveInviteMutation,
          variables: {
            id: inviteId
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          resolve(!!data);
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }
}
