import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Observable, Subject, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { FetchState } from './app.module';
import { OperatorType, PageAndSortInput, SearchInput } from './graphql/types.graphql';
import { CharitiesQuery, CharityQuery } from './graphql/queries/charities.query.graphql';
import { Charity } from './model/charity.model';
import { NavControlService } from './components/nav/nav-control.service';
import { LoginState } from './user-auth.service';
import { CharityDetails } from './graphql/fragments/charity.fragment.graphql';

@Injectable({
  providedIn: 'root'
})
export class CharityService {
  state: FetchState = FetchState.NONE;
  charities$: BehaviorSubject<Charity[]> = new BehaviorSubject<Charity[]>([]);
  searchTerm$ = new Subject<string>();
  private _searchTerm: string = null;
  private currentPage: number = 0;
  private pageSize: number = 7;
  private pendingSearchTerm: string;

  static readonly SEARCH_CHARITIES = gql`
    ${CharityDetails}
    query Charities($search: [SearchInput!]!, $sort: PageAndSortInput) {
      charities(search: $search, sort: $sort) {
        ...CharityDetails
      }
    }
  `;

  constructor(
    private readonly navControlService: NavControlService,
    private readonly apollo: Apollo
    ) {
    this.searchTerm$.pipe(
      debounceTime(300),        // wait for 300ms pause in events
      distinctUntilChanged()   // only emit if value is different from previous value
    ).subscribe(searchTerm => {
      // If searchTerm has changed, then reset page to 0 and reset list
      this._searchTerm = searchTerm;
      this.clear();
      this.fetchCharities(searchTerm, true);
    });

    this.navControlService.userState$.subscribe({
      next: (userState) => {
        if (userState === LoginState.LOGGED_IN) {
          if (this.pendingSearchTerm) {
            this.fetchCharities(this.pendingSearchTerm);
          }

          if (this.pendingCharityId) {
            this.fetchCharity(this.pendingCharityId);
          }
        }
      }
    });
  }

  private clear() {
    this.state = FetchState.NONE;
    this.currentPage = 0;
    this.charities$.next([]);
  }

  loadMoreCharities() {
    this.fetchCharities(this._searchTerm);
  }

  fetchCharities(searchTerm: string = null, forceRefresh: boolean = false) {
    if (this.navControlService.userState !== LoginState.LOGGED_IN) {
      this.pendingSearchTerm = searchTerm;
      this.charities$.next([]);
      return;
    }

    if (this.state === FetchState.LOADING) {
      return;
    }
    if (forceRefresh) {
      this.clear();
    }

    const searchInputs: Array<SearchInput> = [{
      value: searchTerm,
      searchFields: ['name'],
      operator: OperatorType.LIKE
    }];

    const sort: PageAndSortInput = {
      page: this.currentPage,
      pageSize: this.pageSize,
      sortFields: []
    }
    console.log("Fetching Charities");

    this.state = FetchState.LOADING;
    this.apollo.query({
      query: CharitiesQuery,
      variables: {
        // operatorId: operatorId,
        search: searchInputs.map((searchInput) => {
          return {
            value: searchInput.value,
            searchFields: searchInput.searchFields,
            operator: searchInput.operator.toUpperCase()
          };
        }),
        sort: sort,
      },
      fetchPolicy: forceRefresh ? 'no-cache' : 'cache-first'
    }).subscribe({
      next: ({ data }) => {
        const charities: Charity[] = [].concat(this.charities$.value);
        if (!!data && !!data['charities']) {
          for (const charityData of data['charities']) {
            if (charityData) {
              charities.push(Charity.parseResponse(charityData));
            }
          }

          if (data['charities'].length < this.pageSize) {
            this.state = FetchState.LOADED_ALL;
          }
          else {
            this.state = FetchState.GOOD;
          }
        }
        else {
          this.state = FetchState.LOADED_ALL;
        }
        this.currentPage++;
        this.charities$.next(charities);
      }
    })
  }

  private pendingCharityId: string = null;
  stateCharity: FetchState = FetchState.GOOD;
  async fetchCharity(charityId: string, forceRefresh: boolean = false): Promise<Charity> {
    if (this.navControlService.userState !== LoginState.LOGGED_IN) {
      this.pendingCharityId = charityId;
      return Promise.resolve(null);
    }

    if (this.stateCharity === FetchState.LOADING) {
      return Promise.resolve(null);
    }

    if (!forceRefresh) {
      const existingCharity = this.charities$.value.find(v => v.id === charityId);
      if (existingCharity) {
        this.stateCharity = FetchState.LOADED_ALL;
        return Promise.resolve(existingCharity);
      }
    }

    this.pendingCharityId = null;

    this.stateCharity = FetchState.LOADING;

    return new Promise((resolve, reject) => {
      this.apollo.query({
        query: CharityQuery,
        variables: {
          id: charityId,
        }
      }).subscribe({
        next: ({ data, loading }) => {
          let charity: Charity = null;
          if (!!data && !!data['charity']) {
            const charityData = data['charity'];
            if (!!charityData && !!charityData.id) {
              charity = Charity.parseResponse(charityData);
              const charityExists = false;
              const newCharities = [];
              for(let i=0; i<this.charities$.value.length; i++) {
                const nextCharity = this.charities$.value[i];
                if (nextCharity.id === charityData.id) {
                  newCharities.push(charity);
                }
                else {
                  newCharities.push(nextCharity);
                }
              }
              this.charities$.next(newCharities);
            }
          }

          this.stateCharity = FetchState.LOADED_ALL;
          resolve(charity);
        },
        error: (error) => {
          console.error(error);
          this.stateCharity = FetchState.ERROR;
          reject(error);
        }
      })
    }
    );
  }

  page: number = 0;
  search(searchTerm: string): Observable<Charity[]> {

    const sort: PageAndSortInput = {
      page: this.page,
      pageSize: this.pageSize,
      sortFields: []
    };

    const searchInputs: Array<SearchInput> = []
    searchInputs.push({value: searchTerm, searchFields: ["ALL"], operator: OperatorType.LIKE});

    const variables = {
      search: searchInputs.map((searchInput) => {
        return {
          value: searchInput.value,
          searchFields: searchInput.searchFields,
          operator: searchInput.operator.toUpperCase()
        };
      }),
      sort: sort
    };

    return this.apollo.watchQuery<any>({
      query: CharityService.SEARCH_CHARITIES,
      variables: variables
    }).valueChanges.pipe(
      map(result => {
        return result.data.charities.map((entityData: any) => {
          let entity = Charity.parseResponse(entityData);
          return entity;
        });
      })
    );
  }
}
