import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { FetchState } from 'src/app/app.module';

export class ChatMessage {

  constructor({message, role}: {message?: string, role?: string}) {
    this.message = message;
    this.role = role;
  }

  role: string;
  message: string;
}


export class Chat {
  constructor({threadId, messages, chatEnded}: {threadId?: string, messages?: ChatMessage[], chatEnded?: Date | string}) {
    this.threadId = threadId;
    if (!messages) {
      this.messages = [];
    }
    else {
      this.messages = messages.map((m) => new ChatMessage(m));
    }
    if (chatEnded) {
      if (typeof chatEnded === "string") {
        this.chatEnded = new Date(chatEnded);
      }
      else {
        this.chatEnded = chatEnded;
      }
    }
  }

  threadId: string;
  messages: ChatMessage[];
  chatEnded?: Date
}

export const FETCH_CHAT_QUERY = gql`
  query fetchChat($threadId: String) {
    fetchChat(threadId: $threadId) {
      threadId
      messages {
        role
        message
      }
      chatEnded
    }
  }
`;


export const UPSERT_CHAT_MUTATION = gql`
  mutation upsertChat($message: String!, $threadId: String) {
    upsertChat(message: $message, threadId: $threadId) {
      threadId
      messages {
        role
        message
      }
      chatEnded
    }
  }
`;


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.css'
})
export class ChatComponent implements OnInit {

  chatForm: FormGroup;

  FetchState = FetchState;

  constructor(
    private fb: FormBuilder,
    private readonly apollo: Apollo
  ) {}

  ngOnInit(): void {
    this.chat = new Chat({messages: []});
    this.setup();

    this.fetchChat().then((chat) => {
      this.chat = chat;
      console.log('Chat loaded', this.chat);
    });
  }

  setup() {
    this.chatForm = this.fb.group({
      message: [null, Validators.required],
    });
  }

  async sendMessage() {
    console.log('Message sent');
    let message: string = this.chatForm.get('message').value;
    this.upsertChat(message, this.chat.threadId).then((chat) => {
      this.chat = chat;
      this.chatForm.reset();
    });
  }

  private _chat: Chat;
  get chat(): Chat {
    return this._chat;
  }
  set chat(chat: Chat) {
    this._chat = chat;
    // If the chat has ended, then set the chatEnded flag and reset the chat
    if (chat.chatEnded) {
      this.previousChat = chat;
      this.chatForm.reset();
      this.chat = new Chat({messages: []});
    }
  }
  previousChat: Chat;


  ////////////////////////////////
  // CHAT SERVICE
  ////////////////////////////////
  fetchChatState: FetchState = FetchState.NONE;
  async fetchChat(threadId?: string): Promise<Chat> {
    if (this.fetchChatState === FetchState.LOADING) {
      return Promise.reject();
    }
    this.fetchChatState = FetchState.LOADING;

    return new Promise<Chat>((resolve, reject) => {
      this.apollo.query({
        query: FETCH_CHAT_QUERY,
        variables: {
          threadId
        }
      }).subscribe({
        next: ({ data, loading }) => {
          this.fetchChatState = FetchState.LOADED_ALL;
          const chat = new Chat(data['fetchChat']);
          resolve(chat);
        },
        error: (error) => {
          this.fetchChatState = FetchState.ERROR;
          reject(error);
        }
      });
    });
  }


  upsertChatState: FetchState = FetchState.NONE;
  async upsertChat(message: string, threadId?: string): Promise<Chat> {
    if (!message) {
      return Promise.reject();
    }
    if (this.upsertChatState === FetchState.LOADING) {
      return Promise.reject();
    }

    this.upsertChatState = FetchState.LOADING;
    console.log(`upsertChat`, message, threadId);

    //GraphQL mutation
    return new Promise<Chat>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: UPSERT_CHAT_MUTATION,
          variables: {
            message,
            threadId
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          this.upsertChatState = FetchState.LOADED_ALL;
          const upsertedChat = new Chat(data['upsertChat']);
          resolve(upsertedChat);
        },
        error: (error) => {
          this.upsertChatState = FetchState.ERROR;
          console.log(error);
          reject(error);
        }
      });
    });
  }


}
