import { gql } from "apollo-angular";
import { VenueImageDetails } from "./venue-image.fragment.graphql";

export const PaymentMethodTransactionDetails = gql`
${VenueImageDetails}
fragment PaymentMethodTransactionDetails on PaymentMethodTransaction {
  id
  intAmount
  currency
  created
  updated
  auth
  authCode
  cardPresent
  cleared
  reimbursementEligible
  datetime
  venue {
    id
    name
    venueImages {
      ...VenueImageDetails
    }
  }
  paymentMethod {
    id
    scheme
    firstNumbers
    lastNumbers
    expDate
    expMonth
    expYear
    user {
      id
      firstName
      lastName
    }
  }
}
`;
