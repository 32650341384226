import { Injectable } from '@angular/core';
import { PatronOffer } from '../model/patron-offer.model';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { FetchState } from '../app.module';
import { LoginState, UserAuthService } from '../user-auth.service';
import { UserPaymentMethod } from '../model/user_payment_method.model';
import { TranslateService } from '@ngx-translate/core';
import { NavControlService } from '../components/nav/nav-control.service';
import { FidelService } from '../fidel.service';
import { Apollo } from 'apollo-angular';
import { PatronOfferQuery, PatronOffersQuery } from '../graphql/queries/offer.query.graphql';
import { UpsertOfferViewMutation } from '../graphql/mutations/offer_view.mutation.graphql';
import { RemoveClaimMutation, UpdateRewardOfferMutation, UpsertClaimMutation } from '../graphql/mutations/patron_claim.mutation.graphql';
import { Reward } from '../model/ledger';
import { FetchOffersOptions, OfferFilter } from '../offers.service';
import { OperatorType, PageAndSortInput, SearchInput } from '../graphql/types.graphql';
import { EventBusService } from './event-bus.service';
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';

export enum StepState {
  NONE,
  CLAIM,
  LINK,
  EARN,
  ERROR,
  DONE
}

@Injectable({
  providedIn: 'root'
})
export class UserOffersService {
  offer$: BehaviorSubject<PatronOffer> = new BehaviorSubject<PatronOffer>(null);

  private _selectedOfferData: string | PatronOffer;
  get selectedOfferData(): string | PatronOffer {
    return this._selectedOfferData;
  }
  set selectedOfferData(value: string | PatronOffer) {
    if (value instanceof PatronOffer) {
      this.marketingChannelId = value.id;
      this.offer = value;
      this.offerClaim = null;
      this.offerView = null;

      this.loadOffer(null);
    }
    else {
      // this._offerId = value;
      if (!!value) {
        this.marketingChannelId = value;
        this.offer = null;
        this.offerClaim = null;
        this.offerView = null;
        this.loadOffer(null);
      }
      else {
        this.marketingChannelId = null;
        this.offer = undefined;
        this.offerClaim = undefined;
        this.offerView = undefined;
      }
    }
  }

  private _offer: PatronOffer;
  private set offer(value: PatronOffer) {
    this._offer = value;

    this.setMetaTags();

    this.otherOffers = (this.offers$.value ?? []).filter((offer) => !value || offer.id !== value.id).map((offer) => {
      return {offer: offer};
    });

    this.clear();

    if (!!value?.venue?.id) {
      this.fetchOffers({venueId: value.venue.id});
    }

    this.offer$.next(value);
  }
  get offer(): PatronOffer {
    return this._offer;
  }

  private setMetaTags() {
    if (this._offer) {
      // Get the offer image url
      this.metaTagService.updateTag({property: 'og:title', content: this.offer.name});
      this.metaTagService.updateTag({property: 'og:description', content: this.offer.description});
      let imageUrl = this.getOfferMainImageUrl(true);
      if (!!imageUrl && typeof imageUrl === 'string') {
        this.metaTagService.updateTag({property: 'og:image', content: imageUrl});
      }
      this.metaTagService.updateTag({property: 'og:url', content: window.location.href});
      this.metaTagService.updateTag({property: 'og:type', content: 'website'});
    }
  }

  get venuePhone(): string {
    if (this.offer?.venue?.venueAddresses?.length > 0) {
      return this.offer.venue.venueAddresses[0].phone || '';
    }
    return '';
  }

  claimedOffer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get rewardedOffer(): boolean {
    return !!this.offerClaim && this.offerClaim.rewards?.length > 0;
  }

  offerView: any;

  private _offerClaim: any | undefined;
  set offerClaim(offerClaim: any | undefined) {
    this._offerClaim = offerClaim;
    this.setStepState();
    this.claimedOffer$.next(!!offerClaim);
  }
  get offerClaim(): any | undefined {
    return this._offerClaim;
  }

  private _marketingChannelId: string | undefined;
  set marketingChannelId(value: string | undefined) {
    this._marketingChannelId = value;
  }
  get marketingChannelId(): string | undefined {
    return this._marketingChannelId;
  }

  public setStepState(state?: StepState) {
    if (!!state) {
      this.stepState$.next(state);
      return;
    }

    if (this.stateOffer$.value !== FetchState.LOADED_ALL) {
      this.stepState$.next(StepState.NONE);
    }
    if (!this.offerClaim) {
      this.stepState$.next(StepState.CLAIM);
    }
    else if (!this.activeUserPaymentMethods$.value || this.activeUserPaymentMethods$.value.length === 0) {
      this.stepState$.next(StepState.LINK);
    }
    else {
      this.stepState$.next(StepState.EARN);
    }
  }


  LoginState = LoginState;
  StepState = StepState;

  stepState$: BehaviorSubject<StepState> = new BehaviorSubject<StepState>(StepState.NONE);

  claimStep: number = 0;
  userState$: Observable<LoginState>;
  stateUserPaymentMethods$: BehaviorSubject<FetchState>;
  userPaymentMethods$: BehaviorSubject<Array<UserPaymentMethod>>
  activeUserPaymentMethods$: BehaviorSubject<Array<UserPaymentMethod>>

  userNeedsToLinkCard$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly translate: TranslateService,
    private readonly navControlService: NavControlService,
    private readonly fidelService: FidelService,
    private readonly apollo: Apollo,
    private readonly userAuthService: UserAuthService,
    private readonly eventBusService: EventBusService,
    private readonly metaTagService: Meta

  ) {
    this.userState$ = this.navControlService.userState$;
    this.stateUserPaymentMethods$ = this.fidelService.stateUserPaymentMethods$;
    this.userPaymentMethods$ = this.fidelService.userPaymentMethods$;
    this.activeUserPaymentMethods$ = this.fidelService.activeUserPaymentMethods$;

    this.init();
  }


  private _servicesInitialized: boolean = false;
  init(): void {
    if (!this._servicesInitialized) {
      this._servicesInitialized = true;

      this.offers$.pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (offers) => {
          this.otherOffers = (offers ?? []).filter((offer) => !this.offer || offer.id !== this.offer.id).map((offer) => {
            return {offer: offer};
          });
        }
      });

      this.navControlService.offerData$.pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (value) => {
          this.selectedOfferData = value;
        }
      });

      this.navControlService.registerSignInResultHandler(this.handleSignInResult.bind(this));

      this.navControlService.userState$.pipe(
        takeUntil(this.destroy$)
      ).subscribe((state) => {
        if (state === LoginState.LOGGED_OUT) {
          this.offerClaim = undefined;
          this.offerView = undefined;
          this.loadOffer();
          this.updateUserNeedsToLinkCard();
        }
        else if (state === LoginState.LOGGED_IN) {
          // If we are in the "get started" state, then we need to load AND CLAIM the offer
          // const showingGetStarted = this.controller.showGetStartedSheet;
          // this.loadOffer(showingGetStarted);
          this.offerClaim = undefined;
          this.offerView = undefined;
          this.loadOffer();
        }
      });

      this.fidelService.activeUserPaymentMethods$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (userPaymentMethods) => {
          this.updateUserNeedsToLinkCard();
        },
        error: (error) => {

        }
      });

      this.navControlService.user$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (user) => {
          if (user?.id) {
            this.fidelService.fetchUserPaymentMethods(user.id);
          }
        }
      });

      this.claimedOffer$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (claimedOffer) => {
          this.updateUserNeedsToLinkCard();
        }
      });

      this.stateUserPaymentMethods$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (state) => {
          this.updateUserNeedsToLinkCard();
        }
      });
    }
  }

  private destroy$ = new Subject<void>();
  destroy(): void {
    this.navControlService.removeSignInResultHandler(this.handleSignInResult.bind(this));
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateUserNeedsToLinkCard() {
    if (this.stateUserPaymentMethods$.value === FetchState.LOADED_ALL && this.claimedOffer$.value && this.activeUserPaymentMethods$.value.length === 0 && this.navControlService.userState === LoginState.LOGGED_IN) {
      this.userNeedsToLinkCard$.next(true);
    }
    else {
      this.userNeedsToLinkCard$.next(false);
    }
  }

  get userPaymentsLastDigitsDisplay(): string {
    let userPaymentMethods = this.activeUserPaymentMethods$.value;
    if (userPaymentMethods.length === 0) {
      return '';
    }
    else {
      // Return a comma separated list of the last 4 digits of the user's payment methods
      return userPaymentMethods.map((userPaymentMethod) => userPaymentMethod.lastNumbers).join(', ');
    }
  }

  get isCharityOffer(): boolean {
    return !!this.offer?.charityIncentive && (!this.offer.charityIncentive.disabledDate || this.offer.charityIncentive.disabledDate.getTime() <= 0);
  }

  get loginTitle(): Observable<string> {
    if (this.offer?.charityIncentive) {
      return this.translate.get("SIGN_IN_TO_CLAIM");
    }
    else {
      return this.translate.get("SIGN_IN_TO_CLAIM_CHARITY");
    }
  }

  get loginDetails(): Observable<string> {
    if (this.offer?.charityIncentive) {
      return this.translate.get("SIGN_IN_TO_CLAIM_MESSAGE");
    }
    else {
      return this.translate.get("SIGN_IN_TO_CLAIM_MESSAGE_CHARITY");
    }
  }

  validDisplayDate(prefix?: string): string {
    prefix = !!prefix ? (prefix.charAt(prefix.length-1) !== ' ' ? prefix + ' ' : prefix) : '';

    if (!this.offer) {
      return '';
    }

    const today = new Date();

    if (!!this.offer?.redeemStartDate && !!this.offer?.redeemEndDate) {
      if (this.offer.redeemEndDate < this.offer.redeemStartDate) {
        this.offer.redeemEndDate = this.offer.redeemStartDate;
      }
    }
    if (!!this.offer.redeemEndDate && this.offer.redeemEndDate.getTime() < today.getTime()) {
      return this.translate.instant("EXPIRED") ?? 'Expired';
    }

    // If start date is before the beginning of this month, then use "<current month> 1"
    // Else use "<current month> <redeem start date>"
    let startDate = `${this.translate.instant("MONTHS")[today.getMonth()]} 1`;
    let startMonth = today.getMonth();
    if (!!this.offer.redeemStartDate) {
      if (this.offer.redeemStartDate.getTime() < new Date(today.getFullYear(), today.getMonth(), 1).getTime()) {
        startDate = `${this.translate.instant("MONTHS")[today.getMonth()]} ${new Date(this.offer.redeemStartDate).getDate()}`;
      }
      else if (this.offer.redeemStartDate.getTime() > new Date(today.getFullYear(), today.getMonth() + 1, 0).getTime()) {
        startDate = `${this.translate.instant("MONTHS")[new Date(this.offer.redeemStartDate).getMonth()]} ${new Date(this.offer.redeemStartDate).getDate()}`;
        startMonth = new Date(this.offer.redeemStartDate).getMonth();
      }
    }

    let endDate = `${this.translate.instant("MONTHS")[today.getMonth() + 1]} ${new Date(today.getFullYear(), today.getMonth() + 2, 0).getDate()}`;
    if (!!this.offer.redeemEndDate) {
      // If end date is this month or next, then use "<current month> <redeem end date>"
      if (this.offer.redeemEndDate.getTime() < new Date(today.getFullYear(), today.getMonth() + 2, 0).getTime()) {
        if (startMonth === new Date(this.offer.redeemEndDate).getMonth()) {
          endDate = `-${new Date(this.offer.redeemEndDate).getDate()}`;
        }
        else {
          endDate = ` - ${this.translate.instant("MONTHS")[new Date(this.offer.redeemEndDate).getMonth()]} ${new Date(this.offer.redeemEndDate).getDate()}`;
        }
      }
      else if (this.offer.redeemEndDate.getTime() > new Date(today.getFullYear(), today.getMonth() + 2, 0).getTime()) {
        if (startMonth === new Date(this.offer.redeemEndDate).getMonth()) {
          endDate = `-${new Date(this.offer.redeemEndDate).getDate()}`;
        }
        else {
          endDate = ` - ${this.translate.instant("MONTHS")[new Date(this.offer.redeemEndDate).getMonth()]} ${new Date(this.offer.redeemEndDate).getDate()}`;
        }
      }
    }
    else {
      if (startMonth === (today.getMonth() + 2)) {
        endDate = `-${new Date(today.getFullYear(), today.getMonth() + 2, 0).getDate()}`;
      }
      else {
        endDate = ` - ${this.translate.instant("MONTHS")[today.getMonth() + 1]} ${new Date(today.getFullYear(), today.getMonth() + 2, 0).getDate()}`;
      }
    }

    return `${prefix}${startDate}${endDate}`;
  }
  // validDisplayDate(prefix?: string): string {
  //   prefix = !!prefix ? (prefix.charAt(prefix.length-1) !== ' ' ? prefix + ' ' : prefix) : '';
  //   // If the offer never expires, then return "Valid <current month> 1-<end of month>"
  //   if (!this.offer?.redeemStartDate) {
  //     // If no end date, then return "Valid <current month> 1-<end of month>"
  //     if (!this.offer?.redeemEndDate) {
  //       return `${prefix}${this.translate.instant("MONTHS")[new Date().getMonth()]} 1-${new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()}`;
  //     }
  //     // If the offer has already expired, then return "Expired"
  //     if (this.offer?.redeemEndDate.getTime() < new Date().getTime()) {
  //       return this.translate.instant("EXPIRED");
  //     }
  //     // If offer never expires or the expiration is beyond the end of the current month, then return "Valid <current month> 1-<end of month>"
  //     if (!this.offer?.redeemEndDate || this.offer.redeemEndDate.getTime() > new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime()) {
  //       return `${prefix}${this.translate.instant("MONTHS")[new Date().getMonth()]} 1-${new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()}`;
  //     }
  //     // Else the offer expires this month, then return "Valid <current month> 1-<redeem end day of month>"
  //     return `${prefix}${this.translate.instant("MONTHS")[new Date().getMonth()]} 1-${new Date(this.offer.redeemEndDate).getDate()}`;
  //   }
  //   else if (!this.offer?.redeemEndDate) {
  //     // If the offer starts this month, then return "Valid <current month> <start day of month>-<end of month>"
  //     if (this.offer.redeemStartDate.getTime() > new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime()) {
  //       return `${prefix}${this.translate.instant("MONTHS")[new Date().getMonth()]} ${new Date(this.offer.redeemStartDate).getDate()}-${new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()}`;
  //     }
  //     // Else return "Valid <current month> <start day of month>-<end of month>"
  //     return `${prefix}${this.translate.instant("MONTHS")[new Date().getMonth()]} ${new Date(this.offer.redeemStartDate).getDate()}-${new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()}`;
  //   }
  //   else {
  //     // The offer has a start and an end date. If start date is before the end of the current month...
  //     if (this.offer.redeemStartDate.getTime() < new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime()) {
  //       // If the offer ends after the end of the current month, then return "Valid <current month> <start day of month>-<end of month>"
  //       if (this.offer.redeemEndDate.getTime() > new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime()) {
  //         return `${prefix}${this.translate.instant("MONTHS")[new Date().getMonth()]} ${new Date(this.offer.redeemStartDate).getDate()}-${new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()}`;
  //       }
  //       // Else the offer ends this month, then return "Valid <current month> <start day of month>-<redeem end day of month>"
  //       return `${prefix}${this.translate.instant("MONTHS")[new Date().getMonth()]} ${new Date(this.offer.redeemStartDate).getDate()}-${new Date(this.offer.redeemEndDate).getDate()}`;
  //     }
  //     // Else the offer starts next month, so return "Valid <next month> 1-<redeem end day of month>"
  //     return `${prefix}${this.translate.instant("MONTHS")[new Date().getMonth() + 1]} 1-${new Date(this.offer.redeemEndDate).getDate()}`;
  //   }
  // }

  get offerMainImageUrl(): string | ArrayBuffer | boolean {
    return this.getOfferMainImageUrl();
  }

  getOfferMainImageUrl(forceUrl: boolean = false): string | ArrayBuffer | boolean {
    if (!!this.offer?.offerImage?.imageUrl) {
      if (forceUrl) {
        return this.offer.offerImage.url;
      }
      return this.offer?.offerImage?.imageUrl;
    }

    if (!!this.offer?.charityIncentive?.charity) {
      let charityImage = this.offer?.charityIncentive?.charity?.bannerImage?.imageUrl;
      if (!!charityImage) {
        if (forceUrl) {
          this.offer?.charityIncentive?.charity?.bannerImage?.url;
        }
        return charityImage;
      }
      charityImage = this.offer?.charityIncentive?.charity?.logoImage?.imageUrl;
      if (!!charityImage) {
        if (forceUrl) {
          this.offer?.charityIncentive?.charity?.logoImage?.url;
        }
        return charityImage;
      }
    }

    if (!!this.offer?.venue?.venueImages) {
      let venueImage = this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'BANNER')?.imageUrl;
      if (!!venueImage) {
        if (forceUrl) {
          return this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'BANNER')?.url;
        }
        return venueImage;
      }
      venueImage = this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl;
      if (!!venueImage) {
        if (forceUrl) {
          return this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.url;
        }
        return venueImage;
      }
    }
    return false;
  }

  get logoImageUrl(): string | ArrayBuffer | boolean {
    if (!!this.offer?.charityIncentive?.charity?.logoImage?.imageUrl) {
      return this.offer?.charityIncentive?.charity?.logoImage?.imageUrl;
    }
    return this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl ?? false;
  }

  get subLogoImageUrl(): string | ArrayBuffer | boolean {
    if (!!this.offer?.charityIncentive?.charity?.logoImage?.imageUrl) {
      return this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl ?? false;
    }
    return this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl ?? false;
    // return null;
  }

  get venueLogoImageUrl(): string | ArrayBuffer | boolean {
    return this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl ?? false;
  }

  get venueBannerImageUrl(): string | ArrayBuffer | boolean {
    let result = this.offer?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'BANNER')?.imageUrl ?? false;
    return result;
  }

  public stateOffer$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  public async loadOffer(claimOffer: boolean = false, forceRefresh: boolean = false) {
    if (!this.marketingChannelId || this.marketingChannelId.length === 0) {
      this.offer = undefined;
      this.offerClaim = undefined;
      this.offerView = undefined;
      this.stateOffer$.next(FetchState.NONE);
      return;
    }

    if (this.offer?.id === this.marketingChannelId && !forceRefresh) {
      await this.handleOfferLoaded(this.offer, claimOffer);
      this.stateOffer$.next(FetchState.LOADED_ALL);
      return;
    }

    this.stateOffer$.next(FetchState.LOADING);

    this.apollo.query({
      query: PatronOfferQuery,
      variables: {
        id: this.marketingChannelId
      },
      fetchPolicy: 'no-cache'
    }).subscribe({
      next: async ({ data, loading }) => {
        if (data) {
          const parsedOffer = PatronOffer.parseResponse(data['offer']);
          await this.handleOfferLoaded(parsedOffer, claimOffer);
        }
        else {
          this.offer = undefined;
        }

        this.stateOffer$.next(FetchState.LOADED_ALL);
      },
      error: (error) => {
        this.stateOffer$.next(FetchState.ERROR);
      }
    });
  }

  private async handleOfferLoaded(offer: PatronOffer, claimOffer: boolean) {
    if (!offer) {
      this.offer = undefined;
      this.offerClaim = undefined;
      this.offerView = undefined;
      this.stateOffer$.next(FetchState.NONE);
      return;
    }

    this.offer = offer;

    // Fetch the venue's other offers
    this.fetchOffers({venueId: this.offer.venue.id});

    this.createOfferView(this.marketingChannelId, this.offer.id);
    this.offerClaim = this.offer.claim;

    if (!this.offerClaim && claimOffer) {
      this.claimOffer();
    }
    else {
      this.eventBusService.offerClaimed(this.offerClaim);
    }
  }


  stateOfferView: FetchState = FetchState.GOOD;
  private createOfferView(offerIdFromRoute: string, offerId: string) {
    // If the user is logged in, then register an Offer View
    if (this.navControlService.userState === LoginState.LOGGED_IN) {
      // If we are already in the process of creating an Offer View, then return.
      if (this.stateOfferView === FetchState.LOADING) {
        return;
      }
      // Set the state to loading.
      this.stateOfferView = FetchState.LOADING;
      this.apollo.mutate(
        {
          mutation: UpsertOfferViewMutation,
          variables: {
            offerId: offerId,
            promotionId: offerId !== offerIdFromRoute ? offerIdFromRoute : null,
            dateViewed: new Date().toISOString(),
            id: null
          }
        }).subscribe({
          next: ({ data, loading }) => {
            if (data) {
              if (data['upsertOfferView']) {
                this.offerView = data['upsertOfferView'];
                if (!!this._pendingClaimOffer) {
                  this._claimOffer();
                }
                else {
                  this.stateClaim$.value === FetchState.LOADED_ALL;
                }
              }
              else {
                this.offerView = null;
              }
            }
            else {
              this.offerView = null;
            }

            this.stateOfferView = FetchState.LOADED_ALL;
          },
          error: (error) => {
            if (!environment.production) {
              console.log("Error creating Offer View", error);
            }
            this.stateOfferView = FetchState.ERROR;

            if (!!this._pendingClaimOffer) {
              this._pendingClaimOffer = false;
              this.stateClaim$.next(FetchState.ERROR);
            }
          }
        });
    }
  }



  public async handleSignInResult(signInResult: any) {
    if (!!this._pendingClaimOffer) {
      if (!signInResult || !signInResult.user) {
        this._pendingClaimOffer = false;
        this.stateClaim$.next(FetchState.ERROR);
        return;
      }
      await this._claimOffer();
    }
  }

  public claimOffer() {
    this._claimOffer();
  }
  private _pendingClaimOffer: boolean = false;
  stateClaim$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  private _claimOffer() {
    if (!this.offer) {
      return;
    }
    if (this.navControlService.userState === LoginState.LOGGED_IN) {
      if (!this.offerView) {
        this._pendingClaimOffer = true;
        this.createOfferView(this.marketingChannelId, this.offer.id);
        return;
      }

      this._pendingClaimOffer = false;
      if (this.stateClaim$.value === FetchState.LOADING) {
        return;
      }
      this.stateClaim$.next(FetchState.LOADING);
      this.apollo.mutate({
        mutation: UpsertClaimMutation,
        variables: {
          offerViewId: this.offerView?.id,
          claimedDate: null,
        }
      }).subscribe({
        next: ({ data, loading }) => {
          // this.controller.selectSheet(OfferDetailsSheet.OfferClaimed);

          if (data) {
            if (data['upsertClaim']) {
              this.offerClaim = data['upsertClaim'];
              this.eventBusService.offerClaimed(this.offerClaim);
            }
            else {
              this.offerClaim = null;
            }
          }
          else {
            this.offerClaim = null;
          }

          this.stateClaim$.next(FetchState.LOADED_ALL);
        },
        error: (error) => {
          if (!environment.production) {
            console.log("Error claiming Offer", error);
          }
          this.stateClaim$.next(FetchState.ERROR);
        }
      });
    }
    else {
      this._pendingClaimOffer = true;
    }
  }

  async unclaimOffer() {
    if (!this.offerClaim) {
      // We don't have an Offer Claim, so return
      return;
    }

    this.stateClaim$.next(FetchState.LOADING);
    this.apollo.mutate({
      mutation: RemoveClaimMutation,
      variables: {
        id: this.offerClaim.id
      }
    }).subscribe({
      next: ({ data, loading }) => {
        if (data) {
          if (data['removeClaim']) {
            this.offerClaim = null;
            this.eventBusService.offerUnclaimed(this.offer?.id);
          }
        }

        this.stateClaim$.next(FetchState.LOADED_ALL);
      },
      error: (error) => {
        if (!environment.production) {
          console.log("Error unclaiming Offer", error);
        }
        this.stateClaim$.next(FetchState.ERROR);
      }
    });
  }

  stateUpdateRewardOffer$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  async updateRewardOffer(rewardId: string, offerId: string): Promise<Reward | null> {
    this.stateUpdateRewardOffer$.next(FetchState.LOADING);
    return this._updateRewardOffer(rewardId, offerId);
  }
  private async _updateRewardOffer(rewardId: string, offerId: string): Promise<Reward | null> {

    return new Promise<Reward | null>((resolve, reject) => {
      this.apollo.mutate({
        mutation: UpdateRewardOfferMutation,
        variables: {
          id: rewardId,
          offerId: offerId,
          claimedDate: null,
        }
      }).subscribe({
        next: ({ data, loading }) => {
          let result: Reward | null = null;

          if (data) {
            if (data['updateRewardOffer']) {
              result = Reward.parseResponse(data['updateRewardOffer']);
            }
          }

          this.stateUpdateRewardOffer$.next(FetchState.LOADED_ALL);
          resolve(result);
        },
        error: (error) => {
          if (!environment.production) {
            console.log("Error updating reward offer", error);
          }
          this.stateUpdateRewardOffer$.next(FetchState.ERROR);
          reject(error);
        }
      });
    });
  }


  done() {
    this.setStepState(StepState.DONE);
  }


  /**
   * Fetch Offers for User
   */
  offers$: BehaviorSubject<PatronOffer[]> = new BehaviorSubject<PatronOffer[]>([]);

  // List of offers other than the currently selected offer.
  otherOffers: Array<{offer: PatronOffer}> = [];

  page: number = 0;
  pageSize: number = 10;
  private clear() {
    this._killCurrentFetchOffers();
    this.state$.next(FetchState.NONE);
    this.offers$.next([]);
    this.page = 0;
  }

  state$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  private _currentFetchOffersKillSwitch$: Subject<void> = new Subject<void>();
  private _killCurrentFetchOffers() {
    this._currentFetchOffersKillSwitch$.next();
    this._currentFetchOffersKillSwitch$.complete();
    this._currentFetchOffersKillSwitch$ = new Subject<void>();
  }
  // fetchOffers(forceRefresh: boolean = false) {
  fetchOffers(options: FetchOffersOptions = {}) {
    if (!options?.forceRefresh && this.state$.getValue() === FetchState.LOADING) {
      return;
    }
    return this._fetchOffers(options);
  }

  private _fetchOffersRetryCount: number = 0;
  private _fetchOffers(options: FetchOffersOptions = {}) {
    if (options?.forceRefresh) {
      this.clear();
    }

    // Kill any current fetch
    this._killCurrentFetchOffers();

    const sort: PageAndSortInput = {
      page: this.page,
      pageSize: this.pageSize,
      sortFields: []
    };

    let filters = options?.filters ?? [];

    const searchInputs: Array<SearchInput> = []
    // if (filters.indexOf(OfferFilter.CLAIMABLE) >= 0) {
    //   searchInputs.push({value: new Date().toISOString(), searchFields: ["claimableFromDate"], operator: OperatorType.EQUALS});
    //   searchInputs.push({value: new Date().toISOString(), searchFields: ["claimableToDate"], operator: OperatorType.EQUALS});
    // }
    // else
    if (filters.indexOf(OfferFilter.CLAIMED) >= 0) {
      searchInputs.push({value: "true", searchFields: ["claimedOnly"], operator: OperatorType.EQUALS});
    }
    if (filters.indexOf(OfferFilter.NEW) >= 0) {
      searchInputs.push({value: "7", searchFields: ["claimStartedWithinDays"], operator: OperatorType.EQUALS});
    }
    if (filters.indexOf(OfferFilter.CHARITY) >= 0) {
      searchInputs.push({value: "true", searchFields: ["charityOnly"], operator: OperatorType.EQUALS});
    }

    // Check for venue id
    if (options?.venueId) {
      searchInputs.push({value: options.venueId, searchFields: ["venue"], operator: OperatorType.EQUALS});
    }

    // Check available date
    if (options?.availableDate) {
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["claimableFromDate"], operator: OperatorType.EQUALS});
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["claimableToDate"], operator: OperatorType.EQUALS});
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["redeemableFromDate"], operator: OperatorType.EQUALS});
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["redeemableToDate"], operator: OperatorType.EQUALS});
    }

    const variables = {
      id: this.userAuthService.user?.id ?? '',
      search: searchInputs.map((searchInput) => {
        return {
          value: searchInput.value,
          searchFields: searchInput.searchFields,
          operator: searchInput.operator.toUpperCase()
          };
        }),
      sort: sort,
      latitude: null,
      longitude: null,
      radiusKm: null
    };

    // if (filters.indexOf(OfferFilter.NEAR_ME) >= 0) {
    //   // If the location has not been retrieved, then wait for it
    //   if (!this.locationService.locationRetrieved) {
    //     this.waitingForLocation = true;

    //     // Set a timeout to stop waiting for the location
    //     this.waitForLocationTimeout = setTimeout(() => {
    //       if (this.waitingForLocation) {
    //         this.waitingForLocation = false;
    //         if (filters.indexOf(OfferFilter.NEAR_ME) >= 0) {
    //           // Remove the near me filter
    //           this.selectFilter(OfferFilter.NEAR_ME);
    //         }
    //       }
    //     }, 5000);
    //     this.state$.next(FetchState.PENDING);
    //     return;
    //   }
    //   const location = this.locationService.currentLocation;
    //   if (!!location && !!location.latitude && !!location.longitude) {
    //     variables['latitude'] = location.latitude;
    //     variables['longitude'] = location.longitude;
    //     variables['radiusKm'] = this.currentRadiusKm;
    //   }
    // }

    this.state$.next(FetchState.LOADING);

    this.apollo.query({
      query: PatronOffersQuery,
      variables
    })
    .pipe(
      takeUntil(this._currentFetchOffersKillSwitch$)
    )
    .subscribe({
      next: ({ data, loading }) => {
        this._fetchOffersRetryCount = 0;
        const offers: PatronOffer[] = [];
        for (const offerData of data['offers']) {
          if (offerData) {
            // this.offerImageValidMap[offerData.id] = !!offerData.offerImage.url
            // Push the new offer data into the BehaviorSubject
            offers.push(PatronOffer.parseResponse(offerData));
          }
        }

        if (offers.length > 0) {
          this.offers$.next([...this.offers$.value, ...offers]);
        }

        if (data['offers'].length < this.pageSize) {
          this.state$.next(FetchState.LOADED_ALL);
        } else {
          this.page++;
          this.state$.next(FetchState.GOOD);
        }
        // this.state = FetchState.LOADED_ALL;
      },
      error: (error) => {
        this._fetchOffersRetryCount += 1;

        // Implement retry logic with some sort of exponential backoff
        if (this._fetchOffersRetryCount < 5) {
          const timeoutTime = 150 * Math.pow(2, this._fetchOffersRetryCount);
          if (!environment.production) {
            console.log(`error fetching offers: ${error}. Retrying in ${timeoutTime}ms`);
          }
          setTimeout(() => {
            this._fetchOffersRetryCount++;
            this._fetchOffers(options);
          }, timeoutTime);
        }
        else {
          console.log(`error fetching offers: ${error}`);
          this.state$.next(FetchState.ERROR);
        }
      }
    });
  }

}
