<mgl-map
  #map
  class="w-full h-full min-h-[125px]"
  [style]="'mapbox://styles/getzeals/cloj54xof001a01r6d2imfoca'"
  [zoom]="[10]"
  (load)="onMapLoad($event)"
  >
  <mgl-marker
    *ngFor="let place of places"
    [lngLat]="[place.longitude, place.latitude]"
    >
      <div
        *ngIf="!placeIsDuplicate(place)"
        (click)="goToPlace(place)"
        class="flex flex-row gap-2 justify-start items-center drop-shadow-lg cursor-pointer"
        [ngClass]="{
          'hidden': placeIsDuplicate(place)
        }">
        <div
        [ngClass]="{
          'zeal-highlighted': isHighlighted(place) && !isCharity(place),
          'zeal-highlighted-charity': isHighlighted(place) && isCharity(place),
          'zeal-marker': !isHighlighted(place) && !isCharity(place),
          'zeal-marker-charity': !isHighlighted(place) && isCharity(place),
        }"
        >
          <span *ngIf="isHighlighted(place)">
            <span *ngIf="placePercentage(place) > 0">
              {{placePercentage(place) / 100.0 | percent}}
            </span>
            <span *ngIf="placeAmount(place) > 0">
              {{placeAmount(place) | currency}}
            </span>
          </span>
        </div>
        <div *ngIf="isHighlighted(place)" class="px-2 py-1 rounded-lg shadow justify-start items-center gap-1 inline-flex"
        [ngClass]="{'bg-patron-pink': !isCharity(place), 'bg-charity-green': isCharity(place)}">
          <div class="flex-col justify-start items-start inline-flex">
              <div class="justify-center items-center gap-2.5 inline-flex">
                  <div class="text-white text-xs font-semibold">{{place.name}}</div>
              </div>
              <div class="justify-center items-center gap-2.5 inline-flex">
                  <div class="text-white text-xs font-medium">
                    <span *ngIf="placePercentage(place) > 0">
                      {{placePercentage(place) / 100.0 | percent}}
                    </span>
                    <span *ngIf="placeAmount(place) > 0">
                      {{placeAmount(place) | currency}}
                    </span>
                    {{ isCharity(place) ? 'Donation' : 'Cash Back' }}
                  </div>
              </div>
          </div>
        </div>
      </div>
      <mgl-popup [lngLat]="[place.longitude, place.latitude]"
      [closeButton]="false" [offset]="-15" [closeOnClick]="false" [focusAfterOpen]="true" (clickOutside)="closePopup(place)">
        <app-offer-popover [ngClass]="{'hidden': !isOpen(place)}" [place]="place"></app-offer-popover>
      </mgl-popup>
    </mgl-marker>
</mgl-map>
