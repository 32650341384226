import { gql } from "apollo-angular";

export const GetZealsAppConfigQuery = gql`

query GetZealsAppConfig {
  getGetZealsAppConfig {
    baseUrl
    prodEnv
  }
}
  `;
