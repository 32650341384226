import { gql } from "apollo-angular";
import { DwollaServiceCustomerDetails } from "../fragments/ach_account.fragment.graphql";

export const FetchLinkTokenQuery = gql`
query FetchLinkToken($ownerId: String!, $redirectUri: String) {
  fetchLinkToken(ownerId: $ownerId, redirectUri: $redirectUri)
}
`;

export const GetDwollaServiceCustomerQuery = gql`
${DwollaServiceCustomerDetails}
query DwollaServiceCustomer($id: String!) {
  dwollaServiceCustomer(id: $id) {
    ...DwollaServiceCustomerDetails
  }
}
`;
