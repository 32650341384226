import { gql } from "apollo-angular";

export const WaitlistDetails = gql`
    fragment WaitlistDetails on Waitlist {
        id
        name
        companyName
        street
        street2
        city
        state
        zip
        country
        phone
        email
        website
    }
`