import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { UserOffersService } from 'src/app/services/user-offers.service';
import { AllDaysOfTheWeek, WeekDays, parseDaysOfTheWeekInt } from 'src/app/utils/enums/weeks_days.enum';

@Component({
  selector: 'app-offer-detail-info',
  templateUrl: './offer-detail-info.component.html',
  styleUrls: ['./offer-detail-info.component.css']
})
export class OfferDetailInfoComponent {
  @Input()
  title: string = 'Zeal Details';

  @Input()
  showValidDates: boolean = false;

  @Input()
  showMinSpend: boolean = false;

  @Input()
  showValidDaysAndHours: boolean = false;

  private _offer: any | undefined;
  @Input() set offer(value: any | undefined) {
    this._offer = value;
    this._daysOfTheWeekParsed = false;
  }
  get offer(): any | undefined {
    return this._offer;
  }

  WeekDays = WeekDays;
  AllDaysOfTheWeek = AllDaysOfTheWeek;

  constructor(
    public readonly translate: TranslateService,
    protected readonly userOfferService: UserOffersService
  ) {}

  get isCharityOffer(): boolean {
    return !!this.offer?.charityIncentive && (!this.offer.charityIncentive.disabledDate || this.offer.charityIncentive.disabledDate.getTime() <= 0);
  }

  get incentive(): any {
    if (this.isCharityOffer) {
      return this.offer?.charityIncentive;
    }
    else {
      return this.offer?.patronIncentive;
    }
  }

  get validDaysAndHours(): string {
    // Example: "Monday - Thursday (10am - 5pm)"
    let days = '';
    let hours = '';
    if (this.hasDays) {
      days = this.daysOfTheWeek.map(day => this.dayDisplay(day, false)).join(' - ');
    }
    else {
      days = "Sunday - Saturday";
    }
    if (this.hasStartTime && this.hasEndTime) {
      hours = `${this.offer.startTime} - ${this.offer.endTime}`;
    }
    else if (this.hasStartTime) {
      hours = `${this.offer.startTime} - Midnight`;
    }
    else if (this.hasEndTime) {
      hours = `12:00am - ${this.offer.endTime}`;
    }
    else {
      hours = 'All Day';
    }
    // else {
    //   hours = 'All Day';
    // }
    if (days.length === 0 && hours.length === 0) {
      return 'Everyday';
    }
    else if (days.length === 0) {
      return hours;
    }
    else if (hours.length === 0) {
      return days;
    }
    return `${days} (${hours})`;
  }

  get redeemStartDate(): Date | undefined {
    // If the offer redeemStartDate is a string, parse it into a Date object
    if (typeof this.offer?.redeemStartDate === 'string') {
      return new Date(this.offer.redeemStartDate);
    }
    else if (this.offer?.redeemStartDate instanceof Date) {
      return this.offer.redeemStartDate;
    }
    else {
      return undefined;
    }
  }

  get redeemEndDate(): Date | undefined {
    // If the offer redeemEndDate is a string, parse it into a Date object
    if (typeof this.offer?.redeemEndDate === 'string') {
      return new Date(this.offer.redeemEndDate);
    }
    else if (this.offer?.redeemEndDate instanceof Date) {
      return this.offer.redeemEndDate;
    }
    else {
      return undefined;
    }
  }

  get validFromDisplay(): Observable<string> {
    if (!!this.redeemStartDate && this.redeemStartDate?.getTime() > 0) {
      // If the redeemStartDate is before today, display 'Today'
      if (this.redeemStartDate.getTime() < new Date().getTime()) {
        return this.translate.get('DATES.TODAY');
      }
      return of(this.redeemStartDate.toLocaleDateString());
    }
    else {
      return this.translate.get('DATES.TODAY');
    }
  }

  get validUntilDisplay(): Observable<string> {
    if (!!this.redeemEndDate && this.redeemEndDate?.getTime() > 0) {
      return of(this.redeemEndDate.toLocaleDateString());
    }
    else {
      return this.translate.get('DATES.NEVER');
    }
  }

  get hasStartTime(): boolean {
    return !!this.offer?.startTime;
  }

  get hasEndTime(): boolean {
    return !!this.offer?.endTime;
  }

  private _daysOfTheWeekParsed: boolean = false;
  private _daysOfTheWeek: WeekDays[] = [];
  get daysOfTheWeek(): WeekDays[] {
    if (!this._daysOfTheWeekParsed && !!this.offer) {
      this._daysOfTheWeek = parseDaysOfTheWeekInt(this.offer.daysOfTheWeek);
      this._daysOfTheWeekParsed = true;
    }
    return this._daysOfTheWeek;
  }

  get hasDays(): boolean {
    return this.daysOfTheWeek.length > 0 && this.daysOfTheWeek.length < 7;
  }

  isDayOfWeek(day: WeekDays): boolean {
    return this.daysOfTheWeek.length === 0 || this.daysOfTheWeek.includes(day);
  }

  dayDisplay(day: WeekDays, abbreviate: boolean = true): string {
    switch (day) {
      case WeekDays.Sunday:
        if (abbreviate) {
           return this.translate.instant('SUNDAY_ABBREVIATION');
        }
        else {
          return this.translate.instant('SUNDAY');
        }
      case WeekDays.Monday:
        if (abbreviate) {
          return this.translate.instant('MONDAY_ABBREVIATION');
        }
        else {
          return this.translate.instant('MONDAY');
        }
      case WeekDays.Tuesday:
        if (abbreviate) {
          return this.translate.instant('TUESDAY_ABBREVIATION');
        }
        else {
          return this.translate.instant('TUESDAY');
        }
      case WeekDays.Wednesday:
        if (abbreviate) {
          return this.translate.instant('WEDNESDAY_ABBREVIATION');
        }
        else {
          return this.translate.instant('WEDNESDAY');
        }
      case WeekDays.Thursday:
        if (abbreviate) {
          return this.translate.instant('THURSDAY_ABBREVIATION');
        }
        else {
          return this.translate.instant('THURSDAY');
        }
      case WeekDays.Friday:
        if (abbreviate) {
          return this.translate.instant('FRIDAY_ABBREVIATION');
        }
        else {
          return this.translate.instant('FRIDAY');
        }
      case WeekDays.Saturday:
        if (abbreviate) {
          return this.translate.instant('SATURDAY_ABBREVIATION');
        }
        else {
          return this.translate.instant('SATURDAY');
        }
      default:
        return '';
    }
  }

  get minSpend(): number {
    return this.incentive?.intMinSpend / 100.0 ?? 0;
  }

  get maxReward(): number {
    // return 250.;
    return this.incentive?.intMaxReward / 100.0 ?? 0;
  }

}
