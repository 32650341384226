<app-main-container class=""
[backgroundImagePath]="'/assets/images/charity-home.png'"
[overylayImagePath]="'/assets/images/charity-iphone.png'"
[header]="'Raise Money for your Organization while Supporting Local Businesses'"
[subHeader]="'You Keep 100% of the Profit!'"
[buttonLabel]="'Get Started Now'"
[buttonClass]="'button-charity-green-borderless'"
(buttonClicked)="handleMainButtonClick()"
></app-main-container>
<div class="flex w-full h-full flex-col items-center bg-gray-200 pt-12">
  <div #waitlist class="max-w-8xl max-padding flex flex-col justify-center items-center pt-12">
      <app-waitlist [businessType]="BusinessType.Charity"></app-waitlist>
  </div>
</div>

