import { gql } from "apollo-angular";
import { VenueOfferDetails } from '../fragments/venue_offers.fragment.graphql';

//Create a new offer
export const UpsertOfferMutation = gql`
    mutation UpsertOffer(
          $id: String,
          $venueId: String,
          $charityId: String,
          $name: String,
          $internalName: String,
          $description: String,
          $finePrint: String,
          $claimStartDate: String,
          $claimEndDate: String,
          $archivedDate: String,
          $redeemStartDate: String,
          $redeemEndDate: String,
          $startTimeOfDay: Int,
          $endTimeOfDay: Int,
          $daysOfTheWeek: Int,
          $weeksOfTheMonth: Int,
          $isVenueDefault: Boolean,
          $isUniversal: Boolean,
          $singlePatronLimit: Int,
          $intPercentage: Int,
          $intAmount: Int,
          $intMinSpend: Int,
          $intMaxReward: Int,
          $patronsLimit: Int,
          $disabledDate: String,
          $promotions: [UpsertOfferPromotionInput!]
      ) {
        upsertOffer(
        details: {id: $id, venueId: $venueId, charityId: $charityId, name: $name, internalName: $internalName, description: $description, finePrint: $finePrint, claimStartDate: $claimStartDate, claimEndDate: $claimEndDate, archivedDate: $archivedDate, redeemStartDate: $redeemStartDate, redeemEndDate: $redeemEndDate, startTimeOfDay: $startTimeOfDay, endTimeOfDay: $endTimeOfDay, daysOfTheWeek: $daysOfTheWeek, weeksOfTheMonth: $weeksOfTheMonth, isVenueDefault: $isVenueDefault, isUniversal: $isUniversal, singlePatronLimit: $singlePatronLimit, intPercentage: $intPercentage, intAmount: $intAmount, intMinSpend: $intMinSpend, intMaxReward: $intMaxReward, patronsLimit: $patronsLimit, disabledDate: $disabledDate, promotions: $promotions}
        ) {
        ...VenueOfferDetails
        }
    }
    ${VenueOfferDetails}

`;
//Remove an existing offer
export const RemoveOfferMutation = gql`
mutation RemoveOffer($id: String!) {
    removeOffer(id: $id)
  }
  `;



