import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, filter, fromEvent, merge, startWith, switchMap, tap } from 'rxjs';
import { User } from '../../../model/user.model';
import { UserService } from '../../../services/user.service';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit, OnDestroy {
  @Input() placeholder: string = 'Search for a user...';
  @Input() exludeUsers: User[] = [];
  @Output() userSelected = new EventEmitter<User>();

  faTimesCircle = faTimesCircle;
  FaUser = faUser;

  constructor(
    private el: ElementRef,
    private readonly userService: UserService,
    ) {}

  userSearchResults: User[] = [];
  searchTerms = new Subject<string>();
  private _lastSearchTerm: string = '';
  private _lastUserSearchResults: User[] = [];
  ngOnInit(): void {
    this.searchTerms.pipe(
      startWith(''),  // Emit an initial empty string

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // filter the local userSearchResults array based on term
      tap((term: string) => {
        console.log('original userSearchResults', this.userSearchResults.length);
        this.userSearchResults = this._lastUserSearchResults.filter(u => u.fullName.toLowerCase().indexOf(term) >= 0 || u.emails.some(e => e.value.toLowerCase().indexOf(term) >= 0));
        console.log('filtered userSearchResults', this.userSearchResults.length);
      }),

      filter(term => term.length >= 2),  // Only proceed if term has at least 2 characters or is empty

      // wait for 300ms pause in events
      debounceTime(this._lastSearchTerm === "" ? 0 : 300),

      // switch to new search observable each time
      switchMap((term: string) => {
        // if (term.length >= 2) {
          this._lastSearchTerm = term;
          return this.userService.searchUsers(`%${term}%`);
        // }
        // return of([]);
      })

    ).subscribe(results => {
      // Filter out any users that already exist in the affiliate.affiliateOperators array
      if (this.exludeUsers?.length > 0) {
        results = results.filter(u => (u.fullName.length > 0 || u.email?.length > 0) && !this.exludeUsers.some(o => o.id === u.id));
      }
      this.userSearchResults = results;
      this._lastUserSearchResults = results;
    });

    // Create an observable for clicks outside the search input
    const clicksOutside$ = fromEvent(document, 'click').pipe(
      filter((event: MouseEvent) => !this.el.nativeElement.contains(event.target))
    );

    // Create an observable for the escape key
    const escapePresses$ = fromEvent(document, 'keydown').pipe(
      filter((event: KeyboardEvent) => event.key === 'Escape')
    );

    // Merge the click and keydown observables
    const closeEvents$ = merge(clicksOutside$, escapePresses$);

    closeEvents$.subscribe(() => {
      // Clear the search field or list, and hide it
      this.searchTerms.next('');
      this.userSearchResults = [];
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.searchTerms.complete();
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  clearSearch(): void {
    this._lastSearchTerm = '';
    this.userSearchResults = [];
    this._lastUserSearchResults = [];
    this.searchTerms.next('');
  }

  selectUser(user) {
    this.userSelected.emit(user);
    this.clearSearch();
  }


}
