import { gql } from "apollo-angular";
import { RewardDetails } from "./reward.fragment.graphql";
import { PatronOfferDetails } from "./patron_offer.fragment.graphql";
import { PatronClaimDetails } from "./patron_claim.fragment.graphql";
import { PaymentMethodTransactionDetails } from "./payment_method_transaction.fragment.graphql";
import { CharityDetails } from "./charity.fragment.graphql";

export const PatronRewardDetails = gql`
${PatronClaimDetails}
${PaymentMethodTransactionDetails}
fragment PatronRewardDetails on Reward {
  id
  matchedDate
  clearedDate
  processedDate
  claim {
    ...PatronClaimDetails
  }
  transaction {
    ...PaymentMethodTransactionDetails
  }
}
`;

export const PatronCreditDetails = gql`
${PatronRewardDetails}
${PatronOfferDetails}
fragment PatronCreditDetails on PatronCredit {
  id
  currency
  intAmount
  txDate
  clearedDate
  status
  reward {
    ...PatronRewardDetails
  }
  incentive {
    id
    intReturnAmount
    intReturnPercentage
    returnCurrency
    intMinSpend
    intMaxReward
    offer {
      ...PatronOfferDetails
    }
  }
}
`;

export const PromoterCreditDetails = gql`
${RewardDetails}
${PatronOfferDetails}
fragment PromoterCreditDetails on PromoterCredit {
  id
  currency
  intAmount
  txDate
  clearedDate
  status
  reward {
    ...RewardDetails
  }
  incentive {
    id
    intReturnAmount
    intReturnPercentage
    returnCurrency
    intMinSpend
    intMaxReward
    offer {
      ...PatronOfferDetails
    }
  }
}
`;

export const PlatformCreditDetails = gql`
${RewardDetails}
${PatronOfferDetails}
fragment PlatformCreditDetails on PlatformCredit {
  id
  currency
  intAmount
  txDate
  clearedDate
  status
  reward {
    ...RewardDetails
  }
  incentive {
    id
    intReturnAmount
    intReturnPercentage
    returnCurrency
    intMinSpend
    intMaxReward
    offer {
      ...PatronOfferDetails
    }
  }
}
`;

export const CharityCreditDetails = gql`
${RewardDetails}
${PatronOfferDetails}
${CharityDetails}
fragment CharityCreditDetails on CharityCredit {
  id
  currency
  intAmount
  txDate
  clearedDate
  status
  reward {
    ...RewardDetails
  }
  incentive {
    id
    intReturnAmount
    intReturnPercentage
    returnCurrency
    intMinSpend
    intMaxReward
    charity {
      ...CharityDetails
    }
    offer {
      ...PatronOfferDetails
    }
  }
}
`;

export const CreditResultDetails = gql`
${PatronCreditDetails}
${PromoterCreditDetails}
${PlatformCreditDetails}
${CharityCreditDetails}
fragment CreditResultDetails on CreditResult {
  ... on PatronCredit {
    ...PatronCreditDetails
  }
  ... on PromoterCredit {
    ...PromoterCreditDetails
  }
  ... on PlatformCredit {
    ...PlatformCreditDetails
  }
  ... on CharityCredit {
    ...CharityCreditDetails
  }
}
`;

export const VenueDebitDetails = gql`
${PatronRewardDetails}
fragment VenueDebitDetails on VenueDebit {
  id
  currency
  intAmount
  txDate
  clearedDate
  status
  reward {
    ...PatronRewardDetails
  }
  credit {
    __typename
  }
}
`;

export const DebitResultDetails = gql`
${VenueDebitDetails}
fragment DebitResultDetails on DebitResult {
  ... on VenueDebit {
    ...VenueDebitDetails
    # id
    # currency
    # intAmount
    # txDate
    # clearedDate
    # status
    # reward {
    #   ...PatronRewardDetails
    # }
  }
}
`;

export const PayoutDetails = gql`
fragment PayoutDetails on Payout {
  id
  currency
  intAmount
  txDate
  clearedDate
  status
  institutionName
}
`;

export const PaymentDetails = gql`
fragment PaymentDetails on Payment {
  id
  currency
  intAmount
  txDate
  clearedDate
  status
}
`;

export const AchCreditDetails = gql`
fragment AchCreditDetails on AchCredit {
  id
  currency
  intAmount
  txDate
  clearedDate
  status
}
`;

export const AchDebitDetails = gql`
fragment AchDebitDetails on AchDebit {
  id
  currency
  intAmount
  txDate
  clearedDate
  status
}
`;

export const CreditDebitResultDetails = gql`
${PatronCreditDetails}
${PromoterCreditDetails}
${PlatformCreditDetails}
${CharityCreditDetails}
${VenueDebitDetails}
${PayoutDetails}
${PaymentDetails}
${AchCreditDetails}
${AchDebitDetails}
fragment CreditDebitResultDetails on CreditDebitResult {
  ... on PatronCredit {
    ...PatronCreditDetails
  }
  ... on PromoterCredit {
    ...PromoterCreditDetails
  }
  ... on PlatformCredit {
    ...PlatformCreditDetails
  }
  ... on CharityCredit {
    ...CharityCreditDetails
  }
  ... on VenueDebit {
    ...VenueDebitDetails
  }
  ... on Payout {
    ...PayoutDetails
  }
  ... on Payment {
    ...PaymentDetails
  }
  ... on AchCredit {
    ...AchCreditDetails
  }
  ... on AchDebit {
    ...AchDebitDetails
  }
}
`;

// ${CreditDebitResultDetails}
export const LedgerCreditsAndDebitsResultDetails = gql`
${PatronCreditDetails}
${PromoterCreditDetails}
${PlatformCreditDetails}
${CharityCreditDetails}
${VenueDebitDetails}
${PayoutDetails}
${PaymentDetails}
${AchCreditDetails}
${AchDebitDetails}
fragment LedgerCreditsAndDebitsResultDetails on LedgerCreditsAndDebitsResult {
  data {
    # ...CreditDebitResultDetails
    ... on PatronCredit {
      ...PatronCreditDetails
    }
    ... on PromoterCredit {
      ...PromoterCreditDetails
    }
    ... on PlatformCredit {
      ...PlatformCreditDetails
    }
    ... on CharityCredit {
      ...CharityCreditDetails
    }
    ... on VenueDebit {
      ...VenueDebitDetails
    }
    ... on Payout {
      ...PayoutDetails
    }
    ... on Payment {
      ...PaymentDetails
    }
    ... on AchCredit {
      ...AchCreditDetails
    }
    ... on AchDebit {
      ...AchDebitDetails
    }
  }
  total
  currentPage
  pageSize
}
`;



export const LedgerDetails = gql`
fragment LedgerDetails on Ledger {
  id
  currency
  intBalance
  intPendingBalance
  intHoldingBalance
  intClearedBalance
  intClearedCreditBalance
  intClearedDebitBalance
  intPreProcessBalance
  intTotalRevenue
  intClearedTotalRevenue

  intRewardsTotalRevenue
  intRewardsClearedTotalRevenue
  intRewardsProcessedTotalRevenue
  intRewardsCharityTotalRevenue
  intRewardsCharityClearedTotalRevenue
  intRewardsCharityProcessedTotalRevenue
  intRewardsBalance
  intRewardsClearedBalance
  intRewardsProcessedBalance
  intRewardsCharityBalance
  intRewardsCharityClearedBalance
  intRewardsCharityProcessedBalance

}
`;
  // # credits {
  // #   ...CreditResultDetails
  // # }
  // # debits {
  // #   ...DebitResultDetails
  // # }
// # ${CreditResultDetails}
// # ${DebitResultDetails}
