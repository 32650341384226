import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { AllDaysOfTheWeek, WeekDays } from 'src/app/utils/enums/weeks_days.enum';

export function formatDate(date?: Date): string {
  if (!date) return "";
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function convertToDate(dateString: string): Date {
  if (!dateString || dateString.length === 0) return null;
  return new Date(dateString);
}


@Component({
  selector: 'app-available',
  templateUrl: './available.component.html',
  styleUrls: ['./available.component.css']
})
export class AvailableComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private readonly translate: TranslateService
  ) {}
  @Input() availableGroup: FormGroup;

  AllDaysOfTheWeek: Array<WeekDays> = AllDaysOfTheWeek;

  get isAlwaysClaimable(): boolean {
    return this.availableGroup.get('alwaysClaimable')?.value;
  }
  get isAlwaysRedeemable(): boolean {
    return this.availableGroup.get('alwaysRedeemable')?.value;
  }
  get isAllDays(): boolean {
    return this.availableGroup.get('isAllDays')?.value;
  }


  ngOnInit(): void {
    console.log("AvailableComponent.ngOnInit()");
  }

  get claimStartDate() {
    return this.availableGroup?.get('claimStartDate') ?? null;
  }
  get claimEndDate() {
    return this.availableGroup?.get('claimEndDate') ?? null;
  }

  clearClaimStartDate() {
    this.availableGroup.get('claimStartDate').setValue(null);
  }
  clearClaimEndDate() {
    this.availableGroup.get('claimEndDate').setValue(null);
  }

  get redeemStartDate() {
    return this.availableGroup?.get('redeemStartDate') ?? null;
  }
  get redeemEndDate() {
    return this.availableGroup?.get('redeemEndDate') ?? null;
  }

  clearRedeemStartDate() {
    this.availableGroup.get('redeemStartDate').setValue(null);
  }
  clearRedeemEndDate() {
    this.availableGroup.get('redeemEndDate').setValue(null);
  }

  displayDayOfWeek(weekDay: WeekDays): string {
    if (weekDay === WeekDays.Sunday) return "Su";
    else if (weekDay === WeekDays.Monday) return  "M";
    else if (weekDay === WeekDays.Tuesday) return  "Tu";
    else if (weekDay === WeekDays.Wednesday) return  "W";
    else if (weekDay === WeekDays.Thursday) return  "Th";
    else if (weekDay === WeekDays.Friday) return  "F";
    else if (weekDay === WeekDays.Saturday) return  "Sa";
    else return "";
  }

  get daysOfTheWeek() {
    return this.availableGroup?.get('daysOfTheWeek') ?? null;
  }

  //Functions to operate the Days of the Week selector
  checkIfDayIsSelectedClass(day: WeekDays) {
    const selectedDaysOfTheWeek: Array<WeekDays> = this.daysOfTheWeek?.value ?? [];
    let result: string = "justify-center items-center flex square-content h-full rounded p-2 cursor-pointer border ";
    result = result + (selectedDaysOfTheWeek.includes(day) ? " border-white bg-business-blue text-white" : " bg-white text-business-blue border-business-blue");
    return result;
  }

  toggleSelectedDays(days: WeekDays[]) {
    const selectedDaysOfTheWeek: Array<WeekDays> = this.daysOfTheWeek?.value ?? [];

    for(const weekDay of days) {
      const index: number = selectedDaysOfTheWeek.indexOf(weekDay);
      if (index >= 0) {
        selectedDaysOfTheWeek.splice(index, 1);
      }
      else {
        selectedDaysOfTheWeek.push(weekDay);
      }
    }

    this.daysOfTheWeek.setValue(selectedDaysOfTheWeek);
  }

  isDayOfWeek(day: WeekDays): boolean {
    const selectedDaysOfTheWeek: Array<WeekDays> = this.daysOfTheWeek?.value ?? [];
    return selectedDaysOfTheWeek.includes(day);
    // return this.daysOfTheWeek?.length === 0 || this.daysOfTheWeek?.includes(day);
  }

  dayDisplay(day: WeekDays, abbreviate: boolean = true): Observable<string> {
    switch (day) {
      case WeekDays.Sunday:
        if (abbreviate) {
           return this.translate.get('SUNDAY_ABBREVIATION');
        }
        else {
          return this.translate.get('SUNDAY');
        }
      case WeekDays.Monday:
        if (abbreviate) {
          return this.translate.get('MONDAY_ABBREVIATION');
        }
        else {
          return this.translate.get('MONDAY');
        }
      case WeekDays.Tuesday:
        if (abbreviate) {
          return this.translate.get('TUESDAY_ABBREVIATION');
        }
        else {
          return this.translate.get('TUESDAY');
        }
      case WeekDays.Wednesday:
        if (abbreviate) {
          return this.translate.get('WEDNESDAY_ABBREVIATION');
        }
        else {
          return this.translate.get('WEDNESDAY');
        }
      case WeekDays.Thursday:
        if (abbreviate) {
          return this.translate.get('THURSDAY_ABBREVIATION');
        }
        else {
          return this.translate.get('THURSDAY');
        }
      case WeekDays.Friday:
        if (abbreviate) {
          return this.translate.get('FRIDAY_ABBREVIATION');
        }
        else {
          return this.translate.get('FRIDAY');
        }
      case WeekDays.Saturday:
        if (abbreviate) {
          return this.translate.get('SATURDAY_ABBREVIATION');
        }
        else {
          return this.translate.get('SATURDAY');
        }
      default:
        return of('');
    }
  }
}
