import { Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BaseImage, IImage } from '../model/image.model';
import { PatronOffer } from '../model/patron-offer.model';
import { VenueOffer } from '../model/venue_offer.model';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { dateToUtcDateString } from '../utils/enums/date.utils';
import { NavControlService, NavRole } from '../components/nav/nav-control.service';
import { faStar } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-venue-offer-detail',
  templateUrl: './venue-offer-detail.component.html',
  styleUrls: ['./venue-offer-detail.component.css'],
})
export class VenueOfferDetailComponent {
  private _parentForm: FormGroup
  @Input()
  set parentForm(value: FormGroup) {
    this._parentForm = value;
  }
  get parentForm(): FormGroup {
  return this._parentForm;
  }
  @Input() venue: any;
  @ViewChild('offerFileInput') offerFileInput: ElementRef;

  @ViewChildren('detailsDiv, incentivesDiv, availabilityDiv') divs: QueryList<ElementRef>;
  @ViewChild('underline') underline: ElementRef;

  onScroll(event: any) {
    if (!this._scrolling) {
      let highestDiv = null;
      let highestTop = Number.MAX_VALUE;

      this.divs.forEach(div => {
        const divTop = div.nativeElement.getBoundingClientRect().top;
        if (divTop >= 0 && divTop < highestTop) {
          highestTop = divTop;
          highestDiv = div.nativeElement;
        }
      });

      if (!!highestDiv) {
        this.setCurrentDivId(highestDiv.id);
      }
    }
  }

  currentDivId: string = "detailsDiv";
  private _scrolling: boolean = false;

  scrollToDiv(id: string) {
    let scrollToDiv: ElementRef | null = this.divs.find((div) => div.nativeElement.id === id) ?? null;

    if (!!scrollToDiv) {
      this._scrolling = true;

      scrollToDiv.nativeElement.scrollIntoView({ behavior: 'smooth' });
      this.setCurrentDivId(scrollToDiv.nativeElement.id);
      setTimeout(() => {
        this._scrolling = false;
      }, 1250);
    }
  }

  private setCurrentDivId(id: string) {
    this.currentDivId = id;
    const indexMap = {
      'detailsDiv': 0,
      'incentivesDiv': 1,
      'availabilityDiv': 2
    };
    const index = indexMap[id];
    const underlineWidth = this.underline.nativeElement.offsetWidth;
    const newLeft = index * underlineWidth;

    this.underline.nativeElement.style.left = `${newLeft}px`;
  }


  FaPrimary = faStar;
  FaImage = faImage;
  NavRole = NavRole;

  currentIndex: number = 1;

  constructor(
    protected readonly navControlService: NavControlService,
  ) {}

  get id(): string {
    return this.parentForm.value.id ?? '';
  }

  get patronOffer(): PatronOffer {
    if (!this.parentForm.value || !this.venue) return new PatronOffer();
    let offerClone: VenueOffer = new VenueOffer();
    VenueOffer.parseForm(this.parentForm, offerClone);
    return VenueOffer.toPatronOffer(offerClone, this.venue, this.claimable, this.redeemable, this.offerImagePreviewUrl);
  }

  get name(): string {
    return this.parentForm.value.name ?? '';
  }

  get offerImage(): IImage {
    return this.parentForm.value.offerImage ?? new BaseImage();
  }

  get claimStartDateDisplay(): string {
    const date = this.availableGroupValue['claimStartDate'];
    if (!date) return "No start date";
    // Return the UTC date only string
    return dateToUtcDateString(date);
  }

  getVenueStreetAddresses(addresses: any[]): string {
    let street;
    (addresses ?? []).forEach((address) => {
      street = address.street;
    })
    return street;
  }

  selectItem(index: number) {
    if (index <= 0) index = 1;
    if (index > 10) index = 1;
    this.currentIndex = index;
  }

  get isVenueDefault(): boolean {
    let value = this.parentForm.value.isVenueDefault ?? false;
    return value ?? false;
  }

  get detailsGroup(): FormGroup {
    const result = this.parentForm.get('detailsGroup') as FormGroup;
    return result;
  }

  get incentiveGroup(): FormGroup {
    const result = this.parentForm.get('incentiveGroup') as FormGroup;
    return result;
  }

  get incentiveGroupValue(): {} {
    return this.parentForm.value.incentiveGroup ?? {};
  }

  get availableGroup(): FormGroup {
    const result = this.parentForm.get('availableGroup') as FormGroup;
    return result;
  }

  get availableGroupValue(): {} {
    return this.parentForm.value.availableGroup ?? {};
  }

  get disabled(): boolean {
    const disabledDate = this.availableGroupValue['disabledDate'] ?? "";
    if (!disabledDate || disabledDate.length === 0) return false;
    return true;
  }

  get enabled(): boolean {
    let disabledDate = this.availableGroupValue['disabledDate'] ?? "";
    return !disabledDate || disabledDate.length === 0;
  }

  get archived(): boolean {
    const archivedDate = this.availableGroupValue['archivedDate'] ?? "";
    if (!archivedDate || archivedDate.length === 0) return false;
    return true;
  }

  get claimable() {
    if (this.disabled || this.archived) return false;
    const alwaysClaimable = this.availableGroupValue['alaysClaimable'];
    if (alwaysClaimable) return true;
    const claimStartDate = this.availableGroupValue['claimStartDate'];
    const claimEndDate = this.availableGroupValue['claimEndDate'];
    if (!claimStartDate && !claimEndDate) return true;
    const now = moment();
    if (claimStartDate) {
      const date = moment.utc(claimStartDate);
      if (date.isAfter(now)) return false;
    }
    if (claimEndDate) {
      const date = moment.utc(claimEndDate);
      if (date.isBefore(now)) return false;
    }
    return true;
  }

  get redeemable() {
    if (this.disabled || this.archived) return false;
    const alwaysRedeemable = this.availableGroupValue['alwaysRedeemable'];
    if (alwaysRedeemable) return true;
    const redeemStartDate = this.availableGroupValue['redeemStartDate'];
    const redeemEndDate = this.availableGroupValue['redeemEndDate'];
    if (!redeemStartDate && !redeemEndDate) return true;
    const now = moment();
    if (redeemStartDate) {
      const date = moment.utc(redeemStartDate);
      if (date.isAfter(now)) return false;
    }
    if (redeemEndDate) {
      const date = moment.utc(redeemEndDate);
      if (date.isBefore(now)) return false;
    }
    return true;
  }

  toggleEnabled() {
    let disabledDate = this.availableGroupValue['disabledDate'] ?? "";
    if (!disabledDate || disabledDate.length === 0) {
      disabledDate = dateToUtcDateString(new Date());
    }
    else {
      disabledDate = "";
    }
    this.availableGroup.get('disabledDate')?.setValue(disabledDate);
  }

  toggleArchived() {
    let archivedDate = this.availableGroupValue['archivedDate'] ?? "";
    if (!archivedDate || archivedDate.length === 0) {
      archivedDate = dateToUtcDateString(new Date());
    }
    else {
      archivedDate = "";
    }
    this.availableGroup.get('archivedDate')?.setValue(archivedDate);
  }

  // Handle file input
  get offerImagePreviewUrl(): string | ArrayBuffer {
    const selectedFilePreview = this.parentForm.get('offerImageFile' + 'Preview').value;
    if (!!selectedFilePreview) return selectedFilePreview;
    if (this.offerImage && this.offerImage.url) return this.offerImage.url;
    return null;
  }
  onFileSelected(event, fileId: string) {
    if (event.target.files.length > 0) {
      const selectedFile = <File>event.target.files[0];
      this.parentForm.get(fileId).setValue(selectedFile);

      // Create Image Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.parentForm.get(fileId + 'Preview').setValue(reader.result);
        this.parentForm.get(fileId).setValue(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
    }
    else {
      this.parentForm.controls[fileId]?.markAsTouched();
    }
  }

  openFileInput(fileId: string): void {
    if (fileId === 'BANNER') {
      this.offerFileInput.nativeElement.click();
    }
  }

  onCloseFile(fileId: string) {
    this.parentForm.controls[fileId]?.markAsTouched();
    // If offerImageFile is valud, then clear the errors on it
    console.log(`${fileId} is valid?`, this.parentForm.controls[fileId].getRawValue());
    if (this.parentForm.controls[fileId]?.valid) {
      this.parentForm.controls[fileId]?.setErrors(null);
    }
  }

  onFileSelectError($event, fileId: string) {
    console.log(`onFileSelectError`, $event);
  }

}
