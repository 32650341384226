import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatestWith, takeUntil } from 'rxjs';
import { NavControlService, NavRole } from '../nav-control.service';
import { OfferFilter } from 'src/app/offers.service';
import { LoginState } from 'src/app/user-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { VenueService } from 'src/app/venue.service';
import { FidelService } from 'src/app/fidel.service';
import { FetchState } from 'src/app/app.module';
import { LoginViewState } from 'src/app/auth/login/login.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {
  role: NavRole;
  venues: any[];
  venue: any;
  NavRole = NavRole;
  OfferFilter = OfferFilter;
  LoginState = LoginState;

  FaXmark = faXmark;
  FaChevronUp = faChevronUp;
  FaChevronDown = faChevronUp;

  constructor(
    private readonly navControlService: NavControlService,
    protected readonly venueService: VenueService,
    private readonly fidelService: FidelService,
    public translate: TranslateService
  ) {
  }

  userState: LoginState;

  ngOnInit(): void {
    this.navControlService.role$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (role) => {
        this.role = role;
        if (role === NavRole.PATRON) {
          this.subMenuOpen = 'offers';
        }
        else if (role === NavRole.VENUE) {
          this.venueService.fetchVenues();
        }
      }
    });

    this.navControlService.userState$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (userState) => {
        this.userState = userState;
      }
    });

    this.navControlService.user$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (user) => {
        if (user?.id) {
          this.fidelService.fetchUserPaymentMethods(user.id);
        }
        this.isMissingBank = !!user && !user.patron?.achAccount;
      }
    });

    this.fidelService.activeUserPaymentMethods$.pipe(
      takeUntil(this.destroy$),
      combineLatestWith(this.navControlService.user$),
      combineLatestWith(this.fidelService.stateUserPaymentMethods$)
    ).subscribe({
      next: (value) => {
        if (value[1] === FetchState.LOADED_ALL) {
          this.isMissingCreditCard = !!value[0][1] && value[0][0].length === 0;
        }
        else {
          this.isMissingCreditCard = false;
        }
      }
    });

    this.navControlService.selectedVenue$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (venue) => {
        this.venue = venue;
      }
    });

    this.venueService.venues$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (venues) => {
        this.venues = venues;
      }
    });

  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isMissingCreditCard: boolean = false;
  isMissingBank: boolean = false;

  get isOpen(): boolean {
    return this.navControlService.isMenuOpen;
  }

  openMenu() {
    this.navControlService.openMenu();
  }

  closeMenu() {
    this.navControlService.closeMenu();
  }

  subMenuOpen: string | null = null;
  isSubMenuOpen(menu: string) {
    return this.subMenuOpen === menu;
  }
  toggleSubMenu(menu: string) {
    this.subMenuOpen = this.subMenuOpen === menu ? null : menu;
  }

  setRole(role: NavRole) {
    this.navControlService.setRole(role);
  }

  hasRole(role: NavRole) {
    return this.navControlService.hasRole(role);
  }

  goToHome() {
    this.navControlService.goToHome();
  }

  goToChat() {
    this.navControlService.goToChat();
  }

  goToHowItWorks() {
    this.navControlService.goToHowItWorks();
  }
  toggleHowItWorks() {
    this.closeMenu();
    this.navControlService.toggleHowItWorks();
  }

  goToOffers(filters: Array<OfferFilter>) {
    this.navControlService.goToOffers(filters);
  }
  goToPatronCreditCards() {
    this.navControlService.goToPatronCreditCards();
  }

  goToPatronBank() {
    this.navControlService.goToPatronBank();
  }

  goToPatronEarnings() {
    this.navControlService.goToPatronEarnings();
  }

  goToVenueOffers() {
    this.navControlService.goToVenueOffers();
  }

  goToVenue(venueId?: string) {
    venueId = venueId || this.navControlService.selectedVenue?.id;
    if (!!venueId) {
      this.navControlService.goToVenue(venueId);
    }
  }

  goToVenueBank(venueId?: string) {
    venueId = venueId || this.navControlService.selectedVenue?.id;
    if (!!venueId) {
      this.navControlService.goToVenueBank(venueId);
    }
  }

  goToVenueMerchant(venueId?: string) {
    venueId = venueId || this.navControlService.selectedVenue?.id;
    if (!!venueId) {
      this.navControlService.goToVenueMerchant(venueId);
    }
  }

  goToCharityOffers() {
    this.navControlService.goToCharityOffers();
  }

  signIn() {
    this.navControlService.showLogin(LoginViewState.LOGIN);
  }

  joinNow() {
    this.navControlService.showLogin(LoginViewState.REGISTER);
  }

  logout() {
    this.navControlService.logout();
  }

  handleClickBusinesses() {
    if (this.hasRole(NavRole.VENUE) || this.hasRole(NavRole.ADMIN)) {
      if (this.role !== NavRole.VENUE) {
        this.setRole(NavRole.VENUE);
      }
      this.goToVenues()
    }
    else {
      this.navControlService.goToBusinessLanding();
    }
  }

  handleClickCharities() {
    if (this.hasRole(NavRole.CHARITY)) {
      if (this.role !== NavRole.CHARITY) {
        this.setRole(NavRole.CHARITY);
      }
      // this.goToCharities()
    }
    else {
      this.navControlService.goToCharityLanding();
    }
  }

  goToVenues() {
    this.navControlService.goToVenues();
  }

  goToAffiliates() {
    this.navControlService.goToAffiliates();
  }

  goToAbout() {
    this.navControlService.goToAbout();
  }

  goToFaq() {
    this.navControlService.goToFaq();
  }

  goToTerms() {
    this.navControlService.goToTerms();
  }

  goToPrivacy() {
    this.navControlService.goToPrivacy();
  }

  goToEditUser() {
    this.navControlService.goToEditUser();
  }

}
