import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PatronOffersQuery } from './graphql/queries/offer.query.graphql';
import { OperatorType, PageAndSortInput, SearchInput } from './graphql/types.graphql';
import { FetchState } from './app.module';
import { BehaviorSubject, Subject, catchError, takeUntil, throwError } from 'rxjs';
import { LocationService } from './location.service';
import { PatronOffer } from './model/patron-offer.model';
import { UserAuthService } from './user-auth.service';

export enum OfferFilter {
  ALL = 'All',
  NEAR_ME = 'Near Me',
  // CLAIMABLE = 'Claimable',
  CLAIMED = 'Claimed',
  RECENT = 'Recent',
  NEW = 'New',
  CHARITY = 'Charity',
}

export const AllOfferFilters: OfferFilter[] = [
  OfferFilter.ALL,
  OfferFilter.NEAR_ME,
  // OfferFilter.CLAIMABLE,
  OfferFilter.RECENT,
  OfferFilter.CLAIMED,
  OfferFilter.NEW,
  OfferFilter.CHARITY,
];

export type FetchOffersOptions = {
  forceRefresh?: boolean,
  filters?: Array<OfferFilter | string>,
  venueId?: string,
  availableDate?: Date,
}


@Injectable({
  providedIn: 'root'
})

export class OffersService {
  // Observable list of offers
  private _offers$: BehaviorSubject<PatronOffer[]> = new BehaviorSubject<PatronOffer[]>([]);
  get offers$(): BehaviorSubject<PatronOffer[]> {
    // If the current state of offers is NONE, then kick off a request
    // this.initializeLocation();
    if (this.state$.getValue() === FetchState.NONE) {
      this.fetchOffers();
    }
    return this._offers$;
  }
  // filters$: BehaviorSubject<OfferFilter[]> = new BehaviorSubject([OfferFilter.NEAR_ME, OfferFilter.CLAIMABLE]);
  filters$: BehaviorSubject<OfferFilter[]> = new BehaviorSubject([OfferFilter.NEAR_ME]);
  // isValidOfferImage: boolean = false;
  page: number = 0;
  pageSize: number = 30;
  private currentRadiusKm: number = 200.0;

  state$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);


  private waitingForLocation: boolean = false;
  private waitForLocationTimeout: any;

  constructor(
    // private readonly locationService: LocationService,
    private readonly userAuthService: UserAuthService,
    private readonly apollo: Apollo
  ) {

    this.userAuthService.currentUser$.subscribe({
      next: (user) => {
        if (this.state$.getValue() !== FetchState.NONE) {
          this.refreshOffers();
        }
      }
    });


    // this.fetchOffers();
  }

  // private _locationInitialized: boolean = false;
  // private initializeLocation() {
  //   if (this._locationInitialized) {
  //     return;
  //   }
  //   this._locationInitialized = true;

  //   this.locationService.location$.subscribe({
  //     next: async (location) => {
  //       if (this.waitForLocationTimeout) {
  //         clearTimeout(this.waitForLocationTimeout);
  //         this.waitForLocationTimeout = null;
  //       }
  //       if (this.waitingForLocation) {
  //         this.waitingForLocation = false;
  //         if (this.filters$.getValue().indexOf(OfferFilter.NEAR_ME) >= 0) {
  //           if (!location || !location.latitude || !location.longitude) {
  //             // Remove the near me filter
  //             this.selectFilter(OfferFilter.NEAR_ME);
  //           }
  //           else {
  //             if (this.state$.getValue() !== FetchState.NONE) {
  //               this.refreshOffers();
  //             }
  //           }
  //         }
  //       }
  //     }
  //   });

  //   this.locationService.location$.pipe(
  //     catchError((error) => {
  //       // If we have near me as a filter, then we de-select it
  //       if (this.filters$.getValue().indexOf(OfferFilter.NEAR_ME) >= 0) {
  //         this.selectFilter(OfferFilter.NEAR_ME);
  //       }
  //       return throwError(error);
  //     })
  //   );
  // }

  private clear() {
    this._killCurrentOffers();
    this.offers$.next([]);
    this.state$.next(FetchState.NONE);
    this.page = 0;
  }

  refreshOffers(filters: OfferFilter[] = null) {
    if (!!filters) {
      this.filters$.next(filters || this.filters$.getValue());
    }
    this.fetchOffers({forceRefresh: true}); // This forces a clear
  }

  loadMore() {
    // If we have loaded all, then nothing to do
    if (this.state$.getValue() === FetchState.LOADED_ALL) {
      return;
    }
    this.fetchOffers();
  }

  private _currentFetchOffersKillSwitch$: Subject<void> = new Subject<void>();
  private _killCurrentOffers() {
    this._currentFetchOffersKillSwitch$.next();
    this._currentFetchOffersKillSwitch$.complete();
    this._currentFetchOffersKillSwitch$ = new Subject<void>();
  }
  // fetchOffers(forceRefresh: boolean = false) {
  fetchOffers(options: FetchOffersOptions = {}) {
    if (!options?.forceRefresh && this.state$.getValue() === FetchState.LOADING) {
      return;
    }
    return this._fetchOffers(options);
  }

  private _fetchOffersRetryCount: number = 0;
  private _fetchOffers(options: FetchOffersOptions = {}) {
    if (options?.forceRefresh) {
      this.clear();
    }

    // Kill any current fetch
    this._killCurrentOffers();

    const sort: PageAndSortInput = {
      page: this.page,
      pageSize: this.pageSize,
      sortFields: []
    };

    let filters = options?.filters ?? this.filters$.getValue();

    const searchInputs: Array<SearchInput> = []
    // if (filters.indexOf(OfferFilter.CLAIMABLE) >= 0) {
    //   searchInputs.push({value: new Date().toISOString(), searchFields: ["claimableFromDate"], operator: OperatorType.EQUALS});
    //   searchInputs.push({value: new Date().toISOString(), searchFields: ["claimableToDate"], operator: OperatorType.EQUALS});
    // }
    // else
    if (filters.indexOf(OfferFilter.CLAIMED) >= 0) {
      searchInputs.push({value: "true", searchFields: ["claimedOnly"], operator: OperatorType.EQUALS});
    }
    if (filters.indexOf(OfferFilter.NEW) >= 0) {
      searchInputs.push({value: "7", searchFields: ["claimStartedWithinDays"], operator: OperatorType.EQUALS});
    }
    if (filters.indexOf(OfferFilter.CHARITY) >= 0) {
      searchInputs.push({value: "true", searchFields: ["charityOnly"], operator: OperatorType.EQUALS});
    }

    // Check for venue id
    if (options?.venueId) {
      searchInputs.push({value: options.venueId, searchFields: ["venue"], operator: OperatorType.EQUALS});
    }

    // Check available date
    if (options?.availableDate) {
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["claimableFromDate"], operator: OperatorType.EQUALS});
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["claimableToDate"], operator: OperatorType.EQUALS});
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["redeemableFromDate"], operator: OperatorType.EQUALS});
      searchInputs.push({value: options.availableDate.toISOString(), searchFields: ["redeemableToDate"], operator: OperatorType.EQUALS});
    }

    const variables = {
      id: this.userAuthService.user?.id ?? '',
      search: searchInputs.map((searchInput) => {
        return {
          value: searchInput.value,
          searchFields: searchInput.searchFields,
          operator: searchInput.operator.toUpperCase()
          };
        }),
      sort: sort,
      latitude: null,
      longitude: null,
      radiusKm: null
    };

    // print("Latitude: \(locationManager.location?.coordinate.latitude ?? 0.0)")
    // print("Longitude: \(locationManager.location?.coordinate.longitude ?? 0.0)")
    // if (filters.indexOf(OfferFilter.NEAR_ME) >= 0) {
    //   // If the location has not been retrieved, then wait for it
    //   if (!this.locationService.locationRetrieved) {
    //     this.waitingForLocation = true;

    //     // Set a timeout to stop waiting for the location
    //     this.waitForLocationTimeout = setTimeout(() => {
    //       if (this.waitingForLocation) {
    //         this.waitingForLocation = false;
    //         if (filters.indexOf(OfferFilter.NEAR_ME) >= 0) {
    //           // Remove the near me filter
    //           this.selectFilter(OfferFilter.NEAR_ME);
    //         }
    //       }
    //     }, 5000);
    //     this.state$.next(FetchState.PENDING);
    //     return;
    //   }
    //   const location = this.locationService.currentLocation;
    //   if (!!location && !!location.latitude && !!location.longitude) {
    //     variables['latitude'] = location.latitude;
    //     variables['longitude'] = location.longitude;
    //     variables['radiusKm'] = this.currentRadiusKm;
    //   }
    // }

    this.state$.next(FetchState.LOADING);

    const rando = Math.random();
    console.log(`fetchOffers: ${rando}`);
    console.log('');

    this.apollo.query({
      query: PatronOffersQuery,
      variables
    })
    .pipe(
      takeUntil(this._currentFetchOffersKillSwitch$)
    )
    .subscribe({
      next: ({ data, loading }) => {
        this._fetchOffersRetryCount = 0;
        console.dir(data);
        console.log(loading);
        const offers: PatronOffer[] = [];
        for (const offerData of data['offers']) {
          if (offerData) {
            // this.offerImageValidMap[offerData.id] = !!offerData.offerImage.url
            // Push the new offer data into the BehaviorSubject
            offers.push(PatronOffer.parseResponse(offerData));
          }
        }

        if (offers.length > 0) {
          this.offers$.next([...this.offers$.value, ...offers]);
        }

        if (data['offers'].length < this.pageSize) {
          this.state$.next(FetchState.LOADED_ALL);
        } else {
          this.page++;
          this.state$.next(FetchState.GOOD);
        }
        // this.state = FetchState.LOADED_ALL;
      },
      error: (error) => {
        this._fetchOffersRetryCount += 1;

        // Implement retry logic with some sort of exponential backoff
        if (this._fetchOffersRetryCount < 5) {
          const timeoutTime = 150 * Math.pow(2, this._fetchOffersRetryCount);
          console.log(`Retrying fetchOffers in ${timeoutTime}ms`);
          setTimeout(() => {
            this._fetchOffersRetryCount++;
            this._fetchOffers(options);
          }, timeoutTime);
        }
        else {
          console.log(`error fetching offers: ${error}`);
          this.state$.next(FetchState.ERROR);
        }
      }
    });
  }

  selectFilters(filters: OfferFilter[]) {
    // Only update if the filters have changed.
    if (!filters || filters.length === 0 || filters.length !== this.filters$.getValue().length) {
      this.filters$.next(filters);
      this.refreshOffers();
    }
    // Else if the contents of the filters have changed, then update
    else {
      for (const filter of filters) {
        if (this.filters$.getValue().indexOf(filter) < 0) {
          this.filters$.next(filters);
          this.refreshOffers();
          break;
        }
      }
    }
    return;
  }

  selectFilter(filter: OfferFilter) {
    let filters = this.filters$.getValue();
    // iterate backwards on array and splice the array when item is found
    switch (filter) {
      case OfferFilter.ALL:
        if (filters.indexOf(OfferFilter.ALL) > -1) {
          if (filters.indexOf(OfferFilter.CLAIMED) > -1) {
            filters = [OfferFilter.NEAR_ME, OfferFilter.CLAIMED];
          }
          // else {
          //   filters = [OfferFilter.NEAR_ME, OfferFilter.CLAIMABLE];
          // }
        }
        else {
          if (filters.indexOf(OfferFilter.CLAIMED) > -1) {
            filters = [OfferFilter.ALL, OfferFilter.CLAIMED];
          }
          // else {
          //   filters = [OfferFilter.ALL, OfferFilter.CLAIMABLE];
          // }
        }
        break;
      case OfferFilter.NEAR_ME:
        if (filters.indexOf(OfferFilter.NEAR_ME) > -1) {
          filters.reverse().forEach((filter, index) => {
            // if filter contains NEAR_ME, remove it
            if (filter == OfferFilter.NEAR_ME) {
              filters.splice(index, 1);
            }
          });
          // if (filters.length == 0 || filters.length === 1 && filters[0] === OfferFilter.CLAIMABLE) {
          //   filters = [OfferFilter.ALL, OfferFilter.CLAIMABLE];
          // }
        }
        else {
          filters.reverse().forEach((filter, index) => {
            // if filter contains ALL, remove it
            if (filter == OfferFilter.ALL) {
              filters.splice(index, 1);
            }
          })
          filters.push(OfferFilter.NEAR_ME);
        }
        break;
      // case OfferFilter.CLAIMABLE:
      //   if (filters.indexOf(OfferFilter.CLAIMABLE) > -1) {
      //     filters.forEach((filter, index) => {
      //       // if filter contains CLAIMABLE, remove it
      //       if (filter == OfferFilter.CLAIMABLE) {
      //         filters.splice(index, 1);
      //       }
      //     })
      //     filters.push(OfferFilter.CLAIMED);
      //   }
      //   else {
      //     filters.reverse().forEach((filter, index) => {
      //       // if filter contains CLAIMED, remove it
      //       if (filter == OfferFilter.CLAIMED) {
      //         filters.splice(index, 1);
      //       }
      //     })
      //     filters.push(OfferFilter.CLAIMABLE);
      //   }
      //   break;
      case OfferFilter.CLAIMED:
        if (filters.indexOf(OfferFilter.CLAIMED) > -1) {
          filters.reverse().forEach((filter, index) => {
            // if filter contains CLAIMED, remove it
            if (filter == OfferFilter.CLAIMED) {
              filters.splice(index, 1);
            }
          })
          // if (filters.indexOf(OfferFilter.CLAIMABLE) < 0) {
          //   filters.push(OfferFilter.CLAIMABLE);
          // }
        } else {
          filters.push(OfferFilter.CLAIMED);
          // filters.reverse().forEach((filter, index) => {
          //   // if filter contains CLAIMABLE, remove it
          //   if (filter == OfferFilter.CLAIMABLE) {
          //     filters.splice(index, 1);
          //   }
          // });
        }
        break;
      case OfferFilter.NEW:
        if (filters.indexOf(OfferFilter.NEW) > -1) {
          filters.reverse().forEach((filter, index) => {
            // if filter contains NEW, remove it
            if (filter == OfferFilter.NEW) {
              filters.splice(index, 1);
            }
          })
          // if (filters.length == 0) {
          //   filters = [OfferFilter.ALL, OfferFilter.CLAIMABLE];
          // }
        } else {
          filters.push(OfferFilter.NEW);
          filters.reverse().forEach((filter, index) => {
            // if filter contains ALL, remove it
            if (filter == OfferFilter.ALL) {
              filters.splice(index, 1);
            }
          })
        }
        break;
      case OfferFilter.CHARITY:
        if (filters.indexOf(OfferFilter.CHARITY) > -1) {
          filters.reverse().forEach((filter, index) => {
            // if filter contains CHARITY, remove it
            if (filter == OfferFilter.CHARITY) {
              filters.splice(index, 1);
            }
          })
          // if (filters.length == 0) {
          //   filters = [OfferFilter.ALL, OfferFilter.CLAIMABLE];
          // }
        } else {
          filters.push(OfferFilter.CHARITY);
          filters.reverse().forEach((filter, index) => {
            // if filter contains ALL, remove it
            if (filter == OfferFilter.ALL) {
              filters.splice(index, 1);
            }
          })
        }
        break;
    }

    this.filters$.next(filters);
    if (this.state$.getValue() !== FetchState.NONE) {
      this.refreshOffers();
    }
  }
}
