import { LedgerDetails } from "../graphql/fragments/ledger.fragment.graphql";
import { Entity, IEntity } from "./entity.model";
import { CharityIncentive, PatronClaim, PatronIncentive, PaymentMethodTransaction } from "./patron-offer.model";


export class Ledger extends Entity {

  currency: string;
  intBalance: number;
  intPreProcessBalance: number;
  intHoldingBalance: number;
  intPendingBalance: number;
  intClearedBalance: number;
  intClearedCreditBalance: number;
  intClearedDebitBalance: number;
  intCreditTotal: number;
  intDebitTotal: number;
  intTotalRevenue: number;
  intClearedTotalRevenue: number;

  intRewardsTotalRevenue: number;
  intRewardsClearedTotalRevenue: number;
  intRewardsProcessedTotalRevenue: number;
  intRewardsCharityTotalRevenue: number;
  intRewardsCharityClearedTotalRevenue: number;
  intRewardsCharityProcessedTotalRevenue: number;
  intRewardsBalance: number;
  intRewardsClearedBalance: number;
  intRewardsProcessedBalance: number;
  intRewardsCharityBalance: number;
  intRewardsCharityClearedBalance: number;
  intRewardsCharityProcessedBalance: number;

  get intAvailableToTransfer(): number {
    return Math.max(0, this.intBalance - this.intHoldingBalance);
  }

  // credits: Array<ICredit> = [];
  // debits: Array<IDebit> = [];

  // statements: Array<LedgerStatement> = [];


  static override parseResponse(details, result?: Ledger): Ledger {
    if (!details) return null;
    if (!result) result = new Ledger();
    Entity.parseResponse(details, result);

    result.currency = details.currency;
    result.intBalance = details.intBalance;
    result.intPreProcessBalance = details.intPreProcessBalance;
    result.intHoldingBalance = details.intHoldingBalance;
    result.intPendingBalance = details.intPendingBalance;
    result.intClearedBalance = details.intClearedBalance;
    result.intClearedCreditBalance = details.intClearedCreditBalance;
    result.intClearedDebitBalance = details.intClearedDebitBalance;
    result.intCreditTotal = details.intCreditTotal;
    result.intDebitTotal = details.intDebitTotal;
    result.intTotalRevenue = details.intTotalRevenue;
    result.intClearedTotalRevenue = details.intClearedTotalRevenue;

    result.intRewardsTotalRevenue = details.intRewardsTotalRevenue;
    result.intRewardsClearedTotalRevenue = details.intRewardsClearedTotalRevenue;
    result.intRewardsProcessedTotalRevenue = details.intRewardsProcessedTotalRevenue;
    result.intRewardsCharityTotalRevenue = details.intRewardsCharityTotalRevenue;
    result.intRewardsCharityClearedTotalRevenue = details.intRewardsCharityClearedTotalRevenue;
    result.intRewardsCharityProcessedTotalRevenue = details.intRewardsCharityProcessedTotalRevenue;
    result.intRewardsBalance = details.intRewardsBalance;
    result.intRewardsClearedBalance = details.intRewardsClearedBalance;
    result.intRewardsProcessedBalance = details.intRewardsProcessedBalance;
    result.intRewardsCharityBalance = details.intRewardsCharityBalance;
    result.intRewardsCharityClearedBalance = details.intRewardsCharityClearedBalance;
    result.intRewardsCharityProcessedBalance = details.intRewardsCharityProcessedBalance;

    return result;
  }

}


export class Reward extends Entity {

  constructor() {
    super();
  }
  matchedDate: Date;
  clearedDate: Date;
  processedDate: Date;

  claim: PatronClaim;
  transaction: PaymentMethodTransaction;

  // credits: Array<IRewardCredit> = [];
  // debits: Array<IRewardDebit> = [];

  static override parseResponse(details, result?: Reward): Reward {
    if (!details) return null;
    if (!result) result = new Reward();
    Entity.parseResponse(details, result);

    if (details.matchedDate) result.matchedDate = new Date(details.matchedDate);
    if (details.clearedDate) result.clearedDate = new Date(details.clearedDate);
    if (details.processedDate) result.processedDate = new Date(details.processedDate);

    result.claim = PatronClaim.parseResponse(details.claim);
    result.transaction = PaymentMethodTransaction.parseResponse(details.transaction);

    // result.credits = details.credits?.map(c => CharityCredit.parseResponse(c));
    // result.debits = details.debits?.map(d => CharityDebit.parseResponse(d));

    return result;
  }
}



export interface ICredit extends IEntity {

  ledger: Ledger;

  // correlationId: string;
  currency: string;

  /**
   * The amount of the credit in the currency of the ledger. Stored as an integer.
   */
  intAmount: number;
  txDate: Date;
  pendingDate: Date;
  clearedDate: Date;
  status: string;

  debit: IDebit;

}
export interface IDebit extends IEntity {

  ledger: Ledger;

  // correlationId: string;
  currency: string;

  /**
   * The amount of the debit in the currency of the ledger. Stored as an integer.
   */
  intAmount: number;
  txDate: Date;
  pendingDate: Date;
  clearedDate: Date;
  status: string;

  credit: ICredit;

}


export interface IRewardCredit extends ICredit {

  // @Field(type => Reward, {nullable: false})
  // @OneToMany("Reward", "credits")
  reward: Reward;

}

export interface IRewardDebit extends IDebit {

  reward: Reward;

}

export class CharityCredit extends Entity implements IRewardCredit {

  intAmount: number;
  status: string;
  currency: string;
  txDate: Date;
  pendingDate: Date;
  clearedDate: Date;
  ledger: Ledger;
  reward: Reward;
  incentive: CharityIncentive;
  debit: IDebit;

  static override parseResponse(details, result?: CharityCredit): CharityCredit {
    if (!details) return null;
    if (!result) result = new CharityCredit();
    Entity.parseResponse(details, result);

    result.intAmount = details.intAmount;
    result.status = details.status;
    result.currency = details.currency;
    if (details.txDate) result.txDate = new Date(details.txDate);
    if (details.pendingDate) result.pendingDate = new Date(details.pendingDate);
    if (details.clearedDate) result.clearedDate = new Date(details.clearedDate);
    result.ledger = Ledger.parseResponse(details.ledger);
    result.reward = Reward.parseResponse(details.reward);
    result.incentive = CharityIncentive.parseResponse(details.incentive);
    // result.debit = Debit.parseResponse(details.debit);

    return result;
  }
}

export class PatronCredit extends Entity implements IRewardCredit {

  intAmount: number;
  status: string;
  currency: string;
  txDate: Date;
  pendingDate: Date;
  clearedDate: Date;
  ledger: Ledger;
  reward: Reward;
  incentive: PatronIncentive;
  debit: IDebit;

  static override parseResponse(details, result?: PatronCredit): PatronCredit {
    if (!details) return null;
    if (!result) result = new PatronCredit();
    Entity.parseResponse(details, result);

    result.intAmount = details.intAmount;
    result.status = details.status;
    result.currency = details.currency;
    if (details.txDate) result.txDate = new Date(details.txDate);
    if (details.pendingDate) result.pendingDate = new Date(details.pendingDate);
    if (details.clearedDate) result.clearedDate = new Date(details.clearedDate);
    result.ledger = Ledger.parseResponse(details.ledger);
    result.reward = Reward.parseResponse(details.reward);
    result.incentive = PatronIncentive.parseResponse(details.incentive);
    // result.debit = Debit.parseResponse(details.debit);

    return result;
  }
}

export class LedgerSettleResults {
  ownerId?: string;
  intSettleAmount?: number;
  settleDate?: Date;
  success?: boolean;
  message?: string;

  static parseResponse(details, result?: LedgerSettleResults): LedgerSettleResults {
    if (!details) return null;
    if (!result) result = new LedgerSettleResults();

    result.ownerId = details.ownerId;
    result.intSettleAmount = details.intSettleAmount;
    if (details.settleDate) result.settleDate = new Date(details.settleDate);
    result.success = details.success;
    result.message = details.message;

    return result;
  }
}

export class Payout extends Entity implements IDebit {
  intAmount: number;
  institutionName: string;
  accountName: string;
  status: string;
  transactionId: string;
  currency: string;
  txDate: Date;
  pendingDate: Date;
  clearedDate: Date;
  ledger: Ledger;
  credit: ICredit;

  static override parseResponse(details, result?: Payout): Payout {
    if (!details) return null;
    if (!result) result = new Payout();
    Entity.parseResponse(details, result);

    result.intAmount = details.intAmount;
    result.institutionName = details.institutionName ?? "";
    result.accountName = details.accountName ?? "";
    result.status = details.status;
    result.transactionId = details.transactionId;
    result.currency = details.currency;
    if (details.txDate) result.txDate = new Date(details.txDate);
    if (details.pendingDate) result.pendingDate = new Date(details.pendingDate);
    if (details.clearedDate) result.clearedDate = new Date(details.clearedDate);
    result.ledger = Ledger.parseResponse(details.ledger);
    // result.credit = Credit.parseResponse(details.credit);

    return result;
  }
}
