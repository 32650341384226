import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NavControlService } from '../../components/nav/nav-control.service';
import { LoginState } from '../../user-auth.service';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FetchState } from 'src/app/app.module';
import { CognitoService } from '../cognito.service';

export enum LoginViewState {
  REGISTER,
  LOGIN,
  FORGOT_PASSWORD,
  CONFIRMATION_CODE,
  RESET_PASSWORD,
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() title: string = 'Welcome!';
  get showTitle(): boolean {
    return !!this.title && this.title !== '';
  }
  @Input() details: string = 'Please sign in to continue.';
  get showDetails(): boolean {
    return !!this.details && this.details !== '';
  }

  userState: LoginState;
  LoginState = LoginState;
  LoginViewState = LoginViewState;
  ErrorIcon = faTriangleExclamation;

  constructor(
    private readonly navControlService: NavControlService,
    private readonly cognitoService: CognitoService,
    private fb: FormBuilder,
  ) {
    this.setupLogin();
    this.setupRegister();
    this.setupConfirmCode();
    this.setupForgotPassword();
    this.setupResetPassword();
  }

  ngOnInit(): void {
    if (this.navControlService.userState === LoginState.LOGGED_IN) {
      // We are already logged in
      this.navControlService.followReturnUrl();
    }

    this.navControlService.isShowingLogin$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (isShowingLogin) => {
        if (!isShowingLogin) {
          setTimeout(() => {
            this.loginViewState = LoginViewState.LOGIN;
          }, 275);
        }
      }
    });

    this.userState$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (userState) => {
        // Log the state
        this.userState = userState;
      }
    });
  }

  private destroy$: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isLoading(): boolean {
    if ([LoginState.LOGGING_IN].indexOf(this.userState) >= 0) return true;
    if (this.registerState === FetchState.LOADING) return true;
    if (this.loginState === FetchState.LOADING) return true;
    if (this.confirmCodeState === FetchState.LOADING) return true;
    if (this.forgotPasswordState === FetchState.LOADING) return true;
    if (this.resetPasswordState === FetchState.LOADING) return true;
    return false;
  }

  get loginViewState(): LoginViewState {
    return this.navControlService.loginViewState;
  }
  set loginViewState(value: LoginViewState) {
    if (this.navControlService.loginViewState === value) return;
    this.navControlService.loginViewState = value;
    this.userState = LoginState.NONE;
  }

  userState$ = this.navControlService.userState$;

  goToHome() {
    this.navControlService.goToHome();
  }

  goToPrivacy() {
    this.navControlService.goToPrivacy();
  }

  goToTerms() {
    this.navControlService.goToTerms();
  }

  /****************************
   * Login
   ****************************/
  loginState: FetchState = FetchState.NONE;
  loginForm: FormGroup;
  setupLogin() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  async loginUser() {
    if (this.loginForm.valid) {
      this.loginState = FetchState.LOADING;

      const loginFormValues = this.loginForm.getRawValue();
      const email = loginFormValues.email;
      const password = loginFormValues.password;

      try {
        let result = await this.cognitoService.authenticateUser(email, password);
        this.loginState = FetchState.LOADED_ALL;
      }
      catch (error) {
        this.loginState = FetchState.ERROR;
        return;
      }

    }
    else {
      const emailControl = this.loginForm.get('email');
      if (!!emailControl) {
        emailControl.setErrors({required: true});
        emailControl.markAsTouched();
      }
      const passwordControl = this.loginForm.get('password');
      if (!!passwordControl) {
        passwordControl.setErrors({required: true});
        passwordControl.markAsTouched();
      }
      this.loginState = FetchState.ERROR;
    }
  }

  get loginEmailErrorDisplay(): string {
    const emailControl = this.loginForm.get('email');
    if (emailControl.hasError('required')) {
      return 'Email is required';
    }
    if (emailControl.hasError('email')) {
      return 'Email is invalid';
    }
    return 'Email is invalid';
  }



  /****************************
   * Register
   ***************************/
  registerForm: FormGroup;

  setupRegister() {
    this.registerForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.required, Validators.email]],
      password: ['', this.newPasswordValidator()],
      confirmPassword: ['', [Validators.required, this.confirmPasswordValidator()]],
      agreeToTerms: [false, Validators.requiredTrue],
    });
  }

  public newPasswordValidator(): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      const password = control?.value;

      // Must be at least 8 characters, with a number, uppercase letter, lowercase letter, and special character
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
      if (!passwordRegex.test(password)) {
        return { passwordInvalid: true };
      }
      return null;
    };
  }

  public confirmPasswordValidator(): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      const confirmPassword = control?.value;

      // Make sure confirmPassword is the same as password in the form
      const password = this.registerForm?.get('password')?.value;
      if (password !== confirmPassword) {
        return { passwordMismatch: true };
      }
      return null;
    };
  }

  get confirmPasswordErrorDisplay(): string {
    const confirmPasswordControl = this.registerForm.get('confirmPassword');
    if (confirmPasswordControl.hasError('required')) {
      return 'Confirm password is required.';
    }
    if (confirmPasswordControl.hasError('passwordMismatch')) {
      return 'Passwords do not match.';
    }
    return '';
  }

  get userAgreedToTerms(): boolean {
    return this.registerForm.get('agreeToTerms').value;
  }

  handleUserAgreementClick(value: boolean = true) {
    // If email is valid in form, then set the value
    if (this.registerForm.get('email').valid) {
      this.registerForm.get('agreeToTerms').setValue(value);
    }
  }

  registerState: FetchState = FetchState.NONE;
  async registerUser() {
    if (this.registerForm.valid) {
      this.registerState = FetchState.LOADING;

      const registerFormValues = this.registerForm.getRawValue();
      const firstName = registerFormValues.firstName;
      const lastName = registerFormValues.lastName;
      const email = registerFormValues.email;
      const password = registerFormValues.password;

      try {
        let result = await this.cognitoService.registerUser(email, password, firstName, lastName);
        if (!result || !result.user) {
          this.registerState = FetchState.ERROR;
          return;
        }
        // If user is confirmed, then we are done
        if (result.userConfirmed) {
          this.registerState = FetchState.LOADED_ALL;
          return;
        }
        // Otherwise, we need to confirm the user
        this.loginViewState = LoginViewState.CONFIRMATION_CODE;
        this.registerState = FetchState.LOADED_ALL;
        // Set the email in the confirmation code form
        this.confirmCodeForm.get('email').setValue(email);

      }
      catch (error) {
        if (error.code === 'UsernameExistsException') {
          this.registerState = FetchState.ERROR;
          this.registerForm.get('email').setErrors({emailExists: true});

          // Get the state of the user
          let user = await this.cognitoService.validateUser(email);

          return;
        }
        this.registerState = FetchState.ERROR;
        return;
      }

    }
    else {
      const emailControl = this.registerForm.get('email');
      if (!!emailControl) {
        emailControl.setErrors({required: true});
        emailControl.markAsTouched();
      }
      this.registerState = FetchState.ERROR;
    }
  }

  get registerEmailErrorDisplay(): string {
    const emailControl = this.registerForm.get('email');
    if (emailControl.hasError('required')) {
      return 'Email is required.';
    }
    if (emailControl.hasError('email')) {
      return 'Email is invalid.';
    }
    if (emailControl.hasError('emailExists')) {
      return 'Email already exists.';
    }
    return '';
  }

  get registerEmailAlreadyExists(): boolean {
    const emailControl = this.registerForm.get('email');
    return emailControl.hasError('emailExists');
  }

  resendConfirmationCodeState: FetchState = FetchState.NONE;
  async resendConfirmationCode() {
    this.resendConfirmationCodeState = FetchState.LOADING;

    const registerFormValues = this.registerForm.getRawValue();
    const email = registerFormValues.email;

    try {
      let result = await this.cognitoService.resendConfirmationCode(email);
      if (!result) {
        this.resendConfirmationCodeState = FetchState.ERROR;
        return;
      }
      this.resendConfirmationCodeState = FetchState.LOADED_ALL;
      this.confirmCodeForm.get('email').setValue(email);
      this.loginViewState = LoginViewState.CONFIRMATION_CODE;
    }
    catch (error) {
      this.resendConfirmationCodeState = FetchState.ERROR;
      return;
    }
  }


  /****************************
   * Confirm Code
   ***************************/
  confirmCodeForm: FormGroup;
  setupConfirmCode() {
    this.confirmCodeForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      code: ['', [Validators.minLength(6), Validators.maxLength(6), Validators.required]],
    });
  }

  confirmCodeState: FetchState = FetchState.NONE;
  async confirmCode() {
    if (this.confirmCodeForm.valid) {
      this.confirmCodeState = FetchState.LOADING;

      const confirmCodeFormValues = this.confirmCodeForm.getRawValue();
      const email = confirmCodeFormValues.email;
      const code = confirmCodeFormValues.code;

      try {
        let result = await this.cognitoService.confirmUser(email, code);
        if (result !== "SUCCESS") {
          this.confirmCodeState = FetchState.ERROR;
          return;
        }

        this.confirmCodeState = FetchState.LOADED_ALL;
        this.loginViewState = LoginViewState.LOGIN;

        // If we have a username and password from the register form, then attempt a login
        const registerFormValues = this.registerForm.getRawValue();
        const password = registerFormValues.password;
        if (!!password) {
          // Set the fields in the login form and then call loginUser
          this.loginForm.get('email').setValue(email);
          this.loginForm.get('password').setValue(password);
          this.loginUser();
        }
      }
      catch (error) {
        if (error.code === 'NotAuthorizedException' && error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
          // User is already confirmed
          this.confirmCodeState = FetchState.LOADED_ALL;
          this.loginViewState = LoginViewState.LOGIN;
          return;
        }
        this.confirmCodeState = FetchState.ERROR;
        return;
      }

    }
    else {
      this.confirmCodeState = FetchState.ERROR;
    }
  }


  /******************************
   * Forgot Password
   ******************************/
  forgotPasswordForm: FormGroup;
  setupForgotPassword() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  forgotPasswordState: FetchState = FetchState.NONE;
  async forgotPassword() {
    if (this.forgotPasswordForm.valid) {
      this.forgotPasswordState = FetchState.LOADING;

      const forgotPasswordFormValues = this.forgotPasswordForm.getRawValue();
      const email = forgotPasswordFormValues.email;

      try {
        let result = await this.cognitoService.forgotPassword(email);
        if (!result) {
          this.forgotPasswordState = FetchState.ERROR;
          return;
        }
        this.forgotPasswordState = FetchState.LOADED_ALL;
        this.loginViewState = LoginViewState.RESET_PASSWORD;
        this.resetPasswordForm.get('email').setValue(email);
      }
      catch (error) {
        this.forgotPasswordState = FetchState.ERROR;
        return;
      }

    }
    else {
      this.forgotPasswordState = FetchState.ERROR;
    }
  }


  /******************************
   * Reset Password
   ******************************/
  resetPasswordForm: FormGroup;
  setupResetPassword() {
    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      code: ['', [Validators.minLength(6), Validators.maxLength(6), Validators.required]],
      password: ['', this.newPasswordValidator()],
    });
  }

  get resetPasswordCodeErrorDisplay(): string {
    const codeControl = this.resetPasswordForm.get('code');
    if (codeControl.hasError('codeMismatch')) {
      return 'Code is invalid.';
    }
    if (codeControl.hasError('required')) {
      return 'Code is required.';
    }
    if (codeControl.hasError('minlength')) {
      return 'Code is invalid.';
    }
    if (codeControl.hasError('maxlength')) {
      return 'Code is invalid.';
    }
    return '';
  }

  resetPasswordState: FetchState = FetchState.NONE;
  async resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.resetPasswordState = FetchState.LOADING;

      const resetPasswordFormValues = this.resetPasswordForm.getRawValue();
      const email = resetPasswordFormValues.email;
      const code = resetPasswordFormValues.code;
      const password = resetPasswordFormValues.password;

      try {
        let result = await this.cognitoService.resetPassword(email, code, password);
        if (!result) {
          this.resetPasswordState = FetchState.ERROR;
          return;
        }
        this.resetPasswordState = FetchState.LOADED_ALL;
        this.loginViewState = LoginViewState.LOGIN;
      }
      catch (error) {
        if (error.code === 'CodeMismatchException') {
          this.resetPasswordState = FetchState.ERROR;
          this.resetPasswordForm.get('code').setErrors({codeMismatch: true});
          return;
        }
        this.resetPasswordState = FetchState.ERROR;
        return;
      }

    }
    else {
      this.resetPasswordState = FetchState.ERROR;
    }
  }
}
