import moment from "moment";


export function dateToUtcDateString(date?: Date): string {
  if (!date) return "";
  const momentDate = moment(date);
  return momentDate.format('YYYY-MM-DD');
}

export function utcDateStringToDate(utcString: string): Date | null {
  if (!utcString || utcString.length === 0) return null;

  try {
    const momentDate = moment.utc(utcString);
    return momentDate.toDate();
  }
  catch (err) {
    return null;
  }
}


