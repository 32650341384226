import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { AchCreditDetails, AchDebitDetails, CharityCreditDetails, LedgerCreditsAndDebitsResultDetails, LedgerDetails, PatronCreditDetails, PaymentDetails, PayoutDetails, PlatformCreditDetails, PromoterCreditDetails, VenueDebitDetails } from '../graphql/fragments/ledger.fragment.graphql';
import { CharityCredit, Ledger, LedgerSettleResults, PatronCredit, Payout } from '../model/ledger';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FetchState } from '../app.module';
import { PageAndSortInput, SearchInput } from '../graphql/types.graphql';
import { UserAuthService } from '../user-auth.service';

PatronCreditDetails
PromoterCreditDetails
PlatformCreditDetails
CharityCreditDetails
VenueDebitDetails
PayoutDetails
PaymentDetails
AchCreditDetails
AchDebitDetails


const FetchLedger = gql`
  ${LedgerDetails}
  query FetchLedger($ownerId: String!) {
    ledger(ownerId: $ownerId) {
      ...LedgerDetails
    }
  }
`;

const FetchLedgerCreditsAndDebits = gql`
${LedgerCreditsAndDebitsResultDetails}
query LedgerCreditsAndDebits($id: String!, $search: [SearchInput!]! = [], $sort: PageAndSortInput) {
  ledgerCreditsAndDebits(id: $id, search: $search, sort: $sort) {
    ...LedgerCreditsAndDebitsResultDetails
  }
}
`;

const LedgerSettleResultDetails = gql`
fragment LedgerSettleResultDetails on LedgerSettleResult {
  ownerId
  intSettleAmount
  settleDate
  totalCredits
  totalDebits
  success
  message
}
`;

const IssuePayout = gql`
${LedgerSettleResultDetails}
mutation IssuePatronPayout($id: String!) {
  issuePatronPayout(id: $id) {
    ...LedgerSettleResultDetails
  }
}
`;

@Injectable({
  providedIn: 'root'
})
export class LedgerService {

  constructor(
    private readonly apollo: Apollo,
    private readonly userAuthService: UserAuthService,
  ) {
    // Whenever the current user changes, clear out the ledger
    this.userAuthService.currentUser$.subscribe((user) => {
      this.clear();
    });
  }

  private _ownerId: string;
  get ownerId(): string {
    return this._ownerId;
  }
  set ownerId(value: string) {
    if (this._ownerId !== value) {
      this._ownerId = value;
      this.fetchLedger(this._ownerId);
    }
  }
  ledger$: BehaviorSubject<Ledger> = new BehaviorSubject(null);
  ledgerState$: BehaviorSubject<FetchState> = new BehaviorSubject(FetchState.NONE);

  async fetchLedger(ownerId: string, forceRefresh: boolean = true): Promise<Ledger> {
    if (!ownerId) {
      return null;
    }

    this.ledgerState$.next(FetchState.LOADING);

    return new Promise((resolve, reject) => {
        this.apollo.query({
        query: FetchLedger,
        variables: {
          ownerId: ownerId,
        },
        fetchPolicy: forceRefresh ? 'no-cache' : 'cache-first'
      })
      .pipe(
        takeUntil(this._ledgerKillSwitch$)
      )
        .subscribe({
        next: ({ data, loading }) => {
          if (data) {
            if (data['ledger']) {
              const ledger = Ledger.parseResponse(data['ledger']);
              this.ledger$.next(ledger);
              this.ledgerState$.next(FetchState.LOADED_ALL);

              this.clearCreditsAndDebits();
              if (ledger?.id) {
                this.fetchCreditsAndDebits(ledger.id, true);
              }
              else {
                this.creditsAndDebits$.next([]);
                this.creditsAndDebitsState$.next(FetchState.LOADED_ALL);
              }
              resolve(ledger);
            }
            else {
              this.ledger$.next(null);
              this.ledgerState$.next(FetchState.LOADED_ALL);
              this.creditsAndDebits$.next([]);
              this.creditsAndDebitsState$.next(FetchState.LOADED_ALL);
              resolve(null);
            }
          }
          else {
            this.ledger$.next(null);
            this.ledgerState$.next(FetchState.LOADED_ALL);
            this.creditsAndDebits$.next([]);
            this.creditsAndDebitsState$.next(FetchState.LOADED_ALL);
            resolve(null);
          }
        },
        error: (error) => {
          if (error?.message?.includes("Ledger not found")) {
            // This is a valid state
            this.ledger$.next(null);
            this.ledgerState$.next(FetchState.LOADED_ALL);
            this.creditsAndDebits$.next([]);
            this.creditsAndDebitsState$.next(FetchState.LOADED_ALL);
            resolve(null);
            return;
          }
          this.ledgerState$.next(FetchState.ERROR);
          console.log("Error fetching ledger", error);
          reject(error);
        }
      });
    });
  }

  private _ledgerKillSwitch$: Subject<void> = new Subject<void>();
  private _killLedger() {
    this._ledgerKillSwitch$.next();
    this.ledger$.next(null);
    this.ledgerState$.next(FetchState.NONE);
  }
  private _creditsAndDebitsKillSwitch$: Subject<void> = new Subject<void>();
  private _killCreditsAndDebits() {
    this._creditsAndDebitsKillSwitch$.next();
    this.creditsAndDebits$.next([]);
    this.creditsAndDebitsState$.next(FetchState.NONE);
  }

  creditsAndDebits$: BehaviorSubject<Array<PatronCredit | CharityCredit | Payout | LedgerSettleResults | any>> = new BehaviorSubject([]);
  creditsAndDebitsState$: BehaviorSubject<FetchState> = new BehaviorSubject(FetchState.NONE);
  page: number = 0;
  pageSize: number = 5;

  private clear() {
    this.clearLedger();
  }
  private clearLedger() {
    this._killLedger();
    this.ledger$.next(null);
    this.ledgerState$.next(FetchState.NONE);
    this.clearCreditsAndDebits();
  }
  private clearCreditsAndDebits() {
    this._killCreditsAndDebits();
    this.creditsAndDebits$.next([]);
    this.creditsAndDebitsState$.next(FetchState.NONE);
    this.page = 0;
  }

  refreshCreditsAndDebits() {
    this.fetchCreditsAndDebits(null, true);
  }

  loadMore() {
    // If we have loaded all, then nothing to do
    if (this.creditsAndDebitsState$.getValue() === FetchState.LOADED_ALL) {
      return;
    }
    this.fetchCreditsAndDebits(this.ledger$.getValue()?.id);
  }

  fetchCreditsAndDebits(ledgerId?: string, forceRefresh: boolean = false) {
    if (this.creditsAndDebitsState$.getValue() === FetchState.LOADING) {
      return;
    }
    return this._fetchCreditsAndDebits(ledgerId, forceRefresh);
  }

  private _fetchOffersRetryCount: number = 0;
  private _fetchCreditsAndDebits(ledgerId?: string, forceRefresh: boolean = false) {
    if (forceRefresh) {
      this.clearCreditsAndDebits();
    }

    if (!ledgerId) {
      ledgerId = this.ledger$.getValue()?.id;
    }
    if (!ledgerId) {
      return;
    }

    const sort: PageAndSortInput = {
      page: this.page,
      pageSize: this.pageSize,
      sortFields: [
        {sortField: "txDate", sortAscending: false}
      ]
    };

    const searchInputs: Array<SearchInput> = []


    const variables = {
      id: ledgerId,
      search: searchInputs.map((searchInput) => {
        return {
          value: searchInput.value,
          searchFields: searchInput.searchFields,
          operator: searchInput.operator.toUpperCase()
          };
        }),
      sort: sort,
    };

    this.creditsAndDebitsState$.next(FetchState.LOADING);

    this.apollo.query({
      query: FetchLedgerCreditsAndDebits,
      variables
    })
    .pipe(
      takeUntil(this._creditsAndDebitsKillSwitch$)
    )
    .subscribe({
      next: ({ data, loading }) => {
        this._fetchOffersRetryCount = 0;
        const result = data['ledgerCreditsAndDebits'];
        const dataResults = result?.data;
        const results: any[] = LedgerService.parseCreditsAndDebits(dataResults);

        if (results.length > 0) {
          let newResults = [...this.creditsAndDebits$.value, ...results].sort((a, b) => {
            return b.txDate.getTime() - a.txDate.getTime();
          });
          this.creditsAndDebits$.next(newResults);
        }

        // If the current page is the last page, then we have loaded all
        if (!!result && (result?.currentPage + 1) >= Math.ceil(result?.total /result.pageSize)) {
          this.creditsAndDebitsState$.next(FetchState.LOADED_ALL);
        } else {
          this.page++;
          this.creditsAndDebitsState$.next(FetchState.GOOD);
        }
      },
      error: (error) => {
        this._fetchOffersRetryCount += 1;

        if (this._fetchOffersRetryCount < 5) {
          const timeoutTime = 150 * Math.pow(2, this._fetchOffersRetryCount);
          console.log(`Retrying fetch ledger credits and debits in ${timeoutTime}ms`);
          setTimeout(() => {
            this._fetchOffersRetryCount++;
            this._fetchCreditsAndDebits(ledgerId, forceRefresh);
          }, timeoutTime);
        }
        else {
          console.log(`error fetching ledger credits and debits: ${error}`);
          this.creditsAndDebitsState$.next(FetchState.ERROR);
        }
      }
    });
  }

  private static parseCreditsAndDebits(data: any[]) {
    const results: any[] = [];
    for (const lineData of data) {
      if (lineData) {
        if (lineData.__typename === 'CharityCredit') {
          results.push(CharityCredit.parseResponse(lineData));
        }
        else if (lineData.__typename === 'PatronCredit') {
          results.push(PatronCredit.parseResponse(lineData));
        }
        else if (lineData.__typename === 'Payout') {
          results.push(Payout.parseResponse(lineData));
        }
        else {
          results.push(lineData);
        }
      }
    }
    return results;
  }


  issuePayoutResult$: BehaviorSubject<LedgerSettleResults> = new BehaviorSubject(null);
  issuePayoutState$: BehaviorSubject<FetchState> = new BehaviorSubject(FetchState.NONE);
  async issuePayout(): Promise<LedgerSettleResults> {
    // ownerId = ownerId || this.ownerId;
    if (!this.ownerId) {
      return Promise.reject("No owner id");
    }

    if (this.issuePayoutState$.getValue() === FetchState.LOADING) {
      return Promise.reject("Already loading");
    }

    this.issuePayoutState$.next(FetchState.LOADING);

    return new Promise((resolve, reject) => {
      this.apollo.mutate({
        mutation: IssuePayout,
        variables: {
          id: this.ownerId,
        }
      }).subscribe({
        next: ({ data, loading }) => {
          if (data) {
            const result = LedgerSettleResults.parseResponse(data['issuePatronPayout']);
            if (result?.success) {
              this.issuePayoutState$.next(FetchState.GOOD);
              this.issuePayoutResult$.next(result);

              // Refresh the ledger and credits and debits
              this.clearCreditsAndDebits();
              this.fetchLedger(this.ownerId, true);

              resolve(result);
            }
            else {
              this.issuePayoutState$.next(FetchState.ERROR);
              this.issuePayoutResult$.next(result);
              reject(result?.message || "Unknown error");
            }
          }
          else {
            this.issuePayoutState$.next(FetchState.ERROR);
            this.issuePayoutResult$.next(null);
            reject("Unknown error");
          }
        },
        error: (error) => {
          console.log("Error issuing payout", error);
          this.issuePayoutState$.next(FetchState.ERROR);
          reject(error);
        }
      });
    });
  }



}
