import { Injectable } from '@angular/core';
import { AuthTokenService } from '../auth-token.service';
import { environment } from 'src/environments/environment';
import { LoginState, UserAuthService } from '../user-auth.service';

export type AuthToken = {
  jwtToken?:string,
  payload?: any
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private readonly userAuthService: UserAuthService,
  ) { }

  private signInResultHandlers: Array<Function> = [];
  public registerSignInResultHandler(handler: Function) {
    // Push this handler if it's not already in the list
    if (this.signInResultHandlers.indexOf(handler) === -1) {
      this.signInResultHandlers.push(handler);
    }
  }
  public removeSignInResultHandler(handler: Function) {
    // Remove this handler if it's in the list
    const index = this.signInResultHandlers.indexOf(handler);
    if (index !== -1) {
      this.signInResultHandlers.splice(index, 1);
    }
  }


  async handleCredentialResponse(response: {accessToken?: AuthToken, idToken?: AuthToken, refreshToken?: {token?: string}}) {
    this.userAuthService.setState(LoginState.LOGGING_IN);

    if (!response) {
      this.userAuthService.setState(LoginState.ERROR);
      console.log('=== AUTH SIGN IN ERROR ===');
      console.log(response);
      return;
    }
    if (!response.idToken?.jwtToken) {
      this.userAuthService.setState(LoginState.ERROR);
      console.log('=== AUTH SIGN IN ERROR (NO CREDENTIAL) ===');
      console.log(response);
      AuthTokenService.instance.handleAuthFailure();
      for (const handler of this.signInResultHandlers) {
        handler(null, "no authorization", null);
      }
      return;
    }

    // Handle successful response.
    console.log('=== AUTH SIGN IN SUCCESS ===');
    console.log(response);
    const uri = environment.authRedirectUri;

    try {
      // Get/set the system id from local storage
      const systemId: string = AuthTokenService.instance.getSystemId(true);
      const fetchResponse = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-system-id': systemId
        },
        body: JSON.stringify({id_token: response.idToken.jwtToken, auth_provider: 'aws'})
      });
      console.log('=== AUTH SIGN IN SUCCESS (BACK-END) ===');

      const body = await fetchResponse.json();
        // If this is a 200 response, then we have a valid token
        if (fetchResponse.status >= 200 && fetchResponse.status < 300) {
          AuthTokenService.instance.handleAuthResponse(body);
        }
        else {
        // If this is a 401 response, then we have an invalid token
          AuthTokenService.instance.handleAuthFailure(body);
          return;
        }

      for (const handler of this.signInResultHandlers) {
        handler(body, null, null);
      }
    }
    catch (error) {
      console.log('=== AUTH SIGN IN ERROR (BACK-END) ===');
      console.log(error);
      AuthTokenService.instance.handleAuthFailure(error);
    }
  }
}
