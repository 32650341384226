import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plaid',
  templateUrl: './plaid.component.html',
  styleUrls: ['./plaid.component.css']
})
export class PlaidComponent implements OnInit {

  constructor(
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    // Get the oauth_state_id from the URL
    const oauthStateId = this.getOauthStateId();
    console.log('oauthStateId', oauthStateId);

    // Redirect to the credit cards page
    this.router.navigate(['/patron/cards']);
  }

  getOauthStateId(): string {
    const url = window.location.href;
    const oauthStateId = url.split('oauth_state_id=')[1];
    return oauthStateId;
  }
}
