<div class="flex flex-row-reverse items-center h-full w-full gap-8" [formGroup]="parentForm">
  <app-offer-tile2 class="hidden md:flex min-w-[325px] max-w-[425px] m-8 drop-shadow-md " [offerData]="{offer: patronOffer}"></app-offer-tile2>
  <div class="w-full h-full flex-grow flex flex-col">
    <div class="page-header-title mb-4">{{ !!id ? 'Edit' : 'New' }} Offer</div>
    <div class="w-full h-full flex-grow flex flex-col overflow-y-auto" (scroll)="onScroll($event)">
      <div class="flex flex-col justify-start w-full flex-grow gap-6">

        <!-- <div class="w-full flex flex-row justify-around mt-2">
        <div class="flex flex-col justify-around ml-3">
          <label class="relative inline-flex items-center mr-5 cursor-pointer">
            <input type="checkbox" value="" class="sr-only peer" [checked]="!disabled" (click)="toggleEnabled()">
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-business-blue">
            </div>
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-900">Enabled</span>
          </label>
          <label class="relative inline-flex items-center mr-5 cursor-pointer">
            <input type="checkbox" value="" class="sr-only peer" [checked]="archived" (click)="toggleArchived()">
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-business-blue">
            </div>
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-900">Archived</span>
          </label>
        </div>
      </div> -->


        <div *ngIf="currentIndex === 1 || true" formGroupName="detailsGroup"
          class="w-full h-full flex flex-col" #detailsDiv id="detailsDiv">
          <div class="section-header-title w-full flex flex-row justify-between items-center">
            <span>
              Details
            </span>
            <span *ngIf="!!isVenueDefault">
              <fa-icon [icon]="FaPrimary" class="text-business-blue text-2xl"></fa-icon>
            </span>
          </div>
          <div class="w-full flex flex-col justify-center flex-grow space-y-2">
            <div class="w-fullflex flex-col" *ngIf="navControlService.hasRole(NavRole.ADMIN)">
                <label class="text-sm text-grey-grey-5" for="name">
                Offer ID
              </label>
              <input
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="id" formControlName="id" type="text" [disabled]="true">
            </div>
            <div class="w-full">
              <label class="text-sm text-grey-grey-5" for="name">
                Offer Name
              </label>
              <input
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="name" formControlName="name" type="text">
              <div *ngIf="parentForm.controls['name']?.invalid && parentForm.controls['name']?.touched">Name is Required</div>
            </div>
            <div class="flex flex-col">
              <label class="text-sm text-grey-grey-5" for="name">Banner Image</label>
              <div
                class="relative w-full h-[175px] flex flex-row justify-center items-center overflow-hidden border rounded text-grey-grey-8 bg-gray-100 cursor-pointer"
                (click)="openFileInput('BANNER')">
                <!-- Image preview -->
                <img *ngIf="offerImagePreviewUrl" [src]="offerImagePreviewUrl" alt="Image Preview"
                  class="w-full h-full object-cover" />
                <div class="flex flex-col justify-center items-center">
                  <fa-icon *ngIf="!offerImagePreviewUrl" [icon]="FaImage" class="text-business-blue text-4xl"></fa-icon>
                  <div
                    *ngIf="parentForm.controls['offerImageFile']?.invalid && parentForm.controls['offerImageFile']?.touched"
                    class="text-red-500 text-sm">Image is required</div>
                </div>

                <!-- Custom button -->
                <div *ngIf="offerImagePreviewUrl" class="absolute top-2 right-4">
                  <fa-icon [icon]="FaImage" class="text-white text-4xl"></fa-icon>
                </div>

                <!-- Hidden actual input field -->
                <input type="file" class="hidden" #offerFileInput (change)="onFileSelected($event, 'offerImageFile')"
                  (close)="onCloseFile('offerImageFile')" (cancel)="onCloseFile('offerImageFile')"
                  (error)="onFileSelectError($event, 'offerImageFile')" />
              </div>
            </div>
            <div class="">
              <label class="text-sm text-grey-grey-5" for="description">
                Offer Description
              </label>
              <textarea type="text"
                class="h-[100px] appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="description" formControlName="description" type="text"></textarea>
            </div>
            <div class="">
              <label class="text-sm text-grey-grey-5" for="finePrint">
                Fine Print
              </label>
              <textarea type="text"
                class="h-[100px] appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="finePrint" formControlName="finePrint" type="text"></textarea>
            </div>
            <!-- </div> -->
          </div>

        </div>

        <div class="w-full flex flex-col gap-4" #incentivesDiv id="incentivesDiv">
          <div class="section-header-title">
            Incentives
          </div>
          <app-incentives *ngIf="currentIndex === 3 || true" [incentiveGroup]="incentiveGroup"
            class="w-full h-full flex flex-col"></app-incentives>
        </div>

        <div class="w-full flex flex-col gap-4 mb-4" #availabilityDiv id="availabilityDiv">
          <div class="section-header-title">
            Availability
          </div>
          <app-available [availableGroup]="availableGroup" *ngIf="currentIndex === 2 || true"
            class="w-full h-full flex flex-col">
          </app-available>
        </div>

        <div *ngIf="currentIndex === 4 || true">
          <!-- <div formArrayName="promos">
            <div *ngFor="let sub of subsArray.controls; let i = index">
              <app-sub-object [parentForm]="sub"></app-sub-object>
              <button type="button" (click)="removeSubObject(i)">Remove</button>
            </div>
          </div> -->

          <!-- <div *ngIf="currentIndex == 4"> -->
          <!-- <app-promos [parentForm]="parentForm" [venueOffer]="venueOffer"></app-promos> -->
          <!-- </div> -->
        </div>
      </div>

      <div class="w-full flex flex-col gap-4 my-6" #dangerDiv id="dangerDiv">
        <div class="section-header-title !text-red-800">
          Danger Zone
        </div>
        <div class="w-full flex flex-row justify-around">
          <!-- Enable Button -->
          <div *ngIf="!!id && !enabled && !archived" class="button-white-red" (click)="toggleEnabled()">Enable</div>
          <!-- Archive Button -->
          <div *ngIf="!!id && enabled && !archived" class="button-white-red" (click)="toggleEnabled()">Disable</div>
          <!-- Unarchive Button -->
          <div *ngIf="!!id && archived" class="button-white-red" (click)="toggleArchived()">Unarchive</div>
          <!-- Archive Button -->
          <div *ngIf="!!id && !archived" class="button-white-red" (click)="toggleArchived()">Archive</div>
        </div>
      </div>
    </div>
    <div class="w-full flex flex-row justify-center my-2">
      <ul
        class="relative flex-grow text-md font-medium text-center text-gray-100w-full flex flex-row">
        <li class="w-full pb-2">
          <div (click)="scrollToDiv('detailsDiv')"
            [ngClass]="{'text-zinc-900 font-bold': currentDivId === 'detailsDiv', 'font-normal text-zinc-700': currentDivId !== 'detailsDiv'}"
            class=" cursor-pointer inline-block w-full active "
            aria-current="page">Details</div>
        </li>
        <li class="w-full pb-2">
          <div (click)="scrollToDiv('incentivesDiv')"
            [ngClass]="{'text-zinc-900 font-bold': currentDivId === 'incentivesDiv', 'font-normal text-zinc-700': currentDivId !== 'incentivesDiv'}"
            class="cursor-pointer inline-block w-full">
            Incentives</div>
        </li>
        <li class="w-full pb-2">
          <div (click)="scrollToDiv('availabilityDiv')"
            [ngClass]="{'text-zinc-900 font-bold': currentDivId === 'availabilityDiv', 'font-normal text-zinc-700': currentDivId !== 'availabilityDiv'}"
            class="cursor-pointer inline-block w-full">
            Available</div>
        </li>
        <!-- Underline element -->
        <div #underline class="absolute h-1 bg-zinc-900" style="width: calc(100% / 3); bottom: 0; left: 0; transition: left 0.3s;"></div>
      </ul>
    </div>

  </div>

</div>
