import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavControlService } from '../../nav/nav-control.service';
import { ActivatedRoute } from '@angular/router';
import { VenueService } from 'src/app/venue.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Merchant, Venue } from 'src/app/model/venue.model';
import { MerchantService } from 'src/app/merchant.service';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { FetchState } from 'src/app/app.module';

@Component({
  selector: 'app-venue-merchant-details',
  templateUrl: './venue-merchant-details.component.html',
  styleUrls: ['./venue-merchant-details.component.css']
})
export class VenueMerchantDetailsComponent implements OnInit, OnDestroy {

  faEdit = faEdit;
  FetchState = FetchState;

  constructor(
    protected readonly navControlService: NavControlService,
    private readonly route: ActivatedRoute,
    private readonly venueService: VenueService,
    protected readonly merchantService: MerchantService,
  ) {}

  venue: Venue | null;
  merchant: Merchant | null;
  fetchMerchantState: FetchState;

  ngOnInit(): void {
    // Get the venue id from the route, then load that venue
    this.route.params.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (params) => {
        const venueId = params['venueId'];
        this.loadVenue(venueId);
      }
    });

    this.navControlService.selectedVenue$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (venue) => {
        this.venue = venue;
      }
    });

    this.merchantService.fetchMerchantState$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (state) => {
        this.fetchMerchantState = state;
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async loadVenue(venueId: string) {
    try {
      const venue = await this.venueService.fetchById(venueId);
      this.navControlService.selectVenue(venue, false);
      this.loadMerchant(venueId);
    }
    catch (error) {
      console.error(error);
    }
  }

  private async loadMerchant(venueId: string) {
    try {
      const merchant = await this.merchantService.fetchMerchant(venueId);
      this.merchant = merchant;
    }
    catch (error) {
      console.error(error);
    }
  }

  showEditMerchant: boolean = false;
  editMerchant() {
    this.showEditMerchant = true;
  }
  closeMerchantModal() {
    this.showEditMerchant = false;
  }

}
