import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PatronClaim, PatronOffer } from '../model/patron-offer.model';

export enum NavControlEventType {
  OFFER_CLAIMED = 'offerClaimed',
  OFFER_UNCLAIMED = 'offerUnclaimed',
}

export type NavControlEvent = {
  type: NavControlEventType,
  data?: any,
}

@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  constructor() { }

    // Generic event emitter
  eventSubject$: Subject<NavControlEvent> = new Subject<NavControlEvent>();

  public emitEvent(event: NavControlEvent) {
    this.eventSubject$.next(event);
  }

  public offerClaimed(claim: PatronClaim) {
    this.emitEvent({
      type: NavControlEventType.OFFER_CLAIMED,
      data: claim,
    });
  }

  public offerUnclaimed(offerId: string) {
    this.emitEvent({
      type: NavControlEventType.OFFER_UNCLAIMED,
      data: offerId,
    });
  }

}
