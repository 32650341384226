import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { AppleService } from 'src/app/apple/apple.service';
import { BusinessType } from 'src/app/services/waitlist.service';
import { NavControlService } from '../nav/nav-control.service';

@Component({
  selector: 'app-fundraising',
  templateUrl: './fundraising.component.html',
  styleUrls: ['./fundraising.component.css']
})
export class FundraisingComponent implements OnDestroy {
  loggedIn: boolean = false;

  categories: any[] = [
    { label: "Restaurants, Dining, Bistros", imagePath: "../../../assets/images/Category1.jpg" },
    { label: "Cafes, Coffee, Deli", imagePath: "../../../assets/images/Category2.jpg" },
    { label: "Breweries, Wineries", imagePath: "../../../assets/images/Category3.jpeg" },
    { label: "Bars, Lounges, Pubs", imagePath: "../../../assets/images/Category4.jpeg" }
  ];

  questions: any[] = [
    { question: "How does GetZeals fundraising work?", answer: "Bacon ipsum dolor amet pork pork chop rump cow, leberkas pig pastrami beef turkey short loin tri-tip. Filet mignon pork kielbasa pork chop biltong chicken leberkas buffalo. Beef ribs bacon ball tip beef filet mignon. Biltong sirloin andouille pork loin beef beef ribs pork tenderloin swine.", isOpen: false },
    { question: "How much does it cost?", answer: "", isOpen: false },
    { question: "How much can my team make?", answer: "", isOpen: false },
    { question: "How do I get started?", answer: "", isOpen: false },
  ]

  public getScreenWidth: any;

  businessType: BusinessType = BusinessType.Charity;

  constructor(
    private elementRef: ElementRef,
    public readonly appleService: AppleService,
    private readonly navControlService: NavControlService,
  ) {
    this.getScreenWidth = window.innerWidth;
    this.navControlService.primaryColor = 'charity-green';
  }

  ngOnDestroy(): void {
    this.navControlService.primaryColor = 'patron-pink';
  }

  scrollToElement(): void {
    const element = this.elementRef.nativeElement.querySelector('#howItWorks');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }

  toggleFAQ(question: any, i: number) {
    question.isOpen = !question.isOpen
  }

  scrollToWaitlist() {
    document.getElementById("waitlist").scrollIntoView({ behavior: "smooth" });
  }
}
