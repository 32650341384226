import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavControlService, NavRole } from '../components/nav/nav-control.service';
import { Observable, catchError, first, map, of } from 'rxjs';
import { LoginState } from '../user-auth.service';

@Injectable({
  providedIn: 'root'
})
export class VenueOperatorGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly navControlService: NavControlService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.navControlService.userState$
      .pipe(
        first(loginState => loginState === LoginState.LOGGED_IN || loginState === LoginState.LOGGED_OUT),
        map(loginState => {
          if (loginState === LoginState.LOGGED_IN && (this.navControlService.hasRole(NavRole.VENUE) || this.navControlService.hasRole(NavRole.ADMIN))) {
            // If the current role is not venue (or admin), then change the role to venue
            if (this.navControlService.role !== NavRole.VENUE && this.navControlService.role !== NavRole.ADMIN) {
              this.navControlService.setRole(NavRole.VENUE, false);
            }
            return true;
          } else {
            this.router.navigate(['/']);
            return false;
          }
        }),
        catchError(() => {
          this.router.navigate(['/']);
          return of(false);
        })
      );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }
}
