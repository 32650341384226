import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoginState } from 'src/app/user-auth.service';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.css']
})
export class MainContainerComponent {
  @Input()
  backgroundImagePath: string = '';
  @Input()
  overylayImagePath: string = '';
  @Input()
  useBgImageFilter: boolean = false;
  @Input()
  header: string = '';
  @Input()
  subHeader: string = '';
  @Input()
  buttonLabel: string = '';
  @Input()
  buttonClass: string = '';
  @Output()
  buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  LoginState = LoginState;

  constructor(
  ) {

  }

  handleMainButtonClick(): void {
    this.buttonClicked.emit();
  }

  get mainDivBgImage(): string {
    let result =  '';
    if (!!this.useBgImageFilter && !!this.backgroundImagePath) {
      result = 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.25)), url(' + this.backgroundImagePath +')';
    }
    else if (!!this.useBgImageFilter) {
      result = 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.25))';
    }
    else if (!!this.backgroundImagePath) {
      result = 'url(' + this.backgroundImagePath +')';
    }
    return result;
  }
}
