import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavControlService } from '../nav-control.service';
import { LoginState } from 'src/app/user-auth.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { LedgerService } from 'src/app/services/ledger.service';
import { Ledger } from 'src/app/model/ledger';
import { LoginViewState } from 'src/app/auth/login/login.component';

@Component({
  selector: 'app-account-widget',
  templateUrl: './account-widget.component.html',
  styleUrls: ['./account-widget.component.css']
})
export class AccountWidgetComponent implements OnInit, OnDestroy {

  userState: LoginState;
  get user(): User {
    return this.navControlService.user;
  }
  get greeting(): string {
    if (this.user) {
      if (this.user.firstName && this.user.firstName.length > 0) {
        return `Hi ${this.user.firstName}!`;
      }
      else if (this.user.lastName && this.user.lastName.length > 0) {
        return `Hi ${this.user.lastName}!`;
      }
    }
    return 'Hi!';
  }
  ledger: Ledger;
  isExpanded: boolean = false;

  @ViewChild('cashOutWidget') cashOutWidget: ElementRef;

  LoginState = LoginState;

  constructor(
    protected readonly navControlService: NavControlService,
    private readonly ledgerService: LedgerService,
  ) { }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.ledgerService.ledger$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (ledger) => {
        this.ledger = ledger;
      }
    });

    this.navControlService.userState$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (userState) => {
        this.userState = userState;
      }
    });

    this.navControlService.isNavHeaderExpanded$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (isExpanded) => {
        setTimeout(() => {
          this.isExpanded = isExpanded;
        }, 1);
      }
    });

    this.navControlService.user$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (user) => {
        if (user?.patron?.id) {
          this.ledgerService.ownerId = user.patron.id;
        }
      }
    });
  }

  get minWidth(): number {
    // If cashOutWidget is undefined, then return 0, else return its width
    const result = this.cashOutWidget?.nativeElement?.offsetWidth || 0;
    return result;
  }

  get detailsContainerStyle(): any {
    const minWidth = this.minWidth;
    if (minWidth === 0) return {
      'width': 'auto',
    };
    return {
      'min-width': `${minWidth}px`,
      'width': `${minWidth}px`,
      'padding-left': '9px',
    };
  }

  toggleMenu() {
    this.navControlService.toggleMenu();
  }

  closeMenu() {
    this.navControlService.closeMenu();
  }

  get isMenuOpen(): boolean {
    return this.navControlService.isMenuOpen;
  }

  toggleAccount() {
    // If viewport width is greater than 768px, then toggle the menu
    if (window.innerWidth >= 768) {
      this.navControlService.toggleMenu();
    }
    else {
      this.navControlService.toggleAccountDetails();
    }
  }

  signIn() {
    this.navControlService.showLogin(LoginViewState.LOGIN);
  }

  signUp() {
    this.navControlService.showLogin(LoginViewState.REGISTER);
  }



}
