<div class="w-full px-4 sm:px-4 max-w-2xl items-center flex flex-col mx-auto h-full overflow-y-auto">
  <div *ngIf="!showFidelLinkCardOnly" class="w-full items-center flex flex-col">
    <div class="w-full text-center text-black text-4xl font-bold antialiased mb-2">{{ 'LINKED_CREDIT_CARDS' | translate }}</div>
    <div class="w-full text-center text-black text-sm mb-5">
      We keep your financial details secure so no one sees your sensitive info.</div>
  </div>

  <div class="w-full flex transition-all duration-300 bg-gray-50 relative"
    [ngClass]="{'h-[725px] sm:h-[530px]': displayingLinkedCard, 'h-0': !displayingLinkedCard}">
    <div *ngIf="linkCardState === FetchState.LOADING" class="absolute inset-0 z-0">
      <div class="loader-wrapper flex justify-center items-center">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"></div>
      </div>
    </div>
    <div #myFidelContainer class="w-full h-[725px] sm:h-[530px] justify-center items-center"
    [ngClass]="{'z-10': linkCardState === FetchState.LOADING, '-z-10': linkCardState !== FetchState.LOADING}">
    </div>
</div>

  <div *ngIf="!showFidelLinkCardOnly" class="overflow-y-auto mb-10 w-full transition-all duration-300 flex flex-col justify-center items-center gap-4"
    [ngClass]="{'h-auto': !displayingLinkedCard, 'h-0': displayingLinkedCard}">

    <app-card-row class="w-full" *ngFor="let card of activeUserPaymentMethods" [card]="card"></app-card-row>

    <div *ngIf="canLinkCard" class="w-full p-0 pt-4 flex flex-col justify-center items-center">
      <button *ngIf="linkCardState !== FetchState.LOADING" (click)="showLinkCard()"
        class="button-patron-pink">
        Add New Card
      </button>
      <div *ngIf="linkCardState === FetchState.LOADING">
        <div class="loader-wrapper flex justify-center items-center">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"></div>
        </div>
      </div>
    </div>
    <div *ngIf="!canLinkCard" class="w-full p-0 pt-4">
      <div class="flex flex-row w-full items-center justify-center px-3 py-3 rounded-lg bg-white">
        <div *ngIf="fidelScriptState === FetchState.LOADING">
          <div class="loader-wrapper flex justify-center items-center">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"></div>
          </div>
        </div>
        <div *ngIf="fidelScriptState === FetchState.ERROR"
          class="gap-0 flex-col w-full justify-center items-start flex-1 p-0 flex">
          <span class="text-lg font-bold antialiased text-black text-left w-full">{{ 'FIDEL.LINK_UNAVAILABLE' |
            translate }}</span>
        </div>
      </div>
    </div>

    <!-- Archived Cards -->
    <div *ngIf="archivedUserPaymentMethods?.length > 0" class="w-full mt-10 mb-2"
      (click)="toggleShowArchived()">
      <div class="flex flex-row w-full items-center justify-center mb-2">
        <div class="w-full text-left text-black text-xl font-bold antialiased">Archived Credit Cards</div>
        <svg width="16" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg"
          class="transition-transform duration-300 ease-in-out" [ngClass]="{
        'transform rotate-180': showingArchived,
        'transform rotate-0': !showingArchived
      }">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.667 12.6043C18.1444 12.6043 17.6217 12.4042 17.2236 12.0061L10.4759 5.25839L3.75266 11.7509C2.94008 12.5349 1.64975 12.5145 0.865745 11.6999C0.0817455 10.8893 0.104204 9.59489 0.914745 8.81293L9.08141 0.928018C9.88379 0.154226 11.1578 0.164434 11.9438 0.952518L20.1105 9.11918C20.9088 9.91748 20.9088 11.2078 20.1105 12.0061C19.7124 12.4042 19.1897 12.6043 18.667 12.6043Z"
            fill="#000000" />
        </svg>
      </div>
      <div *ngIf="showingArchived" class="w-full">
        <app-card-row *ngFor="let card of archivedUserPaymentMethods" [card]="card"></app-card-row>
      </div>
    </div>
  </div>
</div>
