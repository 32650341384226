import { AchAccount, IAchAccountOwner } from "./ach_account.model";
import { AffiliateVenue } from "./affiliate_venue.model";
import { Entity } from "./entity.model";
import { User } from "./user.model";
import { VenueOffer } from "./venue_offer.model";


export class Affiliate extends Entity implements IAchAccountOwner {// ILedgerOwner

  constructor() {
    super();
  }

  name: string;
  code: string;
  description: string;
  intPlatformPercentage: number;
  intPlatformAmount: number;
  intDurationDays: number;
  // availableDate: Date;
  archivedDate: Date;
  affiliateVenues: Array<AffiliateVenue> = [];
  affiliateOperators: Array<AffiliateOperator> = [];
  incentives: Array<AffiliateIncentive> = [];
  // ledger: Ledger;
  // serviceIntegrations: Array<AffiliateServiceIntegration> = [];
  achAccount: AchAccount;

  static override parseResponse(details: any, result?: Affiliate): Affiliate {
    if (!details) return null;
    if (!result) result = new Affiliate();

    Entity.parseResponse(details, result);

    result.name = details.name;
    result.code = details.code;
    result.description = details.description;
    result.intPlatformPercentage = details.intPlatformPercentage ?? 0;
    result.intPlatformAmount = details.intPlatformAmount ?? 0;
    result.intDurationDays = details.intDurationDays ?? 0;
    // result.availableDate = new Date(details.availableDate);
    if (details.archivedDate) {
      result.archivedDate = new Date(details.archivedDate);
    }
    result.affiliateVenues = details.affiliateVenues?.map(v => AffiliateVenue.parseResponse(v));
    result.affiliateOperators = details.affiliateOperators?.map(o => AffiliateOperator.parseResponse(o));
    result.incentives = details.incentives?.map(i => AffiliateIncentive.parseResponse(i));
    // result.ledger = Ledger.parseResponse(details.ledger);
    // result.serviceIntegrations = details.serviceIntegrations?.map(i => AffiliateServiceIntegration.parseResponse(i));
    result.achAccount = AchAccount.parseResponse(details.achAccount);

    return result;
  }


  override toRequest(): any {
    const result: any = super.toRequest();
    result.name = this.name;
    result.code = this.code;
    result.description = this.description;
    result.intPlatformPercentage = this.intPlatformPercentage;
    result.intPlatformAmount = this.intPlatformAmount;
    result.intDurationDays = this.intDurationDays;

    if (this.archivedDate) {
      result.archivedDate = this.archivedDate.toISOString();
    }

    return result;
  }
}

export class AffiliateOperator extends Entity {

  constructor() {
    super();
  }

  primary: boolean;
  // operatorId: string;
  // affiliateId: string;
  user: User;

  static override parseResponse(details: any, result?: AffiliateOperator): AffiliateOperator {
    if (!details) return null;
    if (!result) result = new AffiliateOperator();

    Entity.parseResponse(details, result);

    result.primary = details.primary;
    // result.operatorId = details.operatorId;
    // result.affiliateId = details.affiliateId;
    if (details.operator?.user) {
      result.user = User.parseResponse(details.operator?.user);
    }

    return result;
  }
}

export class AffiliateIncentive extends Entity { //implements IIncentive {

  constructor() {
    super();
  }

  disabledDate?: Date;
  intPlatformPercentage: number;
  intPlatformAmount: number;
  returnCurrency: string;
  intMinSpend: number;
  intMaxReward: number;
  offer: VenueOffer;
  affiliateId: string;
  // credits: Array<AffiliateCredit> = [];

  static override parseResponse(details: any, result?: AffiliateIncentive): AffiliateIncentive {
    if (!details) return null;
    if (!result) result = new AffiliateIncentive();

    Entity.parseResponse(details, result);

    if (details.disabledDate) {
      result.disabledDate = new Date(details.disabledDate);
    }
    result.intPlatformPercentage = details.intPlatformPercentage;
    result.intPlatformAmount = details.intPlatformAmount;
    result.returnCurrency = details.returnCurrency;
    result.intMinSpend = details.intMinSpend;
    result.intMaxReward = details.intMaxReward;
    result.offer = VenueOffer.parseResponse(details.offer);
    result.affiliateId = details.affiliateId;

    return result;
  }


}
