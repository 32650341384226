import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPullDownClose]'
})
export class PullDownCloseDirective {
  @Input() pullDownHandleRef: ElementRef | null = null;
  @Output() close = new EventEmitter<number>();
  @Output() offsetY = new EventEmitter<number>();

  private startY: number | null = null;
  private lastY: number | null = null;
  private maximumDistance: number | null = null;
  private closingThreshold: number = 500; // px
  private closingThresholdPercentage: number = 0.3; // %
  private velocityThreshold: number = 1.5; // px/ms
  private lastTime: number | null = null;


  private moveListener: (() => void) | null = null;
  private endListener: (() => void) | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  private _isPullDownHandle: boolean = false;
  private _isMouseEvent: boolean = false;
  private _startTime: number | null = null;
  private _windowHeight: number = window.innerHeight;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this._windowHeight = window.innerHeight;
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this._isMouseEvent = true;
    this.moveListener = this.renderer.listen('document', 'mousemove', this.onMove.bind(this));
    this.endListener = this.renderer.listen('document', 'mouseup', this.onEnd.bind(this));
    this.onStart(event);
  }

  private _isDown: boolean = false;
  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent): void {
    console.log(`onTouchStart`);
    this._isMouseEvent = false;
    this._isDown = true;
    // this.moveListener = this.renderer.listen('document', 'touchmove', this.onMove.bind(this));
    // this.endListener = this.renderer.listen('document', 'touchend', this.onEnd.bind(this));
    this.onStart(event);
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent): void {
    console.log(`onTouchMove`);
    if (!this._isDown) return;
    this.onMove(event);
  }

  // @HostListener('touchend', ['$event'])
  // onTouchEnd(event: TouchEvent): void {
  //   if (!this._isDown) return;
  //   this.onEnd();
  // }

  onStart(event: MouseEvent | TouchEvent): void {
    this._shouldClose = false;
    this._startTime = Date.now();
    this.startY = this.getYPosition(event);
    this.lastY = this.startY;
    this.maximumDistance = 0;
    this.lastTime = Date.now();
    this.disableTextSelection();

    this._isPullDownHandle = event.target === this.pullDownHandleRef?.nativeElement;
    this.renderer.addClass(this.el.nativeElement, '!duration-0');
    this.renderer.addClass(document.body, 'body-grabbing');

  }

  private _shouldClose: boolean = false;
  private _currentVelocity: number = 0;
  private _onEndTimeout: any;
  onMove(event: MouseEvent | TouchEvent): void {
    if (!!this._onEndTimeout) {
      clearTimeout(this._onEndTimeout);
      this._onEndTimeout = null;
    }

    if (this.startY === null || this.lastTime === null) return;

    const isAtTop: boolean = this.isAtTop();
    // console.log(`isAtTop: ${isAtTop}\n\tisMouseEvent: ${this._isMouseEvent}\n\tisPullDownHandle: ${this._isPullDownHandle}`);
    if (!this._isMouseEvent && !this._isPullDownHandle && !isAtTop) {
    // if (!this._isPullDownHandle && !isAtTop) {
      return;
    }

    const currentY = this.getYPosition(event);
    const totalDistanceMoved = currentY - this.startY;
    this.maximumDistance = Math.max(this.maximumDistance, Math.abs(totalDistanceMoved));
    this.offsetY.emit(totalDistanceMoved);
    if (totalDistanceMoved > 0) {
      const elm = this.el.nativeElement;
      if (!!elm) {
        this.renderer.setStyle(elm, 'transform', `translateY(${totalDistanceMoved}px)`);
      }
    }

    const currentTime = Date.now();
    const velocity = (currentY - this.lastY) / (currentTime - this.lastTime);
    const movementPercentage = totalDistanceMoved / this._windowHeight;
    this._currentVelocity = velocity;

    // Detect movement back up, indicating that the user is no longer trying to close the modal
    if (totalDistanceMoved < (this.maximumDistance * 0.75)) {
      this.maximumDistance = Math.abs(totalDistanceMoved) * 1.1;
      this._shouldClose = false;
    }
    else if (totalDistanceMoved > this.maximumDistance * 0.95 && movementPercentage > this.closingThresholdPercentage) {
      this._shouldClose = true;
    }
    else if (totalDistanceMoved > this.maximumDistance * 0.95 && totalDistanceMoved > this.closingThreshold) {
      this._shouldClose = true;
    }
    else if (velocity > this.velocityThreshold) {
      this._shouldClose = true;
    }
    else if (velocity < -1 * this.velocityThreshold) {
      this._shouldClose = false;
    }
    // else {
    //   this._shouldClose = false;
    // }

    this.lastY = currentY;
    this.lastTime = currentTime;

    this._onEndTimeout = setTimeout(() => {
      this.onEnd();
    }, 100);
  }

  cleanup(timeout: number = 1) {
    console.log(`cleanup: ${timeout}`);
    const elm = this.el?.nativeElement;
    setTimeout(() => {
      this.renderer.setStyle(elm, 'transition', 'transform 325ms ease');
      this.renderer.setStyle(elm, 'transform', `translateY(0px)`);
      setTimeout(() => {
        this.renderer.removeStyle(elm, 'transform');
        this.renderer.removeStyle(elm, 'transition');
      }, 325);
    }, timeout);
    this.renderer.removeClass(this.el.nativeElement, '!duration-0');
    this.renderer.removeClass(document.body, 'body-grabbing');
    this.removeListeners();
    this.enableTextSelection();
  }

  onEnd(): void {
    // Calculate the duration based on velocity (minimum and maximum duration can be set)
    if (this._shouldClose) {
      const maxVelocity = Math.min(2.5, this._currentVelocity);
      let duration = (2.5 / Math.abs(maxVelocity)) * 100;
      duration = Math.min(625, duration);
      this.close.emit(duration);
    }
    this.cleanup(!this._shouldClose ? 1 : 750);
  }

  private getYPosition(e: MouseEvent | TouchEvent): number {
    return e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;
  }

  private isAtTop(): boolean {
    // You'll need to adjust this to target your scrollable content
    // const scrollableContent = this.el.nativeElement.querySelector('.scrollable-content');
    // return scrollableContent.scrollTop === 0;
    const elm = this.el?.nativeElement;
    if (!!elm) {
      let isScrollingElement = this.isElementScrolled(elm);
      return !isScrollingElement;
    }
    return true;
  }

  private isElementScrolled(element): boolean {
    if (!element) return false;
    if (element.scrollTop > 0) return true;
    for(let i = 0; i < element.childNodes.length; i++) {
      if (this.isElementScrolled(element.childNodes[i])) {
        return true;
      }
    }
    return false;
  }

  private removeListeners(): void {
    if (this.moveListener) {
      this.moveListener();
      this.moveListener = null;
    }
    if (this.endListener) {
      this.endListener();
      this.endListener = null;
    }
    this.startY = null;
    this.lastY = null;
    this.lastTime = null;
  }

  private disableTextSelection(): void {
    this.renderer.setStyle(document.body, 'user-select', 'none');
  }

  private enableTextSelection(): void {
    this.renderer.removeStyle(document.body, 'user-select');
  }

  private animateBack(): void {
    const elm = this.el?.nativeElement;
    if (!!elm) {
      this.renderer.setStyle(elm, 'transition', 'transform 325ms ease');
      this.renderer.setStyle(elm, 'transform', 'translateY(0px)');
      setTimeout(() => {
        this.renderer.removeStyle(elm, 'transform');
        this.renderer.removeStyle(elm, 'transition');
      }, 325);
    }
  }

}
