import { Component, OnDestroy, OnInit } from '@angular/core';
import { VenueService } from '../../../venue.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NavControlService } from '../../nav/nav-control.service';
import { Merchant, Venue, VenueAddress, VenueOperator } from '../../../model/venue.model';
import { User } from '../../../model/user.model';
import { faAdd, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import { FetchState } from 'src/app/app.module';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { MerchantService } from 'src/app/merchant.service';

@Component({
  selector: 'app-venue-details',
  templateUrl: './venue-details.component.html',
  styleUrls: ['./venue-details.component.css']
})
export class VenueDetailsComponent implements OnInit, OnDestroy {

  get venue(): Venue {
    return this.navControlService.selectedVenue;
  }
  merchant: Merchant | null;
  venueImageUrl: string;
  streetAddress: string;
  viewBusinessOpen: boolean = false;
  addBusinessOpen: boolean = false;
  isCharity: boolean = false;


  faTrash = faTrash;
  faEdit = faEdit;
  faAdd = faAdd;
  FaUser = faUser;
  FetchState = FetchState;

  constructor(
    protected readonly venueService: VenueService,
    protected readonly merchantService: MerchantService,
    protected readonly navControlService: NavControlService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
  }

  venueStateById: FetchState;

  ngOnInit(): void {
    this.route.params.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (params) => {
        const venueId = params['venueId'];
        this.getVenueDetails(venueId);
      }
    });

    this.venueService.stateById$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (state) => {
        this.venueStateById = state;
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToAchAccount() {
    if (this.navControlService.selectedVenue?.id) {
      this.navControlService.goToVenueBank(this.navControlService.selectedVenue?.id);
    }
  }

  goToMerchant() {
    if (this.navControlService.selectedVenue?.id) {
      this.navControlService.goToVenueMerchant(this.navControlService.selectedVenue?.id);
    }
  }

  async getVenueDetails(venueId: string) {
    const venue = await this.venueService.fetchById(venueId);
    this.navControlService.selectVenue(venue, false);
    this.merchant = await this.merchantService.fetchMerchant(venueId);
  }

  toggleBusinessListPopup() {
    // this.venueOffers = [];
    this.viewBusinessOpen = !this.viewBusinessOpen;
  }

  toggleAddBusinessPopup() {
    // this.venueOffers = [];
    this.viewBusinessOpen = false;
    this.addBusinessOpen = !this.addBusinessOpen;
  }

  closeBusinessPopup() {
    this.viewBusinessOpen = false;
  }

  private getVenueStreetAddresses(addresses: any[]): string {
    let street;
    addresses.forEach((address) => {
      street = address.street;
    })
    return street;
  }


  get logoImageUrl(): string | ArrayBuffer {
    return this.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl;
  }

  get bannerImageUrl(): string | ArrayBuffer {
    return this.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'BANNER')?.imageUrl;
  }

  get address(): VenueAddress {
    return this.venue?.venueAddresses[0];
  }

  get addressLine1(): string {
    return this.address?.street;
  }

  get addressLine2(): string {
    return this.address?.street2;
  }

  get addressLine3(): string {
    return `${this.address?.city}, ${this.address?.state} ${this.address?.zip}`;
  }

  get addressLine4(): string {
    return this.address?.country;
  }

  get addressLine5(): string {
    return this.address?.phone;
  }

  selectVenue(venue) {
    this.viewBusinessOpen = false;
    this.navControlService.selectedVenue(venue, true);
  }

  isValidVenueImage: boolean = false;
  handleVenueImageError() {
    this.isValidVenueImage = false;
  }

  handleOffersClick() {
    if (!!this.venue && !!this.venue.id) {
      this.router.navigateByUrl(`/venue/${this.venue.id}/offers`);
    }
  }


  showEditVenue: boolean = false;
  editVenue() {
    this.showEditVenue = true;
  }

  closeModal() {
    this.showEditVenue = false;
  }

  showEditMerchant: boolean = false;
  editMerchant() {
    this.showEditMerchant = true;
  }
  closeMerchantModal() {
    this.showEditMerchant = false;
  }

  showEditAchAccount: boolean = false;
  editAchAccount() {
    this.showEditAchAccount = true;
  }
  closeAchAccountModal() {
    this.showEditAchAccount = false;
  }

  handleClickOutsideModal() {
    this.closeModal();
    this.closeMerchantModal();
    this.closeAchAccountModal();
  }



  get venueOperatorUsers(): User[] {
    return this.venue?.venueOperators?.map(ao => ao.user) ?? [];
  }

  async inviteVenueOperator(user: User): Promise<void> {
    await this.venueService.inviteVenueOperator(this.venue, user.id)
  }

  canRemoveVenueOperator(venueOperator: VenueOperator): boolean {
    // Can't remove if this is the only operator or if the current user is the operator
    return !this.removingVenueOperator && this.venue?.venueOperators?.length > 1 && venueOperator?.user?.id !== this.navControlService?.user?.id;
  }

  removingVenueOperator: VenueOperator;

  async removeVenueOperator(venueOperator: VenueOperator): Promise<void> {
    if (!this.canRemoveVenueOperator(venueOperator)) {
      return;
    }
    this.removingVenueOperator = venueOperator;
    await this.venueService.removeVenueOperator(this.venue, venueOperator.id)
  }

}
