import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faGifts, faHandHoldingHeart, faHeart, faLocationDot, faStar, faClock } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { FetchState } from 'src/app/app.module';
import { Entity } from 'src/app/model/entity.model';
import { OfferFilter } from 'src/app/offers.service';

@Component({
  selector: 'app-offer-filter',
  templateUrl: './offer-filter.component.html',
  styleUrls: ['./offer-filter.component.css']
})
export class OfferFilterComponent {
  @Input() filter: OfferFilter | Entity;
  @Input() selectedFilters: Array<OfferFilter | string> = [];
  @Input() fetchState: FetchState = FetchState.GOOD;

  @Output() filterSelected: EventEmitter<OfferFilter | string> = new EventEmitter<OfferFilter | string>();

  FetchState = FetchState;

  constructor(
    private readonly translate: TranslateService,
  ) { }

  selectFilter(filter: OfferFilter | Entity) {
    if (filter instanceof Entity) {
      this.filterSelected.emit(filter.id);
    }
    else {
      this.filterSelected.emit(filter);
    }
  }

  isFilterSelected(filter: OfferFilter | Entity): boolean {
    return filter instanceof Entity ? this.selectedFilters?.indexOf(filter.id) >= 0 : this.selectedFilters?.indexOf(filter) >= 0;
  }

  getFilterIcon(filter: OfferFilter): any {
    switch (filter) {
      case OfferFilter.ALL:
        return faGifts;
      case OfferFilter.NEAR_ME:
        return faLocationDot;
      case OfferFilter.CLAIMED:
        return faHeart;
      case OfferFilter.RECENT:
        return faClock;
      case OfferFilter.NEW:
        return faStar;
      case OfferFilter.CHARITY:
        return faHandHoldingHeart;
      default:
        return '';
    }
  }

  translateFilter(filter: OfferFilter | Entity): Observable<string> {
    switch (filter) {
      case OfferFilter.ALL:
        return this.translate.get('ALL');
      case OfferFilter.NEAR_ME:
        return this.translate.get('NEAR_ME');
      case OfferFilter.CLAIMED:
        return this.translate.get('CLAIMED');
      case OfferFilter.RECENT:
        return this.translate.get('RECENT');
      case OfferFilter.NEW:
        return this.translate.get('NEW');
      case OfferFilter.CHARITY:
        return this.translate.get('FUNDRAISERS');
      default:
        return of(filter instanceof Entity ? (filter as any).name : '');
    }
  }


}
