import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

export class JWTTokenService {

  jwtToken: string;
  decodedToken: { [key: string]: string };

  private static _instance: JWTTokenService;
  static get instance(): JWTTokenService {
    if (!JWTTokenService._instance) {
      JWTTokenService._instance = new JWTTokenService();
    }
    return JWTTokenService._instance;
  }

  private constructor() {}

  getToken(): string {
    return this.jwtToken;
  }

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
    }
  }

  decodeToken() {
    if (this.jwtToken) {
      const _t = jwt_decode as any;
      this.decodedToken = _t(this.jwtToken);
    }
  }

  getDecodeToken() {
    const _t = jwt_decode as any;
    return _t(this.jwtToken);
  }

  getUserId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['sub'] : null;
  }

  getEmailId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['email'] : null;
  }

  getExpiryTime(): number | null {
    this.decodeToken();
    return this.decodedToken ? parseInt(this.decodedToken['exp']) : null;
  }

  isTokenExpired(): boolean {
    const expiryTime: number = this.getExpiryTime();
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return true;
    }
  }
}
