import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, debounceTime, takeUntil, throttleTime, throwError } from 'rxjs';
import { SearchResult, SearchResults } from 'src/app/model/search_results.model';
import { SearchService, SearchType } from 'src/app/services/search.service';
import { NavControlService } from '../nav/nav-control.service';
import { FormControl } from '@angular/forms';
import { Charity } from 'src/app/model/charity.model';
import { PatronVenue } from 'src/app/model/patron-offer.model';
import { Venue } from 'src/app/model/venue.model';
import { FetchState } from 'src/app/app.module';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
  searchControl = new FormControl();
  @ViewChild('searchInput') searchInput: ElementRef;


  constructor(
    private readonly searchService: SearchService,
    private readonly navControlService: NavControlService,
  ) { }

  searchResults: SearchResults = null;

  get charityResults(): SearchResult[] {
    return this.searchResults?.results?.filter((result) => result.type === 'Charity')?.sort((a, b) => b.score - a.score);
  }

  get offerResults(): SearchResult[] {
    return this.searchResults?.results?.filter((result) => result.type === 'Offer')?.sort((a, b) => b.score - a.score);
  }

  get venueResults(): SearchResult[] {
    return this.searchResults?.results?.filter((result) => result.type === 'Venue')?.sort((a, b) => b.score - a.score);
  }

  get sortedSearchResults(): SearchResult[] {
    // Sort by Charity, Offer, Venue, then by score
    return this.searchResults?.results.sort((a, b) => {
      if (a.type === b.type) {
        return b.score - a.score;
      }
      else {
        return a.type === 'Charity' ? -1 : a.type === 'Offer' ? 1 : 0;
      }
    });
  }


  private _showingSearchResults: boolean = false;
  private _isShowingSearchResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSearchResults() {
    this._isShowingSearchResults$.next(true);
  }
  hideSearchResults() {
    this._isShowingSearchResults$.next(false);
  }
  toggleSearchResults() {
    this._isShowingSearchResults$.next(!this._isShowingSearchResults$.value);
  }
  get showingSearchResults(): boolean {
    return this._showingSearchResults;
  }

  private _searchQuery$: Subject<string> = new Subject<string>();
  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(300)
    )
    .subscribe({
      next: (query) => {
        this.search(query);
      }
    });

    this._isShowingSearchResults$.pipe(
      takeUntil(this.destroy$),
      throttleTime(1)
    )
    .subscribe({
      next: (showingSearchResults) => {
        this._showingSearchResults = showingSearchResults;
      }
    });
  }

  ngAfterViewInit(): void {
    this.navControlService.setSearchElement(this.searchInput);
  }


  handleSearchFocus(event: FocusEvent) {
    if (!!this.searchResults && this.searchResults.total > 0) {
      this.showSearchResults();
    }
  }

  handleSearchChange(event: any) {
    this._searchQuery$.next(event.target.value);
  }

  state$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);

  currentPage: number = 0;
  awaitingSearchResults: boolean;
  searchTerm: string = '';
  async search(query: string, forceRefresh: boolean = false) {
    this.searchTerm = query;

    this.awaitingSearchResults = true;
    this.searchService.querySearch(query, this.currentPage, 25, {useCurrentLocation: true, distance: 250.0, searchType: SearchType.ALL})
    .subscribe({
      next: (searchResults) => {
        this.state$.next(FetchState.GOOD);

        if (this.awaitingSearchResults) {
          this.showSearchResults();
        }
        this.awaitingSearchResults = false;
        this.searchResults = searchResults;

        return searchResults;
      },
      error: (error) => {
        console.error('search error', error);
        this.state$.next(FetchState.ERROR);

        if (this.awaitingSearchResults) {
          this.showSearchResults();
        }
        this.awaitingSearchResults = false;
        console.error('search error', error);
        this.searchResults = new SearchResults({
          total: 0,
          page: 0,
          maxScore: 0,
          moreResults: false,
          error: error.message,
          results: []
        });

        return throwError(error);
      }
    });
  }

  handleSearchResultClick(searchResult: SearchResult) {
    console.log('handleSearchResultClick', searchResult);

    if (!searchResult) return;

    this.hideSearchResults();

    if ((searchResult.entity instanceof Charity) || (searchResult.entity instanceof PatronVenue) || (searchResult.entity instanceof Venue)) {
      this.navControlService.goToPivotEntityOffers(searchResult.entity.id);
    }
    // If this is an Offer, then go to the Offer page
    else if (searchResult.type === 'Offer') {
      this.navControlService.goToOffer(searchResult.id);
    }
  }

}
