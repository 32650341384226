import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GoogleService } from '../google.service';

@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.css']
})
export class GoogleLoginComponent implements OnInit, AfterViewInit {

  @Input() textType: 'signin_with' | 'continue_with' | 'signup_with' = 'continue_with';

  // private static GOOGLE_CLIENT_ID = '82300956489-jdehvfkjtuf1pu0i1q6lo005n2derud9.apps.googleusercontent.com';
  private static GOOGLE_CLIENT_ID = '613362574245-ngt8q8m30sot29rqrusmdvcj22c4ccst.apps.googleusercontent.com';

  get GOOGLE_CLIENT_ID(): string {
    return GoogleLoginComponent.GOOGLE_CLIENT_ID;
  }

  @ViewChild('googleButtonDiv', { static: true }) googleButtonDiv: ElementRef;
  @ViewChild('googleContainer', { static: true }) googleContainer: ElementRef;
  @ViewChild('googleSignIn', { static: true }) googleSignIn: ElementRef;

  get containerWidth(): number {
    return this.googleContainer?.nativeElement?.offsetWidth ?? this.googleButtonDiv?.nativeElement?.offsetWidth ?? 200;
  }

  constructor(
    private renderer: Renderer2,
    private readonly googleService: GoogleService,
  ) {
  }

  get redirectUrl(): string {
    return `${window.location.origin}/google/login`;
  }

  ngOnInit(): void {
    // Define the global callback function
    window['handleCredentialResponse'] = this.handleCredentialResponse.bind(this);
  }

  private adjustGoogleButtonWidth() {
    try {
      if (this.googleSignIn?.nativeElement?.attributes) {
        const width = this.containerWidth;
        this.googleSignIn.nativeElement.setAttribute('data-width', `${width}`);
      }
    }
    catch (err) {
      console.error('Error setting data-width', err);
    }
  }

  async ngAfterViewInit() {
    this.adjustGoogleButtonWidth();

    // // const theme = 'filled_black'; // colorSchemeQuery.matches ? 'outline' : 'filled_black';
    const theme = 'outline'; // colorSchemeQuery.matches ? 'outline' : 'filled_black';
    this.loadScript('https://accounts.google.com/gsi/client').then((d: any) => {
      const google = (window as any).google;

      if (google?.accounts?.id) {
        google.accounts.id.initialize({
          client_id: GoogleLoginComponent.GOOGLE_CLIENT_ID,
          // ux_mode: 'redirect',
          auto_select: true,
          itp_support: true,
          callback: this.handleCredentialResponse.bind(this),
        });
        google.accounts.id.renderButton(
          this.googleButtonDiv.nativeElement,  // HTML element
          {
            auto_select: true,
            itp_support: true,
            theme: theme,
            text: this.textType, // "signup_with", // "signin_with", "continue_with"
            logo_alignment: "center",
            width: `241px`,
            size: "large",
            shape: "rectangular",
            type: "standard"
          }  // customization attributes
        );

        // Uncomment the following lines to also display the One Tap dialog
        // google.accounts.id.prompt();
      }
      else {
        // TODO: Do something to show that Google login is not available
        console.warn('Google login is not available');
      }
    });
  }


  private updateGoogleSignInButton(isDarkMode) {
  }

  async handleCredentialResponse(response: any) {
    this.googleService.handleCredentialResponse(response);
  }

  private scriptLoaded: boolean = false;
  private loadScript(url: string): Promise<void> {
    const that = this;
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = url;

      script.onload = () => {
        // Script loaded successfully
        that.scriptLoaded = true;
        resolve();
      };

      script.onerror = (error) => {
        // Script loading failed
        that.scriptLoaded = false;
        reject(error);
      };

      this.renderer.appendChild(document.body, script);
    });
  }

}
