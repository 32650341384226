import { Component, Input } from "@angular/core";
import { FidelService } from "src/app/fidel.service";
import { FetchState } from "src/app/app.module";
import { UserPaymentMethod } from "src/app/model/user_payment_method.model";

@Component({
  selector: 'app-card-row',
  templateUrl: './card-row.component.html',
  styleUrls: ['./card-row.component.css'],
})
export class CardRowComponent {

  @Input() card: UserPaymentMethod;

  FetchState = FetchState;

  constructor(
    private readonly fidelService: FidelService,
  ) {}

  get cardDisplay(): string {
    let cardDisplay = '';
    if (this.card) {
      if (this.card.scheme) {
        if (!this.card.lastNumbers) {
          cardDisplay = this.card.scheme;
        }
        else {
          cardDisplay = this.card.scheme + ' - ' + this.card.lastNumbers;
        }
      }
      else {
        if (!this.card.firstNumbers || !this.card.lastNumbers) {
          if (this.card.firstNumbers) {
            cardDisplay = this.card.firstNumbers + '...';
          }
          else if (this.card.lastNumbers) {
            cardDisplay = '...' + this.card.lastNumbers;
          }
          else {
            cardDisplay = '...';
          }
        }
        else {
          cardDisplay = this.card.firstNumbers + '...' + this.card.lastNumbers;
        }
      }
    }
    else {
      cardDisplay = 'No card';
    }
    return cardDisplay;
  }

  get cardNumbers(): string {
    let cardNumbers = `${this.card?.firstNumbers || ''}...${this.card?.lastNumbers || ''}`;
    return cardNumbers;
  }

  get isLive(): boolean {
    return !!this.card?.live && !this.card.archivedDate;
  }

  get hasDateExpires(): boolean {
    return !!this.card && !!this.card.expDate && this.card.expDate.getTime() > 0;
  }

  get dateExpires(): string {
    let dateExpires = '';
    if (this.card?.expDate) {
      dateExpires = "Expires: " + this.card.expDate.toLocaleDateString();
    }
    return dateExpires;
  }

  get dateAdded(): string {
    let dateAdded = '';
    if (this.card?.created) {
      dateAdded = "Added: " + this.card.created.toLocaleDateString();
    }
    if (this.card?.archivedDate) {
      dateAdded = "Archived: " + this.card.archivedDate.toLocaleDateString();
    }

    return dateAdded;
  }

  get hasDateAdded(): boolean {
    return !!this.card && !!this.card.created && this.card.created.getTime() > 0 || true;
  }

  get isArchived(): boolean {
    return !!this.card && !!this.card.archivedDate && this.card.archivedDate.getTime() > 0;
  }

  removeCardState: FetchState = FetchState.NONE;
  async removeUserPaymentMethod() {
    const id = this.card.id;
    if (id) {
      this.removeCardState = FetchState.LOADING;
      await this.fidelService.removeUserPaymentMethod(id);
      this.removeCardState = FetchState.LOADED_ALL;
    }
    else {
      this.removeCardState = FetchState.NONE;
    }
  }
}
