import { Affiliate } from "./affiliate.model";
import { Entity } from "./entity.model";


export class AffiliateVenue extends Entity {

  constructor() {
    super();
  }

  disabledDate?: Date;
  endDate?: Date;
  intPlatformPercentage: number;
  intPlatformAmount: number;
  intDurationDays: number;
  returnCurrency: string;
  intMinSpend: number;
  intMaxReward: number;
  venue?: {
    id: string;
    name: string;
  };

  static override parseResponse(details: any, result?: AffiliateVenue): AffiliateVenue {
    if (!details) return null;
    if (!result) result = new AffiliateVenue();

    Entity.parseResponse(details, result);

    if (details.disabledDate) {
      result.disabledDate = new Date(details.disabledDate);
    }
    if (details.endDate) {
      result.endDate = new Date(details.endDate);
    }
    result.intPlatformPercentage = details.intPlatformPercentage;
    result.intPlatformAmount = details.intPlatformAmount;
    result.intDurationDays = details.intDurationDays;
    result.returnCurrency = details.returnCurrency;
    result.intMinSpend = details.intMinSpend;
    result.intMaxReward = details.intMaxReward;
    if (details.venue) {
      result.venue = {
        id: details.venue.id,
        name: details.venue.name,
      }
    }
    else {
      result.venue = {
        id: details.venueId,
        name: details.venueName,
      }
    }

    return result;
  }
}
