import { gql } from "apollo-angular";


export const AchAccountDetails = gql`
fragment AchAccountDetails on AchAccount {
  id
  sourceId
  bankAccountType
  bankName
  accountName
  accountStatus
  accountType
}
`;

export const DwollaServiceCustomerDetails = gql`
fragment DwollaServiceCustomerDetails on DwollaServiceCustomer {
  id
  created
  email
  businessName
  firstName
  lastName
  status
  type
}
`;
