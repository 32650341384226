


<div class="bg-white p-8 relative flex flex-col h-full w-full min-h-max">
  <div class="flex flex-row justify-between">
    <h2 class="text-2xl mb-6 text-zinc-900">Select Reward Offer</h2>
  </div>
  <!-- <div *ngIf="venue?.archivedDate" class="text-red-500 mb-6">
    Archived: {{ venue?.archivedDate | date }}
  </div> -->

    <div class="offers-container">
      <!-- <div class="flex flex-row space-x-4 items-end flex-wrap"> -->
        <app-offer-tile2 class="offer-tile" *ngFor="let offer of offers" [actionable]="false" [offerData]="{offer}"
        [ngClass]="{'selected-offer-tile': selectedOffer?.id === offer?.id}"
        (click)="selectOffer(offer)"></app-offer-tile2>
      <!-- </div> -->
    </div>

    <!-- <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Platform Percentage</label>
      <input formControlName="intPlatformPercentage" type="number" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Platform Amount</label>
      <input formControlName="intPlatformAmount" type="number" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Duration (Days)</label>
      <input formControlName="intDurationDays" type="number" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div> -->


    <!-- Show Error Here -->
    <div *ngIf="saveState === FetchState.ERROR" class="text-red-500 mt-6">
      Error saving, please try again.
    </div>

  <!-- Loading Cover -->
  <div *ngIf="saveState === FetchState.LOADING || fetchState === FetchState.LOADING || stateUpdateRewardOffer === FetchState.LOADING" class="flex justify-center items-center h-full absolute inset-0 bg-gray-100 bg-opacity-50">
    <div class="loader-wrapper flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  </div>
</div>
