import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FetchState } from '../app.module';
import { VenueOffersService } from '../venue-offers.service';
import { VenueOffer } from '../model/venue_offer.model';
import { Subject, takeUntil } from 'rxjs';
import { NavControlService } from '../components/nav/nav-control.service';
import { LoginState } from '../user-auth.service';
import { Merchant, Venue } from '../model/venue.model';
import { faPlus, faStar as faIsPrimary } from '@fortawesome/free-solid-svg-icons';
import { BaseImage } from '../model/image.model';
import { PatronOffer } from '../model/patron-offer.model';
import { ModalWidth } from '../components/modal/modal.component';
import { faStar as faMakePrimary } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-venue-offers',
  templateUrl: './venue-offers.component.html',
  styleUrls: ['./venue-offers.component.css']
})
export class VenueOffersComponent implements OnInit, OnDestroy {
  private _venue: Venue;
  @Input()
  set venue(value: Venue) {
    this._venue = value;

    if (!!value?.id && this.navControlService.userState === LoginState.LOGGED_IN) {
      this.init();
    }
  }
  get venue(): Venue {
    return this._venue;
  }
  @Input() merchant: Merchant

  faAdd = faPlus;

  ModalWidth = ModalWidth;

  error: any;
  venueOffers: VenueOffer[];
  offersCount: number = 0;
  venuesCount: number = 0;
  venueOffersCount: number = 0;
  user: any = null;
  isValidOfferImage: boolean = false;

  FetchState = FetchState;
  faMakePrimary = faMakePrimary;
  faIsPrimary = faIsPrimary;

  showNewOffer: boolean = false;
  selectedVenueOffer: VenueOffer;
  isCharity: boolean = false;

  currentIndex: number = 1;

  units: any[] = [
    { value: 'percent', viewValue: '%' },
    { value: 'money', viewValue: '$' },
  ];

  constructor(
    public venueOffersService: VenueOffersService,
    private readonly navControlService: NavControlService,
  ) {
  }

  ngOnInit(): void {
    this.venueOffersService.venueOffers$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (venueOffers) => {
        this.venueOffers = venueOffers;
        this.venueOffersCount = venueOffers.length;
      }
    });

    // Fetch the venue offers once the user is logged in
    this.navControlService.userState$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (state) => {
        if (state === LoginState.LOGGED_IN && !!this.venue?.id) {
          this.init();
        }
      }
    });
  }

  private _initialized: boolean = false;
  private init() {
    if (this._initialized) return;

    if (!!this._venue?.id && this.navControlService.userState === LoginState.LOGGED_IN) {
      this._initialized = true;
      this.venueOffersService.fetchOffers(this._venue.id, true);
    }
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal() {
    this.showNewOffer = false;
  }

  offerImageUrl(offer: VenueOffer): string | ArrayBuffer {
    let result = offer?.offerImage?.imageUrl;
    if (!!result) return result;
    return '/assets/images/offer_placeholder.png';
  }

  getOfferDisplayName(offer): string {
    if (offer.name) return offer.name;
    if (offer.internalName) return offer.internalName;
    if (offer.description) return offer.description;
    return "Offer";
  }

  getVenueStreetAddresses(addresses: any[]) {
    let street;
    addresses.forEach((address) => {
      street = address.street;
    })
    return street;
  }

  selectItem(index: number) {
    if (index == 1) {
      this.isCharity = false;
    }
    if (index == 2) {
      this.isCharity = true;
    }
    this.currentIndex = index;
  }

  get canCreateOffers(): boolean {
    // TODO: At some point we will want to allow users to create offers only with a merchant account
    return true;
    // return !!this.venue?.achAccount && !!this.merchant?.isActive;
  }

  toggleAddOfferPopup(offer: VenueOffer = null, force: boolean = false) {
    if (!force && !this.canCreateOffers) return;
    this.showNewOffer = !this.showNewOffer;
    if (this.showNewOffer) {
      if (!offer) {
        offer = new VenueOffer();
        offer.offerImage = new BaseImage();
        offer.offerImage.type = 'banner';
      }
      this.selectedVenueOffer = offer;
    }
  }

  showOffer(offer) {
    this.selectedVenueOffer = offer;
    this.toggleAddOfferPopup(offer, true);
  }

  patronOffer(offer: VenueOffer): PatronOffer {
    // if (!this.parentForm.value || !this.venue) return new PatronOffer();
    let offerClone: VenueOffer = new VenueOffer();
    // VenueOffer.parseForm(this.parentForm, offerClone);
    // return VenueOffer.toPatronOffer(offerClone, this.venue, this.claimable, this.redeemable, this.offerImagePreviewUrl);
    return VenueOffer.toPatronOffer(offer, this.venue);
  }

  stateUpsertOffer: FetchState = FetchState.NONE;
  currentUpsertOffer: VenueOffer;
  async makePrimary(event: any, offer: VenueOffer) {
    event?.stopImmediatePropagation();
    let initialPrimary = offer.isVenueDefault;
    offer.isVenueDefault = true;
    this.currentUpsertOffer = offer;

    try {
      this.stateUpsertOffer = FetchState.LOADING;
      let result = await this.venueOffersService.upsertOffer(offer);

      if (!!result) {
        // Set any other offers to not be primary
        this.venueOffers.forEach((venueOffer) => {
          if (venueOffer.id !== offer.id) {
            venueOffer.isVenueDefault = false;
          }
        });
      }

      this.stateUpsertOffer = FetchState.LOADED_ALL;
      this.currentUpsertOffer = null;
    }
    catch (error) {
      this.stateUpsertOffer = FetchState.ERROR;
      offer.isVenueDefault = initialPrimary;
      this.currentUpsertOffer = null;
    }

  }
}
