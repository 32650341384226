import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VenueOffer } from 'src/app/model/venue_offer.model';
import { VenueOffersService } from 'src/app/venue-offers.service';

@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.css']
})
export class EditOfferComponent {
  @Input()
  offer: VenueOffer;

  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly venueOfferService: VenueOffersService
  ) {}

  closeModal() {
    this.close.emit();
  }
}
