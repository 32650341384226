import { gql } from "apollo-angular";
import { PatronOfferDetails } from "../fragments/patron_offer.fragment.graphql";
import { VenueOfferDetails } from "../fragments/venue_offers.fragment.graphql";

export const PatronOfferQuery = gql`
${PatronOfferDetails}
query PatronOffer($id: String!) {
  offer(id: $id) {
    ...PatronOfferDetails
  }
}
`;

export const PatronOffersQuery = gql`
query PatronOffers($id: String!, $search: [SearchInput!]! = [], $sort: PageAndSortInput, $latitude: Float, $longitude: Float, $radiusKm: Float) {
  offers(id: $id, search: $search, sort: $sort, latitude: $latitude, longitude: $longitude, radiusKm: $radiusKm) {
    ...PatronOfferDetails
  }
}
${PatronOfferDetails}
`;

export const VenueOffersQuery = gql`
query VenueOffers($id: String! , $search: [SearchInput!]! = [], $sort: PageAndSortInput) {
    offers(id: $id, search: $search, sort: $sort) {
      ...VenueOfferDetails
    }
  }
${VenueOfferDetails}
`;

export const VenueOfferQuery = gql`
query VenueOffer($id: String!) {
  offer(id: $id) {
    ...VenueOfferDetails
  }
}
${VenueOfferDetails}
`;
