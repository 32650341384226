import { Entity } from "./entity.model";

export enum PaymentScheme {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
}

export function parsePaymentScheme(scheme: string): PaymentScheme {
  const schemeUpper = scheme.toUpperCase();
  if (schemeUpper === 'VISA') {
    return PaymentScheme.VISA;
  }
  else if (schemeUpper === 'MASTERCARD' || schemeUpper === 'MC') {
    return PaymentScheme.MASTERCARD;
  }
  else if (schemeUpper === 'AMERICAN EXPRESS' || schemeUpper === 'AMEX') {
    return PaymentScheme.AMEX;
  }
  return null;
}

export class UserPaymentMethod extends Entity {
  scheme: PaymentScheme;
  accountId: string;
  countryCode: string;
  created: Date;
  expDate: Date;
  expMonth: number;
  expYear: number;
  firstNumbers: string;
  lastNumbers: string;
  live: boolean;
  type: string;
  updated: Date;
  archivedDate?: Date;

  static override parseResponse(details, result?: UserPaymentMethod): UserPaymentMethod {
    if (!details) return null;
    if (!result) result = new UserPaymentMethod();

    result.id = details.id;
    result.scheme = parsePaymentScheme(details.scheme);
    result.accountId = details.accountId;
    result.countryCode = details.countryCode;
    if (details.created) {
      result.created = new Date(details.created);
    }
    if (details.expDate) {
      result.expDate = new Date(details.expDate);
    }
    result.expMonth = details.expMonth;
    result.expYear = details.expYear;
    result.firstNumbers = details.firstNumbers;
    result.lastNumbers = details.lastNumbers;
    result.live = details.live;
    result.type = details.type;
    if (details.updated) {
      result.updated = new Date(details.updated);
    }
    if (details.archivedDate) {
      if (parseInt(details.archivedDate) > 0) {
        result.archivedDate = new Date(parseInt(details.archivedDate));
      }
      else {
        result.archivedDate = new Date(details.archivedDate);
      }
    }

    return result;
  }

}


