import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VenueService } from 'src/app/venue.service';
import { NavControlService } from '../../nav/nav-control.service';
import { Venue, VenueOperator } from 'src/app/model/venue.model';
import { faAdd, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import { FetchState } from 'src/app/app.module';
import { User } from 'src/app/model/user.model';
import { Subject, takeUntil } from 'rxjs';
import { UserInvite } from 'src/app/model/invite.model';
import { InviteService } from 'src/app/services/invite.service';

@Component({
  selector: 'app-venue-operators',
  templateUrl: './venue-operators.component.html',
  styleUrls: ['./venue-operators.component.css']
})
export class VenueOperatorsComponent implements OnInit, OnDestroy {

  FaUser = faUser;
  faTrash = faTrash;
  faAdd = faAdd;
  FetchState = FetchState;

  @Input()
  venue: Venue

  constructor(
    protected readonly venueService: VenueService,
    private readonly inviteService: InviteService,
    protected readonly navControlService: NavControlService,
  ) {}

  venueStateById: FetchState;

  ngOnInit(): void {
    this.venueService.stateById$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (state) => {
        this.venueStateById = state;
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get venueOperatorUsers(): User[] {
    return this.venue?.venueOperators?.map(ao => ao.user) ?? [];
  }

  async inviteUserSaveHandler(email: string, message?: string): Promise<any> {
    let result = await this.venueService.inviteVenueOperator(this.venue, null, email, message, true);
    return result;
  }

  canRemoveVenueOperator(venueOperator: VenueOperator): boolean {
    // Can't remove if this is the only operator or if the current user is the operator
    return !this.removingVenueOperator && this.venue?.venueOperators?.length > 1 && venueOperator?.user?.id !== this.navControlService?.user?.id;
  }

  removingVenueOperator: VenueOperator;

  async removeVenueOperator(venueOperator: VenueOperator): Promise<void> {
    if (!this.canRemoveVenueOperator(venueOperator)) {
      return;
    }
    this.removingVenueOperator = venueOperator;
    await this.venueService.removeVenueOperator(this.venue, venueOperator.id)
  }

  removingInvite: UserInvite;
  async removeInvite(invite: UserInvite): Promise<void> {
    this.removingInvite = invite;
    try {
      let result = await this.inviteService.removeInvite(invite.id)
      if (!!result) {
        // Remove the invite from the list
        this.venue.invites = this.venue.invites.filter(i => i.id !== invite.id);
      }
    }
    catch (err) {
      console.error(err);
    }
    this.removingInvite = null;
  }

  showSendInvite: boolean = false;

  closeModal(): void {
    this.showSendInvite = false;
  }
}
