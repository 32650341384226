import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FetchState } from '../app.module';
import { LoginState } from '../user-auth.service';
import { Observable, Subject, first, of, takeUntil } from 'rxjs';
import { NavControlService, NavRole } from '../components/nav/nav-control.service';
import { TranslateService } from '@ngx-translate/core';
import { CardLinkEventType, FidelCard } from '../credit-cards/credit-cards.component';
import { faArrowUpFromBracket, faXmark } from '@fortawesome/free-solid-svg-icons';
import { UserOffersService } from '../services/user-offers.service';
import { PatronOffer } from '../model/patron-offer.model';
import { faShareFromSquare } from '@fortawesome/free-regular-svg-icons';
import { AppService } from '../app.service';
import { LoginViewState } from '../auth/login/login.component';

export enum OfferDetailsSheet {
  CreditCards = 'credit-cards',
  GetStarted = 'getStarted',
  OfferClaimed = 'offerClaimed',
}

export class OfferDetailsController {

  private _selectedSheet: OfferDetailsSheet | null = null;

  selectSheet(sheet: OfferDetailsSheet | null) {
    this._selectedSheet = sheet;
  }

  get isShowingSheet(): boolean {
    return !!this._selectedSheet;
  }

  get showCreditCardsSheet(): boolean {
    return this._selectedSheet === OfferDetailsSheet.CreditCards;
  }
  get showOfferClaimedSheet(): boolean {
    return this._selectedSheet === OfferDetailsSheet.OfferClaimed;
  }
}

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.css'],
})
export class OfferDetailsComponent implements OnInit, OnDestroy {
  error: any;

  @Output() scrolling = new EventEmitter<boolean>();

  // UI
  FetchState = FetchState;
  LoginState = LoginState;
  NavRole = NavRole;
  OfferDetailsSheet = OfferDetailsSheet;
  CardLinkEventType = CardLinkEventType;
  FaArriwUpFromBracket = faArrowUpFromBracket;
  FaXmark = faXmark;
  faShare = faShareFromSquare;

  @ViewChild('mainListContainer') mainListContainer: ElementRef;
  @ViewChild('shareUrl') shareUrl: ElementRef;

  controller: OfferDetailsController = new OfferDetailsController();

  constructor(
    private route: ActivatedRoute,
    public readonly navControlService: NavControlService,
    public readonly translate: TranslateService,
    public userOfferService: UserOffersService,
    private readonly appService: AppService,
  ) {
  }

  ngOnInit(): void {
    this.route.params.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (params) => {
        const marketingChannelId = params['offerId'];
        if (!!marketingChannelId) {
          this.userOfferService.marketingChannelId = marketingChannelId;
          this.userOfferService.init();
        }
      }
    });

    this.navControlService.userState$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (userState) => {
        this.userState = userState;
      }
    });

    this.userOfferService.claimedOffer$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (claimedOffer) => {
        this.claimedOffer = claimedOffer;
      }
    });

    this.userOfferService.stateClaim$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (state) => {
        this.stateClaim = state;
      }
    });

    this.userOfferService.stateOffer$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (state) => {
        this.stateOffer = state;
      }
    });

    this.userOfferService.userNeedsToLinkCard$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (needsToLinkCard) => {
        this.userNeedsToLinkCard = needsToLinkCard;
      }
    });

    // When the offer changes, scroll to the top of the page
    this.userOfferService.offer$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (offer) => {
        if (offer && !!this.mainListContainer) {
          this.mainListContainer.nativeElement.scrollTo(0, 0, );
        }
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private _scrollingTimeout: any;
  onScroll(event) {
    if (this._scrollingTimeout) {
      clearTimeout(this._scrollingTimeout);
      this._scrollingTimeout = null;
    }
    this.scrolling.emit(true);
    this._scrollingTimeout = setTimeout(() => {
      this.scrolling.emit(false);
    }, 50);

    event.preventDefault();
  }

  userState: LoginState;
  claimedOffer: boolean = false;
  stateClaim: FetchState = FetchState.NONE;
  stateOffer: FetchState = FetchState.NONE;
  userNeedsToLinkCard: boolean = false;

  hasRole(role: NavRole): boolean {
    return this.navControlService.hasRole(role);
  }

  get pageTitle(): string {
    if (this.userOfferService.isCharityOffer) {
      return `${this.userOfferService.offer?.charityIncentive?.charity?.name} @ ${this.userOfferService.offer?.venue?.name}`;
    }
    else {
      return `${this.userOfferService.offer?.name} @ ${this.userOfferService.offer?.venue?.name}`;
    }
  }

  get intReturnPercentage(): number {
    if (this.userOfferService.isCharityOffer) {
      return Math.round(this.userOfferService.offer?.charityIncentive?.intReturnPercentage ?? 0);
    }
    else {
      return Math.round(this.userOfferService.offer?.patronIncentive?.intReturnPercentage ?? 0);
    }
  }

  get intReturnAmount(): number {
    if (this.userOfferService.isCharityOffer) {
      return Math.round(this.userOfferService.offer?.charityIncentive?.intReturnAmount ?? 0);
    }
    else {
      return Math.round(this.userOfferService.offer?.patronIncentive?.intReturnAmount ?? 0);
    }
  }

  public claimOffer() {
    this.userOfferService.claimOffer();
  }

  get congratulationsMessage(): Observable<string> {
    if (this.userOfferService.activeUserPaymentMethods$?.value?.length === 0) {
      return this.translate.get('CONGRAULATIONS_MESSAGE_LINK_CARD', { venueName: this.userOfferService.offer?.venue?.name ?? '' });
      // return 'To begin earning rewards, link the credit or debit card you will use to track your transactions.';
    }
    else {
      return this.translate.get('CONGRAULATIONS_MESSAGE_USE_CARD', { venueName: this.userOfferService.offer?.venue?.name ?? '', userPaymentsLastDigitsDisplay: this.userOfferService.userPaymentsLastDigitsDisplay });
      // return `To begin earning rewards, use the credit or debit card you have linked to track your transactions: ${this.userPaymentsLastDigitsDisplay}.`;
    }
  }


  goToHome() {
    this.navControlService.goToHome();
  }

  goToPatronCreditCards() {
    this.controller.selectSheet(OfferDetailsSheet.CreditCards);
  }

  showLinkCard: boolean = false;

  howToEarnOpen: boolean = false;

  cardLinkEvent: CardLinkEventType | null;
  showCreditCards() {
    this.showLinkCard = true;
    this.cardLinkEvent = CardLinkEventType.SHOW_LINK_CARD;
  }

  handleCardLinkEvent(event: CardLinkEventType) {
    this.cardLinkEvent = event;
    if (event === CardLinkEventType.CARD_LINKED) {
      this.showLinkCard = false;
    }
    else if (event === CardLinkEventType.CARD_LINK_CANCELLED) {
      this.showLinkCard = false;
    }
    else if (event === CardLinkEventType.CARD_LINK_ERROR) {
      this.showLinkCard = false;
    }
    else if (event === CardLinkEventType.SHOW_LINK_CARD) {
      this.showLinkCard = true;
    }
  }

  cardLinked: FidelCard | null;
  handleCardLinked(card: FidelCard) {
    this.cardLinked = card;
  }

  showGetStartedLogin() {
    this.claimOffer();
    if (this.userState === LoginState.LOGGED_OUT) {
      this.navControlService.showLogin(LoginViewState.REGISTER);
    }
  }

  closeModal() {
    this.showLinkCard = false;
    this.controller.selectSheet(null);
  }

  handleClickOutsideModal() {
    this.closeModal();
  }


  handleOfferClaimedInfo(): Observable<string> {
    if (!this.userOfferService.offer?.venue) return of("");

    const USDollar = new Intl.NumberFormat(`${this.translate.currentLang ?? 'en'}-US`, {
      style: 'currency',
      currency: 'USD'
    })

    if (this.intReturnPercentage) {
      if (this.userOfferService.isCharityOffer) {
        return this.translate.get('PERCENTAGE_OFFER_CLAIMED_INFO_CHARITY', { percentage: this.intReturnPercentage / 100.0, venueName: this.userOfferService.offer.venue.name, charityName: this.userOfferService.offer?.charityIncentive?.charity?.name });
      }
      else {
        return this.translate.get('PERCENTAGE_OFFER_CLAIMED_INFO', { percentage: this.intReturnPercentage / 100.0, venueName: this.userOfferService.offer.venue.name });
      }
    }
    if (this.intReturnAmount) {
      if (this.userOfferService.isCharityOffer) {
        return this.translate.get('AMOUNT_OFFER_CLAIMED_INFO_CHARITY', { amount: USDollar.format(this.intReturnAmount / 100.0), venueName: this.userOfferService.offer.venue.name, charityName: this.userOfferService.offer?.charityIncentive?.charity?.name });
      }
      else {
        return this.translate.get('AMOUNT_OFFER_CLAIMED_INFO', { amount: USDollar.format(this.intReturnAmount / 100.0), venueName: this.userOfferService.offer.venue.name });
      }
    }

    return of ("");
  }

  get offerClaimedCallToActionPostscript(): string {
    if (this.userOfferService.isCharityOffer) {
      return `to participate in this fundraiser.`;
    }
    else {
      return `to earn cash back.`;
    }
  }

  selectOffer(offer: PatronOffer) {
    if (!offer) return;

    // this.navControlService.selectedOfferId = offer.id;
    this.navControlService.goToOffer(offer);
  }

  get webShareApiSupported(): boolean {
    return !!navigator.share;
  }

  get offerQrCode(): string {
    return `${this.appService.baseUrl}/offers/${this.userOfferService.offer?.id}`;
  }

  shareInfo() {
    if (navigator.share) {
      navigator.share({
        title: this.userOfferService.offer?.name ?? 'GetZeals Offer',
        text: 'Check out this offer on GetZeals!',
        url: this.offerQrCode,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      // Fallback
      this.copyLink()
    }
  }

  isShareConfirmationVisible: boolean = false;
  copyLink() {
    // Use updated clipboard API when available
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.offerQrCode);
    }
    else {
      const inputElement = this.shareUrl.nativeElement;
      inputElement.select();
      document.execCommand('copy');
      inputElement.blur(); // Deselect the text
    }
    // alert('Link copied to clipboard!');
    this.isShareConfirmationVisible = true;
    setTimeout(() => {
      this.isShareConfirmationVisible = false;
    }, 1500);
  }

}
