import { Weeks, parseWeeksOfTheMonthInt } from "../utils/enums/weeks.enum";
import { WeekDays, parseDaysOfTheWeekInt } from "../utils/enums/weeks_days.enum";
import { Charity } from "./charity.model";
import { Entity } from "./entity.model";
import { BaseImage } from "./image.model";
import { VenueImage } from "./venue.model";


export class PatronOffer extends Entity {
  name: string;
  description: string;
  finePrint: string;
  redeemStartDate: Date;
  redeemEndDate: Date;
  archivedDate: Date;
  claimStartDate: Date;
  claimEndDate: Date;
  startTimeOfDay: number;
  endTimeOfDay: number;
  daysOfTheWeek: WeekDays[];
  weeksOfTheMonth: Weeks[];
  patronsLimit: number;
  singlePatronLimit: number;
  isVenueDefault: boolean;
  isUniversal: boolean;
  showInApp: boolean;
  claim?: PatronClaim;
  platformIncentive: PlatformIncentive;
  venueIncentive: VenueIncentive;
  patronIncentive: PatronIncentive;
  charityIncentive: CharityIncentive;
  venue: PatronVenue;
  venueUrl: string;
  offerImage: BaseImage;

  static override parseResponse(details, result?: PatronOffer): PatronOffer {
    if (!details) return null;
    if (!result) result = new PatronOffer();

    super.parseResponse(details, result);
    result.name = details.name;
    result.description = details.description;
    result.finePrint = details.finePrint;
    if (result.redeemStartDate) result.redeemStartDate = new Date(details.redeemStartDate);
    if (result.redeemEndDate) result.redeemEndDate = new Date(details.redeemEndDate);
    if (result.archivedDate) result.archivedDate = new Date(details.archivedDate);
    if (result.claimStartDate) result.claimStartDate = new Date(details.claimStartDate);
    if (result.claimEndDate) result.claimEndDate = new Date(details.claimEndDate);
    result.startTimeOfDay = details.startTimeOfDay;
    result.endTimeOfDay = details.endTimeOfDay;
    result.daysOfTheWeek = parseDaysOfTheWeekInt(details.daysOfTheWeek);
    result.weeksOfTheMonth = parseWeeksOfTheMonthInt(details.weeksOfTheMonth);
    result.patronsLimit = details.patronsLimit;
    result.singlePatronLimit = details.singlePatronLimit;
    result.isVenueDefault = details.isVenueDefault;
    result.isUniversal = details.isUniversal;
    result.showInApp = details.showInApp;
    result.claim = PatronClaim.parseResponse(details.claim, null, result);
    result.platformIncentive = PlatformIncentive.parseResponse(details.platformIncentive);
    result.venueIncentive = VenueIncentive.parseResponse(details.venueIncentive);
    result.patronIncentive = PatronIncentive.parseResponse(details.patronIncentive);
    result.charityIncentive = CharityIncentive.parseResponse(details.charityIncentive);
    result.venue = PatronVenue.parseResponse(details.venue);
    result.venueUrl = details.venueUrl;
    result.offerImage = BaseImage.parseResponse(details.offerImage);

    return result;
  }
}

export class PatronClaim extends Entity {
  claimedDate: Date;
  offer: PatronOffer;
  rewards: RewardDetails[];

  static override parseResponse(details, result?: PatronClaim, offer?: PatronOffer): PatronClaim {
    try {
      if (!details) return null;
      if (!result) result = new PatronClaim();

      super.parseResponse(details, result);
      if (details.claimedDate) result.claimedDate = new Date(details.claimedDate);
      if (offer) {
        result.offer = offer;
      }
      else {
        result.offer = PatronOffer.parseResponse(details.offer);
      }
      if (Array.isArray(details.rewards)) {
        result.rewards = details.rewards.map((reward) => RewardDetails.parseResponse(reward));
      }
      else {
        result.rewards = [];
      }
    }
    catch (e) {
      console.log('PatronClaim --> parseResponse --> Error');
      console.log(e);
    }

    return result;
  }
}

export class PaymentMethod extends Entity {
  scheme: string;
  firstNumbers: string;
  lastNumbers: string;
  expDate: string;
  expMonth: number;
  expYear: number;

  static override parseResponse(details, result?: PaymentMethod): PaymentMethod {
    try {
      if (!details) return null;
      if (!result) result = new PaymentMethod();

      super.parseResponse(details, result);
      result.scheme = details.scheme;
      result.firstNumbers = details.firstNumbers;
      result.lastNumbers = details.lastNumbers;
      result.expDate = details.expDate;
      result.expMonth = details.expMonth;
      result.expYear = details.expYear;
    }
    catch (e) {
      console.log('PaymentMethod --> parseResponse --> super.parseResponse');
      console.log(e);
    }

    return result;
  }
}
export class PaymentMethodTransaction extends Entity {
  intAmount: number;
  currency: string;
  created: Date;
  updated: Date;
  auth: boolean;
  authCode: string;
  cardPresent: boolean;
  cleared: boolean;
  reimbursementEligible: boolean;
  datetime: Date;
  venue: PatronVenue;
  paymentMethod: PaymentMethod;

  static override parseResponse(details, result?: PaymentMethodTransaction): PaymentMethodTransaction {
    try {
      if (!details) return null;
      if (!result) result = new PaymentMethodTransaction();

      super.parseResponse(details, result);
      result.intAmount = details.intAmount;
      result.currency = details.currency;
      if (details.created) result.created = new Date(details.created);
      if (details.updated) result.updated = new Date(details.updated);
      result.auth = details.auth;
      result.authCode = details.authCode;
      result.cardPresent = details.cardPresent;
      result.cleared = details.cleared;
      result.reimbursementEligible = details.reimbursementEligible;
      if (details.datetime) result.datetime = new Date(details.datetime);
      result.venue = PatronVenue.parseResponse(details.venue);
      result.paymentMethod = PaymentMethod.parseResponse(details.paymentMethod);
    }
    catch (e) {
      console.log('PaymentMethodTransaction --> parseResponse --> Error');
      console.log(e);
    }

    return result;
  }
}

export class RewardDetails extends Entity {
  matchedDate: Date;
  claim: PatronClaim;
  transaction: PaymentMethodTransaction;

  static override parseResponse(details, result?: RewardDetails): RewardDetails {
    try {
      if (!details) return null;
      if (!result) result = new RewardDetails();

      super.parseResponse(details, result);
      if (details.matchedDate) result.matchedDate = new Date(details.matchedDate);
      result.claim = PatronClaim.parseResponse(details.claim);
      result.transaction = PaymentMethodTransaction.parseResponse(details.transaction);
    }
    catch (e) {
      console.log('RewardDetails --> parseResponse --> Error');
      console.log(e);
    }

    return result;
  }
}


export class PatronVenue extends Entity {
  name: string;
  description: string;
  venueAddresses: VenueAddress[];
  venueImages: Array<VenueImage> = [];

  static override parseResponse(details, result?: PatronVenue): PatronVenue {
    try {
      if (!details) return null;
      if (!result) result = new PatronVenue();

      super.parseResponse(details, result);
      result.name = details.name;
      result.description = details.description;
      if (Array.isArray(details.venueAddresses)) {
        result.venueAddresses = details.venueAddresses.map((address) => VenueAddress.parseResponse(address));
      }
      else {
        result.venueAddresses = [];
      }
      if (Array.isArray(details.venueImages)) {
        result.venueImages = details.venueImages.map((image) => VenueImage.parseResponse(image));
      }
      else {
        result.venueImages = [];
      }
    }
    catch (e) {
      console.log('PatronVenue --> parseResponse --> Error');
      console.log(e);
    }

    return result;
  }
}

export class VenueAddress extends Entity {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  geoCoordinate: GeoCoordinate;

  static override parseResponse(details, result?: VenueAddress): VenueAddress {
    try {
      if (!details) return null;
      if (!result) result = new VenueAddress();

      super.parseResponse(details, result);
      result.street = details.street;
      result.street2 = details.street2;
      result.city = details.city;
      result.state = details.state;
      result.zip = details.zip;
      result.country = details.country;
      result.phone = details.phone;
      result.geoCoordinate = details.geoCoordinate;
    }
    catch (e) {
      console.log('VenueAddress --> parseResponse --> Error');
      console.log(e);
    }

    return result;
  }
}

export class GeoCoordinate {
  latitude: number;
  longitude: number;
}


export class PlatformIncentive extends Entity {
  disabledDate: Date;
  promotions: Promotion[];

  static override parseResponse(details, result?: PlatformIncentive): PlatformIncentive {
    if (!details) return null;
    if (!result) result = new PlatformIncentive();

    super.parseResponse(details, result);
    if (details.disabledDate) {
      result.disabledDate = new Date(details.disabledDate);
    }
    if (Array.isArray(details.promotions)) {
      result.promotions = details.promotions.map((promotion) => Promotion.parseResponse(promotion));
    }
    else {
      result.promotions = [];
    }

    return result;
  }
}

export class Promotion extends Entity {

}

export class VenueIncentive extends Entity {
  disabledDate: Date;
  promotions: Promotion[];

  static override parseResponse(details, result?: VenueIncentive): VenueIncentive {
    if (!details) return null;
    if (!result) result = new VenueIncentive();

    super.parseResponse(details, result);
    if (details.disabledDate) {
      result.disabledDate = new Date(details.disabledDate);
    }
    if (Array.isArray(details.promotions)) {
      result.promotions = details.promotions.map((promotion) => Promotion.parseResponse(promotion));
    }
    else {
      result.promotions = [];
    }

    return result;
  }
}

export class PatronIncentive extends Entity {
  intReturnPercentage: number;
  intReturnAmount: number;
  intMinSpend: number;
  intMaxReward: number;
  disabledDate: Date;

  static override parseResponse(details, result?: PatronIncentive): PatronIncentive {
    if (!details) return null;
    if (!result) result = new PatronIncentive();

    super.parseResponse(details, result);
    result.intReturnPercentage = details.intReturnPercentage;
    result.intReturnAmount = details.intReturnAmount;
    result.intMinSpend = details.intMinSpend;
    result.intMaxReward = details.intMaxReward;
    if (details.disabledDate) {
      result.disabledDate = new Date(details.disabledDate);
    }

    return result;
  }
}

export class CharityIncentive extends Entity {
  intReturnPercentage: number;
  intReturnAmount: number;
  intMinSpend: number;
  intMaxReward: number;
  disabledDate: Date;
  charity: Charity;

  static override parseResponse(details, result?: CharityIncentive): CharityIncentive {
    if (!details) return null;
    if (!result) result = new CharityIncentive();

    super.parseResponse(details, result);
    result.intReturnPercentage = details.intReturnPercentage;
    result.intReturnAmount = details.intReturnAmount;
    result.intMinSpend = details.intMinSpend;
    result.intMaxReward = details.intMaxReward;
    if (details.disabledDate) {
      result.disabledDate = new Date(details.disabledDate);
    }

    result.charity = Charity.parseResponse(details.charity);

    return result;
  }
}
