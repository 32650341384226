import { Component, Input } from '@angular/core';
import { IPlace, IPlaceOffer } from '../map.component';
import { NavControlService } from '../../nav/nav-control.service';
import { PatronOffer } from 'src/app/model/patron-offer.model';

@Component({
  selector: 'app-offer-popover',
  templateUrl: './offer-popover.component.html',
  styleUrls: ['./offer-popover.component.css']
})
export class OfferPopoverComponent {
  @Input()
  place: Partial<IPlace>;

  get offer(): PatronOffer {
    if (this.place) {
      return this.place['offer'];
    }
    return null;
  }

  constructor(
    private readonly navControlService: NavControlService,
  ) {}


  get isCharityOffer(): boolean {
    return !!this.offer?.charityIncentive && !this.offer?.charityIncentive?.disabledDate;
  }

  get intReturnPercentage(): number {
    if (this.isCharityOffer) {
      return Math.round(this.offer?.charityIncentive?.intReturnPercentage ?? 0);
    }
    else {
      return Math.round(this.offer?.patronIncentive?.intReturnPercentage ?? 0);
    }
  }

  get intReturnAmount(): number {
    if (this.isCharityOffer) {
      return Math.round(this.offer?.charityIncentive?.intReturnAmount ?? 0);
    }
    else {
      return Math.round(this.offer?.patronIncentive?.intReturnAmount ?? 0);
    }
  }

  goToOffer(offer) {
    console.log('goToOffer', offer);
    this.navControlService.goToOffer(offer);
  }

}
