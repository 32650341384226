

export interface IEntity {
  id: string;
  // shellObject: boolean;
}

export class Entity implements IEntity {
  id: string;
  createdDate: Date;
  updatedDate: Date;

  // shellObject: boolean = true;

  constructor(id?: string, createdDate?: Date, updatedDate?: Date) {
    this.id = id;
    this.createdDate = createdDate;
    this.updatedDate = updatedDate;
  }

  toJson(): any {
    return {
      id: this.id,
      createdDate: this.createdDate?.toISOString() ?? "",
      updatedDate: this.updatedDate?.toISOString() ?? "",
    };
  }

  static parseResponse(details, result?: Entity): Entity {
    if (!details) return null;
    if (!result) result = new Entity();

    result.id = details.id;
    if (details.createdDate) {
      result.createdDate = new Date(details.createdDate);
    }
    if (details.updatedDate) {
      result.updatedDate = new Date(details.updatedDate);
    }

    return result;
  }

  toRequest(): any {
    const result: any = {};
    result.id = this.id;

    return result;
  }


}
