<div (clickOutside)="hideSearchResults()" clickOutside class="w-full">
  <!-- Search input -->
  <div class="relative flex flex-row gap-0 w-full">
    <input
      #searchInput
      type="text"
      class="w-full h-10 pl-3 pr-8 text-base font-normal placeholder-gray-400 text-gray-700 leading-normal border rounded-xl  focus:outline-gray-400"
      placeholder="Search for food, fundraisers and more"
      (focus)="handleSearchFocus($event)"
      [formControl]="searchControl"
    />
    <button
      type="submit"
      class="absolute top-0 right-0 bottom-0 w-10 bg-patron-pink rounded-tr-xl rounded-br-xl flex flex-col justify-center items-center"
    >
      <img class="w-5 h-4 object-contain" src="/assets/icons/Outline/search.svg" />
    </button>
  </div>

  <!-- Search results -->
  <div class="relative overflow-visible">
    <div *ngIf="!!showingSearchResults || awaitingSearchResults" class="absolute z-50 w-full mt-2 bg-white shadow-lg border rounded-lg border-gray-100 drop-shadow-2xl overflow-auto max-h-screen-200"
    >
      <!-- Loading indicator -->
      <div *ngIf="awaitingSearchResults" class="p-2 flex flex-col justify-center items-center">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-7 w-7"></div>
      </div>
      <div *ngIf="!awaitingSearchResults" class="w-full bg-white shadow-lg rounded-lg"
      >
        <div *ngIf="charityResults?.length > 0" class="p-0 bg-gradient-to-t from-white to-white">
          <p class="text-sm p-2 uppercase text-center text-stone-500">Fundraisers</p>
          <div class="p-2 ">
            <app-search-result-row *ngFor="let searchResult of charityResults" [searchResult]="searchResult" (click)="handleSearchResultClick(searchResult)" class="cursor-pointer"></app-search-result-row>
          </div>
        </div>
        <div *ngIf="offerResults?.length > 0" class="p-0 bg-gradient-to-t from-white to-white">
          <p class="text-sm p-2 uppercase text-center text-stone-500">Offers</p>
          <div class="p-2 ">
            <app-search-result-row *ngFor="let searchResult of offerResults" [searchResult]="searchResult" (click)="handleSearchResultClick(searchResult)" class="cursor-pointer"></app-search-result-row>
          </div>
        </div>
      </div>
  </div>
</div>
