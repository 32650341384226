import { gql } from "apollo-angular";
import { UserPaymentMethodDetails } from "../fragments/user_payment_method_details.fragment.graphql";

export const UserPaymentMethodsQuery = gql`
query UserPaymentMethods($id: String!, $search: [SearchInput!]! = [], $sort: PageAndSortInput) {
    userPaymentMethods(id: $id, search: $search, sort: $sort) {
      ...UserPaymentMethodDetails
    }
  }
  ${UserPaymentMethodDetails}
  `;

