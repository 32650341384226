<div class="w-full h-full flex justify-center items-start gz-bg-gradient">
  <div class="flex flex-col justify-center items-center py-[50px] px-[50px] lg:px-[200px] w-full max-w-8xl">

    <h1 class="resp-title mb-12 w-full">Privacy Policy</h1>

    <h2 class="resp-subtitle mb-6">Location Of Servers</h2>

    <p class="resp-para mb-12">GetZeals, Inc. (the “Company,” “we,” “us,” and “our”) offers a rewards
      program (“Program”) in the United States to help consumers save money. Our Site is hosted on servers located in
      the United States of America and is intended for use by individuals and entities who are United States residents.
      YOU EXPRESSLY ACKNOWLEDGE AND CONSENT THAT YOUR DATA MAY BE TRANSFERRED TO VARIOUS LOCATIONS AND THIRD-PARTY
      PROVIDER (FIDEL API), AND MAY BE MAINTAINED AND PROCESSED ON COMPUTERS LOCATED OUTSIDE OF YOUR STATE, PROVINCE,
      COUNTRY, OR OTHER GOVERNMENTAL JURISDICTION WHERE THE PRIVACY LAWS MAY NOT BE AS PROTECTIVE AS THOSE IN YOUR
      JURISDICTION.</p>

    <h2 class="resp-subtitle mb-6">What We Collect and How We Use It</h2>

    <h2 class="resp-subtitle mb-6">Identifiable Information</h2>

    <p class="resp-para mb-12">You may visit our Site without expressly submitting any information that can
      be used to identify you (“Personal Information”). However, if you wish to open an account with us, you will be
      required to submit Personal Information as further set forth below. To open an account with us, you are required
      to agree to our Terms of Use that you can access here.</p>

    <h2 class="resp-subtitle mb-6">Information We Collect</h2>

    <p class="resp-para mb-12">If you register in the Program, the Personal Information we collect may
      include your name, email, mailing address, phone number, and other information requested on the registration page.
      Our processing partner, Fidel API, will collect the primary account number for the eligible credit and/or debit
      card(s) that you use to enroll in the Program (“Linked Card”). The primary account number is the 14, 15, or 16
      digit number that appears on the front of your Linked Card (“Linked Card Number”). Accordingly, you are
      authorizing us to obtain and receive information about your Linked Card transactions with Participating Merchants
      from a payment card network (“Payment Card Network”) such as American Express®, Mastercard®, Visa®, Discover®, or
      a payment card processor.</p>

    <p class="resp-para mb-12">You will need to create a user name and password for your Program account
      with us. We may assign you a unique identification number so that we may more easily identify you and your
      transactions.</p>

    <p class="resp-para mb-12">You have the option to also provide us with the month and day of special
      dates (e.g., birthday, anniversary), dining preferences, and information regarding your personal accounts and/or
      profiles on third-party social networking sites such as Facebook® and Twitter®, as further described below.

    <p class="resp-para mb-12">We use your Personal Information only to operate the Program, including to
      send you emails, mailings, or other forms of communications (that may be real-time) that relate to the Program and
      Participating Merchants.</p>

    <h2 class="resp-subtitle mb-6">Card Information And Transaction Information</h2>

    <p class="resp-para mb-12">We will know when you conduct a transaction with a Participating Merchant. We
      will collect information about that transaction, including a unique Program identification number, the date and
      time of your transaction, the amount of your transaction, and the name and location of the Participating Merchant.
      We receive this information from Fidel API. We may also use your transaction information to generate internal
      financial reporting and analysis regarding our business and for external reporting of anonymized data to market
      our business and the Program to third parties, including potential Participating Merchants.</p>

    <p class="resp-para mb-12">We use the transaction data that we receive to match your transactions at
      Participating Merchants with available rewards and otherwise for Program rewards administration. In some cases, a
      Participating Merchant’s payment card processor may experience a lag time (approximately 24 hours) between when a
      credit and/or debit card is linked to a Program account and when the payment card processor is able to identify
      and provide us with the transaction data associated with that card. If you believe that you are eligible for
      rewards for a transaction at a Participating Merchant, and those rewards have not registered in your Program
      account within 10 days of the dine, please contact the Program’s Member Services at (408)905-9271. We reserve the
      right to require that you provide a receipt from the Participating Merchant or documents reasonably required by us
      to evidence an eligible transaction in order to receive your reward.</p>

    <p class="resp-para mb-12">By enrolling in and participating in the Program, you are authorizing us and
      Participating Merchants and payment card processors to obtain, provide, and use transaction information related to
      your purchases (such as date, time and amount) at Participating Merchants in order to calculate rewards, redeem
      rewards, and facilitate and make the Program available. You also authorize us to, in our reasonable discretion,
      collect location data using your device to provide you with recommendations based on your location and past
      activity, analyze your general transaction patterns and stores that you visit, and also aggregate this data to
      help understand general Program member patterns and trends, which we may share with third parties with our
      discretion.</p>

    <h2 class="resp-subtitle mb-6">Transaction Data Usage</h2>

    <p class="resp-para mb-12">Notwithstanding anything to the contrary in the Terms or Privacy Policy,
      Company and its Third Party Service Provider (Fidel API) will use transaction information solely as follows:</p>

    <p class="resp-para mb-12">- Use transaction data such as transaction amount, transaction time and
      merchant location to confirm a Qualifying Purchase or return to match transactions to confirm whether you qualify
      for rewards</p>

    <p class="resp-para mb-12">- Share transaction data with the participating merchant where a transaction
      occurred as needed for the merchant to confirm a specific transaction occurred. For example, the date and amount
      of your purchase and the last 4 digits of your card number so the merchant can verify your purchase with its
      records if there is a missing or disputed transaction;</p>

    <p class="resp-para mb-12">- Provide participating merchants or Third Party Service Provider (Fidel API)
      aggregated and anonymized information relating specifically to registered card activity solely to allow
      participating merchants and Third Party Service Provider (Fidel API) to assess the results of their campaign;</p>

    <p class="resp-para mb-12">- Create a record of the transaction data to allow users to have visibility
      to their qualifying transactions history and related balances;</p>

    <p class="resp-para mb-12">- Provide information in order to respond to a request from government
      authority or a payment organization involved in a transaction with you or a merchant.</p>

    <p class="resp-para mb-12">- You authorize the sharing, exchange and use of transaction data described
      above and herein by and among Company and Company’s Third Party Service Provider (Fidel API), applicable Payment
      Card Networks and applicable Merchants.</p>

    <h2 class="resp-subtitle mb-6">Transaction Monitoring</h2>

    <p class="resp-para mb-12">By registering a payment card in connection with transaction monitoring, you
      authorize GetZeals, Inc to share your payment card information with Visa, Mastercard and AMEX (Payment Networks)
      so it knows you enrolled. You authorize Visa, Mastercard and AMEX to monitor transactions on your registered
      card(s) to identify qualifying purchases in order to determine whether you have qualified for or earned an offer
      linked to your payment card, and for Visa, Mastercard and AMEX (Payment Networks) to share such transaction
      details with GetZeals, Inc to enable your card-linked offer(s) and target offers that may be of interest to you.
      You may opt-out of transaction monitoring on the payment card(s) you have registered by navigating to your
      settings menu to remove your linked card(s).</p>

    <h2 class="resp-subtitle mb-6">Card Updates</h2>

    <p class="resp-para mb-12">If your card number changes, you are responsible for updating your card in
      your account profile. In some cases, we may receive updated credit and/or debit card numbers associated with your
      card from payment card processors. If we receive this information, we will update the card with the new credit
      and/or debit card number that we receive, and we will use it to operate the Program as described in this Privacy
      Policy.</p>

    <h2 class="resp-subtitle mb-6">IP Addresses</h2>

    <p class="resp-para mb-12">We collect IP address information from any visitor to the Site. We log IP
      addresses for system administrative purposes only. IP addresses are not used for identification. This information
      helps us determine how often different areas of the Site are visited. We do not link IP addresses to any
      information that is personally identifiable.</p>

    <h2 class="resp-subtitle mb-6">Usage Information And Session Data</h2>

    <p class="resp-para mb-12">When you visit the Site or click on a link in an e-mail from one of our
      Participating Merchants, we may collect usage information ("Session Data"). Session Data may include browser type
      and version, time zone setting, browser plug-in types and versions, operating system, platform, and what
      Participating Merchants you look at. We may use Session Data for a variety of reasons, including to better
      understand things like how the Site is navigated, how many visitors arrive at and look at specific pages, and the
      length and frequency of stays at our Site. We disclose such Session Data to our Participating Merchants to provide
      proof of attribution metrics.</p>

    <h2 class="resp-subtitle mb-6">Geo-Location</h2>

    <p class="resp-para mb-12">We may have the ability to collect location data and provide you with push
      notifications based on your location and past dining activity. We utilize location information to provide you with
      more relevant location-based restaurant choices, track your general dining patterns and stores that you visit, and
      also aggregate this data to help understand general Program member patterns and trends, which we may share with
      third parties in our discretion. You are agreeing that we can collect and use your location data and can continue
      to do so until you turn off your location settings.</p>

    <p class="resp-para mb-12">Your device may allow you to disable the collection of location data and push
      notifications by accessing your device’s application or notification settings. If you do not want us to have
      access to your location, you may change your location settings. You are agreeing that we can continue to collect
      location data until you turn off data collection in your device. We have no responsibility or liability for the
      functions of location settings within any device.</p>

    <h2 class="resp-subtitle mb-6">Cookies</h2>

    <p class="resp-para mb-12">Like most sites, we use cookies on the Site to collect Session Data and
      persistent data for several purposes, such as to personalize and improve your individual viewing experiences,
      track and analyze non-personally identifiable usage statistical information in order to constantly improve the
      quality of the Site, and for other purposes, including to better understand things like how the Site is navigated,
      how many visitors arrive at and look at specific pages, and the length and frequency of stays at our Site. We use
      two different types of cookies on our Site:</p>
    <ul class="list-disc list-inside">
      <li class="my-2 text-md">
        <b>Session cookies</b> track the user's progression through the Site and session data for a single visit. These
        cookies enable us to follow the user as they progress from one page to another. Your session cookies expire at
        the end of your browser session. In addition, if you leave your browser open for a prolonged period, they are
        set to automatically expire.
      </li>
      <li class="my-2 text-md">
        <b>Persistent cookies</b> are stored on your device between browser sessions, allowing us to remember you and
        your preferences on the Site. We may use a persistent cookie to remember such information as your user name,
        name, preferred dining area, favorite restaurants, and Session Data.
      </li>
    </ul>
    <h2 class="resp-subtitle mb-6">Disclosure Of Personal Information</h2>

    <p class="resp-para mb-12">We use your Personal Information and share it with payment card processors to
      solely operate the Program. We also disclose Session Data to our Participating Merchants, as discussed above. We
      will not, without your prior express consent, disclose your Personal Information to any third party for them to
      market to you directly. For example, we may invite you to participate in a survey or special promotional program
      offered by a non-affiliated third party. Your choice to participate in such a survey or promotional program will
      indicate your consent for us to share your Personal Information with non-affiliated third parties in connection
      with the survey or promotional program. If you consent to such disclosure, any information that you provide to the
      third parties will be controlled by that party's privacy policy, not this Privacy Policy.</p>

    <p class="resp-para mb-12">We may disclose information that we may have, identifiable or not, as
      necessary for defending and bringing legal actions; if required by a government authority or legal process; when
      we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with
      an investigation or prosecution of suspected or actual illegal activity; or in the good faith belief that
      disclosure is otherwise necessary or advisable.</p>

    <p class="resp-para mb-12">By using this Site, you agree that we may collect, use, and disclose any
      information collected on this Site (as described in this Privacy Policy) for any purpose not prohibited by this
      Privacy Policy, applicable law, or an applicable agreement.</p>

    <h2 class="resp-subtitle mb-6">Disclosure Of Anonymized Information</h2>

    <p class="resp-para mb-12">We may share anonymized information about you that is not reasonably
      identifiable to you with our affiliates, Participating Merchants, and other third parties in our discretion. Such
      information could be used, for example, to provide us with statistical or other analysis and to market our
      business, the Program or both. This information is de-identified and cannot reasonably be used to identify you
      personally. This information is most often aggregated demographic and statistical information (such as information
      derived from use of our Site or your participation in any Program) and information submitted in connection with
      restaurant reviews or surveys that you submit to us.</p>

    <h2 class="resp-subtitle mb-6">Transfer Of Personal Information</h2>

    <p class="resp-para mb-12">We reserve the right to transfer all information and data in our possession,
      custody, or control to a successor organization in the event of a merger, acquisition, reorganization, bankruptcy,
      or other sale of all or a portion of our assets. Any acquirer will remain subject to the terms of this Privacy
      Policy.</p>

    <h2 class="resp-subtitle mb-6">Third-Party Sites</h2>

    <p class="resp-para mb-12">We neither own nor control any third-party sites that may be accessible
      through the Site, and do not control the privacy policies of such third parties. As a result, we assume no
      responsibility or liability for the actions of such third parties (which include, but are not limited to,
      third-party providers of Online Ordering Available, Online Ordering Only, and gift cards accessible through links
      on the Site). We suggest that you review the privacy policy and terms of use of any third-party site before
      submitting information to them. For a complete list of third-party sites accessible from the Site, please contact
      legal&#64;getzeals.com.</p>

    <h2 class="resp-subtitle mb-6">Choices For Email And Marketing Communications</h2>

    <p class="resp-para mb-12">You may opt out of receiving Program emails from us. However, doing so will
      affect your eligibility for all or some Program benefits. You may not opt out of receiving administrative emails
      related to your account except by terminating your Program participation.</p>

    <p class="resp-para mb-12">If you do not wish to receive email communications from us, you can
      unsubscribe from the link in the email you received, change your selections within the Account Information
      section, or indicate your preferences within a direct communication to us. You may also email us if you wish to
      unsubscribe, but for your security, please do not send credit and/or debit card information via email.</p>

    <h2 class="resp-subtitle mb-6">Do Not Track</h2>

    <p class="resp-para mb-12">Because there is not an industry or legal standard for recognizing or
      honoring Do Not Track (“DNT”) signals, we do not respond to them at this time.</p>

    <h2 class="resp-subtitle mb-6">Retention And Disposal Of Information</h2>

    <p class="resp-para mb-12">We retain your information as long as we determine it is required for the
      operation of our business and Program, and to meet legal, regulatory, and other requirements.</p>

    <p class="resp-para mb-12">We take reasonable steps to ensure that information is disposed of securely
      when we no longer require such data.</p>

    <h2 class="resp-subtitle mb-6">Data Security</h2>

    <p class="resp-para mb-12">We take commercially reasonable steps to maintain physical, electronic, and
      procedural safeguards to protect your information. These safeguards may include data encryption, access
      authorization, firewalls, and physical access control to our data centers.</p>

    <h2 class="resp-subtitle mb-6">Securing Your Personal Information</h2>

    <p class="resp-para mb-12">We take commercially reasonable steps to maintain physical, electronic, and
      procedural safeguards to protect your information. These safeguards may include data encryption, access
      authorization, firewalls, and physical access control to our data centers.</p>

    <p class="resp-para mb-12">Specifically, GetZeals and the Program will never send you unsolicited
      requests for the following types of Personal Information: (i) username or password; (ii) email address; (iii)
      social security number; (iv) credit and/or debit card number; (v) credit card expiration date or card security
      code; (vi) bank account numbers; or (vii) names of your family members. This list is provided for illustrative
      purposes only and is not intended to include all categories of information that may be the subject of an
      unsolicited request for Personal Information.</p>

    <p class="resp-para mb-12">Any request for personal information of this nature in a conversation not
      initiated by you should be considered suspicious, even if the communication includes GetZeals or the Program
      logos, links to websites that look “like” the Program, or a request for you to take immediate action, such as
      verifying your account information. Unsolicited communications of this type are often fraudulent “phishing”
      communications, and could be an attempt to acquire your sensitive personal information for malicious reasons.</p>

    <h2 class="resp-subtitle mb-6">Payment Card Industry Data Security Standard</h2>

    <p class="resp-para mb-12">In order to protect your Personal Information, GetZeals complies with the
      security standards (PCI DSS) required by the PCI Security Standards Council.</p>

    <p class="resp-para mb-12">If you suspect that you have received a phishing or other type of fraudulent
      communication, you should not: (i) click on any links in the offending email; (ii) open any attachments related to
      the communication; (iii) call any telephone numbers provided in the communication; or (iv) follow instructions
      contained in the communication. If the phishing or other fraudulent communication purports to be associated with
      the Program, you should report the communication to GetZeals by sending an email to security&#64;getzeals.com. You
      should attach the unsolicited communication to your email report. If you received the communication by postal
      mail, rather than by email, you should photograph any mailer, letter, and envelope received and attach those
      materials to the email report that you send to GetZeals.</p>

    <h2 class="resp-subtitle mb-6">Reviewing Or Changing Your Information</h2>

    <p class="resp-para mb-12">You can contact us (by email, telephone, or postal mail) to request access
      and/or modify any information that we have for you, or otherwise modify your preferences pertaining to our
      marketing communications. To review, change, or update your information:</p>
    <ul class="list-disc list-inside">
      <li class="my-2 text-md">
        <b>Online:</b> When the Program Site is available, sign in to your account and click on the "Profile" link to
        change your Personal Information or modify your communication preferences.
      </li>
      <li class="my-2 text-md">
        <b>By postal mail:</b> Please include your email address and mail your request to us at 1702L #173 Meridian Ave,
        San Jose, CA. 95125.
      </li>
      <li class="my-2 text-md">
        <b>By phone:</b> Members may contact GetZeals Member Services toll-free at (408)905-9271.
      </li>
      <li class="my-2 text-md">
        <b>By email:</b> info&#64;getzeals.com.
      </li>
    </ul>

    <p class="resp-para mb-12">To protect your privacy and security, we will take commercially reasonable
      steps to verify your identity before making corrections or granting access to your information.</p>

    <h2 class="resp-subtitle mb-6">Cancellation And Terminating Your Account</h2>

    <p class="resp-para mb-12">If you do not agree to have your information collected, shared and used as
      described in this Privacy Policy, you may terminate your Program account at any time by contacting us at
      (408)905-9271or deleting all remaining credit and/or debit cards from your account.</p>

    <p class="resp-para mb-12">If you no longer consent to this Privacy Policy and our collection and
      maintenance of this information, you must un-enroll from the Program. If you elect to un-enroll or are otherwise
      deactivated from the Program, we will also need to disclose that information to Rewards Partner, non-affiliated
      third-party suppliers, vendors, contractors, payment card issuers, and payment card processors as necessary to
      allow them to remove you from the Program.</p>

    <p class="resp-para mb-12">Pursuant to CCPA, we will not sell the personal information of any consumer
      less than 18 years of age.</p>

    <h2 class="resp-subtitle mb-6">Contact Us</h2>

    <p class="resp-para mb-12">For any questions, or to request further information regarding this Privacy
      Policy, please contact us at legal&#64;getzeals.com.</p>

    <h2 class="resp-subtitle mb-6">Changes To Our Privacy Policy</h2>

    <p class="resp-para mb-12">It is our policy to post any changes we make to our Privacy Policy on this
      page. If we make material changes to how we treat our users' personal information, we will notify you by e-mail to
      the primary e-mail address specified in your account and/or possibly through a notice on our Site’s home page. The
      date the Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring we
      have an up-to-date active and deliverable e-mail address for you, and for periodically visiting our Site and this
      Privacy Policy to check for any changes.</p>
  </div>
</div>
