import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { AuthService } from './auth.service';
import { LoginState, UserAuthService } from '../user-auth.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  private userPoolId: string = 'us-east-2_vQCC931Sv';
  private clientId: string = '3ratupgg3ticraqla706guj5vd';

  constructor(
    private readonly authService: AuthService,
    private readonly userAuthService: UserAuthService,
  ) {
  }

  private _initialized: boolean = false;
  async init(): Promise<void> {
    if (this._initialized) {
      return;
    }
    const uri = environment.authConfigUri;
    const fetchResponse = await fetch(uri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    console.log('=== Cognito Init ===');

    const body = await fetchResponse.json();
    this.userPoolId = body.userPoolId;
    this.clientId = body.clientId;
    this._initialized = true;

  }

  async buildCongitoUserPool(): Promise<CognitoUserPool> {
    await this.init();
    const poolData = {
      UserPoolId: this.userPoolId,
      ClientId: this.clientId,
    };
    return new CognitoUserPool(poolData);
  }

  async registerUser(email: string, password: string, firstName: string, lastName: string): Promise<any> {
    const userPool = await this.buildCongitoUserPool();

    const attributeList = [];
    attributeList.push({
      Name: 'email',
      Value: email,
    });
    attributeList.push({
      Name: 'given_name',
      Value: firstName,
    });
    attributeList.push({
      Name: 'family_name',
      Value: lastName,
    });

    let result = await new Promise((resolve, reject) => {
      userPool.signUp(email, password, attributeList, [], (err: any, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });

    return result;
  }

  async resendConfirmationCode(email: string): Promise<any> {
    const userPool = await this.buildCongitoUserPool();
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    let result = await new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });

    return result;
  }

  async confirmUser(email: string, code: string): Promise<any> {
    const userPool = await this.buildCongitoUserPool();
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    let result = await new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(`${code}`, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });

    return result;
  }

  async validateUser(email: string): Promise<any> {
    const userPool = await this.buildCongitoUserPool();
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    let result = await new Promise((resolve, reject) => {
      cognitoUser.getAttributeVerificationCode('email', {
        onSuccess: (result) => resolve(result),
        onFailure: (err) => {
          reject(err);
        },
        inputVerificationCode: (data) => {
          resolve(data);
        },
      });
    });

    return result;
  }

  async authenticateUser(email: string, password: string): Promise<CognitoUserSession> {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userPool = await this.buildCongitoUserPool();
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    this.userAuthService.setState(LoginState.LOGGING_IN);

    let result: CognitoUserSession | any = await new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session: CognitoUserSession) => resolve(session),
        onFailure: (err) => {
          this.authService.handleCredentialResponse(err);
          reject(err);
        },
      });
    });

    console.log('LoginComponent: authenticateUser: result: ', result);
    await this.authService.handleCredentialResponse({
      idToken: result.idToken,
      accessToken: result.accessToken,
      refreshToken: result.refreshToken,
    });

    return result as CognitoUserSession;
  }

  async forgotPassword(email: string): Promise<any> {
    const userPool = await this.buildCongitoUserPool();
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    let result = await new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => resolve(result),
        onFailure: (err) => {
          reject(err);
        },
        inputVerificationCode: (data) => {
          resolve(data);
        },
      });
    });

    return result;
  }

  async resetPassword(email: string, code: string, password: string): Promise<any> {
    const userPool = await this.buildCongitoUserPool();
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    let result = await new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(`${code}`, password, {
        onSuccess: (result) => resolve(result),
        onFailure: (err) => {
          reject(err);
        },
      });
    });

    return result;
  }
}
