<div class="w-full px-4 max-w-4xl items-center flex flex-col mx-auto h-full">
  <div class="w-full text-center text-black text-4xl font-bold antialiased mb-2">{{ 'LINKED_BANK_ACCOUNT' | translate }}
  </div>
  <div class="w-full text-center text-black text-sm font-light mb-5">
    We keep your financial details secure so no one sees your sensitive info.</div>

  <!-- Scrolling div for all cards -->
  <div class="overflow-y-auto mb-10 w-full relative flex-grow min-h-[125px]">
    <div>
      <table class="w-full mt-10">
        <thead>
          <tr>
            <th class="opacity-60 text-black text-base text-left font-normal uppercase leading-normal">ACH Account</th>
            <th class="opacity-60 text-black text-base text-left font-normal uppercase leading-normal">Name on the Account</th>
            <th class="text-left text-black text-sm font-normal"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!owner?.achAccount" colspan="3" class="text-zinc-600 text-2xl font-medium leading-relaxed">
            Please link a bank account to receive cash deposits.
          </tr>
          <tr *ngIf="!!owner?.achAccount">
            <td class="text-left text-black text-sm font-normal">
              <div class="flex flex-row justify-start items-center">
                <div class="w-12 h-12 xs:w-14 xs:h-14 rounded-xl object-cover aspect-square flex justify-center items-center">
                  <fa-icon [icon]="BankIcon" class="object-contain text-4xl"></fa-icon>
                </div>
                <div class="flex flex-col justify-center items-start">
                  <div class="text-zinc-600 text-xl font-medium leading-relaxed">{{ owner?.achAccount?.accountName }} - {{ owner?.achAccount?.bankName }}</div>
                  <div class="text-zinc-600 text-sm font-medium leading-relaxed">{{ owner?.achAccount?.bankAccountType }}</div>
                </div>
              </div>
            </td>
            <td class="text-left text-black text-sm font-normal">
              <div class="text-zinc-600 text-xl font-medium leading-relaxed">{{ dwollaServiceCustomer?.fullName }}</div>
              <div class="text-zinc-600 text-sm font-medium leading-relaxed">{{ dwollaServiceCustomer?.email }}</div>
          </td>
            <td class="text-left text-black text-sm font-normal cursor-pointer h-full flex justify-center items-center"
              (click)="openConfirmRemove()">
              <div class="text-patron-pink text-lg font-normal cursor-pointer">
                Remove
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <div class="flex flex-col w-full items-center justify-center px-3 py-3 rounded-lg bg-white relative">
        <div class="flex flex-row w-full items-center justify-center py-3">
          <div class="gap-0 flex-col w-full justify-center items-start flex-1 p-0 flex">
            <span class="text-lg font-bold antialiased text-black text-left w-full">ACH Account</span>
          </div>
          <button *ngIf="stateRemove !== FetchState.LOADING" [disabled]="plaidSetupState !== FetchState.LOADED_ALL"
            (click)="openConfirmRemove()" class="cursor-pointer disabled:cursor-auto">
            <fa-icon [icon]="faTrash" class="text-red-600"
              [ngClass]="{'text-opacity-30': plaidSetupState !== FetchState.LOADED_ALL}"></fa-icon>
          </button>
        </div>

        <div *ngIf="!!owner?.achAccount && !!dwollaServiceCustomer"
          class="gap-0 flex-col w-full justify-between items-start flex py-2">
          <div class="text-lg font-normal text-black text-left">Controller</div>
          <div class="gap-0 flex-row w-full justify-between items-start flex">
            <div class="flex flex-col items-start justify-center flex-grow">
              <div class="text-md font-normal text-black text-left">{{ dwollaServiceCustomer?.email }}</div>
              <span class="text-sm font-bold antialiased text-black text-left">{{ dwollaServiceCustomer?.fullName
                }}</span>
            </div>
            <div class="flex flex-row items-end justify-center">
              <fa-icon *ngIf="dwollaServiceCustomer.type === DwollaAccountType.UNVERIFIED" [icon]="faArrowDown"
                [styles]="{'color': 'red'}" class="text-red-500 ml-2"></fa-icon>
              <fa-icon *ngIf="dwollaServiceCustomer.type !== DwollaAccountType.UNKNOWN" [icon]="faArrowUp"
                [styles]="{'color': 'green'}" class="text-green-500 ml-2"></fa-icon>
            </div>
          </div>
        </div>
        <div *ngIf="!!owner?.achAccount" class="gap-0 flex-row w-full justify-between items-start flex">
          <div class="flex flex-col items-start justify-center flex-grow">
            <div class="text-md font-normal text-black text-left">{{ owner?.achAccount?.bankName }}</div>
            <span class="text-sm font-bold antialiased text-black text-left">{{ owner?.achAccount?.accountName }}</span>
          </div>
          <div class="flex flex-col items-end justify-center">
            <span class="text-sm font-bold antialiased text-black text-right">{{ owner?.achAccount?.bankAccountType
              }}</span>
            <fa-icon *ngIf="owner?.achAccount?.accountStatus === 'verified'" [icon]="faCertificate"
              [styles]="{'color': 'green'}" class="text-green-500 ml-2"></fa-icon>
            <fa-icon *ngIf="owner?.achAccount?.accountStatus !== 'verified'" [icon]="faExclamationCircle"
              [styles]="{'color': 'red'}" class="text-red-500 ml-2"></fa-icon>
          </div>
        </div>

      </div> -->
      <div *ngIf="stateRemove === FetchState.ERROR">
        <div class="text-red-700 text-sm font-bold antialiased text-left w-full">Hmm... seems like there was in issue
          removing your account -- please try again later.</div>
      </div>

      <div *ngIf="stateRemove === FetchState.LOADED_ALL" (click)="clearRemovedMessage()">
        <!-- Confirmation message that account was removed -->
        <div class="flex flex-col w-full items-center justify-center px-3 py-3 rounded-lg bg-white bg-opacity-50 relative">
          <!-- <div class="flex flex-row w-full items-center justify-center py-3">
            <div class="gap-0 flex-col w-full justify-center items-start flex-1 p-0 flex">
              <span class="text-lg font-bold antialiased text-black text-left w-full">ACH Account</span>
            </div>
          </div>
 -->
          <div class="gap-0 flex-col w-full justify-between items-start flex py-2">
            <div class="text-lg font-normal text-black text-left">Bank Account Removed</div>
            <div class="gap-0 flex-row w-full justify-between items-start flex">
              <div class="flex flex-col items-start justify-center flex-grow">
                <div class="text-md font-normal text-black text-left">Your account has been removed.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-300 w-full h-px my-2"></div>
    </div>


    <div *ngIf="plaidSetupState === FetchState.ERROR"
      class="text-red-700 text-sm font-bold antialiased text-left w-full">Looks like we are unable to link your
      account at this point -- please try again later.</div>
    <div *ngIf="canLinkAccount" class="w-full p-0 pt-4">
      <div *ngIf="controllerUsers.length > 1">
        <div class="w-full">Select Controller</div>
        <!-- <div class="relative">
        <input type="text" [ngModel]="searchTerms | async" (ngModelChange)="search($event)"
                [placeholder]="placeholder" class="border rounded-lg px-3 py-2 w-full pl-3 pr-8">

        <fa-icon *ngIf="(searchTerms | async)?.length" [icon]="faTimesCircle"
                  class="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer text-grey-400"
                  (click)="clearSearch()"></fa-icon>
      </div>
      -->
        <ul class="mt-2 w-full bg-white border border-gray-300 rounded-lg">
          <!-- If the user is the controllerUser then highlight it -->
          <li *ngFor="let user of controllerUsers" (click)="selectUser(user)"
            class="cursor-pointer hover:bg-gray-100 p-2" [ngClass]="{'bg-gray-100': user.id === controllerUserId}">
            {{ user.fullName }} ({{ user.email }})
          </li>
        </ul>
      </div>

      <div class="w-full p-0 pt-4 flex flex-col justify-center items-center">
        <button *ngIf="linkAccountState !== FetchState.LOADING" class="button-patron-pink"
          [ngClass]="{'text-white': (controllerUsers.length > 0 && !controllerUserId), 'text-black': (controllerUsers.length === 0 || !!controllerUserId)}"
          (click)="showLinkAccount()">
          Link Bank Account
        </button>
        <div *ngIf="linkAccountState === FetchState.ERROR"
          class="text-red-700 text-sm font-bold antialiased text-left w-full">Hmm... seems like there was in issue
          linking your account -- please try again later.</div>
      </div>
    </div>
    <!-- <div *ngIf="!canLinkAccoun" class="w-full p-0 pt-4">
    <div class="flex flex-row w-full items-center justify-center px-3 py-3 rounded-lg bg-grey-200">
      <div *ngIf="fidelScriptState === FetchState.LOADING">
        <div class="loader-wrapper flex justify-center items-center">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"></div>
        </div>
      </div>
      <div *ngIf="fidelScriptState === FetchState.ERROR" class="gap-0 flex-col w-full justify-center items-start flex-1 p-0 flex">
        <span class="text-lg font-bold antialiased text-black text-left w-full">{{ 'FIDEL.LINK_UNAVAILABLE' | translate }}</span>
      </div>
    </div>
  </div> -->

    <!-- Archived Cards -->
    <!-- <div *ngIf="archivedUserPaymentMethods.length > 0" class="w-full mt-10 mb-2" (click)="showArchived = !showArchived">
    <div class="flex flex-row w-full items-center justify-center mb-2"
    >
    <div class="w-full text-left text-black text-xl font-bold antialiased">Archived Credit Cards</div>
    <svg width="16" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg"
    class="transition-transform duration-300 ease-in-out"
    [ngClass]="{
      'transform rotate-180': showArchived,
      'transform rotate-0': !showArchived
    }">
      <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18.667 12.6043C18.1444 12.6043 17.6217 12.4042 17.2236 12.0061L10.4759 5.25839L3.75266 11.7509C2.94008 12.5349 1.64975 12.5145 0.865745 11.6999C0.0817455 10.8893 0.104204 9.59489 0.914745 8.81293L9.08141 0.928018C9.88379 0.154226 11.1578 0.164434 11.9438 0.952518L20.1105 9.11918C20.9088 9.91748 20.9088 11.2078 20.1105 12.0061C19.7124 12.4042 19.1897 12.6043 18.667 12.6043Z"
          fill="#000000" />
    </svg>
    </div>
    <div *ngIf="showArchived" class="w-full">
      <app-card-row *ngFor="let card of archivedUserPaymentMethods" [card]="card"></app-card-row>
    </div>
  </div> -->

    <div *ngIf="upsertAchAccountState === FetchState.LOADING || plaidSetupState === FetchState.LOADING || stateRemove === FetchState.LOADING" class="absolute inset-0">
      <div class="loader-wrapper flex justify-center items-center bg-white bg-opacity-50">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"></div>
      </div>
    </div>
  </div>

</div>

<app-modal [isActive]="showConfirmRemoveModal" (close)="closeModal()" >
  <!-- Confirmation for removing ACH account -->
  <div class="flex flex-col w-full h-full items-center justify-center px-3 py-3 rounded-lg bg-stone-100 relative">
    <div class="text-center text-black text-3xl font-normal mx-4 my-12 leading-9">Are you sure you want to remove this bank account?</div>
    <div class="flex flex-col sm:flex-row w-full items-center justify-center sm:justify-around py-3 gap-4">
      <button class="button-red min-w-[225px] cursor-pointer" (click)="remove()">Remove</button>
      <button class="button-white-patron-pink min-w-[225px] cursor-pointer" (click)="closeModal()">Cancel</button>
    </div>
    <div *ngIf="stateRemove === FetchState.LOADING" class="absolute inset-0">
      <div class="loader-wrapper flex justify-center items-center bg-white bg-opacity-50">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"></div>
      </div>
    </div>
  </div>
</app-modal>
