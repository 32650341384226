<div class="content">
  <div class="center-content-container h-full py-[19px] flex flex-row-reverse justify-center items-start gap-[46px]">
    <!-- <div class="z-85 hidden xl:flex w-full flex-col justify-start items-start sticky top-10 gap-[21px]">
      <! -- Venue Operators -- >
      <div class="flex-grow bg-white shadow-sm rounded-xl p-4 w-full">
        <div *ngIf="venueStateById === FetchState.LOADING"
          class="flex justify-center items-center">
          <div class="loader-wrapper flex justify-center items-center">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        </div>

        <div *ngIf="venueStateById === FetchState.LOADED_ALL">
          <app-venue-operators [venue]="venue$ | async"></app-venue-operators>
        </div>
      </div>
      <! -- <div class="w-full">
        <app-offer-action [userOfferService]="userOfferService" [controller]="controller"></app-offer-action>
      </div>
      <div class="shadow-sm rounded-xl">
        <app-offer-how-to-earn></app-offer-how-to-earn>
      </div> -- >
    </div> -->

    <div
    class="w-full h-full overflow-y-auto flex-col justify-start items-start gap-[19px] inline-flex p-2">
    <!-- class="w-full h-full overflow-y-auto max-w-[880px] xl:max-w-[608px] flex-col justify-start items-start gap-[19px] inline-flex p-2"> -->
    <!-- class="w-full h-full overflow-y-auto center-content-container flex-col justify-start items-start gap-[19px] inline-flex p-2"> -->

      <!-- Merchant Details -->
      <div *ngIf="!!merchant && !merchant?.isActive"
        class="relative w-full flex flex-row items-center justify-between bg-pink rounded-md p-4 gap-4">
        <div *ngIf="!venue?.achAccount" class="w-full flex flex-col flex-grow transition-all duration-300 h-auto">
          <div class="w-full text-white text-xl font-bold antialiased leading-7 pb-4">Action Needed</div>
          <div class="w-full text-white text-sm font-normal leading-[21px]">A verified Merchant ID is required before you
            can create Offers.</div>
        </div>
        <!-- <div *ngIf="!!venue?.achAccount" class="w-full flex flex-col flex-grow transition-all duration-300 h-auto">
          <div class="w-full text-white text-xl font-bold antialiased leading-7 pb-4">Bank Account Linked!</div>
          <div class="w-full text-white text-sm font-normal leading-[21px]">
            {{venue?.achAccount?.bankName}} -> {{venue?.achAccount?.accountName}}</div>
        </div> -->
        <button class="button-white-patron-pink w-full max-w-[130px]"
          (click)="goToMerchant()">Merchant Details</button>
      </div>

      <!-- Bank Account -->
      <div *ngIf="!!venue && !venue?.achAccount"
        class="relative w-full flex flex-row items-center justify-between bg-pink rounded-md p-4 gap-4">
        <div *ngIf="!venue?.achAccount" class="w-full flex flex-col flex-grow transition-all duration-300 h-auto">
          <div class="w-full text-white text-xl font-bold antialiased leading-7 pb-4">Action Needed</div>
          <div class="w-full text-white text-sm font-normal leading-[21px]">A linked bank account is required before you
            can create Offers.</div>
        </div>
        <div *ngIf="!!venue?.achAccount" class="w-full flex flex-col flex-grow transition-all duration-300 h-auto">
          <div class="w-full text-white text-xl font-bold antialiased leading-7 pb-4">Bank Account Linked!</div>
          <div class="w-full text-white text-sm font-normal leading-[21px]">
            {{venue?.achAccount?.bankName}} -> {{venue?.achAccount?.accountName}}</div>
        </div>
        <button *ngIf="!venue?.achAccount" class="button-white-patron-pink w-full max-w-[130px]"
          (click)="goToAchAccount()">Link Bank Account</button>
      </div>


      <div class="flex flex-row flex-wrap justify-between items-center w-full">
        <h1 class="page-header-title">{{ venue?.name }}</h1>
        <div class="flex flex-row flex-wrap justify-center items-center">
          <div class="icon-white-zinc-700"
            (click)="editVenue()">
            <fa-icon [icon]="faEdit" class=""></fa-icon>
          </div>
        </div>
      </div>

      <div class="flex flex-row flex-wrap w-full gap-4">
        <img *ngIf="!!logoImageUrl" [src]="logoImageUrl" alt="Logo" class="h-full max-h-[175px] object-cover rounded-md aspect-[20/19]" />
        <img *ngIf="!!bannerImageUrl" [src]="bannerImageUrl" alt="Logo" class="flex-grow max-h-[175px] h-full object-cover rounded-md" />

      </div>

      <div class="flex-grow w-full">
        <div *ngIf="venueStateById === FetchState.LOADING"
          class="flex justify-center items-center">
          <div class="loader-wrapper flex justify-center items-center">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        </div>

        <div *ngIf="venueStateById === FetchState.LOADED_ALL">
          <app-venue-offers [venue]="venue" [merchant]="merchant"></app-venue-offers>
        </div>
      </div>


      <!-- <div class="flex-row justify-between items-center flex w-full">
        <div class="flex-col justify-start items-start flex flex-grow">
          <div class="w-full text-pink text-xl font-bold antialiased leading-7">{{ userOfferService.offer?.venue?.name
            ||
            userOfferService.offer?.name }}</div>
          <div *ngIf="userOfferService.offer?.venue?.venueAddresses?.length > 0"
            class="self-stretch text-black text-[15.14px] font-light leading-[17.89px]">
            {{userOfferService.offer?.venue?.venueAddresses[0] | address:"singleLine":false}}
          </div>
          <div *ngIf="userOfferService.offer?.venue?.phone?.length > 0"
            class="self-stretch text-black text-[15.14px] font-light leading-[17.89px]">
            {{userOfferService.offer?.venue?.address?.phone | phone}}
          </div>
        </div>
        <div class="cursor-pointer flex items-center justify-center h-10 w-10 bg-pink corner-25 mr-2 flex-none"
          (click)="controller.selectSheet(OfferDetailsSheet.Share)">
          <fa-icon class="fa-lg" [icon]="FaArriwUpFromBracket" [styles]="{'color': 'white'}"></fa-icon>
        </div>
      </div> -->

      <!-- <div class="w-full visible xl:hidden">
        <app-offer-action [userOfferService]="userOfferService" [controller]="controller"></app-offer-action>
      </div> -->

      <!-- <div *ngIf="!!intReturnPercentage || !!intReturnAmount" class="w-full relative">
        <div class="w-full text-black text-xl font-bold antialiased leading-7 mb-2">{{ 'YOUR_OFFER' | translate }}</div>
        <div
          class="w-full min-h-[97px] px-5 py-4 bg-zinc-200 rounded-md flex-col justify-center items-center gap-2.5 inline-flex">
          <div *ngIf="!userOfferService.isCharityOffer"
            class="self-stretch text-pink text-xl font-bold antialiased leading-7">
            <span *ngIf="!!this.intReturnPercentage">{{(this.intReturnPercentage / 100.0)}}% {{ 'BACK' | translate
              }}</span>
            <span *ngIf="!!this.intReturnAmount">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'BACK' | translate
              }}</span>
          </div>
          <div *ngIf="userOfferService.isCharityOffer"
            class="self-stretch text-pink text-xl font-bold antialiased leading-7">
            <span *ngIf="!!this.intReturnPercentage">{{(this.intReturnPercentage / 100.0)}}% {{ 'DONATION' | translate
              }}</span>
            <span *ngIf="!!this.intReturnAmount">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'DONATION' |
              translate }}</span>
          </div>
          <div class="self-stretch text-black text-lg font-light leading-normal"
            [innerHTML]="handleOfferClaimedInfo() | async"></div>
        </div>
      </div> -->

      <!-- <div *ngIf="!!userOfferService.offer?.description" class="flex-col justify-start items-start gap-[5px] flex">
        <div class="w-full text-black text-xl font-bold antialiased leading-7">About This Offer</div>
        <div class="w-full text-black text-md font-normal leading-normal">{{ userOfferService.offer.description }}</div>
      </div>
      <div *ngIf="!!userOfferService?.offer?.charityIncentive?.description"
        class="flex-col justify-start items-start gap-[5px] flex">
        <div class="w-full text-black text-xl font-bold antialiased leading-7">About The Fundraiser</div>
        <div class="w-full text-black text-md font-normal leading-normal">{{
          userOfferService?.offer?.charityIncentive?.description }}</div>
      </div>
      <div *ngIf="!!userOfferService.offer?.venue?.description"
        class="flex-col justify-start items-start gap-[5px] flex">
        <div class="w-full text-black text-xl font-bold antialiased leading-7">About {{
          userOfferService.offer?.venue?.name }}</div>
        <div class="w-full text-black text-md font-normal leading-normal">{{ userOfferService.offer.venue.description }}
        </div>
      </div> -->

      <!-- Venue Operators -->
      <!-- <div class="visible xl:hidden w-full flex-grow bg-white shadow-sm rounded-xl p-4"> -->
      <div class="visible w-full flex-grow">
        <div *ngIf="venueStateById === FetchState.LOADING"
          class="flex justify-center items-center">
          <div class="loader-wrapper flex justify-center items-center">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        </div>

        <div *ngIf="venueStateById === FetchState.LOADED_ALL">
          <app-venue-operators [venue]="venue"></app-venue-operators>
        </div>
      </div>
      <!-- <app-offer-how-to-earn class="visible xl:hidden w-full"></app-offer-how-to-earn>
      <app-offer-detail-info class="w-full" [offer]="userOfferService.offer"></app-offer-detail-info> -->
    </div>
  </div>
</div>

<!-- Overlay -->
<app-modal [isActive]="showEditVenue" (close)="closeModal()" [showClose]="false">
  <app-edit-venue [venue]="venue" (close)="closeModal()"></app-edit-venue>
</app-modal>

