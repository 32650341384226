import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Subject, debounceTime, distinctUntilChanged, filter, fromEvent, merge, startWith, switchMap, tap } from 'rxjs';
import { CharityService } from 'src/app/charity.service';
import { Charity } from 'src/app/model/charity.model';

@Component({
  selector: 'app-charity-search',
  templateUrl: './charity-search.component.html',
  styleUrls: ['./charity-search.component.css']
})
export class CharitySearchComponent implements OnInit, OnDestroy {
  @Input() placeholder: string = 'Search for a charity...';
  @Input() exlude: Charity[] = [];
  @Output() selected = new EventEmitter<Charity>();

  faTimesCircle = faTimesCircle;

  constructor(
    private el: ElementRef,
    private readonly charityService: CharityService,
    ) {}

  searchResults: Charity[] = [];
  searchTerms = new Subject<string>();
  private _lastSearchTerm: string = '';
  private _lastSearchResults: Charity[] = [];
  ngOnInit(): void {
    this.searchTerms.pipe(
      startWith(''),  // Emit an initial empty string

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // filter the local userSearchResults array based on term
      tap((term: string) => {
        // console.log('original userSearchResults', this.userSearchResults.length);
        this.searchResults = this._lastSearchResults.filter(u => u.name.toLowerCase().indexOf(term) >= 0);
        // console.log('filtered userSearchResults', this.userSearchResults.length);
      }),

      filter(term => term.length >= 1),  // Only proceed if term has at least 2 characters or is empty

      // wait for 300ms pause in events
      debounceTime(this._lastSearchTerm === "" ? 0 : 300),

      // switch to new search observable each time
      switchMap((term: string) => {
        // if (term.length >= 2) {
          this._lastSearchTerm = term;
          return this.charityService.search(`%${term}%`);
        // }
        // return of([]);
      })
    ).subscribe(results => {
      // Filter out any users that already exist in the affiliate.affiliateOperators array
      if (this.exlude?.length > 0) {
        results = results.filter(u => u.name.length > 0 && !this.exlude.some(o => o.id === u.id));
      }
      this.searchResults = results;
      this._lastSearchResults = results;
    });

    // Create an observable for clicks outside the search input
    const clicksOutside$ = fromEvent(document, 'click').pipe(
      filter((event: MouseEvent) => !this.el.nativeElement.contains(event.target))
    );

    // Create an observable for the escape key
    const escapePresses$ = fromEvent(document, 'keydown').pipe(
      filter((event: KeyboardEvent) => event.key === 'Escape')
    );

    // Merge the click and keydown observables
    const closeEvents$ = merge(clicksOutside$, escapePresses$);

    closeEvents$.subscribe(() => {
      // Clear the search field or list, and hide it
      this.searchTerms.next('');
      this.searchResults = [];
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.searchTerms.complete();
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  clearSearch(): void {
    this._lastSearchTerm = '';
    this.searchResults = [];
    this._lastSearchResults = [];
    this.searchTerms.next('');
  }

  select(entity) {
    this.selected.emit(entity);
    this.clearSearch();
  }


}
