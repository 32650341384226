import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { FetchState } from 'src/app/app.module';
import { MerchantService } from 'src/app/merchant.service';
import { Merchant } from 'src/app/model/venue.model';

@Component({
  selector: 'app-merchant-details',
  templateUrl: './merchant-details.component.html',
  styleUrls: ['./merchant-details.component.css']
})
export class MerchantDetailsComponent {
  private _merchant: Merchant;
  @Input()
  set merchant(value: Merchant) {
    this._merchant = value;
    this.setup();
  }
  get merchant(): Merchant {
    return this._merchant;
  }

  @Output() close = new EventEmitter<void>();
  merchantForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private readonly merchantService: MerchantService,
  ) {
  }

  FetchState = FetchState;
  saveState: FetchState = FetchState.NONE;

  setup() {
    this.merchantForm = this.fb.group({
      id: [{ value: this.merchant?.id, disabled: true }],
      merchantName: [this.merchant?.merchantName, Validators.required],
      networkMerchantId: [this.merchant?.networkMerchantId, Validators.required],
      visaMerchantId: [this.merchant?.visaMerchantId],
      mastercardMerchantId: [this.merchant?.mastercardMerchantId],
      amexMerchantId: [this.merchant?.amexMerchantId],
      sync: [false]
    });

    this.merchantForm.addValidators([this.merchantIdValidator()]);
  }

  get isActive(): boolean {
    return this.merchant.isActive;
  }

  get isLive(): boolean {
    return this.merchant.isLive;
  }

  public merchantIdValidator(): ValidatorFn {
    return (control: FormGroup): { [key: string]: any } | null => {
      // If the merchant is active, then we cannot sync
      if (this.isLive) {
        return { 'merchantIsLive': true };
      }
      if (this.isActive) {
        return { 'merchantIsActive': true };
      }
      return null;
    };
  }

  async saveMerchant() {
    if (this.saveState === FetchState.LOADING) {
      return;
    }

    if (this.merchantForm.valid) {
      this.saveState = FetchState.LOADING;
      // Save logic here
      const formValues = this.merchantForm.getRawValue();

      try {
        let result = await this.merchantService.upsertMerchant(this.merchant.id, formValues.merchantName, formValues.networkMerchantId, formValues.visaMerchantId, formValues.mastercardMerchantId, formValues.amexMerchantId, formValues.sync, true);
        if (!!result) {
          // Update this merchant
          Object.keys(result).forEach((key) => {
            this.merchant[key] = result[key];
          });
          // Success! Close
          this.saveState = FetchState.LOADED_ALL;
          this.close.emit();
        }
        else {
          this.saveState = FetchState.ERROR;
        }
      }
      catch (err) {
        console.error(err);

        // Update invalid controls
        if (err?.graphQLErrors?.length > 0) {
          const errors = err.graphQLErrors;
          // for(let graphqlError of errors) {
          //   if (graphqlError.message === 'invalid street') {
          //     this.merchantForm.get('street').setErrors({ server: 'Invalid street' });
          //   }
          //   if (graphqlError.message === 'invalid street2') {
          //     this.merchantForm.get('street2').setErrors({ server: 'Invalid street2' });
          //   }
          //   if (graphqlError.message === 'invalid city') {
          //     this.merchantForm.get('city').setErrors({ server: 'Invalid city' });
          //   }
          //   if (graphqlError.message === 'invalid state') {
          //     this.merchantForm.get('state').setErrors({ server: 'Invalid state' });
          //   }
          //   if (graphqlError.message === 'invalid zip') {
          //     this.merchantForm.get('zip').setErrors({ server: 'Invalid zip' });
          //   }
          // }
        }

        this.saveState = FetchState.ERROR;
      }
    }
    else {
      // Which fields are invalid?
      for(const controlName in this.merchantForm.controls) {
        if (this.merchantForm.controls[controlName].invalid) {
          console.log(`Control ${controlName} is invalid`);
        }
        if (this.merchantForm.controls[controlName].pending) {
          console.log(`Control ${controlName} is pending`);
        }
        if (!this.merchantForm.controls[controlName].valid) {
          console.log(`Control ${controlName} is not valid`);
        }
      }
    }
  }

  resetForm() {
    this.merchantForm.reset(this.merchant);
  }

  closeModal() {
    this.close.emit();
  }

}
