import { Component, Input } from '@angular/core';
import { Charity } from 'src/app/model/charity.model';
import { PatronOffer, PatronVenue } from 'src/app/model/patron-offer.model';
import { SearchResult } from 'src/app/model/search_results.model';

@Component({
  selector: 'app-search-result-row',
  templateUrl: './search-result-row.component.html',
  styleUrl: './search-result-row.component.css'
})
export class SearchResultRowComponent {

  @Input() searchResult: SearchResult;

  get title(): string {
    return this.searchResult?.label;
  }

  get subtitle(): string {
    if (this.isOffer) {
      return this.offer?.venue?.name;
    }
    else if (this.isCharity) {
      return this.charity?.description;
    }
    return null;
  }

  get isCharity(): boolean {
    return this.searchResult?.type === 'Charity' ?? false;
  }

  get isVenue(): boolean {
    return this.searchResult?.type === 'Venue' ?? false;
  }

  get isOffer(): boolean {
    return this.searchResult?.type === 'Offer' ?? false;
  }

  get charity(): Charity | null {
    return this.searchResult?.entity as Charity ?? null;
  }

  get venue(): PatronVenue | null {
    return this.searchResult?.entity as PatronVenue ?? null;
  }

  get offer(): PatronOffer | null {
    return this.searchResult?.entity as PatronOffer ?? null;
  }

  get jsonEntity(): string {
    return JSON.stringify(this.searchResult?.entity, null, 2);
  }

  get imageUrl(): string | ArrayBuffer | null | undefined {
    if (this.isCharity) {
      if (this.charity.logoImage?.imageUrl) {
        return this.charity.logoImage.imageUrl;
      }
      else if (this.charity.bannerImage?.imageUrl) {
        return this.charity.bannerImage.imageUrl;
      }
    }
    else if (this.isVenue) {
      let venueImage = this.venue.venueImages?.find((image) => image.type?.toLowerCase() === 'logo');
      if (venueImage?.imageUrl) {
        return venueImage.imageUrl;
      }
      venueImage = this.venue.venueImages?.find((image) => image.type?.toLowerCase() === 'banner');
      if (venueImage?.imageUrl) {
        return venueImage.imageUrl;
      }
    }
    else if (this.isOffer) {
      if (!!this.offer?.offerImage?.imageUrl) {
        return this.offer.offerImage.imageUrl;
      }
    }

    return null;
  }
}
