import { Entity, IEntity } from "./entity.model";


export interface IAchAccountOwner extends IEntity {
  get achAccount(): AchAccount;
  set achAccount(achAccount: AchAccount);

}


export class AchAccount extends Entity {//} implements ILedgerOwner {

  ownerId: string;
  sourceId: string;
  bankAccountType: string;
  bankName: string;
  accountName: string;
  accountStatus: string;
  accountType: string;
  // deposits: Array<AchCredit> = [];
  // withdrawals: Array<AchDebit> = [];
  // ledger: Ledger;

  override toJson(): any {
    const result: any = super.toJson();
    result.ownerId = this.ownerId;
    result.sourceId = this.sourceId;
    result.bankAccountType = this.bankAccountType;
    result.bankName = this.bankName;
    result.accountName = this.accountName;
    result.accountStatus = this.accountStatus;
    result.accountType = this.accountType;
    // result.deposits = this.deposits?.map(d => d.toJson());
    // result.withdrawals = this.withdrawals?.map(w => w.toJson());
    // result.ledger = this.ledger?.toJson();

    return result;
  }

  static override parseResponse(details: any, result?: AchAccount): AchAccount {
    if (!details) return null;
    if (!result) result = new AchAccount();

    Entity.parseResponse(details, result);

    result.ownerId = details.ownerId;
    result.sourceId = details.sourceId;
    result.bankAccountType = details.bankAccountType;
    result.bankName = details.bankName;
    result.accountName = details.accountName;
    result.accountStatus = details.accountStatus;
    result.accountType = details.accountType;
    // result.deposits = details.deposits?.map(d => AchCredit.parseResponse(d));
    // result.withdrawals = details.withdrawals?.map(w => AchDebit.parseResponse(w));
    // result.ledger = Ledger.parseResponse(details.ledger);

    return result;
  }

  override toRequest(): any {
    const result: any = super.toRequest();
    result.ownerId = this.ownerId;
    result.sourceId = this.sourceId;
    result.bankAccountType = this.bankAccountType;
    result.bankName = this.bankName;
    result.accountName = this.accountName;
    result.accountStatus = this.accountStatus;
    result.accountType = this.accountType;
    // result.deposits = this.deposits?.map(d => d.toRequest());
    // result.withdrawals = this.withdrawals?.map(w => w.toRequest());
    // result.ledger = this.ledger?.toRequest();

    return result;
  }

}
