<div class="modal-backdrop-outer"
  [ngClass]="{'modal-backdrop-outer-hidden': !isActive}"
(click)="closeModal()"></div>
<div class="modal-container pb-safe" [ngClass]="{
  'modal-active': isActive,
  'modal-inactive': !isActive,
  }"
  [ngStyle]="closeStyle"
  >
  <div class="modal-backdrop-inner" (click)="closeModal()" *ngIf="showClose"></div>
  <div class="modal-content"
  appPullDownClose
  (close)="closeModal($event)"
  [pullDownHandleRef]="pullDownHandleRef$ | async"
    [ngClass]="{
      'modal-content-wide': wideMode === ModalWidth.WIDE,
      'modal-content-normal': wideMode === ModalWidth.NORMAL,
      'modal-content-narrow': wideMode === ModalWidth.NARROW,
    }">
    <div class="w-full h-full relative">
      <div *ngIf="showClose" class="modal-handle" #myPullDownHandle
      ></div>
      <ng-content></ng-content>
      <div *ngIf="showClose" class="modal-close-button" (click)="closeModal()">
        <fa-icon [icon]="FaTimes" [styles]="{'color': 'zinc'}"></fa-icon>
      </div>
    </div>
  </div>
</div>
