import { Charity } from "./charity.model";
import { PatronOffer } from "./patron-offer.model";
import { Patron } from "./user.model";
import { Venue } from "./venue.model";


export class SearchResult {

  constructor({ id, type, label, tags, score, locations, entity }: { id: string, type: string, label?: string, tags?: string[], score: number, locations?: {latitude: number, longitude: number}[], entity?: any }) {
    this.id = id;
    this.type = type;
    this.label = label;
    this.tags = tags;
    this.score = score;
    this.locations = locations;
    this.entity = entity ? (entity.__typename === 'Offer' ? PatronOffer.parseResponse(entity) : entity.__typename === 'Venue' ? Venue.parseResponse(entity) : entity.__typename === 'Charity' ? Charity.parseResponse(entity) : null) : null;
  }

  id: string;
  type: string;
  label?: string;
  tags?: string[];
  score: number;
  locations?: {latitude: number, longitude: number}[];
  entity?: PatronOffer | Venue | Charity;

  static parseResponse(response: any): SearchResult {
    if (!response) {
      return null;
    }
    const result = new SearchResult(response);
    return result;
  }
};

export class SearchResults {

  constructor({ total, page, maxScore, moreResults, results, error }: { total?: number, page?: number, maxScore?: number, moreResults?: boolean, results?: any[], error?: string }) {
    this.total = total;
    this.page = page;
    this.maxScore = maxScore;
    this.moreResults = moreResults;
    this.results = (results ?? []).map((result: any) => SearchResult.parseResponse(result));
    this.error = error;
  }

  total: number;
  page: number;
  maxScore: number;
  moreResults: boolean;
  results: SearchResult[];
  error?: string;

  static parseResponse(response: any): SearchResults {
    if (!response) {
      return null;
    }
    const result = new SearchResults(response);
    return result;
  }
};
