<div class="max-padding main-container overflow-visible {{!!overylayImagePath ? 'justify-start' : 'justify-center'}}"
[ngStyle]="{'background-image': mainDivBgImage}">
  <div class="main-container-content">
    <div class="text-content">
      <div class="title-content">
        {{ header }}
      </div>
      <div class="subtitle-content">
        {{ subHeader }}
      </div>
      <button class="main-button {{ !!buttonClass ? buttonClass : 'button-patron-pink-borderless'}}"
      *ngIf="!!buttonLabel"
          (click)="handleMainButtonClick()">
          {{ buttonLabel }}
      </button>
    </div>
    <img
      *ngIf="!!overylayImagePath"
      class="phone-image"
      src="{{overylayImagePath}}" alt="iphone">

    </div>
</div>
