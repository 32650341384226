import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { FetchState } from '../../app.module';
import { IAchAccountOwner } from '../../model/ach_account.model';
import { AchAccountService } from '../../services/ach_account.service';
import { DwollaAccountType, DwollaServiceCustomer, User } from 'src/app/model/user.model';
import { faArrowDown, faArrowUp, faBank, faCertificate, faChain, faChainBroken, faExclamationCircle, faExclamationTriangle, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ach-account',
  templateUrl: './ach_account.component.html',
  styleUrls: ['./ach_account.component.css']
})
export class AchAccountComponent implements OnInit, OnDestroy {
  // Use getter and setter to update the value of the input
  private _owner: IAchAccountOwner;
  @Input() set owner(value: IAchAccountOwner) {
    this._owner = value;
    this.fetchDwollaServiceCustomer();
    this.fetchLinkToken();
  }
  get owner(): IAchAccountOwner {
    return this._owner;
  }

  private _controllerUsers: User[] = [];
  @Input() set controllerUsers(value: User[]) {
    this._controllerUsers = value;
    // If the current controllerUserId is not in the list, then set it to null
    if (!!this.controllerUserId && !this._controllerUsers.some(u => u.id === this.controllerUserId)) {
      this.controllerUserId = null;
    }
    // Default to the first user in the list
    if (!this.controllerUserId && this._controllerUsers?.length > 0) {
      this.controllerUserId = this._controllerUsers[0].id;
    }
  }
  get controllerUsers(): User[] {
    return this._controllerUsers;
  }

  controllerUserId: string = null;

  linkToken: string;
  plaidHandler: any;

  BankIcon = faBank;

  FetchState = FetchState;
  DwollaAccountType = DwollaAccountType;

  faCertificate = faCertificate;
  faExclamationTriangle = faExclamationTriangle;
  faExclamationCircle = faExclamationCircle;
  faTrash = faTrash;
  faChain = faChain;
  faChainBroken = faChainBroken;
  faArrowDown = faArrowDown;
  faArrowUp = faArrowUp;

  constructor(
    private readonly achAccountService: AchAccountService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {

    this.fetchLinkToken();

    this.loadScript('https://cdn.plaid.com/link/v2/stable/link-initialize.js');
  }

  dwollaServiceCustomer: DwollaServiceCustomer = null;
  dwollaServiceCustomerState: FetchState = FetchState.NONE;
  async fetchDwollaServiceCustomer() {
    if (!!this.owner?.id) {
      this.dwollaServiceCustomerState = FetchState.LOADING;
      this.dwollaServiceCustomer = await this.achAccountService.fetchDwollaServiceCustomer(this.owner.id);
      this.dwollaServiceCustomerState = FetchState.LOADED_ALL;
    }
    else {
      this.dwollaServiceCustomerState = FetchState.NONE;
      this.dwollaServiceCustomer = null;
    }
  }

  async fetchLinkToken() {
    if (this.owner?.id) {
      // let receivedRedirectUri = window.location.href;
      // let receivedRedirectUri = `https://local.getzeals.com/plaid?oauth_state_id=${this.owner.id}`;
      let receivedRedirectUri = `https://local.getzeals.com/plaid`;
      this.linkToken = await this.achAccountService.fetchLinkToken(this.owner.id, receivedRedirectUri);
      // this.linkToken = await this.achAccountService.fetchLinkToken(this.owner.id);
      localStorage.setItem("plaid_link_token", JSON.stringify(this.linkToken));
      this.setupPlaid();
    }
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    if (this.plaidHandler) {
      this.plaidHandler.destroy();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectUser(user: User) {
    this.controllerUserId = user?.id;
  }

  get canLinkAccount(): boolean {
    return (this.plaidSetupState === FetchState.LOADED_ALL) && !!this.linkToken && !this.owner.achAccount;
  }

  plaidScriptState: FetchState = FetchState.NONE;
  private plaidScriptLoaded: boolean = false;
  private fidelTimeout: any;
  private async loadScript(url: string, counter: number = 0) {
    const that = this;
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.dataset.id = 'plaid-sdk';
    this.plaidScriptState = FetchState.LOADING;

    if (this.fidelTimeout) {
      clearTimeout(this.fidelTimeout);
      this.fidelTimeout = null;
    }
    this.fidelTimeout = setTimeout(() => {
      if (!that.plaidScriptLoaded) {
        that.plaidScriptLoaded = false;
        that.plaidScriptState = FetchState.ERROR;
        console.log('error loading plaid script');
      }
    }, 10000);

    script.onload = () => {
      if (that.fidelTimeout) {
        clearTimeout(that.fidelTimeout);
        that.fidelTimeout = null;
      }
      // Script loaded successfully
      that.plaidScriptLoaded = true;
      that.linkAccountState = FetchState.NONE;
      that.plaidScriptState = FetchState.LOADED_ALL;
      console.log('plaid script loaded');

      this.setupPlaid();
    };

    script.onerror = (error) => {
      if (that.fidelTimeout) {
        clearTimeout(that.fidelTimeout);
        that.fidelTimeout = null;
      }
      that.linkAccountState = FetchState.NONE;
      that.plaidScriptLoaded = false;
      that.plaidScriptState = FetchState.ERROR;
      console.log('error loading plaid script', error);
    };

    this.renderer.appendChild(document.body, script);
  }

  plaidSetupState: FetchState = FetchState.NONE;
  upsertAchAccountState: FetchState = FetchState.NONE;
  setupPlaid() {
    if (!this.linkToken && this.plaidScriptState === FetchState.LOADED_ALL) {
      return;
    }
    this.plaidSetupState = FetchState.LOADING;
    const Plaid = window['Plaid'];
    if (!Plaid) {
      console.log("Plaid not loaded yet");
      return;
    }

    if (!!this.plaidHandler) {
      this.plaidHandler.destroy();
    }

    const that = this;

    // let receivedRedirectUri = window.location.href;
    // let receivedRedirectUri = `https://local.getzeals.com/plaid?oauth_state_id=${this.owner.id}`;
    // let receivedRedirectUri = `https://local.getzeals.com/plaid`;
    // console.log("receivedRedirectUri", receivedRedirectUri);
    // const plaidService = this.plaidService;
    this.plaidHandler = Plaid.create({
      token: this.linkToken,
      onSuccess: async (publicToken, metadata) => {
        console.log("onSuccess called", publicToken, metadata);
        if (!!metadata?.accounts && metadata.accounts.length > 0) {
          const account = metadata.accounts[0];
          try {
            that.linkAccountState = FetchState.LOADED_ALL;
            that.upsertAchAccountState = FetchState.LOADING;
            const achAccount = await this.achAccountService.upsertAchAccount(publicToken, account.id, account.name, this.owner?.id, this.controllerUserId);
            this.owner.achAccount = achAccount;
            await this.fetchDwollaServiceCustomer();
            console.log("achAccount", achAccount);
            that.upsertAchAccountState = FetchState.LOADED_ALL;
          }
          catch (error) {
            console.log("error", error);
            that.linkAccountState = FetchState.ERROR;
            that.upsertAchAccountState = FetchState.ERROR;
          }
        }
        else {
          that.linkAccountState = FetchState.NONE;
          that.upsertAchAccountState = FetchState.ERROR;
        }
        // plaidService.saveLinkResult(public_token, metadata, this.owner?.id);
      },
      onLoad: () => {
        that.plaidSetupState = FetchState.LOADED_ALL;
        console.log("onLoad called");
      },
      onExit: (err, metadata) => {
        if (err != null) {
          that.linkAccountState = FetchState.ERROR;
          console.log("onCardEnrollFailedCallback called");
          console.log(arguments);
        }
        else {
          that.linkAccountState = FetchState.NONE;
          console.log("onUserCancelledCallback called");
        }
      },
      onEvent: (eventName, metadata) => {
        console.log("onEvent called", eventName, metadata);
      },
      // //required for OAuth; if not using OAuth, set to null or omit:
      // receivedRedirectUri: receivedRedirectUri,
    });
  }

  linkAccountState: FetchState = FetchState.NONE;
  pendingShowLinkAccount: boolean = false;
  showLinkAccount() {
    if (!!this.owner?.id && !!this.linkToken && this.plaidSetupState === FetchState.LOADED_ALL) {
      this.linkAccountState = FetchState.LOADING;
      this.pendingShowLinkAccount = false;
      this.plaidHandler.open();
    }
    else {
      this.pendingShowLinkAccount = true;
      this.linkAccountState = FetchState.NONE;
    }
  }

  get stateRemove(): FetchState {
    return this.achAccountService.stateRemove;
  }
  clearRemovedMessage() {
    this.achAccountService.stateRemove = FetchState.NONE;
  }

  showConfirmRemoveModal: boolean = false;
  openConfirmRemove() {
    if (!!this.owner?.id) {
      this.showConfirmRemoveModal = true;
    }
  }
  closeModal() {
    this.showConfirmRemoveModal = false;
  }

  async remove() {
    if (!!this.owner?.id) {
      let result = await this.achAccountService.remove(this.owner);
      if (result) {
        this.owner.achAccount = null;
        if (!this.linkToken || this.linkToken.length === 0) {
          this.fetchLinkToken();
        }
      }

      this.closeModal();
    }
  }

  // removeUserPaymentMethod(id: string) {
  //   this.plaidService.removeUserPaymentMethod(id);
  // }

}
