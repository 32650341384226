import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Affiliate } from 'src/app/model/affiliate.model';
import { AffiliateService } from 'src/app/services/affiliate.service';

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.css']
})
export class AffiliatesComponent implements OnInit, OnDestroy {

  constructor(
    private readonly affiliateService: AffiliateService,
    private readonly router: Router
  ) {

  }

  ngOnInit(): void {
    this.affiliateService.fetch();

    this.affiliateService.affiliates$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (affiliates) => {
        this.affiliates = affiliates;
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showEditAffiliate: boolean = false;

  get selectedAffiliate(): Affiliate {
    return this.affiliateService.selectedAffiliate;
  }

  createAffiliate() {
    this.affiliateService.selectedAffiliate = new Affiliate();
    this.showEditAffiliate = true;
  }

  affiliates: Affiliate[] = [];

  showDetails(affiliate: Affiliate) {
    // Go to affiliate detail page
    this.router.navigate(['/affiliate', affiliate.id]);
  }

  editAffiliate(affiliate: Affiliate) {
    this.affiliateService.selectedAffiliate = affiliate;
    this.showEditAffiliate = true;
  }

  closeModal() {
    this.showEditAffiliate = false;
  }

  handleClickOutsideModal() {
    this.closeModal();
  }
}
