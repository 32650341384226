<form class="bg-white p-8 relative flex flex-col h-full w-full min-h-max" [formGroup]="venueForm" (submit)="saveVenue()">
  <div class="flex flex-row justify-between">
    <h2 class="text-2xl mb-6 text-zinc-900">{{ venue?.id ? 'Edit' : 'New' }} Business </h2>
    <div class=" text-zinc-900 cursor-pointer">
      <fa-icon [icon]="FaClose" (click)="closeModal()"></fa-icon>
    </div>
  </div>
  <div *ngIf="venue?.archivedDate" class="text-red-500 mb-6">
    Archived: {{ venue?.archivedDate | date }}
  </div>

    <div class="flex flex-col flex-grow space-y-2 overflow-y-auto">
      <div class="flex flex-row space-x-4 items-end flex-wrap">
        <div class="flex flex-col flex-grow">
          <label class="text-sm text-grey-grey-5" for="name">Name</label>
          <input id="organization" formControlName="name" type="text" [attr.disabled]="venue?.archivedDate ? true : null" class="border rounded p-2 text-xl text-grey-grey-8" />
          <!-- <div *ngIf="venueForm.controls['organization']?.invalid && venueForm.controls['organization']?.touched" class="text-red-500 text-sm">Venue Name is required</div> -->
        </div>
        <div class="flex flex-col">
          <label class="text-sm text-grey-grey-5" for="name">Logo</label>
          <div class="relative w-[75px] h-[75px] flex flex-row justify-center items-center overflow-hidden border rounded text-grey-grey-8 bg-gray-100 cursor-pointer" (click)="openFileInput('LOGO')">
            <!-- Image preview -->
            <img *ngIf="logoImagePreviewUrl" [src]="logoImagePreviewUrl" alt="Image Preview" class="w-full h-full object-cover" />
            <div class="flex flex-col justify-center items-center">
              <fa-icon *ngIf="!logoImagePreviewUrl" [icon]="FaImage" class="text-business-blue text-2xl"></fa-icon>
              <div *ngIf="venueForm.controls['logoImageFile']?.invalid && venueForm.controls['logoImageFile']?.touched" class="text-red-500 text-sm">Logo is required</div>
            </div>

            <!-- Custom button -->
            <div *ngIf="logoImagePreviewUrl"
            class="absolute top-1 right-1">
              <fa-icon [icon]="FaImage" class="text-white text-xl"></fa-icon>
            </div>

            <!-- Hidden actual input field -->
            <input type="file" class="hidden" #logoFileInput
            (change)="onFileSelected($event, 'logoImageFile')"
            (close)="onCloseFile('logoImageFile')"
            (cancel)="onCloseFile('logoImageFile')"
            (error)="onFileSelectError($event, 'logoImageFile')" />
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5" for="name">Banner Image</label>
        <div class="relative w-full h-[175px] flex flex-row justify-center items-center overflow-hidden border rounded text-grey-grey-8 bg-gray-100 cursor-pointer" (click)="openFileInput('BANNER')">
          <!-- Image preview -->
          <img *ngIf="bannerImagePreviewUrl" [src]="bannerImagePreviewUrl" alt="Image Preview" class="w-full h-full object-cover" />
          <div class="flex flex-col justify-center items-center">
            <fa-icon *ngIf="!bannerImagePreviewUrl" [icon]="FaImage" class="text-business-blue text-4xl"></fa-icon>
            <div *ngIf="venueForm.controls['bannerImageFile']?.invalid && venueForm.controls['bannerImageFile']?.touched" class="text-red-500 text-sm">Image is required</div>
          </div>

          <!-- Custom button -->
          <div *ngIf="bannerImagePreviewUrl"
          class="absolute top-2 right-4">
            <fa-icon [icon]="FaImage" class="text-white text-4xl"></fa-icon>
          </div>
          <!-- Hidden actual input field -->
          <input type="file" class="hidden" #bannerFileInput
          (change)="onFileSelected($event, 'bannerImageFile')"
          (close)="onCloseFile('bannerImageFile')"
          (cancel)="onCloseFile('bannerImageFile')"
          (error)="onFileSelectError($event, 'bannerImageFile')" />
        </div>
      </div>
      <div class="flex flex-col flex-grow">
        <label class="text-sm text-grey-grey-5" for="description">Description</label>
        <textarea id="organization" formControlName="description" [attr.disabled]="venue?.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8 row-span-3"></textarea>
        <!-- <div *ngIf="venueForm.controls['organization']?.invalid && venueForm.controls['organization']?.touched" class="text-red-500 text-sm">Venue Name is required</div> -->
      </div>
    <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5">Street</label>
        <input formControlName="street" type="text" [attr.disabled]="venue?.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
        <div *ngIf="venueForm.controls['street']?.invalid && venueForm.controls['street']?.touched" class="text-red-500 text-sm">Street is required</div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5">Street 2</label>
        <input formControlName="street2" type="text" [attr.disabled]="venue?.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
        <div *ngIf="venueForm.controls['street2']?.invalid && venueForm.controls['street2']?.touched" class="text-red-500 text-sm">Street 2 is invalid</div>
      </div>
      <div class="flex flex-row space-x-2 flex-wrap">
        <div class="flex flex-col flex-grow">
          <label class="text-sm text-grey-grey-5">City</label>
          <input formControlName="city" type="text" [attr.disabled]="venue?.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
          <div *ngIf="venueForm.controls['city']?.invalid && venueForm.controls['city']?.touched" class="text-red-500 text-sm">City is required</div>
        </div>
        <div class="flex flex-col">
          <label class="text-sm text-grey-grey-5">State</label>
          <input formControlName="state" type="text" [attr.disabled]="venue?.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
          <div *ngIf="venueForm.controls['state']?.invalid && venueForm.controls['state']?.touched" class="text-red-500 text-sm">State is required</div>
        </div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5">Zip</label>
        <input formControlName="zip" type="text" [attr.disabled]="venue?.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
        <div *ngIf="venueForm.controls['zip']?.invalid && venueForm.controls['zip']?.touched" class="text-red-500 text-sm">Zip is required</div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5">Phone</label>
        <input formControlName="phone" type="text" [attr.disabled]="venue?.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
        <div *ngIf="venueForm.controls['phone']?.invalid && venueForm.controls['phone']?.touched" class="text-red-500 text-sm">Phone is required</div>
      </div>
    </div>

    <!-- <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Platform Percentage</label>
      <input formControlName="intPlatformPercentage" type="number" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Platform Amount</label>
      <input formControlName="intPlatformAmount" type="number" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Duration (Days)</label>
      <input formControlName="intDurationDays" type="number" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div> -->

    <div class="flex items-center space-x-4 justify-between pt-6 flex-wrap">
      <div class="flex items-center space-x-4 flex-wrap">
        <button *ngIf="!!venue?.id && !venue?.archivedDate" type="button" class="button-white-red" (click)="archiveVenue()">Archive</button>
        <button *ngIf="!!venue?.id && venue?.archivedDate" type="button" class="button-white-red" (click)="unarchiveVenue()">Unarchive</button>
      </div>
      <button type="submit" class="button-zinc-900 px-6 py-2" [disabled]="venueForm.invalid || venue?.archivedDate">Save</button>
    </div>

    <!-- Show Error Here -->
    <div *ngIf="saveState === FetchState.ERROR" class="text-red-500 mt-6">
      Error saving, please try again.
    </div>

  <!-- Loading Cover -->
  <div *ngIf="saveState === FetchState.LOADING" class="flex justify-center items-center h-full absolute inset-0 bg-gray-100 bg-opacity-25">
    <div class="loader-wrapper flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  </div>
</form>
