<div class="w-full flex-col justify-start items-start gap-2.5 flex">
  <div class="flex flex-col w-full justify-between">
    <p class="title">{{ title }}</p>
    <div *ngIf="showValidDaysAndHours" class="flex flex-row justify-between py-2">
      <p class="label">{{validDaysAndHours}}</p>
    </div>

    <div *ngIf="showMinSpend" class="flex flex-row justify-between py-2">
      <p class="label">{{ 'MIN_SPEND' | translate }}:</p>
      <div class="flex flex-col items-end">
        <p class="value">{{minSpend | currency}}</p>
        <p *ngIf="!!maxReward" class="sub-value">*max reward is {{maxReward | currency}}</p>
      </div>
    </div>
    <!-- <div *ngIf="!!maxReward" class="flex flex-row justify-between py-2">
      <p class="text-lg font-bold antialiased sm:px-0">{{ 'MAX_REWARD' | translate }}:</p>
      <p class="text-lg font-normal flex-grow text-right">{{maxReward | currency}}</p>
    </div> -->
    <div *ngIf="showValidDates" class="flex flex-row justify-between py-2">
      <p class="label">Valid Dates:</p>
      <p class="value">{{userOfferService?.validDisplayDate()}}</p>
    </div>

  </div>
  <div *ngIf="showValidDaysAndHours && hasDays" class="w-full flex-col justify-center items-center gap-2 flex">
    <div class="w-full text-black text-xl font-bold antialiased leading-7">{{ 'DAYS_AVAILABLE' | translate }}</div>
    <div class="w-full justify-between items-center gap-2 inline-flex max-w-[475px]">
      <div *ngFor="let day of AllDaysOfTheWeek" class="w-[40.57px] h-[40.57px] relative">
        <div [ngClass]="{'bg-patron-pink': isDayOfWeek(day), 'bg-white': !isDayOfWeek(day)}"
          class="w-[40.57px] h-[40.57px] left-0 top-0 absolute rounded-md"></div>
        <div [ngClass]="{'text-white': isDayOfWeek(day), 'text-black': !isDayOfWeek(day)}"
          class="w-[40.57px] h-[16.23px] left-0 top-[12.17px] absolute text-center text-opacity-95 text-xs font-normal leading-[16.91px]">
          {{dayDisplay(day)}}</div>
      </div>

    </div>
    <div class="justify-start items-start gap-[16.91px] inline-flex">
      <div class="justify-center items-center gap-[8.45px] flex">
        <div class="w-[27.05px] h-[22.82px] bg-pink rounded-md"></div>
        <div class="text-black text-xs font-normal w-full leading-[16.91px]">Offer Available</div>
      </div>
      <div class="justify-center items-center gap-[8.45px] flex">
        <div class="w-[27.05px] h-[22.82px] bg-neutral-300 rounded-md"></div>
        <div class="text-black text-xs font-normal w-full leading-[16.91px]">Offer Not Available</div>
      </div>
    </div>
  </div>

  <div *ngIf="showValidDaysAndHours && (hasStartTime || hasEndTime)" class="w-full flex-col justify-center items-center gap-2 flex">
    <div class="text-black text-xl font-bold antialiased w-full leading-7">{{ 'HOURS_AVAILABLE' | translate }}</div>
    <div class="w-full justify-around items-center flex max-w-[475px]">
      <div *ngIf="hasStartTime" class="flex-col justify-center items-center gap-[9px] inline-flex">
        <div class="px-5 py-2.5 bg-pink rounded-lg justify-start items-start gap-2.5 inline-flex">
          <div class="text-center text-white text-opacity-95 text-lg font-normal w-full  leading-normal">
            {{this.offer?.startTime | minutesToLocalizedTime}}</div>
        </div>
        <div class="w-14 h-[23px] text-center text-black text-base font-normal leading-normal">{{ 'STARTS' | translate
          }}</div>
      </div>
      <div *ngIf="hasEndTime" class="flex-col justify-center items-center gap-[9px] inline-flex">
        <div class="px-5 py-2.5 bg-pink rounded-lg justify-start items-start gap-2.5 inline-flex">
          <div class="text-center text-white text-opacity-95 text-lg font-normal w-full  leading-normal">
            {{this.offer?.endTime | minutesToLocalizedTime}}</div>
        </div>
        <div class="w-14 h-[23px] text-center text-black text-base font-normal leading-normal">{{ 'ENDS' | translate }}
        </div>
      </div>
    </div>
  </div>

</div>
