import { gql } from "apollo-angular";
import { AffiliateDetails, AffiliateOperatorDetails } from "../fragments/affiliate.fragment.graphql";

// Upsert Affiliate
export const UpsertAffiliateMutation = gql`
${AffiliateDetails}
mutation UpsertAffiliate(
      $id: String,
      $name: String,
      $description: String,
      $intPlatformPercentage: Int,
      $intPlatformAmount: Int,
      $intDurationDays: Int,
      $operatorIds: [String!],
  ) {
    upsertAffiliate(
      id: $id, name: $name, description: $description, intPlatformPercentage: $intPlatformPercentage, intPlatformAmount: $intPlatformAmount, intDurationDays: $intDurationDays, operatorIds: $operatorIds
    ) {
    ...AffiliateDetails
    }
}
`;

// Remove an Affiliate
export const RemoveAffiliateMutation = gql`
mutation RemoveAffiliate($id: String!) {
  removeAffiliate(id: $id)
}
`;

// Unarchive an Affiliate
export const UnarchiveAffiliateMutation = gql`
${AffiliateDetails}
mutation UnarchiveAffiliate($id: String!) {
  unarchiveAffiliate(id: $id) {
    ...AffiliateDetails
  }
}`;

export const InviteAffiliateOperatorMutation = gql`
${AffiliateOperatorDetails}
mutation InviteAffiliateOperator($affiliateId: String!, $userId: String!, $accept: Boolean) {
  inviteAffiliateOperator(affiliateId: $affiliateId, userId: $userId, accept: $accept) {
    ...AffiliateOperatorDetails
  }
}`;

export const RemoveAffiliateOperatorMutation = gql`
mutation RemoveAffiliateOperator($id: String!) {
  removeAffiliateOperator(id: $id)
}`;
