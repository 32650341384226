import { Injectable } from '@angular/core';
import { FetchState } from './app.module';
import { Apollo } from 'apollo-angular';
import { OperatorType, PageAndSortInput, SearchInput } from './graphql/types.graphql';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { FetchVenueImageDownloadUrl, FetchVenueImageUploadUrl, VenueQuery, VenuesQuery } from './graphql/queries/venue-offer.query.graphql';
import { Venue, VenueAddress, VenueOperator } from './model/venue.model';
import { LoginState } from './user-auth.service';
import { NavControlService } from './components/nav/nav-control.service';
import { InviteVenueOperatorMutation, RemoveVenueMutation, RemoveVenueOperatorMutation, UpsertVenueMutation } from './graphql/mutations/venue.mutation.graphql';
import { UserInvite } from './model/invite.model';


@Injectable({
  providedIn: 'root'
})
export class VenueService {
  // error: any;
  state$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  venues$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  searchTerm$ = new Subject<string>();
  private _searchTerm: string = null;
  private currentPage: number = 0;
  private pageSize: number = 100;
  private pendingSearchTerm: string;

  constructor(
    private readonly apollo: Apollo,
    private readonly navControlService: NavControlService,
  ) {
    this.searchTerm$.pipe(
      debounceTime(300),        // wait for 300ms pause in events
      distinctUntilChanged()   // only emit if value is different from previous value
    ).subscribe(searchTerm => {
      // If searchTerm has changed, then reset page to 0 and reset list
      this._searchTerm = searchTerm;
      this.clear();
      this.fetch(searchTerm, true);
    });

    this.navControlService.userState$.subscribe({
      next: (userState) => {
        if (userState === LoginState.LOGGED_IN) {
          if (this.pendingSearchTerm) {
            this.fetch(this.pendingSearchTerm);
          }

          if (this.pendingId) {
            this.fetchById(this.pendingId);
          }
        }
      }
    });
  }

  private clear() {
    this.state$.next(FetchState.NONE);
    this.currentPage = 0;
    this.venues$.next([]);
  }
  loadMore() {
    this.fetch(this._searchTerm);
  }

  fetch(searchTerm: string = null, forceRefresh: boolean = false) {
    if (this.navControlService.userState !== LoginState.LOGGED_IN) {
      this.pendingSearchTerm = searchTerm;
      this.venues$.next([]);
      return;
    }

    if (this.state$.getValue() === FetchState.LOADING) {
      return;
    }
    if (forceRefresh) {
      this.clear();
    }

    const searchInputs: Array<SearchInput> = [];
    if (!!searchTerm && searchTerm.length > 0) {
      searchInputs.push({
        value: searchTerm,
        searchFields: ['name'],
        operator: OperatorType.LIKE
      });
    }

    const sort: PageAndSortInput = {
      page: this.currentPage,
      pageSize: this.pageSize,
      sortFields: []
    }
    console.log("Fetching Venues");

    this.state$.next(FetchState.LOADING);
    this.apollo.query({
      query: VenuesQuery,
      variables: {
        search: searchInputs.map((searchInput) => {
          return {
            value: searchInput.value,
            searchFields: searchInput.searchFields,
            operator: searchInput.operator.toUpperCase()
          };
        }),
        sort: sort,
      },
      fetchPolicy: forceRefresh ? 'no-cache' : 'cache-first'
    }).subscribe({
      next: ({ data }) => {
        const venues: Venue[] = [].concat(this.venues$.value);
        if (!!data && !!data['venues']) {
          for (const entityData of data['venues']) {
            if (entityData) {
              venues.push(Venue.parseResponse(entityData));
            }
          }

          if (data['venues'].length < this.pageSize) {
            this.state$.next(FetchState.LOADED_ALL);
          }
          else {
            this.state$.next(FetchState.GOOD);
          }
        }
        else {
          this.state$.next(FetchState.LOADED_ALL);
        }
        this.currentPage++;
        this.venues$.next(venues);
      }
    })
  }


  refreshVenues() {
    this.clear();
    this.fetchVenues(true);
  }
  async fetchVenues(forceRefresh: boolean = false) {
    if (this.state$.getValue() === FetchState.LOADING) {
      return;
    }

    return this._fetchVenues(forceRefresh);
  }
  private _fetchVenuesRetryCount: number = 0;
  private async _fetchVenues(forceRefresh: boolean = false) {
    if(forceRefresh){
      this.clear();
    }

    const searchInputs: Array<SearchInput> = [];

    const sort: PageAndSortInput = {
      page: this.currentPage,
      pageSize: this.pageSize,
      sortFields: []
    }

    this.state$.next(FetchState.LOADING);
    this.apollo.query({
      query: VenuesQuery,
      variables: {
        search: searchInputs.map((searchInput) => {
          return {
            value: searchInput.value,
            searchFields: searchInput.searchFields,
            operator: searchInput.operator.toUpperCase()
          };
        }),
        sort: sort,
      }
    }).subscribe({
      next: ({ data, loading }) => {
        this._fetchVenuesRetryCount = 0;
        const venues: Venue[] = [].concat(this.venues$.value);
        if (!!data && !!data['venues']) {
          for (const entityData of data['venues']) {
            if (entityData) {
              venues.push(Venue.parseResponse(entityData));
            }
          }

          if (data['venues'].length < this.pageSize) {
            this.state$.next(FetchState.LOADED_ALL);
          }
          else {
            this.state$.next(FetchState.GOOD);
          }
        }
        else {
          this.state$.next(FetchState.LOADED_ALL);
        }
        this.currentPage++;
        this.venues$.next(venues);
      },
      error: (error) => {
        this._fetchVenuesRetryCount++;

        if (this._fetchVenuesRetryCount < 5) {
          const retryTime = 150 * Math.pow(2, this._fetchVenuesRetryCount);
          console.log(`Retrying fetchVenues in ${retryTime}ms`);
          setTimeout(() => {
            this._fetchVenues(forceRefresh);
          }, retryTime);
        }
        else {
          console.error(error);
          this.state$.next(FetchState.ERROR);
        }
      }
    })
  }

  private pendingId: string = null;
  stateById$: BehaviorSubject<FetchState> = new BehaviorSubject<FetchState>(FetchState.NONE);
  async fetchById(id: string, forceRefresh: boolean = false): Promise<Venue> {
    if (this.navControlService.userState !== LoginState.LOGGED_IN) {
      this.pendingId = id;
      return Promise.resolve(null);
    }

    if (this.stateById$.getValue() === FetchState.LOADING) {
      return Promise.resolve(null);
    }

    if (!forceRefresh) {
      const existing = this.venues$.value.find(v => v.id === id);
      if (existing) {
        this.stateById$.next(FetchState.LOADED_ALL);
        return Promise.resolve(existing);
      }
    }

    this.pendingId = null;

    this.stateById$.next(FetchState.LOADING);

    return new Promise((resolve, reject) => {
      this.apollo.query({
        query: VenueQuery,
        variables: {
          id: id,
        }
      }).subscribe({
        next: ({ data, loading }) => {
          let entity: Venue = null;
          if (!!data && !!data['venue']) {
            const entityData = data['venue'];
            if (!!entityData && !!entityData.id) {
              entity = Venue.parseResponse(entityData);
              const newEntities = [];
              for(let i=0; i<this.venues$.value.length; i++) {
                const nextEntity = this.venues$.value[i];
                if (nextEntity.id === entityData.id) {
                  newEntities.push(entity);
                }
                else {
                  newEntities.push(nextEntity);
                }
              }
              this.venues$.next(newEntities);
            }
          }

          this.stateById$.next(FetchState.LOADED_ALL);
          resolve(entity);
        },
        error: (error) => {
          console.error(error);
          this.stateById$.next(FetchState.ERROR);
          reject(error);
        }
      })
    }
    );
  }

  getVenues() {
    console.log(this.venues$.value);

    return this.venues$.value
  }

  async save(entity: Venue, venueAddress?: VenueAddress): Promise<Venue> {
    const variables = {
      id: entity.id,
      name: entity.name,
      description: entity.description,
      operatorIds: entity.venueOperators.map(o => o.id)
    };

    if (venueAddress) {
      variables['street'] = venueAddress.street;
      variables['street2'] = venueAddress.street2;
      variables['city'] = venueAddress.city;
      variables['state'] = venueAddress.state;
      variables['zip'] = venueAddress.zip;
      variables['phone'] = venueAddress.phone;
    }

    // TODO: Add affiliateId (code)
    // if (entity.affiliateVenue?.code) {
    //   variables['affiliateId'] = entity.affiliateVenue.code;
    // }

    //GraphQL mutation
    return new Promise<Venue>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: UpsertVenueMutation,
          variables
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          let result: Venue = null;
          if (data && data['upsertVenue']) {
            // If the venue is not already in the list, add it, otherwise update the existing one
            const existing = this.venues$.value.find(v => v.id === data['upsertVenue'].id);
            result = Venue.parseResponse(data['upsertVenue']);
            if (!existing) {
              const venues = [].concat(this.venues$.value);
              venues.push(result);
              this.venues$.next(venues);
            }
            else {
              const venues = this.venues$.value.map(v => {
                if (v.id === data['upsertVenue'].id) {
                  return result;
                }
                return v;
              });
              this.venues$.next(venues);
            }
          }
          resolve(result);
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

  async inviteVenueOperator(venue: Venue, userId?: string, email?: string, message?: string, forceResend: boolean = false): Promise<VenueOperator | UserInvite> {
    console.log(`inviteVenueOperator`);

    // Validate the input
    if (!venue || !venue.id) {
      return Promise.reject('Invalid venue');
    }

    //GraphQL mutation
    return new Promise<VenueOperator | UserInvite>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: InviteVenueOperatorMutation,
          variables: {
            venueId: venue.id,
            userId: userId,
            email: email,
            message: message,
            forceResend: forceResend,
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          if (data && data['inviteVenueOperator2']) {
            if (data['inviteVenueOperator2'].__typename === 'UserInvite') {
              const userInvite = UserInvite.parseResponse(data['inviteVenueOperator2']);

              // If the user invite is not already in the list, add it, otherwise update the existing one
              const existing = venue.invites.find(o => o.id === userInvite.id);
              if (!existing) {
                venue.invites.push(userInvite);
              }
              else {
                const invites = venue.invites.map(o => {
                  if (o.id === userInvite.id) {
                    return userInvite;
                  }
                  return o;
                });
                venue.invites = invites;
              }

              resolve(userInvite);
            }
            else {
              const venueOperator = VenueOperator.parseResponse(data['inviteVenueOperator2']);
              // If the venue operator is not already in the list, add it, otherwise update the existing one
              const existing = venue.venueOperators.find(o => o.id === venueOperator.id);
              if (!existing) {
                venue.venueOperators.push(venueOperator);
              }
              else {
                const venueOperators = venue.venueOperators.map(o => {
                  if (o.id === venueOperator.id) {
                    return venueOperator;
                  }
                  return o;
                });
                venue.venueOperators = venueOperators;
              }
              resolve(venueOperator);
            }
          }
          else {
            resolve(null);
          }
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }


  async removeVenueOperator(venue: Venue, id: string): Promise<void> {
    console.log(`removeVenueOperator`);
    console.dir(id);

    // Validate the input
    if (!venue || !venue.id) {
      return Promise.reject('Invalid venue');
    }

    //GraphQL mutation
    return new Promise<void>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: RemoveVenueOperatorMutation,
          variables: {
            id: id
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          if (!!data) {
            // Remove from the list of venue operators, if it exists
            const index = venue.venueOperators.findIndex(o => o.id === id);
            if (index !== -1) {
              venue.venueOperators.splice(index, 1);
            }
          }
          resolve();
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }
  // Updates the existing venue in the list
  async unarchive(id: string): Promise<Venue> {
    console.log(`unarchiveAffiliate`);
    console.dir(id);

    //GraphQL mutation
    return new Promise<Venue>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: UpsertVenueMutation,
          variables: {
            id: id
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          let result: Venue = null;
          if (data && data['unarchiveVenue']) {
            // If the venue is not already in the list, add it, otherwise update the existing one
            const existing = this.venues$.value.find(v => v.id === data['unarchiveVenue'].id);
            result = Venue.parseResponse(data['unarchiveVenue']);
            if (!existing) {
              const venues = [].concat(this.venues$.value);
              venues.push(result);
              this.venues$.next(venues);
            }
            else {
              const venues = this.venues$.value.map(v => {
                if (v.id === data['unarchiveVenue'].id) {
                  return result;
                }
                return v;
              });
              this.venues$.next(venues);
            }
          }
          resolve(result);
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

  async remove(id: string): Promise<void> {
    console.log(`removeVenue`);
    console.dir(id);

    //GraphQL mutation
    return new Promise<void>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: RemoveVenueMutation,
          variables: {
            id: id
          }
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          resolve();
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

  fetchImageUploadUrl(id: string, contentType: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.apollo.query({
        query: FetchVenueImageUploadUrl,
        variables: {
          id: id,
          contentType: contentType
        }
      }).subscribe({
        next: ({ data, loading }) => {
          let newData: any = data;
          if (newData) {
            newData = newData.fetchVenueImageUploadUrl;
            resolve(newData);
          }
          else {
            reject();
          }
        },
        error: (error) => {
          console.log(error)
          reject(error);
        }
      });
    });
  }

  fetchImageDownloadUrl(id: string, forceRefresh: boolean = false): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.apollo.query({
        query: FetchVenueImageDownloadUrl,
        variables: {
          id: id,
          forceRefresh: forceRefresh
        }
      }).subscribe({
        next: ({ data, loading }) => {
          let newData: any = data;
          if (newData) {
            newData = newData.fetchVenueImageDownloadUrl;
            resolve(newData);
          }
          else {
            reject();
          }
        },
        error: (error) => {
          console.log(error)
          reject(error);
        }
      });
    });
  }

}

