import { Entity } from "./entity.model";
import { User } from "./user.model";


export class UserInvite extends Entity { //implements IInvite {

  constructor() {
    super();
  }

  entityId: string;
  onBehalfOfEntityId: string;
  token: string;
  type: string;
  dateSent: Date;
  dateReceived: Date;
  dateAccepted: Date;
  dateRejected: Date;
  fromUser: User;
  responseBy: User;
  toEmail: string;
  toName: string;
  messageId: string;

  static override parseResponse(details: any, result?: UserInvite): UserInvite {
    if (!result) result = new UserInvite();
    Entity.parseResponse(details, result);
    result.entityId = details.entityId;
    result.onBehalfOfEntityId = details.onBehalfOfEntityId;
    result.token = details.token;
    result.type = details.type;
    result.dateSent = new Date(details.dateSent);
    result.dateReceived = new Date(details.dateReceived);
    result.dateAccepted = new Date(details.dateAccepted);
    result.dateRejected = new Date(details.dateRejected);
    result.fromUser = User.parseResponse(details.fromUser);
    result.responseBy = User.parseResponse(details.responseBy);
    result.toEmail = details.toEmail;
    result.toName = details.toName;
    result.messageId = details.messageId;
    return result;

  }
}
