<div class="flex flex-col p-2 border-t border-gray-100 ">
  <div *ngIf="!isOffer" class="flex flex-row items-center gap-2">
    <img *ngIf="!!imageUrl" [src]="imageUrl" class="w-24 h-24 object-contain rounded-lg" />
    <div class="text-gray-900 text-lg font-medium leading-normal flex flex-col justify-center items-start">
      <div class="text-xl">
        {{title}}
      </div>
      <div *ngIf="!!subtitle" class="text-gray-500 text-sm line-clamp-3 overflow-hidden text-ellipsis">
        {{subtitle}}
      </div>
    </div>
  </div>
  <div *ngIf="!!isOffer">
    <app-offer-list [offer]="offer" ></app-offer-list>
  </div>

</div>
