import { Entity } from "./entity.model";

export class Waitlist extends Entity {
    waitlistType: string;
    name: string;
    companyName: string;
    street: string;
    street2?: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    phone: string;
    email: string;
    website: string;

    override toRequest(): any {
        const result: any = {};
        result.waitlistType = this.waitlistType;
        result.name = this.name;
        result.companyName = this.companyName;
        result.street = this.street;
        result.street2 = this.street2;
        result.city = this.city;
        result.state = this.state;
        result.zip = this.zip;
        result.country = this.country;
        result.phone = this.phone;
        result.email = this.email;
        result.website = this.website;

        return result;
    }
}
