import { gql } from "apollo-angular";
import { FullUserDetails } from "../fragments/user.fragment.graphql";
import { AchAccountDetails } from "../fragments/ach_account.fragment.graphql";

export const CurrentUserQuery = gql`
${FullUserDetails}
query CurrentUser($id: String!) {
  user(id: $id) {
    ...FullUserDetails
  }
}
`;
