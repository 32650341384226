import { gql } from "apollo-angular";
import { PaymentMethodTransactionDetails } from "./payment_method_transaction.fragment.graphql";

export const RewardDetails = gql`
fragment RewardDetails on Reward {
  id
  createdDate
  matchedDate
  claim {
    id
    claimedDate
    offer {
      id
      name
      venue {
        id
        name
      }
    }
    patron {
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
  transaction {
    ...PaymentMethodTransactionDetails
  }
}
${PaymentMethodTransactionDetails}
`;
