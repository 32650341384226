<div class="flex flex-col justify-start items-center w-full h-full bg-gray-100">
<div class=" max-w-4xl w-full h-full mt-6 p-4" >
  <div *ngIf="!merchant && fetchMerchantState === FetchState.LOADED_ALL" class="w-full h-full flex flex-col justify-center items-center pb-12">
    <div class="text-xl font-semibold antialiased mb-4 text-grey-900">No Merchant Found</div>
    <button class="button-business-blue" (click)="editMerchant()">Create Merchant</button>
  </div>

  <div *ngIf="!!merchant" class="flex flex-row justify-between">
    <h2 class="text-xl font-semibold antialiased mb-4 text-grey-900">Merchant Details</h2>
    <!-- <div *ngIf="!merchant?.isActive && !merchant?.isLive" class="cursor-pointer flex flex-col justify-center items-center w-10 h-10 bg-business-blue rounded-md" (click)="editMerchant()"> -->
    <div class="cursor-pointer flex flex-col justify-center items-center w-10 h-10 bg-business-blue rounded-md" (click)="editMerchant()">
      <fa-icon [icon]="faEdit" class="text-white text-lg"></fa-icon>
    </div>

  </div>
  <div *ngIf="fetchMerchantState === FetchState.LOADING" class="flex justify-center items-center">
    <div class="loader-wrapper flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  </div>

  <div *ngIf="!!merchant && fetchMerchantState === FetchState.LOADED_ALL">
    <div class="flex flex-col flex-wrap justify-between items-start">
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5" for="merchantName">Merchant Name</label>
        <div class="rounded p-2 text-xl text-grey-grey-8">{{merchant?.merchantName}}</div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-grey-grey-5" for="merchantName">Network Merchant ID</label>
        <div class="rounded p-2 text-xl text-grey-grey-8">{{merchant?.networkMerchantId}}</div>
      </div>
      <div class="flex flex-col w-full">
        <label class="text-sm text-grey-grey-5" for="merchantName">Locations</label>
      <div class="flex flex-row justify-between items-start w-full my-2 p-2 bg-white" *ngFor="let merchantLocation of merchant?.merchantLocations">
        <div class="flex flex-col">
          <div class="flex flex-col">
            <label class="text-sm text-grey-grey-5" for="merchantName">Address</label>
            <div class="rounded p-2">
              <div class="text-lg text-grey-grey-8">{{merchantLocation?.location?.address}}</div>
              <div class="text-lg text-grey-grey-8">{{merchantLocation?.location?.city}}, {{merchantLocation?.location?.stateCode}}  {{merchantLocation?.location?.postcode}}</div>
            </div>
          </div>
          <!-- <div class="flex flex-col">
            <label class="text-sm text-grey-grey-5" for="merchantName">Service ID</label>
            <div class="rounded p-2 text-xl text-grey-grey-8">{{merchantLocation?.serviceId}}</div>
          </div> -->
          <div class="flex flex-col" *ngIf="!!merchantLocation?.errorCode">
            <label class="text-sm text-grey-grey-5" for="merchantName">Error Code</label>
            <div class="rounded p-2 text-xl text-red-700">{{merchantLocation?.errorCode}}</div>
          </div>
          <div class="flex flex-col" *ngIf="!!merchantLocation?.errorMessage">
            <label class="text-sm text-grey-grey-5" for="merchantName">Error Message</label>
            <div class="rounded p-2 text-xl text-red-700">{{merchantLocation?.errorMessage}}</div>
          </div>
        <!-- </div>
        <div class="flex flex-col justify-between"> -->
          <div class="flex flex-col">
            <label class="text-sm text-grey-grey-5" for="merchantName">Status</label>
            <div class="rounded p-2 text-xl text-grey-grey-8">{{merchantLocation?.location?.live ? 'LIVE' : (merchantLocation?.location?.active ? 'ACTIVE' : 'PRE')}}</div>
          </div>
          <div class="flex flex-col items-start">
            <div class="text-sm text-red-700" [ngClass]="{'!text-green-700 font-bold': merchantLocation?.location?.visa?.status?.toLowerCase() === 'active'}" >VISA: {{merchantLocation?.location?.visa?.status}}</div>
            <div class="text-sm text-red-700" [ngClass]="{'!text-green-700 font-bold': merchantLocation?.location?.mastercard?.status?.toLowerCase() === 'active'}" >MasterCard: {{merchantLocation?.location?.mastercard?.status}}</div>
            <div class="text-sm text-red-700" [ngClass]="{'!text-green-700 font-bold': merchantLocation?.location?.amex?.status?.toLowerCase() === 'active'}" >AMEX: {{merchantLocation?.location?.amex?.status}}</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>

<!-- Merchant Overlay -->
<app-modal [isActive]="showEditMerchant" (close)="closeMerchantModal()">
  <app-merchant-details [merchant]="merchant" (close)="closeMerchantModal()"></app-merchant-details>
</app-modal>
