<div class="w-full h-full flex justify-center items-start gz-bg-gradient">
  <div class="flex flex-col justify-start items-start py-[50px] px-[50px] lg:px-[200px] w-full h-full max-w-8xl">
      <p class="text-3xl font-bold antialiased text-[36px] text-center mb-10">{{ 'FAQ_TITLE' | translate }}</p>

      <div class="w-full p-4 border-grey-300 border-t"
          *ngFor="let question of questions; let i = index">
          <button class="w-full flex flex-row justify-between items-center rounded-xl" (click)="toggleFAQ(question, i)">
              <p class="text-patron-pink text-md font-bold antialiased">{{question.question}}</p>
              <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="transition-transform duration-300 ease-in-out"
                [ngClass]="{
                  'transform rotate-180': question.isOpen,
                  'transform rotate-0': !question.isOpen
                }">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M18.667 12.6043C18.1444 12.6043 17.6217 12.4042 17.2236 12.0061L10.4759 5.25839L3.75266 11.7509C2.94008 12.5349 1.64975 12.5145 0.865745 11.6999C0.0817455 10.8893 0.104204 9.59489 0.914745 8.81293L9.08141 0.928018C9.88379 0.154226 11.1578 0.164434 11.9438 0.952518L20.1105 9.11918C20.9088 9.91748 20.9088 11.2078 20.1105 12.0061C19.7124 12.4042 19.1897 12.6043 18.667 12.6043Z"
                      fill="#ed2d92" />
              </svg>
          </button>

          <div *ngIf="question.isOpen" class="w-full">
            <p class="text-sm mt-5">{{question.answer}}
            </p>
          </div>
      </div>
      <div style="height: 50px;" class="flex justify-center mb-[75px] -mt-10">
          <button class="w-full button-patron-pink hover:border-patron-pink mt-[75px]"
            (click)="goToContact()"
              style="width: 225px; height: 50px; margin-left: 10px; border-color: #ED2D92; border-radius: 25px;">
              {{ 'CONTACT_SALES' | translate }}
          </button>
      </div>
  </div>
</div>
