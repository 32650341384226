import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { NavControlService, NavRole } from '../components/nav/nav-control.service';
import { LoginState } from '../user-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { OfferFilter } from '../offers.service';
import { faMagnifyingGlassDollar } from '@fortawesome/free-solid-svg-icons';
import { User } from '../model/user.model';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit, OnDestroy {

  private _currentOffset: number = 0;
  getHeight(): string {
    let result = `${450 - this._currentOffset}px`;
    console.log(`getHeight: ${result}`);
    return result;
  }

  isExpanded: boolean = false;
  toggleExpanded() {
    this.navControlService.toggleNavHeaderExpanded()
  }
  private _mainBgImage: string = "url('/assets/images/heading-treat.jpg')";
  get mainBgImage(): string {
    return this._mainBgImage;
  }

  getLogoImgUrl(ignoreExpanded: boolean = false): string {
    if (this.isExpanded && !ignoreExpanded) {
      return '/assets/ZealsLogoWhite.png';
    }
    return '/assets/CombZealsLogo.png';
  }

  LoginState = LoginState;
  NavRole = NavRole;

  FaOffers = faMagnifyingGlassDollar;

  constructor(
    public readonly translateService: TranslateService,
    protected readonly navControlService: NavControlService,
    public router: Router,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url === '/' || event.url === '/home') {
        this.navControlService.expandNavHeader();
      }
      else {
        this.navControlService.collapseNavHeader();
      }
    });

    // Set initial state based on the current URL
    this.isExpanded = this.router.url === '/' || this.router.url === '/home';

    this.navControlService.userState$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (userState) => {
        this.userState = userState;
      }
    });

    this.navControlService.isNavHeaderExpanded$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (isExpanded) => {
        setTimeout(() => {
        // requestAnimationFrame(() => {
          this.isExpanded = isExpanded;
        // });
        }, 1);
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get user(): User {
    return this.navControlService.user;
  }
  userState: LoginState;

  goToHowItWorks() {
    this.navControlService.goToHowItWorks();
  }
  closeHowItWorks() {
    this.navControlService.hideHowItWorks();
  }
  toggleHowItWorks() {
    this.navControlService.showHowItWorks();
  }

  goToOffers() {
    this.navControlService.goToOffers([OfferFilter.NEAR_ME]);
  }

  goToPatronCreditCards() {
    this.navControlService.goToPatronCreditCards();
  }

  goToPatronBank() {
    this.navControlService.goToPatronBank();
  }

  hasRole(role: NavRole) {
    return this.navControlService.hasRole(role);
  }

}
