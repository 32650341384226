import { gql } from "apollo-angular";
import { FullUserDetails, UserDetails } from "../fragments/user.fragment.graphql";



export const UpsertUserMutation = gql`
${FullUserDetails}
mutation UpsertUser($id: String!, $firstName: String, $lastName: String, $emailAddress: String, $phone: String, $isPatron: Boolean, $isOperator: Boolean, $isAdmin: Boolean) {
  upsertUser(id: $id, firstName: $firstName, lastName: $lastName, emailAddress: $emailAddress, phone: $phone, isPatron: $isPatron, isOperator: $isOperator, isAdmin: $isAdmin) {
    ...FullUserDetails
  }
}
`;
