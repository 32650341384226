import { Component, Input } from '@angular/core';
import { faBank } from '@fortawesome/free-solid-svg-icons';
import { CharityCredit, LedgerSettleResults, PatronCredit, Payout, Reward } from 'src/app/model/ledger';
import { capitalize } from 'src/app/utils/string.utils';

@Component({
  selector: 'app-earning-row',
  templateUrl: './earning-row.component.html',
  styleUrls: ['./earning-row.component.css']
})
export class EarningRowComponent {
  @Input() creditDebit: PatronCredit | CharityCredit | Payout | LedgerSettleResults | any;

  BankIcon = faBank;

  get isCharity(): boolean {
    return this.creditDebit instanceof CharityCredit;
  }

  get isPayout(): boolean {
    return this.creditDebit instanceof LedgerSettleResults || this.creditDebit instanceof Payout;
  }

  get lastFour(): string {
    return this.creditDebit?.reward?.transaction?.paymentMethod?.lastNumbers;
  }

  get cleared(): boolean {
    return !!this.creditDebit?.clearedDate && this.creditDebit?.clearedDate !== null && this.creditDebit?.clearedDate !== undefined && this.creditDebit?.clearedDate !== "" && this.creditDebit?.clearedDate.getTime() > 0;
  }

  get logoImageUrl(): string | ArrayBuffer | false {
    let result: string | ArrayBuffer | false = false;
    if (this.creditDebit instanceof CharityCredit) {
      result = this.creditDebit.incentive?.charity?.logoImage?.imageUrl ?? false;
    }
    if (!result) {
      result = this.creditDebit.reward?.transaction?.venue?.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.imageUrl ?? false;
    }
    return result;
  }

  get venueName(): string {
    if (this.creditDebit instanceof CharityCredit) {
      return this.creditDebit.reward?.transaction?.venue?.name;
    }
    else if (this.creditDebit instanceof PatronCredit) {
      return this.creditDebit.reward?.transaction?.venue?.name;
    }
    else if (this.creditDebit instanceof Payout) {
      return this.creditDebit.institutionName;
    }
    else if (this.creditDebit instanceof LedgerSettleResults) {
      return "Cash Payout";
    }
    else {
      return this.creditDebit?.reward?.transaction?.venue?.name;
    }
  }

  get charityName(): string | null {
    if (this.creditDebit instanceof CharityCredit) {
      return this.creditDebit.incentive?.charity?.name;
    }
    else {
      return null;
    }
  }

  get txDate(): Date {
    if (this.creditDebit instanceof LedgerSettleResults) {
      return this.creditDebit.settleDate;
    }
    else if (this.creditDebit instanceof Payout) {
      return this.creditDebit.txDate;
    }
    return this.creditDebit?.reward?.transaction?.datetime;
  }

  get txDateIsCurrentYear(): boolean {
    if (this.creditDebit instanceof LedgerSettleResults) {
      return this.creditDebit.settleDate.getFullYear() === new Date().getFullYear();
    }
    else if (this.creditDebit instanceof Payout) {
      return this.creditDebit.txDate.getFullYear() === new Date().getFullYear();
    }
    return this.creditDebit?.reward?.transaction?.datetime.getFullYear() === new Date().getFullYear();
  }

  get isCleared(): boolean {
    if (this.creditDebit instanceof LedgerSettleResults) {
      return this.creditDebit.success;
    }
    return !!this.creditDebit?.clearedDate;
  }

  get offerName(): string {
    if (this.creditDebit instanceof LedgerSettleResults) return "";
    return this.creditDebit?.reward?.claim?.offer?.name;
  }

  get status(): string {
    let result: string = "";
    if (this.creditDebit instanceof LedgerSettleResults) {
      result = this.creditDebit.success ? "Settled" : "Failed";
    }
    else if (this.creditDebit?.status?.toLowerCase() === "cleared") {
      if (this.creditDebit instanceof Payout) {
        result = "Money transfered";
      }
      else {
        result = "Cleared";
      }
    }
    else {
      result = this.creditDebit?.status || "Pending";
    }

    return capitalize(result);
  }

  get intReturnPercentage(): number {
    return this.creditDebit?.incentive?.intReturnPercentage;
  }

  get intReturnAmount(): number {
    return this.creditDebit?.incentive?.intReturnAmount;
  }

  get intAmount(): number {
    if (this.creditDebit instanceof LedgerSettleResults) {
      return this.creditDebit.intSettleAmount;
    }
    // If this is a debit, then the amount should be returned as a negative number
    if (this.creditDebit instanceof Payout) {
      return this.creditDebit.intAmount * -1;
    }
    // else if (this.creditDebit instanceof VenueDebit) {
    //   return this.creditDebit.intAmount * -1;
    // }

    return this.creditDebit?.intAmount || 0;
  }

  get intTxAmount(): number {
    if (this.creditDebit instanceof LedgerSettleResults) {
      return this.creditDebit.intSettleAmount;
    }
    return this.creditDebit?.reward?.transaction?.intAmount || 0;
  }
}
