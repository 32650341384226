<div class="flex flex-col justify-center w-full gap-4" [formGroup]="incentiveGroup">
  <div class="group-content-container">
    <div class="flex flex-row justify-between items-center w-full">
      <p class="subsection-header-title">Fundraiser</p>
      <label class="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" formControlName="isCharity" class="sr-only peer">
        <div
          class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-business-blue">
        </div>
      </label>
    </div>

    <div *ngIf="isCharity" class="w-full flex flex-col bg-white rounded-xl p-4">
      <app-charity-search (selected)="selectCharity($event)"></app-charity-search>
    </div>
  </div>

  <!-- View Charities Popup -->
  <!-- <div *ngIf="isCharity && charityPopup" class="fixed inset-0 flex items-center justify-center z-50">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" (click)="charityPopup = false"></div>

    <div
      class="bg-white rounded-lg text-left overflow-y-auto shadow-lg transform transition-all max-w-xl max-h-[80vh] w-full sm:w-3/4 lg:w-1/2">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
          Charities
        </h3>
        <div class="overflow-y-auto h-full">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased mb-2" for="grid-last-name">
            Search
          </label>
          <input
            class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            formControlName="charitySearchTerm" type="text">
          <div class="overflow-y-auto">
            <div *ngFor="let charity of this.charityService.charities$ | async" class="w-[100%] mt-6">
              <div (click)="selectCharity(charity)" class="flex flex-col justify-start w-full cursor-pointer">
                <p class="text-xl font-bold antialiased text-charity-green">{{charity.name}}</p>
              </div>
              <div class="bg-gray-200 w-full h-[2px] mt-5"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 flex justify-between w-full">
        <div *ngIf="charityService.state !== FetchState.GOOD" class="w-0"></div>
        <div *ngIf="charityService.state === FetchState.GOOD" (click)="loadMoreCharities()" class="bg-blue-500 text-charity-green p-2 rounded">
          Load More
        </div>
        <div (click)="charityPopup = false" class="bg-blue-500 text-black p-2 rounded">
          Close
        </div>
      </div>
    </div>
  </div> -->

  <div class="group-content-container">
    <div *ngIf="returnType === OfferReturnType.Percentage" class="px-3 w-full mt-4">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased mb-2" for="percentage">
        Percentage
      </label>
      <div class="flex flex-row justify-around">
        <input
          class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="percentage" type="number" formControlName="percentage" (blur)="limitDecimalPlaces($event, 2, 100)">
        <div type="button" class="ml-2 text-xl flex flex-row items-center justify-between w-8 cursor-pointer"
          (click)="toggleReturnType()">
          <p>%</p>
          <p>↕</p>
        </div>
      </div>
    </div>
    <div *ngIf="returnType === OfferReturnType.Amount" class="px-3 w-full mt-4">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased mb-2" for="amount">
        Cash
      </label>
      <div class="flex flex-row justify-around">
        <input
          class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="amount" type="number" formControlName="amount" (blur)="limitDecimalPlaces($event, 2)">
        <div type="button" class="ml-2 text-xl flex flex-row items-center justify-between w-8 cursor-pointer"
          (click)="toggleReturnType()">
          <p>$ </p>
          <p>↕</p>
        </div>
      </div>
    </div>

    <div class="px-3 w-full mt-4">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased mb-2" for="minSpend">
        Minimum Spend
      </label>
      <div class="flex flex-row justify-around">
        <button type="button" class="text-xl">
          $
        </button>
        <input
          class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ml-2"
          id="minSpend" type="number" formControlName="minSpend">
      </div>
    </div>

    <div class="px-3 w-full mt-4">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased mb-2" for="maxReward">
        Maximum Reward
      </label>
      <div class="flex flex-row justify-around">
        <button type="button" class="text-xl">
          $
        </button>
        <input
          class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ml-2"
          id="maxReward" type="number" formControlName="maxReward">
      </div>
    </div>
  </div>
</div>
