<div class="w-full px-0 pt-1">
<div class="flex flex-row w-full items-center px-3 py-3 rounded-lg bg-grey-100"
>
<div *ngIf="removeCardState === FetchState.LOADING" class="w-full">
  <div class="loader-wrapper flex justify-center items-center">
    <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"></div>
  </div>
</div>

<div *ngIf="removeCardState !== FetchState.LOADING" class="gap-0 flex-row w-full justify-between items-center flex-1 p-0 flex">
  <div class="flex flex-col items-start justify-start">
    <div class="text-lg font-normal text-black text-left w-full">{{cardDisplay}}</div>
    <div class="text-xs font-light text-#ed2d92 text-left w-full">{{cardNumbers}}</div>
  </div>

  <div class="flex flex-row items-center justify-center gap-2 h-full">
    <div class="flex flex-col w-full items-center justify-end">

      <div *ngIf="isLive" class="text-md font-bold antialiased text-#ed2d92 text-right w-full">Live</div>
      <div *ngIf="hasDateExpires" class="text-xs font-light text-#ed2d92 text-right w-full">{{dateExpires}}</div>
    </div>
    <svg *ngIf="!isArchived" class="fillDeleteIcon cursor-pointer" width="22" height="22.5" viewBox="0 0 22 22.5" fill="none" xmlns="http://www.w3.org/2000/svg"
      (click)="removeUserPaymentMethod()">
      <rect y="0.25" width="22" height="22" rx="11" fill="white" />
      <path d="M6 6l10 10m0-10L6 16" stroke="#ff0000" stroke-width="2"></path>
    </svg>
  </div>
</div>

</div>
</div>
