import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { faClose, faImage } from '@fortawesome/free-solid-svg-icons';
import { FetchState } from 'src/app/app.module';
import { IImage } from 'src/app/model/image.model';
import { Venue, VenueAddress } from 'src/app/model/venue.model';
import { S3Service } from 'src/app/s3.service';
import { VenueService } from 'src/app/venue.service';

@Component({
  selector: 'app-edit-venue',
  templateUrl: './edit-venue.component.html',
  styleUrls: ['./edit-venue.component.css']
})
export class EditVenueComponent {
  private _venue: Venue;
  @Input()
  set venue(value: Venue) {
    this._venue = value;
    this.setup();
  }
  get venue(): Venue {
    return this._venue;
  }

  @Output() close = new EventEmitter<void>();
  venueForm: FormGroup;

  @ViewChild('bannerFileInput') bannerFileInput: ElementRef;
  @ViewChild('logoFileInput') logoFileInput: ElementRef;

  FaImage = faImage;
  FaClose = faClose;

  constructor(
    private fb: FormBuilder,
    private readonly venueService: VenueService,
    private readonly s3Service: S3Service,
  ) {
  }

  FetchState = FetchState;
  saveState: FetchState = FetchState.NONE;

  setup() {
    const address = this.venue?.venueAddresses[0];
    this.venueForm = this.fb.group({
      id: [{ value: this.venue?.id, disabled: true }],
      bannerImageFile: [null, this.imageValidator('BANNER')],
      logoImageFile: [null, this.imageValidator('LOGO')],
      name: [this.venue?.name, Validators.required],
      description: [this.venue?.description],
      street: [address?.street, Validators.required],
      street2: [address?.street2],
      city: [address?.city, [Validators.required, Validators.minLength(2)]],
      state: [address?.state, [Validators.required, Validators.minLength(2)]],
      // Valid pattern is 5 digits or 5 digits dash 4 digits
      zip: [address?.zip, [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]],
      // Exhaustive phone regex
      phone: [address?.phone, [Validators.required, Validators.pattern('^(\\+?\\d{1,2}[\\s-]?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$')]],
      archivedDate: [this.venue?.archivedDate],
    });
  }

  private getImageByType(imageType: string): IImage {
    return this.venue?.venueImages?.find(image => image.type?.toUpperCase() === imageType);
  }

  public imageValidator(imageType: string): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      const selectedFile = control?.value;
      const currentImage = this.getImageByType(imageType);
      if (!!currentImage?.url || !!selectedFile) {
        return null;
      }

      return { imageRequired: true };
    };
  }

  async saveVenue() {
    if (this.saveState === FetchState.LOADING) {
      return;
    }

    if (this.venueForm.valid) {
      this.saveState = FetchState.LOADING;
      // Save logic here
      const venueFormValues = this.venueForm.getRawValue();
      // this.affiliate.id = affiliate.id ?? this;
      this.venue.name = venueFormValues.name ?? this.venue.name;
      this.venue.description = venueFormValues.description ?? this.venue.description;
      let address = this.venue.venueAddresses[0];
      if (!address) {
        address = new VenueAddress();
        this.venue.venueAddresses.push(address);
      }
      address.street = venueFormValues.street ?? address.street;
      address.street2 = venueFormValues.street2 ?? address.street2;
      address.city = venueFormValues.city ?? address.city;
      address.state = venueFormValues.state ?? address.state;
      address.zip = venueFormValues.zip ?? address.zip;
      address.phone = venueFormValues.phone ?? address.phone;

      // this.venue.intPlatformPercentage = affiliate.intPlatformPercentage ?? this.venue.intPlatformPercentage;
      // this.venue.intPlatformAmount = affiliate.intPlatformAmount ?? this.venue.intPlatformAmount;
      // this.venue.intDurationDays = affiliate.intDurationDays ?? this.venue.intDurationDays;
      this.venue.archivedDate = venueFormValues.archivedDate ?? this.venue.archivedDate;

      try {
        let result = await this.venueService.save(this.venue, address);
        if (!!result) {
          // Success! Close

          // Step 2: Fetch Upload URL for Banner Image
          const bannerSelectedFile = this.venueForm.get('bannerImageFile')?.value;
          const bannerImage = result.venueImages?.find(image => image.type?.toUpperCase() === 'BANNER');
          let originalBannerImage = this.venue?.venueImages?.find(image => image.type?.toUpperCase() === 'BANNER');
          if (!originalBannerImage && !!bannerImage) {
            originalBannerImage = bannerImage;
            this.venue.venueImages.push(originalBannerImage);
          }
          if (!originalBannerImage?.id) {
            originalBannerImage.id = bannerImage?.id;
          }

          if (bannerSelectedFile && bannerImage) {
            try {
              const uploadUrl = await this.venueService.fetchImageUploadUrl(bannerImage.id, bannerSelectedFile.type);

              // Step 2: Upload File to S3
              const uploadResult = await this.s3Service.uploadFileToS3(uploadUrl, bannerSelectedFile);
              console.log(`uploadResult`, uploadResult);

              // Get the download url
              bannerImage.url = await this.venueService.fetchImageDownloadUrl(bannerImage.id, true);
              originalBannerImage.url = bannerImage.url;
              originalBannerImage.preview = this.selectedBannerFilePreviewUrl;
            }
            catch(error) {
              console.log(`error uploading file to S3`, error);
            }
          }

          // Step 3: Fetch Upload URL for Logo Image
          const logoSelectedFile = this.venueForm.get('logoImageFile')?.value;
          const logoImage = result.venueImages?.find(image => image.type?.toUpperCase() === 'LOGO');
          let originalLogoImage = this.venue?.venueImages?.find(image => image.type?.toUpperCase() === 'LOGO');
          if (!originalLogoImage && !!logoImage) {
            originalLogoImage = logoImage;
            this.venue.venueImages.push(originalLogoImage);
          }
          if (!originalLogoImage?.id) {
            originalLogoImage.id = logoImage?.id;
          }

          if (logoSelectedFile && logoImage) {
            try {
              const uploadUrl = await this.venueService.fetchImageUploadUrl(logoImage.id, logoSelectedFile.type);

              // Step 2: Upload File to S3
              const uploadResult = await this.s3Service.uploadFileToS3(uploadUrl, logoSelectedFile);
              console.log(`uploadResult`, uploadResult);

              // Get the download url
              logoImage.url = await this.venueService.fetchImageDownloadUrl(logoImage.id, true);
              originalLogoImage.url = logoImage.url;
              originalLogoImage.preview = this.selectedLogoFilePreviewUrl;
            }
            catch(error) {
              console.log(`error uploading file to S3`, error);
            }
          }


          this.saveState = FetchState.LOADED_ALL;
          this.close.emit();
        }
        else {
          this.saveState = FetchState.ERROR;
        }
      }
      catch (err) {
        console.error(err);

        // Update invalid controls
        if (err?.graphQLErrors?.length > 0) {
          const errors = err.graphQLErrors;
          for(let graphqlError of errors) {
            if (graphqlError.message === 'invalid street') {
              this.venueForm.get('street').setErrors({ server: 'Invalid street' });
            }
            if (graphqlError.message === 'invalid street2') {
              this.venueForm.get('street2').setErrors({ server: 'Invalid street2' });
            }
            if (graphqlError.message === 'invalid city') {
              this.venueForm.get('city').setErrors({ server: 'Invalid city' });
            }
            if (graphqlError.message === 'invalid state') {
              this.venueForm.get('state').setErrors({ server: 'Invalid state' });
            }
            if (graphqlError.message === 'invalid zip') {
              this.venueForm.get('zip').setErrors({ server: 'Invalid zip' });
            }
          }
        }

        this.saveState = FetchState.ERROR;
      }
    }
    else {
      // Which fields are invalid?
      for(const controlName in this.venueForm.controls) {
        if (this.venueForm.controls[controlName].invalid) {
          console.log(`Control ${controlName} is invalid`);
        }
        if (this.venueForm.controls[controlName].pending) {
          console.log(`Control ${controlName} is pending`);
        }
        if (!this.venueForm.controls[controlName].valid) {
          console.log(`Control ${controlName} is not valid`);
        }
      }
    }
  }

  // Handle file input
  selectedBannerFilePreviewUrl: string | ArrayBuffer = null;
  get bannerImagePreviewUrl(): string | ArrayBuffer {
    if (this.selectedBannerFilePreviewUrl) return this.selectedBannerFilePreviewUrl;
    const bannerImage = this.getImageByType('BANNER');
    if (bannerImage && bannerImage?.url) return bannerImage?.url;
    return null;
  }

  selectedLogoFilePreviewUrl: string | ArrayBuffer = null;
  get logoImagePreviewUrl(): string | ArrayBuffer {
    if (this.selectedLogoFilePreviewUrl) return this.selectedLogoFilePreviewUrl;
    const logoImage = this.getImageByType('LOGO');
    if (logoImage && logoImage?.url) return logoImage?.url;
    return null;
  }
  onFileSelected(event, fileId: string) {
    if (event.target.files.length > 0) {
      const selectedFile = <File>event.target.files[0];
      this.venueForm.get(fileId).setValue(selectedFile);

      // Create Image Preview
      const reader = new FileReader();

      reader.onload = () => {
        if (fileId === 'logoImageFile') this.selectedLogoFilePreviewUrl = reader.result;
        if (fileId === 'bannerImageFile') this.selectedBannerFilePreviewUrl = reader.result;
        this.venueForm.get(fileId).setValue(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
    }
    else {
      this.venueForm.controls[fileId]?.markAsTouched();
    }
  }

  openFileInput(fileId: string): void {
    if (fileId === 'LOGO') {
      this.logoFileInput.nativeElement.click();
    }
    else if (fileId === 'BANNER') {
      this.bannerFileInput.nativeElement.click();
    }
  }

  onCloseFile(fileId: string) {
    this.venueForm.controls[fileId]?.markAsTouched();
    // If bannerImageFile is valud, then clear the errors on it
    console.log(`${fileId} is valid?`, this.venueForm.controls[fileId].getRawValue());
    if (this.venueForm.controls[fileId]?.valid) {
      this.venueForm.controls[fileId]?.setErrors(null);
    }
  }

  onFileSelectError($event, fileId: string) {
    console.log(`onFileSelectError`, $event);
  }

  unarchiveVenue() {
    if (!!this.venue.id) {
      // Call the unarchive logic here
      this.venueService.unarchive(this.venue.id)
    }
  }

  archiveVenue() {
    if (!!this.venue.id) {
      this.venueForm.get('archivedDate').setValue(new Date());
      // Call the archive logic here
      this.venueService.remove(this.venue.id)
    }
  }

  resetForm() {
    this.venueForm.reset(this.venue);
  }

  closeModal() {
    this.close.emit();
  }

}
