<div class="bg-white items-center flex flex-col p-2 w-full">
    <div class="flex flex-row justify-around items-center w-full max-w-2xl">
      <div class="flex flex-col items-center">
        <p class="text-gray-500 text-center">{{ 'APP_STORE_AVAILABLE' | translate }}</p>
        <div class="flex flex-row">
          <a href="https://apps.apple.com/us/app/getzeals/id6444629621"><img src="../../../assets/images/app-store-badge.svg" alt="app-store-badge" class="mt-[9px]"></a>
        </div>
      </div>
      <div class="flex flex-col items-center">
        <p class="text-gray-500 text-center">{{ 'APP_STORE_COMING_SOON' | translate }}</p>
        <div class="flex flex-row">
            <a href=""><img src="../../../assets/images/google-play-badge.png" alt="google-play-badge"
                    class="w-[150px]"></a>
        </div>
      </div>
    </div>
    <ul class="leading-10 flex flex-row justify-center mt-2">
        <li class="mx-2 text-gray-500 text-sm font-normal"><a [routerLink]="'/term/'">{{ 'TERMS_OF_SERVICE' | translate }}</a></li>
        <li class="mx-2 text-gray-500 text-sm font-normal"><a [routerLink]="'/privacy/'">{{ 'PRIVACY_POLICY' | translate }}</a></li>
    </ul>
</div>

<div class="bg-white w-full p-2 flex flex-row items-center justify-center">
    <p class="text-gray-500 text-xs ml-2 font-sans font-normal">{{ 'COPYRIGHT' | translate }}</p>
</div>
