<div class="flex flex-col mt-12 relative h-full max-h-[95vh]">
  <div class="main-content w-full h-full top-0 bottom-0 transition-all ease-cubic-bezier duration-200"
    [ngClass]="{'translate-x-0': !showDetails, '-translate-x-full': showDetails}">

    <div class="flex flex-col w-full h-full">
      <div class="text-center text-black text-4xl font-bold antialiased mb-2">
        Account
      </div>

      <div class="flex-grow flex flex-col overflow-y-auto p-4 w-full justify-between h-full">
        <div class="flex-grow overflow-y-auto w-full">
          <div *ngIf="role === NavRole.VENUE && !!venue" class="w-full mb-8 p-4">
            <div
              class="w-full px-2 py-1.5 bg-business-blue rounded-sm justify-start items-center inline-flex relative cursor-pointer"
              (click)="goToVenue()">
              <div class="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                <div class="text-white text-base font-medium leading-normal">{{venue?.name}}</div>
              </div>
              <!-- <div class="absolute right-0">
              <fa-icon [icon]="FaChevronUp" [styles]="{'color': 'zinc'}" class="mr-3"></fa-icon>
            </div> -->
            </div>
            <div class="w-full pl-10 pt-3 flex-col justify-center items-start gap-4 inline-flex">
              <div
                class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer flex flex-row justify-start items-center"
                (click)="goToVenueBank()">
                {{ 'LINKED_BANK_ACCOUNT' | translate }}
              </div>
              <div
                class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer flex flex-row justify-start items-center"
                (click)="goToVenueMerchant()">
                Merchant Details
              </div>
            </div>
          </div>

          <div *ngIf="role === NavRole.VENUE" class="w-full mt-2">

            <div
              class="w-full px-2 py-1.5 bg-gray-100 rounded-sm justify-start items-center inline-flex relative cursor-pointer"
              (click)="goToVenues()">
              <div class="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                <div class="text-gray-900 text-base font-medium leading-normal">Venues</div>
              </div>
              <div class="absolute right-0">
                <fa-icon [icon]="FaChevronUp" [styles]="{'color': 'zinc'}" class="mr-3"></fa-icon>
              </div>
            </div>
            <div class="w-full pl-10 pt-3 flex-col justify-center items-start gap-4 inline-flex">
              <div *ngFor="let venue of venues"
                class="self-stretch h-6 flex-col justify-start items-start gap-2.5 flex">
                <div class="text-gray-900 text-base font-medium leading-normal cursor-pointer"
                  (click)="goToVenue(venue.id)">{{ venue.name }}</div>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-full gap-4 my-4">
            <div *ngIf="role === NavRole.GUEST"
              class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer"
              (click)="toggleHowItWorks()">{{ 'HOW_ZEALS_WORKS_TITLE' | translate }}</div>
            <div *ngIf="role === NavRole.PATRON"
              class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer"
              (click)="goToPatronEarnings()">{{ 'EARNINGS' | translate }}</div>
            <div *ngIf="role === NavRole.PATRON"
              class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer flex flex-row justify-start items-center"
              (click)="goToPatronCreditCards()">
              {{ 'LINKED_CREDIT_CARDS' | translate }}
              <!-- <img *ngIf="isMissingCreditCard" class="w-8 h-8 object-contain ml-2" src="assets/icons/alert-circle.png" /> -->
            </div>
            <div *ngIf="role === NavRole.PATRON"
              class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer flex flex-row justify-start items-center"
              (click)="goToPatronBank()">
              {{ 'LINKED_BANK_ACCOUNT' | translate }}
              <!-- <img *ngIf="isMissingBank" class="w-8 h-8 object-contain ml-2" src="assets/icons/alert-circle.png" /> -->
            </div>
            <div *ngIf="role === NavRole.PATRON"
              class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer flex flex-row justify-start items-center"
              (click)="goToEditUser()">
              Profile
            </div>
          </div>

          <div *ngIf="hasRole(NavRole.CHARITY) || hasRole(NavRole.VENUE) || hasRole(NavRole.ADMIN)"
            class="w-full h-[1px] my-5 bg-gray-200"></div>

          <div *ngIf="hasRole(NavRole.CHARITY) || hasRole(NavRole.VENUE) || hasRole(NavRole.ADMIN)"
            class="flex flex-col w-full gap-4 my-4">
            <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer"
            (click)="setRole(NavRole.PATRON); goToOffers([OfferFilter.NEAR_ME])">My Patron
          </div>
            <div *ngIf="hasRole(NavRole.VENUE) || hasRole(NavRole.ADMIN)"
              class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer"
              (click)="handleClickBusinesses()">My Businesses</div>
          </div>


          <div class="w-full h-[1px] my-5 bg-gray-200"></div>

          <div class="flex flex-col w-full gap-4 my-4">
            <div *ngIf="role !== NavRole.GUEST" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToHowItWorks()">{{ 'HOW_ZEALS_WORKS_TITLE' | translate }}</div>
            <div *ngIf="!hasRole(NavRole.CHARITY)" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="handleClickCharities()">{{ 'CHARITIES' | translate }}</div>
            <div *ngIf="!hasRole(NavRole.VENUE)" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="handleClickBusinesses()">{{ 'BUSINESSES' | translate }}</div>
          </div>

          <div class="w-full h-[1px] my-5 bg-gray-200"></div>

          <div class="flex flex-col w-full gap-4">
            <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToAbout()">{{ 'ABOUT_US' | translate }}</div>
            <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToTerms()">{{ 'TERMS_OF_SERVICE' | translate }}</div>
            <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToPrivacy()">{{ 'PRIVACY_POLICY' | translate }}</div>
            <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToFaq()">{{ 'FAQ_S' | translate }}</div>
          </div>

          <div class="w-full h-[1px] my-5 bg-gray-200"></div>

        </div>
        <div class="flex-none w-full mt-4 mb-12">
          <button class="w-full button-white-patron-pink-borderless" (click)="logout()">
            {{ 'LOGOUT' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Details Screen -->
  <div class="absolute top-0 bottom-0 pt-12 transition-all w-full ease-cubic-bezier duration-200 overflow-y-auto"
    [ngClass]="{'translate-x-0': showDetails, 'translate-x-full': !showDetails}">
    <!-- Details content -->
    <!-- <button (click)="closeEditUser()">Close Details</button> -->
    <app-edit-user *ngIf="showEditUser" [user]="user" (close)="closeAll()" [showCloseButton]="false"></app-edit-user>
    <div *ngIf="showCreditCards" class="relative">
      <app-credit-cards [visible]="showCreditCards" [showFidelLinkCardOnly]="false"
        [initWithFidelAddCard]="false"></app-credit-cards>
    </div>
    <!-- (close)="closeAll()" -->
    <!-- (cardLinkEvent)="handleCardLinkEvent($event)"
    (cardLinked)="handleCardLinked($event)" -->

    <app-ach-account *ngIf="showBankAccount" class="max-w-4xl w-full" [owner]="user?.patron" [controllerUsers]="[user]"></app-ach-account>

    <button class="absolute top-0 left-4" (click)="closeAll()">
      <fa-icon class="fa-xl fa-regular" [icon]="FaArrowLeft" [styles]="{'color': '#252F40'}"></fa-icon>
    </button>
  </div>
</div>
