<div class="container mx-auto px-4 py-8">
  <div class="flex items-center justify-between mb-4">
    <h1 class="text-2xl font-semibold antialiased">Affiliates</h1>
    <button (click)="createAffiliate()" class="text-white bg-business-blue py-1 px-3 rounded">
      New Affiliate
    </button>
  </div>

  <ul class="divide-y divide-gray-200">
    <li *ngFor="let affiliate of affiliates" class="py-4">
      <div class="flex items-center justify-between">
        <div>
          <h2 class="text-lg font-medium">{{ affiliate.name }}</h2>
          <p class="text-grey-700 text-sm">{{ affiliate.description }}</p>
          <p class="text-grey-700 text-sm">
            {{ affiliate.intPlatformPercentage ? affiliate.intPlatformPercentage + '%' : '$' + affiliate.intPlatformAmount }}
          </p>
          <p class="text-grey-700 text-sm">Duration: {{ affiliate.intDurationDays }} days</p>
        </div>

        <div class="space-x-4">
          <button (click)="editAffiliate(affiliate)" class="text-white bg-business-blue py-1 px-3 rounded">
            Edit
          </button>
          <button (click)="showDetails(affiliate)" class="text-grey-800 bg-background-grey-2 py-1 px-3 rounded">
            Details
          </button>
        </div>
      </div>
    </li>
  </ul>
</div>

<!-- <div class="flex flex-row justify-center z-10">
  <div class="bg-white w-[80vw] p-8">
    <div class="flex flex-row justify-between items-center">
      <p class="text-lg font-bold antialiased text-business-blue">Affiliates</p>
      <div class="flex flex-row">
        <button class="text-business-blue" (click)="createAffiliate()">New</button>
      </div>
    </div>
    <div class="overflow-y-auto">
      <div *ngFor="let affiliate of affiliates" class="w-[100%] mt-6">
        <button (click)="editAffiliate(affiliate)" class="flex flex-col justify-start w-full">
          <p class="text-xl font-bold antialiased text-business-blue">{{affiliate.name}}</p>
        </button>
        <div class="bg-gray-200 w-full h-[2px] mt-5"></div>
      </div>
    </div>
  </div>
</div> -->

<!-- Overlay -->
<div class="fixed inset-0 h-screen w-full bg-black bg-opacity-30 z-50 transition-opacity duration-300"
  [ngClass]="{
    'opacity-0 pointer-events-none': !showEditAffiliate,
    'opacity-100 pointer-events-auto': showEditAffiliate
  }"
  (click)="handleClickOutsideModal()">
</div>

<!-- Sheet/Modal -->
<div [ngClass]="{
  'translate-y-0 opacity-100 pointer-events-auto': showEditAffiliate,
  'translate-y-full opacity-0 pointer-events-none': !showEditAffiliate
  }"
  class="fixed left-1/2 transform -translate-x-1/2 bottom-0 w-full md:w-3/4 lg:w-1/2 xl:w-1/3 2xl:w-1/4 z-50 rounded-t-lg md:rounded-lg shadow-lg transition-all duration-300 ease-in-out transform translate-y-full overflow-y-auto h-[90vh] md:h-auto md:my-auto md:bottom-auto">
  <app-edit-affiliate [affiliate]="selectedAffiliate" (close)="closeModal()"></app-edit-affiliate>
</div>
