import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { FetchState } from 'src/app/app.module';
import { CharityCredit, Ledger, LedgerSettleResults, PatronCredit, Reward } from 'src/app/model/ledger';
import { User } from 'src/app/model/user.model';
import { LedgerService } from 'src/app/services/ledger.service';
import { NavControlService } from '../nav/nav-control.service';

@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrls: ['./earnings.component.css']
})
export class EarningsComponent implements OnInit, OnDestroy {
  private _owner: User;
  @Input() set owner(value: User) {
    this._owner = value;
    this.ledgerService.ownerId = value?.patron?.id;
  }
  get owner(): User {
    return this._owner;
  }

  ngOnInit(): void {
    this.ledgerService.ledger$.pipe(
      takeUntil(this._destroy$)
    ).subscribe({
      next: (ledger) => {
        this.ledger = ledger;
      }
    });

    this.ledgerService.ledgerState$.pipe(
      takeUntil(this._destroy$)
    ).subscribe({
      next: (state) => {
        this.ledgerState = state;
      }
    });

    this.ledgerService.creditsAndDebitsState$.pipe(
      takeUntil(this._destroy$)
    ).subscribe({
      next: (state) => {
        this.creditsAndDebitsState = state;
      }
    });

    this.ledgerService.creditsAndDebits$.pipe(
      takeUntil(this._destroy$)
    ).subscribe({
      next: (creditsAndDebits) => {
        this.creditsAndDebits = creditsAndDebits;
      },
      error: (err) => {
        console.error('EarningsComponent.ledgerService.creditsAndDebits$.error', err);
      }
    });

    this.ledgerService.issuePayoutResult$.pipe(
      takeUntil(this._destroy$)
    ).subscribe({
      next: (result) => {
        this.issuePayoutResult = result;
      }
    });

    this.ledgerService.issuePayoutState$.pipe(
      takeUntil(this._destroy$)
    ).subscribe({
      next: (state) => {
        this.issuePayoutState = state;
      }
    });
  }

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  ledger: Ledger;
  ledgerState: FetchState;
  creditsAndDebitsState: FetchState;
  creditsAndDebits: Array<PatronCredit | CharityCredit | any>;

  FetchState = FetchState;
  ErrorIcon = faTriangleExclamation;

  constructor(
    private readonly ledgerService: LedgerService,
    private readonly navControl: NavControlService,
  ) {}

  issuePayoutResult: LedgerSettleResults;
  issuePayoutState: FetchState;
  withdrawEarnings() {
    this.ledgerService.issuePayout();
  }

  loadMore() {
    this.ledgerService.loadMore();
  }

  get isMissingBank(): boolean {
    return !!this.owner && !this.owner.patron?.achAccount
  }

  showLinkAccount() {
    this.navControl.goToPatronBank();
  }

  get userStartedDateDisplay(): string {
    // Example: "Since May 5, 2022"
    let result = this.owner?.createdDate?.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    if (!result) {
      // Use today's date
      result = new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    }
    if (result) {
      result = `Since ${result}`;
    }

    return result;
  }

  rewardCanBeSelected(creditDebit: PatronCredit | CharityCredit | any): boolean {
    if (creditDebit instanceof PatronCredit || creditDebit instanceof CharityCredit) {
      // if (!!creditDebit?.clearedDate && creditDebit?.clearedDate !== null && creditDebit?.clearedDate !== undefined && creditDebit?.clearedDate.getTime() > 0) {
      //   return false;
      // }
      if (creditDebit?.status?.toLowerCase() === 'pending') {
        return !!creditDebit?.reward?.id;
      }
    }
    return false;
  }

  selectRewardOffer(creditDebit: PatronCredit | CharityCredit | any) {
    if (this.rewardCanBeSelected(creditDebit)) {
      this.selectedReward = creditDebit.reward;
    }
  }

  private _seletedReward: Reward;
  get selectedReward(): Reward {
    return this._seletedReward;
  }
  private set selectedReward(value: Reward) {
    this._seletedReward = value;
    if (value) {
      this.showSelectRewardOffer = true;
    }
  }

  showSelectRewardOffer: boolean = false;
  closeModal() {
    this.showSelectRewardOffer = false;
  }


}
