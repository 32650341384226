import { gql } from "apollo-angular";
import { AddFidelUserPaymentMethodInput } from "../fragments/add_fidel_user_payment_method_input.fragment.graphql";
import { UserPaymentMethodDetails } from "../fragments/user_payment_method_details.fragment.graphql";


export const AddFidelUserPaymentMethodMutation = gql`
mutation AddFidelUserPaymentMethod($input: AddFidelUserPaymentMethodInput!) {
  addFidelUserPaymentMethod(input: $input) {
    ...UserPaymentMethodDetails
  }
}
${UserPaymentMethodDetails}
`;
