import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { VenueService } from '../../../venue.service';
import { NavControlService, NavRole } from '../../nav/nav-control.service';
import { Venue } from 'src/app/model/venue.model';
import { FetchState } from 'src/app/app.module';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { UserOffersService } from 'src/app/services/user-offers.service';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.css'],
})
export class VenuesComponent implements OnInit, OnDestroy {

  venues: any[];
  venuesState: FetchState;
  viewBusinessOpen: boolean = false;

  FetchState = FetchState;
  FaEdit = faEdit;
  FaCreate = faPlus;
  NavRole = NavRole;

  constructor(
    private readonly venueService: VenueService,
    protected readonly navControlService: NavControlService,
    protected userOfferService: UserOffersService,
  ) {
  }

  ngOnInit(): void {
    this.venueService.venues$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (venues) => {
        this.venues = venues;
      }
    });

    this.venueService.fetchVenues();
  }

  showAllVenues() {
    this.venueService.fetchVenues();
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getVenueStreetAddresses(addresses: any[]) {
    let street;
    addresses.forEach((address) => {
      street = address.street;
    })
    return street;
  }

  selectVenue(venue) {
    this.navControlService.selectVenue(venue, true);
  }

  venue: Venue | null;
  showUpsertVenue(venue?: Venue) {
    this.venue = venue || new Venue();
  }

  handleClickOutsideModal() {
    this.venue = null;
  }

  logoImageUrl(venue: Venue): string {
    return venue.venueImages?.find((image) => image.type?.toUpperCase() === 'LOGO')?.url;
  }

}
