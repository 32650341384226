import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthTokenService } from '../auth-token.service';
import { first, fromEvent, throttleTime } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppleService {

  private static CLIENT_ID: string = 'com.getzeals.app.login';

  constructor(
  ) {
      this.init();
  }

  private appleSignInListenerAdded: boolean = false;
  private appleSignInEvent$;
  private appleSignInFailureEvent$;
  private cooldownPeriod = 2500; // 2.5 seconds


  private init(): void {
    // Listen for authorization success.
    if (!environment.production) {
      console.log('=== APPLE SIGN IN INIT ===');
    }
    if (!this.appleSignInListenerAdded) {
      this.appleSignInListenerAdded = true;

      this.appleSignInEvent$ = fromEvent(document, 'AppleIDSignInOnSuccess');
      this.appleSignInEvent$.pipe(
        throttleTime(this.cooldownPeriod)
      )
      .subscribe({
        next: (event: any) => {
          if (!environment.production) {
            console.log('=== APPLE SIGN IN INIT (SUCCESS) ===');
            console.log(event.detail.data);
          }
          const uri = environment.appleRedirectUri;

          if (event.detail.authorization) {
            let bodyContent = Object.assign({}, event.detail.authorization);
            if (!!event.detail.user) {
              if (!!event.detail.user.email) {
                bodyContent = Object.assign(bodyContent, {email: event.detail.user.email});
              }
              if (!!event.detail.user.name) {
                if (!!event.detail.user.name.firstName) {
                  bodyContent = Object.assign(bodyContent, {first_name: event.detail.user.name.firstName});
                }
                if (!!event.detail.user.name.lastName) {
                  bodyContent = Object.assign(bodyContent, {last_name: event.detail.user.name.lastName});
                }
              }
            }

            try {
              // Get/set the system id from local storage
              const systemId: string = AuthTokenService.instance.getSystemId(true);
              const response = fetch(uri, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'x-system-id': systemId
                },
                body: JSON.stringify(bodyContent)
              });
              if (!environment.production) {
                console.log('=== APPLE SIGN IN INIT (BACK-END) ===');
              }

              response.then((response) => {
                response.json().then((body) => {
                  // If this is a 200 response, then we have a valid token
                  if (response.status >= 200 && response.status < 300) {
                    AuthTokenService.instance.handleAuthResponse(body);
                  }
                  else {
                  // If this is a 401 response, then we have an invalid token
                    AuthTokenService.instance.handleAuthFailure(body);
                  }
                });
              });
            }
            catch (error) {
              if (!environment.production) {
                console.log('=== APPLE SIGN IN INIT (BACK-END ERROR) ===');
                console.log(error);
              }
              AuthTokenService.instance.handleAuthFailure(error);
            }
          }
          else {
            if (!environment.production) {
              console.log('=== APPLE SIGN IN INIT (NO AUTHORIZATION) ===');
            }
            AuthTokenService.instance.handleAuthFailure(event.detail);
          }
        },
        error: (error) => {
          if (!environment.production) {
            console.log('=== APPLE SIGN IN INIT (ERROR) ===');
            console.log(error);
          }
          AuthTokenService.instance.handleAuthFailure(error);
        }
      });

      // Now add one for failure
      this.appleSignInFailureEvent$ = fromEvent(document, 'AppleIDSignInOnFailure');
      this.appleSignInFailureEvent$.pipe(
        throttleTime(this.cooldownPeriod)
      )
      .subscribe({
        next: (event: any) => {
          // Handle error.
          if (!environment.production) {
            console.log('=== APPLE SIGN IN FAILURE ===');
            console.log(event.detail.error);
          }
          AuthTokenService.instance.handleAuthFailure(event.detail.error);
          for (const handler of this.signInResultHandlers) {
            handler(null, "sign on failure", event.detail.error);
          }
        },
        error: (error) => {
          if (!environment.production) {
            console.log('=== APPLE SIGN IN FAILURE (ERROR) ===');
            console.log(error);
          }
          AuthTokenService.instance.handleAuthFailure(error);
        }
      });
    }
  }

  private signInResultHandlers: Array<Function> = [];
  public registerSignInResultHandler(handler: Function) {
    // Push this handler if it's not already in the list
    if (this.signInResultHandlers.indexOf(handler) === -1) {
      this.signInResultHandlers.push(handler);
    }
  }
  public removeSignInResultHandler(handler: Function) {
    // Remove this handler if it's in the list
    const index = this.signInResultHandlers.indexOf(handler);
    if (index !== -1) {
      this.signInResultHandlers.splice(index, 1);
    }
  }

}
