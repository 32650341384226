export const environment = {
  production: false,
  appleAppId: "6444629621",
  mapbox: {
    accessToken: "pk.eyJ1IjoiZ2V0emVhbHMiLCJhIjoiY2xvb3VkM2p1MDNoeTJvcW9ybHE5bHphMSJ9.H63xbaxKP9FDMZgs50q2zg",
  },
  authUri: "https://nqrs37vb53.execute-api.us-east-2.amazonaws.com/dev/auth",
  api: "https://nqrs37vb53.execute-api.us-east-2.amazonaws.com/dev/graphql",
  authConfigUri: "https://nqrs37vb53.execute-api.us-east-2.amazonaws.com/dev/auth/config",
  authRedirectUri: "https://nqrs37vb53.execute-api.us-east-2.amazonaws.com/dev/auth/login",
  appleRedirectUri: "https://nqrs37vb53.execute-api.us-east-2.amazonaws.com/dev/apple/redirect",
  googleRedirectUri: "https://nqrs37vb53.execute-api.us-east-2.amazonaws.com/dev/google/login"
};
