import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavControlService } from '../nav/nav-control.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/model/user.model';

@Component({
  selector: 'app-patron-earnings',
  templateUrl: './patron-earnings.component.html',
  styleUrls: ['./patron-earnings.component.css']
})
export class PatronEarningsComponent implements OnInit, OnDestroy {


  constructor(
    protected readonly navControlService: NavControlService,
  ) {}

  user: User;

  ngOnInit(): void {
    this.navControlService.user$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (user) => {
        this.user = user;
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
