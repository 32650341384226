<div class="flex flex-col justify-center w-full gap-3" [formGroup]="availableGroup">
  <div class="group-content-container">
    <div class="flex flex-col justify-center w-full">
      <div class="flex flex-row flex-wrap justify-between">
        <p class="subsection-header-title">Claimable</p>

        <label class="relative inline-flex cursor-pointer">
          <input type="checkbox" value="" class="text-normal sr-only peer" formControlName="alwaysClaimable">
          <div
            class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-business-blue">
          </div>
          <span class="ml-3 text-md font-medium text-gray-900 dark:text-gray-900">Always</span>
        </label>
      </div>

      <div class="flex flex-row flex-wrap justify-around mt-4" *ngIf="!isAlwaysClaimable">
        <div class="">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased"
            for="claimStartDate">Start
            Date</label>
          <input class="bg-white p-2" id="claimStartDate" formControlName="claimStartDate" type="date">
          <button class="ml-2 button-white-zinc" *ngIf="!!claimStartDate?.value"
            (click)="clearClaimStartDate()">Clear</button>
        </div>
        <div class="">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased" for="claimEnDate">End
            Date</label>
          <input class="bg-white p-2" id="claimEndDate" formControlName="claimEndDate" type="date">
          <button class="ml-2 button-white-zinc" *ngIf="!!claimEndDate?.value"
            (click)="clearClaimEndDate()">Clear</button>
        </div>
      </div>
    </div>

  </div>
  <div class="group-content-container">
    <div class="flex flex-col justify-center w-full">
      <div class="flex flex-row flex-wrap justify-between w-full">
        <p class="subsection-header-title">Redeemable</p>
        <label class="relative inline-flex cursor-pointer">
          <input type="checkbox" value="" class="sr-only peer" formControlName="alwaysRedeemable">
          <div
            class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-business-blue">
          </div>
          <span class="ml-3 text-md font-medium text-gray-900 dark:text-gray-900">Always</span>
        </label>

      </div>

      <div *ngIf="!isAlwaysRedeemable">
        <div class="flex flex-row flex-wrap justify-around mt-4">
          <div class="">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased"
              for="redeemStartDate">Start
              Date</label>
            <input class="bg-white p-2" id="redeemStartDate" formControlName="redeemStartDate" type="date">
            <button class="ml-2 button-white-zinc" *ngIf="!!redeemStartDate?.value"
              (click)="clearRedeemStartDate()">Clear</button>
          </div>
          <div class="">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold antialiased"
              for="redeemEnDate">End
              Date</label>
            <input class="bg-white p-2" id="redeemEndDate" formControlName="redeemEndDate" type="date">
            <button class="ml-2 button-white-zinc" *ngIf="!!redeemEndDate?.value"
              (click)="clearRedeemEndDate()">Clear</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="group-content-container">
    <div class="flex flex-col justify-center items-center w-full">
      <div class="flex flex-row flex-wrap justify-between w-full">
        <p class="subsection-header-title">Days Available</p>
        <label class="relative inline-flex cursor-pointer">
          <input type="checkbox" value="" class="sr-only peer" formControlName="isAllDays">
          <div
            class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-business-blue">
          </div>
          <span class="ml-2 text-md font-medium text-gray-900 dark:text-gray-900">All Days</span>
        </label>
      </div>

      <!-- <div *ngIf="!isAlwaysRedeemable">
          <div class="flex flex-col justify-between mt-8">
          <div class="flex flex-row justify-between w-full">
            <div>
              <p class="font-bold">Days Available</p>
            </div>
            <div class="flex flex-row justify-between">
              <label class="relative inline-flex items-center mr-5 cursor-pointer">
                <input type="checkbox" formControlName="isAllDays" class="sr-only peer">
                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300
                                    dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white
                                    after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300
                                    after:border after:rounded-full after:h-5 after:w-5 after:transition-all
                                    dark:border-gray-600 peer-checked:bg-business-blue">
                </div>
                <span class="ml-3 text-md font-medium text-gray-900 dark:text-gray-900">All Days</span>
              </label>
            </div>

          </div> -->
      <div *ngIf="!isAllDays" class="w-full justify-between items-center gap-2 inline-flex max-w-[475px] mt-2">
        <div *ngFor="let day of AllDaysOfTheWeek" class="w-[40.57px] h-[40.57px] relative cursor-pointer"
          (click)="toggleSelectedDays([day])">
          <div [ngClass]="{'bg-business-blue': isDayOfWeek(day), 'bg-white': !isDayOfWeek(day)}"
            class="w-full max-w-[40.57px] h-[40.57px] left-0 top-0 absolute rounded-md"></div>
          <div [ngClass]="{'text-white': isDayOfWeek(day), 'text-black': !isDayOfWeek(day)}"
            class="w-full max-w-[40.57px] h-[16.23px] left-0 top-[12.17px] absolute text-center text-opacity-95 text-xs font-normal leading-[16.91px]">
            {{dayDisplay(day) | async}}</div>
        </div>

      </div>
    </div>

    <!-- <div *ngIf="!isAllDays" class="flex flex-row justify-between my-6 pt-4 border-t-2">
            <div *ngFor="let dayKey of AllDaysOfTheWeek">
              <div [classList]="checkIfDayIsSelectedClass(dayKey)"
                (click)="toggleSelectedDays([dayKey])">{{displayDayOfWeek(dayKey)}}</div>
            </div>
          </div> -->
  </div>

  <!--
        <div class="flex flex-col justify-between mt-8 border-b-2 mb-4">
          <div class="flex flex-row justify-between">
            <div>
              <p class="font-bold">Weeks of Month</p>
            </div>
            <div class="flex flex-row justify-between w-[150px]">
              <label class="relative inline-flex items-center mr-5 cursor-pointer">
                <input type="checkbox" [(ngModel)]="weeksOfTheMonth" (change)="selectAllWeeks($event)"
                  class="sr-only peer" />
                <div
                  class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-business-blue">
                </div>
                <span class="ml-3 text-md text-gray-900 dark:text-gray-900">All Weeks</span>
              </label>
            </div>
          </div>
          <div class="flex flex-row justify-between my-6 ">
            <div *ngFor="let weekKey of getWeekKey()">
              <button [classList]="this.checkIfWeekIsSelected(this.getWeekFromString(weekKey))"
                class="rounded ring-4 p-2 focus:bg-business-blue focus:text-white"
                (click)="toggleSelectedWeeks([this.getWeekFromString(weekKey)])">{{weekKey}}</button>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-start mt-8">
          <p class="font-bold">Time of Day</p>
        </div>

        <div class="flex flex-row justify-around mt-4">
          <div>
            <p class="text-center">Start Time</p>
            <input *ngIf="!!offer.startTimeOfDay || toggleStartTime" type="time" id="start" [(ngModel)]="startTime"
              class="bg-gray-200 p-2 rounded-lg">
            <button *ngIf="offer.startTimeOfDay == 0 && !toggleStartTime" (click)="showHideStartTime()">None</button>
          </div>
          <div>
            <p class="text-center">End Time</p>
            <input *ngIf="!!offer.endTimeOfDay || toggleEndTime" type="time" id="end" [(ngModel)]="endTime"
              class="bg-gray-200 p-2 rounded-lg">
            <button *ngIf="offer.endTimeOfDay == -1 && !toggleEndTime" (click)="showHideEndTime()">None</button>
          </div>
        </div>
      -->
  <!-- </div> -->
</div>
