<div class="tile-container-2" (click)="goToOfferDetails()">
  <div class="main-image-container" [ngStyle]="{'background-image': 'url(' + offerImageUrl + ')'}">
    <div *ngIf="offer.charityIncentive && !offer.charityIncentive.disabledDate" class="charity-header">
      <div class="title-text text-center text-white ">{{offer.charityIncentive.charity.name}}</div>
    </div>

    <div *ngIf="!!offer.claim || !!rewardedOffer" class="tag-container">
      <div *ngIf="!!rewardedOffer" class="tag">
        {{ 'REWARDED' | translate }}
      </div>
      <div *ngIf="!!offer.claim && !rewardedOffer" class="tag">
        {{ 'CLAIMED' | translate }}
      </div>
    </div>

  </div>
  <div class="main-content-container">
    <div class="w-full flex flex-col">
      <div class="w-full text-zinc-600 title-text ">{{offer.venue?.name}}</div>
      <div class="w-full text-black subtitle-text">{{offer.venue?.venueAddresses[0] | address:"singleLine":false}}</div>
      <div class="w-full text-black subtitle-text">{{offer.venue?.venueAddresses[0]?.phone | phone}}</div>
    </div>

    <div *ngIf="!isCharityOffer" class="reward-text title-text">
      <span *ngIf="!!offer.patronIncentive?.intReturnPercentage" class="">
        {{offer.patronIncentive?.intReturnPercentage / 100.0}}% {{ 'CASH_BACK' | translate }}
      </span>
      <span *ngIf="!!offer.patronIncentive?.intReturnAmount" class="">
        {{(offer.patronIncentive?.intReturnAmount / 100.0) | currency}} {{ 'CASH_BACK' | translate }}
      </span>
    </div>
    <div *ngIf="isCharityOffer" class="reward-text title-text">
      <span *ngIf="!!offer.charityIncentive?.intReturnPercentage" class="">
        {{offer.charityIncentive?.intReturnPercentage / 100.0}}% {{ 'DONATION' | translate }}
      </span>
      <span *ngIf="!!offer.patronIncentive?.intReturnAmount" class="">
        {{(offer.patronIncentive?.intReturnAmount / 100.0) | currency}} {{ 'DONATION' | translate }}
      </span>
    </div>

    <div class="self-stretch justify-between items-center inline-flex w-full">
      <div *ngIf="!!distance" class="justify-center items-center gap-px flex">
        <img src="/assets/icons/pin.svg" class="pin">
        <div class="text-black subtitle-text">{{distanceDisplay}}</div>
      </div>
    </div>
  </div>
</div>
