import { Injectable } from '@angular/core';
import { Waitlist } from '../model/waitlist.model';
import { Apollo } from 'apollo-angular';
import { UpsertWaitlistMutation } from '../graphql/mutations/waitlist.mutation.graphql';

export enum BusinessType {
  Charity,
  Business
}

@Injectable({
  providedIn: 'root'
})
export class WaitlistService {
  BusinessType: BusinessType;

  constructor(private readonly apollo: Apollo) { }

  async upsertWaitlist(waitlist: Waitlist): Promise<void> {
    const request = waitlist.toRequest();
    console.log(request);

    return new Promise<void>((resolve, reject) => {
      this.apollo.mutate(
        {
          mutation: UpsertWaitlistMutation,
          variables: request,
        }
      ).subscribe({
        next: ({ data, loading }) => {
          console.log(data);
          resolve();
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      })
    })
  }
}
