import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'minutesToLocalizedTime',
  pure: false  // set to false if you want the pipe to update when you change the language
})
export class MinutesToLocalizedTimePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(minutes: number): string {
    if (!minutes) return "";
    const currentLang = this.translateService.currentLang;
    const timeMoment = moment().startOf('day').add(minutes, 'minutes');
    moment.locale(currentLang);
    return timeMoment.format('LT');
  }
}
