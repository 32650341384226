import { Pipe, PipeTransform } from '@angular/core';
import { PhonePipe } from './phone.pipe';

export function addressToString(value: unknown, formatType?: string, includePhone: boolean = true): string {
  let result = "";
  const nextCharacter = formatType === "singleLine" ? " " : "\n";
  if (value) {
    if (typeof value === "string") {
      result = value;
    }
    if (value["street"]) {
      result += value["street"].trim();
    }
    if (value["street2"]) {
      if (result.length > 0) {
        result += nextCharacter;
      }
      result += value["street2"].trim();
    }
    if (value["city"]) {
      if (result.length > 0) {
        result += nextCharacter;
      }
      result += value["city"].trim();
    }
    if (value["state"]) {
      if (result.length > 0) {
        result += ", ";
      }
      result += value["state"].trim();
    }
    if (value["zip"]) {
      if (result.length > 0) {
        result += " ";
      }
      result += value["zip"].trim();
    }
    // if (value["country"]) {
    //   if (result.length > 0) {
    //     result += nextCharacter;
    //   }
    //   result += value["country"].trim();
    // }
    if (value["phone"] && includePhone) {
      if (result.length > 0) {
        result += nextCharacter;
      }
      const phonePipe: PhonePipe = new PhonePipe();
      result += phonePipe.transform(value["phone"]);
    }
  }
  return result;
}

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: unknown, formatType?: string, includePhone: boolean = true): unknown {
    return addressToString(value, formatType, includePhone);
  }



}
