import { Component, ElementRef, ViewChild } from '@angular/core';
import { BusinessType } from 'src/app/services/waitlist.service';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent {

  @ViewChild('waitlist') waitlistComponent: ElementRef | undefined;

  BusinessType = BusinessType;

  constructor(
  ) {

  }


  handleMainButtonClick(): void {
    this.waitlistComponent.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
