import { gql } from "apollo-angular";


export const FidelLocationNetworkDetails = gql`
fragment FidelLocationNetworkDetails on FidelLocationNetwork {
  estimatedActivationDate
  clearingTransactionId
  auth
  authTransactionId
  clearing
  status
  error {
    message
  }
}
`;

export const FidelLocationDetails = gql`
${FidelLocationNetworkDetails}
fragment FidelLocationDetails on FidelLocation {
  id
  currency
  visa {
    ...FidelLocationNetworkDetails
  }
  mastercard {
    ...FidelLocationNetworkDetails
  }
  amex {
    ...FidelLocationNetworkDetails
  }
  city
  geolocation {
    latitude
    longitude
  }
  programId
  searchBy {
    merchantIds {
      mastercard
      amex
      visa
    }
  }
  preonboard
  postcode
  accountId
  activeDate
  stateCode
  countryCode
  created
  address
  live
  updated
  brandId
  timezone
  active
}
`;

export const MerchantLocationDetails = gql`
${FidelLocationDetails}
fragment MerchantLocationDetails on MerchantLocation {
  id
  deactivatedDate
  errorCode
  errorMessage
  errorDate
  serviceId
  location {
    ...FidelLocationDetails
  }
}
`;

export const MerchantDetails = gql`
${MerchantLocationDetails}
fragment MerchantDetails on Merchant {
  id
  merchantName
  networkMerchantId
  visaMerchantId
  mastercardMerchantId
  amexMerchantId
  activeLocation {
    ...MerchantLocationDetails
  }
  merchantLocations {
    ...MerchantLocationDetails
  }
}
`;

