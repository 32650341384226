import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, first, map, of } from 'rxjs';
import { NavControlService } from '../components/nav/nav-control.service';
import { LoginState } from '../user-auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {
  constructor(
    private router: Router,
    private readonly navControlService: NavControlService,
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.navControlService.userState$
      .pipe(
        first(loginState => loginState === LoginState.LOGGED_IN || loginState === LoginState.LOGGED_OUT),
        map(loginState => {
          if (loginState !== LoginState.LOGGED_IN) {
            this.navControlService.userAuthService.setReturnUrl(state.url);
            this.router.navigate(['/']);
            return false;
          }

          return true;
        }),
        catchError(() => {
          this.router.navigate(['/']);
          return of(false);
        })
      );
  }
}
