import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { CurrentUserQuery } from './graphql/queries/currentuser.query.graphql';
import { AuthTokenService, AuthTokenState } from './auth-token.service';
import { User } from './model/user.model';
import { environment } from 'src/environments/environment';

export enum LoginState {
  NONE,
  LOGGED_IN,
  LOGGED_OUT,
  LOGGING_IN,
  LOGGING_OUT,
  ERROR
}

export enum LoginSubState {
  NONE,
  REFRESHING_ACCESS_TOKEN,
  FETCHING_USER,
  CANCELLED,
  ERROR
}


@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  constructor(
    private readonly apollo : Apollo,
  ) {
    this.init();
  }

  hasCachedToken$: BehaviorSubject<boolean> = AuthTokenService.instance.hasCachedToken$;

  private async init() {

    // Attempt to get the user from local storage
    let user = null;
    try {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        user = User.parseResponse(JSON.parse(userJson));
        if (!!user?.id) {
          this.currentUser$.next(user);
        }
      }
    }
    catch (error) {
      // Do nothing
    }

    AuthTokenService.instance.state$.subscribe((state) => {
      switch (state) {
        case AuthTokenState.NONE:
          this.logout();
          break;
        case AuthTokenState.SUCCESS:
          this.handleAuthLoggedIn();
          break;
        case AuthTokenState.REFRESHED_ACCESS_TOKEN:
          this.handleAuthLoggedIn();
          break;
        case AuthTokenState.CANCELLED:
          this.logout();
          break;
        case AuthTokenState.NO_ACCESS_TOKEN:
          this.logout();
          break;
        case AuthTokenState.ERROR:
          this.setState(LoginState.ERROR);
          this.subState$.next(LoginSubState.ERROR);
          break;
        default:
          // Do nothing
          break;
      }
    });
  }

  public state$: BehaviorSubject<LoginState> = new BehaviorSubject(LoginState.NONE);
  public subState$: BehaviorSubject<LoginSubState> = new BehaviorSubject(LoginSubState.NONE);
  public currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

  public setState(state: LoginState) {
    this.state$.next(state);
  }

  get user(): User {
    return this.currentUser$.getValue();
  }

  public setReturnUrl(url: string) {
    localStorage.setItem('return_url', url);
  }

  public getReturnUrl(): string {
    return localStorage.getItem('return_url');
  }

  public clearReturnUrl() {
    localStorage.removeItem('return_url');
  }

  public async logout() {
    if (!!this.currentUser$.getValue()) {
      this.currentUser$.next(null);
    }
    if (this.state$.getValue() !== LoginState.LOGGED_OUT) {
      // Clear the Apollo cache
      try {
        await this.apollo.client?.resetStore();
      }
      catch (error) {
        console.warn('error resetting Apollo store', error);
      }

      // Clear the local storage
      try {
        localStorage.removeItem('user');
      }
      catch (error) {
        // Do nothing
      }

      this.state$.next(LoginState.LOGGED_OUT);
    }

    await AuthTokenService.instance.logout();
  }

  private handleAuthLoggedIn() {
    // this.setState(LoginState.LOGGING_IN);
    const cachedUserModelId = AuthTokenService.instance.getCachedUserModelId();
    this.fetchUser(cachedUserModelId)
    .subscribe({
      next: (user) => {
        if (!environment.production) {
          console.log("===== Login Result =====")
          console.log(user);
        }
        this.subState$.next(LoginSubState.NONE);
        this.state$.next(LoginState.LOGGED_IN);
      },
      error: (error) => {
        if (!environment.production) {
          console.log("===== Login Error =====", {cachedUserModelId});
          console.log(error);
        }
        this.state$.next(LoginState.ERROR);
        AuthTokenService.instance.logout();
      }
    });
  }

  private fetchUser(modelId: string = null): Observable<User> {
    if (!modelId) {
      modelId = AuthTokenService.instance.getCachedUserModelId();
    }
    this.subState$.next(LoginSubState.FETCHING_USER);
    return this.apollo.query({
      query: CurrentUserQuery,
      variables: { id: modelId }
    }).pipe(
      map(response => {
        const user = User.parseResponse(response.data["user"]);
        this.currentUser$.next(user);
        this.subState$.next(LoginSubState.NONE);

        // Attempt to store in local storage
        try {
          const jsonUser = user.toJson();
          localStorage.setItem('user', JSON.stringify(jsonUser));
        }
        catch (error) {
          // Do nothing
        }
        return user;
      }),
      catchError(error => {
        console.error(`error getting current user`, {modelId, error});
        this.subState$.next(LoginSubState.ERROR);
        AuthTokenService.instance.logout();
        return error;
      })
    ) as Observable<User>;
  }

}
