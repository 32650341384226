import { Component } from '@angular/core';
import { NavControlService } from '../nav/nav-control.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent {

  constructor(
    private readonly navControlService: NavControlService,
  ) {}

  goToHome() {
    this.navControlService.goToHome();
  }
}
