<div class="container">

  <!-- Loading User -->
  <div *ngIf="userState === LoginState.LOGGING_IN || userState === LoginState.NONE"
    class="flex justify-center items-center h-full">
    <div class="loader-wrapper flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  </div>

  <div *ngIf="userState === LoginState.LOGGED_IN || userState === LoginState.LOGGED_OUT"
  class="flex-grow overflow-y-auto p-4 w-full mb-10 pb-safe">
    <!-- Menu Items -->


    <div class="flex-none w-full my-5" *ngIf="role !== NavRole.GUEST">
      <button class="w-full button-white-patron-pink-borderless" (click)="logout()">
        {{ 'LOGOUT' | translate }}
      </button>
    </div>
    <div class="sm:hidden flex w-full my-5 flex-row justify-between items-center" *ngIf="role === NavRole.GUEST">
      <button
        class="text-center border border-gray-500 text-gray-500 rounded-md py-1.5 px-2 text-sm font-medium leading-tight flex flex-row justify-center items-center w-full hover:bg-gray-100 hover:shadow-md shadow-gray-500"
        (click)="signIn()"
        >
        <img src="/assets/icons/user.png" class="w-5 h-6 object-contain block"/>
        <span class="ml-2">
          Sign In
        </span>
      </button>
    </div>

    <!-- <div *ngIf="role === NavRole.GUEST || role === NavRole.PATRON"
    class="w-full">
      <div class="w-full px-2 py-1.5 bg-gray-100 rounded-sm justify-start items-center inline-flex relative">
        <div class="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
            <div class="text-gray-900 text-base font-medium leading-normal">Offers</div>
        </div>
        <div class="absolute right-0">
          <fa-icon [icon]="FaChevronUp" [styles]="{'color': 'zinc'}" class="mr-3"></fa-icon>
        </div>
      </div>
      <div class="w-full pl-10 pt-3 flex-col justify-center items-start gap-4 inline-flex">
        <div class="self-stretch h-6 flex-col justify-start items-start gap-2.5 flex">
            <div class="text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToOffers([OfferFilter.NEAR_ME])">{{ 'NEAR_ME' | translate }}</div>
        </div>
        <div class="self-stretch h-6 flex-col justify-start items-start gap-2.5 flex">
            <div class="text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToOffers([OfferFilter.NEAR_ME, OfferFilter.CHARITY])">{{ 'FUNDRAISERS' | translate }}</div>
        </div>
        <div *ngIf="role !== NavRole.GUEST" class="self-stretch h-6 flex-col justify-start items-start gap-2.5 flex">
            <div class="text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToOffers([OfferFilter.CLAIMED])">{{ 'CLAIMED' | translate }}</div>
        </div>
      </div>
    </div> -->

    <div *ngIf="role === NavRole.VENUE && !!venue"
    class="w-full mb-8">
      <div class="w-full px-2 py-1.5 bg-business-blue rounded-sm justify-start items-center inline-flex relative cursor-pointer" (click)="goToVenue()">
        <div class="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
            <div class="text-white text-base font-medium leading-normal">{{venue?.name}}</div>
        </div>
        <!-- <div class="absolute right-0">
          <fa-icon [icon]="FaChevronUp" [styles]="{'color': 'zinc'}" class="mr-3"></fa-icon>
        </div> -->
      </div>
      <div class="w-full pl-10 pt-3 flex-col justify-center items-start gap-4 inline-flex">
        <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer flex flex-row justify-start items-center" (click)="goToVenueBank()">
          {{ 'LINKED_BANK_ACCOUNT' | translate }}
        </div>
        <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer flex flex-row justify-start items-center" (click)="goToVenueMerchant()">
          Merchant Details
        </div>
      </div>
    </div>

    <div *ngIf="role === NavRole.VENUE" class="w-full mt-2">

      <div class="w-full px-2 py-1.5 bg-gray-100 rounded-sm justify-start items-center inline-flex relative cursor-pointer" (click)="goToVenues()">
        <div class="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
          <div class="text-gray-900 text-base font-medium leading-normal">Venues</div>
        </div>
        <div class="absolute right-0">
          <fa-icon [icon]="FaChevronUp" [styles]="{'color': 'zinc'}" class="mr-3"></fa-icon>
        </div>
      </div>
      <div class="w-full pl-10 pt-3 flex-col justify-center items-start gap-4 inline-flex">
        <div *ngFor="let venue of venues" class="self-stretch h-6 flex-col justify-start items-start gap-2.5 flex">
          <div class="text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToVenue(venue.id)">{{ venue.name }}</div>
        </div>
      </div>
    </div>

    <div class="flex flex-col w-full gap-4 my-4">
      <div *ngIf="hasRole(NavRole.ADMIN)" class="hidden md:block w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToChat()">Chat</div>
      <div *ngIf="role === NavRole.GUEST" class="hidden md:block w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToHowItWorks()">{{ 'HOW_ZEALS_WORKS_TITLE' | translate }}</div>
      <div *ngIf="role === NavRole.GUEST" class="block md:hidden w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="toggleHowItWorks()">{{ 'HOW_ZEALS_WORKS_TITLE' | translate }}</div>
      <div *ngIf="role === NavRole.PATRON" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToPatronEarnings()">{{ 'EARNINGS' | translate }}</div>
      <div *ngIf="role === NavRole.PATRON" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer flex flex-row justify-start items-center" (click)="goToPatronCreditCards()">
        {{ 'LINKED_CREDIT_CARDS' | translate }}
        <img *ngIf="isMissingCreditCard" class="w-8 h-8 object-contain ml-2" src="assets/icons/alert-circle.png" />
      </div>
      <div *ngIf="role === NavRole.PATRON" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer flex flex-row justify-start items-center" (click)="goToPatronBank()">
        {{ 'LINKED_BANK_ACCOUNT' | translate }}
        <img *ngIf="isMissingBank" class="w-8 h-8 object-contain ml-2" src="assets/icons/alert-circle.png" />
      </div>
      <div *ngIf="role === NavRole.PATRON"
      class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer flex flex-row justify-start items-center"
      (click)="goToEditUser()">
      Profile
    </div>
</div>

    <div *ngIf="hasRole(NavRole.CHARITY) || hasRole(NavRole.VENUE) || hasRole(NavRole.ADMIN)" class="w-full h-[1px] my-5 bg-gray-200"></div>

    <div *ngIf="hasRole(NavRole.CHARITY) || hasRole(NavRole.VENUE) || hasRole(NavRole.ADMIN)" class="flex flex-col w-full gap-4 my-4">
      <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="setRole(NavRole.PATRON); goToOffers([OfferFilter.NEAR_ME])">My Patron</div>
      <!-- <div *ngIf="hasRole(NavRole.CHARITY) || hasRole(NavRole.ADMIN)" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="handleClickCharities()">My Non-Profits</div> -->
      <div *ngIf="hasRole(NavRole.VENUE) || hasRole(NavRole.ADMIN)" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="handleClickBusinesses()">My Businesses</div>
    </div>

    <div class="w-full h-[1px] my-5 bg-gray-200"></div>

    <div class="flex flex-col w-full gap-4 my-4">
      <div *ngIf="role !== NavRole.GUEST" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToHowItWorks()">{{ 'HOW_ZEALS_WORKS_TITLE' | translate }}</div>
      <div *ngIf="!hasRole(NavRole.CHARITY)" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="handleClickCharities()">{{ 'CHARITIES' | translate }}</div>
      <div *ngIf="!hasRole(NavRole.VENUE)" class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="handleClickBusinesses()">{{ 'BUSINESSES' | translate }}</div>
    </div>

    <div class="w-full h-[1px] my-5 bg-gray-200"></div>

    <div class="flex flex-col w-full gap-4">
      <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToAbout()">{{ 'ABOUT_US' | translate }}</div>
      <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToTerms()">{{ 'TERMS_OF_SERVICE' | translate }}</div>
      <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToPrivacy()">{{ 'PRIVACY_POLICY' | translate }}</div>
      <div class="w-full text-gray-900 text-base font-medium leading-normal cursor-pointer" (click)="goToFaq()">{{ 'FAQ_S' | translate }}</div>

    </div>
  </div>

</div>
