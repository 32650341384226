import { gql } from "apollo-angular";

export const FidelConfigQuery = gql`
query FidelConfig {
  getFidelConfig {
    sdkKey
    programId
    programName
    liveEnv
  }
}
`;

