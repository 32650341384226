<div
  class="mt-0 mb-12 pb-12 mx-auto bg-white z-1 w-full h-full sm:min-w-[500px] overflow-auto z-20 flex flex-col justify-start items-center transition-all duration-300 min-h-[585px]">
  <div class="bg-pink w-full h-12 mb-8 sm:h-14 flex justify-center items-center p-2 relative">
    <img src="assets/getZealsLogoWhite.png" class="w-auto max-w-[150px] max-h-9 sm:max-h-10" />
    <button class="absolute right-0 p-4" (click)="handleClose()">
      <fa-icon [icon]="FaXmark" [styles]="{'color': 'white'}"></fa-icon>
    </button>
  </div>
  <div
    class="p-2 sm:p-12 bg-white z-1 w-full h-full flex flex-col justify-start items-center transition-all duration-300">
    <qrcode [qrdata]="offerQrCode()" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    <p>{{offerQrCode()}}</p>
  </div>
</div>
