
enum ErrorLength {
  BRIEF,
  MEDIUM,
  LONG
}

export default class ErrorHelper {


  static getErrorPhrase(length: ErrorLength = ErrorLength.MEDIUM): string {
    let phrases = [];
    switch (length) {
      case ErrorLength.BRIEF:
        phrases = ErrorHelper.briefErrorMessages;
        break;
      case ErrorLength.MEDIUM:
        phrases = ErrorHelper.mediumErrorMessages;
        break;
      case ErrorLength.LONG:
        phrases = ErrorHelper.longErrorMessages;
        break;
      default:
        phrases = ErrorHelper.mediumErrorMessages;
        break;
    }
    const index = Math.floor(Math.random() * phrases.length);
    return phrases[index];
  }

  static getReassurancePhrase(length: ErrorLength = ErrorLength.MEDIUM): string {
    let phrases = [];
    switch (length) {
      case ErrorLength.BRIEF:
        phrases = ErrorHelper.briefReassuranceMessages;
        break;
      case ErrorLength.MEDIUM:
        phrases = ErrorHelper.mediumReassuranceMessages;
        break;
      case ErrorLength.LONG:
        phrases = ErrorHelper.longReassuranceMessages;
        break;
      default:
        phrases = ErrorHelper.mediumReassuranceMessages;
        break;
    }
    const index = Math.floor(Math.random() * phrases.length);
    return phrases[index];
  }

  static mediumErrorMessages: string[] = [
    "Whoops! We tripped over our own shoelaces. Let's try that again.",
    "Uh-oh, our hamster powering the server just took a nap. We're waking him up!",
    "Seems like our gears got a bit jammed. Give us a moment to unstick them.",
    "Oops! Looks like we took a wrong turn at the internet crossroads.",
    "We're not quite sure what just happened, but rest assured, our best minds are on it!",
    "Our server is having a coffee break. We'll be right back after a quick refill!",
    "Houston, we have a problem... but don't worry, we have our top people fixing it!",
    "Looks like someone spilled coffee on the code. Cleaning up now!",
    "The gremlins in our machine got a bit mischievous. We're setting things right!",
    "It’s not you, it’s us. We’re on it, so please hold on tight!",
    "We're updating our crystal ball. Please stand by for clearer visions.",
    "Our magic spell didn't work as expected. Time to dust off the old spell book.",
    "This is just a bump on the digital road. We're smoothing it out!",
    "Our digital chefs are still cooking this part of the website. Stay tuned!",
    "It's a glitch, not a feature, but we're working to make it disappear!",
    "Someone let the pixies out again and they’re playing tricks on our site!",
    "We're playing hide and seek with some lost bytes. Bear with us!",
    "The internet gnomes are causing a little mischief. We’re on gnome patrol!",
    "Our site's just taking a quick catnap. We'll be purring along again shortly!",
    "Seems like we’ve encountered a mystery. Calling all detectives to the scene!"
  ];

  static mediumReassuranceMessages: string[] = [
    "Looks like you're ahead of us! You've already done this, but we're glad you're so enthusiastic!",
    "You're so efficient, you already took care of this! No further action needed.",
    "Oops, it seems you've already outpaced us here. All is set and done!",
    "Hooray! You're already one step ahead. No need to do this again.",
    "Wow, you're quick! You've already completed this step. Onwards to new adventures!",
    "Looks like you've already been here and conquered. No need for a second round!",
    "You're a shooting star! Already completed this task. What's next on your journey?",
    "Our records show you're moving faster than light. This task is already done!",
    "You’ve got the speed of a cheetah! Already checked this off your list.",
    "Deja vu! Seems like you’ve already done this. Let’s see what else is in store for you.",
    "You’re ahead of the game! This step is already complete. Go you!",
    "You've already mastered this challenge. Ready for the next one?",
    "You're not seeing double – you've already completed this. Great job!",
    "This page is feeling lonely because you left it completed. On to the next one!",
    "You’ve already sailed these waters. Time to explore new seas!",
    "Like a time traveler, you’ve been here before and done that. What’s your next time-stop?",
    "You’ve already crossed this bridge. What’s on the other side awaits!",
    "Your efficiency is stellar! This task is already in your past. What’s next?",
    "You're like a ninja, so quick and silent. This task? Already completed!",
    "As perceptive as an owl, you’ve already noticed and done this. Wise move!"
  ];

  // // List of random error phrases to use in error messages. These are friendly, cheerful, witty, and hopefuly will make the user feel better about the error.
  // export const ERROR_PHRASES = [
  //   "Oops! Something went wrong.",
  //   "Don't worry, we're on it!",
  //   "Hang in there, we're fixing the issue.",
  //   "Oopsie daisy! We'll get it sorted.",
  //   "Stay calm, we're working to resolve the error.",
  //   "Hakuna Matata! We'll handle this error.",
  //   "Error 404: Happiness not found. But we're trying!",
  //   "Keep calm and let us handle the error.",
  //   "Oops, our bad! We'll make it right.",
  //   "Don't fret, we're here to help!",
  // ];

  static briefErrorMessages: string[] = [
    "Whoops! We tripped over our own shoelaces. Let's try that again.",
    "Uh-oh, our hamster powering the server just took a nap. We're waking him up!",
    "Seems like our gears got a bit jammed. Give us a moment to unstick them.",
    "Oops! Looks like we took a wrong turn at the internet crossroads.",
    "We're not quite sure what just happened, but rest assured, our best minds are on it!",
    "Our server is having a coffee break. We'll be right back after a quick refill!",
    "Houston, we have a problem... but don't worry, we have our top people fixing it!",
    "Looks like someone spilled coffee on the code. Cleaning up now!",
    "The gremlins in our machine got a bit mischievous. We're setting things right!",
    "It’s not you, it’s us. We’re on it, so please hold on tight!",
    "We're updating our crystal ball. Please stand by for clearer visions.",
    "Our magic spell didn't work as expected. Time to dust off the old spell book.",
    "This is just a bump on the digital road. We're smoothing it out!",
    "Our digital chefs are still cooking this part of the website. Stay tuned!",
    "It's a glitch, not a feature, but we're working to make it disappear!",
    "Someone let the pixies out again and they’re playing tricks on our site!",
    "We're playing hide and seek with some lost bytes. Bear with us!",
    "The internet gnomes are causing a little mischief. We’re on gnome patrol!",
    "Our site's just taking a quick catnap. We'll be purring along again shortly!",
    "Seems like we’ve encountered a mystery. Calling all detectives to the scene!"
  ];

  static briefReassuranceMessages: string[] = [
    "Looks like you're ahead of us! You've already done this, but we're glad you're so enthusiastic!",
    "You're so efficient, you already took care of this! No further action needed.",
    "Oops, it seems you've already outpaced us here. All is set and done!",
    "Hooray! You're already one step ahead. No need to do this again.",
    "Wow, you're quick! You've already completed this step. Onwards to new adventures!",
    "Looks like you've already been here and conquered. No need for a second round!",
    "You're a shooting star! Already completed this task. What's next on your journey?",
    "Our records show you're moving faster than light. This task is already done!",
    "You’ve got the speed of a cheetah! Already checked this off your list.",
    "Deja vu! Seems like you’ve already done this. Let’s see what else is in store for you.",
    "You’re ahead of the game! This step is already complete. Go you!",
    "You've already mastered this challenge. Ready for the next one?",
    "You're not seeing double – you've already completed this. Great job!",
    "This page is feeling lonely because you left it completed. On to the next one!",
    "You’ve already sailed these waters. Time to explore new seas!",
    "Like a time traveler, you’ve been here before and done that. What’s your next time-stop?",
    "You’ve already crossed this bridge. What’s on the other side awaits!",
    "Your efficiency is stellar! This task is already in your past. What’s next?",
    "You're like a ninja, so quick and silent. This task? Already completed!",
    "As perceptive as an owl, you’ve already noticed and done this. Wise move!"
  ];


  static longErrorMessages: string[] = [
    "Whoops! We tripped over our own shoelaces. Let's try that again.",
    "Uh-oh, our hamster powering the server just took a nap. We're waking him up!",
    "Seems like our gears got a bit jammed. Give us a moment to unstick them.",
    "Oops! Looks like we took a wrong turn at the internet crossroads.",
    "We're not quite sure what just happened, but rest assured, our best minds are on it!",
    "Our server is having a coffee break. We'll be right back after a quick refill!",
    "Houston, we have a problem... but don't worry, we have our top people fixing it!",
    "Looks like someone spilled coffee on the code. Cleaning up now!",
    "The gremlins in our machine got a bit mischievous. We're setting things right!",
    "It’s not you, it’s us. We’re on it, so please hold on tight!",
    "We're updating our crystal ball. Please stand by for clearer visions.",
    "Our magic spell didn't work as expected. Time to dust off the old spell book.",
    "This is just a bump on the digital road. We're smoothing it out!",
    "Our digital chefs are still cooking this part of the website. Stay tuned!",
    "It's a glitch, not a feature, but we're working to make it disappear!",
    "Someone let the pixies out again and they’re playing tricks on our site!",
    "We're playing hide and seek with some lost bytes. Bear with us!",
    "The internet gnomes are causing a little mischief. We’re on gnome patrol!",
    "Our site's just taking a quick catnap. We'll be purring along again shortly!",
    "Seems like we’ve encountered a mystery. Calling all detectives to the scene!"
  ];

  static longReassuranceMessages: string[] = [
    "Looks like you're ahead of us! You've already done this, but we're glad you're so enthusiastic!",
    "You're so efficient, you already took care of this! No further action needed.",
    "Oops, it seems you've already outpaced us here. All is set and done!",
    "Hooray! You're already one step ahead. No need to do this again.",
    "Wow, you're quick! You've already completed this step. Onwards to new adventures!",
    "Looks like you've already been here and conquered. No need for a second round!",
    "You're a shooting star! Already completed this task. What's next on your journey?",
    "Our records show you're moving faster than light. This task is already done!",
    "You’ve got the speed of a cheetah! Already checked this off your list.",
    "Deja vu! Seems like you’ve already done this. Let’s see what else is in store for you.",
    "You’re ahead of the game! This step is already complete. Go you!",
    "You've already mastered this challenge. Ready for the next one?",
    "You're not seeing double – you've already completed this. Great job!",
    "This page is feeling lonely because you left it completed. On to the next one!",
    "You’ve already sailed these waters. Time to explore new seas!",
    "Like a time traveler, you’ve been here before and done that. What’s your next time-stop?",
    "You’ve already crossed this bridge. What’s on the other side awaits!",
    "Your efficiency is stellar! This task is already in your past. What’s next?",
    "You're like a ninja, so quick and silent. This task? Already completed!",
    "As perceptive as an owl, you’ve already noticed and done this. Wise move!"
  ];
}
