import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Ledger } from 'src/app/model/ledger';
import { NavControlService } from '../../nav-control.service';
import { LedgerService } from 'src/app/services/ledger.service';
import { LoginState } from 'src/app/user-auth.service';

@Component({
  selector: 'app-cash-out-widget',
  templateUrl: './cash-out-widget.component.html',
  styleUrl: './cash-out-widget.component.css'
})
export class CashOutWidgetComponent implements OnInit, OnDestroy {

  ledger: Ledger;

  constructor(
    protected readonly navControlService: NavControlService,
    private readonly ledgerService: LedgerService,
  ) { }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.ledgerService.ledger$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (ledger) => {
        this.ledger = ledger;
      }
    });

    this.navControlService.user$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (user) => {
        if (user?.patron?.id) {
          this.ledgerService.ownerId = user.patron.id;
        }
      }
    });
  }

  goToCashOut(): void {
    this.navControlService.goToPatronEarnings();
  }

}
