<div class="flex flex-row justify-center mx-8 max-w-[500px] w-full">
  <ul
    class="relative flex-grow text-md font-medium text-center w-full flex flex-row"
    [ngClass]="{'text-white': isExpanded, 'text-zinc-700': !isExpanded}"
    >
    <li class="w-full pb-2 text-center">
      <div (click)="goToOffers()"
        id="zealsDiv"
        #zealsDiv
        [ngClass]="{'font-bold': currentDivId === 'zealsDiv', 'font-normal': currentDivId !== 'zealsDiv'}"
        class=" cursor-pointer inline-block active "
        aria-current="page">Zeals</div>
    </li>
    <!-- <li class="w-full pb-2 text-center">
      <div (click)="scrollToDiv('experiencesDiv')"
        id="experiencesDiv"
        #experiencesDiv
        [ngClass]="{'font-bold': currentDivId === 'experiencesDiv', 'font-normal': currentDivId !== 'experiencesDiv'}"
        class="cursor-pointer inline-block ">
        Experiences</div>
    </li> -->
    <li class="w-full pb-2 text-center">
      <div (click)="scrollToDiv('myZealsDiv')"
        id="myZealsDiv"
        #myZealsDiv

        [ngClass]="{'font-bold': currentDivId === 'myZealsDiv', 'font-normal ': currentDivId !== 'myZealsDiv'}"
        class="cursor-pointer inline-block">
        My Zeals</div>
    </li>
    <!-- Underline element -->
    <div #underline class="absolute h-1 bg-patron-pink ease-cubic-bezier"
    [ngClass]="{'hidden': !currentDivId}"
    style="bottom: 0; transition: left 0.3s;"
    ></div>
  </ul>
</div>
