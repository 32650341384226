import { BehaviorSubject } from "rxjs";
import { Entity } from "./entity.model";

export interface IImage {

  id: string;
  name: string;
  type: string;
  dateAdded: Date;
  urlExpiry?: Date;
  url?: string;
  preview?: string | ArrayBuffer;
  // imageUrl$: BehaviorSubject<string | ArrayBuffer | null>;
  imageUrl: string | ArrayBuffer | null;
  getImageUrl(): string | ArrayBuffer;

}


export class BaseImage extends Entity implements IImage {

  constructor() {
    super();
  }

  name: string;
  type: string;
  dateAdded: Date;
  urlExpiry?: Date;

  private _url?: string;
  set url(value: string) {
    this._url = value;
    // this.imageUrl$.next(this.getImageUrl());
    this.imageUrl = this.getImageUrl();
  }
  get url(): string {
    return this._url;
  }
  private _preview?: string | ArrayBuffer = null;
  set preview(value: string | ArrayBuffer) {
    this._preview = value;
    // this.imageUrl$.next(this.getImageUrl());
    this.imageUrl = this.getImageUrl();
  }
  get preview(): string | ArrayBuffer {
    return this._preview;
  }

  imageUrl: string | ArrayBuffer | null = null;
  // imageUrl$: BehaviorSubject<string | ArrayBuffer | null> = new BehaviorSubject<string | ArrayBuffer | null>(null);
  getImageUrl(): string | ArrayBuffer {
    if (!!this._preview) return this._preview;
    return this._url;
  }
  override toJson(): any {
    const result: any = super.toJson();
    return {
      ...result,
      name: this.name,
      type: this.type,
      dateAdded: this.dateAdded?.toISOString() ?? "",
      url: this.url,
      urlExpiry: this.urlExpiry?.toISOString() ?? "",
    };
  }

  static override parseResponse(details: any, result?: BaseImage): BaseImage {
    if (!details) return null;
    if (!result) result = new BaseImage();

    Entity.parseResponse(details, result);
    result.name = details.name;
    result.type = details.type;
    if (details.dateAdded) {
      result.dateAdded = new Date(details.dateAdded);
    }
    else {
      result.dateAdded = new Date();
    }
    if (details.urlExpiry) {
      result.urlExpiry = new Date(details.urlExpiry);
    }

    result.url = details.url;
    return result;
  }
}
