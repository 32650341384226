import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavControlService } from '../nav/nav-control.service';
import { OfferFilter } from 'src/app/offers.service';
import { User } from 'src/app/model/user.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent implements OnInit, OnDestroy {
  constructor(
    private readonly navControlService: NavControlService,
  ) {
  }

  user: User;

  ngOnInit(): void {
    this.navControlService.user$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (user) => {
        this.user = user;
      }
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleMainButtonClick(isMobile: boolean = false) {
    if (!!this.user) {
      if (isMobile) {
        this.navControlService.hideHowItWorks();
      }
      this.navControlService.goToOffers([OfferFilter.NEAR_ME]);
    }
    else {
      if (isMobile) {
        this.navControlService.hideHowItWorks();
      }
      this.navControlService.showLogin();
    }
  }

}
