import { gql } from "apollo-angular";
import { VenueDetails, VenueOperatorDetails } from "../fragments/venue-details.fragment.graphql";
import { UserInviteDetails } from "../fragments/invite.fragment.graphql";

// Upsert Venue
export const UpsertVenueMutation = gql`
${VenueDetails}
mutation UpsertVenue($id: String, $name: String!, $description: String, $street: String, $street2: String, $city: String, $state: String, $zip: String, $country: String, $phone: String, $operatorIds: [String!]) {
  upsertVenue(id: $id, name: $name, description: $description, street: $street, street2: $street2, city: $city, state: $state, zip: $zip, country: $country, phone: $phone, operatorIds: $operatorIds) {
    ...VenueDetails
  }
}
`;

// Remove an Venue
export const RemoveVenueMutation = gql`
mutation RemoveVenue($id: String!) {
  removeVenue(id: $id)
}
`;

// Unarchive an Venue
export const UnarchiveVenueMutation = gql`
${VenueDetails}
mutation UnarchiveVenue($id: String!) {
  unarchiveVenue(id: $id) {
    ...VenueDetails
  }
}`;

export const InviteVenueOperatorMutation = gql`
${VenueOperatorDetails}
${UserInviteDetails}
mutation InviteVenueOperator($venueId: String!, $userId: String, $email: String, $message: String, $forceResend: Boolean, $accept: Boolean) {
  inviteVenueOperator2(venueId: $venueId, userId: $userId, email: $email, message: $message, forceResend: $forceResend, accept: $accept) {
    ...on VenueOperator {
      ...VenueOperatorDetails
    }
    ...on UserInvite {
      ...UserInviteDetails
    }
  }
}`;

export const RemoveVenueOperatorMutation = gql`
mutation RemoveVenueOperator($id: String!) {
  removeVenueOperator(id: $id)
}`;

