import { Component, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { UserPaymentMethod } from 'src/app/model/user_payment_method.model';
import { OfferDetailsController, OfferDetailsSheet } from 'src/app/offer-details/offer-details.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { PatronOffer } from 'src/app/model/patron-offer.model';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent {
  @Input() offer: PatronOffer;
  @Input() offerClaim: boolean;
  @Input() controller: OfferDetailsController;
  @Input() userPaymentMethods: UserPaymentMethod[] = [];

  copyLinkButtonText = '';

  constructor(
    private readonly appService: AppService,
    private clipboard: ClipboardService,
    public readonly translate: TranslateService,
  ) {
  }

  copyLink(link: HTMLAnchorElement) {
    const linkValue = link.getAttribute('href');

    if (linkValue) {
      const copied = this.clipboard.copy(linkValue);

      // if (copied) {
        this.copyLinkButtonText = ' Copied';
        setTimeout(() => this.copyLinkButtonText = '', 5000);
      // }
    }
  }

  offerQrCode(): string {
    return `${this.appService.baseUrl}/offers/${this.offer.id}`;
  }

  get isCharityOffer(): boolean {
    return !!this.offer?.charityIncentive && (!this.offer.charityIncentive.disabledDate || this.offer.charityIncentive.disabledDate.getTime() <= 0);
  }

  get pageTitle(): string {
    if (this.isCharityOffer) {
      return `${this.offer?.charityIncentive?.charity?.name} @ ${this.offer?.venue?.name}`;
    }
    else {
      return `${this.offer?.name} @ ${this.offer?.venue?.name}`;
    }
  }

  get intReturnPercentage(): number {
    if (this.isCharityOffer) {
      return Math.round(this.offer?.charityIncentive?.intReturnPercentage ?? 0);
    }
    else {
      return Math.round(this.offer?.patronIncentive?.intReturnPercentage ?? 0);
    }
  }

  get intReturnAmount(): number {
    if (this.isCharityOffer) {
      return Math.round(this.offer?.charityIncentive?.intReturnAmount ?? 0);
    }
    else {
      return Math.round(this.offer?.patronIncentive?.intReturnAmount ?? 0);
    }
  }

  offerClaimedMessage(): Observable<string> {
    if (this.offer?.redeemEndDate) {
      const redeemEndDate = new Date(this.offer.redeemEndDate).toLocaleDateString();
      if (this.isCharityOffer) {
        return this.translate.get('OFFER_CLAIMED_CHARITY_MESSAGE_END_DATE', {offerName: this.offer?.name ?? '', redeemEndDate, venueName: this.offer?.venue?.name});
      }
      else {
        return this.translate.get('OFFER_CLAIMED_MESSAGE_END_DATE', {offerName: this.offer?.name ?? '', redeemEndDate, venueName: this.offer?.venue?.name});
      }
    }
    else {
      if (this.isCharityOffer) {
        return this.translate.get('OFFER_CLAIMED_CHARITY_MESSAGE', {offerName: this.offer?.name ?? '', venueName: this.offer?.venue?.name});
      }
      else {
        return this.translate.get('OFFER_CLAIMED_MESSAGE', {offerName: this.offer?.name ?? '', venueName: this.offer?.venue?.name});
      }
    }

  }

  handleOfferClaimedInfo(): Observable<string> {
    if (!this.offer?.venue) return of("");

    const USDollar = new Intl.NumberFormat(`${this.translate.currentLang ?? 'en'}-US`, {
      style: 'currency',
      currency: 'USD'
    })

    if (this.intReturnPercentage) {
      if (this.isCharityOffer) {
        return this.translate.get('PERCENTAGE_OFFER_CLAIMED_INFO_CHARITY', { percentage: this.intReturnPercentage / 100.0, venueName: this.offer.venue.name, charityName: this.offer?.charityIncentive?.charity?.name });
      }
      else {
        return this.translate.get('PERCENTAGE_OFFER_CLAIMED_INFO', { percentage: this.intReturnPercentage / 100.0, venueName: this.offer.venue.name });
      }
    }
    if (this.intReturnAmount) {
      if (this.isCharityOffer) {
        return this.translate.get('AMOUNT_OFFER_CLAIMED_INFO_CHARITY', { amount: USDollar.format(this.intReturnAmount / 100.0), venueName: this.offer.venue.name, charityName: this.offer?.charityIncentive?.charity?.name });
      }
      else {
        return this.translate.get('AMOUNT_OFFER_CLAIMED_INFO', { amount: USDollar.format(this.intReturnAmount / 100.0), venueName: this.offer.venue.name });
      }
    }

    return of ("");
  }

  showCreditCards() {
    this.controller.selectSheet(OfferDetailsSheet.CreditCards);
  }

  get userPaymentsLastDigitsDisplay(): string {
    if (this.userPaymentMethods.length === 0) {
      return '';
    }
    else {
      // Return a comma separated list of the last 4 digits of the user's payment methods
      return this.userPaymentMethods.map((userPaymentMethod) => userPaymentMethod.lastNumbers).join(', ');
    }
  }

  get hasUserPaymentMethods(): boolean {
    return this.userPaymentMethods.length > 0;
  }

}
