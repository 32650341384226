<div class="bg-white p-8 rounded-lg shadow-lg">
  <button class="text-red-500 float-right" (click)="closeModal()">Close</button>
  <h2 class="text-2xl mb-6 text-grey-grey-5">{{ affiliate?.id ? 'Edit' : 'New' }} Affiliate</h2>
  <div *ngIf="affiliate?.archivedDate" class="text-red-500 mb-6">
    Archived: {{ affiliate?.archivedDate | date }}
  </div>

  <form *ngIf="!!affiliate" [formGroup]="affiliateForm" class="space-y-6" (submit)="saveAffiliate()">
    <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Name</label>
      <input formControlName="name" type="text" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Code</label>
      <input formControlName="code" type="text" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Description</label>
      <textarea formControlName="description" rows="3" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8"></textarea>
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Platform Percentage</label>
      <input formControlName="intPlatformPercentage" type="number" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Platform Amount</label>
      <input formControlName="intPlatformAmount" type="number" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-grey-grey-5">Duration (Days)</label>
      <input formControlName="intDurationDays" type="number" [attr.disabled]="affiliate.archivedDate ? true : null" class="border rounded p-2 text-grey-grey-8" />
    </div>

    <div class="flex items-center space-x-4 justify-between">
      <button type="submit" class="bg-business-blue text-white px-6 py-2 rounded" [disabled]="affiliateForm.invalid || affiliate.archivedDate">Save</button>
      <div class="flex items-center space-x-4">
        <button type="button" class="text-business-blue" (click)="resetForm()">Undo/Reset</button>
        <button *ngIf="!!affiliate.id && !affiliate.archivedDate" type="button" class="bg-red-500 text-white px-6 py-2 rounded" (click)="archiveAffiliate()">Archive</button>
        <button *ngIf="!!affiliate.id && affiliate.archivedDate" type="button" class="bg-red-500 text-white px-6 py-2 rounded" (click)="unarchiveAffiliate()">Unarchive</button>
      </div>
    </div>
  </form>
</div>
