<div class="p-0 cursor-pointer font-outfit" (click)="goToOffer(place)">
  <div class="flex flex-row justify-between items-center pl-2 min-h-[20px]"
  [ngClass]="{'bg-charity-green': isCharityOffer, 'bg-pink': !isCharityOffer} "
  >
    <div class="text-lg text-white text-left flex-grow">{{ place.name }}</div>
    <div *ngIf="!!intReturnPercentage || !!intReturnAmount"
    class="p-3 justify-center items-center gap-[8.24px] inline-flex ml-1">
    <div *ngIf="!!intReturnPercentage" class="text-center">
      <div class="text-white text-lg font-bold antialiased">{{(intReturnPercentage / 100.0)}}%</div>
    </div>
    <div *ngIf="!!intReturnAmount" class="text-center">
      <div class="text-white text-lg font-bold antialiased">{{(intReturnAmount / 100.0) | currency}}</div>
    </div>
  </div>
  </div>
  <div class="p-2 flex flex-col justify-center items-start gap-1">
    <div>{{ place.address }}</div>
    <div>{{ place.phone | phone }}</div>
  </div>

</div>
