<div class="w-full flex justify-center items-center" style="background-image: url(../../../assets/images/fundraising-header.png); background-position: top center; background-size: cover;">
  <div class="relative flex flex-row justify-center w-full max-w-[1500px]">
    <div
        class="flex flex-col max-w-[85%] ml-0 xl:ml-7 lg:ml-5 md:ml-3 md:mr-[50px] xl:mr-0 xl:max-w-8xl sm:items-start text-white mt-[7%] lg:mt-[12%] mb-4">
        <p class="text-[28px] sm:text-[36px] lg:text-[52px] font-extrabold antialiased w-full sm:w-[60%]">Raise Money for your
            Organization while Supporting Local Businesses</p>
        <p class="text-lg lg:text-4xl font-normal w-full sm:w-[60%] mt-10">You Keep 100% of the Profit!
        </p>
        <div class="flex flex-col sm:flex-row">
            <div style="height: 50px;" class="flex sm:mb-[75px] mt-4 sm:mt-5 justify-center sm:justify-normal">
                <button
                    class="button-charity-green w-full max-w-[325px] corner-25 min-w-[175px]"
                    (click)="scrollToWaitlist()">
                    Get Started Now
                </button>
            </div>
            <div style="height: 50px;" class="flex sm:mb-[75px] mt-4 sm:mt-5 ml-0 sm:ml-4 justify-center sm:justify-normal">
                <button
                    class="w-full max-w-[325px] corner-25 hover:border-[#04A100] border-2 hover:bg-white hover:text-[#04A100] min-w-[175px]"
                    >
                    Contact Us
                </button>
            </div>
        </div>

      </div>
    <div class="absolute left-0 right-[50px] top-0 bottom-[-40px] flex justify-end items-end">
      <img
          class="w-[25%] h-auto max-w-[290px] mt-0 hidden sm:block"
          src="../../../assets/images/fundraising-iphone.png" alt="iphone">
    </div>
  </div>
</div>

<!-- HOW IT WORKS -->
<div class="flex flex-col justify-center items-center bg-[#EFEFF4]" id="howItWorks">
    <div class="flex flex-col justify-center items-center mt-[100px]">
        <p class="text-[36px] font-bold antialiased font-extrabold antialiased text-center">Raising Money is as Easy as 1 2 3</p>
    </div>
    <div class="flex flex-col lg:flex-row sm:justify-center items-center sm:items-start mt-8 pb-[100px]">
        <div class="flex flex-col justify-center w-[300px] items-center mt-8 mx-4">
            <svg width="153" height="153" viewBox="0 0 153 153" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4829_27)">
                    <path
                        d="M95.7746 102C96.1002 99.9148 96.259 97.7728 96.259 95.625C96.259 93.4888 96.1002 91.3725 95.7816 89.3006L106.319 81.0326C108.792 79.0879 109.475 75.5697 107.906 72.8277L101.104 61.0473C99.5228 58.3123 96.1259 57.1392 93.1995 58.3182L80.7806 63.3224C77.3896 60.5886 73.7103 58.4583 69.8162 56.9793L67.9287 43.72C67.4828 40.6021 64.7735 38.25 61.6171 38.25H48.0263C44.8699 38.25 42.1606 40.6021 41.7147 43.7258L39.8272 56.9921C35.9261 58.4909 32.2094 60.6458 28.9141 63.3224L16.438 58.3182C13.5116 57.145 10.1206 58.3182 8.53892 61.0473L1.74292 72.8277C0.162399 75.5697 0.851104 79.1007 3.34328 81.0385L13.8688 89.2504C13.5431 91.3352 13.3844 93.4772 13.3844 95.625C13.3844 97.7612 13.5431 99.8775 13.8618 101.949L3.32461 110.217C0.851104 112.162 0.169402 115.68 1.73708 118.422L8.53309 130.203C10.1136 132.932 13.5116 134.105 16.438 132.932L28.8569 127.928C32.2479 130.661 35.9261 132.792 39.8213 134.271L41.7088 147.53C42.1606 150.648 44.8699 153 48.0263 153H61.6171C64.7735 153 67.4828 150.648 67.9287 147.524L69.8162 134.258C73.7173 132.759 77.434 130.604 80.7293 127.928L93.2053 132.932C96.1446 134.111 99.5298 132.938 101.104 130.203L107.9 118.422C109.481 115.68 108.792 112.149 106.3 110.212L95.7746 102ZM54.8223 117.938C42.5178 117.938 32.5094 107.928 32.5094 95.625C32.5094 83.3217 42.5178 73.3121 54.8223 73.3121C67.1256 73.3121 77.134 83.3217 77.134 95.625C77.134 107.928 67.1256 117.938 54.8223 117.938Z"
                        fill="#04A100" />
                    <path
                        d="M145.366 34.8648C145.488 33.8773 145.666 32.9014 145.666 31.8754C145.666 30.8482 145.488 29.8735 145.366 28.8848L151.111 24.5378C152.391 23.5689 152.748 21.7958 151.945 20.3997L146.788 11.4745C145.985 10.0854 144.269 9.5053 142.79 10.1298L136.18 12.9161C134.566 11.6858 132.852 10.6014 130.958 9.81113L130.079 2.79218C129.875 1.19881 128.523 0 126.917 0H116.609C115.003 0 113.644 1.19881 113.447 2.79218L112.566 9.81113C110.673 10.6014 108.952 11.6917 107.345 12.9161L100.735 10.1298C99.2499 9.5053 97.5339 10.0784 96.7308 11.4745L91.5807 20.3997C90.7765 21.7899 91.1336 23.5619 92.4153 24.5378L98.1596 28.8848C98.0312 29.8735 97.8538 30.8482 97.8538 31.8754C97.8538 32.9014 98.0312 33.8773 98.1526 34.8648L92.4095 39.213C91.1278 40.1819 90.7706 41.9538 91.5737 43.3499L96.7308 52.2751C97.5339 53.6653 99.2499 54.2443 100.729 53.6198L107.339 50.8276C108.952 52.058 110.667 53.1424 112.561 53.9326L113.44 60.9516C113.644 62.552 114.996 63.7496 116.602 63.7496H126.91C128.518 63.7496 129.875 62.552 130.072 60.9574L130.952 53.9385C132.846 53.1482 134.566 52.058 136.174 50.8346L142.784 53.6198C144.269 54.2443 145.985 53.6712 146.788 52.2751L151.938 43.3499C152.742 41.9597 152.385 40.1877 151.104 39.213L145.366 34.8648ZM121.76 44.6246C114.715 44.6246 109.01 38.9189 109.01 31.8754C109.01 24.8307 114.715 19.125 121.76 19.125C128.803 19.125 134.509 24.8307 134.509 31.8754C134.509 38.9189 128.803 44.6246 121.76 44.6246Z"
                        fill="#04A100" />
                </g>
                <defs>
                    <clipPath id="clip0_4829_27">
                        <rect width="153" height="153" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <p class="text-xl sm:text-[22px] font-bold antialiased mt-4 text-gray-800 font-bold antialiased">Setup</p>
            <p class="sm:text-sm mt-3 text-gray-500 break-words text-center font-sans font-normal">Your Organization can
                sign up and claim restaurant donation offers in just a few minutes
            </p>
        </div>
        <div class="flex flex-col justify-center w-[300px] items-center mt-8 mx-4">
            <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M113.123 49.705C116.25 50.5421 118.856 52.5333 120.46 55.3121C123.555 60.674 122.054 67.4481 117.193 71.0477L105.196 50.2731C107.681 49.1957 110.447 48.9879 113.123 49.7047V49.705ZM38.3397 128.163L55.0807 118.499L33.0255 80.3509L16.3084 90.0043C11.2376 92.9319 7.60256 97.6859 6.07554 103.389C4.54521 109.093 5.31519 115.029 8.24271 120.098C12.3211 127.158 19.7416 131.11 27.3621 131.11C31.0964 131.11 34.8789 130.161 38.3397 128.163ZM73.2654 150.047L59.2068 125.7L46.085 133.276L60.1402 157.624C62.2323 161.242 66.8741 162.485 70.4933 160.397C72.2331 159.392 73.4815 157.756 74.0098 155.794C74.5347 153.83 74.2708 151.79 73.2654 150.048V150.047ZM125.543 102.113L82.4693 27.5094C81.5728 25.9535 80.0986 25.1005 78.3156 25.1005C78.2821 25.1005 78.2469 25.1022 78.2133 25.1022C76.3838 25.1357 74.9033 26.0448 74.0453 27.6631C72.5313 30.5203 71.0713 33.5693 69.5267 36.798C63.0265 50.3744 55.7182 65.6366 40.1456 76.0773L62.3382 114.472C79.1496 106.219 96.0172 107.519 111.017 108.677C114.591 108.954 117.963 109.214 121.198 109.332C123.031 109.403 124.555 108.574 125.498 107.005C126.444 105.436 126.458 103.698 125.544 102.114L125.543 102.113ZM114.715 25.5119C113.571 27.4968 114.251 30.0352 116.236 31.1814C116.887 31.5592 117.601 31.7385 118.305 31.7385C119.739 31.7385 121.137 30.9941 121.904 29.6626L130.478 14.8169C131.623 12.832 130.943 10.2936 128.958 9.14742C126.971 8.00158 124.434 8.68158 123.29 10.6665L114.715 25.5119ZM164.693 71.0337C164.693 68.7434 162.836 66.8833 160.531 66.8833L143.394 66.885C141.103 66.885 139.243 68.7434 139.243 71.0354C139.243 73.3274 141.103 75.1858 143.394 75.1858L160.531 75.1841C162.836 75.1841 164.693 73.3274 164.693 71.0337ZM154.769 35.5031C153.617 33.5182 151.088 32.8382 149.103 33.984L133.894 42.7619C131.907 43.9098 131.227 46.4465 132.373 48.433C133.143 49.7648 134.537 50.5092 135.971 50.5092C136.676 50.5092 137.39 50.3283 138.044 49.9521L153.249 41.1725C155.234 40.0263 155.922 37.488 154.769 35.5031Z"
                    fill="#04A100" />
            </svg>
            <p class="text-xl sm:text-[22px] font-bold antialiased mt-4 text-gray-800 font-bold antialiased">Promote</p>
            <p class="sm:text-sm mt-3 text-gray-500 text-center font-sans font-normal">Your participants can then easily
                promote the fundraiser via you website, social media, email and text message.</p>
        </div>
        <div class="flex flex-col justify-center w-[300px] items-center mt-8 mx-4">
            <svg width="150" height="158" viewBox="0 0 150 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.36715 154.715H13.1014C15.5133 154.715 17.4686 152.406 17.4686 149.558V92.8291H4.36715C1.95532 92.8291 0 95.1381 0 97.9863V149.558C0 152.406 1.95532 154.715 4.36715 154.715Z"
                    fill="#04A100" />
                <path
                    d="M114.129 0C94.8331 0 79.1914 18.4712 79.1914 41.2573C79.1914 64.0427 94.8331 82.5146 114.129 82.5146C133.424 82.5146 149.066 64.0427 149.066 41.2573C149.066 18.4712 133.424 0 114.129 0ZM121.447 59.8183C120.501 60.3766 119.51 60.8164 118.495 61.1492V62.5743C118.495 65.4224 116.54 67.7315 114.128 67.7315C111.716 67.7315 109.761 65.4224 109.761 62.5743V61.3104C107.943 60.77 106.248 59.8613 104.811 58.5895C102.882 56.8808 102.491 53.6483 103.938 51.3695C105.385 49.0914 108.123 48.629 110.052 50.3381C111.799 51.8852 115.159 52 117.541 50.5932C118.499 50.0276 119.015 49.3468 119.015 48.9938C119.015 48.3766 119.015 48.2814 118.434 47.9152C117.295 47.197 115.28 46.7555 113.331 46.3289C110.73 45.7588 108.04 45.1695 105.709 43.7004C102.306 41.5551 100.506 38.0355 100.506 33.5223C100.506 29.0039 102.801 25.0576 106.801 22.6956C107.75 22.1356 108.744 21.6924 109.761 21.3593V19.9417C109.761 17.0936 111.716 14.7846 114.128 14.7846C116.54 14.7846 118.495 17.0936 118.495 19.9417V21.2045C120.313 21.7454 122.008 22.6547 123.445 23.9265C125.374 25.6356 125.765 28.8681 124.318 31.1465C122.871 33.425 120.134 33.887 118.204 32.178C116.455 30.6284 113.092 30.5136 110.707 31.9208C109.744 32.4894 109.241 33.1568 109.241 33.5219C109.241 34.1394 109.241 34.2343 109.822 34.6008C110.961 35.319 112.976 35.7605 114.925 36.1871C117.526 36.7572 120.216 37.3465 122.547 38.8156C125.95 40.9609 127.75 44.4805 127.75 48.9938C127.75 53.5076 125.453 57.4532 121.447 59.8183Z"
                    fill="#04A100" />
                <path
                    d="M78.8421 157.466H79.8029C94.4765 157.26 108.538 152.344 120.942 143.473L145.31 123.36C149.415 119.956 150.201 113.561 147.494 109.023C144.699 104.278 139.283 103.144 135.353 106.445L111.945 125.836C104.434 130.993 95.2629 132.127 86.965 128.826L60.0343 118.305C57.7634 117.481 56.5403 114.489 57.3266 111.808C58.0251 109.229 60.296 107.785 62.5669 108.507C62.6543 108.507 92.8167 120.06 92.8167 120.06C97.896 121.857 102.409 118.464 103.822 113.458C105.306 107.991 102.774 102.215 98.232 100.462L57.1517 84.5782C48.1664 80.7155 36.058 81.5135 26.2031 88.0212V145.755L60.4707 154.922C66.748 156.553 72.9075 157.466 78.8421 157.466Z"
                    fill="#04A100" />
            </svg>
            <p class="text-xl sm:text-[22px] font-bold antialiased mt-4 text-gray-800 font-bold antialiased">Raise Money</p>
            <p class="sm:text-sm mt-3 text-gray-500 break-words text-center font-sans font-normal">Your supporters can
                easily connect a credit card to the donation offer. Once they visit and pay at the restaurant, your
                organization will get paid the donation percentage.</p>
        </div>
    </div>
    <div style="height: 50px;" class="flex mb-[75px] mt-5">
        <button
            class="bg-[#04A100] text-white hover:border-[#04A100] border-2 hover:bg-white hover:text-[#04A100] transition-all duration-150"
            style="width: 150px; height: 50px; border-color: #04A100; border-radius: 25px;">
            Request a Demo
        </button>
    </div>
</div>

<!-- Fundraising -->
<div class="w-full flex justify-center items-center bg-[#E1E3E6] px-[50px] lg:px-[200px] py-[50px]">
  <div class="flex flex-col justify-center items-start bg-[#E1E3E6] w-full max-w-[1280px]" id="howItWorks">
      <p class="text-3xl font-bold antialiased text-[36px]">The easiest way to raise funds for your local school, church or sports
          team.</p>

      <div class="flex flex-row w-full justify-between">
          <div class="w-full xl:w-[30vw] flex flex-col">
              <p class="text-[#04A100] text-2xl font-bold antialiased mt-10">Fundraising Made Easy</p>
              <p class="text-md mt-7">If your local school, club or sports team is holding fundraisers, we’ve found a
                  better
                  way to
                  make them easy.</p>
              <p class="text-md mt-5">GetZeals makes fundraising events with your local business partners simple and
                  frictionless.
                  From marketing of the event to the revenue split from the business and your organization managed with no
                  paperwork. Download the app and register as a fundraiser to bring GetZeals to you’re your next event.
              </p>
              <div style="height: 50px;" class="mb-[75px] mt-7">
                  <button
                      class="w-full bg-[#04A100] text-white hover:bg-white hover:text-[#04A100] rounded-2xl py-2 px-4 transition-all duration-150 text-sm sm:text-base font-sans font-normal border border-white hover:border-white hover:border-[#04A100]"
                      style="width: 150px; height: 50px; border-color: #04A100; border-radius: 25px;"
                      routerLink="/fundraising">
                      Learn More
                  </button>
              </div>
          </div>
          <div class="flex flex-row lg:items-end w-[700px] justify-evenly mt-5 ml-10" *ngIf="getScreenWidth > 1280">
              <img class="w-[150px] h-[325px] xl:w-[175px] xl:h-[375px] lg:w-[150px] lg:h-[300px]" src="../../../assets/images/iphone-left.png"
                  alt="iphone-left">
              <img class="w-[200px] h-[425px] xl:w-[225px] xl:h-[475px] lg:w-[200px] lg:h-[400px] xl:mt-0 ml-2"
                  src="../../../assets/images/iphone-center.png" alt="iphone-center">
              <img class="w-[150px] h-[325px] xl:w-[175px] xl:h-[375px] lg:w-[150px] lg:h-[300px] ml-2"
                  src="../../../assets/images/iphone-right.png" alt="iphone-right">
          </div>
      </div>
  </div>
</div>

<!-- FAQ -->
<div class="w-full flex justify-center items-center px-[50px] lg:px-[200px] py-[50px]">
  <div class="flex flex-col justify-center items-center w-full max-w-[1280px]" id="howItWorks">
    <p class="text-3xl font-bold antialiased text-[36px] text-center mb-10 ">Frequently Asked Questions</p>

    <div class="w-full p-4 border-grey-300 border-t"
        *ngFor="let question of questions; let i = index">
        <button class="w-full flex flex-row justify-between items-center rounded-xl" (click)="toggleFAQ(question, i)">
            <p class="text-[#04A100] text-md font-bold antialiased">{{question.question}}</p>
            <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="transition-transform duration-300 ease-in-out"
              [ngClass]="{
                'transform rotate-180': question.isOpen,
                'transform rotate-0': !question.isOpen
              }">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.667 12.6043C18.1444 12.6043 17.6217 12.4042 17.2236 12.0061L10.4759 5.25839L3.75266 11.7509C2.94008 12.5349 1.64975 12.5145 0.865745 11.6999C0.0817455 10.8893 0.104204 9.59489 0.914745 8.81293L9.08141 0.928018C9.88379 0.154226 11.1578 0.164434 11.9438 0.952518L20.1105 9.11918C20.9088 9.91748 20.9088 11.2078 20.1105 12.0061C19.7124 12.4042 19.1897 12.6043 18.667 12.6043Z"
                    fill="#04A100" />
            </svg>
        </button>

        <div *ngIf="question.isOpen" class="w-full">
          <p class="text-sm mt-5">{{question.answer}}
          </p>
        </div>
    </div>
    <div style="height: 50px;" class="flex mb-[75px] mt-7">
        <button
            class="bg-[#04A100] hover:border-[#04A100] border-2 hover:bg-white hover:text-[#04A100] transition-all duration-150 text-white"
            style="width: 150px; height: 50px; border-color: #04A100; border-radius: 25px;">
            Contact Sales
        </button>
    </div>
  </div>
</div>

<!-- WAITLIST FORM MODAL -->
<div class="w-full flex justify-center items-center px-[50px] lg:px-[200px] py-[50px]">
  <div class="flex flex-col justify-center items-center w-full max-w-[1280px]" id="waitlist">
    <app-waitlist [businessType]="businessType"></app-waitlist>
  </div>
</div>
