import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  constructor(private http: HttpClient) { }

  async uploadFileToS3(url: string, file: File, contentType?: string) {
    if (!url || !file) return false;

    if (!contentType) {
      contentType = file.type;
    }

    const headers = new HttpHeaders({ 'Content-Type': contentType, 'Cache-Control': 'public, max-age=86400' });
    const req = new HttpRequest(
      'PUT',
      url,
      file,
      {
        headers: headers,
      });
    return this.http.request(req).toPromise();
  }
}
