import moment from "moment";
import { Weeks, parseWeeksOfTheMonthInt, weeksOfMonthToInt } from "../utils/enums/weeks.enum";
import { AllDaysOfTheWeek, WeekDays, daysOfWeekToInt, parseDaysOfTheWeekInt } from "../utils/enums/weeks_days.enum";
import { Charity } from "./charity.model";
import { Entity } from "./entity.model";
import { BaseImage } from "./image.model";
import { CharityIncentive, PatronClaim, PatronIncentive, PatronOffer, PatronVenue, RewardDetails } from "./patron-offer.model";
import { IPromotion } from "./promotion.model";
import { Patron } from "./user.model";
import { Venue } from "./venue.model";
import { utcDateStringToDate } from "../utils/enums/date.utils";


export enum OfferReturnType {
  Percentage = 0,
  Amount = 1
}

export class VenueOffer extends Entity {

  constructor() {
    super();
  }

  venueId: string
  // charityId: string
  charity?: Charity;
  name: string
  internalName: string
  description: string
  finePrint: string
  claimStartDate: Date
  claimEndDate: Date
  archivedDate: Date
  disabledDate: Date
  redeemStartDate: Date
  redeemEndDate: Date
  startTimeOfDay: number
  endTimeOfDay: number
  daysOfTheWeek: WeekDays[] = []
  weeksOfTheMonth: Weeks[] = []
  isVenueDefault: boolean
  isUniversal: boolean
  singlePatronLimit: number
  returnType: OfferReturnType
  intPercentage: number = 1500;  // Default to 15%
  intAmount: number
  intMinSpend: number
  intMaxReward: number
  patronsLimit: number
  showInApp: boolean
  promotions: IPromotion[] = []
  offerImage: BaseImage;


  disabled(): boolean {
    if (!this.disabledDate || this.disabledDate.getTime() <= 0) return false;
    return true;
  }

  archived(): boolean {
    if (!this.archivedDate || this.archivedDate.getTime() <= 0) return false;
    return true;
  }

  alwaysClaimable(): boolean {
    if ((!this.claimStartDate || this.claimStartDate.getTime() <= 0) && (!this.claimEndDate || this.claimEndDate.getTime() <= 0)) return true;
    return false;
  }

  alwaysRedeemable(): boolean {
    if ((!this.redeemStartDate || this.redeemStartDate.getTime() <= 0) && (!this.redeemEndDate || this.redeemEndDate.getTime() <= 0)) return true;
    return false;
  }

  claimable() {
    if (this.disabled() || this.archived()) return false;
    if (this.alwaysClaimable()) return true;
    const now = moment();
    if (this.claimStartDate) {
      const date = moment.utc(this.claimStartDate);
      if (date.isAfter(now)) return false;
    }
    if (this.claimEndDate) {
      const date = moment.utc(this.claimEndDate);
      if (date.isBefore(now)) return false;
    }
    return true;
  }

  redeemable() {
    if (this.disabled() || this.archived()) return false;
    if (this.alwaysRedeemable()) return true;
    const now = moment();
    if (this.redeemStartDate) {
      const date = moment.utc(this.redeemStartDate);
      if (date.isAfter(now)) return false;
    }
    if (this.redeemEndDate) {
      const date = moment.utc(this.redeemEndDate);
      if (date.isBefore(now)) return false;
    }
    return true;
  }


  static toPatronOffer(venueOffer: VenueOffer, venue: Venue, claimed: boolean = false, rewarded: boolean = false, offerImageFile?): PatronOffer {
    let result: PatronOffer = new PatronOffer();
    result.id = venueOffer.id;
    result.name = venueOffer.name;
    result.description = venueOffer.description;
    result.finePrint = venueOffer.finePrint;
    result.redeemStartDate = venueOffer.redeemStartDate;
    result.redeemEndDate = venueOffer.redeemEndDate;
    result.archivedDate = venueOffer.archivedDate;
    result.claimStartDate = venueOffer.claimStartDate;
    result.claimEndDate = venueOffer.claimEndDate;
    result.startTimeOfDay = venueOffer.startTimeOfDay;
    result.endTimeOfDay = venueOffer.endTimeOfDay;
    result.daysOfTheWeek = venueOffer.daysOfTheWeek;
    result.weeksOfTheMonth = venueOffer.weeksOfTheMonth;
    result.patronsLimit = venueOffer.patronsLimit;
    result.singlePatronLimit = venueOffer.singlePatronLimit;
    result.isVenueDefault = venueOffer.isVenueDefault;
    result.isUniversal = venueOffer.isUniversal;
    result.showInApp = venueOffer.showInApp;

    if (!!venueOffer.charity) {
      result.charityIncentive = new CharityIncentive();
      result.charityIncentive.charity = venueOffer.charity;
      result.charityIncentive.intMaxReward = venueOffer.intMaxReward;
      result.charityIncentive.intMinSpend = venueOffer.intMinSpend;
      result.charityIncentive.intReturnAmount = venueOffer.intAmount;
      result.charityIncentive.intReturnPercentage = venueOffer.intPercentage;
    }
    else {
      result.patronIncentive = new PatronIncentive();
      result.patronIncentive.intMaxReward = venueOffer.intMaxReward;
      result.patronIncentive.intMinSpend = venueOffer.intMinSpend;
      result.patronIncentive.intReturnAmount = venueOffer.intAmount;
      result.patronIncentive.intReturnPercentage = venueOffer.intPercentage;
    }
    result.venue = new PatronVenue();
    result.venue.id = venue.id;
    result.venue.venueAddresses = venue.venueAddresses;
    result.venue.venueImages = venue.venueImages;
    result.venue.name = venue.name;
    // result.venue.name = this.ven;

    if (!!venueOffer.offerImage) {
      result.offerImage = new BaseImage();
      result.offerImage.id = venueOffer.offerImage.id;
      result.offerImage.name = venueOffer.offerImage.name;
      result.offerImage.type = venueOffer.offerImage.type;
      result.offerImage.dateAdded = venueOffer.offerImage.dateAdded;
      result.offerImage.url = offerImageFile ?? venueOffer.offerImage.url;
      result.offerImage.urlExpiry = venueOffer.offerImage.urlExpiry;
    }

    if (claimed || rewarded) {
      result.claim = new PatronClaim();
      result.claim.claimedDate = new Date();

      if (rewarded) {
        result.claim.rewards = [];
        const reward = new RewardDetails();
        reward.matchedDate = new Date();
        result.claim.rewards.push(reward);
      }
    }

    return result;
  }

  static override parseResponse(details: any, result?: VenueOffer): VenueOffer {
    if (!details) return null;
    if (!result) result = new VenueOffer();

    Entity.parseResponse(details, result);

    result.venueId = details.venue?.id;
    // result.charityId = details.charityIncentive?.charity?.id;
    if (details.charityIncentive?.charity && !details.charityIncentive?.charity?.disabledDate) {
      result.charity = Charity.parseResponse(details.charityIncentive?.charity);
    }
    result.name = details.name;
    result.internalName = details.name ?? details.internalName;
    result.description = details.description;
    result.finePrint = details.finePrint;
    if (details.redeemStartDate) {
      result.redeemStartDate = new Date(details.redeemStartDate);
    }
    if (details.redeemEndDate) {
      result.redeemEndDate = new Date(details.redeemEndDate);
    }
    if (details.archivedDate) {
      result.archivedDate = new Date(details.archivedDate);
    }
    if (details.disabledDate) {
      result.disabledDate = new Date(details.disabledDate);
    }
    if (details.claimStartDate) {
      result.claimStartDate = new Date(details.claimStartDate);
    }
    if (details.claimEndDate) {
      result.claimEndDate = new Date(details.claimEndDate);
    }
    result.startTimeOfDay = details.startTimeOfDay;
    result.endTimeOfDay = details.endTimeOfDay;
    result.daysOfTheWeek = parseDaysOfTheWeekInt(details.daysOfTheWeek);
    result.weeksOfTheMonth = parseWeeksOfTheMonthInt(details.weeksOfTheMonth);
    result.patronsLimit = details.patronsLimit;
    result.singlePatronLimit = details.singlePatronLimit;
    result.isVenueDefault = details.isVenueDefault;
    result.isUniversal = details.isUniversal;
    result.showInApp = details.showInApp;

    const incentive = (!!details.charityIncentive && !details.charityIncentive.disabledDate) ? details.charityIncentive : details.patronIncentive;
    if (!!incentive && !incentive.disabledDate) {
      result.intPercentage = incentive.intReturnPercentage;
      result.intAmount = incentive.intReturnAmount;
      result.intMinSpend = incentive.intMinSpend;
      result.intMaxReward = incentive.intMaxReward;
    }

    if (!!result.intAmount && result.intAmount > 0) {
      result.returnType = OfferReturnType.Amount;
    }
    else {
      result.returnType = OfferReturnType.Percentage;
    }

    result.offerImage = BaseImage.parseResponse(details.offerImage);

    // TODO: Promotions

    return result;
  }

  static parseForm(offerForm, offerClone: VenueOffer) {
    const formValue = { ...offerForm.value.availableGroup, ...offerForm.value.detailsGroup, ...offerForm.value.incentiveGroup };

    const dateFields = ["claimStartDate", "claimEndDate", "redeemStartDate", "redeemEndDate", "archivedDate", "disabledDate"];
    Object.keys(formValue).forEach((key) => {
      // Update the offerClone, if this is a date field, convert it to a date
      if (dateFields.includes(key)) {
        offerClone[key] = utcDateStringToDate(formValue[key]) ?? "";
      }
      else {
        offerClone[key] = formValue[key];
      }
    });

    // Handle offer image
    offerClone.offerImage = offerForm.value.offerImage;
    // If no offer image, then create one
    if (!offerClone.offerImage) {
      offerClone.offerImage = new BaseImage();
      offerClone.offerImage.type = "banner";
    }

    // Handle Charity
    if (!formValue['isCharity']) {
      offerClone.charity = null;
    }

    // Handle amount
    if (offerClone.returnType === OfferReturnType.Amount) {
      offerClone.intAmount = Math.round(formValue['amount'] * 100);
    }
    else {
      offerClone.intAmount = 0;
    }
    if (offerClone.returnType === OfferReturnType.Percentage) {
      offerClone.intPercentage = Math.round(formValue['percentage'] * 100);
    }
    else {
      offerClone.intPercentage = 0;
    }

    offerClone.intMaxReward = Math.round((formValue['maxReward'] ?? 0) * 100) ?? 0;
    offerClone.intMinSpend = Math.round((formValue['minSpend'] ?? 0) * 100) ?? 0;

    if (formValue['alwaysClaimable']) {
      offerClone.claimStartDate = null;
      offerClone.claimEndDate = null;
    }
    if (formValue['alwaysRedeemable']) {
      offerClone.redeemStartDate = null;
      offerClone.redeemEndDate = null;
    }
    if (formValue['isAllDays']) {
      offerClone.daysOfTheWeek = AllDaysOfTheWeek.concat([]);
    }
  }

  override toRequest(): any {
    const result: any = super.toRequest();
    result.venueId = this.venueId;
    result.charityId = this.charity?.id;
    result.name = this.name;
    result.internalName = this.name ?? this.internalName;
    result.description = this.description;
    result.finePrint = this.finePrint;
    if (this.claimStartDate) {
      result.claimStartDate = this.claimStartDate.toISOString();
    }
    if (this.claimEndDate) {
      result.claimEndDate = this.claimEndDate.toISOString();
    }
    if (this.archivedDate) {
      result.archivedDate = this.archivedDate.toISOString();
    }
    if (this.redeemStartDate) {
      result.redeemStartDate = this.redeemStartDate.toISOString();
    }
    if (this.redeemEndDate) {
      result.redeemEndDate = this.redeemEndDate.toISOString();
    }
    if (this.disabledDate) {
      result.disabledDate = this.disabledDate.toISOString();
    }
    result.startTimeOfDay = this.startTimeOfDay;
    result.endTimeOfDay = this.endTimeOfDay;
    result.daysOfTheWeek = daysOfWeekToInt(this.daysOfTheWeek);
    result.weeksOfTheMonth = weeksOfMonthToInt(this.weeksOfTheMonth);
    result.isVenueDefault = this.isVenueDefault;
    result.isUniversal = this.isUniversal;
    result.singlePatronLimit = this.singlePatronLimit;
    result.intPercentage = this.intPercentage;
    result.intAmount = this.intAmount;
    result.intMinSpend = this.intMinSpend;
    result.intMaxReward = this.intMaxReward;
    result.patronsLimit = this.patronsLimit;

    // TODO: Promotions

    return result;
  }

  clone(): VenueOffer {
    const result: VenueOffer = new VenueOffer();
    Object.keys(this).forEach((key) => {
      result[key] = this[key];
    });

    return result;
  }

}
