import { Injectable } from '@angular/core';
import { FetchState } from './app.module';
import { Apollo } from 'apollo-angular';
import { AddFidelUserPaymentMethodMutation } from './graphql/mutations/add_fidel_user_payment_method.mutation.graphql';
import { BehaviorSubject } from 'rxjs';
import { FidelConfigQuery } from './graphql/queries/fidel-config.query.graphql';
import { UserPaymentMethodsQuery } from './graphql/queries/user_payment_methods.query.graphql';
import { RemoveUserPaymentMethodMutation } from './graphql/mutations/remove_user_payment_method.mutation.graphql copy';
import { UserPaymentMethod } from './model/user_payment_method.model';
import { LoginState, UserAuthService } from './user-auth.service';

export type FidelConfig = {
  sdkKey: string,
  programId: string,
  programName: string,
  liveEnv: boolean
}

@Injectable({
  providedIn: 'root'
})
export class FidelService {

  cardAddedResult$: BehaviorSubject<UserPaymentMethod> = new BehaviorSubject(null);
  private programId = "0de8a6d5-6fb6-4dfb-9f5a-d5da005b733a";

  constructor(
    private readonly apollo: Apollo,
    private readonly userAuthService: UserAuthService
  ) {}

  async init() {
    if (this.configState === FetchState.LOADED_ALL) {
      return;
    }

    if (this.userAuthService.state$.value === LoginState.LOGGED_IN) {
      this.fetchConfig();
    }
    else {
      this.userAuthService.state$.subscribe({
        next: (state) => {
          if (state === LoginState.LOGGED_IN) {
            this.fetchConfig();
          }
        }
      });
    }
  }

  configState: FetchState = FetchState.NONE;
  fidelConfig$: BehaviorSubject<FidelConfig> = new BehaviorSubject(null);
  private async fetchConfig() {
    this.configState = FetchState.LOADING;
    this.apollo.query({
      query: FidelConfigQuery,
    }).subscribe({
      next: (result) => {
        if (!!result?.data && !!result.data['getFidelConfig']) {
          const config = result.data['getFidelConfig'];
          this.configState = FetchState.LOADED_ALL;
          this.fidelConfig$.next({
            sdkKey: config.sdkKey,
            programId: config.programId,
            programName: config.programName,
            liveEnv: config.liveEnv
          });

          localStorage.setItem("fidel_config", JSON.stringify(this.fidelConfig$.value));
        }
      },
      error: (error) => {
        this.configState = FetchState.ERROR;
        console.error(error);
      }
    });
  }

  stateUserPaymentMethods$: BehaviorSubject<FetchState> = new BehaviorSubject(FetchState.NONE);
  userPaymentMethods$: BehaviorSubject<Array<UserPaymentMethod>> = new BehaviorSubject([]);
  async fetchUserPaymentMethods(userId: string) {
    if (this.stateUserPaymentMethods$.value === FetchState.LOADING) {
      return;
    }
    return this._fetchUserPaymentMethods(userId);
  }

  private _fetchUserPaymentMethodsRetryCount = 0;
  private async _fetchUserPaymentMethods(userId: string) {
    this.stateUserPaymentMethods$.next(FetchState.LOADING);

    this.apollo.query({
      query: UserPaymentMethodsQuery,
      variables: {
        id: userId
      },
      fetchPolicy: 'network-only'
    }).subscribe({
      next: (result) => {
        this._fetchUserPaymentMethodsRetryCount = 0;
        const results = [];
        if (!!result?.data && !!result.data['userPaymentMethods']) {
          for(const nextData of result.data['userPaymentMethods']) {
            results.push(UserPaymentMethod.parseResponse(nextData));
          }
        }
        this.userPaymentMethods$.next(results);
        const activeResults = (results || []).filter((userPaymentMethod) => !userPaymentMethod.archivedDate || userPaymentMethod.archivedDate.getTime() <= 0);
        this.activeUserPaymentMethods$.next(activeResults);

        const archivedResults = (results || []).filter((userPaymentMethod) => !!userPaymentMethod.archivedDate && userPaymentMethod.archivedDate.getTime() > 0);
        this.archivedUserPaymentMethods$.next(archivedResults);

        this.stateUserPaymentMethods$.next(FetchState.LOADED_ALL);
      },
      error: (error) => {
        this._fetchUserPaymentMethodsRetryCount++;

        if (this._fetchUserPaymentMethodsRetryCount < 5) {
          console.log(`Retrying fetch user payment methods in ${150 * Math.pow(2, this._fetchUserPaymentMethodsRetryCount)}ms`);
          setTimeout(() => {
            this._fetchUserPaymentMethods(userId);
          }, 150 * Math.pow(2, this._fetchUserPaymentMethodsRetryCount));
        }
        else {
          console.log(`Failed to fetch user payment methods after ${this._fetchUserPaymentMethodsRetryCount} retries`);
          this.userPaymentMethods$.next([]);
          this.activeUserPaymentMethods$.next([]);
          this.archivedUserPaymentMethods$.next([]);
          this.stateUserPaymentMethods$.next(FetchState.ERROR);
        }
      }
    })
  }

  activeUserPaymentMethods$: BehaviorSubject<Array<UserPaymentMethod>> = new BehaviorSubject([]);
  archivedUserPaymentMethods$: BehaviorSubject<Array<UserPaymentMethod>> = new BehaviorSubject([]);

  async saveLinkResult(linkResult, userId: string): Promise<UserPaymentMethod> {
    if (!!linkResult && !!linkResult[0]) {
      return this._saveLinkResult(linkResult[0], userId);
    }
    return Promise.reject(false);
  }

  private _saveLinkResultPromise: Promise<UserPaymentMethod>;
  stateLinkCard$: BehaviorSubject<FetchState> = new BehaviorSubject(FetchState.NONE);
  private _saveLinkResult(linkResult, userId: string): Promise<UserPaymentMethod> {
    if (!!userId && userId.length > 0) {
      if (this.stateLinkCard$.getValue() === FetchState.LOADING) {
        return this._saveLinkResultPromise;
      }
      this.stateLinkCard$.next(FetchState.LOADING);

      this._saveLinkResultPromise = new Promise<UserPaymentMethod>((resolve, reject) => {
        this.apollo.mutate({
          mutation: AddFidelUserPaymentMethodMutation,
          variables: {
            input: {
              fidelId: linkResult.id,
              programId: this.programId,
              created: linkResult.created,
              scheme: linkResult.scheme,
              live: linkResult.live,
              firstNumbers: linkResult.firstNumbers,
              lastNumbers: linkResult.lastNumbers,
              expDate: linkResult.expDate,
              expMonth: linkResult.expMonth,
              expYear: linkResult.expYear,
              countryCode: linkResult.countryCode,
              accountId: linkResult.accountId,
              userId: userId
            }
          }
        }).subscribe({
          next: ({ data, loading }) => {
            let result: UserPaymentMethod = null;

            if (data) {
              if (data['addFidelUserPaymentMethod']) {
                // this.cardAddedResult = data['addFidelUserPaymentMethod'];
                result = UserPaymentMethod.parseResponse(data['addFidelUserPaymentMethod']);
                this.cardAddedResult$.next(result);

                // If this card is already in the list, then check to see if the archived date needs to be cleared
                const existingCard = this.userPaymentMethods$.value.find((nextCard) => {
                  return nextCard.id === result.id;
                });
                if (existingCard) {
                  existingCard.archivedDate = null;
                  result = existingCard;
                }
                else {
                  this.userPaymentMethods$.next([result, ...this.userPaymentMethods$.value]);
                  if (!result.archivedDate || result.archivedDate.getTime() <= 0) {
                    this.activeUserPaymentMethods$.next([result, ...this.activeUserPaymentMethods$.value]);
                  }
                  else {
                    this.archivedUserPaymentMethods$.next([result, ...this.archivedUserPaymentMethods$.value]);
                  }
                }
              }
              else {
                // this.cardAddedResult = null;
                this.cardAddedResult$.error("no result in data");
              }
            }
            else {
              // this.cardAddedResult = null;
              this.cardAddedResult$.error("no result");
            }

            this.stateLinkCard$.next(FetchState.LOADED_ALL);
            resolve(result);
          },
          error: (error) => {
            console.log("Error linking card", error);
            this.stateLinkCard$.next(FetchState.ERROR);
          }
        });
      });

      return this._saveLinkResultPromise;
    }
    else {
      this.cardAddedResult$.error("invalid user");
      return Promise.reject(false);
    }
  }

  async removeUserPaymentMethod(id: string): Promise<boolean> {
    if (!!id && id.length > 0) {
      return this._removeUserPaymentMethod(id);
    }
    return false;
  }

  stateRemoveUserPaymentMethod: FetchState = FetchState.GOOD;
  private _removeUserPaymentMethod(id: string): Promise<boolean> {
    if (!id || id.length === 0) {
      return Promise.resolve(false);
    }
    if (this.stateRemoveUserPaymentMethod === FetchState.LOADING) {
      return Promise.resolve(false);
    }

    this.stateRemoveUserPaymentMethod = FetchState.LOADING;

    return new Promise<boolean>((resolve, reject) => {
      this.apollo.mutate({
        mutation: RemoveUserPaymentMethodMutation,
        variables: {
          id: id
        }
      }).subscribe({
        next: ({ data, loading }) => {
          const result = (data ? data['removeUserPaymentMethod'] : false) ?? false;
          if (result) {
            for(const userPaymentMethod of this.userPaymentMethods$.value ?? []) {
              if (userPaymentMethod.id === id) {
                userPaymentMethod.archivedDate = new Date();
              }
            }
          }

          const activeResults = (this.userPaymentMethods$.value || []).filter((userPaymentMethod) => !userPaymentMethod.archivedDate || userPaymentMethod.archivedDate.getTime() <= 0);
          this.activeUserPaymentMethods$.next(activeResults);

          const archivedResults = (this.userPaymentMethods$.value || []).filter((userPaymentMethod) => !!userPaymentMethod.archivedDate && userPaymentMethod.archivedDate.getTime() > 0);
          this.archivedUserPaymentMethods$.next(archivedResults);

          this.stateRemoveUserPaymentMethod = FetchState.LOADED_ALL;
          resolve(result);
        },
        error: (error) => {
          console.log("Error removing user payment method", error);
          this.stateRemoveUserPaymentMethod = FetchState.ERROR;
          reject(error);
        }
      });
    });
  }
}
