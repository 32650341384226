<div class="w-full flex relative">
  <div class="relative w-full">
    <input type="text" [ngModel]="searchTerms | async" (ngModelChange)="search($event)"
           [placeholder]="placeholder" class="border rounded-lg px-3 py-2 w-full pl-3 pr-8">

    <fa-icon *ngIf="(searchTerms | async)?.length" [icon]="faTimesCircle"
             class="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer text-grey-400"
             (click)="clearSearch()"></fa-icon>
  </div>

  <div class="absolute mt-[50px] w-full bg-white border border-gray-300 rounded-lg" *ngIf="searchResults.length > 0">
    <div *ngFor="let entity of searchResults" (click)="select(entity)" class="cursor-pointer hover:bg-gray-100 p-2">
      {{ entity.name }}
    </div>
  </div>
</div>
