import { gql } from "apollo-angular";
import { UserInviteResponseDetails } from "../fragments/invite.fragment.graphql";



export const AcceptInviteMutation = gql`
${UserInviteResponseDetails}
mutation AcceptInvite($token: String!) {
  acceptInvite(token: $token) {
    ...UserInviteResponseDetails
  }
}
`;

export const RemoveInviteMutation = gql`
mutation RemoveInvite($id: String!) {
  removeInvite(id: $id)
}
`;


