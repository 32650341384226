<div>
  <div class="relative">
    <input type="text" [ngModel]="searchTerms | async" (ngModelChange)="search($event)"
           [placeholder]="placeholder" class="border rounded-lg px-3 py-2 w-full pl-3 pr-8">

    <fa-icon *ngIf="(searchTerms | async)?.length" [icon]="faTimesCircle"
             class="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer text-grey-400"
             (click)="clearSearch()"></fa-icon>
  </div>

  <ul class="absolute mt-2 w-full bg-white border border-gray-300 rounded-lg" *ngIf="userSearchResults.length > 0">
    <li *ngFor="let user of userSearchResults" (click)="selectUser(user)" class="cursor-pointer hover:bg-gray-100 p-2 flex flex-row justify-start items-center">
      <div class="h-9 w-9 flex justify-center items-center corner-25 overflow-hidden relative mr-2">
        <div class="absolute inset-0 flex justify-center items-center corner-25 border border-zinc-900">
          <fa-icon class="fa-lg fa-regular" [icon]="FaUser" [styles]="{'color': 'zinc'}"></fa-icon>
        </div>
        <img class="absolute inset-0 w-full h-full object-cover" *ngIf="!!user?.userImage?.imageUrl"
          [src]="user?.userImage?.imageUrl">
      </div>
      <span *ngIf="!!user.fullName && !!user.email">{{ user.fullName }} ({{ user.email }})</span>
      <span *ngIf="!!user.fullName && !user.email">{{ user.fullName }}</span>
      <span *ngIf="!user.fullName && !!user.email">{{ user.email }}</span>
    </li>
  </ul>
</div>
