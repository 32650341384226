import { gql } from "apollo-angular";
import { MerchantDetails } from "../fragments/merchant.fragment.graphql";


export const MerchantQuery = gql`
${MerchantDetails}
query Merchant($venueId: String!) {
  merchant(venueId: $venueId) {
    ...MerchantDetails
  }
}
`;
