<div
  class="md:px-4 w-full transition-all transform relative bg-cover bg-bottom bg-no-repeat flex flex-col gap-0 items-center"

  style="background-image: url('/assets/images/heading-treat.jpg');"
  [ngClass]="{'header-expanded': isExpanded, 'header-collapsed': !isExpanded}">
  <div
    class="bg-white transition-opacity w-full h-full absolute inset-0 pointer-events-none"
    [ngClass]="{'cover-collapsed': !isExpanded, 'cover-expanded': isExpanded}"
    >
  </div>
  <div class="border-gray-200 drop-shadow-none md:drop-shadow-sm pt-6 pb-2 w-full bg-white md:bg-transparent px-4 md:px-0 z-40">
    <div class="container flex flex-wrap items-center justify-between mx-auto max-w-8xl">
      <div class="flex flex-row items-center justify-start">
        <a [routerLink]="'/'" class="flex items-center">
          <img [src]="getLogoImgUrl()" class="w-[150px] h-[41.29px] hidden md:block" />
          <img [src]="getLogoImgUrl(true)" class="w-[150px] h-[41.29px] md:hidden" />
        </a>
      </div>

      <!-- <app-header-slider class="hidden md:flex flex-grow justify-center items-center"></app-header-slider> -->

      <app-account-widget></app-account-widget>
    </div>
  </div>

  <div
    class="bg-white md:bg-transparent absolute md:relative bottom-0 inset-x-0 py-2 md:py-0 shadow-bottom px-4 md:px-0 w-auto md:w-full flex justify-center items-center">
    <div class=" w-full max-w-[auto] flex justify-center items-center md:max-w-[411px]">
      <app-search class="w-full "></app-search>
    </div>
  </div>

  <div *ngIf="isExpanded" class="px-4 md:px-0 w-full flex flex-col gap-3 md:gap-6 mt-5 2xs:mt-6 xs:mt-10 max-padding max-w-8xl">
    <div class="w-full text-white text-3xl xs:text-4xl md:text-5xl font-bold">Get paid to visit your favorite places</div>
    <div class="hidden sm:flex w-[85%] md:w-[50%] text-white text-2xl font-normal">Connect Directly with you favorite
      local restaurants, bars, breweries and more!</div>
    <button class="hidden md:block button-patron-pink-borderless max-w-[225px] mb-12" (click)="goToHowItWorks()">How it
      works</button>
    <button class="block md:hidden button-patron-pink-borderless max-w-[225px] mb-8" (click)="toggleHowItWorks()">How it
      works</button>
  </div>

</div>

