import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { VenueOffer } from '../model/venue_offer.model';

@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.css']
})
export class PromosComponent implements OnInit {
  // @Input() parentForm: FormGroup;
  @Input() venueOffer: VenueOffer;

  addPromoOpen: boolean = false;

  options: string[] = ["Facebook", "Twitter", "Email", "Table Tent", "Four Wall", "Instagram", "LinkedIn", "YouTube", "None", "Unknown"];

  constructor(
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    // Populate subs array
    // this.venueOffer.promotions.forEach(promo => {
    //   const promoGroup = this.createPromoGroup(promo);
    //   this.promosArray.push(promoGroup);
    // });
  }

  // get promosArray() {
  //   return (this.parentForm.get('promos') as FormArray);
  // }

  // createPromoGroup(promo: IPromotion): FormGroup {
  //   return this.fb.group({
  //     id: [promo.id, Validators.required],
  //     medium: [promo.medium, Validators.required],
  //     // description: [promo.description],
  //     disabledDate: [promo.disabledDate]
  //   });
  // }

  // addPromo() {
  //   const promoGroup = this.createPromoGroup(new VenuePromotion());
  //   this.promosArray.push(promoGroup);
  // }

  // removePromo(index: number) {
  //   this.promosArray.removeAt(index);
  // }


}
