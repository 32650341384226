import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VenueOffersService } from '../venue-offers.service';
import { FetchState } from '../app.module';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { OfferReturnType, VenueOffer } from '../model/venue_offer.model';
import { BehaviorSubject } from 'rxjs';
import { VenueService } from '../venue.service';
import { S3Service } from '../s3.service';
import { Merchant, Venue } from '../model/venue.model';
import { faClose, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BaseImage } from '../model/image.model';
import { dateToUtcDateString } from '../utils/enums/date.utils';

@Component({
  selector: 'app-venue-offer-detail-wrapper',
  templateUrl: './venue-offer-detail-wrapper.component.html',
  styleUrls: ['./venue-offer-detail-wrapper.component.css']
})
export class VenueOfferDetailWrapperComponent {

  private _venue: Venue;
  @Input()
  set venue(value: Venue) {
    this._venue = value;
    if (!!this.offerClone && !this.offerClone.venueId) {
      this.offerClone.venueId = value?.id;
    }
    this.showDeleteConfirmation = false;
  }
  get venue(): Venue {
    return this._venue;
  }

  @Input() merchant: Merchant;

  private _offer: VenueOffer;
  @Input()
  set offer(value: VenueOffer) {
    this._offer = value;
    if (!value) {
      this.offerClone = new VenueOffer();
      this.offerClone.offerImage = new BaseImage();
      this.offerClone.offerImage.type = 'banner';
      this.offerClone.venueId = this.venue?.id;
    }
    else {
      this.offerClone = value.clone();
    }
    this.init(this.offerClone);
  }
  get offer(): VenueOffer {
    return this._offer;
  }

  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  // venueId: string;
  // venueOfferId: string;
  FetchState = FetchState;

  faClose = faClose;
  faTrash = faTrash;

  // offer: VenueOffer;
  offerClone: VenueOffer;
  offerForm: FormGroup;

  constructor(
    protected readonly venueOfferService: VenueOffersService,
    protected readonly venueService: VenueService,
    private readonly s3Service: S3Service,
    private fb: FormBuilder,
  ) {
    const newOffer = new VenueOffer();
    newOffer.offerImage = new BaseImage();
    newOffer.offerImage.type = 'banner';
    this.init(newOffer);
  }

  init(offer?: VenueOffer) {
    this.showDeleteConfirmation = false;
    this.initForm(offer);
  }

  initForm(offer?: VenueOffer) {
    this.offerForm = this.fb.group({
      id: [offer?.id],
      offerImage: [offer?.offerImage],
      offerImageFile: [null, [this.imageValidator()]],
      offerImageFilePreview: [null],
      isVenueDefault: [!!offer?.isVenueDefault ?? false],
    });

    const detailsGroup = this.fb.group({
      id: [offer?.id],
      name: [offer?.name, Validators.required],
      // internalName: [offer.internalName, Validators.required],
      internalName: [offer?.internalName],
      description: [offer?.description],
      finePrint: [offer?.finePrint],
      // promos: this.fb.array(this.venueOffer.promotions)
    });

    this.offerForm.addControl('detailsGroup', detailsGroup);

    const availableGroup: FormGroup = this.fb.group({
      alwaysClaimable: [!offer?.claimStartDate && !offer.claimEndDate],
      claimStartDate: [dateToUtcDateString(offer?.claimStartDate)],
      claimEndDate: [dateToUtcDateString(offer?.claimEndDate)],
      alwaysRedeemable: [!offer?.redeemStartDate && !offer?.redeemEndDate],
      redeemStartDate: [dateToUtcDateString(offer?.redeemStartDate)],
      redeemEndDate: [dateToUtcDateString(offer?.redeemEndDate)],
      isAllDays: [offer?.daysOfTheWeek?.length === 7 || offer?.daysOfTheWeek?.length === 0],
      daysOfTheWeek: [offer?.daysOfTheWeek],
      startTimeOfDay: [offer?.startTimeOfDay],
      endTimeOfDay: [offer?.endTimeOfDay],
      disabledDate: [dateToUtcDateString(offer?.disabledDate ?? null)],
      archivedDate: [dateToUtcDateString(offer?.archivedDate ?? null)],
    });

    this.offerForm.addControl('availableGroup', availableGroup);

    const incentiveGroup: FormGroup = this.fb.group({
      returnType: [offer?.returnType ?? OfferReturnType.Percentage],
      amount: [(offer?.intAmount ?? 0) / 100.0],
      percentage: [(offer?.intPercentage ?? 0) / 100.0],
      minSpend: [(offer?.intMinSpend ?? 0) / 100.0],
      maxReward: [(offer?.intMaxReward ?? 0) / 100.0],
      isCharity: [!!offer?.charity],
      charity: [offer?.charity],
      // charityId: [offer.charity?.id],
      // isPercentage: boolean = true;
    });
    incentiveGroup.addValidators([this.amountValidator(), this.charityValidator(), this.venueValidator()]);

    this.offerForm.addControl('incentiveGroup', incentiveGroup);
  }

  public imageValidator(): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      const selectedFile = control?.value;
      const currentImage = this.offerForm?.get('offerImage')?.value;
      if (!!currentImage?.url || !!selectedFile) {
        return null;
      }

      return { imageRequired: true };
    };
  }

  public amountValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const returnType = group.get('returnType')?.value;

      if (returnType === OfferReturnType.Amount) {
        const amount = group.get('amount')?.value ?? 0;
        if (amount <= 0) {
          return { amountRequired: true };
        }
      }
      else if (returnType === OfferReturnType.Percentage) {
        const intPercentage = group.get('percentage')?.value ?? 0;
        if (intPercentage <= 0 || intPercentage > 100) {
          return { amountRequired: true };
        }
      }

      const minSpend = group.get('minSpend')?.value ?? 0;
      if (minSpend < 0) {
        return { minSpendRequired: true };
      }

      const maxReward = group.get('maxReward')?.value ?? 0;
      if (maxReward < 0) {
        return { maxRewardRequired: true };
      }

      return null;
    };
  }

  public charityValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const isCharity = group.get('isCharity')?.value;
      if (isCharity) {
        const charity = group.get('charity')?.value;
        if (charity) {
          return null;
        }
      }
      else {
        return null;
      }

      return { charityRequired: true };
    };
  }

  public venueValidator(): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      const venueId = this.venue?.id ?? null;
      if (!venueId) {
        return { venueRequired: true };
      }

      if (!this.venue?.achAccount) {
        return { bankAccountRequired: true };
      }

      if (!this.merchant?.isActive) {
        return { merchantActiveRequired: true };
      }

      return null;
    };
  }

  submitState: FetchState = FetchState.NONE;
  async onSubmit() {
    if (this.offerForm.valid) {
      VenueOffer.parseForm(this.offerForm, this.offerClone);

      // If venueId is not set on offerClone, then get it from the venue
      if (!this.offerClone.venueId) {
        this.offerClone.venueId = this.venue?.id;
      }

      console.log(`onSubmit VenueOffer`);
      console.dir(this.offerClone);

      this.submitState = FetchState.LOADING;

      // Step 1: Submit the offer
      let upsertOfferResult: VenueOffer;
      try {
        upsertOfferResult = await this.venueOfferService.upsertOffer(this.offerClone);

        if (!upsertOfferResult) {
          this.submitState = FetchState.ERROR;
          return;
        }

        // this.offer.charity = this.offerClone.charity;
        Object.keys(this.offerClone).forEach((key) => {
          this.offer[key] = this.offerClone[key];
        });
        // VenueOffer.parseResponse(this.offerClone, this.offer);
        // Offer has been upserted, now go back to the list of offers
        // this.router.navigate([`/venue/${this.venueId}/offers`]);
      }
      catch(error) {
        this.submitState = FetchState.ERROR;
        console.log(`error upserting offer`, error);
        return;
      }

      // Step 2: Fetch Upload URL
      const selectedFile = this.offerForm.get('offerImageFile')?.value;
      const updatedOfferImage = upsertOfferResult?.offerImage ?? this.offerClone?.offerImage;
      let originalOfferImage = this.offer.offerImage;
      if (!originalOfferImage && !!updatedOfferImage) {
        originalOfferImage = updatedOfferImage;
        this.offer.offerImage = updatedOfferImage;
      }
      else if (!originalOfferImage.id && !!updatedOfferImage?.id) {
        originalOfferImage.id = updatedOfferImage.id;
      }

      if (selectedFile && !!updatedOfferImage?.id) {
        try {
          const uploadUrl = await this.venueOfferService.fetchOfferImageUploadUrl(updatedOfferImage.id, selectedFile.type);

          // Step 3: Upload File to S3
          const uploadResult = await this.s3Service.uploadFileToS3(uploadUrl, selectedFile);
          console.log(`uploadResult`, uploadResult);

          // Update the offer image url
          this.offerClone.offerImage.url = await this.venueOfferService.fetchOfferImageDownloadUrl(updatedOfferImage.id, true);
          this.offer.offerImage.url = this.offerClone.offerImage.url;
          this.offer.offerImage.preview = this.offerForm.get('offerImageFile' + 'Preview')?.value;
          upsertOfferResult.offerImage.url = this.offerClone.offerImage.url;
          upsertOfferResult.offerImage.preview = this.offerForm.get('offerImageFile' + 'Preview')?.value;
        }
        catch(error) {
          console.log(`error uploading file to S3`, error);
        }
      }

      this.submitState = FetchState.LOADED_ALL;
      this.handleClose();

    }
    else {
      console.log(`form invalid`);
    }
  }

  handleClose() {
    this.showDeleteConfirmation = false;
    this.close.emit();
  }

  showDeleteConfirmation: boolean = false;
  async onDelete() {
    this.showDeleteConfirmation = true;
  }

  async onDeleteCancel() {
    this.showDeleteConfirmation = false;
  }

  async onDeleteConfirm() {
    let result = await this.venueOfferService.deleteOffer(this.offerClone?.id)
    if (result) {
      console.log(`deleteOffer result`, result);
      this.handleClose();
    }
    else {
      console.log(`error deleting offer`);
      this.submitState = FetchState.ERROR;
    }
  }

  /**
   * TODO:
  submitForm(): void {
    forkJoin([
      this.uploadFileToS3(),
      this.submitGraphQLMutation(),
    ]).subscribe(([s3Response, gqlResponse]) => {
      // Do something after both are successful
    });
  }

  uploadFileToS3() {
    // Assuming you have a method to get the S3 upload URL
    return this.apollo.query({
      query: gql`query GetUploadUrl { uploadUrl }`,
    }).pipe(
      switchMap(({ data }) => {
        const uploadUrl = data.uploadUrl;
        return this.http.put(uploadUrl, this.selectedFile, {
          headers: { 'Content-Type': this.selectedFile.type }
        });
      })
    );
  }

  submitGraphQLMutation() {
    // Define your GraphQL mutation and form variables
    const mutation = gql`
      mutation UpdateObject($input: UpdateObjectInput) {
        updateObject(input: $input) {
          id
          // other fields
        }
      }
    `;

    const variables = {
      input: this.form.value
    };

    return this.apollo.mutate({
      mutation,
      variables
    });
  }
    */

  onCancel() {
    this.offerForm.patchValue(this.offer);
    this.handleClose();
  }
  check() {
    console.log(this.offerForm);

    // Find out which controls are invalid
    const invalid = [];
    const controls = this.offerForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
            console.log(`invalid: ${name}`);
        }
    }
  }
}
