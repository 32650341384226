import { gql } from "apollo-angular";
import { VenueDetails } from "../fragments/venue-details.fragment.graphql";
import { VenueOfferDetails } from "../fragments/venue_offers.fragment.graphql";
import { CharityDetails } from "../fragments/charity.fragment.graphql";

export const VenueQuery = gql`
${VenueDetails}
  query VenueQuery($id: String!) {
        venue(id: $id) {
            ...VenueDetails
        }
    }
    ${VenueDetails}
`;

export const FetchOfferImageUploadUrl = gql`
  query FetchOfferImageUploadUrl($id: String!, $contentType: String!) {
    fetchOfferImageUploadUrl(id: $id, contentType: $contentType)
  }
`;

export const FetchOfferImageDownloadUrl = gql`
  query FetchOfferImageDownloadUrl($id: String!, $forceRefresh: Boolean) {
    fetchOfferImageDownloadUrl(id: $id, forceRefresh: $forceRefresh)
  }
`;

export const FetchVenueImageUploadUrl = gql`
  query FetchVenueImageUploadUrl($id: String!, $contentType: String!) {
    fetchVenueImageUploadUrl(id: $id, contentType: $contentType)
  }
`;

export const FetchVenueImageDownloadUrl = gql`
  query FetchVenueImageDownloadUrl($id: String!, $forceRefresh: Boolean) {
    fetchVenueImageDownloadUrl(id: $id, forceRefresh: $forceRefresh)
  }
`;

export const VenuesQuery = gql`
    ${VenueDetails}
  query VenuesQuery($operatorId: String, $search: [SearchInput!]! = [], $sort: PageAndSortInput) {
        venues(operatorId: $operatorId, search: $search, sort: $sort) {
            ...VenueDetails
        }
    }
`

export const VenueOffersQuery = gql`
  ${VenueOfferDetails}
  ${CharityDetails}
query VenueOffers($id: String!, $search: [SearchInput!]! = [], $sort: PageAndSortInput) {
    offers(id: $id, search: $search, sort: $sort) {
      __typename
      ...VenueOfferDetails
    }
  }
`;

