<div class="sm:pt-2 pt-0 pb-10 mt-[0px] sm:mt-0 w-full" *ngIf="offerClaim">
    <div class="mt-[25px] sm:mt-[25px] mx-auto flex flex-col items-start bg-grey-200 border-1 rounded-md xs:w-[240px] sm:w-[550px] w-full lg:w-[650px] xl:w-[750px] py-3">
        <p class="text-2xl font-bold antialiased text-patron-pink ml-3">{{ 'OFFER_CLAIMED' | translate }}</p>
        <div
            class="flex flex-col sm:flex-row justify-start items-start sm:justify-center sm:items-center px-4 sm:px-[50px] mt-4">
            <div>
                <p class="font-400 w-full sm:w-[60%] text-[14px] text-left sm:text-center" [innerHTML]="offerClaimedMessage() | async"></p>
            </div>
            <div *ngIf="hasUserPaymentMethods" class="flex flex-row sm:flex-col items-center mt-4">
                <p class="text-[12px] sm:text-[14px] text-left sm:text-center w-full sm:w-[200px]">{{ 'PAY_WITH_LINKED_CARDS' | translate }}: <span class="font-bold">{{userPaymentsLastDigitsDisplay}}</span></p>
            </div>
            <div *ngIf="!hasUserPaymentMethods" class="flex flex-row sm:flex-col items-center mt-4 w-full">
              <button class="hover:border-patron-pink button-patron-pink w-full" (click)="showCreditCards()">{{ 'LINK_CARD' | translate }}</button>
            </div>
        </div>
    </div>

    <div
        class="mt-[25px] sm:mt-[25px] mx-auto flex flex-col items-start border-1 rounded-md xs:w-[240px] sm:w-[550px] w-full lg:w-[650px] xl:w-[750px] py-3">
        <p class="text-xl font-bold antialiased">{{ 'YOUR_OFFER' | translate }}</p>
        <div class="px-7 w-full mt-5">
            <div class="text-patron-pink font-bold antialiased text-xl">
                <div *ngIf="!isCharityOffer">
                    <!-- <p class="text-patron-pink font-bold antialiased">{{handlePatronIncentive()}}</p> -->
                    <p *ngIf="!!this.intReturnPercentage" class="text-patron-pink font-bold antialiased">{{(this.intReturnPercentage / 100.0)}}% {{ 'BACK' | translate }}</p>
                    <p *ngIf="!!this.intReturnAmount" class="text-patron-pink font-bold antialiased">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'BACK' | translate }}</p>
                </div>
                <div *ngIf="isCharityOffer">
                    <p *ngIf="!!this.intReturnPercentage" class="text-patron-pink font-bold antialiased">{{(this.intReturnPercentage / 100.0)}}% {{ 'DONATION' | translate }}</p>
                    <p *ngIf="!!this.intReturnAmount" class="text-patron-pink font-bold antialiased">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'DONATION' | translate }}</p>
                    <!-- <p class="text-patron-pink font-bold antialiased">{{handleCharityIncentive()}}</p> -->
                </div>
            </div>
            <p class="mt-3" [innerHTML]="handleOfferClaimedInfo() | async"></p>
        </div>
    </div>
</div>

<!-- Offer NOT Claimed -->
<div class="sm:pt-2 pt-0 pb-10 mt-[0px] sm:mt-0 w-full -mt-[25px] sm:mt-0" *ngIf="!offerClaim">
    <div
        class="mt-[25px] sm:mt-[25px] mx-auto flex flex-col items-start border-1 rounded-md xs:w-[240px] sm:w-[550px] w-full lg:w-[650px] xl:w-[750px] py-3">
        <p class="text-xl font-bold antialiased">{{ 'YOUR_OFFER' | translate }}</p>
        <div
            class="mt-[25px] px-5 sm:mt-[25px] mx-auto flex flex-col items-start border-1 rounded-md xs:w-[240px] sm:w-[550px] w-full lg:w-[650px] xl:w-[750px] py-3 bg-grey-200">
            <div class="text-patron-pink font-bold antialiased text-xl">
                <div *ngIf="!isCharityOffer">
                    <!-- <p class="text-patron-pink font-bold antialiased">{{handlePatronIncentive()}}</p> -->
                    <p *ngIf="!!this.intReturnPercentage" class="text-patron-pink font-bold antialiased">{{(this.intReturnPercentage / 100.0)}}% {{ 'BACK' | translate }}</p>
                    <p *ngIf="!!this.intReturnAmount" class="text-patron-pink font-bold antialiased">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'BACK' | translate }}</p>
                </div>
                <div *ngIf="isCharityOffer">
                    <!-- <p class="text-patron-pink font-bold antialiased">{{handleCharityIncentive()}}</p> -->
                    <p *ngIf="!!this.intReturnPercentage" class="text-patron-pink font-bold antialiased">{{(this.intReturnPercentage / 100.0)}}% {{ 'DONATION' | translate }}</p>
                    <p *ngIf="!!this.intReturnAmount" class="text-patron-pink font-bold antialiased">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'DONATION' | translate }}</p>
                </div>
            </div>
            <p class="mt-3">{{handleOfferClaimedInfo() | async}}</p>
        </div>
    </div>
</div>
