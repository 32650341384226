
<!-- OFFERS -->
<div class="flex flex-col justify-start w-full bg-gradient-to-t from-stone-100 to-stone-50 pt-8 max-padding pb-8">
  <div class="flex flex-row justify-between items-end w-full">
    <span class="h-full text-black text-opacity-60 text-2xl font-medium leading-relaxed">Nearby Offers</span>
    <span class="h-full text-center text-patron-pink text-sm font-medium leading-tight cursor-pointer" (click)="goToOffers()">
      See All</span>
  </div>
  <div id="offersLoading" *ngIf="offersServiceState === FetchState.LOADING && offers?.length === 0"
    class="flex justify-center items-center min-h-[225px]">
    <div class="loader-wrapper flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
    </div>
  </div>
  <!-- <div class="flex flex-col justify-center items-center w-full"> -->

    <ngx-slick-carousel class="carousel" [config]="slideConfig">
      <div *ngFor="let offerData of offers" ngxSlickItem class="slide mr-2">
        <app-offer-tile2 [offerData]="offerData" (click)="goToOffer(offerData.offer)"></app-offer-tile2>
      </div>
    </ngx-slick-carousel>

    <!-- <div *ngIf="offers?.length !== 0"
      class="mt-2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 mx-auto max-w-[1427px] w-full">
      <app-offer-tile2 *ngFor="let offerData of offers | slice:0:5" [offerData]="offerData"
        (click)="goToOffer(offerData.offer)"></app-offer-tile2>
    </div> -->

    <!-- <div style="margin-top: 50px; height: 50px;" class="flex justify-center mb-[75px]">
            <button routerLink="/offers" class="w-full button-patron-pink hover:border-patron-pink"
                style="width: 220px; height: 50px; margin-left: 10px; border-color: #ED2D92; border-radius: 25px;">
                {{ 'VIEW_MORE' | translate }}
            </button>
        </div> -->
  <!-- </div> -->
</div>

<!-- BECOME A PARTNER -->
<!-- <div class="flex flex-row justify-center">
    <div
        class="flex flex-col items-center absolute w-[85%] md:w-[600px] lg:w-[700px] xl:w-[900px] z-10 mt-[75px] xl:mt-[225px]">
        <p class="text-[36px] xl:text-[48px] text-white font-bold antialiased font-extrabold antialiased">{{ 'BECOME_A_PARTNER_TITLE' | translate }}</p>
        <p class="text-center text-[15px] xl:text-lg text-white mt-7 font-normal">{{ 'BECOME_A_PARTNER_MESSAGE' | translate }}</p>
        <div style="height: 50px;" class="flex justify-center mb-[75px] mt-10">
            <button class="w-full button-patron-pink hover:border-patron-pink"
                style="width: 225px; height: 50px; border-color: #ED2D92; border-radius: 25px;">
                {{ 'LEARN_MORE' | translate }}
            </button>
        </div>
    </div>
    <img src="../../../assets/images/LandingPageImage2.png" alt="Landing-Page-Img-2"
        class="object-cover brightness-50 h-[400px] w-full xl:h-[600px]">
</div> -->

<!-- Fundraising -->
<div class="bg-gray-200 p-6 md:p-12 flex flex-col justify-center items-center">
  <!-- Example for a full-width row with background color and padding -->
  <div class="container max-w-8xl max-padding"> <!-- Centered container with max width -->

    <!-- Heading Section -->
    <div class="mb-8 text-left"> <!-- Margin bottom and text centering -->
      <h3 class="bold-title"> <!-- Responsive font size and font weight -->
        The easiest way to raise funds for your local school, organization or sports team
      </h3>
    </div>

    <!-- Two-column layout for larger screens -->
    <div class="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4">

      <!-- Text Content Column -->
      <div class="md:w-1/2">
        <h4 class="bold-subtitle mb-3">Fundraising Made Easy</h4>
        <p class="mb-3 paragraph">
          If your local school, club or sports team is holding fundraisers, we’ve found a better way to make them easy.
        </p>
        <p class="paragraph">
          GetZeals makes fundraising events with your local business partners simple and frictionless. From marketing of
          the event to the revenue split from the business and your organization managed with no paperwork. Download the
          app and register as a fundraiser to bring GetZeals to your next event.
        </p>
        <button
          class="button-patron-pink-borderless mt-6 inline-block bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
          (click)="goToCharityLanding()">Contact Us</button>
      </div>

      <!-- Image Content Column -->
      <div class="md:w-1/2">
        <img class="w-full h-auto" src="/assets/images/fundraising-phone.png">
      </div>
    </div>
  </div>
</div>

<!-- Become a Partner -->
<div
  class="w-full bg-cover bg-center bg-no-repeat h-[400px] relative lg:h-[600px] 2xl:h-[700px] flex flex-col justify-center items-center"
  style="background-image: url('/assets/images/business-landing.jpeg');">
  <div class="bg-black bg-opacity-50 w-full h-full flex justify-center items-center">

    <div class="flex justify-center text-center py-12  max-w-8xl max-padding">

      <div class="w-full md:w-1/2">

        <div class="py-4">
          <h2 class="bold-title text-white">Become a Partner</h2>
        </div>

        <div class="text-white mb-6 paragraph">
          <p>You have a unique experience to offer and you need to reach people who want to experience it. With GetZeals
            you can. We help you find new customers and you give them a great experience to remember.</p>
        </div>

        <div class="text-center mb-4">
          <button
            class="button-patron-pink-borderless bg-blue-600 text-white px-6 py-2 rounded inline-block hover:bg-blue-700 transition-colors"
            (click)="goToBusinessLanding()">Learn More</button>
        </div>

      </div>

    </div>
  </div>
</div>

