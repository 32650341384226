import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NavControlService } from '../nav-control.service';
import { OfferFilter } from 'src/app/offers.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header-slider',
  templateUrl: './header-slider.component.html',
  styleUrls: ['./header-slider.component.css']
})
export class HeaderSliderComponent implements OnInit, OnDestroy {

  private _divs: QueryList<ElementRef>;
  @ViewChildren('zealsDiv, experiencesDiv, myZealsDiv')
  set divs(divs: QueryList<ElementRef>) {
    this._divs = divs;
    if (!!divs && !this.currentDivId) {
      this.setCurrentDivId('zealsDiv');
    }
  }
  get divs(): QueryList<ElementRef> {
    return this._divs;
  }
  @ViewChild('underline') underline: ElementRef;

  isExpanded: boolean = false;

  currentDivId: string = "zealsDiv";


  constructor(
    private readonly navControlService: NavControlService,
    private router: Router
  ) {}

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.router.events.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          // If route is /offers or / then scroll to the zealsDiv
          if (event.url === '/offers' || event.url === '/') {
            this.scrollToDiv('zealsDiv');
          }
          else {
            this.scrollToDiv('otherDiv');
          }
        }
      }
    });

    this.navControlService.isNavHeaderExpanded$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (isExpanded) => {
        setTimeout(() => {
          this.isExpanded = isExpanded;
        }, 1);
      }
    });
  }

  goToOffers() {
    this.scrollToDiv('zealsDiv');
    this.navControlService.goToOffers([OfferFilter.NEAR_ME]);
  }

  scrollToDiv(id: string) {
    this.setCurrentDivId(id);
  }
  private _underlineWidth: number = 0;
  private _underlineLeft: number = 0;
  private setCurrentDivId(id: string) {
    this.currentDivId = id;

    if (!this._divs) {
      return;
    }

    // Set the width of the underline to the width of the div
    const div = this.divs.find((div) => div.nativeElement.id === id);
    if (!!div) {
      const underlineWidth = div.nativeElement.offsetWidth + 20;
      const parentElement = div.nativeElement.parentElement;
      const newLeft = parentElement.offsetLeft + (parentElement.offsetWidth / 2 - underlineWidth / 2 - 1);
      this._underlineWidth = underlineWidth;
      this._underlineLeft = Math.max(0, newLeft);

      this.underline.nativeElement.style.width = `${this._underlineWidth}px`;
      this.underline.nativeElement.style.left = `${this._underlineLeft}px`;
    }
    else {
      this._underlineWidth = 0;
      this._underlineLeft = 0;
      this.underline.nativeElement.style.width = '0px';
      this.underline.nativeElement.style.left = '0px';
    }

  }

}
