
export enum Weeks{
  First = 0b00000001,
  Second = 0b00000010,
  Third = 0b00000100,
  Fourth = 0b00001000,
  Fifth = 0b00010000
}
export const AllWeeks: Array<Weeks> = [Weeks.First, Weeks.Second, Weeks.Third, Weeks.Fourth, Weeks.Fifth];
// export enum WeekSet{
//   All,
//   Odd,
//   Even,
//   Miscellanious
// }


export function parseWeeksOfTheMonthInt(weeksOfTheMonth: number): Array<Weeks> {
  const result: Array<Weeks> = [];
  for(const week of AllWeeks) {
    if (weeksOfTheMonth & week) {
      result.push(week);
    }
  }
  return result;
}

export function weeksOfMonthToInt(weeksOfMonth: Array<Weeks>): number {
  let result: number = 0;
  for(const week of weeksOfMonth) {
    result = result | week;
  }
  return result;
}
