import { gql } from "apollo-angular";



export const FetchUserImageUploadUrl = gql`
  query FetchUserImageUploadUrl($id: String!, $contentType: String!) {
    fetchUserImageUploadUrl(id: $id, contentType: $contentType)
  }
`;

export const FetchUserImageDownloadUrl = gql`
  query FetchUserImageDownloadUrl($id: String!, $forceRefresh: Boolean) {
    fetchUserImageDownloadUrl(id: $id, forceRefresh: $forceRefresh)
  }
`;

