<div class="w-full h-full flex flex-col justify-center items-center">
  <div class="w-full h-full flex flex-col justify-center items-center relative max-w-2xl">

    <div class="w-full flex flex-col justify-start items-center relative mb-4 md:mb-8">
      <div class="text-center text-gray-500 text-xl font-medium leading-relaxed">Cash Back Balance</div>
      <div class="text-center text-zinc-600 text-4xl font-medium leading-relaxed">{{ ((ledger?.intAvailableToTransfer || 0.00) / 100.0) | currency }}</div>
      <div *ngIf="ledgerState !== FetchState.LOADING" class="w-full flex-col justify-center items-center inline-flex gap-2 flex-wrap">
        <!-- Withdraw button -->
        <!-- <div *ngIf="((ledger?.intAvailableToTransfer || 0.00) > 0.0 || true) && issuePayoutState !== FetchState.LOADING" -->
        <div *ngIf="!isMissingBank" class="flex justify-center items-center m-2">
          <div class="flex flex-col justify-center items-center">
            <button class="button-patron-pink" [disabled]="((ledger?.intAvailableToTransfer || 0.00) === 0.0) || issuePayoutState === FetchState.LOADING" (click)="withdrawEarnings()">Transfer To My Bank</button>
          </div>
        </div>
        <div *ngIf="isMissingBank" class="flex flex-col justify-center items-center m-2">
          <div class="flex flex-col justify-center items-center">
            <button class="button-patron-pink" (click)="showLinkAccount()">Link Bank Account</button>
          </div>
          <div class="flex flex-row gap-1">
            <div class="w-6 h-6 p-0.5 justify-center items-center inline-flex">
              <img class="w-5 h-5" src="/assets/icons/warning_pink.svg" />
            </div>
            <div class="w-64 h-7 opacity-60 text-center text-slate-600 text-base font-normal leading-normal">A bank account needed for transfer</div>
          </div>

        </div>

        <!-- Withdraw Result -->
        <div *ngIf="!!issuePayoutResult && issuePayoutState !== FetchState.LOADING" class="flex justify-center items-center">
          <div *ngIf="!issuePayoutResult?.success" class="flex flex-col justify-center items-center">
            <div class="w-full border-red-500 text-red-500 border p-2 flex justify-center items-center rounded-sm text-xs my-4">
              <fa-icon [icon]="ErrorIcon" [styles]="{'color': 'red'}" class="mr-1"></fa-icon>
              <div>There was an error withdrawing your earnings.</div>
              <div>{{ issuePayoutResult?.message }}</div>
            </div>
          </div>
          <div *ngIf="!!issuePayoutResult?.success" class="flex flex-col justify-center items-center bg-white border border-charity-green py-2 px-4 rounded-md">
            <!-- Withdraw Result Details -->
            <div>{{ issuePayoutResult?.message }}</div>
            <div *ngIf="issuePayoutResult?.intSettleAmount">{{ (issuePayoutResult?.intSettleAmount / 100.0) | currency }} withdrawn!</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Total Cash Back Earned -->
    <div class="w-full flex flex-col justify-start items-center relative">
      <div class="w-full text-left opacity-60 text-slate-600 text-xl font-semibold leading-normal">Total Cash Back Earned </div>
      <div class="w-full text-left text-zinc-600 text-4xl font-medium leading-relaxed">{{ ((ledger?.intRewardsBalance || 0.00) / 100.0) | currency }}</div>

      <div class="flex flex-row w-full justify-between items-center">
        <div class="opacity-60 text-slate-600 text-base font-normal leading-normal">{{ userStartedDateDisplay }}</div>
        <div *ngIf="ledger?.intRewardsBalance > 1000" class="flex flex-row justify-center items-center">
          <div class="w-6 h-6 relative">
            <img src="/assets/icons/Fill/star.svg" class="w-full h-full object-contain" />
          </div>
          <div class="text-gray-500 text-base font-normal leading-normal">Top Earner</div>
        </div>
      </div>
    </div>

    <div class="w-full h-px justify-center items-center inline-flex mt-4 mb-2">
      <div class="w-full h-px border border-neutral-300"></div>
    </div>

    <!-- Total Donations Earned -->
    <div class="w-full flex flex-col justify-start items-center relative">
      <div class="w-full text-left opacity-60 text-slate-600 text-xl font-semibold leading-normal">Total Donations Contributed </div>
      <div class="w-full text-left text-zinc-600 text-4xl font-medium leading-relaxed">{{ ((ledger?.intRewardsCharityBalance || 0.00) / 100.0) | currency }}</div>

      <div *ngIf="ledger?.intRewardsCharityBalance > 500" class="flex flex-row w-full justify-between items-center">
        <div class="opacity-60 text-slate-600 text-base font-normal leading-normal">{{ userStartedDateDisplay }}</div>
        <div class="flex flex-row justify-center items-center">
          <div class="w-6 h-6 relative">
            <img src="/assets/icons/Fill/star.svg" class="w-full h-full object-contain" />
          </div>
          <div class="text-gray-500 text-base font-normal leading-normal">Top Contributor</div>
        </div>
      </div>
    </div>

    <div class="w-full h-px justify-center items-center inline-flex mt-4 mb-2">
      <div class="w-full h-px border border-neutral-300"></div>
    </div>

    <!-- Recent Activity -->
    <div class="w-full flex flex-col justify-start items-center relative mb-8">
      <div class="w-full text-left opacity-60 text-slate-600 text-xl font-semibold leading-normal">Recent Activity </div>
      <div *ngIf="creditsAndDebitsState === FetchState.LOADED_ALL && creditsAndDebits?.length === 0" class="w-full text-left text-zinc-600 text-lg font-normal leading-normal">No Earnings Yet </div>

      <div class="flex flex-col w-full justify-start items-center mt-1">
        <div *ngFor="let row of creditsAndDebits" class="w-full flex flex-col">
          <app-earning-row
          [ngClass]="{'cursor-pointer': rewardCanBeSelected(row)}" (click)="selectRewardOffer(row)"
           class="w-full" [creditDebit]="row"></app-earning-row>
          <div class="w-full h-px justify-center items-center inline-flex mt-4 mb-2">
            <div class="w-full h-px border border-neutral-300"></div>
          </div>
        </div>

        <div *ngIf="creditsAndDebitsState === FetchState.GOOD" class="mt-4">
          <button class="button-patron-pink" (click)="loadMore()">Load More</button>
        </div>

        <div *ngIf="creditsAndDebitsState === FetchState.ERROR" class="flex justify-center items-center">
          <div class="flex flex-col justify-center items-center">
            <div class="w-full border-red-500 text-red-500 border p-2 flex justify-center items-center rounded-sm text-xs my-4">
              <fa-icon [icon]="ErrorIcon" [styles]="{'color': 'red'}" class="mr-1"></fa-icon>
              Bummer... there was an error fetching your transactions. Please try again later.
            </div>
          </div>
        </div>

        <div *ngIf="creditsAndDebitsState === FetchState.LOADING"
        class="w-full px-2 py-2.5 xs:px-4 xs:py-3 md:px-6 md:py-4 bg-stone-100 rounded-xl justify-center xs:justify-between items-center inline-flex gap-1"
        >
          <div class="loader-wrapper flex justify-center items-center">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        </div>

      </div>
    </div>

    <!-- <div class="w-full p-5 bg-white rounded-2xl flex-col justify-between items-between inline-flex gap-2 flex-wrap relative">
      <div class="w-full flex-row justify-between items-between inline-flex gap-2 flex-wrap">
        <div class="justify-start items-start inline-flex flex-col">
          <div class="flex-col justify-center items-start inline-flex">
            <div class="resp-subtitle">{{ ((ledger?.intBalance || 0.00) / 100.0) | currency }}</div>
            <div class="justify-start items-center gap-2.5 inline-flex">
              <div class="text-patron-pink resp-label">Present Balance</div>
            </div>
          </div>
          <div class="flex-col justify-start items-start inline-flex">
            <div class="resp-subtitle">{{ ((ledger?.intAvailableToTransfer || 0.00) / 100.0) | currency }}</div>
            <div class="justify-start items-center gap-2.5 inline-flex">
              <div class="text-patron-pink resp-label">Available to Withdraw</div>
            </div>
          </div>
        </div>
        <div class="justify-center items-start inline-flex flex-col">
          <div class="flex-col justify-start items-start inline-flex">
            <div class="resp-subtitle">{{ ((ledger?.intClearedCreditBalance || 0.00) / 100.0) | currency }}</div>
            <div class="justify-start items-center gap-2.5 inline-flex">
              <div class="text-patron-pink resp-label">Lifetime Cash Back</div>
            </div>
          </div>
          <div class="flex-col justify-start items-start inline-flex">
            <div class="resp-subtitle">{{ ((ledger?.intRewardsCharityBalance || 0.00) / 100.0) | currency }}</div>
            <div class="justify-start items-center gap-2.5 inline-flex">
              <div class="text-charity-green resp-label">Lifetime Donations</div>
            </div>
          </div>
        </div>
      </div>

      <!- - Loading - ->
      <div *ngIf="ledgerState === FetchState.LOADING || issuePayoutState === FetchState.LOADING"
        class="flex justify-center items-center absolute inset-0 bg-white bg-opacity-50">
        <div class="loader-wrapper flex justify-center items-center">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      </div>

    </div> -->

    <!-- <div class="w-full h-full flex flex-col justify-start items-center mt-12 gap-2">
      <div *ngIf="creditsAndDebitsState === FetchState.LOADED_ALL && creditsAndDebits?.length === 0"
      class="w-full px-2 py-2.5 xs:px-4 xs:py-3 md:px-6 md:py-4 bg-white rounded-xl justify-center xs:justify-between items-center inline-flex gap-1"
      >
        <div class="h-full justify-center items-center gap-5 flex flex-grow text-lg">
          No Earnings Yet
        </div>
      </div>

      <app-earning-row *ngFor="let row of creditsAndDebits" class="w-full" [creditDebit]="row"></app-earning-row>

      <div *ngIf="creditsAndDebitsState === FetchState.GOOD">
        <button class="button-patron-pink" (click)="loadMore()">Load More</button>
      </div>

      <div *ngIf="ledgerState === FetchState.LOADING || creditsAndDebitsState === FetchState.LOADING"
      class="w-full px-2 py-2.5 xs:px-4 xs:py-3 md:px-6 md:py-4 bg-white rounded-xl justify-center xs:justify-between items-center inline-flex gap-1"
      >
        <div class="loader-wrapper flex justify-center items-center">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      </div>

      <div *ngIf="creditsAndDebitsState === FetchState.ERROR" class="flex justify-center items-center">
        <div class="flex flex-col justify-center items-center">
          <div class="w-full border-red-500 text-red-500 border p-2 flex justify-center items-center rounded-sm text-xs my-4">
            <fa-icon [icon]="ErrorIcon" [styles]="{'color': 'red'}" class="mr-1"></fa-icon>
            There was an error fetching your earnings.
          </div>
        </div>
      </div>

    </div> -->

    <div *ngIf="ledgerState === FetchState.LOADING || issuePayoutState === FetchState.LOADING"
    class="flex justify-center items-center absolute inset-0 bg-stone-100 bg-opacity-50">
    <div class="loader-wrapper flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  </div>

  </div>
</div>

<!-- Overlay -->
<app-modal [isActive]="showSelectRewardOffer" (close)="closeModal()">
  <app-select-reward-offer [reward]="selectedReward" (close)="closeModal()"></app-select-reward-offer>
</app-modal>
