<form [formGroup]="offerForm" (ngSubmit)="onSubmit()" class="p-4 w-full h-full flex flex-col relative">
  <fa-icon [icon]="faClose" class="absolute text-red-500 right-4 top-4 cursor-pointer" (click)="onCancel()"></fa-icon>
  <app-venue-offer-detail [venue]="venue" [parentForm]="offerForm"
    class="w-full h-full flex-grow overflow-y-auto"></app-venue-offer-detail>
  <!-- Delete Confirmation Overlay -->
  <div *ngIf="!!offerClone?.id && showDeleteConfirmation" class="absolute inset-0 bg-black bg-opacity-25 flex justify-center items-center">
    <div class="bg-white rounded-md p-4 flex flex-col justify-center items-center">
      <div class="text-xl font-semibold antialiased mb-4 text-grey-900">Are you sure you want to delete this offer?</div>
      <div class="flex flex-row justify-between items-center w-full">
        <div class="button-red" (click)="onDeleteConfirm()">Yes</div>
        <div class="button-zinc-900" (click)="onDeleteCancel()">No</div>
      </div>
    </div>
  </div>
  <div class="flex flex-row flex-wrap justify-between items-center mt-3 space-x-2">
    <!-- Delete Button -->
    <div *ngIf="!!offerClone && !!offerClone?.id" class="button-white-red mr-12" (click)="onDelete()">Delete</div>
    <!-- Show Error Here -->
    <div *ngIf="submitState === FetchState.ERROR" class="text-red-500">
      Error saving, please try again.
    </div>
    <div class="flex flex-row justify-end items-center mt-3 flex-grow">
      <button [disabled]="!offerForm?.valid" class="button-business-blue"
        [ngClass]="{ 'cursor-not-allowed opacity-50': !offerForm?.valid }" type="submit">Save</button>
    </div>
  </div>

  <div *ngIf="submitState === FetchState.LOADING"
    class="flex justify-center items-center absolute inset-0 bg-black bg-opacity-25">
    <div class="loader-wrapper flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  </div>

</form>
