<!-- [disabled]="offersServiceState !== FetchState.LOADED_ALL && offersServiceState !== FetchState.GOOD && offersServiceState !== FetchState.NO_RESULTS" -->
<!-- <button (click)="selectFilter(filter)"
  class="flex flex-col justify-center items-center text-center m-2 py-2 min-w-[95px]  hover:text-black px-6 antialiased cursor-pointer text-xl md:text-base text-zinc-700 overflow"
  [ngClass]="{ 'border-b-2 border-black !text-black': selectedFilters?.indexOf(filter) >= 0}">
  <fa-icon [icon]="getFilterIcon(filter)" [classes]="['fa-regular']"></fa-icon>
  <span class="text-xs">
    {{ translateFilter(filter) | async }}
  </span>
</button> -->
<button (click)="selectFilter(filter)"
  class="flex flex-col justify-center items-center text-center m-2 py-2 min-w-[95px]  px-6 cursor-pointer overflow !rounded-2xl"
  [disabled]="fetchState === FetchState.LOADING"
  [ngClass]="{
    'button-patron-pink': isFilterSelected(filter),
    'button-white-patron-pink': !isFilterSelected(filter),
  }">
  <span class="text-xs"
  >
    {{ translateFilter(filter) | async }}
  </span>
</button>
