import { Component, Input, OnInit } from '@angular/core';
import { LocationService } from '../../location.service';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PatronOffer } from 'src/app/model/patron-offer.model';

@Component({
  selector: 'app-offer-tile',
  templateUrl: './offer-tile.component.html',
  styleUrls: ['./offer-tile.component.css']
})
export class OfferTileComponent implements OnInit {
  @Input() offer: PatronOffer;
  distance: number | null = null;
  distanceDisplay: string = "";

  location$ = this.locationService.location$;
  error$ = this.locationService.location$.pipe(
    catchError((error) => {
      // Handle errors here
      return throwError(error);
    })
  );

  constructor(
    private router: Router,
    private readonly locationService: LocationService,
    public readonly translate: TranslateService,
  ) {

  }

  ngOnInit(): void {
    this.location$.subscribe({
      next: async (location) => {
        if (location) {
          if (this.offer?.venue?.venueAddresses?.length > 0) {
            const venueAddress = this.offer.venue.venueAddresses[0];
            if (venueAddress.geoCoordinate?.latitude && venueAddress.geoCoordinate?.longitude) {
              console.log(`fetching distance from ${venueAddress.geoCoordinate.latitude},${venueAddress.geoCoordinate.longitude} to ${location.latitude},${location.longitude}`)
              this.distance = await this.locationService.fetchDistance(venueAddress.geoCoordinate, location);

              if (this.distance <= 0) this.distanceDisplay = "0.0 mi";
              else if (this.distance < 10) this.distanceDisplay = `${this.distance.toFixed(2)} mi`;
              else if (this.distance < 25) this.distanceDisplay = `${this.distance.toFixed(1)} mi`;
              else this.distanceDisplay = `${this.distance.toFixed(0)} mi`;
            }
          }
        }
      }
    });
  }

  goToOfferDetails(): void {
    if (!this.offer || !this.offer.id || this.offer.id.length === 0) return;
    this.router.navigate(['/offers/', this.offer.id]);
  }

  get offerImageUrl(): string | ArrayBuffer {
    let result = this.offer?.offerImage?.imageUrl;
    if (!!result) return result;
    return '/assets/images/offer_placeholder.png';
  }

  get isCharityOffer(): boolean {
    return !!this.offer?.charityIncentive && (!this.offer.charityIncentive.disabledDate || this.offer.charityIncentive.disabledDate.getTime() <= 0);
  }

  get rewardedOffer(): boolean {
    return !!this.offer?.claim && this.offer?.claim?.rewards?.length > 0;
  }


}
