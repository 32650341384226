import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { AppService } from 'src/app/app.service';
import { UserOffersService } from 'src/app/services/user-offers.service';

@Component({
  selector: 'app-offer-share',
  templateUrl: './offer-share.component.html',
  styleUrls: ['./offer-share.component.css']
})
export class OfferShareComponent {

  @Output() close = new EventEmitter<void>();

  FaXmark = faXmark;

  constructor(
    private readonly appService: AppService,
    private readonly userOfferService: UserOffersService
  ) {}

  offerQrCode(): string {
    return `${this.appService.baseUrl}/offers/${this.userOfferService.offer?.id}`;
  }

  handleClose() {
    this.close.emit();
  }
}
