import { Injector } from '@angular/core';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthTokenService } from '../auth-token.service';
import { environment } from 'src/environments/environment';
import { LoginState, UserAuthService } from '../user-auth.service';
import { AppInjector } from '../app.injector';

declare var FB: any;


// In your global function
(window as any).onFacebookLogin = () => {
  // Access Angular's injector to get the AuthService instance
  const injector = AppInjector.getInjector();
  const authService = injector.get(FacebookService);
  authService.notifyFbLogin();
}


@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor(
    private readonly userAuthService: UserAuthService,
  ) {
  }

  private statusChangeCallback(response) {
    console.log('statusChangeCallback', response);
  }

  async login() {
    const that = this;
    FB.login((response: any) => {
      console.log('FB login response', response);
      if (response.authResponse) {
          that.getUserDetails();
          console.log('Welcome! Fetching your information.... ');
          FB.api('/me', (response: any) => {
              console.log('Good to see you, ' + response.name + '.');
              // Here you can send the response to your backend for further processing
          });
      } else {
          console.log('User cancelled login or did not fully authorize.');
      }
    }, {scope: 'email'});
  }

  checkLoginStatus() {
    this.userAuthService.state$.next(LoginState.LOGGING_IN);
    FB.getLoginStatus(async (response) => {
        if (response.status === 'connected') {
          // this.getUserDetails();
          // Validate the access token by sending it to the server
          console.log('=== FACEBOOK LOGIN ===');
          let result = await this.handleCredentialResponse(response);
        } else {
          this.userAuthService.state$.next(LoginState.ERROR);
            // The user is not logged in or hasn't authenticated your app
          console.log('=== FACEBOOK SIGN IN ERROR (USER) ===');
          AuthTokenService.instance.handleAuthFailure(response);
        }
    });
  }

  async handleCredentialResponse(response: any) {
    const uri = environment.authRedirectUri;

    try {
      // Get/set the system id from local storage
      const systemId: string = AuthTokenService.instance.getSystemId(true);
      const fetchResponse = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-system-id': systemId
        },
        body: JSON.stringify({access_token: response.authResponse.accessToken, auth_provider: 'facebook'})
      });
      console.log('=== FACEBOOK SIGN IN SUCCESS (BACK-END) ===');

      const body = await fetchResponse.json();
      // If this is a 200 response, then we have a valid token
      if (fetchResponse.status >= 200 && fetchResponse.status < 300) {
        AuthTokenService.instance.handleAuthResponse(body);
      }
      else {
      // If this is a 401 response, then we have an invalid token
        this.userAuthService.state$.next(LoginState.ERROR);
        AuthTokenService.instance.handleAuthFailure(body);
        return;
      }

      // for (const handler of this.signInResultHandlers) {
      //   handler(body, null, null);
      // }
    }
    catch (error) {
      console.log('=== AUTH SIGN IN ERROR (BACK-END) ===');
      console.log(error);
      this.userAuthService.state$.next(LoginState.ERROR);
      AuthTokenService.instance.handleAuthFailure(error);
    }
  }

  getUserDetails() {
    FB.api('/me', { fields: 'id,name,email' }, (response) => {
        console.log('Successful login for: ' + response.name);
        // Here you have the user's data
    });
  }

  private fbLoginSubject = new Subject<void>();

  notifyFbLogin() {
      this.fbLoginSubject.next();
      this.checkLoginStatus();
  }

  getFbLoginObservable(): Observable<any> {
      return this.fbLoginSubject.asObservable();
  }

}
