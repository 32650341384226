<div class="w-full items-center inline-flex gap-1">
  <!-- <div> -->
  <img *ngIf="!!logoImageUrl" class="w-12 h-12 xs:w-14 xs:h-14 rounded-xl object-cover aspect-square" [src]="logoImageUrl" />
  <div *ngIf="!logoImageUrl" class="w-12 h-12 xs:w-14 xs:h-14 rounded-xl object-cover aspect-square flex justify-center items-center">
    <fa-icon *ngIf="isPayout" [icon]="BankIcon" class="object-contain text-4xl"></fa-icon>
  </div>
  <!-- </div> -->
  <div class="flex-grow flex flex-col justify-center items-start ml-2 gap-0">
    <div class="text-zinc-600 text-lg font-semibold leading-normal">{{ venueName }}</div>
    <div *ngIf="!!txDate" class="text-slate-600 text-base font-normal leading-snug">
      <span *ngIf="txDateIsCurrentYear">{{ txDate | date:'MMMM d' }}</span>
      <span *ngIf="!txDateIsCurrentYear">{{ txDate | date:'MMMM d, yyyy' }}</span>
    </div>
    <!-- <div class="text-slate-600 text-base font-extrabold leading-snug">10% CASH BACK</div> -->
    <div *ngIf="!isPayout && !isCharity" class="text-slate-600 text-base font-extrabold leading-snug">
      <!-- <span *ngIf="!!offerName">{{offerName}}: </span> -->
      <span *ngIf="!!this.intReturnPercentage">{{(this.intReturnPercentage / 100.0)}}% {{ 'CASH_BACK' | translate
        }}</span>
      <span *ngIf="!!this.intReturnAmount">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'CASH_BACK' | translate
        }}</span>
    </div>
    <div *ngIf="!isPayout && isCharity" class="text-slate-600 text-base font-extrabold leading-snug">
      <!-- <span *ngIf="!!offerName">{{offerName}}: </span> -->
      <span *ngIf="!!this.intReturnPercentage">{{(this.intReturnPercentage / 100.0)}}% {{ 'DONATION' | translate
        }}</span>
      <span *ngIf="!!this.intReturnAmount">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'DONATION' |
        translate }}</span>
      <span *ngIf="!!charityName"> to {{charityName}}</span>
    </div>

    <div class="text-slate-600 text-base font-extrabold leading-snug">{{status}}</div>
    <!-- <div class="text-slate-600 text-base font-extrabold leading-snug">{{ cleared ? "Cleared" : status}}</div>
    <div class="text-slate-600 text-base font-extrabold leading-snug">{{ isCleared ? "Cleared" : status}}</div> -->

  </div>
  <div class="text-xl font-medium leading-relaxed" [ngClass]="{
    'text-grey-400': !cleared,
    'text-zinc-600': cleared && !isCharity,
    'text-charity-green-600': cleared && isCharity,
  }">{{intAmount > 0 ? '+' : ''}}{{(intAmount / 100.0) | currency}}</div>
</div>

<!-- <div *ngIf="isPayout"
  class="w-full px-2 py-2.5 xs:px-4 xs:py-3 md:px-6 md:py-4 bg-white rounded-xl justify-center xs:justify-between items-center inline-flex gap-1"
  [ngClass]="{'border border-zinc-300': isPayout}">
  <div class="h-full justify-start items-center gap-5 flex flex-grow">
    <div *ngIf="!isPayout"
      class="min-w-[80px] w-[80px] h-[80px] rounded-xl justify-center items-center flex flex-col flex-wrap"
      [ngClass]="{'bg-charity-green': isCharity, 'bg-patron-pink': !isCharity}">
      <div *ngIf="!!lastFour" class="text-center text-white base-label">Card</div>
      <div *ngIf="!!lastFour" class="text-center text-white base-label">{{ lastFour }}</div>
    </div>
    <div class="w-full flex-col justify-start items-start inline-flex">
      <div class="w-full text-black row-title">{{ venueName }}</div>

      <div *ngIf="!isPayout && !isCharity" class="row-subtitle text-patron-pink"
        [ngClass]="{'button-patron-pink': !this.cleared}" (click)="selectRewardOffer()">
        <span *ngIf="!!offerName">{{offerName}}: </span>
        <span *ngIf="!!this.intReturnPercentage">{{(this.intReturnPercentage / 100.0)}}% {{ 'BACK' | translate
          }}</span>
        <span *ngIf="!!this.intReturnAmount">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'BACK' | translate
          }}</span>
      </div>
      <div *ngIf="!isPayout && isCharity" class="row-subtitle text-charity-green"
        [ngClass]="{'button-charity-green': !this.cleared}" (click)="selectRewardOffer()">
        <span *ngIf="!!offerName">{{offerName}}: </span>
        <span *ngIf="!!this.intReturnPercentage">{{(this.intReturnPercentage / 100.0)}}% {{ 'DONATION' | translate
          }}</span>
        <span *ngIf="!!this.intReturnAmount">{{ (this.intReturnAmount / 100.0) | currency }} {{ 'DONATION' |
          translate }}</span>
        <span *ngIf="!!charityName"> - {{charityName}}</span>
      </div>

      <div *ngIf="!!txDate" class="w-full justify-start items-start inline-flex">
        <div class="w-full text-black row-label">{{ txDate | date:'short' }}</div>
      </div>
      <div *ngIf="!isCleared" class="tag bg-gray-500 text-white">{{status}}</div>
    </div>
  </div>
  <div class="flex-col justify-start items-end gap-0.5 inline-flex">
    <div class="text-right text-black row-title leading-relaxed">{{(intAmount / 100.0) | currency}}</div>
    <div *ngIf="!isPayout" class="text-right row-label"
      [ngClass]="{'text-charity-green': isCharity, 'text-patron-pink': !isCharity}">{{(intTxAmount / 100.0) | currency}}
    </div>
  </div>
</div> -->
