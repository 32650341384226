import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NavControlService } from 'src/app/components/nav/nav-control.service';
import { UserPaymentMethod } from 'src/app/model/user_payment_method.model';
import { FetchState } from '../../app.module';
import { LoginState } from '../../user-auth.service';
import { OfferDetailsController, OfferDetailsSheet } from '../offer-details.component';
import { UserOffersService } from 'src/app/services/user-offers.service';

@Component({
  selector: 'app-offer-action',
  templateUrl: './offer-action.component.html',
  styleUrls: ['./offer-action.component.css']
})
export class OfferActionComponent implements OnInit, OnDestroy {
  @Input()
  controller: OfferDetailsController;

  stateOffer: FetchState = FetchState.NONE;
  claimedOffer: boolean = false;
  activeUserPaymentMethods: UserPaymentMethod[] = [];

  FetchState = FetchState;
  LoginState = LoginState;

  constructor(
    public readonly navControlService: NavControlService,
    public readonly translate: TranslateService,
    public readonly userOfferService: UserOffersService,
  ) {
  }

  ngOnInit(): void {
    this.userOfferService.userState$.pipe(
      takeUntil(this.userOfferServiceDestroy$)
    ).subscribe({
      next: (userState) => {
        this.userState = userState;
      }
    });

    this.userOfferService.stateOffer$.pipe(
      takeUntil(this.userOfferServiceDestroy$)
    ).subscribe({
      next: (state) => {
        this.stateOffer = state;
      }
    });

    this.userOfferService.claimedOffer$.pipe(
      takeUntil(this.userOfferServiceDestroy$)
    ).subscribe({
      next: (claimed) => {
        this.claimedOffer = claimed;
      }
    });

    this.userOfferService.activeUserPaymentMethods$.pipe(
      takeUntil(this.userOfferServiceDestroy$)
    )
    .subscribe({
      next: (activeUserPaymentMethods) => {
        this.activeUserPaymentMethods = activeUserPaymentMethods;
      }
    });
  }

  private userOfferServiceDestroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.userOfferServiceDestroy$.next();
    this.userOfferServiceDestroy$.complete();
  }

  userState: LoginState;

  showGetStartedLogin() {
    this.controller.selectSheet(OfferDetailsSheet.GetStarted);
  }


  offerClaimedMessage(): Observable<string> {
    if (this.userOfferService?.offer?.redeemEndDate) {
      const redeemEndDate = new Date(this.userOfferService.offer.redeemEndDate).toLocaleDateString();
      if (this.userOfferService.isCharityOffer) {
        return this.translate.get('OFFER_CLAIMED_CHARITY_MESSAGE_END_DATE', {offerName: this.userOfferService?.offer?.name ?? '', redeemEndDate, venueName: this.userOfferService?.offer?.venue?.name});
      }
      else {
        return this.translate.get('OFFER_CLAIMED_MESSAGE_END_DATE', {offerName: this.userOfferService?.offer?.name ?? '', redeemEndDate, venueName: this.userOfferService?.offer?.venue?.name});
      }
    }
    else {
      if (this.userOfferService?.isCharityOffer) {
        return this.translate.get('OFFER_CLAIMED_CHARITY_MESSAGE', {offerName: this.userOfferService?.offer?.name ?? '', venueName: this.userOfferService?.offer?.venue?.name});
      }
      else {
        return this.translate.get('OFFER_CLAIMED_MESSAGE', {offerName: this.userOfferService?.offer?.name ?? '', venueName: this.userOfferService?.offer?.venue?.name});
      }
    }

  }

}
