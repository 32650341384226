import { gql } from "apollo-angular";
import { CharityDetails, CharityImageDetails, CharityPromotionDetails } from "./charity.fragment.graphql";
import { OfferImageDetails } from "./offer_image.fragment.graphql";
import { PlatformPromotionDetails, PromoterDetails, PromoterPromotionDetails } from "./promoter.fragment.graphql";
import { UserDetails } from "./user.fragment.graphql";

export const VenuePromotionDetails = gql`
fragment VenuePromotionDetails on VenuePromotion {
  id
  name
  disabledDate
  url
  medium {
    id
    name
    type
  }
}`

export const VenueOfferDetails = gql`
fragment VenueOfferDetails on Offer {
  id
  name
  internalName
  description
  finePrint
  redeemStartDate
  redeemEndDate
  archivedDate
  disabledDate
  claimStartDate
  claimEndDate
  startTimeOfDay
  endTimeOfDay
  daysOfTheWeek
  weeksOfTheMonth
  patronsLimit
  singlePatronLimit
  isVenueDefault
  isUniversal
  showInApp
  patronIncentive {
    id
    intReturnPercentage
    intReturnAmount
    intMinSpend
    intMaxReward
    disabledDate
  }
  charityIncentive {
    id
    intReturnPercentage
    intReturnAmount
    intMinSpend
    intMaxReward
    disabledDate
    charity {
      ...CharityDetails
    }
    promotions {
      ...CharityPromotionDetails
    }
  }
  promoterIncentive {
    id
    intReturnPercentage
    intReturnAmount
    intMinSpend
    intMaxReward
    disabledDate

  }
  platformIncentive {
    id
    intReturnPercentage
    intReturnAmount
    intMinSpend
    intMaxReward
    disabledDate
    promotion {
      ...PlatformPromotionDetails
    }
  }
  offerImage {
    ...OfferImageDetails
  }
  promotions {
    ...VenuePromotionDetails
  }
  }
${CharityDetails}
${CharityPromotionDetails}
${OfferImageDetails}
${PlatformPromotionDetails}
${VenuePromotionDetails}
`;
