import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FetchState } from '../app.module';
import { OfferFilter } from '../offers.service';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NavControlService, NavRole } from '../components/nav/nav-control.service';
import { LoginState } from '../user-auth.service';
import { IPlace } from '../components/map/map.component';
import { PatronOffer } from '../model/patron-offer.model';
import { addressToString } from '../pipes/address.pipe';
import { faGifts, faHandHoldingHeart, faHeart, faList, faLocationDot, faStar, faMapLocationDot as fasMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '../services/search.service';
import { UserOffersService } from '../services/user-offers.service';
import { Entity } from '../model/entity.model';

export class OfferData {
  constructor({offer}: {offer: PatronOffer}) {
    this.offer = offer;
  }

  offer?: PatronOffer;

  static updateOfferDataArray(offerDatas: OfferData[], newOffers: PatronOffer[]): void {
    newOffers.forEach((item, index) => {
      if (index < offerDatas.length) {
        // Update existing items
        offerDatas[index].offer = item;
      } else {
        // Add new items if the new data has more items than the initial array
        offerDatas.push({offer: item});
      }
    });

    // Handle case where new data has fewer items than the initial array
    if (newOffers.length < offerDatas.length) {
      offerDatas.splice(newOffers.length);
    }
  }

}

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css'],
})
export class OffersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('fakeOffersContainer') fakeOffersContainer: ElementRef;
  @ViewChild('offersListContainer') offersListContainer: ElementRef;

  showMap: boolean = false;
  place: IPlace = null;

  toggleMap() {
    this.navControlService.viewingMap = !this.showMap;
    this.showMap = !this.showMap;
  }

  error: any;
  get offersCount(): number {
    return this.offers?.length || 0;
  }

  possibleFilters: OfferFilter[] = [OfferFilter.NEAR_ME, OfferFilter.CHARITY, OfferFilter.RECENT, OfferFilter.CLAIMED];
  entityFilter$: Observable<Entity> = this.searchService.entityFilter$;
  isValidOfferImage: boolean = false;

  FetchState = FetchState;
  NavRole = NavRole;
  FaMap = faMap;
  // FaMap = fasMapLocationDot;
  FaList = faList;

  constructor(
    public readonly translate: TranslateService,
    private readonly navControlService: NavControlService,
    private readonly route: ActivatedRoute,
    private readonly searchService: SearchService,
    private readonly userOffersService: UserOffersService,
  ) {
  }

  offersServiceState: FetchState;

  mapOffers: IPlace[] = [];
  offers: Array<OfferData> = [{},{},{},{},{}];

  fakeOffers: any[] = [{}];

  showOfferDetails(offer: PatronOffer) {
    if (!offer) {
      return;
    }
    this.userOffersService.selectedOfferData = offer;
  }


  ngAfterViewInit(): void {
    // Determine the number of fake offers to show based on the height offersListContainer and the height of its first child
    const firstOfferDiv = this.fakeOffersContainer?.nativeElement?.firstElementChild;
    const firstOfferDivHeight = firstOfferDiv?.offsetHeight || 0;
    let offersOffset = 8;
    let offersListContainerHeight = this.offersListContainer?.nativeElement?.offsetHeight || 0;
    let numFakeOffers = Math.ceil(offersListContainerHeight / (firstOfferDivHeight + offersOffset));
    numFakeOffers = Math.max(numFakeOffers, 1);
    const fakeOffers = [];
    for (let i = 0; i < numFakeOffers; i++) {
      fakeOffers.push({});
    }
    setTimeout(() => {
      this.fakeOffers = fakeOffers;
      this.setListContainerBufferHeight();
    }, 1);
  }

  static uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  ngOnInit(): void {
    // If this route has an offer id, then we want to show that offer in a details view
    this.route.params.pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      next: (params) => {
        // offerId
        if (params['offerId']) {
          this.userOffersService.selectedOfferData = params['offerId'];
        }
        // pivotId
        else if (params['pivotId']) {
          if( OffersComponent.uuidRegex.test(params['pivotId']) ) {
            this.searchService.selectFilter(params['pivotId']);
          }
        }
      }
    });

    this.route.queryParams.pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      next: (params) => {
        const entityId = params['entityId'] ?? params['pivotId'];
        if (!!entityId) {
          // make sure entityId is a UUID
          // regex for uuid v4
          if (OffersComponent.uuidRegex.test(entityId)) {
            this.searchService.selectFilter(entityId);
          }
        }
      }
    });

    this.userOffersService.offer$.pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      next: (offerData) => {
        let offerId: string;
        if (offerData instanceof PatronOffer) {
          offerId = offerData.id;
        }
        else {
          offerId = offerData;
        }
        this.scrollToPlace(offerId);
      }
    });

    this.showMap = this.navControlService.viewingMap;

    this.searchService.searchResults$.pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      next: (searchResults) => {
        if (searchResults) {
          const newOffers = searchResults.results.map((result) => {
            if (result.entity instanceof PatronOffer) {
              return result.entity;
            }
            return null;
          })
          .filter((offer) => !!offer);

          this.mapOffers = (newOffers ?? []).map((offer) => {
            let latitude: number = offer.venue?.venueAddresses[0]?.geoCoordinate?.latitude;
            let longitude: number = offer.venue?.venueAddresses[0]?.geoCoordinate?.longitude;

            return {
              id: offer.id,
              name: offer.venue?.name || offer.name,
              address: addressToString(offer.venue?.venueAddresses[0] || '', null, false),
              phone: offer.venue?.venueAddresses[0]?.phone || '',
              latitude,
              longitude,
              offer: offer
            };
          })
          .filter((place) => place.latitude && place.longitude);

          OfferData.updateOfferDataArray(this.offers, newOffers);
        }
        else {
          this.offers = [];
          this.mapOffers = [];
        }

        this.setListContainerBufferHeight();
      }
    });

    this.searchService.state$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (state) => {
        this.offersServiceState = state;
      }
    });

    this.navControlService.userState$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (userState) => {
        if (userState === LoginState.LOGGED_IN) {
          if (this.possibleFilters.indexOf(OfferFilter.RECENT) < 0) {
            this.possibleFilters.push(OfferFilter.RECENT);
          }
          if (this.possibleFilters.indexOf(OfferFilter.CLAIMED) < 0) {
            this.possibleFilters.push(OfferFilter.CLAIMED);
          }
        }
        else if (userState === LoginState.LOGGED_OUT) {
          if (this.possibleFilters.indexOf(OfferFilter.RECENT) >= 0) {
            this.possibleFilters.splice(this.possibleFilters.indexOf(OfferFilter.RECENT), 1);
          }
          if (this.possibleFilters.indexOf(OfferFilter.CLAIMED) >= 0) {
            this.possibleFilters.splice(this.possibleFilters.indexOf(OfferFilter.CLAIMED), 1);
          }
        }
      }
    });

    this.selectedFilters = this.searchService.filters$.value;
    this.searchService.filters$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (filters) => {
        this.selectedFilters = filters;
      }
    });

    // this.navControlService.isHideNavTabBar$.pipe(
    //   takeUntil(this.destroy$)
    // )
    // .subscribe({
    //   next: (isHidingNavTabBar) => {
    //     this.isHidingNavTabBar = isHidingNavTabBar;
    //   }
    // });
  }

  isHidingNavTabBar: boolean = true;
  selectedFilters: Array<OfferFilter | string> = [];

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.searchService.loadMore();
    }
  }

  hasRole(role: NavRole) {
    return this.navControlService.hasRole(role);
  }

  getFilterIcon(filter: OfferFilter): any {
    switch (filter) {
      case OfferFilter.ALL:
        return faGifts;
      case OfferFilter.NEAR_ME:
        return faLocationDot;
      case OfferFilter.CLAIMED:
        return faHeart;
      case OfferFilter.NEW:
        return faStar;
      case OfferFilter.CHARITY:
        return faHandHoldingHeart;
      default:
        return '';
    }
  }

  loadMore() {
    this.searchService.loadMore();
  }

  selectFilter(filter: OfferFilter | string) {
    this.searchService.selectFilter(filter);
  }

  translateFilter(filter: OfferFilter): Observable<string> {
    switch (filter) {
      case OfferFilter.ALL:
        return this.translate.get('ALL');
      case OfferFilter.NEAR_ME:
        return this.translate.get('NEAR_ME');
      case OfferFilter.CLAIMED:
        return this.translate.get('CLAIMED');
      case OfferFilter.NEW:
        return this.translate.get('NEW');
      case OfferFilter.CHARITY:
        return this.translate.get('FUNDRAISERS');
      default:
        return of('');
    }
  }

  listContainerBufferHeight: number = 8;
  setListContainerBufferHeight() {
    const minHeight: number = 8;
    let result = minHeight;
    if (!!this.offersListContainer && this.offers?.length > 0) {
      const firstOfferDiv = this.offersListContainer?.nativeElement?.firstElementChild;
      let offersOffset = 8;
      result = Math.max((this.offersListContainer?.nativeElement?.offsetHeight - firstOfferDiv.offsetHeight - offersOffset) || minHeight, minHeight);
    }
    else {
      result = minHeight;
    }

    setTimeout(() => {
      this.listContainerBufferHeight = result;
    }, 1);
  }

  private _scrollTimeout: any = null;
  onScrollList(event: any) {
    if (this._scrollTimeout) {
      clearTimeout(this._scrollTimeout);
    }
    this._scrollTimeout = setTimeout(() => {
      this._forcePlaceId = null;
    }, 150);

    if (!!this._forcePlaceId) {
      return;
    }

    let highestDiv = null;
    let highestTop = Number.MAX_VALUE;

    // Find top container in view
    const divs = this.offersListContainer.nativeElement.querySelectorAll('.offer-list-item');

    divs.forEach(div => {
      const divTop = div.getBoundingClientRect().top;
      // Div should be largely in view
      let _minDivTop = div.offsetHeight / 1.25;

      if (divTop >= _minDivTop && divTop < highestTop) {
        highestTop = divTop;
        highestDiv = div;
      }
    });

    if (!!highestDiv) {
      this.navControlService.highlightedOfferId = highestDiv.id;
      const currentOffer = this.offers.find((data) => data.offer?.id === highestDiv.id);
      if (!!currentOffer) {
        this.place = this.mapOffers.find((place) => place.id === highestDiv.id);
      }
    }
  }

  private _forcePlaceId: string = null;
  scrollToPlace(placeId: string, displayPlace: boolean = false) {
    if (!placeId || placeId.length === 0) return;
    this._forcePlaceId = placeId;
    const divs = this.offersListContainer?.nativeElement.querySelectorAll('.offer-list-item');

    let div = null;
    (divs ?? []).forEach((_div) => {
      if (_div.id === placeId) {
        div = _div;
      }
    });

    if (!!div) {
      const desiredScrollPosition = (div.offsetTop - this.offersListContainer.nativeElement.offsetTop);
      if (desiredScrollPosition < this.offersListContainer.nativeElement.scrollTop || desiredScrollPosition > this.offersListContainer.nativeElement.scrollTop) {
        // Smoothly scroll the parent div
        this.offersListContainer.nativeElement.scrollTo({ top: desiredScrollPosition, behavior: 'smooth' });
      }
    }

    if (displayPlace) {
      const offer: PatronOffer = this.offers.find((data) => data.offer?.id === placeId)?.offer;
      if (!!offer) {
        this.showOfferDetails(offer);
      }
    }

    this.navControlService.highlightedOfferId = placeId;
  }
}
