<div
  class="flex flex-col items-center justify-start w-full mx-auto max-w-xl min-w-[180px] sm:min-w-[240px] min-h-[240px] relative bg-stone-100 py-12 px-8 h-full overflow-y-auto max-h-[95vh]">
  <div class="w-full flex flex-col justify-start items-start mb-4">
    <div *ngIf="loginViewState === LoginViewState.REGISTER">
      <div class="text-gray-500 text-4xl font-medium leading-relaxed">Join GetZeals</div>
      <div><span class="text-gray-500 text-lg font-normal leading-relaxed">or </span><span
          class="text-patron-pink text-2xl font-normal leading-relaxed cursor-pointer"
          (click)="loginViewState = LoginViewState.LOGIN">Sign in</span></div>
    </div>

    <div *ngIf="loginViewState === LoginViewState.CONFIRMATION_CODE">
      <div class="text-gray-500 text-4xl font-medium leading-relaxed">Confirmation Code</div>
    </div>
    <div *ngIf="loginViewState === LoginViewState.FORGOT_PASSWORD">
      <div class="text-gray-500 text-4xl font-medium leading-relaxed">Forgot Password</div>
      <div><span class="text-gray-500 text-lg font-normal leading-relaxed">or </span><span
          class="text-patron-pink text-2xl font-normal leading-relaxed cursor-pointer"
          (click)="loginViewState = LoginViewState.LOGIN">Sign in</span></div>
    </div>
    <div *ngIf="loginViewState === LoginViewState.RESET_PASSWORD">
      <div class="text-gray-500 text-4xl font-medium leading-relaxed">Reset Password</div>
      <div><span class="text-gray-500 text-lg font-normal leading-relaxed">or </span><span
          class="text-patron-pink text-2xl font-normal leading-relaxed cursor-pointer"
          (click)="loginViewState = LoginViewState.LOGIN">Sign in</span></div>
    </div>
    <div *ngIf="loginViewState === LoginViewState.LOGIN">
      <div class="text-gray-500 text-4xl font-medium leading-relaxed">Sign in to GetZeals</div>
      <div *ngIf="showDetails"><span class="text-gray-500 text-lg font-normal leading-relaxed">or </span><span
          class="text-patron-pink text-2xl font-normal leading-relaxed cursor-pointer"
          (click)="loginViewState = LoginViewState.REGISTER">Sign up</span></div>
    </div>
  </div>

  <!-- 3rd party logins -->
  <div *ngIf="loginViewState === LoginViewState.REGISTER || loginViewState === LoginViewState.LOGIN"
    class="w-full flex flex-col justify-start items-start">

    <div *ngIf="(userState) === LoginState.ERROR"
      class="w-full border-red-500 text-red-500 border p-2 flex justify-center items-center rounded-sm text-xs my-4">
      <fa-icon [icon]="ErrorIcon" [styles]="{'color': 'red'}" class="mr-1"></fa-icon>
      Please try again.
    </div>

    <div class="flex flex-col justify-center items-center w-full">
      <div class="my-2 w-full">
        <app-google-login [textType]="'continue_with'"></app-google-login>
      </div>
      <div class="my-2 w-full">
        <app-apple-login></app-apple-login>
      </div>
      <!-- <div class="my-2 w-full text-center">
        <div class="fb-login-button" data-scope="public_profile,email" data-width="" data-size="large" data-button-type="" data-layout="" data-auto-logout-link="false" data-use-continue-as="true" data-onlogin="onFacebookLogin()"></div>
      </div> -->
    </div>

    <div *ngIf="(userState) !== LoginState.ERROR" class="w-full my-4 flex flex-row gap-4 justify-center items-center">
      <div class="w-full h-[1px] bg-zinc-300"></div>
      <div class="text-center text-gray-500 text-2xl font-normal leading-relaxed">or</div>
      <div class="w-full h-[1px] bg-zinc-300"></div>
    </div>
  </div>

  <form *ngIf="loginViewState === LoginViewState.REGISTER" [formGroup]="registerForm" (submit)="registerUser()"
    class="w-full flex flex-col justify-start items-start">
    <!-- <div class="text-gray-500 text-4xl font-medium leading-relaxed">Join GetZeals</div> -->
    <!-- <div><span class="text-gray-500 text-lg font-normal leading-relaxed">or </span><span class="text-patron-pink text-lg font-normal leading-relaxed cursor-pointer" (click)="loginViewState = LoginViewState.LOGIN">Sign in</span></div> -->

    <div class="flex flex-col w-full">
      <label class="text-sm text-gray-500" for="email">Email</label>
      <input formControlName="email" type="text"
        class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
      <div *ngIf="registerForm.controls['email']?.invalid && registerForm.controls['email']?.touched"
        class="flex flex-row justify-between items-center">
        <div class="text-red-500 text-sm">{{ registerEmailErrorDisplay }}</div>
        <!-- Forgot Password link -->
        <span class="text-gray-500 text-sm font-normal leading-none cursor-pointer"
          (click)="loginViewState = LoginViewState.FORGOT_PASSWORD">Forgot Password?</span>
      </div>
    </div>
    <div *ngIf="!userAgreedToTerms" class="my-2">
      <span class="text-gray-500 text-sm font-normal leading-none">By clicking Agree, you agree to GetZeals</span>
      <span class="text-gray-500 text-sm font-normal leading-none"> </span>
      <span class="text-patron-pink text-sm font-normal leading-none cursor-pointer" (click)="goToTerms()"> User
        Agreement</span>
      <span class="text-gray-500 text-sm font-normal leading-none">, </span>
      <span class="text-patron-pink text-sm font-normal leading-none cursor-pointer" (click)="goToPrivacy()">Privacy
        Policy</span>
      <span class="text-gray-500 text-sm font-normal leading-none"> </span>
      <span class="text-gray-500 text-sm font-normal leading-none"> and </span>
      <span class="text-gray-500 text-sm font-normal leading-none">Cookie Policy.</span>
      <!-- <span class="text-patron-pink text-sm font-normal leading-none">Cookie Policy.</span> -->
    </div>
    <!-- Agree Button -->
    <div *ngIf="!userAgreedToTerms" class="w-full flex flex-row justify-between items-start mt-4 gap-2">
      <button class="button-patron-pink-borderless w-full max-w-80"
        [disabled]="registerForm.controls['email']?.invalid && false"
        (click)="handleUserAgreementClick()">Agree</button>
    </div>


    <div *ngIf="userAgreedToTerms" class="flex flex-col w-full">
      <label class="text-sm text-gray-500" for="firstName">First Name</label>
      <input formControlName="firstName" type="text"
        class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
      <div *ngIf="registerForm.controls['firstName']?.invalid && registerForm.controls['firstName']?.touched"
        class="text-red-500 text-sm">First Name is required</div>
    </div>
    <div *ngIf="userAgreedToTerms" class="flex flex-col w-full">
      <label class="text-sm text-gray-500" for="lastName">Last Name</label>
      <input formControlName="lastName" type="text"
        class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
      <div *ngIf="registerForm.controls['lastName']?.invalid && registerForm.controls['lastName']?.touched"
        class="text-red-500 text-sm">Last Name is invalid</div>
    </div>
    <div *ngIf="userAgreedToTerms" class="flex flex-col w-full">
      <label class="text-sm text-gray-500" for="password">Password</label>
      <input formControlName="password" type="password"
        class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
      <div *ngIf="registerForm.controls['password']?.invalid && registerForm.controls['password']?.touched"
        class="text-red-500 text-sm">Must be at least 8 characters, with a number, uppercase letter, lowercase letter,
        and special character</div>
    </div>
    <div *ngIf="userAgreedToTerms" class="flex flex-col w-full">
      <label class="text-sm text-gray-500" for="confirmPassword">Confirm Password</label>
      <input formControlName="confirmPassword" type="password"
        class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
      <div
        *ngIf="registerForm.controls['confirmPassword']?.invalid && registerForm.controls['confirmPassword']?.touched"
        class="text-red-500 text-sm">{{ confirmPasswordErrorDisplay }}</div>
    </div>
    <!-- Join Button -->
    <div *ngIf="userAgreedToTerms" class="w-full flex flex-row justify-between items-start mt-4 gap-2">
      <button *ngIf="registerEmailAlreadyExists" class="button-patron-pink-borderless w-full"
        (click)="resendConfirmationCode()">Resend Code</button>
      <button class="button-patron-pink-borderless w-full max-w-80" [disabled]="!registerForm.valid && false"
        type="submit">Join</button>
    </div>

  </form>
  <form *ngIf="loginViewState === LoginViewState.CONFIRMATION_CODE" [formGroup]="confirmCodeForm"
    (submit)="confirmCode()" class="w-full h-full flex flex-col justify-start items-start">
    <!-- <div class="text-gray-500 text-4xl font-medium leading-relaxed">Confirmation Code</div> -->
    <!-- <div><span class="text-gray-500 text-lg font-normal leading-relaxed">or </span><span class="text-patron-pink text-lg font-normal leading-relaxed cursor-pointer" (click)="loginViewState = LoginViewState.LOGIN">Sign in</span></div> -->
    <input type="hidden" formControlName="email" />

    <div class="flex flex-col w-full">
      <label class="text-sm text-gray-500" for="lastName">Confirmation Code</label>
      <input formControlName="code" type="number"
        class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
      <div *ngIf="confirmCodeForm.controls['code']?.invalid && confirmCodeForm.controls['code']?.touched"
        class="text-red-500 text-sm">Confirmation code is invalid</div>
    </div>

    <div class="w-full flex flex-row justify-center items-center mt-4">
      <button class="button-patron-pink-borderless w-full max-w-80" [disabled]="!confirmCodeForm.valid && false"
        type="submit">Confirm</button>
    </div>

  </form>
  <form *ngIf="loginViewState === LoginViewState.FORGOT_PASSWORD" [formGroup]="forgotPasswordForm"
    (submit)="forgotPassword()" class="w-full h-full flex flex-col justify-start items-start">
    <!-- <div class="text-gray-500 text-4xl font-medium leading-relaxed">Forgot Password</div> -->
    <!-- <div><span class="text-gray-500 text-lg font-normal leading-relaxed">or </span><span class="text-patron-pink text-lg font-normal leading-relaxed cursor-pointer" (click)="loginViewState = LoginViewState.LOGIN">Sign in</span></div> -->
    <!-- <div><span class="text-gray-500 text-lg font-normal leading-relaxed">or </span><span class="text-patron-pink text-lg font-normal leading-relaxed cursor-pointer" (click)="loginViewState = LoginViewState.LOGIN">Sign in</span></div> -->
    <input type="hidden" formControlName="email" />

    <div class="flex flex-col w-full">
      <label class="text-sm text-gray-500" for="lastName">Email</label>
      <input formControlName="email" type="text"
        class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
      <div *ngIf="forgotPasswordForm.controls['email']?.invalid && forgotPasswordForm.controls['email']?.touched"
        class="text-red-500 text-sm">Email is invalid</div>
    </div>

    <div class="w-full flex flex-row justify-center items-center mt-4">
      <button class="button-patron-pink-borderless w-full max-w-80" [disabled]="!forgotPasswordForm.valid && false"
        type="submit">Send Code</button>
    </div>
  </form>
  <form *ngIf="loginViewState === LoginViewState.RESET_PASSWORD" [formGroup]="resetPasswordForm"
    (submit)="resetPassword()" class="w-full h-full flex flex-col justify-start items-start">
    <!-- <div class="text-gray-500 text-4xl font-medium leading-relaxed">Reset Password</div> -->
    <!-- <div><span class="text-gray-500 text-lg font-normal leading-relaxed">or </span><span class="text-patron-pink text-lg font-normal leading-relaxed cursor-pointer" (click)="loginViewState = LoginViewState.LOGIN">Sign in</span></div> -->
    <input type="hidden" formControlName="email" />
    <input type="hidden" formControlName="code" />

    <!-- Code -->
    <div class="flex flex-col w-full">
      <label class="text-sm text-gray-500" for="lastName">Confirmation Code</label>
      <input formControlName="code" type="number"
        class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
      <div *ngIf="resetPasswordForm.controls['code']?.invalid && resetPasswordForm.controls['code']?.touched"
        class="text-red-500 text-sm">{{ resetPasswordCodeErrorDisplay }}</div>
    </div>
    <div class="flex flex-col w-full mt-6">
      <label class="text-sm text-gray-500" for="lastName">New Password</label>
      <input formControlName="password" type="password"
        class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
      <div *ngIf="resetPasswordForm.controls['password']?.invalid && resetPasswordForm.controls['password']?.touched"
        class="text-red-500 text-sm">Password is invalid</div>
    </div>

    <div class="w-full flex flex-row justify-center items-center mt-4">
      <button class="button-patron-pink-borderless w-full max-w-80" [disabled]="!resetPasswordForm.valid && false"
        type="submit">Reset Password</button>
    </div>
  </form>

  <div *ngIf="loginViewState === LoginViewState.LOGIN" class="w-full flex flex-col justify-start items-start">
    <!-- <div *ngIf="showTitle" class="text-gray-500 text-4xl font-medium leading-relaxed">Sign in to GetZeals</div> -->
    <!-- <div *ngIf="showDetails"><span class="text-gray-500 text-lg font-normal leading-relaxed">or </span><span class="text-patron-pink text-lg font-normal leading-relaxed cursor-pointer" (click)="loginViewState = LoginViewState.REGISTER">Sign up</span></div> -->
    <!-- <div *ngIf="showTitle" class="text-lg sm:text-2xl font-bold antialiased text-center">{{title}}</div>
    <div *ngIf="showDetails" class="text-sm sm:text-lg text-center mt-5">{{details}}</div> -->

    <form [formGroup]="loginForm" (submit)="loginUser()" class="w-full flex flex-col justify-start items-start">
      <div class="flex flex-col w-full">
        <label class="text-sm text-gray-500" for="email">Email</label>
        <input formControlName="email" type="text"
          class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
        <div *ngIf="loginForm.controls['email']?.invalid && loginForm.controls['email']?.touched"
          class="text-red-500 text-sm">{{ loginEmailErrorDisplay }}</div>
      </div>
      <div class="flex flex-col w-full">
        <label class="text-sm text-gray-500" for="password">Password</label>
        <input formControlName="password" type="password"
          class="border rounded p-2 text-xl text-gray-700 bg-white focus:bg-white" />
        <div *ngIf="loginForm.controls['password']?.invalid && loginForm.controls['password']?.touched"
          class="text-red-500 text-sm">Password is required</div>
        <!-- Forgot Password link -->
        <div class="flex flex-row justify-end items-center mt-2">
          <span class="text-gray-500 text-sm font-normal leading-none cursor-pointer"
            (click)="loginViewState = LoginViewState.FORGOT_PASSWORD">Forgot Password?</span>
        </div>
      </div>

      <div class="w-full flex flex-row justify-between items-start mt-4">
        <button class="button-patron-pink-borderless w-full max-w-80" [disabled]="!loginForm.valid && false"
          type="submit">Sign in</button>
      </div>
    </form>
  </div>

  <!-- <div class="w-full flex flex-col justify-start items-start">
    <!- - 3rd party logins - ->
    <div *ngIf="(userState) !== LoginState.ERROR" class="w-full my-4 flex flex-row gap-4 justify-center items-center">
      <div class="w-full h-[1px] bg-zinc-300"></div>
      <div class="text-center text-gray-500 text-2xl font-normal leading-relaxed">or</div>
      <div class="w-full h-[1px] bg-zinc-300"></div>

    </div>
    <div *ngIf="(userState) === LoginState.ERROR" class="w-full border-red-500 text-red-500 border p-2 flex justify-center items-center rounded-sm text-xs my-4">
        <fa-icon [icon]="ErrorIcon" [styles]="{'color': 'red'}" class="mr-1"></fa-icon>
        Please try again.
    </div>

    <div class="flex flex-col justify-center items-center w-full">
      <div class="my-2 w-full">
        <app-google-login [textType]="'continue_with'"></app-google-login>
      </div>
      <div class="my-2 w-full">
        <app-apple-login></app-apple-login>
      </div>
      <!- - <div class="my-2 w-full text-center">
        <div class="fb-login-button" data-scope="public_profile,email" data-width="" data-size="large" data-button-type="" data-layout="" data-auto-logout-link="false" data-use-continue-as="true" data-onlogin="onFacebookLogin()"></div>
      </div> - ->
    </div>
  </div> -->

  <div *ngIf="isLoading"
    class="absolute inset-0 flex flex-col justify-center items-center flex-grow bg-white bg-opacity-75">
    <div class="loader-wrapper flex flex-col justify-center items-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  </div>
</div>
