<div class="container mx-auto px-6 py-8 bg-white rounded-lg max-w-3xl">
  <h1 class="text-3xl font-semibold antialiased mb-6">{{ affiliate?.name }}</h1>

  <div class="mb-8">
    <h2 class="text-xl font-semibold antialiased mb-4 text-grey-900">Details</h2>

    <div class="flex items-center mb-2 hover:bg-gray-100 rounded px-2 py-1 cursor-pointer" (click)="copyToClipboard()">
      <p class="mr-2 text-lg text-grey-900">Code:</p>
      <p class="font-mono text-lg text-emerald">{{ affiliate?.code }}</p>
      <fa-icon [icon]="faCopy" class="ml-2 text-grey"></fa-icon>
      <span class="text-sm invisible group-hover:visible ml-2">Click to Copy</span>
    </div>

    <p class="text-lg text-grey-900">Description: <span class="font-normal">{{ affiliate?.description }}</span></p>
    <p class="text-lg text-grey-900">Percentage: <span class="font-normal">{{ affiliate?.intPlatformPercentage }}%</span></p>
    <p class="text-lg text-grey-900">Amount: <span class="font-normal">${{ affiliate?.intPlatformAmount }}</span></p>
    <p class="text-lg text-grey-900">Duration: <span class="font-normal">{{ affiliate?.intDurationDays }} days</span></p>
  </div>

  <div class="flex justify-end">
    <button (click)="editAffiliate()" class="text-white bg-business-blue py-2 px-4 rounded hover:bg-business-blue-dark">
      Edit Affiliate
    </button>
  </div>

  <!-- Affiliate Operators -->
  <div class="mt-8">
    <h2 class="text-xl font-semibold antialiased mb-4 text-grey-900">Operators</h2>
    <div class="relative mb-4">
      <app-user-search [exludeUsers]="affiliateOperatorUsers" (userSelected)="inviteAffiliateOperator($event)"></app-user-search>
    </div>

    <h3 class="text-md font-medium mb-2">Current Affiliate Operators:</h3>
    <ul class="divide-y divide-gray-200">
      <li *ngFor="let operator of affiliate?.affiliateOperators" class="py-2 flex justify-between">
        <div>
          {{ operator.user?.fullName }} ({{ operator.user?.email }})
        </div>
        <div *ngIf="removingAffiliateOperator === operator">
          <div class="loader-wrapper flex justify-center items-center">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"></div>
          </div>
        </div>
        <button *ngIf="canRemoveAffiliateOperator(operator)" (click)="removeAffiliateOperator(operator)" class="text-red-500 hover:text-red-700">
          <fa-icon [icon]="faTrash" class="mr-2 text-red"></fa-icon>
        </button>
      </li>
    </ul>
  </div>

  <div class="mt-8">
    <h2 class="text-xl font-semibold antialiased mb-4 text-grey-900">Affiliate Venues</h2>
    <ul class="divide-y divide-gray-300">
      <li *ngFor="let affiliateVenue of affiliate?.affiliateVenues" class="py-4">
        <p class="text-lg text-grey-900">{{ affiliateVenue.createdDate | date:'short' }}</p>
      </li>
    </ul>
  </div>

  <app-ach-account [owner]="affiliate" [controllerUsers]="affiliateOperatorUsers"></app-ach-account>
</div>





<!-- Overlay -->
<div class="fixed inset-0 h-screen w-full bg-black bg-opacity-30 z-50 transition-opacity duration-300"
  [ngClass]="{
    'opacity-0 pointer-events-none': !showEditAffiliate,
    'opacity-100 pointer-events-auto': showEditAffiliate
  }"
  (click)="handleClickOutsideModal()">
</div>

<!-- Sheet/Modal -->
<div [ngClass]="{
  'translate-y-0 opacity-100 pointer-events-auto': showEditAffiliate,
  'translate-y-full opacity-0 pointer-events-none': !showEditAffiliate
  }"
  class="fixed left-1/2 transform -translate-x-1/2 bottom-0 w-full md:w-3/4 lg:w-1/2 xl:w-1/3 2xl:w-1/4 z-50 rounded-t-lg md:rounded-lg shadow-lg transition-all duration-300 ease-in-out transform translate-y-full overflow-y-auto h-[90vh] md:h-auto md:my-auto md:bottom-auto">
  <app-edit-affiliate [affiliate]="affiliate" (close)="closeModal()"></app-edit-affiliate>
</div>
