<div class="flex flex-col justify-center w-full h-full">

  <div class="flex-1 overflow-x-hidden space-y-2 h-full">

    <div class="flex flex-row flex-wrap justify-between items-center">
      <div class="flex flex-row flex-wrap justify-between items-center w-full">
        <h2 class="section-header-title">Operators</h2>
        <div class="icon-white-zinc-700 cursor-pointer" (click)="showSendInvite = true">
          <fa-icon [icon]="faAdd" class=""></fa-icon>
        </div>
      </div>
    </div>
    <div *ngIf="venueStateById === FetchState.LOADED_ALL">
      <div *ngIf="venue?.invites?.length > 0" class="w-full mb-2 border border-gray-200 rounded-xl p-4">
        <div class="flex flex-row flex-wrap justify-between items-center">
          <h3 class="text-md font-medium mb-2">Pending Invitations:</h3>
        </div>
          <ul class="divide-y divide-gray-200">
          <li *ngFor="let invite of venue?.invites" class="py-2 flex justify-between">
            <div class="flex flex-row justify-start items-center">
              <div class="h-9 w-9 flex justify-center items-center corner-25 overflow-hidden relative mr-2">
                <div class="absolute inset-0 flex justify-center items-center corner-25 border border-zinc-900">
                  <fa-icon class="fa-lg fa-regular" [icon]="FaUser" [styles]="{'color': 'zinc'}"></fa-icon>
                </div>
              </div>
              <span *ngIf="!!invite.toName && !!invite.toEmail">{{ invite.toName }} ({{ invite.toEmail }})</span>
              <span *ngIf="!!invite.toName && !invite.toEmail">{{ invite.toName }}</span>
              <span *ngIf="!invite.toName && !!invite.toEmail">{{ invite.toEmail }}</span>

            </div>
            <div *ngIf="removingInvite === invite">
              <div class="loader-wrapper flex justify-center items-center">
                <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"></div>
              </div>
            </div>
            <button (click)="removeInvite(invite)"
              class="text-red-500 hover:text-red-700 cursor-pointer">
              <fa-icon [icon]="faTrash" class="mr-2 text-red"></fa-icon>
            </button>
          </li>
        </ul>
      </div>

      <!-- <div class="relative mb-4">
        <app-user-search [exludeUsers]="venueOperatorUsers" (userSelected)="inviteVenueOperator($event)"
          [placeholder]="'Search for a user to add...'"></app-user-search>
      </div> -->

      <ul class="divide-y divide-gray-200">
        <li *ngFor="let operator of venue?.venueOperators" class="py-2 flex justify-between">
          <div class="flex flex-row justify-start items-center">
            <div class="h-9 w-9 flex justify-center items-center corner-25 overflow-hidden relative mr-2">
              <div class="absolute inset-0 flex justify-center items-center corner-25 border border-zinc-900">
                <fa-icon class="fa-lg fa-regular" [icon]="FaUser" [styles]="{'color': 'zinc'}"></fa-icon>
              </div>
              <img class="absolute inset-0 w-full h-full object-cover" *ngIf="!!operator.user?.userImage?.imageUrl"
                [src]="operator.user?.userImage?.imageUrl">
            </div>
                        <span *ngIf="!!operator.user?.fullName && !!operator.user?.email">{{ operator.user?.fullName }} ({{ operator.user?.email }})</span>
            <span *ngIf="!!operator.user?.fullName && !operator.user?.email">{{ operator.user?.fullName }}</span>
            <span *ngIf="!operator.user?.fullName && !!operator.user?.email">{{ operator.user?.email }}</span>

          </div>
          <div *ngIf="removingVenueOperator === operator">
            <div class="loader-wrapper flex justify-center items-center">
              <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"></div>
            </div>
          </div>
          <button *ngIf="canRemoveVenueOperator(operator)" (click)="removeVenueOperator(operator)"
            class="text-red-500 hover:text-red-700 cursor-pointer">
            <fa-icon [icon]="faTrash" class="mr-2 text-red"></fa-icon>
          </button>
        </li>
      </ul>
    </div>


  </div>

</div>

<!-- Overlay -->
<app-modal [isActive]="showSendInvite" (close)="closeModal()" [showClose]="false">
  <app-share-invite [saveHandler]="inviteUserSaveHandler.bind(this)" (close)="closeModal()"></app-share-invite>
</app-modal>
